import { UtilityService } from './../../../core/services/utility/utility.service';
import * as StaticDataConfig from './../../../core/services/utility/static-data.config';
import { HttpService } from './../../../services/http/http.service';
import { environment } from 'src/environments/environment';
import { SocketioService } from '../../../services/socketio/socketio.service';
import { AfterViewInit, HostListener, OnDestroy, TemplateRef } from '@angular/core';
import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Router } from '@angular/router';

import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input-r';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { debounceTime, distinctUntilChanged, first } from 'rxjs/operators';
import { Observable, Subscription } from 'rxjs';

import { MomentDateAdapter, MAT_MOMENT_DATE_ADAPTER_OPTIONS } from '@angular/material-moment-adapter';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { country_code } from './country-list';
// the `default as` syntax.
import * as _moment from 'moment';

import { StripeService, StripeCardComponent } from 'ngx-stripe';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { StripeCardOption, StripeElementOption } from 'src/app/core/services/utility/stripe-element.config';
import { Card } from 'src/app/models/card.model';
import { Trip } from 'src/app/models/trips.model';
import { animate, style, transition, trigger } from '@angular/animations';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MaxRangeSelectionStrategy } from 'src/app/core/services/utility/roster-trip-date-strategy';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { Time_Picker } from 'src/app/core/components/time-range-picker/time-range-picker.component';
import { AssignedStatusType, MasAssignService } from 'src/app/corporate/services/mas-assign/mas-assign.service';
import { UserService } from '../../services/user/user.service';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DateTimePickerComponent } from '../../components/date-time-picker/date-time-picker.component';
import { PhoneNumberModalComponent } from '../../components/phone-number-modal/phone-number-modal.component';
import { MasQueueService } from '../../services/mas-queue/mas-queue.service';
import { QueueTripModalComponent } from '../../components/queue-trip-modal/queue-trip-modal.component';
import { MasService } from '../../services/mas/mas.service';
import { GoogleMapService } from '../../services/google-map/google-map.service';
import { Notification } from 'src/app/models/notification.model';
import {
    Assigned_Status_Text_Class,
    Assinged_Ride_Status,
    MileStone,
    MileStone_Class,
    Milestone_Display_Label_Map,
    MileStone_Map,
    Milestone_Update_Confirmation,
} from 'src/app/models/assigned-trips.model';
import { TripsService } from '../../services/trips/trips.service';
import { ContactModalComponent } from '../../components/contact-modal/contact-modal.component';
import { FareEstimateRecalculationComponent } from '../../components/fare-estimate-recalculation/fare-estimate-recalculation.component';

export const MY_FORMATS = {
    parse: {
        dateInput: 'DD-MM-YYYY',
    },
    display: {
        dateInput: 'MMM DD',
        monthYearLabel: 'MMMM YYYY',
        dateA11yLabel: 'LL',
        monthYearA11yLabel: 'MMMM YYYY',
    },
};

declare const jQuery: any;
declare const Stripe: any;
declare const google: any;
const $: any = jQuery;

let emitRides: any = 0;
let markers = [];
// let ongoingDriverIds = [];
// let haightAshbury = { lat: 37.769, lng: -122.446 };
let gettingDrivers;
let rotation = 0;
// { preserveViewport: true}
let directionsDisplay = new google.maps.DirectionsRenderer();
let directionsService = new google.maps.DirectionsService();
let marker;
let map;
let bounds = new google.maps.LatLngBounds();
let zoomlevel;
let clear: any = 0;

type MAS_TRIP = { header: any; legs: Array<any> };
@Component({
    selector: 'app-live-tracking',
    templateUrl: './live-tracking.component.html',
    styleUrls: ['./common.component.scss', './live-tracking.component.scss', './live-tracking-dynamic.component.scss'],
    providers: [
        // `MomentDateAdapter` can be automatically provided by importing `MomentDateModule` in your
        // application's root module. We provide it at the component level here, due to limitations of
        // our example generation script.
        {
            provide: DateAdapter,
            useClass: MomentDateAdapter,
            deps: [MAT_DATE_LOCALE, MAT_MOMENT_DATE_ADAPTER_OPTIONS],
        },

        { provide: MAT_DATE_FORMATS, useValue: MY_FORMATS },
        { provide: 'rangeMax', useValue: 7 },
        { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: MaxRangeSelectionStrategy },
    ],
    animations: [
        trigger('onOff', [
            transition(':enter', [
                style({
                    opacity: 1,
                    transform: 'translateY(-10%)',
                }),
                animate(400),
            ]),
        ]),
    ],
})
export class LiveTrackingComponent implements OnInit, AfterViewInit, OnDestroy {
    third_party_integration: 'MAS' = 'MAS';
    third_party_enabled: boolean = false;
    // mas_trips: Array<MAS_TRIP> = []; // contains paginated mas trips
    // all_mas_trips: Array<MAS_TRIP> = []; // contains all mas trips in paginated format
    mas_session_identifier: string;
    mas_list_loading: boolean = false;
    riderSearchForm: FormControl = new FormControl('');
    rider_loading: boolean = false;
    riderList: Array<any>;
    selected_rider: any;

    date_range_picker: FormGroup = new FormGroup({
        start: new FormControl(new Date()),
        end: new FormControl(new Date()),
    });

    // It just bypass the value to mas_range_picker, and trigger the event change, need it this way to integrate range and singular date together in business function.
    mas_date_picker: FormControl = new FormControl(new Date());

    rider_mas_trip_ranger_picker: FormGroup = new FormGroup({
        start: new FormControl(moment()),
        end: new FormControl(moment().add(1, 'day')),
    });

    mas_active_trip: any;
    mas_active_leg_trip: any;
    mas_trip_status: 'active' | 'cancelled' | 'deleted';
    is_mas_loading: boolean = false;

    mas_pickup_time: any;
    mas_pickup_time_24: any;
    mas_pickup_date: any;
    mas_dropoff_date: any;
    mas_invoice_number: any;
    mas_leg_cost: any;
    mas_total_leg_cost: any;
    mas_mileage: any;
    mas_leg_id: any;
    queue_filter_type: 'all' | 'live' = 'all';

    pickup_dropoff_distance: string;
    pickup_dropoff_eta: { text: string; value: number };

    active_assigned_trip: any;
    assigned_filter_list: Array<string> = ['all', 'dispatched', 'confirmed', 'declined', 'pending', 'completed', 'cancelled'];
    assigned_status_filter: AssignedStatusType = null;

    time_picker: { start: any; end: any } = {
        start: '',
        end: '',
    };

    fare_factor: number = 1; // value which will be shared for api parameters
    fare_factor_display: number = 1; // value which will be used to display the multiplier in ui.

    edit_mas_passenger_detail_form: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required]),
        mobile: new FormControl('', [Validators.required]),
    });
    edit_passenger_selected_country_iso: any = CountryISO.UnitedStates;

    regular_scheduled_trips: Array<any> = [];

    completedOngoingTrips: Array<any> = [];

    loadings: boolean;
    session_id: number;
    lastToastRef;
    // fare_show_button_label = '';
    active_fare_breakdown_flag: 'now' | 'later' = 'now';
    fare_breakdown: any = {};

    subAddresses: any = { list: [], selectType: '' };

    routeOptionForm: FormGroup = new FormGroup({
        avoidHighways: new FormControl(false),
        avoidTolls: new FormControl(false),
        avoidFerries: new FormControl(false),
    });
    routeOptionBackup: any;

    active_trip_data: any; // variable store the data of currently opened ride
    active_trip_tab: number = 0; // variable store the data of currently opened tab
    middleBarClosed: boolean = false;
    windowSize: { width?: number; height?: number } = {};
    ipadSize: boolean = false;
    mobileSize: boolean = false;
    leftSideClosed: boolean = false;
    premiumOption: FormGroup = new FormGroup({
        pet_friendly_car: new FormControl(false),
        plexiglass_partition: new FormControl(false),
        through_door_service: new FormControl(false),
    });
    premiumOption_Backup: any;

    activeOption: string;

    premium_option_confirmation = StaticDataConfig.PremiumOptionConfirmation;

    premiumOptionLabel: string;
    carScrollEnable: boolean = false;
    dateFiltered: boolean = false;
    cardLoading: boolean = false; // cardLoading state

    filterRideType: string = ''; // 0 = not any, 1 = scheduled, 2 = on-demand/regular

    health_policy_checkbox: boolean = true;
    newRideMode: boolean = false;

    completedRides: Array<Trip | any> = [];

    // Pagination Section
    total_rides: number = 0;
    total_pages: number = 0;
    current_page: number = 1;
    trip_to: number = 0;

    // In order to pass the value faster and ignore the wait for socket response
    total_scheduled_rides: number = 0;
    total_regular_rides: number = 0;
    // total_completed_rides: number = 0;

    scheduledrides: Array<Trip | any> = [];
    ridesPerPage = 50;

    availableFrom: number = 0;
    availableTo: number = 0;
    countAvailableCars: number = 0;
    hidePrevsAV: any;
    availableCurrentPage: number;
    hideNextAV: any;
    availableCarsPages: number;

    regular_rides: Array<Trip | any> = [];

    assigned_trips: Array<any> = [];
    queued_trips: Array<any> = [];
    all_queued_assigned_trips: Array<any> = [];

    classExpandnumber: number;
    classExpand: any;
    tripDetailMode: boolean;
    returnRideMode: boolean;

    qudos_essential: any;
    DisableSRR: boolean;
    ride_essential: any;

    user_name: string;
    user_mobile: string;
    user_image: string;
    car_name: string;
    showStatus: string;
    pickup_location_address: string;
    manual_destination_address: string;
    rideMissed: string;
    driver_name: string;
    driver_mobile: string;
    driver_image: string;
    driverNames: Array<any>;
    payment_date: any;
    tzName: string;
    offset: any;
    schedule_calender_accept_time: any;
    pickup_time_raw: any;
    trip_activity_loading: boolean = false;
    trip_activity: any[] = [];

    // driver1: string;
    // driver2: string;
    // driver3: string;
    // driverrec2: number;
    // driverrec3: number;

    searchCard: string = '';

    MapShow: any = true;
    searchLocation: string;
    searchDriver: string;
    driverEnable: any = 0;

    queued_driver_mode: 'confirmed' | 'interested' = 'confirmed';
    QueuedDriverShow: any = false;
    confirmed_driver_list: Array<any>;
    interested_driver_list: Array<any>;

    CarShow: any = false;
    DirectionShow: any;
    force: any;

    //TODO: fix all show flags with one singular variable
    UserShow: any = false;

    rideInFocus: any = {
        car_name: '',
        car_make: '',
        car_no: 0,
    };
    ride_status: any;
    ride_status_text: string;
    rideText: string;
    is_schedule: any;
    bookingDate: Date = new Date();
    rideDate: Date = null;
    rideDateText = moment().format('MMM DD');
    maxDate: Date = new Date();

    selected_driver: any;
    driverSearchControl: FormControl = new FormControl('');
    drivers: Array<any> = [];

    // left: any = {};
    selectedIndex: number; // 0, 1, 2
    carTypes: any = StaticDataConfig.CarTypes;
    carsOptions: Array<any> = StaticDataConfig.Primary_CarsDriverOptions;
    // carDriverOptions: Array<any> = StaticDataConfig.Primary_CarsDriverOptions;

    routeState: any = {
        id: null,
    };

    appliedPromoCode: string = '';

    isRideLoading: boolean = true;
    loadingLock: boolean = false;

    myQudosMode: any;
    UserQudosMode: any;
    AllQudosMode: any;

    searchQudosFave: string = '';

    corporateFavDriver: Array<any> = [];
    userFavDriver: Array<any> = [];
    all_drivers: Array<any> = [];
    corporateFareInfo: Array<any> = [];
    Drivers: Array<any> = [];
    Users: Array<any> = [];
    Cards: Array<Card> = [];
    RiderCards: Array<Card> = [];

    triptoCancel: string;
    doc: any = {};
    rebooking: any = {};
    DisableOnReschd: boolean;
    driverselect: boolean;
    closeAutocomplete: any = 1;
    stepTimeSelector: number;
    DisableOnload: boolean;
    promo_applied: any = 0;
    corporateCards: number;
    stepPaymentSelector: number;
    checked: boolean;
    riderCards: number;
    paymentForNow: number;
    DisbleOnBRN: boolean;
    paymentForLater: number;
    DisbleOnPFL: boolean;
    disableCRA: boolean;
    paymentAlert: any;
    invalid_card: boolean;
    DisableResnd: boolean;
    onInvalidOtp: boolean;
    userMobileToAdd: string;
    otpToAdd: string;
    userToAdd: string;
    now: Date;
    payment_timezone: string;

    initialRideView: boolean = true;
    initialRideTimeOut: Array<any> = [];

    settings: any = {
        RiderCardEnabled: true,
        CorpCardEnabled: true,
    };

    max_schedule_ride_days_count: any;
    buttonNowClicked = 0;
    buttonLaterClicked = 0;

    list15 = Array(15);
    recalculate_options: any = [];
    recalculate_state: any;
    dummy_recalculate_state: any;
    custom_recalculate_multiplier: boolean = false;
    minutesList: Array<number> = Array.from({ length: 60 }, (_, x) => x);

    hoursList: Array<{ value: string; title: string }> = Array.from({ length: 24 }, (_, x: number) => {
        const x_string = x < 10 ? '0' + x.toString() : x.toString();
        return { value: x_string, title: `${x_string} (${x % 12 || 12} ${x < 12 ? 'AM' : 'PM'} )` };
    });

    ridePage = 1;
    tabletScreen = 0;

    essential = 0;
    dash: string = 'enabled';

    defaultZoom = 13;
    driverZoom = 16;

    notFav = 0;

    booking: any = {
        user_mobile: localStorage.getItem('book-ride-for'),
        proceed: 0,
    };

    phoneNumber: FormControl = new FormControl(undefined);
    searchForm: FormControl = new FormControl();

    searchFlag = 0;
    searchString: string = '';

    dsearchFlag = 0;
    dsearchString = '';

    requestPending = 0;

    promoCountryCode: any = '+1-';

    userDetails: any;
    driverDetails: any;

    defaultTab: any;
    routeOffID: any;

    expand = 1;
    showim = 0;
    select: any = {};
    expand1 = 1;

    ongoingCurrentPage: any;
    scheduled: any;
    blocked = 1;

    driverModel: any;

    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    countryToSet: string = 'US';
    separateDialCode = false;
    countries = country_code;

    minDate: Date = new Date();
    maxDate_6_Day = moment(new Date()).add(6, 'days')['_d'];
    // last_7_days = moment(new Date()).add(7, 'days')['_d'];

    fareEstimatePriceLoading: boolean = false;
    recalculate_price_loading: boolean = false;

    send_invoice_session: any = null;

    cardOptions: StripeCardElementOptions = StripeCardOption;
    elementsOptions: StripeElementsOptions = StripeElementOption;

    cardForEmail: string = '';
    show_email_error: boolean = false;

    pickup_id_list: Array<string> = ['pickup', 'return-pickup', 'mas-pickup', 'mobile-pickup'];
    drop_id_list: Array<string> = ['drop', 'return-drop', 'mas-drop', 'mobile-drop'];

    // phone_number_group: new FormControl('', [Validators.required]),
    phone_no_modal: BsModalRef;
    @ViewChild('phone_no_modal') public phone_no_templateRef: TemplateRef<any>;

    @ViewChild(StripeCardComponent) stripeCard: StripeCardComponent;
    cardError: string = '';

    // @ViewChild('rideCancelButton') rideCancelButtonRef: ElementRef;
    @ViewChild('carShow') carshowPanelRef: ElementRef;
    @ViewChild('trip_data_list') tripDataListRef: ElementRef;
    @ViewChild('masDatePicker') masDatePickerRef;
    driverLoading: boolean = false;

    mas_date_filter_type: 'single' | 'range' = 'single';

    corporateAddOnValue: FormControl = new FormControl(0, [Validators.min(1), Validators.max(100)]);
    corporate_addon: any = 0;
    @ViewChild('modalInput') modalInput: any;
    @ViewChild('modalInputDiscountPin') modalInputDiscountPin: any;
    @ViewChild('modalInputDiscount') modalInputDiscount: any;
    ismultipliear: boolean = true;

    // Ride Discount
    idPinValidate: boolean = false;
    discountPin: FormControl = new FormControl(null, [Validators.min(3), Validators.max(4)]);
    rideDiscount: FormControl = new FormControl(null, [Validators.min(1), Validators.max(100)]);
    ride_discount: number = 0;

    constructor(
        private router: Router,
        private socketService: SocketioService,
        private httpService: HttpService,
        public utilityService: UtilityService,
        private cookieService: CookieService,
        private stripeService: StripeService,
        private validation: ValidationService,
        private masAssignService: MasAssignService,
        private userService: UserService,
        private modalService: BsModalService,
        private masQueueService: MasQueueService,
        private masService: MasService,
        private mapService: GoogleMapService,
        private tripsService: TripsService
    ) {}

    ngOnInit(): void {
        this.checkScreenWidth();
        // Subscribe to window resize event to dynamically update the screen width
        window.addEventListener('resize', () => this.checkScreenWidth());
        if (this.isMobileScreen) {
            this.activeMobileMenu = 'addRide';
        } else {
            this.activeMobileMenu = 'viewMap';
        }

        this.settings.bothEnabled = this.settings.RiderCardEnabled && this.settings.CorpCardEnabled;
        this.initialRideView = true;
        this.getCarDriverOptions();
        this.getPaymentMode();
        this.getHour();
        this.getDate();
        this.driverEnable = true;
        this.newRideModeSelect(true);
        this.getNewUsers();
        this.getCards();

        setTimeout(() => this.loadPickup(), 500);

        if (!this.cookieService.get('web_access_token')) {
            this.router.navigate(['/', 'corporate_login']);
        }

        this.driverModel = JSON.parse(localStorage.getItem('corporateModel'));
        // var newdriverModel: any = JSON.parse(localStorage.getItem('driverdata'));

        this.userDetails = [];
        this.driverDetails = {};

        if (this.driverModel) {
            this.userDetails.userName = this.driverModel.driver_name;
            this.userDetails.userImage = this.driverModel.driver_image;
            this.driverDetails.driver_image = this.driverModel.driver_image;
            this.driverDetails.driver_mobile = this.driverModel.driver_mobile;
            this.driverDetails.driver_location = ' New York';
            this.driverDetails.referral_code = this.driverModel.referral_code;
            this.driverDetails.corporate_id = this.driverModel.corporate_id;
            this.driverDetails.lat = this.driverModel.lat;
            this.driverDetails.lng = this.driverModel.lng;
            this.driverDetails.state = this.driverModel.state;
            this.driverDetails.city = this.driverModel.city;
            this.driverDetails.address = this.driverModel.address;
        } else {
            this.cookieService.delete('web_access_token');
            this.router.navigate(['/', 'corporate_login']);
        }
        this.select.selectedIndex = 3;

        this.monitorLiveData();

        if (localStorage.getItem('book-ride-for')) {
            this.booking = this.booking || {};
            this.booking.user_mobile = localStorage.getItem('book-ride-for');
            this.booking.user_name = localStorage.getItem('book-ride-user-name');
            let number = localStorage.getItem('book-ride-for').split('-');
            this.countryToSet = this.countries[number[0]] || this.countries;
            this.phoneNumber.setValue(JSON.parse(number[1]));
        }

        this.searchForm.valueChanges.pipe(debounceTime(800)).subscribe((searchValue) => {
            if (!this.loadingLock) this.isRideLoading = true;
            this.searchString = searchValue ? searchValue.trim() : '';

            // Completed ride search
            // if (this.selectedIndex === 0) {
            //     this.searchL(this.searchString, 2); // scheduled
            // }

            // // scheduled ride search
            // if (this.filterRideType === 'scheduled' || this.filterRideType === 'cancelled') {
            //     this.searchL(this.searchString, 0); // scheduled
            // }

            // // regular ride search
            // if (this.filterRideType === 'on-demand' || this.filterRideType === 'cancelled') {
            //     this.searchL(this.searchString, 1); // regular
            // }

            // if (this.selectedIndex === 2 && this.filterRideType === 'assigned') {
            //     this.get_assign_mas_roster_trip_info();
            // }

            if (this.selectedIndex < 2) {
                this.emitSocketRides();
            } else {
                this._fetchTripRoster();
            }
        });
        this.phoneNumber.valueChanges.subscribe((value) => {
            if (typeof value == 'object' && value) {
                this.promoCountryCode = `${value.dialCode}-`;
            }
            if (value) {
                this.booking.user_mobile = (value.number?.toString()?.includes('-') ? '' : value.dialCode + '-') + value.number;
                // this.checkName();
                this.getNewUsers(1, value);
            }
        });

        this.utilityService.newRideButtonChanges.subscribe((value) => {
            if (!value) {
                // clear saved ride request config.
                localStorage.removeItem('book-ride-for');
                localStorage.removeItem('book-ride-user-name');
                const mas_ride = sessionStorage.getItem('book_mas_trip');
                if (!mas_ride) this.newRideModeSelect();
            }
        });

        if (window.innerWidth <= 1200) {
            this.toggleIpadScreen(true);

            if (window.innerWidth <= 660) {
                this.toggleMobileScreen(true);
            }
        }

        this.third_party_enabled = this.driverModel.is_mas_dispatcher == 1 ? true : false;
        this.mas_session_identifier = this.utilityService.mas_session_identifier;
        // if (this.third_party_enabled) this.intiMasView();

        // We check for third_party_enabled inside the checkForActiveRide
        this.handleActiveRideView(false);

        this.utilityService.toggleMASUI.pipe(distinctUntilChanged()).subscribe(async (res) => {
            if (res) {
                this.third_party_enabled = true;
                this.initMasView();
            } else {
                this.third_party_enabled = false;
                this.date_range_picker.reset();
            }
        });

        this.date_range_picker.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (value.start && value.end) {
                if (this.selectedIndex === 2) {
                    if (this.filterRideType === 'alltrips') {
                        this.get_all_trips();
                    } else if (this.filterRideType === 'assigned') {
                        this.get_assign_mas_roster_trip_info();
                    } else if (this.filterRideType === 'queue-trip') {
                        this.get_queued_trips();
                    }
                } else {
                    this.emitSocketRides();
                }
            }
        });

        this.mas_date_picker.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            this.mas_date_filter_type = 'single';
            this.date_range_picker.patchValue({ start: new Date(value), end: new Date(value) });
        });

        this.getRiderList();
        this.riderSearchForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (value) value = value.trim();
            this.getRiderList(value);
        });

        this.utilityService.searchTripByRider.subscribe((data: { date_range: { start: Date; end: Date }; selected_rider: any }) => {
            if (!data.selected_rider) return;
            this.mas_date_filter_type = 'range';
            this.date_range_picker.patchValue(data.date_range);
            this.searchForm.patchValue(data.selected_rider?.user_name);
        });

        this.driverSearchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            value = value.trim();
            this.getDriver(value);
        });
        this.getDriver();

        this.subscriptionList$.add(
            this.masAssignService.mas_assigned_notification.subscribe((notification: Notification) => {
                if (notification.identifier && this.assigned_trips.find((trip) => trip?.mas_trip_id === notification?.identifier)) {
                    if (this.mas_active_trip && this.rideInFocus?.mas_trip_id === notification?.identifier) {
                        this.get_assign_mas_roster_trip_info(1, notification?.identifier, true);
                    } else {
                        this.get_assign_mas_roster_trip_info(1, '', true);
                    }
                }
            })
        );

        // highlight active trip again

        this.subscriptionList$.add(
            this.utilityService.highlightActiveTrip.subscribe(() => {
                this.handleActiveRideView(false);
            })
        );
    }

    // --------------------------------- MAS TRIP SECTION ---------------------------------

    private async initMasView() {
        this.mas_session_identifier = await this.utilityService.start_mas_session({ silent: true });
        this.setSelectedTab(2);
        this.is_mas_loading = true;
        this.date_range_picker.patchValue({ start: moment(), end: moment() });
    }

    // if cacheTrip is passed true then it'll ignore the mas trip api call and use the active mas trip as it is.
    public async open_mas_trip_details(trip: any, show_estimate_popup: boolean = false, useCacheTrip: boolean = false) {
        this.pickup_dropoff_distance = '';
        this.showDirection(1);
        this.health_policy_checkbox = false;
        this.fare_factor = 1;
        if (trip.session_id) {
            this.tripnumber = 'Trip #' + trip.session_id;
        } else {
            this.tripnumber = 'Unavailable';
        }
        if (this.active_trip_data === `scheduled-mas-trip-${trip?.mas_trip_id}` && !useCacheTrip) return;
        this.active_trip_data = `scheduled-mas-trip-${trip?.mas_trip_id}`;
        this.active_trip_tab = 0;
        this.showTripDetailCard();
        try {
            this.is_mas_loading = true;
            this.driver_image = '';
            // this.tripnumber = '';
            this.user_image = '';
            this.ride_status = null;
            this.rideText = '';
            this.car_name = '';

            let is_assigned_trip = false;
            this.rideInFocus = null;
            this.rideInFocus = trip;

            // Check if Assigned Trip
            this.active_assigned_trip = trip;
            if (trip?.sent_to) {
                is_assigned_trip = true;
                trip.is_assigned_trip = true;
                // this.active_assigned_trip = trip;
            }

            // auto select driver for fare estimate pop up
            if (trip?.sent_to) {
                this.selected_driver = {
                    driver_name: trip?.requested_driver_names,
                    driver_mobile: trip?.requested_driver_mobile,
                    driver_id: trip?.sent_to.toString(),
                };
                // this.selected_driver = this.drivers.find((x) => x?.driver_id.toString() === trip?.sent_to.toString()) || {};
                this.booking.driver_id = trip?.sent_to.toString();
                this.booking.driver_selected = 1;
            }

            /**
             * Check if trip is mas provided trip or manual mas trip
             * Need to fetch fresh mas trip
             */

            if (trip?.invoice_number) {
                // FETCH DATA from MAS, Use cached trip for mas fetch
                if (!useCacheTrip) {
                    this.mas_active_trip = await this.masService.fetch_mas_details(trip?.pickupDate, trip?.invoiceNumber?.toString());

                    trip = this.mas_active_trip?.trips.find((x: any) => x?.tripLegId?.toString() === trip?.tripLegId.toString());
                    this.mas_active_leg_trip = trip;

                    const pickup = new google.maps.LatLng(trip.pickupAddress?.lat, trip?.pickupAddress?.lon);
                    const dropoff = new google.maps.LatLng(trip.dropoffAddress?.lat, trip?.dropoffAddress?.lon);

                    const eta = await this.mapService
                        .get_eta({
                            origin: pickup,
                            destination: dropoff,
                            travelMode: google.maps.TravelMode.DRIVING,
                            transitOptions: {
                                departureTime: new Date(trip.pickup_datetime),
                            },
                        })
                        .pipe(first())
                        .toPromise();
                    this.mas_active_leg_trip.ride_estimate_time = eta?.value;
                    this.mas_active_leg_trip.ride_estimate_time_text = eta?.text;
                } else {
                    trip = this.mas_active_leg_trip;
                }

                if (is_assigned_trip) {
                    trip.pickupTime = moment(this.active_assigned_trip?.pickup_datetime).format('HHmm');
                    this.mas_active_leg_trip.pickupTime = moment(this.active_assigned_trip?.pickup_datetime).format('HHmm');
                }

                // MAS Trip Initialization
                this.user_name = `${trip?.firstName} ${trip?.lastName}`;
                this.booking.user_name = this.user_name;
                this.showStatus = trip?.status;
                this.pickup_location_address = `${trip?.pickupAddress?.address} ${trip?.pickupAddress?.address2}, ${trip?.pickupAddress?.city}, ${trip?.pickupAddress?.state} ${trip?.pickupAddress?.zip}`;
                this.manual_destination_address = `${trip?.dropoffAddress?.address} ${trip?.dropoffAddress?.address2}, ${trip?.dropoffAddress?.city}, ${trip?.dropoffAddress?.state} ${trip?.dropoffAddress?.zip}`;
                this.mas_active_leg_trip.pickup_location_address = this.pickup_location_address;
                this.mas_active_leg_trip.dropoff_location_address = this.manual_destination_address;

                this.strtloca = trip?.pickupAddress?.lat + ',' + trip?.pickupAddress?.lon;
                this.strtlocaObj = { lat: trip?.pickupAddress?.lat, lng: trip?.pickupAddress?.lon };

                this.mdes = trip?.dropoffAddress?.lat + ',' + trip?.dropoffAddress?.lon;
                this.mdesObj = { lat: trip?.dropoffAddress?.lat, lng: trip?.dropoffAddress?.lon };

                if (trip?.tripLegStatus === '0') this.mas_trip_status = 'cancelled';
                else if (trip?.tripLegStatus === '1') this.mas_trip_status = 'active';
                else if (trip?.tripLegStatus === '2') this.mas_trip_status = 'deleted';

                this.mas_trip_status = trip?.tripLegStatus === '1' ? 'active' : 'cancelled';
                this.mas_pickup_date = trip?.pickupDate;
                this.mas_invoice_number = trip?.invoiceNumber;
                this.mas_leg_cost = trip?.legCost;
                this.mas_mileage = trip?.legMileage;
                this.mas_leg_id = trip?.tripLegId;

                this.mas_dropoff_date = trip?.dropoffDate;
                const pickup_time = `${trip?.pickupTime.slice(0, 2)}:${trip?.pickupTime.slice(2, 4)}`;
                this.mas_pickup_time_24 = pickup_time + ':00';
                this.mas_pickup_time = moment(pickup_time, 'hh:mm a').format('hh:mm a');
                this.mas_active_leg_trip.pickup_time = this.mas_pickup_time;

                if (!trip?.recipientPhone) {
                    this.phoneNumber.reset();
                    // this.phone_no_modal = this.modalService.show(this.phone_no_templateRef, { ...this.utilityService.ngxModalOption });
                    this.open_phone_number_popup();
                    return;
                }
                this.user_mobile = '+1-' + trip?.recipientPhone?.replace(new RegExp(/[(]|[)]| |-/, 'gm'), '');
                this.booking.user_mobile = this.user_mobile;
            } else {
                // Manual Trip

                this.user_name = trip?.user_name;
                this.user_mobile = this.utilityService.reformat_mobile_no(trip?.user_mobile);

                this.booking.user_name = trip?.user_name;
                this.booking.user_mobile = this.user_mobile.split('-')[1] ? this.user_mobile.split('-')[1] : this.user_mobile;

                this.pickup_location_address = trip?.pickup_location;
                this.manual_destination_address = trip?.dropoff_location;

                this.strtloca = trip?.pickup_latitude + ',' + trip?.pickup_longitude;
                this.strtlocaObj = { lat: trip?.pickup_latitude, lng: trip?.pickup_longitude };

                this.mdes = trip?.dropoff_latitude + ',' + trip?.dropoff_longitude;
                this.mdesObj = { lat: trip?.dropoff_latitude, lng: trip?.dropoff_longitude };

                // Avoid if not needed
                this.mas_trip_status = 'active';
                this.mas_pickup_date = trip?.pickup_date;
                this.mas_invoice_number = '';
                this.mas_mileage = trip?.trip_mileage;
                this.mas_leg_id = 'N/A';

                const pickup_time = `${trip?.pickup_time.slice(0, 2)}:${trip?.pickup_time.slice(2, 4)}`;
                this.mas_pickup_time_24 = pickup_time + ':00';
                this.mas_pickup_time = moment(pickup_time, 'hh:mm a').format('hh:mm a');

                this.mas_active_trip = trip;
            }

            if (this.rideInFocus?.is_assigned_trip) {
                if (this.rideInFocus?.ride_milestone_text) {
                    this.showStatus = `Active | <span class="status-color ${this.rideInFocus?.ride_milestone !== 0 ? 'blue' : 'text-green'}">${
                        this.rideInFocus.ride_milestone_text
                    }</span>`;
                } else {
                    this.showStatus = ` Active | <span class="${Assigned_Status_Text_Class[this.rideInFocus?.assigned_status]} transform-capital"> ${
                        this.rideInFocus?.assigned_status
                    } </span> `;
                }
            } else {
                this.showStatus = `Active | <span class="text-green"> Queued </span> `;
            }

            const { trips: driver_trip_map } = await this.masAssignService.get_assign_driver_mas_trip(this.mas_leg_id).pipe(first()).toPromise();
            if (Array.isArray(driver_trip_map) && driver_trip_map?.length) {
                if (driver_trip_map[0]?.trip_leg_id.toString() === this.mas_leg_id.toString()) {
                    const driver_id = driver_trip_map[0]?.sent_to;
                    this.selected_driver = this.drivers.find((x) => x?.driver_id.toString() === driver_id?.toString()) || {};
                    this.booking.driver_id = driver_id;
                    this.booking.driver_selected = 1;
                } else {
                    this.selected_driver = {};
                }
            }

            this.booking.pickup_text = this.pickup_location_address;
            this.booking.drop_text = this.manual_destination_address;
            this.booking.manual_destination_latitude = this.mdesObj.lat;
            this.booking.manual_destination_longitude = this.mdesObj.lng;
            this.booking.current_latitude = this.strtlocaObj.lat;
            this.booking.current_longitude = this.strtlocaObj.lng;
            this.booking.pickup_location_address = this.pickup_location_address;
            this.booking.manual_destination_address = this.manual_destination_address;
            this.booking.latitude = this.strtlocaObj.lat;
            this.booking.longitude = this.strtlocaObj.lng;
            // this.calculate_total_leg_cost(this.mas_active_leg_trip);

            if (trip?.invoice_number) {
                this.mas_total_leg_cost = this.masService.calculate_total_leg_cost(
                    this.mas_active_leg_trip?.legMileage,
                    this.mas_leg_cost,
                    this.mas_active_leg_trip?.secondary_services
                );
                this.mas_active_leg_trip.mas_total_leg_cost = this.mas_total_leg_cost;
            }

            this.getDirections();
            this.getDirectionsForBooking();
            // Turn on loading lock
            this.booking.autoHitApi = 0;
            this.booking.proceed = 0;
            if (!show_estimate_popup) {
                this.getDataAndshowPopup({ healthCheck: false, loading: false, skip: false, addQueued: false }); // silent = true
            } else {
                this.getDataAndshowPopup();
            }
        } catch (e) {
            this.utilityService.toast('warning', e.message);
            console.error(e);
        } finally {
            this.is_mas_loading = false;
        }
    }

    open_mas_trip_info_popup(trips: any = this.mas_active_trip) {
        this.masService.open_mas_trip_info_popup({
            trips,
        });
    }

    calculate_total_leg_cost(trip: any) {
        let charges: number = Number(this.mas_leg_cost);
        let charge_S0215 = 0;
        let is_TN_exist: boolean = false;
        let leg_milege: number = Number(trip?.legMileage) || 0;
        if (trip && trip?.secondary_services) {
            trip?.secondary_services.forEach((service: any) => {
                if (service.service_procedure_code === 'A0100' || service.service_procedure_code === 'A0170')
                    charges += Number(service?.service_rate || '0');
                // if (service.service_procedure_code === 'S0215') charge_S0215 = Number(service?.service_rate);
                if (service.service_procedure_code === 'S0215') {
                    if (service?.service_procedure_code_modifier === 'TN') {
                        is_TN_exist = true;
                    }
                    charge_S0215 = Number(service?.service_rate);
                }
            });
        }
        if (!is_TN_exist) {
            if (leg_milege > 8) leg_milege -= 8;
            // if leg_milege is more than 8 then subtract 8 fron the number
            else charge_S0215 = 0; // else remove the multiplier
        }
        this.mas_total_leg_cost = charges + leg_milege * charge_S0215;
        this.mas_active_leg_trip.mas_total_leg_cost = this.mas_total_leg_cost;
    }

    update_assigned_trip(
        trip: any,
        driver: any,
        type: 'update' | 'unassign',
        operation: 'uncompleting' | 'uncancelling' | 'unassigning' | 'queuing back' = 'unassigning'
    ) {
        const config = {
            heading: `${_.startCase(operation)} a Trip`,
            description: `You are ${operation} this trip, please confirm`,
            leftButtonText: 'Cancel',
            rightButtonText: 'Yes',
        };
        if (type === 'update') {
            config.heading = 'Assigning a New Driver';
            config.description = 'You are assigning a new driver for this trip, please confirm.';
        }

        this.subscriptionList$.add(
            this.utilityService.confirm(config).subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.subscriptionList$.add(
                        this.masAssignService
                            .update_assigned_driver({
                                web_access_token: this.cookieService.get('web_access_token'),
                                driver_id: driver?.driver_id || '',
                                mas_trip_id: trip?.mas_trip_id,
                                request_type: type === 'update' ? 0 : 1,
                            })
                            .subscribe((res) => {
                                if (this.filterRideType === 'alltrips') {
                                    this.get_all_trips(1, trip.mas_trip_id);
                                } else if (this.filterRideType === 'assigned') {
                                    this.get_assign_mas_roster_trip_info(1, trip.mas_trip_id);
                                }
                            })
                    );
                }
            })
        );
    }

    update_queued_mas_trip(
        payload: {
            mas_trip_id: string | number;
            status?: number;
            ride_status?: number;
            car_type?: number;
            pickup_time?: any;
            third_party_amount?: number;
            gross_fare_amount?: number;
            user_toll?: any;
            will_call?: number;
            pickup_location?: string;
            pickup_latitude?: string;
            pickup_longitude?: string;
            dropoff_location?: string;
            dropoff_latitude?: string;
            dropoff_longitude?: string;
            ride_confirmed?: number;
            is_prepaid?: number;
            prepaid_amount?: number;
        },
        skipReload: boolean = false
    ) {
        console.log('queued trip payload', payload);
        this.subscriptionList$.add(
            this.masQueueService.edit_queue_mas_trip(payload).subscribe((res: { flag: number; log: string; error: any }) => {
                if (!res?.error) {
                    this.utilityService.toast('success', res.log);
                    // this.getQueuedTrips(this.trip?.mas_trip_id, true);
                    this.get_all_trips(1, payload?.mas_trip_id);
                }
            })
        );
    }

    handleUnCancelTrip(trip: any) {
        if (trip?.invoice_number) {
            this.update_assigned_trip(trip, {}, 'unassign', 'uncancelling');
        } else {
            trip.rider_status = 0;
            this.update_assign_mas_trip({ mas_trip_id: trip?.mas_trip_id, ride_status: 0 });
        }
    }

    get_trip_activity(mas_trip_id: number | string) {
        this.trip_activity_loading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id: mas_trip_id,
        };
        this.subscriptionList$.add(
            this.httpService
                .postJSON(environment.urlWC + 'get_trip_activity', params)
                .subscribe((data) => {
                    if (data) {
                        this.trip_activity = data.trip_activity;
                    }
                })
                .add(() => {
                    this.trip_activity_loading = false;
                })
        );
    }

    // Get QUEUED and ASSIGNED trips together.
    private get_all_trips(pageNo: number = 1, mas_trip_id: any = null, skipReload: boolean = false) {
        if (skipReload === false) {
            this.mas_list_loading = true;
            this.assigned_trips = [];
        }
        let payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: (pageNo - 1) * this.ridesPerPage,
            limit: this.ridesPerPage < 50 ? 50 : this.ridesPerPage,
            assign_status_filter: 0,
        };

        if (this.searchString) {
            // Apply rider based search
            payload.searchFlag = 1;
            payload.searchString = this.searchString;

            // apply driver based search
            // payload.driver_filter = 1;
            // payload.driver_searchString = this.searchString;
        }

        if (this.date_range_picker.value?.start && this.date_range_picker.value?.end) {
            payload = { ...payload, ...this._getDateTimeFilter() };
        }

        // Filter by
        // if (this.assigned_status_filter) {
        //     const trip_map = { confirmed: 1, declined: 2, pending: 3, completed: 4, cancelled: 5 };
        //     payload.assign_status_filter = trip_map[this.assigned_status_filter];
        // }
        this.masQueueService
            .get_queued_assigned_trip(payload)
            .subscribe((res: { count: number; trips: Array<any>; error: string }) => {
                if (res?.error) {
                    this.utilityService.toast('error', res.error);
                } else {
                    this.paginateRides(res?.count);
                    this.all_queued_assigned_trips = res?.trips || [];
                    // Data preprocessing
                    this.all_queued_assigned_trips = this.all_queued_assigned_trips.map((trip: any) => {
                        trip.pickup_datetime = new Date(
                            moment
                                .utc(trip?.pickup_date + ' ' + trip?.pickup_time)
                                .local()
                                .toString()
                        );

                        // Pre-Preperation for assigned trips
                        trip.assigned_status = this.masAssignService.get_assigned_status(trip);
                        if (trip?.is_assigned_trip) {
                            // trip.assigned_status = this.masAssignService.get_assigned_status(trip);

                            if (trip?.ride_status == Assinged_Ride_Status.CONFIRMED) {
                                trip.ride_milestone_text =
                                    trip?.ride_milestone === 5 ? 'Completed' : Milestone_Display_Label_Map[trip?.ride_milestone];
                                trip.ride_milestone_class = MileStone_Class[trip?.ride_milestone];

                                // trip.is_live_milestone_trip = trip?.ride_milestone <= 4;
                            }

                            if (
                                trip?.invoice_number &&
                                trip?.ride_status == Assinged_Ride_Status.CONFIRMED &&
                                ((moment().isSame(moment(trip?.pickup_datetime), 'day') &&
                                    moment(trip?.pickup_datetime).isBefore(moment().add(1, 'hour'))) ||
                                    trip?.ride_milestone > 0) &&
                                trip?.ride_milestone !== 6
                            ) {
                                trip.is_live_milestone_trip = true;
                                trip.next_milestone = trip?.ride_milestone + 1;
                                trip.next_milestone_text = MileStone_Map[trip?.next_milestone];
                                trip.ride_milestone_class = MileStone_Class[trip?.next_milestone];
                            }
                        }

                        // to use in mas trip reusable api calls
                        trip.tripLegId = trip?.trip_leg_id?.toString();
                        trip.invoiceNumber = trip?.invoice_number?.toString();
                        trip.pickupDate = trip?.pickup_date?.toString();

                        if (trip?.mas_trip_id === mas_trip_id) {
                            this.open_mas_trip_details(trip);
                        }

                        return trip;
                    });
                }
            })
            .add(() => {
                this.mas_list_loading = false;
                this.isRideLoading = false;
            });
    }

    private _getDateTimeFilter(): {
        date_filter: number;
        start_date: string;
        end_date: string;
        time_filter: 1;
        start_time: string;
        end_time: string;
    } {
        const { start, end } = this.date_range_picker.value;
        let start_date = moment(start).startOf('day');
        let end_date = moment(end).endOf('day');

        if (this.time_picker?.start && this.time_picker?.end && start_date.isSame(end_date)) {
            start_date = start_date.set({ hour: this.time_picker?.start, minute: 0, second: 0 });
            end_date = end_date.set({ hour: this.time_picker?.end, minute: 59, second: 59 });
        }

        return {
            date_filter: 1,
            start_date: moment.utc(start_date).format('YYYY-MM-DD'),
            end_date: moment.utc(end_date).format('YYYY-MM-DD'),

            time_filter: 1,
            start_time: moment.utc(start_date).format('HH:mm:ss'),
            end_time: moment.utc(end_date).format('HH:mm:ss'),
        };
    }

    private get_assign_mas_roster_trip_info(pageNo: number = 1, mas_trip_id: any = null, skipReload: boolean = false) {
        if (skipReload === false) {
            this.mas_list_loading = true;
            this.assigned_trips = [];
        }
        let payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: (pageNo - 1) * this.ridesPerPage,
            limit: this.ridesPerPage < 50 ? 50 : this.ridesPerPage,
            assign_status_filter: 0,
        };

        if (this.searchString) {
            // Apply rider based search
            // payload.searchFlag = 1;
            // payload.searchString = this.searchString;

            // apply driver based search
            payload.driver_filter = 1;
            payload.driver_searchString = this.searchString;
        }

        if (this.date_range_picker.value?.start && this.date_range_picker.value?.end) {
            payload = { ...payload, ...this._getDateTimeFilter() };
        }

        // Filter by
        if (this.assigned_status_filter) {
            const trip_map = { confirmed: 1, declined: 2, pending: 3, completed: 4, cancelled: 5 };
            payload.assign_status_filter = trip_map[this.assigned_status_filter];
        }
        this.masAssignService
            .get_assign_mas_roster_trip_info(payload)
            .subscribe((res: { count: number; trips: Array<any>; error: string }) => {
                if (res?.error) {
                    this.utilityService.toast('error', res.error);
                } else {
                    this.paginateRides(res?.count);
                    this.assigned_trips = res?.trips || [];
                    // Data preprocessing
                    this.assigned_trips = this.assigned_trips.map((trip: any) => {
                        trip.assigned_status = this.masAssignService.get_assigned_status(trip);

                        // to use in mas trip reusable api calls
                        trip.tripLegId = trip?.trip_leg_id?.toString();
                        trip.invoiceNumber = trip?.invoice_number?.toString();
                        trip.pickupDate = trip?.pickup_date?.toString();

                        if (trip?.ride_status == Assinged_Ride_Status.CONFIRMED) {
                            trip.ride_milestone_text = trip?.ride_milestone === 5 ? 'Completed' : Milestone_Display_Label_Map[trip?.ride_milestone];
                            trip.ride_milestone_class = MileStone_Class[trip?.ride_milestone];

                            // trip.is_live_milestone_trip = trip?.ride_milestone <= 4;
                        }

                        if (
                            trip?.invoice_number &&
                            trip?.ride_status == Assinged_Ride_Status.CONFIRMED &&
                            ((moment().isSame(moment(trip?.pickup_datetime), 'day') &&
                                moment(trip?.pickup_datetime).isBefore(moment().add(1, 'hour'))) ||
                                trip?.ride_milestone > 0) &&
                            trip?.ride_milestone !== 6
                        ) {
                            trip.is_live_milestone_trip = true;
                            trip.next_milestone = trip?.ride_milestone + 1;
                            trip.next_milestone_text = MileStone_Map[trip?.next_milestone];
                            trip.ride_milestone_class = MileStone_Class[trip?.next_milestone];
                        }

                        if (trip?.mas_trip_id === mas_trip_id) {
                            this.open_mas_trip_details(trip);
                        }

                        return trip;
                    });
                }
            })
            .add(() => {
                this.mas_list_loading = false;
                this.isRideLoading = false;
            });
    }

    public get_queued_trips(pageNo: number = 1) {
        this.mas_list_loading = true;
        this.queued_trips = [];

        let payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: (pageNo - 1) * this.ridesPerPage,
            limit: this.ridesPerPage < 50 ? 50 : this.ridesPerPage,
            location_filter: 0,
            date_filter: 0,
            live_trip: this.queue_filter_type === 'live' ? 1 : 0,
        };

        if (this.searchString) {
            payload.driver_filter = 1;
            payload.driver_searchString = this.searchString;
        }

        if (this.assigned_status_filter) {
            const trip_map = { confirmed: 1, declined: 2, pending: 3, completed: 4, cancelled: 5 };
            payload.assign_status_filter = trip_map[this.assigned_status_filter];
        }

        if (this.date_range_picker.value?.start && this.date_range_picker.value?.end) {
            payload = { ...payload, ...this._getDateTimeFilter() };
        }

        this.masQueueService
            .get_queued_trips(payload)
            .subscribe((res: { count: number; trips: Array<any>; error: string }) => {
                if (res?.error) {
                    this.utilityService.toast('error', res.error);
                } else {
                    this.paginateRides(res?.count);
                    this.queued_trips = res?.trips || [];

                    // this.queued_trips = this.queued_trips.map((trip: any) => {
                    //     trip.pickup_datetime = new Date(moment(trip?.pickup_date).format('YYYY-MM-DD') + ' ' + trip?.pickup_time);
                    //     return trip;
                    // });
                    this.queued_trips = this.queued_trips.map((trip: any) => {
                        // To display in html code
                        // trip.pickup_datetime = new Date(moment(trip?.pickup_date).format('YYYY-MM-DD') + ' ' + trip?.pickup_time);

                        // to use in mas trip reusable api calls
                        trip.tripLegId = trip?.trip_leg_id?.toString();
                        trip.invoiceNumber = trip?.invoice_number?.toString();
                        trip.pickupDate = trip?.pickup_date?.toString();

                        return trip;
                    });
                }
            })
            .add(() => {
                this.mas_list_loading = false;
                this.isRideLoading = false;
            });
    }

    edit_assigned_trip_time(trip: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                viewMode: 'time',
                bookingDate: trip?.pickup_datetime ? new Date(trip?.pickup_datetime) : new Date(),
                rightButtonText: 'Save',
                title: 'Edit Trip Time',
                date: trip?.pickup_datetime || new Date(),
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(DateTimePickerComponent, initialState);
        modalref.content.event.subscribe((res: { date: Date }) => {
            if (res.date) {
                const pickup_time = moment(new Date(res.date)).format('hh:mm a');

                this.masAssignService
                    .edit_assign_mas_trip({
                        mas_trip_id: trip?.mas_trip_id,
                        pickup_time,
                        car_type: trip?.car_type || 1,
                        third_party_amount: trip?.third_party_amount || 0,
                        will_call: trip?.will_call || 0,
                    })
                    .subscribe((res: { flag: number; log: string }) => {
                        if (res.flag === 2204) {
                            this.utilityService.toast('success', res.log);
                            this.get_assign_mas_roster_trip_info();
                            this.active_assigned_trip.pickup_datetime = new Date(moment(trip?.pickup_date).format('YYYY-MM-DD') + ' ' + pickup_time);
                            this.open_mas_trip_details(this.active_assigned_trip, false, true);
                        }
                    });
            }
        });
    }

    filter_assigned_status(status: AssignedStatusType) {
        if (status === this.assigned_status_filter) return;
        this.assigned_status_filter = status;
        this.get_assign_mas_roster_trip_info(1);
    }

    update_assigned_driver(driver: any, type: 'update' | 'unassign') {
        if (!this.mas_active_trip) return;

        const config = {
            heading: 'Unassigning a Driver',
            description: 'You are unassigning this trip, please confirm',
            leftButtonText: 'Cancel',
            rightButtonText: 'Yes',
        };
        if (type === 'update') {
            config.heading = 'Assigning a New Driver';
            config.description = `You are assigning ${driver?.driver_name} for this trip, please confirm.`;
        }

        this.utilityService.confirm(config).subscribe((event: { action: 'left' | 'right' }) => {
            if (event.action === 'right') {
                // const { driver, trip, type } = this.active_driver_temp;
                this.masAssignService
                    .update_assigned_driver({
                        web_access_token: this.cookieService.get('web_access_token'),
                        driver_id: driver?.driver_id || '',
                        mas_trip_id: this.active_assigned_trip?.mas_trip_id,
                        request_type: type === 'update' ? 0 : 1,
                    })
                    .subscribe((res: { flag: number; log: string }) => {
                        if (res.flag === 2202) {
                            this.utilityService.toast('success', res.log);

                            if (type === 'update') {
                                this.selected_driver = driver;
                                this.booking.driver_id = driver?.driver_id;
                                this.booking.driver_selected = 1;
                                this.assigned_status_filter = null;
                            } else {
                                this.selected_driver = null;
                                this.booking.driver_id = '';
                                this.booking.driver_selected = 0;
                                this.assigned_status_filter = null;
                                this.newRideModeSelect();
                            }
                            if (this.filterRideType === 'alltrips') {
                                this.get_all_trips(1, this.rideInFocus?.mas_trip_id);
                            } else if (this.filterRideType === 'assigned') {
                                this.get_assign_mas_roster_trip_info(1, this.rideInFocus?.mas_trip_id);
                            }
                        }
                    });
            }
        });
    }

    select_this_driver(driver: any) {
        if (this.filterRideType === 'assigned') {
            this.update_assigned_driver(driver, 'update');
        } else {
            this.selected_driver = driver;
            this.booking.driver_id = driver?.driver_id;
            this.booking.driver_selected = 1;
        }
    }

    assign_mas_driver(driver: any) {
        if (!driver) {
            return;
        }

        this.utilityService
            .confirm({
                heading: 'Assigning a Driver',
                description: `You are assigning ${driver?.driver_name} for this trip, please confirm.`,
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                const trip = this.rideInFocus;
                if (event.action === 'right') {
                    let assign_payload: any;

                    if (trip?.invoice_number) {
                        assign_payload = this.masAssignService.mas_trip_assign_driver_params(
                            this.mas_active_leg_trip,
                            driver,
                            'queued',
                            this.rideInFocus,
                            this.mas_active_leg_trip?.callerName !== this.mas_active_leg_trip?.rider_name
                        );
                    } else {
                        assign_payload = {
                            first_name: trip?.user_name,
                            last_name: '',
                            user_mobile: trip?.user_mobile,
                            pickup_date: trip?.pickup_date,
                            pickup_latitude: trip?.pickup_latitude,
                            pickup_longitude: trip?.pickup_longitude,
                            pickup_location: trip?.pickup_location,
                            dropoff_location: trip?.dropoff_location,
                            dropoff_latitude: trip?.dropoff_latitude,
                            dropoff_longitude: trip?.dropoff_longitude,
                            invoice_number: 0,
                            trip_leg_id: 0,
                            pickup_time: trip?.pickup_time,
                            driver_id: driver?.driver_id,
                            offset: new Date().getTimezoneOffset() * -1,
                            confirmed_driver_id: driver?.type === 'confirmed' ? driver?.driver_id : 0,
                            ride_estimate_time: this.pickup_dropoff_eta?.value,
                            miles: 0,
                            trip_leg_sequence: 0,
                            mas_trip_id: trip?.mas_trip_id,
                            ride_confirmed: trip?.ride_confirmed ? 1 : 0,
                            isUTC: 1,
                            car_type: trip?.car_type,
                        };
                    }

                    this.masAssignService.assign_driver(assign_payload).subscribe(async (res) => {
                        if (res.flag === 1322) {
                            this.utilityService.toast('success', 'Driver assigned successfully');
                            if (this.filterRideType === 'queued') this.filterRideType = 'assigned';

                            if (this.filterRideType === 'alltrips') {
                                this.get_all_trips(1, res?.mas_trip_id);
                            } else if (this.filterRideType === 'assigned') {
                                this.get_assign_mas_roster_trip_info(1, res?.mas_trip_id);
                            }
                            // this.all_mas_trips = (await this.masAssignService.filter_out_assigned_trip(this.all_mas_trips)) as [];

                            this.selected_driver = driver;
                            this.booking.driver_id = driver?.driver_id;
                            this.booking.driver_selected = 1;
                            this.showDirection(1);
                        } else if (res?.error) {
                            this.utilityService.toast('warning', res?.error);
                        }
                    });
                }
            });
    }

    recent_driver_alert_logs: Array<string | number> = [];
    send_driver_alert(mas_trip_id: number | string, driver_id: number | string) {
        this.recent_driver_alert_logs.push(`q:${mas_trip_id}:${driver_id}`);
        this.utilityService.loading = true;
        this.subscriptionList$.add(
            this.masQueueService
                .send_driver_alert({ mas_trip_id, driver_id, web_access_token: this.cookieService.get('web_access_token') })
                .subscribe(
                    (res: { flag: number; log: string; error: string }) => {
                        if (res?.flag === 1333) {
                            this.utilityService.toast('success', 'Request sent successfully');
                        } else if (res?.error) {
                            this.utilityService.toast('error', res?.error || 'Something went wrong');
                        } else {
                            this.utilityService.toast('warning', res?.log);
                        }

                        setTimeout(() => {
                            this.release_driver_log(mas_trip_id, driver_id);
                        }, 30 * 1000);
                    },
                    (err) => {
                        console.log(err);
                        this.utilityService.toast('error', err?.messsage || 'Something went wrong');
                        this.release_driver_log(mas_trip_id, driver_id);
                    }
                )
                .add(() => {
                    this.utilityService.loading = false;
                })
        );
    }

    release_driver_log(mas_trip_id: number | string, driver_id: number | string) {
        this.recent_driver_alert_logs = this.recent_driver_alert_logs.filter((log) => log !== `q:${mas_trip_id}:${driver_id}`);
    }

    open_queue_a_trip_modal() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                isMobilePopup: false,
            },
            class: 'bg-none modal-dialog-centered modal-dialog-large w-auto modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(QueueTripModalComponent, initialState);
        modalref.content.event.subscribe((res: { queued: boolean }) => {
            if (res?.queued) {
                this.get_queued_trips();
            }
        });
    }

    private get_mas_ride_details(all_mas_flag: boolean = false): Observable<any> {
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
        };
        if (all_mas_flag) {
            payload.all_trip_flag = 1;
        } else {
            payload.invoice_number = this.mas_invoice_number;
            payload.trip_leg_id = this.mas_leg_id;
        }
        return this.httpService.post(environment.urlC + 'ride_details', payload);
    }

    edit_mas_passenger() {
        this.edit_passenger_selected_country_iso = country_code[this.user_mobile.split('-')[0]]?.toLowerCase();
        this.edit_mas_passenger_detail_form.patchValue({
            name: this.user_name,
            mobile: this.user_mobile.split('-')[1],
        });
        this.utilityService.showModal('edit_mas_passenger_detail');
    }

    view_passenger_trip(rider_mobile: string) {
        this.userService.new_user_register(this.user_mobile, this.user_name, 0).subscribe((data) => {
            if (data?.user_id) {
                this.router.navigate(['/', 'corporate', 'riders', 'rider-detail', data?.user_id]);
            } else {
                console.log('RES => ', data);
            }
        });
    }

    update_mas_passenger_detail() {
        const passenger_detail: {
            mobile: {
                dialCode: string;
                number: string;
            };
            name: string;
        } = this.edit_mas_passenger_detail_form.value;

        this.user_name = passenger_detail.name;
        this.user_mobile = `${passenger_detail.mobile.dialCode}-${passenger_detail.mobile.number}`;
        this.booking.user_mobile = this.user_mobile;
        this.booking.user_name = this.user_name;
        this.utilityService.hideModal('edit_mas_passenger_detail');
        this.edit_mas_passenger_detail_form.reset();
    }

    openRanagePicker() {
        let self = this;
        setTimeout(() => {
            self.masDatePickerRef.open();
        }, 50);
    }

    onTimePickerChange(time_picker: Time_Picker) {
        if (this.time_picker.start === time_picker.start && this.time_picker.end === time_picker.end) return;
        this.time_picker = time_picker;

        if (this.filterRideType === 'alltrips') {
            this.get_all_trips();
        } else if (this.filterRideType === 'assigned') {
            this.get_assign_mas_roster_trip_info();
        } else if (this.filterRideType === 'queue-trip') {
            this.get_queued_trips();
        }
    }

    open_leg_cost_breakdown() {
        this.masService.open_leg_cost_breakdown({
            trips: this.mas_active_trip,
            trip_leg_id: this.mas_active_leg_trip?.tripLegId,
            toll: this.fare_breakdown?.estimated_tolls,
        });
    }

    // --------------------------------- MAS TRIP SECTION ---------------------------------

    open_recalculate_popup(recalculate_type: 'now' | 'later') {
        this.getMultiplier();
        this.recalculate_state = this.fare_breakdown;
        this.recalculate_state.recalculate_type = recalculate_type;
        if (this.fare_factor != 1) {
            this.get_new_fare_estimateion(1);
        }
        this.utilityService.showModal('recalculate_popup');
    }

    recalculate() {
        if (this.fare_factor === this.fare_factor_display && this.corporateAddOnValue.value == 0 && this.rideDiscount.value == 0) return;
        this.fare_factor = this.fare_factor_display;
        this.booking.estimated_fare_later = '';
        this.booking.estimated_fare = '';
        this.getFareEstimate(0, false);
        this.fare_factor_display = 1;
        this.custom_recalculate_multiplier = false;
        this.select_button_flag = false;
    }

    select_button_flag: boolean = false;
    toggle_recalculate_type() {
        this.custom_recalculate_multiplier = !this.custom_recalculate_multiplier;
        if (this.custom_recalculate_multiplier) this.select_button_flag = true;
        if (this.fare_factor_display === 1) return;

        this.fare_factor_display = 1;
        this.get_new_fare_estimateion(1);
    }

    public get_new_fare_estimateion(new_fare_factor: any) {
        this.select_button_flag = false;
        if (new_fare_factor < 0) return;
        this.sortPhoneNumber();
        this.recalculate_price_loading = true;
        let params = {
            is_scheduled: 0,
            pickup_latitude: this.booking.current_latitude,
            pickup_longitude: this.booking.current_longitude,
            destination_latitude: this.booking.manual_destination_latitude,
            destination_longitude: this.booking.manual_destination_longitude,
            promo_code: this.booking.promo_code,
            web_access_token: this.cookieService.get('web_access_token'),
            car_type: this.booking.car_type,
            user_mobile: this.promoCountryCode + this.booking.user_mobile,
            avoid_tolls: this.routeOptionForm.value['avoidTolls'] ? 1 : 0,
            fare_factor: new_fare_factor,
            corporate_addon: this.corporate_addon,
            ride_discount: this.ride_discount,
            avoid_highways: this.routeOptionForm.controls?.avoidHighways?.value ? 1 : 0,
            pickup_city: this.booking.pickup_city,
            pickup_country: this.booking.pickup_country,
        };

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            params['premium_options'] = premium_option;
        }
        this.httpService.postJSON(environment.urlC + 'fare_estimate', params).subscribe(
            (data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                if (data.flag != 101 && data.flag != 1316 && data.flag != 1317 && !(data.error || data.flag == 0)) {
                    const cars_option = data.estimated_fare;
                    const current_car_option: any = cars_option.find((option) => option.car_type === this.booking.car_type);
                    const recalculate_type = this.recalculate_state?.recalculate_type;
                    this.recalculate_state = current_car_option;
                    this.recalculate_state.recalculate_type = recalculate_type;
                    this.recalculate_price_loading = false;
                } else {
                    this.utilityService.toast('warning', 'Something went wrong');
                }
            },
            (error) => {
                this.utilityService.alert('error', 'Something went Wrong!');
                this.utilityService.loading = false;
            }
        );
    }

    // This flag is responsible for selecting first on-demand ride
    expand_disc_for_first_ride: boolean = true;
    view_assigned_trip: { id: number | string; date: Date };
    private handleActiveRideView(refetch = true) {
        // this.utilityService.loading = true;
        if (localStorage.getItem('assigned-trip-id')) {
            this.view_assigned_trip = JSON.parse(localStorage.getItem('assigned-trip-id'));
            this.mas_date_picker.patchValue(new Date(this.view_assigned_trip?.date), { emitEvent: false });
            this.date_range_picker.patchValue(
                {
                    start: new Date(this.view_assigned_trip?.date),
                    end: new Date(this.view_assigned_trip?.date),
                },
                { emitEvent: false }
            );

            this.selectedIndex = 2;
            this.filterRideType = 'assigned';

            setTimeout(() => {
                this.get_assign_mas_roster_trip_info(1, this.view_assigned_trip?.id, true);
            }, 0);
            localStorage.removeItem('assigned-trip-id');
            return;
        }

        if (refetch) {
            this.searchL('', this.selectedIndex);
            setTimeout(() => {
                this.loadPickup();
            }, 500);
        }

        this.routeOffID = false;
        if (localStorage.getItem('routeOff')) {
            this.routeOffID = localStorage.getItem('routeOff');
            this.session_id = this.routeOffID;
        }
        this.defaultTab = localStorage.getItem('defaultTab');
        localStorage.removeItem('defaultTab');

        this.setSelectedTab(1);
        if (this.defaultTab == 'reg') {
            // this.selectedIndex = 1;
            this.active_trip_data = 'active-socket-trip-' + this.session_id;
        } else if (this.defaultTab == 'sch') {
            // this.selectedIndex = 0;
            this.active_trip_data = 'active-socket-trip-' + this.routeOffID;
            this.active_trip_tab = 1;
            // } else if (this.third_party_enabled) {
        }
        //  else {
        //     // If no active ride found then init the mas view;
        //     // this.intiMasView();
        //     this.changeFilterRideType('alltrips');
        // }
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        this.windowSize = {
            width: event.target.innerWidth,
            height: event.target.innerHeight,
        };

        // ipad screen size
        if (this.windowSize.width <= 1200) {
            this.toggleIpadScreen(true);

            if (this.windowSize.width <= 660) this.toggleMobileScreen(true);
            else this.toggleMobileScreen();
        } else {
            // normal screen size
            this.toggleIpadScreen(false);
        }
    }

    toggleIpadScreen(switch_flag = false) {
        if (switch_flag) {
            if (!this.ipadSize) {
                this.ipadSize = true;
                this.middleBarClosed = true;
            }
        } else {
            this.ipadSize = false;
            this.middleBarClosed = false;
        }
    }

    toggleMobileScreen(switch_flag = false) {
        if (switch_flag) {
            if (!this.mobileSize) {
                this.mobileSize = true;
                this.leftSideClosed = true;
            }
        } else {
            this.mobileSize = false;
            this.leftSideClosed = false;
        }
    }

    open_middleBar() {
        if (this.ipadSize) {
            this.middleBarClosed = false;
            if (this.mobileSize && !this.leftSideClosed) {
                this.leftSideClosed = true;
            }
        }
    }

    open_leftSideBar() {
        if (this.mobileSize) {
            this.leftSideClosed = false;
            if (this.ipadSize && !this.middleBarClosed) this.middleBarClosed = true;
        }
    }

    findDistance(event) {
        let diff = $('#prefer_fav_dropdown').offset().top - $('.btn_cs').offset().top;
        if (diff > -185) {
            let ele = document.getElementById('prefer_ride_menu');
            setTimeout(() => {
                ele.style.top = -207 + 'px';
            }, 20);
        }
    }

    open_phone_number_popup() {
        const initialState: ModalOptions = {
            id: Date.now(),
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(PhoneNumberModalComponent, initialState);
        modalref.content.event.subscribe((phone_number: any) => {
            this.mas_active_leg_trip.recipientPhone = phone_number.number.toString();
            this.open_mas_trip_details(this.mas_active_leg_trip, false, true);
        });
    }

    updateOption(clear = false) {
        if (_.isEqual(this.premiumOption.value, this.premiumOption_Backup)) return;

        const po = this.premiumOption.value;

        if (clear) {
            this.resetPremiumOption();
        } else if (po['pet_friendly_car'] || po['plexiglass_partition'] || po['through_door_service']) {
            this.updatePremiumLabel('Added');
        } else {
            this.updatePremiumLabel();
        }
        this.getFareEstimate();
    }

    public cancelOption() {
        this.premiumOption.patchValue({ ...this.premiumOption_Backup });
    }

    private updatePremiumLabel(text?) {
        setTimeout(() => {
            if (text) this.premiumOptionLabel = 'Added';
            else this.premiumOptionLabel = null;
        }, 50);
    }

    private resetPremiumOption() {
        this.premiumOption.reset();
        this.updatePremiumLabel();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.socketListener();
        }, 0);
        this.mapInit();
    }

    public scrollTopCarShowPanel() {
        this.carshowPanelRef.nativeElement.scrollTop = 0;
    }

    public scrollTopTripDataList() {
        if (this.tripDataListRef) this.tripDataListRef.nativeElement.scrollTop = 0;
    }

    public scrollButtonEnable(event) {
        if (event.srcElement.scrollTop > 0) {
            this.carScrollEnable = true;
        } else {
            this.carScrollEnable = false;
        }
    }

    setSelectedTab(tab: number): void {
        // For Socket
        if (this.selectedIndex !== 2) {
            this.searchForm.reset('', { emitEvent: false });
        }

        this.ridesPerPage = 50;
        this.ride_status_text = '';

        // reset date filter
        this.dateFiltered = false;
        this.rideDateText = moment().format('MMM DD');
        this.rideDate = null;

        this.searchString = '';
        this.searchFlag = 0;

        // ping socket connection if completed tab is connected

        // reset to new ride request mode
        if (!this.newRideMode && (tab !== this.selectedIndex || this.filterRideType) && !this.mobileSize) {
            this.utilityService.showNewRideButton(false);
        }

        this.selectedIndex = tab;
        this.changeFilterRideType('alltrips');
        // if (this.selectedIndex === 0) {
        //     this.changeFilterRideType('alltrips');
        // } else if (this.selectedIndex === 2) {
        //     this.changeFilterRideType('alltrips');
        // } else {
        //     this.filterRideType = '';
        //     this.searchL('', 0);
        //     this.searchL('', 1);
        // }
        this.is_mas_loading = false;

        this.scrollTopTripDataList();
    }
    // setupSocketConnection() {
    //   this.socketService.connect();
    //   // this.socketService.emit('');
    // }

    public onRideFilter(date: any) {
        // for queued and assigned filter. when third party api is disabled
        if (this.selectedIndex === 2) {
            console.log('In Condition onRideFilter');
            this.mas_date_picker.patchValue(new Date(date));
        }
        this.dateFiltered = true;
        this.rideDateText = moment(date).format('MMM DD');
        this.rideDate = new Date(date);
        this.mas_date_picker.patchValue(this.rideDate);
    }

    public confirmPremiumOption(option) {
        this.activeOption = option;
        this.utilityService.showModal('premiumOption_Confirmation');
    }

    public confirmPremiumOptionAction() {
        this.premiumOption.get(this.activeOption).patchValue(true);
        // setTimeout(() => {
        // this.activeOption = null;
        // }, 200);
    }

    public logout() {
        this.utilityService.logout();
    }

    // initial will be true if function is being called from ngonint.
    public newRideModeSelect(initial: boolean = false) {
        // reset map

        // this.carDriverOptions = StaticDataConfig.Primary_CarsDriverOptions;
        this.rideInFocus.session_id = '';
        this.mas_active_trip = null;
        this.active_assigned_trip = null;
        this.routeOptionForm.reset({
            avoidHighways: false,
            avoidTolls: false,
            avoidFerries: false,
        });
        directionsDisplay.setMap(null);
        this.routeState = {};
        // for ipadscreen
        this.open_middleBar();
        this.active_trip_data = null;
        this.health_policy_checkbox = true;
        globalThis.qudos_essential = 1;
        globalThis.dash = 'enabled';

        this.searchString = '';

        if (this.tabletScreen) {
            this.classExpand = 1;
        }
        this.booking.user_name = '';
        this.phoneNumber.reset();
        this.booking.pickup_text = '';
        this.booking.drop_text = '';
        this.booking.user_mobile = '';
        this.booking.current_latitude = '';
        this.booking.manual_destination_latitude = '';
        this.booking.started = 1;
        this.booking.car_type = this.booking.car_type;
        this.booking.returnRide = 0;
        this.booking.select_car_type = this.booking.car_type;
        this.booking.select_car_type = '1';
        this.booking.driver_id = '';

        this.tripDetailMode = false;
        this.newRideMode = true;
        this.returnRideMode = false;
        this.cleanDrivers();
        if (this.driverEnable) {
            this.enableDrivers(!initial);
        }
        this.ride_status_text = '';

        // Default show map view
        this.showDirection(1);
    }

    // Socket: integrate search for socket
    public searchL(data, id) {
        this.searchFlag = 0;
        this.cleanDrivers();
        if (data != '') {
            this.searchFlag = 1;
        }
        this.searchString = data;

        this.current_page = 1;
        this.emitSocketRides();

        // let payload: any = {
        //     corporate_id: this.driverDetails.corporate_id,
        //     limit: this.ridesPerPage,
        //     sort_by: '',
        //     sort_order: '',
        //     searchFlag: this.searchFlag,
        //     searchString: this.searchString,
        //     offset: (this.current_page - 1) * this.ridesPerPage,
        // };
        // if (id == 0) {
        //     payload.requestType = 5;
        //     this.socketService.emit('corporateScheduledRides', payload);
        // } else if (id == 1) {
        //     payload.requestType = 5;
        //     this.socketService.emit('corporateRegularRides', payload);
        // } else if (id == 2) {
        //     payload.requestType = 2;
        //     this.socketService.emit('corporateCompletedRequests', payload);
        // }
    }

    public collapseDisc() {
        this.classExpand = 0;
    }
    public onDateChange(date: any) {
        this.booking.date = moment(date).format('YYYY-MM-DD, dddd');
    }

    public showTripDetailCard() {
        this.tripDetailMode = true;
        this.newRideMode = false;
        this.returnRideMode = false;
        this.utilityService.showNewRideButton(true);

        // for ipadscreen
        this.open_middleBar();
    }

    public openTripDetails(data: any, type: string) {
        console.log('data', data);
        this.showDirection(1);
        this.selected_driver = null;
        this.fare_factor = 1;
        this.ride_status_text = '';
        this.initialRideView = true;
        const new_active_trip = `${type}-${data.session_id}`;
        if (new_active_trip === this.active_trip_data) return;
        this.mas_active_trip = null;
        this.active_assigned_trip = null;

        this.showTripDetailCard();
        // this.showTripDetailCard();
        this.showonm(data);
        this.expandDisc(data);
        this.check(data);
        this.active_trip_data = new_active_trip;
        this.driverNames = null;
    }

    public findIndex_from_objectArray(key: string, value: any, data: Array<Object[]>) {
        for (let i in data) {
            if (data[i][key] == value) return i;
        }
    }

    public isStringExist(str): boolean {
        if (str != null && str != '') return true;
        return false;
    }

    public check(daa) {
        this.search_session_id = undefined;
        setTimeout(() => {
            if (this.tripDetailMode) {
                if (this.hasDriver == 'YES') {
                    if (this.driverEnable) {
                    } else {
                        this.search_session_id = daa.session_id;
                        this.cleanDrivers(daa.driver_id);
                    }

                    this.socketService.emit('corporateDriverAvailablity', {
                        session_id: this.search_session_id,
                        limit: this.driverLimit,
                        offset: this.driverSkip,
                        searchFlag: this.dsearchFlag,
                        searchString: this.dsearchString,
                    });
                } else {
                    if (!this.driverEnable) {
                        this.disableDrivers();
                    }
                }
            }
        }, 10);
    }

    public get_ride_status(ride: any) {
        const { ride_status, request_status, is_active } = ride;
        const is_assigned_trip: number = ride?.is_assigned_trip;
        if (!is_assigned_trip) {
            switch (ride_status) {
                case 0:
                    if (request_status == 1) {
                        return 'Accepted';
                    } else if (request_status == 10 || (request_status == null && is_active == 0)) {
                        // req === 10 -> // return "Lapsed";
                        return 'Missed';
                    } else {
                        return 'Assigning';
                    }
                case 1:
                    return 'Picking Up';
                case 2:
                    return 'Arrived';
                case 3:
                    return 'En Route';
                case 4:
                    return 'Completed';
                case 5:
                case 6:
                case 7:
                case 9:
                case 11:
                    return 'Cancelled';
                case 8:
                    return 'Unsuccessful';
                default:
                    return ride_status + ' ' + request_status;
            }
        } else {
            // Assigned trip;
            return Milestone_Display_Label_Map[ride?.ride_milestone];
        }

        /**
        5 -> Cancelled By Driver;
        6 -> Cancelled By Rider;
        7 -> Cancelled By Rider;
        8 -> Unsuccessful Payment;
        9 -> Cancelled by Admin;
        11 -> Cancelled By Corporate;
        */
    }

    public getButtonClass(index: number, req: any, is_active: any): string {
        if (index === 0) {
            if (req == 1) return 'green';
            else if (req === 10 || (req === null && is_active === 0)) return 'black';
            else return 'blue';
        } else if (index >= 1 && index <= 4) return 'blue';
        else if ((index >= 5 && index <= 9) || index === 11) return 'black';
        else return index + ' ' + req;
    }

    public update_assigned_milestone(mas_trip_id: string, next_milestone: number) {
        // const { mas_trip_id } = this.rideInFocus;
        this.utilityService
            .confirm({
                heading: MileStone_Map[next_milestone],
                description: Milestone_Update_Confirmation[next_milestone],
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.utilityService.loading = true;
                    this.masAssignService
                        .update_ride_milestone(mas_trip_id, next_milestone)
                        .subscribe(
                            (res: { flag: number; message: string; error?: string }) => {
                                if (res?.flag === 923) {
                                    this.utilityService.toast('success', res?.message);
                                    this.refetch_live_milestone_trip();
                                    // // If last milestone call then complete the trip.
                                    // if (next_milestone === 5) {
                                    //     // this.update_assign_mas_trip({ mas_trip_id, ride_status: 4 });
                                    // } else {
                                    //     this.refetch_live_milestone_trip();
                                    // }
                                } else if (res?.error) {
                                    this.utilityService.toast('error', res?.error);
                                } else {
                                    this.utilityService.toast('info', res?.message);
                                }
                            },
                            (err) => {
                                this.utilityService.toast('error', err?.message || 'Something went wrong');
                            }
                        )
                        .add(() => {
                            this.utilityService.loading = false;
                        });
                }
            });
    }

    update_assign_mas_trip(payload: {
        mas_trip_id: number | string;
        ride_status?: any;
        // pickup_time?: string;
        // car_type?: number;
        // third_party_amount?: any;
        // user_toll?: any;
        // will_call?: number;
        // pickup_location?: string;
        // pickup_latitude?: string;
        // pickup_longitude?: string;
        // dropoff_location?: string;
        // dropoff_latitude?: string;
        // dropoff_longitude?: string;
    }) {
        this.masAssignService.edit_assign_mas_trip(payload).subscribe((res: { flag: number; log: string }) => {
            if (res.flag === 2204) {
                this.utilityService.toast('success', res.log);
                // this.refetch_live_milestone_trip();
                if (this.filterRideType === 'alltrips') {
                    this.get_all_trips(1, payload.mas_trip_id);
                } else if (this.filterRideType === 'assigned') {
                    this.get_assign_mas_roster_trip_info(1, payload.mas_trip_id);
                }
            }
        });
    }

    // Refetch Trip data after Milestone Updatae Could be called for Ongling Live trip or Assigned Trip
    refetch_live_milestone_trip() {
        if (this.mas_active_trip) {
            this.get_assign_mas_roster_trip_info(1, this.rideInFocus?.mas_trip_id, true);
        } else if (this.rideInFocus.session_id) {
            if (this.rideInFocus?.is_schedule) {
                this.searchL('', 0);
            } else {
                this.searchL('', 1);
            }
        }
    }

    driverLimit = 200;
    public showDirection(force: any) {
        this.DirectionShow = 1;
        this.MapShow = 1;
        this.CarShow = 0;
        this.UserShow = 0;
        this.QueuedDriverShow = false;

        if (force == 1) {
            this.force = 1;
        } else {
            this.force = 0;
        }
    }

    public carSelectMode() {
        this.MapShow = false;
        this.CarShow = true;
        this.UserShow = false;
        this.QueuedDriverShow = false;
    }

    public dirverSelectMode() {
        this.MapShow = false;
        this.CarShow = false;
        this.UserShow = true;
        this.QueuedDriverShow = false;
    }

    public checkName() {
        this.booking.proceed = 0;
        // setTimeout(() => {
        if (this.booking.user_mobile.length > 9) {
            if (this.Users) {
                this.Users.every((user) => {
                    // user.user_mobile.indexOf(this.booking.user_mobile) >= 0
                    if (user.user_mobile == this.booking.user_mobile) {
                        this.booking.user_name = user.user_name;
                        return false;
                    } else {
                        return true;
                    }
                });
            } else {
                // setTimeout(() => {
                //     this.checkName();
                // }, 400);
            }
        }
        // }, 500);
    }

    public swapLocation() {
        let pickup_address = this.booking.pickup_location_address;
        // let pickup_text = this.booking.pickup_text;

        let drop_address = this.booking.manual_destination_address;
        // let drop_text = this.booking.drop_text;

        let pickup_lat = this.booking.latitude;
        let pickup_long = this.booking.longitude;

        let drop_lat = this.booking.manual_destination_latitude;
        let drop_long = this.booking.manual_destination_longitude;

        this.booking.pickup_location_address = drop_address;
        this.booking.manual_destination_address = pickup_address;

        this.booking.pickup_text = drop_address;
        this.booking.drop_text = pickup_address;

        this.booking.manual_destination_latitude = pickup_lat;
        this.booking.manual_destination_longitude = pickup_long;

        this.booking.latitude = drop_lat;
        this.booking.longitude = drop_long;

        this.booking.current_latitude = drop_lat;
        this.booking.current_longitude = drop_long;

        this.getDirectionsForBooking();
    }
    public getDirectionsForBooking(proceed_for_fare_estimate: boolean = false) {
        if (this.booking.manual_destination_latitude && this.booking.current_latitude) {
            directionsDisplay.setMap(null);
            directionsDisplay.setMap(map);
            directionsDisplay.setPanel(document.getElementById('panel'));

            const origin = this.booking.current_latitude + ', ' + this.booking.current_longitude;
            const destination = this.booking.manual_destination_latitude + ', ' + this.booking.manual_destination_longitude;
            const request = {
                origin: origin,
                destination: destination,
                travelMode: google.maps.DirectionsTravelMode.DRIVING,
                ...this.routeOptionForm.value,
            };
            this.booking.polylines = 'NOT FOUND YET';
            directionsService.route(request, (response, status) => {
                if (status == google.maps.DirectionsStatus.OK) {
                    this.pickup_dropoff_distance = response.routes?.[0].legs?.[0]?.distance?.text;
                    this.pickup_dropoff_eta = response.routes?.[0].legs?.[0]?.duration;

                    directionsDisplay.setDirections(response);
                    this.booking.polylines = response.routes[0].overview_polyline;

                    if (proceed_for_fare_estimate) {
                        this.getFareEstimate(0, false);
                    }
                }
            });
        }
    }

    public selectCarType(carType: any, refetchFare?: boolean) {
        this.booking.car_type = carType?.car_type;
        this.booking.select_car_type = carType?.car_type;
        this.booking.driver_id = '';

        this.booking.estimated_fare = carType?.value_regular;
        this.booking.estimated_fare_later = carType?.value_scheduled;

        this.booking.car_selected = 1;

        if (this.booking.autoHitApi === 0) {
            if (carType.value_regular) {
                this.selectCarClassNext();
            }
        }

        if (refetchFare) {
            this.getFareEstimate(0, false);
        }
    }

    clearSearchUserQuery() {
        this.searchQudosFave = '';
        // this.searchlistuser = '';
        // this.searchalluser = '';
    }

    public carSelectorMode() {
        this.clearSearchUserQuery();
        if (this.checkInputValid()) {
            this.booking.autoHitApi = 0;
            this.booking.carSelected = 1;

            this.hitCheckMobile(1);
            this.forceShowDriver = 0;
            /*this.getFareEstimate();

      this.MapShow = false;
      this.CarShow = true;
      this.UserShow = false;*/
        }
    }

    public DriverSelectorMode() {
        this.clearSearchUserQuery();
        if (this.checkInputValid()) {
            this.booking.autoHitApi = 0;
            this.booking.carSelected = 0;

            this.myQudosMode = 1;
            this.driver_data_loading = true;
            this.corporateFavDriver = [];
            this.dirverSelectMode();
            this.hitCheckMobile(2);

            /*this.getFareEstimate();
            this.utilityService.loading = true;

            this.MapShow = false;
            this.CarShow = false;
            this.UserShow = true;*/
        }
    }

    private fetchUserMobile() {
        const phoneValue = this.phoneNumber.value;
        if (phoneValue) {
            const phoneNumberString = phoneValue.number.toString();
            this.booking.user_mobile = (phoneNumberString?.includes('-') ? '' : phoneValue.dialCode + '-') + phoneNumberString;
        } else if (this.newRideMode) {
            this.booking.user_mobile = '';
        }
    }

    openFareEstimatePopup(from?) {
        this.fare_factor = 1;
        $('.modal').modal('hide');
        this.initializeFareEsimateMap();
        $('#add_to_account').modal('show');
    }

    // Loading false will ignore the loading animation toggl, skip true will ignore the rider fetch operation
    public getDataAndshowPopup(
        payload: { healthCheck?: boolean; loading?: boolean; skip?: boolean; addQueued?: boolean } = {
            healthCheck: true,
            loading: true,
            skip: false,
            addQueued: false,
        }
    ) {
        // this.carDriverOptions = StaticDataConfig.Primary_CarsDriverOptions;
        this.resetPremiumOption();
        if (this.checkInputValid()) {
            // show health modal
            if (payload.healthCheck && !this.health_policy_checkbox) {
                // $('.modal').modal('hide');
                // $('#confirmCDCPolicy').modal('show');
                // TODO: require e2e testing
                this._confirm_cdc_policy().subscribe((event: { action: 'left' | 'right' }) => {
                    if (event.action === 'right') {
                        this.health_policy_checkbox = true;
                        this.getDataAndshowPopup(payload);
                    }
                });
            } else {
                if (payload.skip) {
                    if (this.isMobileScreen) {
                        this.fareEstimateRecalc();
                    } else {
                        this.openFareEstimatePopup('getDataAndshowPopup, 1447');
                    }
                } else {
                    $('.modal').modal('hide');
                    this.DisableSRR = true;
                    this.hitCheckMobile(0, payload.loading);
                    this.booking.autoHitApi = 1;
                    payload.addQueued ? (this.booking.addQueued = true) : (this.booking.addQueued = false);
                    setTimeout(() => {
                        this.DisableSRR = false;
                    }, 1000);
                }
            }
        }
    }

    private _confirm_cdc_policy() {
        return this.utilityService.confirm({
            heading: 'CDC Policy Compliance',
            description:
                'By clicking on the “Confirm” button, I confirm to adhere to the latest CDC policies to stay informed, review health and safety measures taken about COVID-19.',
            leftButtonText: 'Cancel',
            rightButtonText: 'Confirm',
        });
    }

    public getNewUsers(searchflag?: any, search_by_mobile_no: boolean = false) {
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 10,
            offset: 0,
        };

        const username = $('#userName').val();
        this.booking.user_name = username;

        // if (searchflag == 1 && search_by_mobile_no) {
        //     params.searchFlag = 1;
        //     params.searchString = this.booking.user_name;
        //     this.closeAutocomplete = 0;
        // }
        if (searchflag == 1) {
            params.searchFlag = 1;
            if (search_by_mobile_no) {
                params.searchString = this.booking.user_mobile;
            } else if (this.booking.user_name) {
                params.searchString = this.booking.user_name;
                this.closeAutocomplete = 0;
            }
        }

        this.httpService.post(environment.urlC + 'associated_user_list', params).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.flag == 101) {
                this.router.navigate(['/', 'corporate_login']);
            }
            if (data.flag == 502) {
            } else {
                this.Users = data.users;
                if (search_by_mobile_no) {
                    this.checkName();
                }
                $('.modal-backdrop.show').fadeOut();
            }
        });
    }

    public swapLocationforReturn() {
        let pickup_address, pickup_text, pickup_lat, pickup_long, drop_address, drop_text, drop_lat, drop_long;

        pickup_address = this.booking.pickup_location_address;
        pickup_text = this.booking.pickup_text_return;

        drop_address = this.booking.manual_destination_address;
        drop_text = this.booking.drop_text_return;

        pickup_lat = this.booking.latitude;
        pickup_long = this.booking.longitude;

        drop_lat = this.booking.manual_destination_latitude;
        drop_long = this.booking.manual_destination_longitude;

        this.booking.pickup_location_address = drop_address;
        this.booking.manual_destination_address = pickup_address;

        //this.booking.pickup_text_return =  drop_text;
        //this.booking.drop_text_return  = pickup_text;

        // $('#return-pickup').val(drop_address);
        // $('#return-drop').val(pickup_address);

        this.booking.pickup_text_return = drop_address;
        this.booking.drop_text_return = pickup_address;

        this.booking.manual_destination_latitude = pickup_lat;
        this.booking.manual_destination_longitude = pickup_long;

        this.booking.latitude = drop_lat;
        this.booking.longitude = drop_long;

        this.booking.current_latitude = drop_lat;
        this.booking.current_longitude = drop_long;

        this.getDirectionsForBooking();
    }

    public cancelReturn() {
        this.tripDetailMode = true;
        this.newRideMode = false;
        this.returnRideMode = false;
    }

    public returnscheduleRide(CurrentRide) {
        const datedata = this.booking.date.split(', ');

        this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes + ':00';

        if (CurrentRide.user_mobile.includes('-')) {
            this.promoCountryCode = CurrentRide.user_mobile.split('-')[0] + '-';
        }

        this.booking.started = 1;
        this.booking.returnRide = 1;
        this.booking.user_id = CurrentRide.user_id;
        this.booking.user_mobile = CurrentRide.user_mobile;
        this.booking.user_name = CurrentRide.user_name;
        this.booking.offset = CurrentRide.offset;
        this.booking.car_type = CurrentRide.car_type;
        this.booking.toll = '';
        this.booking.promo_code = '';
        this.booking.promo_value = '';
        this.booking.ride_estimate_distance = '';
        this.booking.ride_estimate_time = '';

        this.booking.manual_destination_latitude = CurrentRide.pickup_latitude;
        this.booking.manual_destination_longitude = CurrentRide.pickup_longitude;

        this.booking.drop_text_return = CurrentRide.pickup_location_address;
        this.booking.pickup_text_return = CurrentRide.manual_destination_address;

        this.booking.pickup_location_address = CurrentRide.manual_destination_address;
        this.booking.manual_destination_address = CurrentRide.pickup_location_address;
        this.booking.latitude = CurrentRide.manual_destination_latitude;
        this.booking.longitude = CurrentRide.manual_destination_longitude;
        this.booking.current_latitude = CurrentRide.manual_destination_latitude;
        this.booking.current_longitude = CurrentRide.manual_destination_longitude;

        this.notFav = 1;
        this.booking.driver_id = '';
        this.booking.select_car_type = 1;
        this.booking.driver_selected = 1;
        this.getDirectionsForBooking();
    }

    sendInvoiceOption(currentRide: any) {
        this.send_invoice_session = currentRide.session_id;
        this.utilityService.showModal('send_invoice_option');
    }

    sendInvoice(send_to: 0 | 1) {
        this.utilityService.sendInvoice(this.send_invoice_session, send_to);
    }

    public showReturn() {
        this.tripDetailMode = false;
        this.newRideMode = false;
        this.returnRideMode = true;
        this.health_policy_checkbox = true;
        this.routeOptionForm.reset({
            avoidHighways: false,
            avoidTolls: false,
            avoidFerries: false,
        });
        setTimeout(() => {
            this.loadPickup();
        }, 500);
    }

    public checkPhoneCountry() {
        if ($('#phone').length) {
            if ($('#phone').val().split('-').length > 1) {
                const phone_parts = $('#phone').val().split('-');
                $('#phone').val(phone_parts[1]);
                // var countryToSet = this.countries[phone_parts[0]] ? this.countries[phone_parts[0]] : 'US';
                // $("#phone").intlTelInput("setCountry", countryToSet);
                this.DisableSRR = false;
                setTimeout(() => {
                    this.checkName();
                }, 500);
            }
        }
    }

    public loadPickup() {
        $('html, body').animate({ scrollTop: 0 }, 'slow');
        this.booking.is_fav = 1;
        this.booking.offset = '330';
        this.booking.offset = new Date().getTimezoneOffset() * -1;

        this.checkPhoneCountry();

        /*this.map = new google.maps.Map(document.getElementById('map'), {
      zoom:12,
      mapTypeId: google.maps.MapTypeId.ROADMAP,
      center: {lat: 40.715818, lng: -73.963976},
     });*/

        let autocomplete = {};
        let autocompletesWraps = ['pickup', 'drop', 'return-pickup', 'return-drop', 'mas-pickup', 'mas-drop', 'mobile-pickup', 'mobile-drop'];
        // const inputNgModelRef = ['pickup_text', 'drop_text', 'pickup_text_return', 'drop_text_return'];

        $.each(autocompletesWraps, (index: number, name: string) => {
            if ($('#' + name).length == 0) {
                return;
            }
            autocomplete[name] = new google.maps.places.Autocomplete($('#' + name + '.autocomplete')[0]);

            google.maps.event.addListener(autocomplete[name], 'place_changed', async () => {
                const place = autocomplete[name].getPlace();
                if (place.types?.includes('airport')) {
                    const searchPayload = place.name + ' terminal';

                    const autoCompleteService = new google.maps.places.AutocompleteService();
                    await autoCompleteService.getPlacePredictions({ input: searchPayload }, async (result) => {
                        this.subAddresses.list = result.map((p) => {
                            return {
                                place_id: p.place_id,
                                main_text: p.structured_formatting?.main_text,
                                secondary_text: p.structured_formatting?.secondary_text,
                            };
                        });
                        this.subAddresses.selectType = name;
                        this.utilityService.showModal('subAddress_Option');
                        return;
                    });
                }
                if (!place.geometry) {
                    this.utilityService.alert('error', 'Address incomplete or invalid, Please select from the suggested locations!!');
                    return;
                }
                this.setDirectionBooking(name, place);
            });
        });
    }

    public selectSubaddress(address) {
        const placeService = new google.maps.places.PlacesService(document.createElement('div'));
        placeService.getDetails({ placeId: address.place_id }, (place) => {
            this.setDirectionBooking(this.subAddresses.selectType, place);
        });
    }

    public openRouteOptionModal() {
        this.utilityService.showModal('route_option');
        this.routeOptionBackup = this.routeOptionForm.value;
    }

    public updateRouteOption(status: 'use' | 'cancel' = 'use') {
        if (_.isEqual(this.routeOptionForm.value, this.routeOptionBackup)) return;

        if (status === 'cancel') {
            this.routeOptionForm.patchValue(this.routeOptionBackup);
        } else if (status === 'use') {
            this.getDirectionsForBooking();
        }
    }

    public setDirectionBooking(locationType, place) {
        if (this.pickup_id_list.includes(locationType)) {
            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();
            this.booking.current_latitude = latitude;
            this.booking.current_longitude = longitude;

            this.booking.pickup_location_address = place.formatted_address;

            this.booking.latitude = latitude;
            this.booking.longitude = longitude;
        }

        if (this.drop_id_list.includes(locationType)) {
            const latitude = place.geometry.location.lat();
            const longitude = place.geometry.location.lng();

            this.booking.manual_destination_latitude = latitude;
            this.booking.manual_destination_longitude = longitude;

            this.booking.manual_destination_address = place.formatted_address;
        }
        this.getDirectionsForBooking();
    }

    public reTryRide(CurrentRide) {
        this.qudos_essential = 1;
        this.dash = 'enabled';

        // if (this.selectedIndex == 2) {
        //   this.qudos_essential = 0;
        // }

        const datedata = this.booking.date.split(', ');

        this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes + ':00';

        if (CurrentRide.user_mobile.includes('-')) {
            this.promoCountryCode = CurrentRide.user_mobile.split('-')[0] + '-';
        }

        this.booking.started = 1;
        this.booking.returnRide = 1;
        this.booking.retryRide = 1;
        this.booking.user_id = CurrentRide.user_id;
        this.booking.user_mobile = CurrentRide.user_mobile;
        this.booking.user_name = CurrentRide.user_name;
        this.booking.offset = CurrentRide.offset;
        this.booking.car_type = CurrentRide.car_type;

        //this.booking.driver_id = CurrentRide.driver_id;
        this.booking.toll = '';

        if (!this.booking.promo_code) {
            this.booking.promo_code = '';
            this.booking.promo_value = '';
        }

        this.booking.ride_estimate_distance = '';
        this.booking.ride_estimate_time = '';

        this.booking.manual_destination_latitude = CurrentRide.manual_destination_latitude;
        this.booking.manual_destination_longitude = CurrentRide.manual_destination_longitude;

        this.booking.drop_text_return = CurrentRide.manual_destination_address;
        this.booking.pickup_text_return = CurrentRide.pickup_location_address;

        this.booking.pickup_location_address = CurrentRide.pickup_location_address;
        this.booking.manual_destination_address = CurrentRide.manual_destination_address;

        this.booking.latitude = CurrentRide.pickup_latitude;
        this.booking.longitude = CurrentRide.pickup_longitude;

        this.booking.current_latitude = CurrentRide.pickup_latitude;
        this.booking.current_longitude = CurrentRide.pickup_longitude;

        this.notFav = 1;
        this.booking.driver_id = '';
        this.booking.select_car_type = CurrentRide.car_type;
        this.booking.driver_selected = 1;

        this.getDirectionsForBooking();
    }

    public hitCheckMobile(clicked: any, loading: boolean = false) {
        this.booking.promo_code = undefined;
        this.sortPhoneNumber();
        this.utilityService.loading = false;
        if (loading) this.utilityService.loading = true;
        if (this.booking.proceed == 1) {
            if (this.isMobileScreen) {
                this.fareEstimateRecalc();
            } else {
                this.getFareEstimate(clicked, loading);
            }
            return false;
        }

        const user_mobile = this.promoCountryCode + this.booking.user_mobile;
        this.userService.check_mobile(user_mobile, this.booking.user_name).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.flag == 1316) {
                this.utilityService.loading = false;
                if (!this.booking.user_name) {
                    this.utilityService.alert('error', 'Please enter User Name.');
                    return 0;
                }
                if (this.mas_active_trip) this.createUserandProceed();
                else this.UserNotFoundScreen();
            } else if (data.flag == 1317) {
                this.utilityService.loading = false;
                //$rootScope.openToast('warning', data.error, '');
                this.booking.user_id = data.user_id;
                // this.shoUserAddScreen();
                this.addtoAccount();
                localStorage.removeItem('book-ride-for');
                localStorage.removeItem('book-ride-user-name');
            } else if (data.error) {
                this.utilityService.loading = false;
                this.utilityService.toast('error', data.error, '');
                return 0;
            } else {
                if (this.isMobileScreen) {
                    this.fareEstimateRecalc();
                } else {
                    this.getFareEstimate(clicked, loading);
                }
            }
        });
    }

    otpMode: any;
    public addtoAccount() {
        this.utilityService.loading = false;
        this.otpMode = 1;
        this.userToAdd = this.booking.user_id;
        this.userMobileToAdd = this.promoCountryCode + this.booking.user_mobile;
        this.otpToAdd = '';

        this.httpService
            .post(environment.urlC + 'associatedUser_send_otp', {
                web_access_token: this.cookieService.get('web_access_token'),
                mobile: this.promoCountryCode + this.booking.user_mobile,
                email: this.booking.user_id,
            })
            .subscribe((data) => {
                // $('#loading').hide();
                this.utilityService.loading = false;

                if (typeof data == 'string') data = JSON.parse(data);

                if (data.error || data.flag == 0) {
                    // this.utilityService.toast('error', data.error || data.message, '');
                    this.utilityService.alert('info', data.error || data.message);
                    // $('#add_myUser').modal('hide');
                    $('.modal-backdrop.show').fadeOut();
                    return;
                } else if (data.flag == 7) {
                    if (this.mas_active_trip) this.autoFillOTP();
                    else {
                        this.shoUserAddScreen();
                        this.utilityService.toast('success', data.message, '');
                    }

                    //alert("This user is not yet added to your list, an OTP is sent to ("+this.userMobileToAdd+") and need to be entered below for the new ride request to proceed");
                }
            });
    }

    public shoUserAddScreen() {
        $('#add_myUser').modal('show');
    }

    public showCardAlert() {
        this.utilityService.hideModalList(['request_right_now', 'request_right_now_1']);
        this.utilityService
            .confirm({
                heading: 'Add New Card',
                description: 'You need to add a card for the payment method.',
                rightButtonText: 'Add Card',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.clearStripeCard();
                    this.utilityService.showModal('add_card');
                }
            });
    }

    public UserNotFoundScreen() {
        // this.utilityService.showModal('no_User');
        // Confirm
        this.utilityService
            .confirm({
                heading: 'User is not registered with us',
                description:
                    'You can now send the ride request without signing the user with <b>“Proceed Anyway”</b> or you can register them through <b>“Add User”</b> for easy access for next booking.',
                leftButtonText: 'Add User',
                rightButtonText: 'Proceed Anyway',
                styleType: 'primary',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.createUserandProceed();
                } else if (event.action === 'left') {
                    this.createUser();
                }
            });
    }

    public checkInputValid(show_alert: boolean = true) {
        this.checkPhoneCountry();

        if (this.booking.returnRide !== 1 && this.booking.retryRide !== 1) {
            this.fetchUserMobile();
        }

        this.DisableSRR = false;

        if (!this.booking.user_mobile) {
            if (show_alert) this.utilityService.alert('info', 'Please enter Mobile number');
            return 0;
        }
        // else if (!this.booking.user_mobile) {
        //     if (show_alert) this.utilityService.alert('info', 'Please enter valid Mobile Number');
        //     return 0;
        // }
        else if ($('#pickup').val() != '' && !this.booking.current_latitude) {
            $('#pickup').val('');
            if (show_alert) this.utilityService.alert('info', "We couldn't locate your Pickup Location, Please select another.");
            return 0;
        } else if (!this.booking.current_latitude) {
            if (show_alert) this.utilityService.alert('info', 'Please enter Pickup Location.');
            return 0;
        } else if ($('#drop').val() != '' && !this.booking.manual_destination_latitude) {
            $('#drop').val('');
            if (show_alert) this.utilityService.alert('info', "We couldn't locate your Drop off Location, Please select another.");
            return 0;
        } else if (!this.booking.manual_destination_latitude || !this.booking.manual_destination_address) {
            if (show_alert) this.utilityService.alert('info', 'Please enter Destination.');
            return 0;
        } else {
            return 1;
        }
    }

    send_payment_link_to_rider(trip: any, type: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode: 'edit',
                actionButton: 'Send',
                title: trip?.paid ? 'Send Trip Info Link' : 'Send Payment Link to Rider ',
                showHeaderSuffixLabel: 'Check fare estimate?',
                contact: {
                    name: trip?.user_name,
                    mobile: trip?.user_mobile,
                },
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((action: { mobile: string; onSuffixHeaderClicked?: boolean }) => {
            if (action?.onSuffixHeaderClicked) {
                // this.book_mas_ride(trip, 'suggested_fare');
            } else {
                if (action.mobile?.includes('-')) action.mobile = action.mobile.replace('-', '');
                this.utilityService.loading = true;
                this.tripsService
                    .send_payment_link(action.mobile, trip?.mas_trip_id, type)
                    .subscribe((res) => {
                        if (res?.error) {
                            this.utilityService.toast('error', res?.error || res?.message);
                        } else {
                            this.utilityService.toast('success', res?.message);
                        }
                    })
                    .add(() => {
                        this.utilityService.loading = false;
                    });
                modalref.hide();
            }
        });
    }

    get_payment_link(mas_trip_id: string) {
        this.utilityService.loading = true;
        this.tripsService
            .get_payment_link(mas_trip_id)
            .subscribe((res) => {
                if (res?.error || !res?.referral_link) {
                    this.utilityService.toast('error', res?.error || res?.message || 'Something went wrong');
                } else {
                    window.open(res?.referral_link, '_blank');
                }
            })
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    public sortPhoneNumber() {
        this.booking.countrycode = this.promoCountryCode;
        if (this.booking.user_mobile.indexOf('+') >= 0) {
            this.booking.user_mobile = this.booking.user_mobile.replace(this.promoCountryCode, '');
        }
    }

    onCountryChange($event) {
        this.promoCountryCode = `+${$event.dialCode}-`;
    }

    routeMode: any;
    public searchD() {
        this.dsearchString = this.searchDriver;
        this.availableCurrentPage = 1;
        if (!this.searchDriver || this.searchDriver == '') {
            const newlatlng = new google.maps.LatLng(this.driverDetails.latitude, this.driverDetails.longitude);

            map.setCenter(newlatlng);
            map.setZoom(this.defaultZoom);
            this.dsearchFlag = 0;
        } else {
            this.cleanDrivers();

            this.dsearchFlag = 1;
            this.routeMode = 0;

            /*this.driverSearchAPI*/
        }

        this.socketService.emit('corporateDriverAvailablity', {
            limit: this.driverLimit,
            offset: this.driverSkip,
            searchFlag: this.dsearchFlag,
            searchString: this.dsearchString,
        });
    }

    public enableDrivers(toggle: boolean = true) {
        this.search_session_id = undefined;
        if (toggle) this.driverEnable = this.driverEnable ? 0 : 1;
        this.getLiveDrivers();
    }

    public getLiveDrivers() {
        if (this.driverEnable == 1) {
            this.socketService.emit('corporateDriverAvailablity', {
                session_id: this.search_session_id,
                limit: this.driverLimit,
                offset: this.driverSkip,
                searchFlag: this.dsearchFlag,
                searchString: this.dsearchString,
            });
            if (!gettingDrivers) {
                gettingDrivers = setInterval(() => {
                    this.socketService.emit('corporateDriverAvailablity', {
                        session_id: this.search_session_id,
                        limit: this.driverLimit,
                        offset: this.driverSkip,
                        searchFlag: this.dsearchFlag,
                        searchString: this.dsearchString,
                        liveDRIVER: 1,
                    });
                }, 8000);
            }
        } else {
            clearInterval(gettingDrivers);
            gettingDrivers = 0;
            if (this.tripDetailMode == false && this.returnRideMode == false) {
                this.cleanDrivers();
            }
        }
    }

    public showCar() {
        this.MapShow = 0;
        this.CarShow = 1;
        this.UserShow = 0;
        this.QueuedDriverShow = false;
    }

    public donothing() {}

    public showUser() {
        this.force = 0;
        this.DirectionShow = 0;
        this.MapShow = 0;
        this.CarShow = 0;
        this.UserShow = 1;
        this.QueuedDriverShow = false;
        this.scrollTopCarShowPanel();
    }

    showQueuedDrivers(tab_mode: 'confirmed' | 'interested' = 'confirmed') {
        this.QueuedDriverShow = true;
        this.UserShow = 0;
        this.MapShow = 0;
        this.CarShow = 0;
        this.DirectionShow = 0;
        this.queued_driver_mode = tab_mode;
    }

    getQueuedDrivers(mas_trip_id: string) {
        // tab_mode: 'confirmed' | 'interested' = 'confirmed'
        // this.QueuedDriverShow = true;
        // this.queued_driver_mode = tab_mode;
        this.driver_data_loading = true;
        this.masQueueService
            .get_queue_driver_info(mas_trip_id)
            .subscribe((data) => {
                this.confirmed_driver_list = data?.confirmed_drivers || [];
                this.interested_driver_list = data?.interested_drivers || [];
            })
            .add(() => {
                this.driver_data_loading = false;
            });
    }

    premiumOptionControl() {
        this.premiumOption_Backup = { ...this.premiumOption.value };
        $('#premiumOption').modal('show');
    }

    public showCredentialsAlert() {}

    cars_option: Array<any> = [];
    min_ride_request_distance: any;
    max_schedule_ride_request_distance: any;
    max_ride_request_distance: any;
    ride_estimate_distance: any;

    public async getFareEstimate(clicked = 0, loading: boolean = true) {
        this.fare_breakdown = {};
        this.getDriver();
        if (!this.checkInputValid()) {
            this.utilityService.loading = false;
            return;
        }
        localStorage.removeItem('book-ride-for');
        this.sortPhoneNumber();
        this.fareEstimatePriceLoading = true;
        const geocoder = new google.maps.Geocoder();
        if (this.booking.pickup_location_address || (this.booking.current_latitude && this.booking.current_longitude)) {
            const geoCoderPayload: { address?: string; location?: { lat: number; lng: number } } = {};
            if (this.booking.pickup_location_address) {
                geoCoderPayload.address = this.booking.pickup_location_address;
            }
            if (this.booking.current_latitude) {
                geoCoderPayload.location = {
                    lat: parseFloat(this.booking.current_latitude),
                    lng: parseFloat(this.booking.current_longitude),
                };
            }
            try {
                const geocoderResponse: any = await geocoder.geocode(geoCoderPayload);
                if (geocoderResponse.results.length) {
                    const place: any = geocoderResponse.results[0];
                    place.address_components.find((item) => {
                        if (item.types.includes('locality')) {
                            this.booking.pickup_city = item.long_name;
                        }
                        if (item.types.includes('country')) {
                            this.booking.pickup_country = item.long_name;
                        }
                    });
                }
            } catch (error) {
                this.booking.pickup_city = 'New York';
                this.booking.country = 'United States';
            }
        }

        let params = {
            is_scheduled: 0,
            pickup_latitude: this.booking.current_latitude,
            pickup_longitude: this.booking.current_longitude,
            destination_latitude: this.booking.manual_destination_latitude,
            destination_longitude: this.booking.manual_destination_longitude,
            promo_code: this.booking.promo_code,
            web_access_token: this.cookieService.get('web_access_token'),
            car_type: this.booking.car_type,
            user_mobile: this.promoCountryCode + this.booking.user_mobile,
            avoid_tolls: this.routeOptionForm.controls?.avoidTolls?.value ? 1 : 0,
            avoid_highways: this.routeOptionForm.controls?.avoidHighways?.value ? 1 : 0,
            fare_factor: this.fare_factor,
            pickup_city: this.booking.pickup_city,
            pickup_country: this.booking.pickup_country,
            corporate_addon: this.corporate_addon,
            ride_discount: this.ride_discount,
        };

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            params['premium_options'] = premium_option;
        }
        this.httpService.postJSON(environment.urlC + 'fare_estimate', params).subscribe(
            (data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                if (data.flag == 101) {
                    this.showCredentialsAlert();
                } else if (data.flag == 1316) {
                    this.utilityService.toast('warning', data.error, '');
                    this.booking.user_id = data.user_id;
                } else if (data.flag == 1317) {
                    this.utilityService.toast('warning', data.error, '');
                    this.shoUserAddScreen();
                    this.addtoAccount();
                    this.booking.user_id = data.user_id;
                    this.utilityService.loading = false;
                } else if (data.error || data.flag == 0) {
                    if (this.lastToastRef?.toastId) {
                        this.utilityService.removeToast(this.lastToastRef?.toastId);
                    }
                    this.lastToastRef = this.utilityService.toast('error', data.error || data.message, '');
                    this.utilityService.loading = false;
                    return;
                } else {
                    this.min_ride_request_distance = data.min_ride_request_distance;
                    this.max_schedule_ride_days_count = data.max_schedule_ride_days_count;
                    this.max_schedule_ride_request_distance = data.max_schedule_ride_request_distance * 1609.344;
                    this.max_ride_request_distance = data.max_ride_request_distance * 1609.344;
                    this.ride_estimate_distance = data.ride_estimate_distance;
                    if (this.ride_estimate_distance <= this.min_ride_request_distance) {
                        this.utilityService.toast('error', 'Ride Distance too short, Please choose another Destination', '');
                        return false;
                    } else if (this.ride_estimate_distance > this.max_ride_request_distance) {
                        this.utilityService.toast('error', 'Destination too far, Please choose another Destination', '');
                        return false;
                    }
                    if (clicked == 1) {
                        this.carSelectMode();
                    } else if (clicked == 2) {
                        this.dirverSelectMode();
                    }
                    this.cars_option = [];
                    const carsData = data.estimated_fare;
                    carsData.forEach((carInfo) => {
                        let d = carInfo;
                        let c: any = {};

                        if (this.carTypes[carInfo.car_name]) {
                            c = this.carTypes[carInfo.car_name];
                            d.carClass = c.className;
                            d.similar = c.similar;
                            d.max = c.max;
                            d.luggage = c.luggage;
                            d.value_regular = parseFloat(d.value_regular);
                            d.value_scheduled = parseFloat(d.value_scheduled);
                        }
                        this.cars_option.push(d);
                    });

                    this.carDriverOptions = carsData;

                    this.carsOptions = this.cars_option;

                    this.booking.user_id = data.user_id;
                    this.booking.user_name = data.user_name;
                    this.booking.ride_estimate_time = data.ride_estimate_time;
                    this.booking.ride_estimate_distance = data.ride_estimate_distance;
                    this.booking.toll = data.toll;
                    this.booking.route = data.route;

                    this.booking.route_image =
                        'https://maps.googleapis.com/maps/api/staticmap?&path=' +
                        this.booking.pickup_location_address +
                        '|' +
                        this.booking.manual_destination_address +
                        '&size=600x600&style=feature:landscape|visibility:on&style=feature:poi|visibility:on&style=feature:transit|visibility:on&style=feature:road.highway|element:geometry|lightness:39&style=feature:road.local|element:geometry|gamma:1.45&style=feature:road|element:labels|gamma:1.22&style=feature:administrative|visibility:on&style=feature:administrative.locality|visibility:on&style=feature:landscape.natural|visibility:on&scale=2&markers=shadow:false|scale:2|icon:https://s3.ap-south-1.amazonaws.com/qudosemail/pickup1.png|' +
                        this.booking.current_latitude +
                        ',' +
                        this.booking.current_longitude +
                        '&markers=shadow:false|scale:2|icon:https://s3.ap-south-1.amazonaws.com/qudosemail/drop_off1.png|' +
                        this.booking.manual_destination_latitude +
                        ',' +
                        this.booking.manual_destination_longitude +
                        '&path=geodesic:true|weight:3|color:0x2dbae4ff|enc:kn{wFf`abMCECCCCCCACCECCCCCECCCCCCAA?A?????????@@@@@@@@@@BBB@@TA@NFJHJHJHJJHHLFLb@z@GICCEG@BBB@@@@??????????????@@@@@@@@@BBDDDDDDDBDBBBB@BAPCBCDEFGJGJGJKPILILGHEFAPBDDFHHJLJLJNLPLPNRLPLNJLHJFHDFBDBBBBBBBBDFBBNRJNLNNRLNJLHJFHDFBDBB@@@@@@BB@BBBDDFHHJLNLNLNLPNPLNLPLNLNLNLNJLJLJLJLHJHJHJFHFHFHFHFHHHHJHJHJHJFHDFDFDFBD@B@@?@???????@??????????????????????????@@???????????????????@@@@@@@??BDBOFKBGBEBEBEDEBEDGDIDGBE@A&key=AIzaSyADXfBi40lkKpklejdGIWNxdkqQCKz8aKI';

                    this.booking.route_directions =
                        'https://maps.googleapis.com/maps/api/staticmap?size=350x600&path=color:0x00000cd0|weight:5|enc:' +
                        this.booking.polylines +
                        '&markers=shadow:false|scale:2|color:green|label:A|' +
                        this.booking.current_latitude +
                        ',' +
                        this.booking.current_longitude +
                        '&markers=color:red|label:B|shadow:false|scale:2|' +
                        this.booking.manual_destination_latitude +
                        ',' +
                        this.booking.manual_destination_longitude +
                        '&key=AIzaSyADXfBi40lkKpklejdGIWNxdkqQCKz8aKI';

                    // const current_car_option = this.cars_option[this.booking.car_type - 1];
                    const current_car_option: any = this.cars_option.find((option) => option.car_type === this.booking.car_type);
                    this.fare_breakdown = current_car_option;
                    if (this.mas_active_trip) {
                        this.mas_total_leg_cost += Number(this.fare_breakdown?.estimated_tolls || 0);
                    }
                    if (this.booking.autoHitApi) {
                        const fare = current_car_option?.value_regular;
                        const sche_fare = current_car_option?.value_scheduled;

                        this.booking.estimated_fare = fare;
                        this.booking.estimated_fare_later = sche_fare;

                        this.booking.estimated_fare_original = fare;
                        this.booking.estimated_fare_later_original = sche_fare;

                        const fare_old = current_car_option?.regular_ride_without_discount;
                        const sche_fare_old = current_car_option?.schedule_ride_without_discount;
                        this.booking.estimated_fare_old = fare_old;
                        this.booking.estimated_fare_later_old = sche_fare_old;

                        if (this.booking.driver_id) {
                            this.notFav = 0;
                            this.booking.select_car_type = '';
                        } else {
                            this.booking.driver_selected = 1;
                            this.notFav = 1;
                            this.booking.driver_id = '';
                            this.booking.car_selected = 1;
                            this.booking.select_car_type = this.booking.car_type;
                        }

                        if (this.booking.car_type) {
                            this.getDriversData(loading);
                        } else {
                            this.utilityService.loading = false;
                        }
                    } else {
                        const fare = current_car_option?.value_regular;
                        const sche_fare = current_car_option?.value_scheduled;

                        this.booking.estimated_fare = fare;
                        this.booking.estimated_fare_later = sche_fare;

                        this.booking.estimated_fare_original = fare;
                        this.booking.estimated_fare_later_original = sche_fare;
                        //this.booking.car_selected = 1;

                        const fare_old = current_car_option?.regular_ride_without_discount;
                        const sche_fare_old = current_car_option?.schedule_ride_without_discount;
                        this.booking.estimated_fare_old = fare_old;
                        this.booking.estimated_fare_later_old = sche_fare_old;

                        if (!this.booking.driver_id || this.booking.driver_id == '') {
                            this.booking.select_car_type = this.booking.car_type;
                        }
                        this.getDriversData(loading);
                    }
                    this.myQudosMode = 1;

                    if (!this.booking.promo_code) {
                        this.booking.promo_code = '';
                    }
                    if (data.promo_data.code) {
                        this.booking.promo_code = data.promo_data.code;
                    }
                    this.booking.promo_value = data.promo_data.value;
                }
                this.fareEstimatePriceLoading = false;
            },
            (error) => {
                this.utilityService.alert('error', 'Something went Wrong!');
                this.utilityService.loading = false;
            }
        );
    }

    fare_estimate_center: { lat: number; lng: number } = { lat: 40.73221, lng: -73.91902 };
    fare_estimate_zoom = 11;
    fare_estimate_directions_service: google.maps.DirectionsService = new google.maps.DirectionsService();
    fare_estimate_directions_display: google.maps.DirectionsRenderer = new google.maps.DirectionsRenderer();
    fare_Estimate_map: google.maps.Map;
    fare_trip_distance: string | number;
    fare_trip_duration: string | number;
    initializeFareEsimateMap() {
        console.log(this.routeOptionForm.controls?.avoidTolls?.value);
        if (!this.fare_Estimate_map) {
            this.fare_Estimate_map = new google.maps.Map(document.getElementById('fare_estimate_map'), {
                center: this.fare_estimate_center, //{ lat: -34.397, lng: 150.644 },
                zoom: this.fare_estimate_zoom,
                mapTypeId: google.maps.MapTypeId.TERRAIN,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                streetViewControl: false,
            });
        }
        this.fare_estimate_directions_display.setMap(this.fare_Estimate_map);
        // this.directionsDisplay.setPanel(document.getElementById('panel'));
        const request: google.maps.DirectionsRequest = {
            origin: `${this.booking.latitude},${this.booking.longitude}`,
            destination: `${this.booking.manual_destination_latitude},${this.booking.manual_destination_longitude}`,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: this.routeOptionForm.controls?.avoidTolls?.value,
            avoidHighways: this.routeOptionForm.controls?.avoidHighways?.value,
        };
        // this.polylines = 'NOT FOUND YET';
        this.fare_estimate_directions_service.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.fare_estimate_directions_display.setDirections(response);
                this.fare_trip_distance = response?.routes[0]?.legs[0]?.distance?.text;
                this.fare_trip_duration = response?.routes[0]?.legs[0]?.duration?.text;
            }
        });
    }

    driver_data_loading: boolean = false;
    public getDriversData(loading: boolean = false) {
        const datedata = this.booking.date.split(', ');

        this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes + ':00';

        this.myQudosMode = 1;
        this.UserQudosMode = 0;
        this.AllQudosMode = 0;

        this.booking.selectedTime = new Date();

        const timeInThatArea = this.booking.timeInThatArea
            ? new Date(this.booking.timeInThatArea)
            : new Date().toLocaleString('en-US', { timeZone: this.booking.timezone });
        this.booking.timeInThatArea = new Date(timeInThatArea);
        this.booking.timeInThatAreaLocale = new Date(timeInThatArea).toLocaleString();

        if (this.booking.user_id) {
            this.driver_data_loading = true;
            if (loading) this.utilityService.loading = true;
            this.httpService
                .post(environment.urlC + 'get_ride_data', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    user_id: this.booking.user_id,
                    region_id: 24,
                    car_type: this.booking.car_type,
                    is_essential: this.essential,
                })
                .subscribe(
                    (data) => {
                        if (loading) this.utilityService.loading = true;
                        if (typeof data == 'string') data = JSON.parse(data);

                        if (data.flag == 1317) {
                            this.utilityService.toast('warning', data.error, '');
                            this.shoUserAddScreen();
                            this.addtoAccount();
                        } else if (data.error || data.flag == 0) {
                            this.utilityService.toast('error', data.error || data.message, '');
                            // document.getElementById('card-errors').textContent = data.message;
                            this.cardError = data.message;
                            return;
                        } else {
                            this.corporateFavDriver = data.corporateFavDriver;
                            this.userFavDriver = data.userFavDriver;
                            this.all_drivers = data.all_drivers;
                            if (this.booking.autoHitApi) {
                                if (loading) {
                                    $('#add_to_account').modal('show');
                                    this.initializeFareEsimateMap();
                                    // this.openFareEstimatePopup('getDriversData 2304');
                                }
                            } else {
                                if (this.booking.carSelected == 0 || this.forceShowDriver) {
                                    this.showUser();
                                }
                            }
                            if (this.userFavDriver.length == 0) {
                            }
                        }
                        this.utilityService.loading = false;
                    },
                    (err) => {
                        this.utilityService.loading = false;
                        this.utilityService.alert('error', 'Something went wrong');
                    }
                )
                .add(() => {
                    this.driver_data_loading = false;
                });
        } else {
            // because it also set to true at getFareestimate
            this.driver_data_loading = false;
        }
    }

    // for far_estimate
    getDriver(searchString: string = '', type: 'mas' | 'all' = 'mas') {
        const params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: this.utilityService.MAS_Qudosfave_Map['Non-MAS Drivers'],
            searchFlag: searchString ? 1 : 0,
            searchString,
        };

        this.driverLoading = true;
        if (this.mas_active_trip) {
            params.tab_type = this.utilityService.MAS_Qudosfave_Map[this.mas_invoice_number ? 'MAS Drivers' : 'All Drivers'];
        }
        return this.httpService
            .post(environment.urlC + 'get_fav_driver', params)
            .subscribe((data: any) => {
                // if (res.count) this.drivers = res.drivers;
                if (data.flag !== 101 && data.flag !== 807) {
                    this.drivers = data?.drivers;
                }
            })
            .add(() => {
                this.driverLoading = false;
            });
    }

    forceShowDriver: any;
    public selectCarClassNext() {
        if (this.booking.car_type) {
            this.forceShowDriver = 1;
            this.getDriversData();
        } else {
            this.utilityService.alert('info', 'Please select a car first.');
        }
    }

    public slectNext() {
        if (this.booking.driver_id || this.notFav == 1) {
            // CDC confirm
            if (!this.health_policy_checkbox) {
                // this.utilityService.showModal('confirmCDCPolicy_moreinfo');
                this._confirm_cdc_policy().subscribe((event: { action: 'left' | 'right' }) => {
                    if (event.action === 'right') {
                        this.health_policy_checkbox = true;
                        this.slectNext();
                    }
                });
                return;
            }
            // $('.modal').modal('hide');
            // $('#add_to_account').modal('show');
            this.openFareEstimatePopup('slectNext 2343');
        } else {
            this.utilityService.alert('info', 'Please select a Driver.');
        }
    }

    public selectCarClass(type, fare, sche_fare) {
        this.booking.car_type = type;
        this.booking.select_car_type = type;
        this.booking.driver_id = '';
        this.booking.autoHitApi = 0;

        this.booking.estimated_fare = fare;
        //this.booking.estimated_fare_later = this.later_car_options[type-1].value;
        this.booking.estimated_fare_later = sche_fare;
        this.booking.car_selected = 1;
        this.selectCarClassNext();
    }

    private _fetchTripRoster() {
        if (this.filterRideType === 'alltrips') this.get_all_trips();
        if (this.filterRideType === 'queue-trip') this.get_queued_trips();
        if (this.filterRideType === 'assigned') this.get_assign_mas_roster_trip_info();
    }

    public changeFilterRideType(key: string) {
        this.filterRideType = key;
        this.current_page = 1;
        this.scrollTopTripDataList();

        // reset to new ride request mode
        if (!this.newRideMode && !this.mobileSize) {
            this.utilityService.showNewRideButton(false);
        }

        if (this.selectedIndex < 2) {
            // first tab
            this.isRideLoading = true;
            this.emitSocketRides();
        } else if (this.selectedIndex === 2) {
            // third tab
            this._fetchTripRoster();
        }

        // if (this.filterRideType === 'cancelled') {
        //     this.regular_scheduled_trips = [...this.scheduledrides, ...this.regular_rides];
        // }
        // if (this.filterRideType === 'scheduled' || this.filterRideType === 'cancelled') {
        //     this.searchL('', 0); // scheduled
        // }
        // if (this.filterRideType === 'on-demand' || this.filterRideType === 'cancelled') {
        //     this.searchL('', 1); // regular
        // }

        // if (this.filterRideType === 'assigned') {
        //     this.assigned_status_filter = null;
        //     this.get_assign_mas_roster_trip_info();
        // }

        // if (this.filterRideType === 'queue-trip') {
        //     this.get_queued_trips();
        // }

        // if (this.filterRideType === 'alltrips') {
        //     this.get_all_trips();
        // }
    }

    public switchMode(mode) {
        this.clearSearchUserQuery();
        if (mode == 'm') {
            this.myQudosMode = 1;
            this.UserQudosMode = 0;
            this.AllQudosMode = 0;
        } else if (mode == 'u') {
            this.myQudosMode = 0;
            this.UserQudosMode = 1;
            this.AllQudosMode = 0;
        } else {
            this.myQudosMode = 0;
            this.UserQudosMode = 0;
            this.AllQudosMode = 1;
        }
        this.scrollTopCarShowPanel();
    }

    public slect(driver, notfav) {
        this.booking.driver_id = driver;
        this.booking.driver_selected = 1;

        if (driver == '') {
            return false;
        }

        if (notfav) {
            this.notFav = 1;
            this.booking.driver_id = '';
            this.booking.select_car_type = driver;
            this.booking.car_type = driver;
            this.selected_driver = { driver_name: 'Random Driver' };
        } else {
            this.notFav = 0;
            this.booking.select_car_type = '';
        }

        // if (!this.booking.autoHitApi) {
        this.slectNext();
        // } else { }
    }

    public addToQudosFave(driver_id: any) {
        this.utilityService.loading = true;
        this.httpService
            .post(environment.urlC + 'add_fav_driver', {
                web_access_token: this.cookieService.get('web_access_token'),
                driver_id: driver_id,
                referral_code: '',
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                if (data.error) {
                    alert(data.error || data.message);
                    this.utilityService.loading = false;
                    return;
                } else {
                    this.utilityService.toast('success', 'Driver Added Successfully!', '');
                    setTimeout(() => {
                        this.utilityService.loading = false;
                        this.getDriversDatas();
                    }, 20);
                }
            });
    }

    public selectThisUser(id, num, name, email) {
        this.booking.user_id = id;
        this.booking.user_name = name;
        this.user_email = email;
        if (num.includes('-')) {
            const num_fraction = num.split('-');
            num = num_fraction[1];
            // this.selectedCountryISO = country_code[mobile.split('-')[0]]?.toLowerCase();
            this.countryToSet = country_code[num_fraction[0]?.toLowerCase()];
        }
        this.booking.user_mobile = num;
        this.phoneNumber.setValue(num);

        setTimeout(() => {
            this.checkPhoneCountry();
        }, 200);
        this.closeAutocomplete = 1;
    }

    public openAddUser() {
        setTimeout(() => {
            window.open('/corporate/riderSignup', '_blank');
        }, 0);
    }

    public closeAC(fast = 0) {
        if (fast == 1) {
            this.closeAutocomplete = 1;
        } else {
            setTimeout(() => {
                this.closeAutocomplete = 1;
            }, 250);
        }
    }

    carDriverOptions: Array<any> = StaticDataConfig.CarsDriverOptions;
    private getCarDriverOptions() {
        this.tripsService.get_car_type().subscribe((res) => {
            if (res?.data) {
                this.carDriverOptions = res?.data;
                this.booking.car_type = this.carDriverOptions?.[0]?.car_type;
            }
        });
    }

    public closePayment() {
        this.utilityService.loading = false;
        this.utilityService.hideModalList(['show_PaymentError', 'request_right_now', 'request_right_now_1']);
    }

    public showPaymentAlert() {
        this.utilityService.hideModalList(['request_right_now', 'request_right_now_1']);
        $('#show_PaymentError').modal('show');

        if (this.Cards.length > 0) {
            this.invalid_card = true;
        } else {
            this.invalid_card = false;
        }

        this.paymentAlert = 1;
    }

    totalCards: any;
    public getCards(from?: 'add_card') {
        this.cardLoading = true;

        this.httpService
            .post(environment.urlC + 'list_payment_cards', {
                web_access_token: this.cookieService.get('web_access_token'),
                user_id: this.booking.user_id,
            })
            .subscribe((data) => {
                if (typeof data == 'string') {
                    data = JSON.parse(data);
                } else {
                    data = data;
                }

                if (data.flag == 101) {
                    this.showCredentialsAlert();
                }

                if (data.flag != 807) {
                    this.totalCards = data.count;
                    this.Cards = [];

                    data.corporateCards.forEach((cardInfo) => {
                        if (cardInfo.default_card == 1) {
                            this.booking.cardIdCorporate = cardInfo.id;
                        }

                        if (Date.parse(cardInfo.card_added_on)) {
                            const dt = new Date(cardInfo.card_added_on);
                            dt.setMinutes(dt.getMinutes() - cardInfo.offset);
                            cardInfo.card_added_on = dt.toISOString();
                        }
                        this.Cards.push(cardInfo);
                    });

                    this.RiderCards = [];
                    data.userCards.forEach((card) => {
                        if (card.default_card == 1) {
                            this.booking.cardIdUser = card.id;
                        }

                        if (Date.parse(card.card_added_on) && card.offset) {
                            const dt = new Date(card.card_added_on);
                            dt.setMinutes(dt.getMinutes() - card.offset);
                            card.card_added_on = dt.toISOString();
                        }
                        this.RiderCards.push(card);
                    });

                    if (from === 'add_card' && this.Cards.length === 1) {
                        this.setDefaultCard(this.Cards[0]?.id, true);
                    } else {
                        this.cardLoading = false;
                    }

                    this.booking['cardSelected'] = 'corporate';
                    if (this.riderCards) this.showCardList('rider');
                    if (this.corporateCards) this.showCardList();
                }
            });
    }

    public paymentProcess() {
        this.httpService
            .post(environment.urlC + 'ride_payment_process', {
                web_access_token: this.cookieService.get('web_access_token'),
                card_user_type: this.booking.card_user_type,
                mobile: this.promoCountryCode + this.booking.user_mobile,
                user_id: this.booking.user_id,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag == 101) {
                    this.showCredentialsAlert();
                }
                if (data.error || data.flag == 0) {
                    this.utilityService.toast('error', data.error || data.message, '');
                    return;
                } else {
                }
            });
    }

    private showPaymentStep() {
        this.DisbleOnBRN = false;
        this.DisbleOnPFL = false;
        this.disableCRA = false;
        $('#payment_step').modal('show');
    }

    public timeSelected(when?) {
        this.booking.date = moment(this.bookingDate).format('YYYY-MM-DD, dddd');
        // $('#request_ride_later').hide();

        this.Cards = [];
        this.RiderCards = [];
        this.getCards();

        this.stepTimeSelector = 1;
        this.stepPaymentSelector = 1;
        this.corporateCards = 1;
        this.riderCards = 0;

        if (this.booking.card_user_type === 1) {
            this.booking.cardSelected == 'corporate';
            this.booking.card_user_type = 1;
            this.corporateCards = 1;
            this.riderCards = 0;
        } else if (this.booking.card_user_type === 0) {
            this.booking.cardSelected == 'rider';
            this.booking.card_user_type = 0;
            this.corporateCards = 0;
            this.riderCards = 1;
        } else {
            this.booking.cardSelected == 'corporate';
            this.booking.card_user_type = 1;
            this.corporateCards = 1;
            this.riderCards = 0;
        }
        //this.booking.cardOTP = '9999';

        if (when == 'now') {
            this.paymentForNow = 1;
            this.paymentForLater = 0;
        } else {
            this.paymentForNow = 0;
            this.paymentForLater = 1;
            $('#request_ride_later').modal('hide');
            if (!this.validateBookingData(this.booking.date, this.booking.time_hour, this.booking.time_minutes, this.validateDateTime())) {
                return false;
            }
        }

        /*** Check if both Rider and Corporate Cards are enabled?, If Yes, then only we will show payment step ****/
        if (this.settings.bothEnabled == true) {
            this.showPaymentStep();
        } else {
            this.utilityService.loading = true;
            setTimeout(() => {
                if (this.settings.CorpCardEnabled) this.booking.card_user_type = 1;
                else this.booking.card_user_type = 0;

                if (this.settings.CorpCardEnabled && !this.booking.cardIdCorporate) {
                    this.showCardList('corporate');
                    this.showPaymentStep();
                } else if (this.settings.RiderCardEnabled && !this.booking.cardIdUser) {
                    if (this.RiderCards.length === 0) {
                        this.showRiderCardError();
                    } else {
                        this.showCardList('rider');
                        this.showPaymentStep();
                    }
                } else {
                    if (when == 'now') {
                        this.BookRideRightNow();
                    } else {
                        this.BookRideLater();
                    }
                }
            }, 1000);
        }
    }

    private sorterFunc(car) {
        return parseFloat(car.value_regular);
    }

    private addZero(i) {
        if (i < 10) {
            i = '0' + i;
        }
        return i;
    }

    private validateDateTime() {
        const datedata = this.booking.date.split(', ');

        let today = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1; //January is 0!
        let yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        let dateToday = yyyy + '-' + mm + '-' + dd;

        if (datedata[0] === dateToday) {
            const d = new Date();
            const h = d.getHours();
            const m = d.getMinutes();

            let sm = m,
                sh = h;

            if (h != 23) {
                sh = sh + 1;
            } else if (h == 23) {
                sh = 0;
            }

            sm = this.addZero(sm);
            sh = this.addZero(sh);

            const hour_difference = sh - parseInt(this.booking.time_hour);
            const min_difference = sm - parseInt(this.booking.time_minutes);

            if (hour_difference == 0) {
                if (min_difference <= 0) {
                } else {
                    return 0;
                }
            } else if (hour_difference >= 1) {
                return 0;
            } else if (hour_difference <= 1) {
            }
        } else {
            const date1 = new Date(datedata[0]);
            const date2 = new Date(dateToday);
            const timeDiff = Math.abs(date2.getTime() - date1.getTime());
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (this.max_schedule_ride_days_count < diffDays) {
                return 8;
            }

            return 1;
        }
        return 1;
    }

    public validateBookingData(bookingDate: any, bookingTimeHour: any, bookingTimeMinutes: any, dateTimeValidation: any) {
        if (!bookingDate) {
            this.utilityService.alert('error', 'Please enter Date of travel');
            return 0;
        } else if (!bookingTimeHour || bookingTimeHour == '') {
            this.utilityService.alert('error', 'Please select Time.');
            return 0;
        } else if (!bookingTimeMinutes) {
            this.utilityService.alert('error', 'Please select Time minutes.');
            return 0;
        } else if (dateTimeValidation == 0) {
            this.utilityService.alert('error', 'Please select a minimum of one hour from now for scheduled ride or request an on-demand ride.');
            return 0;
        } else if (dateTimeValidation == 8) {
            this.utilityService.alert('error', 'You are allowed to book a scheduled ride for upto next 6 days only');
            return 0;
        } else {
            return 1;
        }
    }

    CorpCard: any;
    RiderCard: any;
    public getPaymentMode() {
        this.httpService
            .post(environment.urlC + 'list_card_payment_option', {
                web_access_token: this.cookieService.get('web_access_token'),
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag == 101) {
                    // this.showCredentialsAlert(); TODO:
                }

                if (data.payment_type[0].card_used == true) {
                    this.settings.CorpCardEnabled = true;
                    this.settings.RiderCardEnabled = true;
                    this.settings.bothEnabled = true;
                } else if (data.payment_type[0].card_used == 2) {
                    this.settings.CorpCardEnabled = true;
                    this.settings.RiderCardEnabled = false;
                    this.settings.bothEnabled = false;
                } else if (data.payment_type[0].card_used == 3) {
                    this.settings.CorpCardEnabled = false;
                    this.settings.RiderCardEnabled = true;
                    this.settings.bothEnabled = false;
                }
                if (this.settings.RiderCardEnabled == true && this.settings.CorpCardEnabled == true) {
                    this.settings.bothEnabled = true;
                } else {
                    this.settings.bothEnabled = false;
                }

                this.CorpCard = this.settings.CorpCardEnabled;
                this.RiderCard = this.settings.RiderCardEnabled;
            });
    }

    public getDate(newday?) {
        let today: any = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1; //January is 0!
        let yyyy: any = today.getFullYear();

        if (newday) {
            if (dd == 31 && (mm == 1 || mm == 3 || mm == 5 || mm == 7 || mm == 8 || mm == 10 || mm == 12)) {
                dd = 1;
                if (mm == 12) {
                    mm = 1;
                    yyyy = yyyy + 1;
                } else {
                    mm = mm + 1;
                }
            } else if (dd == 30 && (mm == 4 || mm == 6 || mm == 9 || mm == 11)) {
                dd = 1;
                mm = mm + 1;
            } else if (dd == 29 && mm == 2 && yyyy % 4 == 0) {
                dd = 1;
                mm = mm + 1;
            } else if (dd == 28 && mm == 2 && yyyy % 4 != 0) {
                dd = 1;
                mm = mm + 1;
            } else {
                dd = dd + 1;
            }
        }

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        let dateToday = yyyy + '-' + mm + '-' + dd;

        this.booking.date = dateToday;

        const weekday: Array<string> = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];

        const n = weekday[today.getDay()];

        this.booking.date = this.booking.date + ', ' + n;
    }
    public getHour() {
        this.bookingDate = new Date(this.bookingDate);
        this.bookingDate.setHours(this.bookingDate.getHours() + 1);

        // update the booking.date, to keep latest date value for case if date has been changed or not because of 1 hour interval addition
        this.onDateChange(this.bookingDate);

        this.booking.time_hour = this.addZero(this.bookingDate.getHours().toString());
        this.booking.time_minutes = this.bookingDate.getMinutes().toString();
    }

    public promoPopupShow() {
        this.utilityService.hideModalList(['request_right_now', 'request_right_now_1', 'show_PaymentError', 'showRiderCardError']);
        $('#promoCodeEnter').modal('show');
    }

    public timeScheduler() {
        // If trip type is MAS then skip date picker.
        if (
            this.selectedIndex === 2 &&
            this.mas_active_trip &&
            this.mas_trip_status === 'active'
            // && this.showStatus.includes('Unassigned')
        ) {
            // 2022-03-04 00:36:00
            this.booking.time = moment(this.mas_pickup_date).format('YYYY-MM-DD') + ' ' + this.mas_pickup_time_24;
            this.booking.date = moment(this.booking.time).format('YYYY-MM-DD, dddd');
            this.bookingDate = new Date(this.booking.time);
            this.booking.time_hour = moment(this.booking.time).format('HH');
            this.booking.time_minutes = moment(this.booking.time).format('mm');

            if (!moment(this.booking.time).isBefore(moment().add(1, 'hour'))) {
                this.timeSelected();
                return;
            }
        }
        this.utilityService.showModal('request_ride_later');
        this.bookingDate = new Date();
        this.stepTimeSelector = 1;
        this.stepPaymentSelector = 1;
        this.getHour();
        this.getDate();
    }

    public cancelAndUnselect() {
        // if (!this.booking.autoHitApi) {
        //     this.booking.driver_id = ''
        //     this.booking.car_type = 1
        //     this.booking.select_car_type = 1

        //     this.booking.driver_selected = 0;
        //     this.booking.car_selected = 0;
        //     // this.showCar();
        // }
        this.promo_applied = 0;
        this.booking.promo_code = undefined;
        // setTimeout(() => {
        //     this.updateOption(true);
        this.resetPremiumOption();
        // }, 200);
        $('.modal-backdrop.show').fadeOut();
    }

    public showConfirm() {
        $('#show_confirmation').modal('show', { backdrop: 'static', keyboard: false });
        this.booking.confirmNow = 1;
    }

    private showRescheduleConfirm() {
        $('#show_reschedule_confirmation').modal('show', { backdrop: 'static', keyboard: false });
    }

    public showCardList(cardType: 'corporate' | 'rider' = 'corporate') {
        this.booking.cardSelected = cardType;
        if (this.booking.cardSelected == 'corporate') {
            this.booking.card_user_type = 1;
            this.corporateCards = 1;
            this.riderCards = 0;
        } else if (this.booking.cardSelected == 'rider') {
            this.corporateCards = 0;
            this.riderCards = 1;
            this.booking.card_user_type = 0;
            if (this.RiderCards.length == 0) {
                this.showRiderCardError();
            }
        }
    }

    public showRiderCardError() {
        this.utilityService.hideModalList(['request_right_now', 'request_right_now_1', 'show_PaymentError', 'promoCodeEnter']);
        // $('#showRiderCardError').modal('show');
        this.utilityService
            .confirm({
                heading: 'Payment method option not available',
                description: `There is no available payment method option. Do you want to send a link to your Rider to add a payment method through
            Qudos Rider app or at rider portal at www.rideQudos.com to complete the ride Request.`,
                leftButtonText: 'Yes, Please Send Link',
                rightButtonText: `Add Rider's Card`,
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.clearStripeCard();
                    // TODO Add card
                    this.utilityService.showModal('add_card');
                } else if (event.action === 'left') {
                    this.sendRiderCardLink();
                }
            });
    }

    public getDriversDatas() {
        const datedata = this.booking.date.split(', ');

        this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes + ':00';

        this.myQudosMode = 1;

        this.UserQudosMode = 0;
        this.AllQudosMode = 0;

        this.booking.selectedTime = new Date();

        const timeInThatArea = this.booking.timeInThatArea
            ? new Date(this.booking.timeInThatArea)
            : new Date().toLocaleString('en-US', { timeZone: this.booking.timezone });
        this.booking.timeInThatArea = new Date(timeInThatArea);
        this.booking.timeInThatAreaLocale = new Date(timeInThatArea).toLocaleString();

        if (this.booking.user_id) {
            this.httpService
                .post(environment.urlC + 'get_ride_data', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    user_id: this.booking.user_id,
                    region_id: 24,
                    car_type: this.booking.car_type,
                    is_essential: this.essential,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);

                    if (data.flag == 1317) {
                        this.utilityService.toast('warning', data.error, '');
                        this.shoUserAddScreen();
                        this.addtoAccount();
                        this.utilityService.loading = false;
                        // this.$apply();
                    } else if (data.error || data.flag == 0) {
                        this.utilityService.toast('error', data.error || data.message, '');
                        return;
                    } else {
                        this.corporateFavDriver = data.corporateFavDriver;
                        this.userFavDriver = data.userFavDriver;
                        this.all_drivers = data.all_drivers;

                        if (this.booking.autoHitApi) {
                            this.utilityService.loading = false;
                            // $('#add_to_account').modal('show');
                        } else {
                            this.utilityService.loading = false;
                            if (this.booking.carSelected == 0 || this.forceShowDriver) {
                                this.showUser();
                            }
                        }

                        if (this.userFavDriver.length == 0) {
                        }
                    }
                });
        } else {
        }
    }

    public setDefaultCard(card_id, silent: boolean = false) {
        if (card_id) {
            this.httpService
                .post(environment.urlC + 'change_default_card', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    card_id: card_id,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;
                    if (data.error) {
                        this.utilityService.toast('error', data.error, '');
                        return;
                    } else {
                        this.getCards();
                        if (!silent) this.utilityService.toast('success', 'Default card updated', '');
                        this.Users = [];
                    }
                });
        }
    }
    public doNothing() {}

    public BookRideRightNow() {
        this.DisbleOnBRN = true;

        // this.booking.cardId = '';
        if (this.booking.card_user_type == 1) {
            // user corporate card
            this.booking.cardId = this.booking.cardIdCorporate;
        } else {
            // user rider card
            this.booking.cardId = this.booking.cardIdUser;
        }

        if (!this.booking.cardId) {
            if (this.settings.bothEnabled == true) {
                this.showPaymentAlert();
                setTimeout(() => {
                    this.DisbleOnBRN = false;
                }, 2500);
                return false;
            } else {
                // $('#show_PaymentError').modal('hide');
                // this.utilityService.alert('info', 'Please Add a new card.');

                this.showCardAlert();

                // this.utilityService.loading = false;
                // setTimeout(function(){$('#request_right_now').modal('hide');},300);
                setTimeout(() => {
                    this.DisbleOnBRN = false;
                }, 2500);
                return false;
            }
        } else {
            setTimeout(() => {
                this.DisbleOnBRN = false;
            }, 2500);
        }

        // if (this.buttonNowClicked === 1) {
        // 	alert('Please Wait while we complete the request for you!');
        // 	setTimeout(function(){
        // 		this.DisableOnload = false;
        // 	},1500)
        // 	return false;
        // }

        // $('#request_right_now').modal('show');

        // this.buttonNowClicked = 0;
        setTimeout(() => {
            this.DisbleOnBRN = false;
        }, 2500);
        this.requestRideAPI();
    }

    public resetForBookingOnError() {
        this.requestPending = 0;
        this.buttonLaterClicked = 0;
        this.buttonNowClicked = 0;
        this.utilityService.loading = false;
        $('#request_right_now').modal('hide');
        // this.resetCorporateAddOnAndRideDiscount();
    }

    getPremiumOption() {
        let po = this.premiumOption.value;
        const keys = Object.keys(po);
        let premium_option = {};
        let flag: boolean = false;
        keys.forEach((k) => {
            if (po[k]) {
                flag = true;
                premium_option[k] = 1;
            } else {
                premium_option[k] = 0;
            }
        });
        if (flag) return premium_option;
        return false;
    }

    public requestRideAPI() {
        this.DisableOnload = true;
        this.DisbleOnBRN = true;
        // this.booking.cardId = '';
        if (this.booking.card_user_type == 1) {
            this.booking.cardId = this.booking.cardIdCorporate;
        } else {
            this.booking.cardId = this.booking.cardIdUser;
        }

        const datedata = this.booking.date.split(', ');
        this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes;

        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            current_latitude: this.booking.current_latitude,
            current_longitude: this.booking.current_longitude,
            estimated_fare: this.booking.estimated_fare,
            ride_estimate_distance: this.booking.ride_estimate_distance,
            ride_estimate_time: this.booking.ride_estimate_time,
            pickup_location_address: this.booking.pickup_location_address,
            latitude: this.booking.latitude,
            longitude: this.booking.longitude,
            car_type: this.booking.car_type,
            manual_destination_latitude: this.booking.manual_destination_latitude,
            manual_destination_longitude: this.booking.manual_destination_longitude,
            manual_destination_address: this.booking.manual_destination_address,
            toll: this.booking.toll,
            route: '',
            promo_code: this.booking.promo_code,
            user_id: this.booking.user_id,
            offset: this.booking.offset,
            card_user_type: this.booking.card_user_type,
            card_id: this.booking.cardId,
            is_essential: this.essential,
            is_favt: this.booking.driver_id,
            third_party_ride: 0,
            fare_factor: this.fare_factor,
            pickup_city: this.booking.pickup_city,
            pickup_country: this.booking.pickup_country,
            congestion_charge: this.fare_breakdown.congestionCharge || 0,
            avoidHighways: this.routeOptionForm.controls?.avoidHighways?.value ? 1 : 0,
            avoid_tolls: this.routeOptionForm.controls?.avoidTolls?.value ? 1 : 0,
        };

        if (this.mas_active_trip) {
            params.third_party_ride = 1;
            const currentTrip = this.mas_active_trip?.trips?.find((x) => x.tripLegId === this.mas_leg_id);
            const mas_trip = Object.assign({}, this.mas_active_trip);
            mas_trip.trips = [currentTrip];
            mas_trip.trip_leg_id = this.mas_leg_id;
            params.third_party_info = mas_trip;
        }

        if (this.notFav) {
            params.is_fav = 0;
            params.select_car_type = this.booking.select_car_type;
        }

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            params.premium_options = premium_option;
        }

        if (!this.booking.card_user_type) {
            /*if(!this.booking.cardOTP){
        $('#rider_payment_otp').modal('show');
        return false;
      }*/
            params.otp = this.booking.cardOTP;
        }

        if (!this.booking.cardId) {
            this.requestPending = 0;
            this.resetForBookingOnError();
            this.utilityService.alert('info', 'Please select a valid card.');
            $('#request_right_now').modal('hide');
            setTimeout(() => {
                this.DisableOnload = false;
                this.DisbleOnBRN = false;
            }, 2000);
            return false;
        } else {
            setTimeout(() => {
                this.DisableOnload = false;
                this.DisbleOnBRN = false;
            }, 2000);
        }

        if (this.booking.card_user_type == 1) {
            this.booking.cardId = this.booking.cardIdCorporate;
        } else {
            this.booking.cardId = this.booking.cardIdUser;
        }
        params.card_id = this.booking.cardId;

        this.buttonNowClicked = 1;
        $('#request_right_now').modal('show');
        // $('#add_to_account').modal('hide');

        /** Adding Corporate Add on Param  */
        if (this.corporateAddOnValue.value != 0) {
            params.estimated_fare = this.booking.estimated_fare;
            params.corporate_addon = this.corporate_addon;
        }
        if (this.rideDiscount.value) {
            params.ride_discount = this.ride_discount;
        }
        this.httpService.postJSON(environment.urlC + 'request_ride', params).subscribe(
            (data) => {
                this.fare_factor = 1;
                if (typeof data == 'string') data = JSON.parse(data);
                if (data.flag == 101) {
                    this.resetForBookingOnError();
                    this.showCredentialsAlert();
                    this.utilityService.loading = false;
                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);
                } else if (data.flag == 303) {
                    $('#request_right_now').modal('hide');
                    this.resetForBookingOnError();

                    this.showCardAlert();
                    this.utilityService.loading = false;
                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);
                } else if (data.error && data.flag == 213) {
                    $('#request_right_now').modal('hide');
                    this.resetForBookingOnError();
                    this.utilityService.hideModalList([
                        'request_right_now',
                        'request_right_now_1',
                        'rider_payment_otp',
                        'payment_step',
                        'show_confirmation',
                    ]);
                    this.utilityService.loading = false;
                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);
                    this.utilityService.toast('error', data.error || data.message, '');
                    return;
                } else if (data.flag == 215) {
                    this.utilityService.hideModalList(['request_right_now', 'payment_step']);
                    this.utilityService.toast('error', data.log, '');
                } else if (data.flag == 906) {
                    this.utilityService.loading = false;
                    this.resetForBookingOnError();
                    this.showPaymentAlert();
                    // this.$apply();
                    // alert(data.error );
                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);
                    // this.openToast('error', data.error || data.message, '');
                } else if (data.error && data.flag == 921) {
                    this.resetForBookingOnError();

                    $('#request_right_now').modal('hide');
                    this.utilityService.loading = false;
                    // alert(data.error);
                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);
                    this.utilityService.alert('error', data.error, '');
                    //this.showPaymentAlert();
                    return;
                } else if (data.error || data.flag == 0) {
                    this.resetForBookingOnError();
                    this.utilityService.hideModalList(['request_right_now', 'request_right_now_1', 'request_right_now']),
                        (this.utilityService.loading = false);
                    // this.$apply();
                    /*
          if(data.error == 'Incorrect verification code'){
            $('#rider_payment_otp').modal('show');
          }
        */
                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);
                    setTimeout(() => {
                        this.utilityService.toast('error', data.error || data.message, '');
                    }, 0);
                } else if (data.flag == 202 || data.flag == 900) {
                    this.utilityService.loading = false;
                    localStorage.setItem('defaultTab', 'reg');

                    this.utilityService.hideModalList([
                        'add_to_account',
                        'request_right_now_1',
                        'request_ride_later',
                        'payment_step',
                        'showRiderCardError',
                        'show_PaymentError',
                        'show_confirmation',
                        'rider_payment_otp',
                    ]);
                    this.utilityService.loading = false;
                    this.buttonNowClicked = 0;

                    this.session_id = data.session_id;
                    localStorage.setItem('routeOff', data.session_id);
                    this.resetPremiumOption();

                    // reset premium option
                    this.booking.promo_code = undefined;
                    this.appliedPromoCode = '';
                    this.promo_applied = 0;

                    this.utilityService.toast('success', 'Ride Booked Successfully', '');
                    setTimeout(() => {
                        $('#request_right_now').modal('hide');
                    }, 2500);
                    this.requestPending = 0;

                    setTimeout(() => {
                        this.DisableOnload = false;
                        this.DisbleOnBRN = false;
                    }, 2000);

                    this.scrollTopTripDataList();
                    this.ngOnInit();
                    // this.checkForActiveRide();

                    // clear = setInterval(() => {
                    //   clearInterval(stop);
                    //   stop = undefined;
                    //   // location.reload();

                    //   // $('#request_right_now').modal('hide');
                    // }, 2500);

                    // this.$apply();
                }
            },
            () => {
                $('#request_right_now').modal('hide');
                this.buttonNowClicked = 0;
                this.utilityService.alert('error', 'Something went Wrong, Please check your Internet!');
                this.utilityService.loading = false;
                setTimeout(() => {
                    this.DisableOnload = false;
                    this.DisbleOnBRN = false;
                }, 2000);
            }
        );
    }

    // public resetForBookingOnSuccess() {
    //     this.utilityService.loading = false;
    //     $('#add_to_account').modal('hide');
    //     $('#request_ride_later').modal('hide');
    //     $('#payment_step').modal('hide');
    //     $('#showRiderCardError').modal('hide');
    //     $('#rider_payment_otp').modal('hide');
    //     $('#show_PaymentError').modal('hide');
    //     this.buttonLaterClicked = 0;
    //     this.buttonNowClicked = 0;
    // }

    public BookRideLater() {
        // if (!this.validateBookingData()) {
        //     return false;
        // }

        // this.booking.cardId = '';

        if (this.booking.card_user_type == 1) {
            this.booking.cardId = this.booking.cardIdCorporate;
        } else {
            this.booking.cardId = this.booking.cardIdUser;
        }

        /*if(this.RiderCards.length == 0){
      this.showRiderCardError();
    }*/

        // if(!this.booking.cardId && !this.booking.returnRide){
        // 	$('#request_right_now').modal('hide');

        // 	alert('Please select a valid card.');
        // 	this.utilityService.loading = false;
        // 	return false;
        // }
        if (!this.booking.cardId) {
            if (this.settings.bothEnabled == true) {
                this.showPaymentAlert();
                return false;
            } else {
                // this.utilityService.alert('info', 'Please Add a new card.');
                this.showCardAlert();
                return false;
            }
        } else {
        }
        // if (this.buttonLaterClicked === 1) {
        // 	// $rootScope.openToast('error', 'Please Wait while we complete the request for you!', '');
        // 	return false;
        // }

        // this.buttonLaterClicked = 1;

        this.httpService
            .post(environment.urlC + 'ride_payment_process', {
                web_access_token: this.cookieService.get('web_access_token'),
                card_user_type: this.booking.card_user_type,
                mobile: this.promoCountryCode + this.booking.user_mobile,
                user_id: this.booking.user_id,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag == 101) {
                    this.buttonLaterClicked = 0;
                    this.showCredentialsAlert();
                }
                if (data.error || data.flag == 0) {
                    this.buttonLaterClicked = 0;
                    this.utilityService.alert('error', data.error || data.message);
                    return;
                } else {
                    this.buttonLaterClicked = 0;
                    this.utilityService.loading = true;
                    // $('#payment_step').modal('hide');
                    this.scheduleRequestAPI();
                }
            });
    }

    public scheduleRequestAPI() {
        this.DisbleOnPFL = true;
        // if (!this.validateBookingData()) {
        //     this.requestPending = 0;
        //     this.resetForBookingOnError();
        //     setTimeout(() => {
        //         this.DisbleOnPFL = false;
        //     }, 1500);
        //     return false;
        // }

        // if(!this.checkRiderAuthorization()){
        //   return false;
        // }

        const datedata = this.booking.date.split(', ');
        this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes + ':00';
        this.booking.selectedTime = new Date(this.booking.time);

        const timeInThatArea = new Date(this.booking.time).toLocaleString('en-US', { timeZone: this.booking.timezone });
        this.booking.timeInThatArea = new Date(timeInThatArea);
        this.booking.timeInThatAreaLocale = new Date(timeInThatArea).toLocaleString();

        // this.booking.cardId = '';

        if (this.booking.card_user_type == 1) {
            this.booking.cardId = this.booking.cardIdCorporate;
        } else {
            this.booking.cardId = this.booking.cardIdUser;
        }

        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            current_latitude: this.booking.current_latitude,
            current_longitude: this.booking.current_longitude,
            estimated_fare: this.booking.estimated_fare_later,
            ride_estimate_distance: this.booking.ride_estimate_distance,
            ride_estimate_time: this.booking.ride_estimate_time,
            pickup_location_address: this.booking.pickup_location_address,
            latitude: this.booking.latitude,
            longitude: this.booking.longitude,
            pickup_time: this.booking.time,
            car_type: this.booking.car_type,
            driver_id: this.booking.driver_id.toString(),
            is_fav: this.booking.is_fav,
            manual_destination_latitude: this.booking.manual_destination_latitude,
            manual_destination_longitude: this.booking.manual_destination_longitude,
            manual_destination_address: this.booking.manual_destination_address,
            toll: this.booking.toll,
            route: '',
            promo_code: this.booking.promo_code,
            user_id: this.booking.user_id,
            offset: this.booking.offset,
            promo_value: this.booking.promo_value,
            card_user_type: this.booking.card_user_type,
            card_id: this.booking.cardId,
            is_essential: this.essential,
            third_party_ride: 0,
            fare_factor: this.fare_factor,
            pickup_city: this.booking.pickup_city,
            pickup_country: this.booking.pickup_country,
            congestion_charge: this.fare_breakdown.congestionCharge || 0,
            avoidHighways: this.routeOptionForm.controls?.avoidHighways?.value ? 1 : 0,
            avoid_tolls: this.routeOptionForm.controls?.avoidTolls?.value ? 1 : 0,
        };
        if (this.notFav) {
            params.is_fav = 0;
            params.select_car_type = this.booking.select_car_type;
            params.favdriver = 0;
        }

        if (this.mas_active_trip) {
            params.third_party_ride = 1;
            const currentTrip = this.mas_active_trip?.trips?.find((x) => x.tripLegId === this.mas_leg_id);
            const mas_trip = Object.assign({}, this.mas_active_trip);
            mas_trip.trips = [currentTrip];
            mas_trip.trip_leg_id = this.mas_leg_id;
            params.third_party_info = mas_trip;

            if (this.active_assigned_trip && this.active_assigned_trip.assigned_status === 'confirmed') {
                params.auto_accept = 1;
            }
        }

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            params.premium_options = premium_option;
        }

        if (!this.booking.card_user_type) {
            /*if(!this.booking.cardOTP){
        $('#rider_payment_otp').modal('show');
        return false;
      }*/
            this.booking.cardOTP = '1234';

            params.otp = this.booking.cardOTP;
        }

        if (!this.booking.cardId && !this.booking.returnRide) {
            $('#request_right_now').modal('hide');
            this.resetForBookingOnError();
            alert('Please select a valid card.');
            this.utilityService.loading = false;
            return false;
        }

        if (this.booking.card_user_type == 1) {
            this.booking.cardId = this.booking.cardIdCorporate;
        } else {
            this.booking.cardId = this.booking.cardIdUser;
        }

        if (this.booking.returnRide) {
            params.card_id = this.booking.cardId;
        }

        this.buttonLaterClicked = 1;
        /** Adding Corporate Add on Param  */
        if (this.corporateAddOnValue.value != 0) {
            params.estimated_fare = this.booking.estimated_fare;
            params.corporate_addon = this.corporate_addon;
        }
        if (this.rideDiscount.value) {
            params.ride_discount = this.ride_discount;
        }
        this.httpService.postJSON(environment.urlC + 'schedule_request', params).subscribe(
            (data) => {
                this.fare_factor = 1;
                if (typeof data == 'string') data = JSON.parse(data);
                this.utilityService.loading = false;

                if (data.flag == 101) {
                    this.showCredentialsAlert();
                    this.resetForBookingOnError();
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);
                } else if (data.flag == 303) {
                    this.showCardAlert();
                    this.resetForBookingOnError();
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);
                } else if (data.error && data.flag == 213) {
                    this.resetForBookingOnError();
                    this.utilityService.hideModalList(['request_right_now', 'request_right_now_1', 'rider_payment_otp', 'show_confirmation']);
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);

                    this.utilityService.toast('error', 'Ride has been already booked for this User, Please choose another Rider.', '');
                    return;
                } else if (data.error || data.flag == 0) {
                    this.resetForBookingOnError();
                    this.utilityService.hideModalList(['request_ride_later', 'payment_step']);

                    if (data.error == 'Incorrect verification code') {
                        //$('#rider_payment_otp').modal('show');
                    }
                    this.utilityService.toast('error', data.error || data.message, '');
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);
                    return;
                } else if (data.flag == 906) {
                    this.resetForBookingOnError();
                    this.showPaymentAlert();
                    this.utilityService.toast('error', data.error || data.message, '');
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);
                } else if (data.error && data.flag == 921) {
                    this.resetForBookingOnError();
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);
                    this.utilityService.toast('error', 'Your sub merchant account not added yet.', '');
                    return;
                } else if (data.flag == 900) {
                    localStorage.setItem('defaultTab', 'sch');
                    localStorage.setItem('routeOff', data.session_id);

                    this.utilityService.hideModalList([
                        'add_to_account',
                        'request_ride_later',
                        'payment_step',
                        'showRiderCardError',
                        'rider_payment_otp',
                    ]);
                    this.buttonLaterClicked = 0;
                    $('#show_PaymentError').modal('hide');

                    this.showConfirm();
                    setTimeout(() => {
                        this.DisbleOnPFL = false;
                    }, 1500);

                    // reset premium option
                    this.booking.promo_code = undefined;
                    this.appliedPromoCode = '';
                    this.promo_applied = 0;

                    this.resetPremiumOption();
                    this.requestPending = 0;
                    this.utilityService.toast('success', 'Booking Scheduled Successfully', '');
                    // this.checkForActiveRide();
                }
            },
            (e) => {
                this.utilityService.alert('error', 'Something went Wrong!');
                this.buttonLaterClicked = 0;
                this.utilityService.loading = false;
                setTimeout(() => {
                    this.DisbleOnPFL = false;
                }, 1500);
            }
        );
    }

    public checkRiderAuthorization() {
        $('#showRiderAuthorization').modal('show');
        if (!this.booking.returnRide) {
            if (this.booking.card_user_type == 0) {
                this.booking.cardId = this.booking.cardIdUser;
            } else {
                this.booking.cardId = this.booking.cardIdCorporate;
                return true;
            }
            return false;
        }
        return true;
    }

    public closeConfirm() {
        this.utilityService.hideModalList(['show_confirmation', 'add_to_account', 'request_right_now_1', 'request_right_now', 'request_ride_later']);
        this.utilityService.loading = false;
        //this.booking.confirmNow = 0;
        // setTimeout(() => {
        //     //window.location.reload();
        //     clearInterval(stop);
        //     stop = undefined;
        //     // location.reload();
        //     // this.ngOnInit();
        // }, 0);
        this.scrollTopTripDataList();
        this.ngOnInit();
    }
    public closeCard(redirect?) {
        this.utilityService.loading = false;
        //this.booking.confirmNow = 0;
        //this.cardAlert = 0;
        if (redirect) {
            $('.modal-backdrop.show').remove();
            // setTimeout(() => {
            //     // $state.go('corporate.cards');TODO:
            // }, 1000);
        }
    }

    public closeandCard() {
        // $('#add_to_account').modal('hide');
        $('#payment_step').modal('hide');
        this.closeCard();
        this.utilityService.loading = false;
        $('#show_PaymentError').modal('hide');
        $('.modal-backdrop.show').fadeOut();
        $('#request_right_now').modal('hide');
        $('#request_right_now_1').modal('hide');
        // setTimeout(() => {
        //     // $state.go('corporate.cards'); //TODO:
        // }, 1000);
        window.open('/corporate/cards', '_blank');
    }

    public sendRiderCardAuthorization(when) {
        //this.utilityService.loading = false;
        this.utilityService.hideModalList(['showRiderAuthorization', 'request_ride_later', 'payment_step']);

        this.requestPending = 1;

        if (when == 'Now') {
            this.requestRideAPI();
        } else if (when == 'Later') {
            this.scheduleRequestAPI();
        }
    }

    public reSendRiderOTP() {}

    public closeandCancel() {
        // $('#promoCodeEnter').fadeOut(500).modal('hide');
        $('#promoCodeEnter').modal('hide');
        this.booking.promo_code = undefined;
        this.appliedPromoCode = '';
        this.getFareEstimate();
        setTimeout(() => {
            this.promo_applied = 0;
        }, 100);
    }

    public closeandApply() {
        if (!this.booking.promo_code) {
            this.utilityService.alert('info', 'Please add Promo Code');
        } else if (this.promo_applied && this.booking.promo_code == this.appliedPromoCode) {
            this.utilityService.alert('error', 'Promo code is already applied');
            return;
        } else {
            this.applyPromoCode();
        }
    }

    public applyPromoCode() {
        this.utilityService.loading = true;
        this.httpService
            .post(environment.urlC + 'redeem_promotion', {
                web_access_token: this.cookieService.get('web_access_token'),
                promo_code: this.booking.promo_code,
                is_schedule: '',
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag == 101) {
                    this.showCredentialsAlert();
                } else if (data.flag == 1401) {
                    // this.utilityService.loading = false;
                    this.booking.promo_code = '';
                    this.promo_applied = 0;
                    this.getFareEstimate();
                    // $('#promoCodeEnter').modal('show');
                    this.utilityService.toast('error', data.error || data.message, '');
                } else if (data.error || data.flag == 0) {
                    this.booking.promo_code = '';
                    $('#promoCodeEnter').modal('show');
                    this.utilityService.toast('error', data.error || data.message, '');
                    this.utilityService.loading = false;
                    return;
                } else {
                    this.promo_applied = 1;
                    this.appliedPromoCode = this.booking.promo_code;
                    this.getFareEstimate();
                    $('#promoCodeEnter').modal('hide');
                    this.utilityService.toast('success', data.log, '');
                }
                this.utilityService.loading = false;
            });
    }

    public closeNoUser(redirect?) {
        this.utilityService.loading = false;

        setTimeout(() => {
            window.open('/corporate/riderSignup', '_blank'); //TODO:
        }, 1000);
    }
    public closeandReBook(redirect?) {
        this.utilityService.loading = false;

        setTimeout(() => {
            window.location.reload();
        }, 1000);
    }

    public createUser() {
        if (this.booking.proceed == 1) {
            this.getFareEstimate();
            return false;
        } else {
        }

        const user_mobile = this.promoCountryCode + this.booking.user_mobile;
        this.userService.new_user_register(user_mobile, this.booking.user_name, 0).subscribe((data) => {
            // $rootScope.$apply(function () {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.error) {
                this.utilityService.toast('error', data.error, '');

                return 0;
            } else {
                this.booking.user_id = data.user_id;
                // this.shoUserAddScreen();
                this.addtoAccount();
            }

            // });
        });
    }

    public createUserandProceed() {
        if (this.booking.proceed == 1) {
            if (this.mas_active_trip) {
                this.getDataAndshowPopup({ healthCheck: false, loading: false, skip: false, addQueued: false }); // silent = true
            } else {
                this.getFareEstimate();
            }
            return false;
        }
        const user_mobile = this.promoCountryCode + this.booking.user_mobile;
        this.userService.new_user_register(user_mobile, this.booking.user_name, 1).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.error) {
                this.utilityService.toast('error', data.error, '');

                return 0;
            } else {
                this.booking.proceed = 1;

                this.booking.user_id = data.user_id;
                if (this.mas_active_trip) {
                    this.getDataAndshowPopup({ healthCheck: false, loading: false, skip: false, addQueued: false }); // silent = true
                } else {
                    this.getFareEstimate();
                }
            }
        });
    }

    public selectThisCard(id) {
        $('#' + id).click();
    }

    public reAddUser() {
        this.otpMode = 1;
        this.userToAdd = this.booking.user_id;
        // this.otpToAdd = '';
        this.DisableResnd = true;
        this.httpService
            .post(environment.urlC + 'associatedUser_resend_otp', {
                web_access_token: this.cookieService.get('web_access_token'),
                mobile: this.promoCountryCode + this.booking.user_mobile,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);

                if (data.error || data.flag == 0) {
                    setTimeout(() => {
                        this.DisableResnd = false;
                    }, 1500);
                    this.utilityService.toast('error', data.error || data.message, '');
                    return;
                } else if (data.flag == 7) {
                    this.utilityService.toast('success', 'OTP sent again!', '');
                    setTimeout(() => {
                        this.DisableResnd = false;
                    }, 1500);
                }
            });
    }

    public autoFillOTP() {
        if (!this.userMobileToAdd) {
            this.utilityService.alert('info', "Please create account first by clicking  'Create My Rider Account'! ");
            return;
        }
        this.httpService
            .post(environment.urlC + 'associated_otp_auto_fill', {
                web_access_token: this.cookieService.get('web_access_token'),
                mobile: this.userMobileToAdd,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                this.buttonClicked = 0;
                if (data.error || data.flag == 0) {
                    this.utilityService.toast('error', data.error || data.message, '');
                    return;
                } else {
                    if (data) {
                        this.otpToAdd = data.otp;
                        if (this.mas_active_trip) this.completeUserAdd();
                    } else {
                        this.otpToAdd = '';
                    }
                    return;
                }
            });
    }

    public completeUserAdd() {
        this.onInvalidOtp = true;

        if (this.otpToAdd === '' || !this.otpToAdd) {
            // alert('Select Expiry Date');
            this.utilityService.alert('info', 'Please Enter OTP ');
            setTimeout(() => {
                this.onInvalidOtp = false;
            }, 1000);
            return false;
        } else if (!this.booking.user_id) {
            this.utilityService.alert('info', 'Unknown User');
            setTimeout(() => {
                this.onInvalidOtp = false;
            }, 1000);
            return false;
        } else {
            this.httpService
                .post(environment.urlC + 'corporate_add_user', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    user_id: this.booking.user_id,
                    role: 0,
                    otp: this.otpToAdd,
                    mobile: this.userMobileToAdd,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);

                    if (data.error || data.flag == 0) {
                        setTimeout(() => {
                            this.onInvalidOtp = false;
                        }, 1000);
                        this.otpToAdd = '';
                        this.utilityService.toast('error', data.error || data.message, '');
                        //
                        return;
                    } else {
                        // this.onInvalidOTp = false;
                        // this.utilityService.toast('success', data.log || data.message, '');
                        this.utilityService.loading = false;
                        if (!this.mas_active_trip) this.utilityService.toast('success', 'Associated user added successfully', '');
                        $('#add_myUser').modal('hide');
                        //this.getDriversData(this.booking.user_id);
                        this.getFareEstimate();
                    }
                });
        }
    }

    onSaveCard() {
        if (!this.user_email && this.riderCards) {
            if (!this.validation.email.test(this.cardForEmail)) {
                this.show_email_error = true;
                return;
            } else {
                this.updateUser();
            }
        } else {
            this.createStripeToken();
        }
    }

    createStripeToken(): void {
        this.utilityService.loading = true;
        this.stripeService.createToken(this.stripeCard.element).subscribe((result) => {
            this.utilityService.loading = false;
            if (result.token) {
                this.stripeTokenHandler(result.token);
            } else if (result.error) {
                console.log(result.error.message);
                this.cardError = result.error.message;
            }
        });
    }

    onStripeCardChange(event: any) {
        if (event.error) {
            this.cardError = event.error.message;
        } else {
            this.cardError = '';
        }
    }

    clearStripeCard() {
        if (this.stripeCard) {
            this.stripeCard.element.clear();
        }
    }

    // update user before adding card
    public updateUser() {
        if (!this.user_email) {
            if (this.validation.isEmailValid(this.cardForEmail)) {
                this.utilityService.toast('error', 'Please fill valid mail id');
                return;
            }
            this.httpService
                .post(environment.urlC + 'update_registered_user_info', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    user_id: this.booking.user_id,
                    user_email: this.cardForEmail,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;
                    if (data.flag == 101) {
                        this.utilityService.alert('error', data.error);
                        this.router.navigate(['/', 'corporate_login']);
                    } else if (data.error) {
                        if (data.error == 'Please fill all the required fields.') {
                            this.utilityService.alert('error', 'Please fill valid mail id');
                        } else {
                            this.utilityService.alert('error', data.error);
                        }
                        return;
                    } else {
                        this.user_email = this.cardForEmail;
                        // this.stripeTokenHandler(this.token);
                        this.utilityService.toast('success', 'User info updated Successfully', '');
                        this.createStripeToken();
                        // $('#emailUpdateforUser').modal('hide');
                        // $('#add_user_card').modal('show');
                        // $('html, body').animate({ scrollTop: 0 }, 'slow');
                    }
                });
        } else {
            this.utilityService.toast('error', 'Please Wait while we complete the request for you!', '');
            return false;
        }
    }

    buttonClicked: any;
    public stripeTokenHandler(token) {
        this.utilityService.loading = true;
        if (this.buttonClicked === 1) {
            // $rootScope.openToast('error', 'Please Wait while we complete the request for you!', '');
            this.utilityService.loading = false;
            return false;
        } else {
            this.buttonClicked = 1;

            const card_http_config: any = {
                url: environment.urlC + 'add_credit_card',
                params: {
                    web_access_token: this.cookieService.get('web_access_token'),
                    nounce: token.id,
                    card_type: 52,
                    offset: new Date().getTimezoneOffset() * -1,
                },
            };
            if (this.riderCards && this.booking.user_id) {
                card_http_config.url = environment.urlC + 'add_user_credit_card';
                card_http_config.params.user_id = this.booking.user_id;
            }

            this.httpService.post(card_http_config.url, card_http_config.params).subscribe((data) => {
                // $rootScope.$apply(function () {
                // setTimeout(() => {
                this.buttonClicked = 0;
                // }, 3000);

                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.error) {
                    this.utilityService.loading = false;
                    // this.utilityService.toast('error', data.error, '');
                    if (data.flag == 301) {
                        this.cardError = 'Duplicate Card, Please try another';
                        // document.getElementById('card-errors').textContent = 'Duplicate Card, Please try another';
                    } else {
                        this.utilityService.alert('error', data.error);
                    }
                    return;
                } else {
                    this.utilityService.toast('success', data.log, '');

                    this.Cards = [];
                    this.RiderCards = [];
                    this.getCards('add_card');

                    $('#add_card').modal('hide');
                    setTimeout(() => {
                        this.utilityService.loading = false;
                    }, 1100);
                }
                // })
            });
        }
    }

    public findPlace(type: string) {
        // this.findPlaceNow(type);
        setTimeout(() => {
            this.findPlaceNow(type);
            // this.apply();
        }, 50);
    }
    public findPlaceNow(type: string) {
        //return;
        let existing = '';
        let address = '';

        if (this.pickup_id_list.includes(type)) {
            address = $(`#${type}`).val();

            if (!address || address == '') {
                this.booking.current_latitude = '';
                this.booking.current_longitude = '';
                this.booking.pickup_location_address = '';
                this.booking.latitude = '';
                this.booking.longitude = '';
                this.getDirectionsForBooking();
            } else {
                existing = this.booking.latitude;
            }
        } else if (this.drop_id_list.includes(type)) {
            address = $(`#${type}`).val();

            if (!address || address == '') {
                this.booking.manual_destination_latitude = '';
                this.booking.manual_destination_longitude = '';
                this.booking.manual_destination_address = '';
                this.getDirectionsForBooking();
            } else {
                existing = this.booking.manual_destination_latitude;
            }
        }

        // Code to search location based on entered location string manually

        if (address != '') {
            let geocoder = new google.maps.Geocoder();
            geocoder.geocode({ address: address }, (results, status) => {
                if (status == google.maps.GeocoderStatus.OK) {
                    // var formatted_address = results[0].formatted_address;
                    const latitude = results[0].geometry.location.lat();
                    const longitude = results[0].geometry.location.lng();

                    if (this.pickup_id_list.includes(type)) {
                        this.booking.current_latitude = latitude;
                        this.booking.current_longitude = longitude;
                        //this.booking.pickup_location_address = formatted_address;
                        this.booking.pickup_location_address = $(`#${type}`).val();
                        this.booking.latitude = latitude;
                        this.booking.longitude = longitude;
                        directionsDisplay.setMap(null);
                    } else if (this.drop_id_list.includes(type)) {
                        this.booking.manual_destination_latitude = latitude;
                        this.booking.manual_destination_longitude = longitude;
                        //this.booking.manual_destination_address = formatted_address;
                        this.booking.manual_destination_address = $(`#${type}`).val();

                        directionsDisplay.setMap(null);
                    }
                    this.getDirectionsForBooking();
                }
            });
        } else if (address == '') {
            directionsDisplay.setMap(null);
        } else {
        }

        if (this.mas_active_trip) {
            this.getDataAndshowPopup({ healthCheck: false, loading: false, skip: false, addQueued: false }); // silent = true
        }
    }

    public addMarker(location: any) {
        const marker = new google.maps.Marker({
            position: location,
            map: map,
        });
        markers.push(marker);
        // markers.setMap(null);
    }

    ride_new: any;
    ride_old: any;
    stopCompleted: number;
    pickup_time_formatted: any;
    pickup_time_used: any;
    fare_calculated: any;
    tripnumber: any;
    user_id: any;
    user_rating: any;
    user_email: any;
    driver_email: any;
    search_session_id: any;
    start_time_trip: any;
    start_time_formatted: any;
    accept_time: any;
    pickup_id: any;
    rideshistory: any;
    faretotal: any;
    // driverLimit = 200;
    driverSkip = 0;
    // dsearchFlag = 0;
    // dsearchString = '';

    public expandDisc(daa): void {
        if (this.selectedIndex === 2 && this.filterRideType === 'alltrips') return;
        // this.driverNames = '';
        if (this.tripDetailMode) {
            this.socketService.emit('corporateRequestedDriverList', { session_id: daa.session_id });

            this.classExpand = 1;
            this.ride_new = { status: daa.ride_status, session_id: daa.session_id, scheduled: daa.is_schedule };

            if (this.ride_old) {
                if (this.ride_old.session_id == this.ride_new.session_id) {
                    if (this.ride_old.status != this.ride_new.status) {
                        if (this.ride_new.status == 4) {
                            let rts: any;
                            if (this.ride_new.scheduled == 1) {
                                if (this.scheduledrides[0].session_id != this.ride_old.session_id) {
                                    rts = this.scheduledrides[0];
                                } else {
                                    rts = this.scheduledrides[1];
                                }
                            } else if (this.ride_new.scheduled == 0) {
                                if (this.regular_rides[0].session_id != this.ride_old.session_id) {
                                    rts = this.regular_rides[0];
                                } else {
                                    rts = this.regular_rides[1];
                                }
                            }
                            this.expandDisc(rts);

                            setTimeout(() => {
                                this.expandDisc(rts);
                                setTimeout(() => {
                                    this.expandDisc(rts);
                                }, 1000);
                            }, 500);
                            this.stopCompleted = 1;
                        }
                    }
                }
            }

            this.ride_old = { status: daa.ride_status, session_id: daa.session_id, scheduled: daa.is_schedule };
        }

        this.rideInFocus = daa;
        this.is_schedule = daa.is_schedule;
        this.ride_status = daa.ride_status;
        this.ride_status_text = this.get_ride_status(daa);

        // If trip is assigned trip
        if (this.rideInFocus?.is_assigned_trip) {
            this.rideText = '';
        } else {
            this.getRideText(daa.ride_status, daa.request_status, daa.is_active);
        }

        this.pickup_time_raw = '';
        if (daa.is_schedule == '0') {
            this.pickup_time_formatted = moment(new Date(daa.start_time)).format('MM/dd/yyyy HH:mm a');
            this.pickup_time_used = daa.start_time;
            this.pickup_time_raw = daa.start_time;
        } else {
            this.pickup_time_formatted = moment(new Date(daa.pickup_time)).format('MM/dd/yyyy HH:mm a');
            this.pickup_time_used = daa.pickup_time;
            this.pickup_time_raw = daa.pickup_time_processed;
        }

        if (daa.ride_status == 3) {
        }

        if (daa.pickup_location_address) {
            this.pickup_location_address = daa.pickup_location_address;
        } else if (daa.address) {
            this.pickup_location_address = daa.address;
        }
        this.manual_destination_address = daa.manual_destination_address;

        this.driver_name = daa.driver_name;

        this.car_name = daa.car_name;
        this.rideInFocus.car_info = daa?.car_make
            ? `${daa?.car_make || ''} ${daa?.car_model || ''} ${daa?.car_color || ''} ${daa?.car_no || ''}`
            : '';
        this.driver_mobile = daa.driver_mobile;
        if (daa.total_with_tax) {
            daa.total_with_tax = parseFloat(daa.total_with_tax);

            daa.total_with_tax = daa.total_with_tax.toFixed(2);
            daa.total_with_tax = parseFloat(daa.total_with_tax);

            this.fare_calculated = '$' + daa.total_with_tax;
        } else {
            this.fare_calculated = 'Unavailable';
        }

        // let status = this.getTripStatus(
        //     this.rideInFocus.ride_status,
        //     this.rideInFocus.request_status,
        //     this.rideInFocus.is_active,
        //     this.rideInFocus.is_schedule
        // );

        const blinkRange: number = 5;
        if (daa?.is_assigned_trip) {
            this.showStatus = Milestone_Display_Label_Map[daa?.ride_milestone];
            if (daa?.ride_milestone === MileStone.START_PICKUP) {
                const attachClass = this.rideInFocus?.estimate_pickup_time < blinkRange ? 'blink-text text-red' : '';
                // PICKING UP
                this.showStatus += ` | ETA <span class="${attachClass}">${this.rideInFocus?.estimate_pickup_time} mins</span>`;
            } else if (daa?.ride_milestone === MileStone.START_TRIP) {
                const attachClass = this.rideInFocus?.estimate_diff < blinkRange ? 'blink-text text-red' : '';
                // EN ROUTE
                this.showStatus += ` | ETA <span class="${attachClass}"> ${this.rideInFocus?.estimate_diff} mins </span>`;
            }
            if (this.activeMobileMenu != 'addRide') {
                this.booking.pickup_text = this.pickup_location_address;
                this.booking.drop_text = this.manual_destination_address;
                this.booking.manual_destination_latitude = daa.manual_destination_latitude;
                this.booking.manual_destination_longitude = daa.manual_destination_longitude;
                this.booking.current_latitude = daa.pickup_latitude;
                this.booking.current_longitude = daa.pickup_longitude;
                this.booking.pickup_location_address = this.pickup_location_address;
                this.booking.manual_destination_address = this.manual_destination_address;
                this.booking.latitude = daa.pickup_latitude;
                this.booking.longitude = daa.pickup_longitude;
                this.booking.user_mobile = daa.user_mobile;
            }
        } else {
            this.showStatus = this.getTripStatus(
                this.rideInFocus.ride_status,
                this.rideInFocus.request_status,
                this.rideInFocus.is_active,
                this.rideInFocus.is_schedule,
                blinkRange
            );
        }

        // let parts = status.split('<br>')
        // if (parts.length) {
        //   if (parts[0] == 'Completed') {
        //     status = parts[0] + "<br>" + parts[1]
        //   }
        // }

        // this.showStatus = $sce.trustAsHtml(this.showStatus); //TODO:

        if (daa.hasOwnProperty('session_id')) {
            // this.tripnumber = "Trip Number: #" + daa.session_id;
            this.tripnumber = 'Trip #' + daa.session_id;
        }
        if (daa.hasOwnProperty('session_id') == false) {
            // this.tripnumber = "Trip Number:Unavailable";
            this.tripnumber = 'Unavailable';
        }

        //Customer
        this.user_name = daa.user_name;
        this.user_id = daa.user_id;
        this.user_rating = daa.user_rating;
        this.user_mobile = daa.user_mobile;
        this.driver_mobile = daa.driver_mobile;
        this.user_email = daa.user_email;
        this.driver_email = daa.driver_email;
        this.user_image = daa.user_image;
        this.ride_essential = daa.ride_essential;
        this.session_id = daa.session_id;

        if (daa.user_image == null) {
            this.user_image = 'http://qudos-s3.s3.amazonaws.com/user_profile/user.png';
        }

        this.search_session_id = undefined;
        if (this.tripDetailMode) {
            if (this.hasDriver == 'YES') {
                if (this.driverEnable) {
                } else {
                    this.search_session_id = daa.session_id;
                }
                this.socketService.emit('corporateDriverAvailablity', {
                    session_id: this.search_session_id,
                    limit: this.driverLimit,
                    offset: this.driverSkip,
                    searchFlag: this.dsearchFlag,
                    searchString: this.dsearchString,
                });
            } else {
                if (!this.driverEnable) {
                    this.disableDrivers();
                    this.cleanDrivers();
                }
            }
        }
        this.driver_image = daa.driver_image;

        if (daa.driver_image == null) {
            this.driver_image = 'http://qudos-s3.s3.amazonaws.com/user_profile/user.png';
        }

        this.start_time_trip = daa.start_time;
        this.start_time_formatted = '';
        if (daa.start_time != null) {
            this.start_time_formatted = moment(new Date(daa.start_time)).format('MM/dd/yyyy HH:mm a');
        } else if (daa.pickup_time != null) {
            this.start_time_formatted = moment(new Date(daa.pickup_time)).format('MM/dd/yyyy HH:mm a');
        }
        //History
        this.tzName = daa.tzName;
        this.accept_time = daa.accept_time;
        this.offset = daa.offset;

        if (daa.is_schedule == '0') {
            this.schedule_calender_accept_time = daa.accept_time;
        } else {
            this.schedule_calender_accept_time = daa.schedule_calender_accept_time;
        }

        this.pickup_id = daa.pickup_id;
        this.session_id = daa.session_id;
        if (daa.pickup_id) {
            const scheduledrides_history = this.scheduledrides;
            if (scheduledrides_history) {
                let schhistory = [];
                for (let ride_history of scheduledrides_history) {
                    if (daa.user_id == ride_history.user_id) {
                        ride_history = { ...ride_history };
                        schhistory.push(ride_history);
                    } else {
                        continue;
                    }
                }
                this.rideshistory = schhistory;
            }
        } else if (daa.session_id) {
            const completedrides_history = this.completedRides;
            let total: any = 0;
            let comhistory = [];
            for (let ride_history of completedrides_history) {
                if (daa.driver_id == ride_history.driver_id) {
                    ride_history = { ...ride_history };
                    comhistory.push(ride_history);

                    total = total + ride_history.fare_calculated;
                    total = parseFloat(total);

                    total = total.toFixed(2);
                    total = parseFloat(total);
                } else {
                    continue;
                }
            }
            this.rideshistory = comhistory;
            this.faretotal = total;
        }

        // only works if flag 'driverEnable' is true
        this.getLiveDrivers();
    }

    hasDriver: string;
    public getRideText(ride_status, request_status, is_active) {
        this.hasDriver = 'NO';
        this.rideText = 'NO';
        //this.rideRechedulable = 'NO';
        this.rideMissed = 'NO';
        switch (ride_status) {
            case 0:
                if (request_status == 0) {
                    this.rideText = 'Yes';
                } else if (request_status == 1) {
                    this.rideText = 'Yes';
                    this.hasDriver = 'YES';
                } else if (request_status == 10) {
                    this.rideText = 'Cancelled';
                    this.rideMissed = 'Yes';
                } else if (request_status == null && is_active == 0) {
                    this.rideText = 'Cancelled';
                    this.rideMissed = 'Yes';
                } else {
                    this.rideText = 'Yes';
                }
                break;
            case 1:
                this.rideText = 'Yes';
                this.hasDriver = 'YES';
                break;
            case 2:
                this.rideText = 'Yes';
                this.hasDriver = 'YES';
                break;
            case 3:
                this.rideText = 'No-enroute';
                this.hasDriver = 'YES';
                break;
            case 4:
                this.rideText = 'Completed';
                break;
            case 5:
            case 6:
            case 8:
            case 9:
            case 11:
                this.rideText = 'Cancelled';
                break;
            default:
                this.rideText = 'Default';
                break;
        }
        this.rideInFocus.rideText = this.rideText;
    }
    // ride_status, request_status
    public getTripStatus(ride_status, request_status, is_active, is_schedule, blinkRange: number = 5) {
        if (this.rideInFocus.driver_name == null) {
            this.rideInFocus.driver_name = ' - ';
        }
        if (this.rideInFocus.estimate_pickup_time == null) {
            this.rideInFocus.estimate_pickup_time = '00:00';
        }
        if (this.rideInFocus.estimate_diff == 'NaN') {
            this.rideInFocus.estimate_diff = '0';
        }

        if (ride_status == 0) {
            if (request_status == 1) {
                return 'Accepted<br>' + this.fare_calculated;
            } else if (request_status == null && is_active == 0) {
                return 'Missed';
            } else if (is_active == 0) {
                return 'Lapsed';
                // return "Missed";
            } else {
                if (is_schedule == '1') return 'Assigning';
                else if (is_schedule == '0') return 'Driver Time to Accept<br>' + this.rideInFocus.timer + ' mins';
            }
        } else if (ride_status == 1) {
            const attachClass = this.rideInFocus.estimate_pickup_time < blinkRange ? 'text-red blink-text' : '';
            return `Estimate to Pickup<br> <span class="${attachClass}">  ${this.rideInFocus.estimate_pickup_time} mins </span>`;
        } else if (ride_status == 2) {
            return 'Arrived';
        } else if (ride_status == 3) {
            const attachClass = this.rideInFocus.estimate_diff < blinkRange ? 'text-red blink-text' : '';
            return `Estimate to Arrival<br> <span class="${attachClass}"> ${this.rideInFocus.estimate_diff} mins </span>`;
        } else if (ride_status == 4) {
            return 'Completed<br>' + this.fare_calculated;
        } else if (ride_status == 5 || request_status == 5) {
            return 'Cancelled By Driver'; //<br>Driver: " + this.rideInFocus.driver_name;
        } else if (ride_status == 6 || ride_status == 7 || request_status == 7) {
            return 'Cancelled By Rider';
        } else if (ride_status == 8 || request_status == 8) {
            return 'Unsuccessful Payment';
        } else if (ride_status == 9 || request_status == 9) {
            return 'Cancelled by Admin';
        } else if (ride_status == 10 && is_active == 0) {
            return 'Lapsed';
        } else if (ride_status == 11 || request_status == 11) {
            return 'Cancelled By Corporate';
        } else {
            return ride_status + ' ' + request_status;
        }
    }

    public disableDrivers() {
        clearInterval(gettingDrivers);

        this.availableCurrentPage = 0;
        this.availableCarsPages = 0;
        this.countAvailableCars = 0;

        this.availableFrom = 0;
        this.availableTo = 0;

        gettingDrivers = 0;
        // this.cleanDrivers();
        setTimeout(() => {
            this.cleanDrivers();
        }, 1000);
    }
    public cleanDrivers(id?: any) {
        clearInterval(gettingDrivers);

        // clear all the initial ride timeout
        this.initialRideTimeOut.forEach((x) => {
            clearTimeout(x);
        });

        if (!id) {
            this.availableCurrentPage = 0;
            this.availableCarsPages = 0;
            this.countAvailableCars = 0;

            this.availableFrom = 0;
            this.availableTo = 0;
        }
        for (let i = 0; i < markers.length; i++) {
            if (markers[i].driver_id == id) {
            } else {
                markers[i].marker.setMap(null);
            }
        }
    }

    public isNumberKeys(evt) {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode < 48 || charCode > 57) return false;
        return true;
    }
    public isNumberKey(evt) {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode == 43) return true;

        if (charCode < 48 || charCode > 57) return false;

        return true;
    }

    public pageChanged = (currentPage, type) => {
        this.scrollTopTripDataList();
        // alert('A');
        // this.active_trip_data = null;
        this.current_page = currentPage;

        // Assign or Queue realm
        if (this.selectedIndex < 2) {
            this.emitSocketRides();
        }
        if (this.selectedIndex == 2) {
            if (this.filterRideType === 'alltrips') {
                this.get_all_trips(this.current_page);
            } else if (this.filterRideType === 'queue-trip') {
                this.get_queued_trips(this.current_page);
            } else if (this.filterRideType === 'assigned') {
                this.get_assign_mas_roster_trip_info(this.current_page);
            }

            return;
        }

        // if (this.current_page * this.ridesPerPage > this.total_rides) {
        //     this.trip_to = this.total_rides;
        // } else {
        //     this.trip_to = this.current_page * this.ridesPerPage;
        // }

        // const skip = (this.current_page - 1) * this.ridesPerPage;

        // if (type == 'scheduled') {
        //     this.socketService.emit('corporateScheduledRides', {
        //         corporate_id: this.driverDetails.corporate_id,
        //         limit: this.ridesPerPage,
        //         offset: skip,
        //         sort_by: '',
        //         sort_order: '',
        //         searchFlag: this.searchFlag,
        //         searchString: this.searchString,
        //         requestType: 5,
        //     });
        // } else if (type == 'regular') {
        //     this.socketService.emit('corporateRegularRides', {
        //         corporate_id: this.driverDetails.corporate_id,
        //         limit: this.ridesPerPage,
        //         offset: skip,
        //         sort_by: '',
        //         sort_order: '',
        //         searchFlag: this.searchFlag,
        //         searchString: this.searchString,
        //         requestType: 5,
        //     });
        // } else if (type == 'completed') {
        //     this.socketService.emit('corporateCompletedRequests', {
        //         corporate_id: this.driverDetails.corporate_id,
        //         limit: this.ridesPerPage,
        //         offset: skip,
        //         sort_by: '',
        //         sort_order: '',
        //         searchFlag: this.searchFlag,
        //         searchString: this.searchString,
        //         requestType: 2,
        //     });
        // } else if (type == 'availableDrivers') {
        //     this.availableCurrentPage = currentPage;
        //     this.hideNextAV = 0;

        //     this.availableFrom = (this.availableCurrentPage - 1) * this.driverLimit + 1;
        //     this.availableTo = this.availableFrom - 1 + this.driverLimit;

        //     if (this.availableCurrentPage == 0) {
        //         this.availableFrom = 1;
        //         this.availableTo = this.driverLimit;
        //     }

        //     if (parseInt(`${this.totalAvailableCars / this.driverLimit + 1}`) <= currentPage) {
        //         this.hideNextAV = 1;
        //     }

        //     if (this.availableCurrentPage == 1 && this.availableCarsPages == 1) {
        //         this.hidePrevsAV = 0;
        //         this.hideNextAV = 0;
        //     } else if (this.availableCurrentPage == 1) {
        //         this.hidePrevsAV = 1;
        //         this.hideNextAV = 0;
        //     } else if (this.availableCurrentPage == this.availableCarsPages) {
        //         this.hidePrevsAV = 0;
        //         this.hideNextAV = 1;
        //     } else {
        //         this.hidePrevsAV = 0;
        //         this.hideNextAV = 0;
        //     }

        //     if (!this.dsearchFlag) {
        //         //this.totalAvailableCars = this.totalAvailableCars/ this.driverLimit + 1;
        //     }
        //     for (let i = 1; i <= this.totalAvailableCars; i++) {
        //         if (this.availableCurrentPage == i) {
        //             if (!this.dsearchFlag) {
        //                 this.driverSkip = this.driverLimit * (i - 1);
        //             }
        //         }
        //     }

        //     if (this.dsearchFlag) {
        //     } else {
        //     }
        //     this.socketService.emit('corporateDriverAvailablity', {
        //         limit: this.driverLimit,
        //         offset: this.driverSkip,
        //         searchFlag: this.dsearchFlag,
        //         searchString: this.dsearchString,
        //     });
        // }
    };

    private paginateRides(totalCount: number) {
        this.total_rides = totalCount;
        this.total_pages = Math.ceil(this.total_rides / this.ridesPerPage);
        // alert('B');
        // this.active_trip_data = null;
        this.current_page = this.current_page || 1;
        if (this.current_page * this.ridesPerPage > this.total_rides) {
            this.trip_to = this.total_rides;
        } else {
            this.trip_to = this.current_page * this.ridesPerPage;
        }
    }

    // driverSkip = 0;
    public monitorLiveData() {
        this.emitSocketRides();

        this.utilityService.loading = true;

        emitRides = setInterval(() => {
            this.emitSocketRides();
        }, 8000);
    }

    public addYourLocationButton(map, marker) {
        const controlDiv: any = document.createElement('div');

        const firstChild = document.createElement('button');
        firstChild.style.backgroundColor = '#fff';
        firstChild.style.border = 'none';
        firstChild.style.outline = 'none';
        firstChild.style.width = '28px';
        firstChild.style.height = '28px';
        firstChild.style.borderRadius = '2px';
        firstChild.style.boxShadow = '0 1px 4px rgba(0,0,0,0.3)';
        firstChild.style.cursor = 'pointer';
        firstChild.style.marginRight = '10px';
        firstChild.style.padding = '0px';
        firstChild.title = 'Your Location';
        controlDiv.appendChild(firstChild);

        const secondChild = document.createElement('div');
        secondChild.style.margin = '5px';
        secondChild.style.marginBottom = '48px';
        secondChild.style.width = '18px';
        secondChild.style.height = '18px';
        secondChild.style.backgroundImage = 'url(https://maps.gstatic.com/tactile/mylocation/mylocation-sprite-1x.png)';
        secondChild.style.backgroundSize = '180px 18px';
        secondChild.style.backgroundPosition = '0px 0px';
        secondChild.style.backgroundRepeat = 'no-repeat';
        secondChild.id = 'you_location_img';
        firstChild.appendChild(secondChild);

        google.maps.event.addListener(map, 'dragend', () => {
            $('#you_location_img').css('background-position', '0px 0px');
        });

        firstChild.addEventListener('click', () => {
            let imgX = '0';
            const animationInterval = setInterval(() => {
                if (imgX == '-18') imgX = '0';
                else imgX = '-18';
                $('#you_location_img').css('background-position', imgX + 'px 0px');
            }, 500);
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition((position) => {
                    const latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
                    marker.setPosition(latlng);
                    map.setCenter(latlng);
                    clearInterval(animationInterval);
                    $('#you_location_img').css('background-position', '-144px 0px');
                });
            } else {
                clearInterval(animationInterval);
                $('#you_location_img').css('background-position', '0px 0px');
            }
        });

        controlDiv.index = 1;
        map.controls[google.maps.ControlPosition.RIGHT_BOTTOM].push(controlDiv);
    }

    public mapInit() {
        const bounds = new google.maps.LatLngBounds();
        this.driverDetails.latitude = this.driverModel.latitude;
        this.driverDetails.longitude = this.driverModel.longitude;

        let center: any = {};
        let zoom: any;
        if (!this.driverDetails.latitude || this.driverDetails.latitude == 0.0) {
            center = { lat: 40.73221, lng: -73.91902 };
            zoom = 11;
        } else {
            let lat = this.driverDetails.latitude;
            let lng = this.driverDetails.longitude;

            if (typeof this.driverDetails.latitude == 'string' || typeof this.driverDetails.longitude == 'string') {
                lat = parseFloat(this.driverDetails.latitude);
                lng = parseFloat(this.driverDetails.longitude);
            }
            center = { lat: lat, lng: lng };
            zoom = 13;
        }

        map = new google.maps.Map(document.getElementById('map'), {
            zoom: zoom,
            center: center,

            streetViewControl: false,
            mapTypeControl: false,

            zoomControl: false,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            fullscreenControl: false,
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
        });

        // const ny = { lat: 40.73221, lng: -73.91902 };

        const myMarker = new google.maps.Marker({
            map: map,
            animation: google.maps.Animation.DROP,
            //position: ny
        });

        this.addYourLocationButton(map, myMarker);
        this.loadPlaces();
    }

    public zoomIn() {
        map.setZoom(map.getZoom() + 1);
    }

    public zoomOut() {
        map.setZoom(map.getZoom() - 1);
    }

    public fullScreen() {
        // map.requestFullScreen(map);
        // google.maps.event.trigger(map,'resize');
        const element = map.getDiv(); //$("#map-container")[0];
        if (element.requestFullscreen) {
            element.requestFullscreen();
        }
        if (element.webkitRequestFullScreen) {
            element.webkitRequestFullScreen();
        }
        if (element.mozRequestFullScreen) {
            element.mozRequestFullScreen();
        }
    }

    public loadPlaces() {
        let autocomplete = {};
        let autocompletesWraps = ['searchLocation'];
        $.each(autocompletesWraps, (index, name) => {
            if ($('#' + name).length == 0) {
                return;
            }
            autocomplete[name] = new google.maps.places.Autocomplete($('#' + name + '.autocomplete')[0]);

            google.maps.event.addListener(autocomplete[name], 'place_changed', () => {
                const place = autocomplete[name].getPlace();
                if (!place.geometry) {
                    this.utilityService.alert('error', 'Location not found, Please select from the suggested locations!!');
                    return;
                }

                const latitude = place.geometry.location.lat();
                const longitude = place.geometry.location.lng();

                const newlatlng = new google.maps.LatLng(latitude, longitude);

                map.setCenter(newlatlng);
                map.setZoom(this.driverZoom);
            });
        });
    }

    strtloca: any;
    strtlocaObj: any;
    // strtloca: any;
    drvObj: any;
    mdes: any;
    mdesObj: any;

    public showonm(data) {
        this.MapShow = true;
        this.CarShow = false;
        this.UserShow = false;
        this.QueuedDriverShow = false;

        let routeState: any = {};
        if (data['session_id']) {
            routeState = {
                key: 'session_id',
                id: data.session_id,
            };
            this.strtloca = data.pickup_latitude + ',' + data.pickup_longitude;

            this.strtlocaObj = { lat: data.pickup_latitude, lng: data.pickup_longitude };
        } else if (data['pickup_id']) {
            routeState = {
                key: 'pickup_id',
                id: data.pickup_id,
            };
            this.strtloca = data.latitude + ',' + data.longitude;
            this.strtlocaObj = { lat: data.latitude, lng: data.longitude };
        }

        this.routeMode = 1;

        this.drvObj = { lat: data.current_location_latitude, lng: data.current_location_longitude };

        /*this.routeState = routeState;
            this.mdes = data.manual_destination_latitude + ','+ data.manual_destination_longitude;
            this.mdesObj = {lat:data.manual_destination_latitude,lng:data.manual_destination_longitude};
            this.getDirections();
            */
        if (!(this.routeState && this.routeState.key == routeState.key && this.routeState.id == routeState.id)) {
            // directionsDisplay.setMap(null);
            // this.routeState = {};
            this.routeState = routeState;
            this.mdes = data.manual_destination_latitude + ',' + data.manual_destination_longitude;
            this.mdesObj = { lat: data.manual_destination_latitude, lng: data.manual_destination_longitude };
            this.getDirections();
        }
    }

    fakeThisId = 0;
    public getDirections() {
        directionsDisplay.setMap(null);
        directionsDisplay.setMap(map);

        const request = {
            origin: this.strtloca,
            destination: this.mdes,
            travelMode: google.maps.DirectionsTravelMode.DRIVING,
        };

        directionsService.route(request, (response, status) => {
            if (status === google.maps.DirectionsStatus.OK) {
                console.log('DIRECTION ==>>', response);
                directionsDisplay.setDirections(response);

                if (this.fakeThisId == this.routeState.id) {
                    this.search_session_id = this.fakeThisId;
                    /**** Displayed driver marker but to show this moving need to set Interval ******/
                    this.socketService.emit('corporateDriverAvailablity', {
                        session_id: this.search_session_id,
                        limit: this.driverLimit,
                        offset: this.driverSkip,
                        searchFlag: this.dsearchFlag,
                        searchString: this.dsearchString,
                    });
                    setTimeout(() => {
                        this.setCenterNow();
                    }, 200);
                }
            } else {
                console.log('Google route unsuccesfull!');
                // this.utilityService.alert('error', 'Google route unsuccesfull!');
            }
        });
    }

    public replaceNumber(id: any) {
        $('#' + id).val(
            $('#' + id)
                .val()
                .replace(/[0-9]/gi, '')
        );
    }

    public replaceText(id: string = 'phone') {
        $(`#${id}`).val(
            $(`#${id}`)
                .val()
                .replace(/[a-z ]/gi, '')
        );
    }

    public fakeCenter(ride, force?) {
        if (this.routeState.id == ride.session_id) {
            this.strtlocaObj = { lat: ride.pickup_latitude, lng: ride.pickup_longitude };
            this.mdesObj = { lat: ride.manual_destination_latitude, lng: ride.manual_destination_longitude };
            if (ride.current_location_latitude) {
                this.drvObj = { lat: ride.current_location_latitude, lng: ride.current_location_longitude };
            } else {
                this.drvObj = { lat: 40.710344, lng: -73.79898 };
            }

            this.fakeThisId = ride.session_id;
            if (force) {
                this.setCenterNow();
            }
            this.centering = 1;
        } else {
            this.showonm(ride);
            setTimeout(() => {
                this.fakeCenter(ride);
            }, 0);
        }
    }

    public setCenterNow(pointers: Array<any> = [this.strtlocaObj, this.mdesObj, this.drvObj]) {
        bounds = new google.maps.LatLngBounds();
        if (this.drvObj?.lat != null) {
            for (let i = 0; i < pointers.length; i++) {
                const data = pointers[i];
                if (data.lat == null) {
                    continue;
                }
                const myLatlng = new google.maps.LatLng(data.lat, data.lng);

                marker = new google.maps.Marker({
                    position: myLatlng,
                    map: map,
                    title: '',
                    icon: null,
                    visible: false,
                });

                bounds.extend(marker.position);
            }

            if (pointers.length === 3) {
                map.setCenter(bounds.getCenter());
                zoomlevel = map.getZoom();
                map.setZoom(zoomlevel);
            }
        }
    }

    lastBounds: any;

    public setBoundsNow(pointers: any) {
        // map.setZoom(16);
        if (!pointers) {
            pointers = [this.strtlocaObj, this.mdesObj, this.drvObj];
        }
        if (pointers.length > 0) {
            for (let i = 0; i < pointers.length; i++) {
                const data = pointers[i];
                if (data.lat == null) {
                    continue;
                }
                const myLatlng = new google.maps.LatLng(data.lat, data.lng);

                marker = new google.maps.Marker({
                    position: myLatlng,
                    map: map,
                    title: '',
                    icon: null,
                    visible: false,
                });

                bounds.extend(marker.position);
            }
            map.setCenter(bounds.getCenter());

            map.fitBounds(bounds);
            zoomlevel = map.getZoom();
            //map.setZoom(zoomlevel);
            this.driverZoom = zoomlevel;
            this.lastBounds = { searched: this.dsearchString, size: pointers.length, zoom: zoomlevel };
        } else {
        }
    }

    public move = (driverMarker: any, wait: any) => {
        if (driverMarker.path.length > 0) {
            driverMarker.isMoving = true;
            driverMarker.marker.setPosition(driverMarker.path[0][0]);
            let url = driverMarker.marker.getIcon().url;
            const markerImg: any = document.querySelector(`img[src="${url}"]`);
            if (markerImg) {
                const deg = driverMarker.path[0][1];
                markerImg.style.transform = 'rotate(' + deg + 'deg)';
            }
            driverMarker.path.splice(0, 1);
            setTimeout(() => {
                this.move(driverMarker, wait);
            }, wait);
            let icon = driverMarker.marker.getIcon();
            driverMarker.marker.setIcon(icon);
        } else {
            driverMarker.isMoving = false;
        }
    };

    private fitBounds(count: number = 0) {
        map.fitBounds(bounds);
        let zoom = map.getZoom() + count;
        if (zoom > 20) zoom = 18 + count;
        else if (zoom > 16) zoom = 16 + count;
        else if (zoom > 14) zoom = 15 + count;
        map.setZoom(zoom);
    }

    setMarker(driverData, { image, infowindow, rotation }) {
        let driverMarker = _.find(markers, { driver_id: driverData.driver_id });
        let location = new google.maps.LatLng(driverData.current_location_latitude, driverData.current_location_longitude);
        let marker;
        if (driverMarker) {
            let prevPos = driverMarker.marker.getPosition();
            if (driverMarker.path.length > 0) {
                prevPos = driverMarker.path[driverMarker.path.length - 1][0];
            }
            let icon = driverMarker.marker.getIcon();
            icon.url = image.url;
            driverMarker.marker.setIcon(icon);
            driverMarker.marker.setMap(map);
            // var rotation = google.maps.geometry.spherical.computeHeading(prevPos, location);
            let rotation = google.maps.geometry.spherical.computeHeading(prevPos, location);
            let fromLat = prevPos.lat();
            let fromLng = prevPos.lng();
            let toLat = location.lat();
            let toLng = location.lng();
            if (fromLat != toLat || fromLng != toLng) {
                let diff = Date.now() - driverMarker.time;
                driverMarker.time = Date.now();
                let frames = driverMarker.path;
                let hasPath = false;
                if (frames.length > 0) {
                    hasPath = true;
                }
                if (diff > 2000) {
                    diff = 1000;
                }
                if (frames.length >= 100) {
                    frames = [];
                }

                for (let percent = 0; percent < 1; percent += 0.01) {
                    let curLat = fromLat + percent * (toLat - fromLat);
                    let curLng = fromLng + percent * (toLng - fromLng);
                    frames.push([new google.maps.LatLng(curLat, curLng), rotation]);
                }
                driverMarker.path = frames;
                if (!hasPath) {
                    this.move(driverMarker, diff / 100);
                } else if (!driverMarker.isMoving) {
                    this.move(driverMarker, diff / 100);
                } else {
                    this.move(driverMarker, 0.5);
                }
            }
            marker = driverMarker.marker;
        } else {
            marker = new google.maps.Marker({
                position: location,
                icon: image,
                map: map,
                infoWindow: infowindow,
            });
            marker.addListener('click', function (e) {
                marker.infoWindow.open(map, this);
                setTimeout(function () {
                    const contentDiv = $('.gm-style-iw');
                    contentDiv.next('div').hide();
                    contentDiv.prev('div.custom-close').remove();
                    const closeBtn = `<div class="custom-close" id="${driverData.driver_id}">
                                <img alt="" src="https://maps.gstatic.com/mapfiles/api-3/images/mapcnt6.png" draggable="false" style="position: absolute; left: -2px; top: -336px; width: 59px; height: 492px; user-select: none; border: 0px; padding: 0px; margin: 0px; max-width: none;">
                            </div>`;
                    $(closeBtn).insertBefore(contentDiv);
                    $('div.custom-close').bind('click', function (e) {
                        $(e.target).parent().parent().css({ opacity: 0, 'pointer-events': 'none' });
                    });
                });
            });

            new google.maps.event.trigger(marker, 'click');
            markers.push({
                driver_id: driverData.driver_id,
                marker: marker,
                time: Date.now(),
                path: [],
                isMoving: false,
            });
            // setTimeout(function() {
            // });
        }
        return marker;
    }

    public cancelRidePopup(session_id) {
        this.utilityService.loading = false;
        this.utilityService.loading = true;
        this.triptoCancel = session_id;
        setTimeout(() => {
            this.utilityService.loading = false;
            this.utilityService
                .confirm({
                    heading: 'Cancel Ride?',
                    description: `Are you sure you want to cancel the ${this.is_schedule == '0' ? 'Regular' : 'Scheduled'} ride?`,
                    leftButtonText: 'No',
                    rightButtonText: 'Yes',
                })
                .subscribe((event: { action: 'left' | 'right' }) => {
                    if (event.action === 'right') {
                        this.cancelRide(session_id);
                    }
                });
        }, 500);
    }

    public cancelRide(session_id: any) {
        // clearInterval(clear);
        if (session_id) {
            this.utilityService.loading = true;
            this.httpService
                .post(environment.urlC + 'cancel_ride', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    session_id: session_id,
                    //pickup_id:trip
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);

                    if (data.error || data.flag == 0) {
                        this.utilityService.loading = false;
                        this.utilityService.toast('error', data.error || data.message, '');
                        this.socketService.emit('corporateRequestedDriverList', { session_id: session_id });
                        return;
                    } else {
                        // this.rideCancelButtonRef.nativeElement.click();
                        this.utilityService.toast('success', 'Ride Cancelled Successfully', '');
                        this.emitSocketRides();
                        setTimeout(() => {
                            this.utilityService.loading = false;
                        }, 1000);
                    }

                    this.emitSocketRides();
                });
        } else {
            // this.utilityService.alert('info', 'No pickup_id is found');
            this.utilityService.alert('info', 'Something went wrong, please try again');
            this.utilityService.loading = false;
        }
    }

    public sendRiderCardLink() {
        this.utilityService.loading = true;
        this.httpService
            .post(environment.urlC + 'ride_payment_user_card_send_link', {
                web_access_token: this.cookieService.get('web_access_token'),
                user_id: this.booking.user_id,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);

                if (data.error || data.flag == 0) {
                    this.utilityService.loading = false;
                    this.utilityService.toast('error', data.error || data.message, '');

                    return;
                } else {
                    this.utilityService.loading = false;
                    $('#showRiderCardError').modal('hide');
                    // $('#payment_step').modal('hide');
                    this.disableCRA = true;

                    this.utilityService.toast('success', data.log, '');
                    setTimeout(() => {
                        this.disableCRA = false;
                    }, 9000);
                }
            });
    }

    public validateRebookingDateTime() {
        const datedata = this.rebooking.date.split(', ');

        let today = new Date();
        let dd: any = today.getDate();
        let mm: any = today.getMonth() + 1; //January is 0!
        let yyyy = today.getFullYear();

        if (dd < 10) {
            dd = '0' + dd;
        }

        if (mm < 10) {
            mm = '0' + mm;
        }

        let dateToday = yyyy + '-' + mm + '-' + dd;

        if (datedata[0] === dateToday) {
            let d = new Date();
            let h = d.getHours();
            let m = d.getMinutes();

            let sm = m,
                sh = h;

            if (h != 23) {
                sh = sh + 1;
            } else if (h == 23) {
                sh = 0;
            }

            sm = this.addZero(sm);
            sh = this.addZero(sh);

            const hour_difference = sh - parseInt(this.rebooking.time_hour);
            const min_difference = sm - parseInt(this.rebooking.time_minutes);

            if (hour_difference == 0) {
                if (min_difference <= 0) {
                } else {
                    return 0;
                }
            } else if (hour_difference >= 1) {
                return 0;
            } else if (hour_difference <= 1) {
            }
        } else {
            const date1 = new Date(datedata[0]);
            const date2 = new Date(dateToday);
            const timeDiff = Math.abs(date2.getTime() - date1.getTime());
            const diffDays = Math.ceil(timeDiff / (1000 * 3600 * 24));

            if (this.max_schedule_ride_days_count < diffDays) {
                return 8;
            }

            return 1;
        }
        return 1;
    }

    rescheduleNow() {
        this.rebooking.date = moment(this.bookingDate).format('YYYY-MM-DD, dddd');

        this.DisableOnReschd = true;
        if (!this.rebooking || !this.rebooking.time_hour || !this.rebooking.date || !this.rebooking.time_minutes) {
            this.DisableOnReschd = false;
            this.utilityService.alert('info', 'Please enter Date and Time');
            return;
        }

        if (!this.validateBookingData(this.rebooking.date, this.rebooking.time_hour, this.rebooking.time_minutes, this.validateRebookingDateTime())) {
            this.DisableOnReschd = false;
            return false;
        }

        const datedata = this.rebooking.date.split(', ');

        this.rebooking.time = datedata[0] + ' ' + this.rebooking.time_hour + ':' + this.rebooking.time_minutes + ':00';

        //this.rebooking.time = this.rebooking.date+' '+this.rebooking.time_hour+':'+this.rebooking.time_minutes;

        if (!this.rTrip.pickup_id) {
            this.utilityService.alert('info', 'Pickup ID is not found');
            this.DisableOnReschd = false;
            return false;
        }

        this.httpService
            .post(environment.urlC + 'modify_schedule', {
                web_access_token: this.cookieService.get('web_access_token'),
                pickup_id: this.rTrip.pickup_id,
                pickup_time: this.rebooking.time,
                latitude: this.rTrip.pickup_latitude,
                longitude: this.rTrip.pickup_longitude,
                offset: new Date().getTimezoneOffset() * -1,
                manual_destination_latitude: this.rTrip.manual_destination_latitude,
                manual_destination_longitude: this.rTrip.manual_destination_longitude,
                manual_destination_address: this.rTrip.manual_destination_address,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);

                if (data.error || data.flag == 0) {
                    setTimeout(() => {
                        this.DisableOnReschd = false;
                    }, 1500);
                    this.utilityService.toast('error', data.error || data.message, '');

                    return;
                } else {
                    this.showRescheduleConfirm();
                    $('#reschedule').modal('hide');
                    setTimeout(() => {
                        this.DisableOnReschd = false;
                    }, 1500);
                    // this.utilityService.toast('success', data.log, '');
                    this.utilityService.toast('success', 'Booking Rescheduled Successfully', '');
                    this.searchL('', this.selectedIndex);
                }
            });
    }

    setDate(stamp: any) {
        const year: any = new Date(stamp).getFullYear();
        let month: any = new Date(stamp).getMonth();

        month = month + 1;
        month = month < 10 ? '0' + month : month;

        let day: any = new Date(stamp).getDate();
        day = day < 10 ? '0' + day : day;
        const dateOfPickup = year + '-' + month + '-' + day;

        this.rebooking.date = dateOfPickup;
    }

    setTime(stamp: any) {
        let hours: any = new Date(stamp).getHours();
        hours = hours < 10 ? '0' + hours : '' + hours;
        this.rebooking.time_hour = hours;

        let mins: any = new Date(stamp).getMinutes();
        mins = mins < 10 ? '0' + mins : '' + mins;

        this.rebooking.time_minutes = mins;
    }

    rTrip: any = {};
    rescheduleRide(ride) {
        this.bookingDate = new Date();
        const tripDetails = ride;

        this.rTrip = tripDetails;
        this.rebooking = {};

        this.getHour();
        this.getDate();

        /** Now this is used**/
        this.rebooking.date = this.booking.date;

        this.rebooking.time_hour = this.booking.time_hour;
        this.rebooking.time_minutes = this.booking.time_minutes;
    }

    centering: any;

    totalAvailableCars: any;
    avail: any;
    driverToFocus: any;

    driverListSubscription: Subscription;
    completedRequestSubscription: Subscription;
    scheduledRidesSubscription: Subscription;
    regularRidesSubscription: Subscription;
    driverAvailablitySubscription: Subscription;

    private subscriptionList$: Subscription = new Subscription();

    public emitSocketRides() {
        if (this.selectedIndex === 2) return;

        let payload: any = {
            corporate_id: this.driverDetails.corporate_id,
            limit: this.ridesPerPage,
            sort_by: '',
            sort_order: '',
            searchFlag: this.searchFlag,
            searchString: this.searchString,
            offset: (this.current_page - 1) * this.ridesPerPage,
            rootTab: this.selectedIndex === 0 ? 'completed' : 'ongoing',
            subTab: this.filterRideType || 'alltrips',
        };

        if (this.date_range_picker.value?.start && this.date_range_picker.value?.end) {
            payload = { ...payload, ...this._getDateTimeFilter() };
        }

        if (this.selectedIndex === 0) payload.rootTab = 'completed';
        if (this.selectedIndex === 1) payload.rootTab = 'ongoing';

        // payload.subTab = this.filterRideType;

        // console.log('payload', payload);

        this.socketService.emit('corporateDashboardRides', {
            ...payload,
        });

        // this.socketService.emit('corporateCompletedRequests', {
        //     ...payload,
        //     requestType: 2,
        // });

        // this.socketService.emit('corporateScheduledRides', {
        //     ...payload,
        //     requestType: 5,
        // });

        // this.socketService.emit('corporateRegularRides', {
        //     ...payload,
        //     requestType: 5,
        // });
    }

    public socketListener(): void {
        console.log('socket listener');

        this.socketService.on('corporateDashboardRides').subscribe((socketRes: { data: { count: number; paginated_rides: any[] } }) => {
            const data = socketRes?.data;
            if (!data) return;

            // console.log('data?.paginated_rides', data?.paginated_rides);

            // this.completedOngoingTrips = data?.paginated_rides;
            // this.total_rides = data?.count;
            this.paginateRides(data?.count);
            this.completedOngoingTrips = [];

            // If completed trips
            if (this.selectedIndex === 0) {
                // this.total_completed_rides = data.data.count;
                const CompletedData = data?.paginated_rides;

                this.completedRides = [];
                let i = 1;
                CompletedData.forEach((ride: any) => {
                    let r = ride;

                    if (ride.start_time) {
                        const dt = new Date(ride.start_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        const raw = dt.toISOString();
                        r.start_time_raw = raw;
                        r.start_time_original = ride.start_time;
                        r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    }
                    if (ride.drop_time) {
                        const dt = new Date(ride.drop_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        const raw = dt.toISOString();
                        r.drop_time_raw = raw;
                        r.drop_time_original = ride.drop_time;
                        r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    }
                    if (ride.pickup_time) {
                        const dt = new Date(ride.pickup_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        // const raw = dt.toISOString();
                        //r.pickup_time_raw =  raw;
                        r.pickup_time_processed = ride.pickup_time;
                        r.pickup_time_original = ride.pickup_time;

                        r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    }

                    if (r.tzName == 'India Standard Time' || r.tzName == 'GMT+5:30') {
                        r.tzName = 'IST';
                    }

                    // if (i == 1) {
                    //this.showonm(r);
                    // }
                    i++;
                    r.display_date = r?.drop_time;
                    this.completedOngoingTrips.push(r);
                    if (!this.stopCompleted) {
                        if (this.classExpand == 1) {
                            if (this.rideInFocus.session_id == r.session_id) {
                                this.expandDisc(r);
                            }
                        }
                    }
                });
                this.isRideLoading = false;
            } else if (this.selectedIndex === 1) {
                // ongoing tab
                // this.regular_rides = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
                const newRegularData = [];

                // var statscroll = $('table.table-full:last').scrollTop();
                data?.paginated_rides.forEach((ride: any, index: number) => {
                    let r = ride;
                    r.tzName = '';

                    // We can refactor this logic by reusable function
                    if (ride.start_time) {
                        const dt = new Date(ride.start_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        const raw = dt.toISOString();
                        /***Old COde***/
                        /***r.start_time =  raw;***/

                        /***New COde***/
                        r.start_time_processed = raw;
                        r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    } else if (ride.pickup_time) {
                        const dt = new Date(ride.pickup_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        const raw = dt.toISOString();
                        /****r.pickup_time =  raw;***/
                        r.pickup_time_processed = raw;
                        r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    } else if (ride.date) {
                        const dt = new Date(ride.date);
                        /*dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset())
                  var raw = dt.toISOString();
                  r.date =  raw;*/
                        r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    }

                    if (r.tzName == 'India Standard Time' || r.tzName == 'GMT+5:30') {
                        r.tzName = 'IST';
                    }

                    // =============xxxxxxxx============= START: Regular Ride Only =============xxxxxxxx=============

                    if (!ride?.is_schedule) {
                        let timerString = '00:00';
                        r.timer = timerString;
                        if (ride.ride_status == 0 && ride.is_active == 1) {
                            const givendate = new Date(ride.date);
                            const givenmilliseconds: any = givendate.getTime();

                            const nowdate = new Date();
                            const nowmilliseconds: any = nowdate.getTime();

                            let secondsSinceBooked: any = nowmilliseconds - givenmilliseconds;
                            secondsSinceBooked = parseInt(`${secondsSinceBooked / 1000}`);
                            const lapsWindow = 135;
                            if (secondsSinceBooked <= lapsWindow) {
                                const secsLeft = lapsWindow - secondsSinceBooked;
                                const totalsecs = secsLeft;

                                const mins: any = totalsecs / 60;
                                let cleanMins = parseInt(mins);

                                let secs = totalsecs % 60;
                                secs = this.addZero(secs);
                                cleanMins = this.addZero(cleanMins);

                                timerString = cleanMins + ':' + secs;
                            }

                            r.timer = timerString;
                        }

                        // For regular ride only
                        if (r?.ride_milestone > 0 && r?.ride_milestone !== 5) {
                            r.next_milestone = r?.ride_milestone + 1;
                            r.next_milestone_text = MileStone_Map[r?.next_milestone];
                            r.is_live_milestone_trip = r?.ride_milestone <= 4;
                            r.ride_milestone_class = MileStone_Class[r?.next_milestone];
                        }
                    }

                    // =============xxxxxxxx============= END: Regular Ride Only =============xxxxxxxx=============

                    r.estimate_pickup_time_original = ride.estimate_pickup_time;
                    r.estimate_pickup_time = Math.ceil(ride.estimate_pickup_time);
                    r.estimate_pickup_time = Math.abs(r.estimate_pickup_time);

                    //r.estimate_diff = ride.estimate_diff.replace('-','');
                    r.estimate_diff = Math.ceil(ride.estimate_diff);
                    r.estimate_diff = Math.abs(r.estimate_diff);

                    if (ride?.sent_to) {
                        const bounceData = ride?.sent_to?.split(',');
                        r.bounce = bounceData.length;
                    } else {
                        r.bounce = 0;
                    }
                    const userName = ride.user_name.split(' ');
                    let firstname: any, lastname: any;
                    if (userName.length > 2) {
                        firstname = userName[0];
                        lastname = userName[1] + ' ' + userName[2];
                    } else if (userName.length == 2) {
                        firstname = userName[0];
                        lastname = userName[1];
                    } else {
                        firstname = ride.user_name;
                        lastname = '';
                    }

                    r.User_firstname = firstname;
                    r.User_lastname = lastname;

                    r.ride_status_text = this.get_ride_status(ride);
                    r.ride_status_text_class = this.getButtonClass(ride.ride_status, ride.request_status, ride.is_active);

                    //this.regular_rides.push(r);
                    if (r.is_schedule) r.display_date = r?.pickup_time_processed;
                    else r.display_date = r?.date;
                    newRegularData.push(r);

                    if (this.routeOffID) {
                        if (r.session_id == this.routeOffID) {
                            this.showTripDetailCard();
                            this.expandDisc(r);
                            this.showonm(r);
                            localStorage.removeItem('routeOff');
                            this.routeOffID = null;
                        }
                    } else if (
                        !this.view_assigned_trip &&
                        index === 0 &&
                        this.expand_disc_for_first_ride &&
                        ['Accepted', 'Assigning', 'Picking Up', 'Arrived', 'En Route']?.includes(r.ride_status_text)
                    ) {
                        this.active_trip_data = 'active-socket-trip-' + r.session_id;
                        this.showTripDetailCard();
                        this.expandDisc(r);
                        this.showonm(r);
                        this.expand_disc_for_first_ride = false;
                    }

                    if (this.classExpand == 1) {
                        if (this.rideInFocus.session_id == r.session_id) {
                            this.expandDisc(r);
                        }
                    }
                });
                this.isRideLoading = false;
                this.completedOngoingTrips = newRegularData;

                setTimeout(() => {
                    this.utilityService.loading = false;
                    // document.getElementById('loading').style.display = 'none';
                }, 1300);
            }
        });

        this.utilityService.loading = false;

        this.driverListSubscription = this.socketService.on('corporateRequestedDriverList').subscribe((data) => {
            this.driverNames = data.data.paginated_list;
            this.payment_date = null;
            this.payment_timezone = null;

            this.driverNames.map((driver: any, index: number) => {
                if (!driver.names) driver.names = '';
                if (index === 0) {
                    this.payment_date = driver.payment_date;
                    this.payment_timezone = new Date(driver.payment_date).toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
                    if (this.payment_timezone == 'India Standard Time' || this.payment_timezone == 'GMT+5:30') {
                        this.payment_timezone = 'IST';
                    }
                }
                let labelString: string;
                if ((index + 1) % 10 === 1) labelString = 'st';
                else if ((index + 1) % 10 === 2) labelString = 'nd';
                else if ((index + 1) % 10 === 3) labelString = 'rd';
                else labelString = 'th';
                driver.request_sent_label = `${index + 1}${labelString} Request Sent to:`;
                driver.names = driver.names.replace(/,/g, '<br />');
                return driver;
            });
        });

        this.driverAvailablitySubscription = this.socketService.on('corporateDriverAvailablity').subscribe((data) => {
            this.cleanDrivers();
            this.countAvailableCars = 0;
            this.totalAvailableCars = 0;
            if (!data.data?.total_drivers) {
                return;
            }
            this.countAvailableCars = data.data?.total_drivers[0].total_drivers;
            this.totalAvailableCars = data.data?.total_drivers[0].total_drivers;

            if (this.availableCurrentPage == 0) {
                this.availableCurrentPage = 1;
            }

            this.availableFrom = (this.availableCurrentPage - 1) * this.driverLimit + 1;
            this.availableTo = this.availableFrom - 1 + this.driverLimit;

            if (this.availableTo >= this.totalAvailableCars) {
                this.availableTo = this.totalAvailableCars;
                this.hideNextAV = 1;
            }

            if (this.totalAvailableCars == 0) {
                this.availableFrom = 0;
                this.availableTo = 0;
            }

            this.avail = data.data.drivers;

            this.driverToFocus = [];
            this.driverToFocus = this.avail;

            if (!this.dsearchFlag) {
                //this.totalAvailableCars = this.totalAvailableCars/ this.driverLimit + 1;
            }
            this.availableCarsPages = parseInt(`${this.totalAvailableCars / this.driverLimit}`) + 1;
            if (this.availableCurrentPage == 1 && this.availableCarsPages == 1) {
                //this.hidePrevsAV = 0;
                //this.hideNextAV = 0;
                this.hidePrevsAV = 1;
                this.hideNextAV = 1;
            } /*else if(this.availableCurrentPage == 1){
            this.hidePrevsAV = 1;
            this.hideNextAV = 0;
          }*/ else if (
                this.availableCurrentPage == this.availableCarsPages
            ) {
                this.hidePrevsAV = 0;
                this.hideNextAV = 1;
            } else if (this.availableTo >= this.totalAvailableCars) {
                this.hidePrevsAV = 0;
                this.hideNextAV = 1;
            } else {
                this.hidePrevsAV = 0;
                this.hideNextAV = 0;
            }

            if (this.dsearchFlag && !this.routeMode) {
                if (this.avail[0]) {
                    if (!this.lastBounds || this.lastBounds.searched != this.dsearchString || this.lastBounds.size != this.avail.length) {
                        this.cleanDrivers();

                        map.setZoom(map.getZoom());
                        /*	var newlatlng = new google.maps.LatLng(this.avail[0].current_location_latitude, this.avail[0].current_location_longitude);

            map.setCenter(newlatlng);
            this.driverZoom = map.getZoom();

            map.setZoom(this.driverZoom);

            */
                        /** get all drivers coordinates and set map bounds **/

                        const pointers = [];
                        for (let p = 0; p < this.avail.length; p++) {
                            const obj = {
                                lat: this.avail[p].current_location_latitude,
                                lng: this.avail[p].current_location_longitude,
                            };
                            pointers.push(obj);
                        }
                        this.setBoundsNow(pointers);
                    } else {
                    }
                }
            }

            let url;
            let urlGoing;
            const image = {
                url: url,
                scaledSize: new google.maps.Size(33, 33),
                anchor: new google.maps.Point(16.5, 16.5),
            };

            // consider only active drivers
            this.avail = this.avail.filter((x) => x.is_available === 1);

            let avl = new Array();
            if (this.tripDetailMode == true || this.returnRideMode == true) {
                const driver = this.avail.find((x: any) => x.driver_id === this.rideInFocus.driver_id);
                if (driver) {
                    avl.push(_.pick(driver, 'current_location_latitude', 'current_location_longitude'));
                    this.avail = [driver];
                    const driverObject = { lat: driver.current_location_latitude, lng: driver.current_location_longitude };

                    if (this.ride_status_text === 'Picking Up') {
                        this.setCenterNow([this.strtlocaObj, driverObject]);
                        const distance = this.getDistance(this.strtlocaObj, driverObject);
                        if (distance < 500) {
                            if (this.initialRideView) {
                                const interval = setTimeout(() => {
                                    this.fitBounds();
                                }, 2000);
                                this.initialRideTimeOut.push(interval);
                            } else {
                                this.fitBounds();
                            }
                        } else this.fitBounds();
                    } else if (this.ride_status_text === 'En Route') {
                        const distance = this.getDistance(this.mdesObj, driverObject);
                        this.setCenterNow([this.mdesObj, driverObject]);
                        if (distance < 500) this.fitBounds(1);
                        else this.fitBounds();
                    } else if (this.ride_status_text === 'Arrived') {
                        const distance = this.getDistance(this.strtlocaObj, driverObject);
                        this.setCenterNow([this.strtlocaObj, driverObject]);
                        if (this.initialRideView) {
                            map.setZoom(this.defaultZoom);
                            // this.fitBounds();
                            const interval = setTimeout(() => {
                                if (this.ride_status_text === 'Arrived') {
                                    // map.setZoom(this.driverZoom);
                                    this.fitBounds();
                                }
                                this.initialRideView = false;
                            }, 2000);
                            this.initialRideTimeOut.push(interval);
                        } else this.fitBounds();
                    } else {
                        this.setCenterNow();
                        map.setZoom(this.defaultZoom);
                    }
                }
            } else {
                _.each(this.avail, (key, value) => {
                    avl.push(_.pick(key, 'current_location_latitude', 'current_location_longitude'));
                });
            }
            for (let i = 0; i < avl.length; i++) {
                if (this.avail[i].is_free == 1) {
                    if (this.avail[i].car_type == 2) {
                        url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                        urlGoing = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
                    } else if (this.avail[i].car_type == 1) {
                        url = 'assets/img/driver_idle.svg';
                        urlGoing = 'assets/img/driver_intransit.svg';
                    } else if (this.avail[i].car_type == 3) {
                        url = 'assets/carTypeImage/LUXE/3_White_LUXE.svg';
                        urlGoing = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
                    } else if (this.avail[i].car_type == 4) {
                        url = 'assets/carTypeImage/Grande/3_White_Grande.svg';
                        urlGoing = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
                    } else {
                        url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                        urlGoing = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
                    }
                } else {
                    if (this.avail[i].car_type == 1) {
                        url = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
                    } else if (this.avail[i].car_type == 2) {
                        url = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
                    } else if (this.avail[i].car_type == 3) {
                        url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
                    } else {
                        url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
                    }
                }

                let content =
                    "<div id='" +
                    this.avail[i].driver_id +
                    "' style='font-size: 9px; padding: 8px'>" +
                    "<div style=' class='p-2'>" +
                    this.avail[i].driver_name.slice(0, 20) +
                    '</div>';

                if (this.ride_status_text === 'Picking Up' || this.ride_status_text == 'En Route') {
                    const time = this.ride_status_text === 'Picking Up' ? this.rideInFocus.estimate_pickup_time : this.rideInFocus.estimate_diff;
                    content =
                        "<div class='detail' id='" +
                        this.avail[i].driver_id +
                        "' style='font-size: 9px;'>" +
                        "<div style='background: #1f8dc6; color: white' class='p-2'>" +
                        time +
                        'mins | ' +
                        this.ride_status_text +
                        "</div> <div class='p-1 mr-2'>" +
                        this.avail[i].driver_name.slice(0, 20) +
                        '</div><br>' +
                        '</div>';
                } else if (this.ride_status_text === 'Arrived') {
                    content =
                        "<div class='detail' id='" +
                        this.avail[i].driver_id +
                        "' style='font-size: 9px;'>" +
                        "<div style='background: #1f8dc6; color: white' class='p-2'>" +
                        this.ride_status_text +
                        "</div> <div class='p-1 mr-2'>" +
                        this.avail[i].driver_name.slice(0, 20) +
                        '</div><br>' +
                        '</div>';
                }

                let infowindow = new google.maps.InfoWindow({
                    content,
                    disableAutoPan: true,
                });
                image.url = `${url}#${this.avail[i].driver_id}`;
                const marker = this.setMarker(this.avail[i], { image, infowindow, rotation });

                if (this.tripDetailMode == true || this.returnRideMode == true) {
                    bounds.extend(marker.position);
                    map.setCenter(bounds.getCenter());
                    zoomlevel = map.getZoom();
                    map.setZoom(zoomlevel);
                }
            }
        });

        //   this.completedRequestSubscription = this.socketService.on('corporateCompletedRequests').subscribe((data) => {
        //     this.total_completed_rides = data.data.count;
        //     if (this.selectedIndex <= 1 && this.filterRideType === 'completed') this.paginateRides(data.data.count, 'completed');

        //     const CompletedData = data.data.paginated_rides;

        //     this.completedRides = [];
        //     let i = 1;
        //     CompletedData.forEach((ride: any) => {
        //         let r = ride;

        //         if (ride.start_time) {
        //             const dt = new Date(ride.start_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             const raw = dt.toISOString();
        //             r.start_time_raw = raw;
        //             r.start_time_original = ride.start_time;
        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         }
        //         if (ride.drop_time) {
        //             const dt = new Date(ride.drop_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             const raw = dt.toISOString();
        //             r.drop_time_raw = raw;
        //             r.drop_time_original = ride.drop_time;
        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         }
        //         if (ride.pickup_time) {
        //             const dt = new Date(ride.pickup_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             // const raw = dt.toISOString();
        //             //r.pickup_time_raw =  raw;
        //             r.pickup_time_processed = ride.pickup_time;
        //             r.pickup_time_original = ride.pickup_time;

        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         }

        //         if (r.tzName == 'India Standard Time' || r.tzName == 'GMT+5:30') {
        //             r.tzName = 'IST';
        //         }

        //         // if (i == 1) {
        //         //this.showonm(r);
        //         // }
        //         i++;
        //         this.completedRides.push(r);
        //         if (!this.stopCompleted) {
        //             if (this.classExpand == 1) {
        //                 if (this.rideInFocus.session_id == r.session_id) {
        //                     this.expandDisc(r);
        //                 }
        //             }
        //         }
        //     });
        //     this.isRideLoading = false;
        // });

        // this.scheduledRidesSubscription = this.socketService.on('corporateScheduledRides').subscribe((data) => {
        //     this.total_scheduled_rides = data.data.count;
        //     if (this.selectedIndex === 0 && this.filterRideType != 'completed') this.paginateRides(data.data.count, 'scheduled');

        //     // this.scheduledPages = parseInt((this.totalItemsScheduled / this.ridesPerPage + 1).toString());

        //     // this.scheduledFrom = (this.scheduledCurrentPage - 1) * this.ridesPerPage + 1;

        //     // this.scheduledTo = this.scheduledFrom - 1 + this.ridesPerPage;

        //     // if (this.scheduledTo >= this.totalItemsScheduled) {
        //     //     this.scheduledTo = this.totalItemsScheduled;
        //     //     this.hideNextscheduled = 1;
        //     // }

        //     // if (this.scheduledCurrentPage == 1) {
        //     //     this.hidePrevscheduled = 1;
        //     //     this.hideNextscheduled = 0;
        //     // } else if (this.scheduledCurrentPage == this.scheduledPages) {
        //     //     this.hidePrevscheduled = 0;
        //     //     this.hideNextscheduled = 1;
        //     // } else if (this.scheduledTo >= this.totalItemsScheduled) {
        //     //     this.hidePrevscheduled = 0;
        //     //     this.hideNextscheduled = 1;
        //     // } else {
        //     //     this.hidePrevscheduled = 0;
        //     //     this.hideNextscheduled = 0;
        //     // }

        //     // if (this.scheduledPages <= 1) {
        //     //     this.hidePrevscheduled = 1;
        //     //     this.hideNextscheduled = 1;
        //     // }

        //     const ScheduledRides = data.data.paginated_rides;

        //     this.scheduledrides = [];
        //     ScheduledRides.forEach((ride: any) => {
        //         if (ride.session_id == 10007) {
        //         }

        //         let r = ride;
        //         r.tzName = '';
        //         if (ride.pickup_time) {
        //             const dt = new Date(ride.pickup_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             const raw = dt.toISOString();
        //             /**** r.pickup_time =  raw; ***/
        //             r.pickup_time_processed = raw;

        //             //r.pickup_time_raw =  raw;
        //             r.pickup_time_original = ride.pickup_time;

        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         }

        //         if (ride.start_time) {
        //             const dt = new Date(ride.start_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             const raw = dt.toISOString();
        //             r.start_time = raw;
        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         }

        //         if (r.tzName == 'India Standard Time' || r.tzName == 'GMT+5:30') {
        //             r.tzName = 'IST';
        //         }
        //         const bounceData = ride.sent_to.split(',');

        //         if (ride.sent_to != '') {
        //             r.bounce = bounceData.length;
        //         } else {
        //             r.bounce = 0;
        //         }

        //         let userName = ride?.user_name?.split(' ') || [];
        //         let firstname, lastname;
        //         if (userName.length > 2) {
        //             firstname = userName[0];
        //             lastname = userName[1] + ' ' + userName[2];
        //         } else if (userName.length == 2) {
        //             firstname = userName[0];
        //             lastname = userName[1];
        //         } else {
        //             firstname = ride.user_name;
        //             lastname = '';
        //         }

        //         r.User_firstname = firstname;
        //         r.User_lastname = lastname;

        //         r.estimate_pickup_time_original = ride.estimate_pickup_time;
        //         r.estimate_pickup_time = Math.ceil(ride.estimate_pickup_time);
        //         r.estimate_pickup_time = Math.abs(r.estimate_pickup_time);

        //         r.estimate_diff_original = ride.estimate_diff;
        //         r.estimate_diff = Math.ceil(ride.estimate_diff);
        //         r.estimate_diff = Math.abs(r.estimate_diff);

        //         r.ride_status_text = this.get_ride_status(ride);
        //         r.ride_status_text_class = this.getButtonClass(ride.ride_status, ride.request_status, ride.is_active);

        //         this.scheduledrides.push(r);

        //         if (this.routeOffID) {
        //             if (r.session_id == this.routeOffID) {
        //                 this.showTripDetailCard();
        //                 this.expandDisc(r);
        //                 this.showonm(r);

        //                 localStorage.removeItem('routeOff');
        //                 this.routeOffID = null;
        //             }
        //         }

        //         if (this.classExpand == 1) {
        //             if (this.rideInFocus.session_id == r.session_id) {
        //                 this.expandDisc(r);
        //             }
        //         }
        //     });
        //     this.isRideLoading = false;
        // });

        // this.regularRidesSubscription = this.socketService.on('corporateRegularRides').subscribe((data) => {
        //     this.total_regular_rides = data.data.count;
        //     if (this.selectedIndex === 1 && this.filterRideType != 'completed') this.paginateRides(data.data.count, 'regular');
        //     // this.totalItemsRegular = parseInt(data.data.count);
        //     // this.regularPages = parseInt(`${this.totalItemsRegular / this.ridesPerPage + 1}`);

        //     // this.regularFrom = (this.regularCurrentPage - 1) * this.ridesPerPage + 1;

        //     // this.regularTo = this.regularFrom - 1 + this.ridesPerPage;

        //     // if (this.regularTo >= this.totalItemsRegular) {
        //     //     this.regularTo = this.totalItemsRegular;
        //     //     this.hideNext = 1;
        //     // }

        //     // if (this.regularCurrentPage == 1) {
        //     //     this.hidePrev = 1;
        //     //     this.hideNext = 0;
        //     // } else if (this.regularCurrentPage == this.regularPages) {
        //     //     this.hidePrev = 0;
        //     //     this.hideNext = 1;
        //     // } else if (this.regularTo >= this.totalItemsRegular) {
        //     //     this.hidePrev = 0;
        //     //     this.hideNext = 1;
        //     // } else {
        //     //     this.hidePrev = 0;
        //     //     this.hideNext = 0;
        //     // }

        //     // if (this.regularPages <= 1) {
        //     //     this.hidePrev = 1;
        //     //     this.hideNext = 1;
        //     // }

        //     const RegularRides = data.data.paginated_rides;

        //     this.regular_rides = [{}, {}, {}, {}, {}, {}, {}, {}, {}, {}];
        //     const newRegularData = [];

        //     // var statscroll = $('table.table-full:last').scrollTop();

        //     RegularRides.forEach((ride: any, index: number) => {
        //         let r = ride;
        //         if (r.ride_status == 3) {
        //         }
        //         r.tzName = '';
        //         if (ride.start_time) {
        //             const dt = new Date(ride.start_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             const raw = dt.toISOString();
        //             /***Old COde***/
        //             /***r.start_time =  raw;***/

        //             /***New COde***/
        //             r.start_time_processed = raw;
        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         } else if (ride.pickup_time) {
        //             const dt = new Date(ride.pickup_time);
        //             dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
        //             const raw = dt.toISOString();
        //             /****r.pickup_time =  raw;***/
        //             r.pickup_time_processed = raw;
        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         } else if (ride.date) {
        //             const dt = new Date(ride.date);
        //             /*dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset())
        //   var raw = dt.toISOString();
        //   r.date =  raw;*/
        //             r.tzName = dt.toLocaleString('en', { timeZoneName: 'short' }).split(' ').pop();
        //         }

        //         if (r.tzName == 'India Standard Time' || r.tzName == 'GMT+5:30') {
        //             r.tzName = 'IST';
        //         }
        //         let string = '00:00';
        //         r.timer = string;
        //         if (ride.ride_status == 0 && ride.is_active == 1) {
        //             const givendate = new Date(ride.date);
        //             const givenmilliseconds: any = givendate.getTime();

        //             const nowdate = new Date();
        //             const nowmilliseconds: any = nowdate.getTime();

        //             let secondsSinceBooked: any = nowmilliseconds - givenmilliseconds;
        //             secondsSinceBooked = parseInt(`${secondsSinceBooked / 1000}`);
        //             const lapsWindow = 135;
        //             if (secondsSinceBooked <= lapsWindow) {
        //                 const secsLeft = lapsWindow - secondsSinceBooked;
        //                 const totalsecs = secsLeft;

        //                 const mins: any = totalsecs / 60;
        //                 let cleanMins = parseInt(mins);

        //                 let secs = totalsecs % 60;
        //                 secs = this.addZero(secs);
        //                 cleanMins = this.addZero(cleanMins);

        //                 string = cleanMins + ':' + secs;
        //             }

        //             r.timer = string;
        //         }

        //         r.estimate_pickup_time_original = ride.estimate_pickup_time;
        //         r.estimate_pickup_time = Math.ceil(ride.estimate_pickup_time);
        //         r.estimate_pickup_time = Math.abs(r.estimate_pickup_time);

        //         //r.estimate_diff = ride.estimate_diff.replace('-','');
        //         r.estimate_diff = Math.ceil(ride.estimate_diff);
        //         r.estimate_diff = Math.abs(r.estimate_diff);

        //         // Show an active ride on the map on initial load of the page
        //         // if (ride.ride_status == 1 && !this.centering) {
        //         //     this.centering = 1;

        //         //     setTimeout(() => {
        //         //         this.fakeCenter(ride, 1);
        //         //     }, 0);
        //         // }

        //         const bounceData = ride.sent_to.split(',');

        //         if (ride.sent_to != '') {
        //             r.bounce = bounceData.length;
        //         } else {
        //             r.bounce = 0;
        //         }
        //         const userName = ride.user_name.split(' ');
        //         let firstname: any, lastname: any;
        //         if (userName.length > 2) {
        //             firstname = userName[0];
        //             lastname = userName[1] + ' ' + userName[2];
        //         } else if (userName.length == 2) {
        //             firstname = userName[0];
        //             lastname = userName[1];
        //         } else {
        //             firstname = ride.user_name;
        //             lastname = '';
        //         }

        //         r.User_firstname = firstname;
        //         r.User_lastname = lastname;

        //         r.ride_status_text = this.get_ride_status(ride);
        //         r.ride_status_text_class = this.getButtonClass(ride.ride_status, ride.request_status, ride.is_active);

        //         if (r?.ride_milestone > 0 && r?.ride_milestone !== 5) {
        //             r.next_milestone = r?.ride_milestone + 1;
        //             r.next_milestone_text = MileStone_Map[r?.next_milestone];
        //             r.is_live_milestone_trip = r?.ride_milestone <= 4;
        //             r.ride_milestone_class = MileStone_Class[r?.next_milestone];
        //         }
        //         //this.regular_rides.push(r);
        //         newRegularData.push(r);

        //         if (this.routeOffID) {
        //             if (r.session_id == this.routeOffID) {
        //                 this.showTripDetailCard();
        //                 this.expandDisc(r);
        //                 this.showonm(r);
        //                 localStorage.removeItem('routeOff');
        //                 this.routeOffID = null;
        //             }
        //         } else if (
        //             !this.view_assigned_trip &&
        //             index === 0 &&
        //             this.expand_disc_for_first_ride &&
        //             ['Accepted', 'Assigning', 'Picking Up', 'Arrived', 'En Route']?.includes(r.ride_status_text)
        //         ) {
        //             this.active_trip_data = 'regular-ride-' + r.session_id;
        //             this.showTripDetailCard();
        //             this.expandDisc(r);
        //             this.showonm(r);
        //             this.expand_disc_for_first_ride = false;
        //         }

        //         if (this.classExpand == 1) {
        //             if (this.rideInFocus.session_id == r.session_id) {
        //                 this.expandDisc(r);
        //             }
        //         }
        //     });
        //     this.isRideLoading = false;
        //     this.regular_rides = newRegularData;

        //     setTimeout(() => {
        //         this.utilityService.loading = false;
        //         // document.getElementById('loading').style.display = 'none';
        //     }, 1300);
        // });
    }

    public resetRiderSearch() {
        this.utilityService.resetTripSearch.next();
        this.searchForm.reset('', { emitEvent: false });
    }

    private getRiderList(searchString: string = '') {
        this.riderList = [];
        this.rider_loading = true;
        const params = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            searchFlag: searchString ? 1 : 0,
            searchString: searchString,
        };

        this.httpService
            .post(environment.urlC + 'associated_user_list', params)
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                if (data.flag == 101) {
                    this.router.navigate(['/', 'corporate_login']);
                }
                if (data.flag == 502) {
                } else {
                    this.riderList = data.users;
                }
            })
            .add(() => {
                this.rider_loading = false;
            });
    }

    public view_rider_trip() {
        if (!this.selected_rider) return;
        this.date_range_picker.patchValue(this.rider_mas_trip_ranger_picker.value);
        this.searchForm.patchValue(this.selected_rider?.user_name);
    }

    rad(x) {
        return (x * Math.PI) / 180;
    }

    getDistance(p1: { lat: any; lng: any }, p2: { lat: any; lng: any }) {
        const R = 6378137; // Earth’s mean radius in meter
        const dLat = this.rad(p2.lat - p1.lat);
        const dLong = this.rad(p2.lng - p1.lng);
        const a =
            Math.sin(dLat / 2) * Math.sin(dLat / 2) +
            Math.cos(this.rad(p1.lat)) * Math.cos(this.rad(p2.lat)) * Math.sin(dLong / 2) * Math.sin(dLong / 2);
        const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
        const d = R * c;
        return d; // returns the distance in meter
    }

    ngOnDestroy(): void {
        if (!!this.driverListSubscription) this.driverListSubscription.unsubscribe();
        if (!!this.completedRequestSubscription) this.completedRequestSubscription.unsubscribe();
        if (!!this.scheduledRidesSubscription) this.scheduledRidesSubscription.unsubscribe();
        if (!!this.regularRidesSubscription) this.regularRidesSubscription.unsubscribe();
        if (!!this.driverAvailablitySubscription) this.driverAvailablitySubscription.unsubscribe();

        if (!!emitRides) clearInterval(emitRides);
        if (!!gettingDrivers) clearInterval(emitRides);

        this.subscriptionList$.unsubscribe();
    }

    /** Corporate Add On */
    corporateAddOn() {
        if (this.corporateAddOnValue.value != 0) {
            this.corporate_addon = this.corporateAddOnValue.value;
            this.get_new_fare_estimateion(this.fare_factor_display);
        }
    }

    /** Dismiss Corporate add on */
    dismissCorporateAddon() {
        this.corporateAddOnValue.setValue(this.corporate_addon);
    }

    /** Open Modal */
    openModal() {
        setTimeout(() => {
            const inputValue = this.modalInput.nativeElement.value;
            if (inputValue === '0') {
                // Remove the 0
                this.modalInput.nativeElement.value = null;
            }
            // Focus the input
            this.modalInput.nativeElement.focus();
        }, 0);
    }

    /** Open Modal Ride */
    openModalRide() {
        setTimeout(() => {
            // Focus the input
            if (this.idPinValidate) {
                const inputValue = this.modalInputDiscount.nativeElement.value;
                if (inputValue === '0') {
                    // Remove the 0
                    this.modalInputDiscount.nativeElement.value = null;
                }
                this.modalInputDiscount.nativeElement.focus();
            } else {
                this.modalInputDiscountPin.nativeElement.focus();
            }
        }, 0);
    }

    /** Apply PIN */
    applyDiscount(type: string) {
        if (type === 'pin') {
            if (this.discountPin.value == 1221) {
                setTimeout(() => {
                    const inputValue = this.modalInputDiscount.nativeElement.value;
                    if (inputValue === '0') {
                        // Remove the 0
                        this.modalInputDiscount.nativeElement.value = null;
                    }
                    // Focus the input
                    this.modalInputDiscount.nativeElement.focus();
                }, 0);

                this.idPinValidate = true;
            } else {
                this.utilityService.toast('error', 'Please enter a valid 4-digit PIN.');
                this.modalInputDiscountPin.nativeElement.focus();
            }
        } else {
            if (this.rideDiscount.value != 0) {
                this.ride_discount = this.rideDiscount.value;
                this.get_new_fare_estimateion(this.fare_factor_display);
            }
        }
    }

    /** DisMiss Discount */
    dismissDiscount() {
        this.discountPin.setValue(null);
        this.rideDiscount.setValue(this.ride_discount);
    }

    /** Reset Corporate Add On And Ride Request */
    resetCorporateAddOnAndRideDiscount() {
        this.rideDiscount.setValue(0);
        this.corporateAddOnValue.setValue(0);
        this.ride_discount = 0;
        this.corporate_addon = 0;
        this.discountPin.setValue(null);
    }

    /** Multiplier Get */
    getMultiplier() {
        let params = {
            is_scheduled: 0,
            pickup_latitude: this.booking.current_latitude,
            pickup_longitude: this.booking.current_longitude,
            destination_latitude: this.booking.manual_destination_latitude,
            destination_longitude: this.booking.manual_destination_longitude,
            promo_code: this.booking.promo_code,
            web_access_token: this.cookieService.get('web_access_token'),
            car_type: this.booking.car_type,
            user_mobile: this.promoCountryCode + this.booking.user_mobile,
            avoid_tolls: this.routeOptionForm.value['avoidTolls'] ? 1 : 0,
            fare_factor: this.fare_factor,
        };
        this.subscriptionList$.add(
            this.httpService
                .postJSON(environment.urlC + 'mapped_fare_estimate', params)
                .subscribe((data) => {
                    this.recalculate_options = data;
                    this.ismultipliear = false;
                })
                .add(() => {
                    this.ismultipliear = false;
                })
        );
    }

    add_queue_tripMobile() {
        this.router.navigateByUrl('corporate/active-trips/queued');
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                isMobilePopup: true,
            },
            class: 'queud-sortcut modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(QueueTripModalComponent, initialState);
        modalref.content.event.subscribe((res: { queued: boolean; mas_trip_id: any }) => {
            if (res?.queued) {
                this.router.navigateByUrl('corporate/active-trips/queued/' + res?.mas_trip_id);
                // if (this.router.url == '/corporate/active-trips/queued') {
                //     this.utilityService.sendToQueuedTrip();
                // } else {
                //     this.router.navigateByUrl('corporate/active-trips/queued');
                // }
                // Emit event from here
            }
        });
    }

    /** Add Queued trip */
    async queue_a_new_rider() {
        console.log(this.booking);
        const check_mobile: any = await this.userService
            .check_mobile(`${this.booking?.countrycode}${this.booking?.user_mobile}`, this.booking?.user_name)
            .pipe(first())
            .toPromise();

        if (check_mobile?.error && check_mobile?.flag === 1316) {
            await this.userService
                .new_user_register(`${this.booking?.countrycode}${this.booking?.user_mobile}`, this.booking?.user_name, 1)
                .pipe(first())
                .toPromise();
        }

        this.utilityService.loading = true;
        const pickup = new google.maps.LatLng(this.booking?.latitude, this.booking?.longitude);
        const dropoff = new google.maps.LatLng(this.booking?.manual_destination_latitude, this.booking?.manual_destination_longitude);
        const eta = await this.mapService
            .get_eta({
                origin: pickup,
                destination: dropoff,
                travelMode: google.maps.TravelMode.DRIVING,
                transitOptions: {
                    departureTime: new Date(this.booking?.time),
                },
            })
            .pipe(first())
            .toPromise();
        let mas_queue_payload: any;
        mas_queue_payload = {
            firstName: this.booking?.user_name,
            lastName: '',
            user_mobile: `${this.booking?.countrycode}${this.booking?.user_mobile}`,
            pickupDate: moment.utc(this.booking?.timeInThatArea).format('MM/DD/YYYY'),
            pickup_time: moment.utc(this.booking?.timeInThatArea).format('hh:mm a'),
            pickupAddress: {
                lat: this.booking?.latitude,
                lon: this.booking?.longitude,
            },
            dropoffAddress: {
                lat: this.booking?.manual_destination_latitude,
                lon: this.booking?.manual_destination_longitude,
            },
            pickup_location_address: this.booking?.pickup_location_address,
            dropoff_location_address: this.booking?.manual_destination_address,
            tripLegId: 0,
            invoiceNumber: 0,
            legMileage: null,
            car_type: this.booking?.car_type,
            ride_estimate_time: eta?.value,
            trip_leg_sequence: 0,
            estimated_fare: this.booking?.estimated_fare,
            fare_factor: this.booking?.fare_factor,
            corporate_addon: this.corporate_addon,
            ride_discount: this.ride_discount,
            avoid_highways: this.booking?.avoid_highways ? this.booking?.avoid_highways : this.routeOptionForm.controls?.avoidHighways?.value ? 1 : 0,
            avoid_tolls: this.booking?.avoid_tolls ? this.booking?.avoid_tolls : this.routeOptionForm.controls?.avoidTolls?.value ? 1 : 0,
        };
        this.masQueueService
            .queue_mas_trip(mas_queue_payload)
            .subscribe(
                (res: { flag: number; error: string; log: string; mas_trip_id: any }) => {
                    if (!res?.error) {
                        this.utilityService.toast('success', 'Trip queued successfully.');

                        this.utilityService.hideModalList(['add_to_account']);
                        this.router.navigateByUrl('corporate/active-trips/queued/' + res?.mas_trip_id);
                    } else {
                        this.utilityService.toast('error', res.error);
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err.message);
                }
            )
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    /** Current Location  */
    isPickupLocation: boolean = false;
    isDropOffLocation: boolean = false;
    @ViewChild('dropInput', { static: false }) dropInput: ElementRef;
    getCurrentLocation(type: string) {
        type === 'pickup_location' ? (this.isPickupLocation = true) : (this.isDropOffLocation = true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Success callback
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    const latlng = new google.maps.LatLng(lat, lng);
                    // Use Geocoding service to get the address
                    this.getAddressFromLatLng(latlng).then(
                        (address: string) => {
                            // Patch the values to the form group
                            if (type === 'pickup_location') {
                                this.booking.current_latitude = lat;
                                this.booking.current_longitude = lng;
                                this.booking.latitude = lat;
                                this.booking.longitude = lng;
                                this.booking.pickup_location_address = address;
                                this.booking.pickup_text = address;
                            } else {
                                this.booking.manual_destination_address = address;
                                this.booking.manual_destination_latitude = lat;
                                this.booking.manual_destination_longitude = lng;
                                this.booking.drop_text = address;
                                this.dropInput.nativeElement.focus();
                            }
                            if (this.booking.current_latitude && this.booking.manual_destination_latitude) {
                                this.findPlace(type === 'pickup_location' ? 'pickup' : 'drop');
                            }
                            this.isPickupLocation = false;
                            this.isDropOffLocation = false;
                        },
                        (error) => {
                            this.utilityService.toast(
                                'error',
                                `Oops! We couldn't fetch your address. Please allow location access to enhance your experience.`
                            );
                            this.isPickupLocation = false;
                            this.isDropOffLocation = false;
                        }
                    );
                },
                (error) => {
                    // Error callback
                    this.utilityService.toast(
                        'error',
                        `Oops! We couldn't fetch your address. Please allow location access to enhance your experience.`
                    );
                    this.isPickupLocation = false;
                    this.isDropOffLocation = false;
                },
                { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
            );
        } else {
            this.utilityService.toast('error', 'Geolocation is not supported by your browser');
            this.isPickupLocation = false;
            this.isDropOffLocation = false;
        }
    }

    /** Get Address From Lat Lng */
    getAddressFromLatLng(latlng: google.maps.LatLng): Promise<string> {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        resolve(results[0].formatted_address);
                    } else {
                        reject('No address found');
                    }
                } else {
                    reject(`Geocoder failed due to: ${status}`);
                }
            });
        });
    }

    /** Window Resize */
    isMobileScreen = false;
    activeMobileMenu: string = '';
    from: string;
    private checkScreenWidth(): void {
        this.isMobileScreen = window.innerWidth <= 600;
        // if (this.isMobileScreen) {
        //     this.activeMobileMenu = 'addRide';
        // } else {
        //     this.activeMobileMenu = 'viewMap';
        // }
    }

    /** Open Mobile Design Fare Estimate modal */
    fareEstimateRecalc() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                formValue: this.booking,
                from: this.from,
            },
            class: 'queud-sortcut modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(FareEstimateRecalculationComponent, initialState);
        modalref.content.event.subscribe((res: any) => {
            if (res) {
                let splitResult = res.user_mobile.split('-');
                this.booking.countrycode = splitResult[0];
                this.booking.user_mobile = splitResult[1];
                this.booking.estimated_fare = res.estimated_fare;
                this.booking.corporate_addon = res.corporate_addon;
                this.booking.fare_factor = res.fare_factor;
                this.booking.ride_discount = res.ride_discount;
                this.booking.avoid_highways = res.avoid_highways ? 1 : 0;
                this.booking.avoid_tolls = res.avoid_tolls ? 1 : 0;
                this.booking.car_type = res.car_type;
                this.booking.select_car_type = res.car_type;
                if (res.from == 'queued') {
                    this.queue_a_new_rider();
                } else if (res.from == 'now') {
                    this.booking.ride_estimate_distance = res.ride_estimate_distance;
                    this.booking.ride_estimate_time = res.ride_estimate_time;
                    this.timeSelected(res.from);
                } else if (res.from == 'later') {
                    this.booking.ride_estimate_distance = res.ride_estimate_distance;
                    this.booking.ride_estimate_time = res.ride_estimate_time;
                    this.timeScheduler();
                }
            }
        });
    }

    /** Open Time Selection Popup */
    open_time_selection_popup() {
        console.log('if');
        const bookingDate = new Date();
        bookingDate.setHours(bookingDate.getHours() + 1);
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                minDate: new Date(),
                maxDate: moment(new Date()).add(6, 'days')['_d'],
                bookingDate: this.booking.timeInThatArea || new Date(),
                rightButtonText: 'Select',
                viewMode: 'full',
                date: this.booking.timeInThatArea ? this.booking.timeInThatArea : new Date(),
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(DateTimePickerComponent, initialState);
        modalref.content.event.subscribe((res: { date: Date }) => {
            if (res?.date) {
                this.booking.timeInThatArea = new Date(res.date);
                // this.booking.timeInThatArea = moment(res.date).format('MM/DD/YYYY hh:mm a');
            }
        });
    }
}
