<!--  

    Note: Side nav is not currently in use.
    In order to add it back 
    1. remove *ngIf="false" at line 9

-->

<div class="bg-light border-right side_navigation no-select" id="sidebar-wrapper" *ngIf="false">
    <div class="list-group list-group-flush accordion" id="accordion">
        <a
            class="list-group-item bg-light svg-effect"
            [routerLink]="['/', 'corporate', 'active-trips', 'unassigned']"
            [class.active-tab]="activeTab === 'active-trips'"
        >
            <svg width="16" height="18" viewBox="0 0 16 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M14.2222 2V16H1.77778V2H14.2222ZM15.2 0H0.8C0.355556 0 0 0.4 0 0.9V17.1C0 17.5 0.355556 18 0.8 18H15.2C15.5556 18 16 17.5 16 17.1V0.9C16 0.4 15.5556 0 15.2 0ZM7.11111 4H12.4444V6H7.11111V4ZM7.11111 8H12.4444V10H7.11111V8ZM7.11111 12H12.4444V14H7.11111V12ZM3.55556 4H5.33333V6H3.55556V4ZM3.55556 8H5.33333V10H3.55556V8ZM3.55556 12H5.33333V14H3.55556V12Z"
                />
            </svg>

            Trip Roster
        </a>

        <a
            class="list-group-item bg-light svg-effect"
            [routerLink]="['/', 'corporate', 'attestation', 'dispatched']"
            [class.active-tab]="activeTab === 'attestation'"
        >
            <svg width="12" height="14" viewBox="0 0 12 14" xmlns="http://www.w3.org/2000/svg">
                <path
                    d="M10.6667 1.33333H7.88C7.6 0.56 6.86667 0 6 0C5.13333 0 4.4 0.56 4.12 1.33333H1.33333C0.6 1.33333 0 1.93333 0 2.66667V12C0 12.7333 0.6 13.3333 1.33333 13.3333H10.6667C11.4 13.3333 12 12.7333 12 12V2.66667C12 1.93333 11.4 1.33333 10.6667 1.33333ZM6 1.33333C6.36667 1.33333 6.66667 1.63333 6.66667 2C6.66667 2.36667 6.36667 2.66667 6 2.66667C5.63333 2.66667 5.33333 2.36667 5.33333 2C5.33333 1.63333 5.63333 1.33333 6 1.33333ZM7.33333 10.6667H2.66667V9.33333H7.33333V10.6667ZM9.33333 8H2.66667V6.66667H9.33333V8ZM9.33333 5.33333H2.66667V4H9.33333V5.33333Z"
                />
            </svg>
            Attestation
        </a>
        <!-- <a
            [routerLink]="['/', 'corporate', 'completed-trips']"
            class="list-group-item bg-light"
            [class.active-tab]="activeTab == 'trips'"
            (click)="setRegularDefault()"
        >
            <span class="icons icon-scheduled_car"></span>
            Trips
        </a> -->
        <a
            class="list-group-item bg-light dropdown_menu collapsed"
            [class.active-tab]="activeTab == 'riders' || activeTab == 'qudosfave'"
            aria-expanded="true"
        >
            <div [routerLink]="['/', 'corporate', 'completed-trips']">
                <span class="icons icon-scheduled_car"></span>
                Trips
                <i class="fa drop-icon ml-2 fa-angle-down" aria-hidden="true"></i>
            </div>
            <div id="network" class="dropMenu collapse show">
                <ul>
                    <li class="open">
                        <a [routerLink]="['/', 'corporate', 'completed-trips']" [class.active-tab]="activeTab == 'riders'">
                            <span class="icons icon-scheduled_car"></span>
                            Trip History
                        </a>
                    </li>
                    <li class="open">
                        <a [routerLink]="['/', 'corporate', 'live-tracking']" (click)="utilityService.open_trip_by_rider()">
                            <span class="icons icon-user"></span>
                            Search Trips by Rider
                        </a>
                    </li>
                </ul>
            </div>
        </a>
        <a
            class="list-group-item bg-light dropdown_menu collapsed"
            [class.active-tab]="activeTab == 'riders' || activeTab == 'qudosfave'"
            aria-expanded="true"
        >
            <div [routerLink]="['/', 'corporate', 'riders', 'my-riders']">
                <span class="icons icon-network"></span>
                Network
                <i class="fa drop-icon ml-2 fa-angle-down" aria-hidden="true"></i>
            </div>
            <div id="network" class="dropMenu collapse show">
                <ul>
                    <li class="open">
                        <a [routerLink]="['/', 'corporate', 'riders', 'my-riders']" [class.active-tab]="activeTab == 'riders'">
                            <span class="icons icon-user"></span>
                            Riders List
                        </a>
                    </li>
                    <li class="open">
                        <a [routerLink]="['/', 'corporate', 'drivers', 'all-drivers']" [class.active-tab]="activeTab == 'qudosfave'">
                            <span class="icons icon-scheduled_car"></span>
                            QudosFave™ Driver
                        </a>
                    </li>
                </ul>
            </div>
        </a>
        <a class="list-group-item bg-light" [routerLink]="['/', 'corporate', 'cards']" [class.active-tab]="activeTab == 'settings'">
            <span class="icons icon-notification"></span>
            Settings
        </a>
        <a
            class="list-group-item bg-light"
            [routerLink]="['/', 'corporate', 'notifications', 'list']"
            [class.active-tab]="activeTab === 'notifications'"
        >
            <span class="icons icon-Vector"></span>
            Notifications
            <ng-container *ngIf="totalNotifications > 0">( {{ totalNotifications.toString().padStart(2, '0') }} )</ng-container>
        </a>
    </div>
</div>
