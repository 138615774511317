<div class="profile_top">
    <div class="profile_left">
        <div class="media align-items-center">
            <div class="media-left">
                <img [src]="image" onerror="this.src='assets/images/person_placeholder.png'" alt="Detail Image" />
            </div>
            <div class="media-body">
                <h2>
                    <div class="d-flex align-items-center">
                        <div>{{ name | ShortText | titlecase }}</div>
                        <div class="text-orange ml-2" *ngIf="is_mas_driver">MAS</div>

                        <div class="live-indicator-block ml-3" *ngIf="show_active_ride_label">
                            <span class="live-indicator">
                                <i class="fa fa-circle blink" aria-hidden="true"></i>
                                IN ACTIVE RIDE
                            </span>
                        </div>

                        <!-- <div class="ml-2 d-flex text-white fs-10 active_ride_label" *ngIf="show_active_ride_label">
                            <div class="bg-red px-2 label">IN ACTIVE RIDE</div>

                            <div class="bg-grey close-icon cursor-pointer btn-group no-caret" dropdown #force_close_dropdown>
                                <button id="close-dropdown-btn" type="button" class="dropdown-toggle" dropdownToggle aria-controls="close-dropdown">
                                    <mat-icon class="va-middle text-white">close</mat-icon>
                                </button>
                                <ul
                                    id="close-dropdown"
                                    *dropdownMenu
                                    class="dropdown-menu cd-menu left-0 first card-shadow p-4 br-6"
                                    style="min-width: 150px !important"
                                    role="menu"
                                    aria-labelledby="close-dropdown-btn"
                                >
                                    <div class="text-grey mb-3">Forced End Trip</div>
                                    <div class="d-flex text-blue">
                                        <div class="cursor-pointer" (click)="force_close_dropdown.isOpen = false">Yes</div>
                                        <div class="cursor-pointer ml-4" (click)="force_close_dropdown.isOpen = false">Dismiss</div>
                                    </div>
                                </ul>
                            </div>
                        </div> -->
                    </div>
                </h2>
                <h5>{{ email }}</h5>
                <h5>
                    <div class="d-flex">
                        <div>{{ mobile | phoneFormat }}</div>
                        <div class="ml-2 bg-dark-blue text-white px-3 br-4" *ngIf="device_type">{{ device_type | uppercase }}</div>
                    </div>
                </h5>
                <div class="mt-2 fs-14" *ngIf="driver_tlc_status">
                    <span class="text-green" *ngIf="driver_tlc_status === 'active'">TLC Active</span>
                    <span class="text-red" *ngIf="driver_tlc_status === 'inactive'">TLC Inactive</span>
                </div>
            </div>
        </div>
    </div>
    <div class="profile_right">
        <a onclick="history.go(-1)" class="btn btn-outline-primary btn-md tripsbtn exportBtn default_btn">Back</a>

        <div class="top_icons">
            <a *ngIf="!is_fav" (click)="add_to_fav.emit()">
                <span class="icons icon-fav"></span>
                Add to
                <br />
                My Fave
            </a>
            <a *ngIf="is_fav" (click)="remove_from_fav.emit()">
                <span class="icons icon-delete"></span>
                Remove &nbsp;from List
            </a>

            <!-- to enable the button remove disabled class  -->
            <a class="disabled" (click)="report_issue.emit()">
                <span class="icons icon-bookmark"></span>
                Report
                <br />
                an Issue
            </a>
        </div>
    </div>
</div>
