import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'app-show-driver-popover',
    templateUrl: './show-driver-popover.component.html',
    styleUrls: ['./show-driver-popover.component.scss'],
})
export class ShowDriverPopoverComponent implements OnInit {
    show_driver_popover_reference: PopoverDirective;
    @Input() show_driver: boolean = false;
    @Output() onToggle: EventEmitter<void> = new EventEmitter<void>();
    constructor() {}

    ngOnInit(): void {}
}
