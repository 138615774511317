import { Component, OnInit, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input-r';
import { country_code } from '../../pages/live-tracking/country-list';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { CardsService } from '../../services/cards/cards.service';
import { StripeCardElementOptions, StripeElementsOptions, StripeError, Token } from '@stripe/stripe-js';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { StripeCardOption, StripeElementOption } from 'src/app/core/services/utility/stripe-element.config';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-add-passenger-modal',
    templateUrl: './add-passenger-modal.component.html',
    styleUrls: ['./add-passenger-modal.component.scss'],
})
export class AddPassengerModalComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private stripeService: StripeService,
        private cardsService: CardsService,
        private utilityService: UtilityService,
        private userService: UserService
    ) {}

    title: string = 'Add a new passenger';
    cardError: string = '';
    isCardEmpty: boolean = true;
    isCardTouched: boolean = false;
    isLoading: boolean = false;
    @ViewChild(StripeCardComponent) stripeCard: StripeCardComponent;

    cardOptions: StripeCardElementOptions = StripeCardOption;
    elementsOptions: StripeElementsOptions = StripeElementOption;

    passengerForm: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
        mobile: new FormControl('', [Validators.required]), // If Mobile Only
        email: new FormControl('', [Validators.required, Validators.email]),
    });

    actionButton: string = 'Save';

    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    countryToSet: string = 'US';
    separateDialCode = false;
    countries = country_code;
    selected_country_iso: any = CountryISO.UnitedStates;

    user_id: number;
    step: 'register' | 'update_email' | 'card' = 'register';

    ngOnInit(): void {
        this.cardOptions.style.base.fontSize = '12px';
    }

    onSave() {
        if (this.passengerForm.invalid) return;
        const contact: {
            name: string;
            mobile: {
                dialCode: string;
                number: string;
            };
            email: string;
        } = this.passengerForm.value;

        if (this.step != 'register') {
            this.updateEmail(this.user_id, contact.email);
            return;
        }

        const user_mobile = `${contact.mobile.dialCode}-${contact.mobile.number}`;
        this.isLoading = true;
        this.userService.new_user_register(user_mobile, contact.name, 1).subscribe(
            (res: { user_id: number; flag: 107; error: string }) => {
                if (res?.error) {
                    if (res?.flag == 107) {
                        this.utilityService.toast('error', 'This mobile number is already registered');
                    } else {
                        this.utilityService.toast('error', res?.error);
                    }
                    this.isLoading = false;
                } else {
                    this.step = 'update_email';
                    this.user_id = res?.user_id;
                    this.updateEmail(res?.user_id, contact.email);
                }
            },
            (err) => {
                this.utilityService.toast('error', err?.message);
            }
        );
    }

    updateEmail(user_id: number, user_email: string) {
        if (this.step == 'card') {
            this.createStripeToken(user_id);
            return;
        }
        this.isLoading = true;
        this.userService
            .update_registered_user_info({
                user_id,
                user_email,
            })
            .subscribe((data) => {
                if (data?.error) {
                    this.utilityService.toast('error', data.error);
                    this.isLoading = false;
                } else {
                    this.createStripeToken(user_id);
                    this.step = 'card';
                }
            });
    }

    onStripeCardChange(event: any) {
        this.isCardEmpty = !event?.complete;

        if (event.error) {
            this.cardError = event.error.message;
        } else {
            this.cardError = '';
        }
    }

    createStripeToken(user_id: number): void {
        this.isLoading = true;
        this.stripeService.createToken(this.stripeCard.element).subscribe((result: { token?: Token; error?: StripeError }) => {
            if (result.token) {
                this.stripeTokenHandler(result.token, user_id);
            } else if (result.error) {
                console.log(result.error.message);
                this.cardError = result.error.message;
            }
        });
    }

    public stripeTokenHandler(token: Token, user_id: number) {
        const params: any = {
            nounce: token.id,
            card_type: 52,
            offset: new Date().getTimezoneOffset() * -1,
            user_id: user_id,
        };
        this.cardsService
            .add_card(params, 'rider')
            .subscribe(
                (data) => {
                    if (data.error) {
                        // this.utilityService.loading = false;
                        this.isLoading = false;
                        if (data.flag == 301) {
                            this.cardError = 'Duplicate Card, Please try another';
                        } else {
                            this.utilityService.alert('error', data.error);
                        }
                    } else {
                        this.utilityService.toast('success', 'Passenger added successfully', '');
                        this.bsModalRef.hide();
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err.message || 'Something went wrong');
                    console.log('err => ', err);
                }
            )
            .add(() => {
                this.isLoading = false;
            });
    }
}
