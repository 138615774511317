<!-- Mobile Screen Only Desgin  -->
<div class="mobile-screen-queued modal-fullscreen">
    <div class="modal-content">
        <div class="touch-header sticky-top-header">
            <div class="d-flex justify-content-between">
                <div class="ml-3 mt-4">
                    <div class="touch-tabs d-flex justify-content-between">
                        <div class="d-flex">
                            <div class="align-self-end tab active" [ngClass]="{ active: menuActive === 'fare' }" (click)="menuToggle('fare')">
                                Fare Estimate
                            </div>
                            <div class="ml-3 tab">|</div>
                            <div
                                class="align-self-end tab ml-4"
                                [ngClass]="{ active: menuActive === 'calculation' }"
                                (click)="menuToggle('calculation')"
                            >
                                Recalculation
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mr-3 mt-4">
                    <button type="button" aria-label="Close" (click)="closePopup()">
                        <img src="/assets/images/modalClose.svg" alt="closeIcon" />
                    </button>
                </div>
            </div>
            <hr class="m-0 mb-2" />
            <!-- Fare -->
            <ng-container *ngIf="menuActive === 'fare'">
                <div class="sub-header">
                    <div class="d-flex sub">
                        <div class="text-center">
                            <app-select-car-type-popover
                                [carTypes]="carDriverOptions"
                                [selectedCarType]="booking.car_type"
                                (onCarTypeChange)="changeCarType($event)"
                            >
                                <span class="otherOption">
                                    <span>Other Options</span>
                                    <span class="ml-2 span-arrow"><i class="fa fa-sort-desc" aria-hidden="true"></i></span>
                                </span>
                            </app-select-car-type-popover>
                            <!-- <h6>Total Fare</h6> -->
                            <h5 class="amount">${{ booking?.estimated_fare | number: '1.2-2' }}</h5>
                            <a (click)="open_fare_breakdown_popup(active_fare_breakdown_flag)">Fare Breakdown</a>
                        </div>
                        <div class="devider"></div>
                        <div class="text-center">
                            <h6>Trip Estimates</h6>
                            <div class="d-flex justify-content-between mb-3">
                                <div class="miles mr-2">Miles</div>
                                <div class="value">
                                    {{ trip_distance }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div class="miles mr-2">Time</div>
                                <div class="value">
                                    {{ trip_duration }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>

            <!-- Calculation -->
            <ng-container *ngIf="menuActive === 'calculation'">
                <div class="sub-header">
                    <div class="d-flex sub">
                        <div class="text-center">
                            <h6>New Total Fare</h6>
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div>
                                    <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                </div>
                            </div>
                            <ng-container *ngIf="!recalculate_price_loading">
                                <h5 class="amount">
                                    <ng-container *ngIf="recalculate_type === 'now'">
                                        {{ recalculate_state?.value_regular | currency: 'USD' }}
                                    </ng-container>
                                    <ng-container *ngIf="recalculate_type === 'later'">
                                        {{ recalculate_state.value_scheduled | currency: 'USD' }}
                                    </ng-container>
                                </h5>
                                <div
                                    class="fs-14 dark-color deducted-price my-3"
                                    [hidden]="
                                        (recalculate_type === 'now' && recalculate_state?.value_regular == fare_breakdown?.value_regular) ||
                                        (recalculate_type === 'later' && recalculate_state?.value_scheduled == fare_breakdown?.value_scheduled)
                                    "
                                >
                                    <ng-container *ngIf="recalculate_type === 'now'">
                                        {{ fare_breakdown?.value_regular | currency: 'USD' }}
                                    </ng-container>
                                    <ng-container *ngIf="recalculate_type === 'later'">
                                        {{ fare_breakdown.value_scheduled | currency: 'USD' }}
                                    </ng-container>
                                </div>
                            </ng-container>
                        </div>
                        <div class="devider"></div>
                        <div class="text-center">
                            <h6>Select New Fare</h6>
                            <div>
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div>
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <ng-container *ngIf="!ismultipliear && !recalculate_price_loading">
                                    <ng-container *ngIf="!custom_recalculate_multiplier">
                                        <select
                                            name="recalculate"
                                            [(ngModel)]="fare_factor"
                                            class="touch-input"
                                            (ngModelChange)="get_new_fare_estimateion(fare_factor)"
                                        >
                                            <option *ngFor="let option of recalculate_options" [value]="option.fare_factor">
                                                <span class="value-regular">${{ option.value_regular | number: '1.2-2' }}</span>
                                            </option>
                                        </select>
                                        <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                    </ng-container>
                                    <div *ngIf="custom_recalculate_multiplier" class="d-flex justify-content-center">
                                        <div class="touch-input">
                                            <input
                                                name="recalculate"
                                                type="text"
                                                [(ngModel)]="fare_factor"
                                                step="0.1"
                                                (keydown)="select_button_flag = true"
                                                [class.error]="fare_factor < 0"
                                            />
                                        </div>
                                    </div>
                                </ng-container>
                            </div>
                            <div class="cursor-pointer text-blue mt-2" (click)="toggle_recalculate_type()">
                                <a *ngIf="!custom_recalculate_multiplier">Custom</a>
                                <a *ngIf="custom_recalculate_multiplier">Back</a>
                            </div>
                        </div>
                    </div>
                </div>
            </ng-container>
            <!-- <hr /> -->
        </div>
        <div class="touch-content">
            <div class="custom-height">
                <!-- Fare -->
                <ng-container *ngIf="menuActive === 'fare'">
                    <div class="more-info">
                        <div class="row ml-3 mr-3">
                            <div class="col-12">
                                <div class="info mb-3">Trip Information</div>
                            </div>
                            <div class="col-6 label mb-3">Pickup Location</div>
                            <div class="col-6 value mb-3">
                                {{ booking.pickup_location_address?.toString()?.replaceAll('/', ' ') }}
                            </div>

                            <div class="col-6 label mb-3">Drop Off Location</div>
                            <div class="col-6 value mb-3">
                                {{ booking.manual_destination_address?.toString()?.replaceAll('/', ' ') }}
                            </div>

                            <div class="col-6 label mb-3">Avoid Tolls</div>
                            <div class="col-6 value mb-3">
                                <div class="custom-control custom-switch float-left">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="tolls"
                                        [(ngModel)]="avoid_tolls"
                                        name="avoid_tolls"
                                        (change)="getDirectionsForBooking(true); initializeMap()"
                                    />
                                    <label class="custom-control-label fs-14 fw-600 dark-color" for="tolls"></label>
                                </div>
                            </div>

                            <div class="col-6 label mb-3">Avoid Highways</div>
                            <div class="col-6 value mb-3">
                                <div class="custom-control custom-switch float-left">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input"
                                        id="highways"
                                        [(ngModel)]="avoid_highways"
                                        name="avoid_highways"
                                        (change)="getDirectionsForBooking(true); initializeMap()"
                                    />
                                    <label class="custom-control-label fs-14 fw-600 dark-color" for="highways"></label>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row ml-3 mr-3">
                            <div class="col-6 driver-info">Driver Information</div>
                            <div class="col-6 value select-driver">
                                <div class="dropdown">
                                    <span
                                        class="w-100"
                                        type="button"
                                        id="affiliation_menu"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <div class="d-flex justify-content-between placeholder small fw-600">
                                            <div class="mt-1">
                                                <ng-container *ngIf="!selected_driver">Select Driver</ng-container>
                                                <ng-container *ngIf="selected_driver">{{ selected_driver?.driver_name }}</ng-container>
                                            </div>
                                            <div class="align-self-center"><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                                        </div>
                                    </span>
                                    <div
                                        class="dropdown-menu p-0 card-shadow w-100 overflow-auto"
                                        id="trip_status_menu"
                                        aria-labelledby="affiliation_menu"
                                    >
                                        <div class="px-2 py-3 search-box">
                                            <span>
                                                <input
                                                    [formControl]="driverSearchControl"
                                                    class="m-0 border-0"
                                                    style="width: 79%"
                                                    type="text"
                                                    placeholder=" Search Drivers"
                                                />
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div
                                            class="dropdown-item cursor-pointer fs-13"
                                            (click)="selected_driver = { driver_name: 'Random Driver', driver_id: '' }"
                                        >
                                            Random Driver
                                        </div>
                                        <div
                                            *ngFor="let driver of drivers"
                                            class="dropdown-item cursor-pointer fs-13"
                                            (click)="selected_driver = driver; booking.driver_id = driver?.driver_id; booking.driver_selected = 1"
                                        >
                                            {{ driver.driver_name }}
                                            <span class="fs-11">{{ driver.vehicle_number }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="row ml-3 mr-3">
                            <div class="col-6 driver-info">
                                <span *ngIf="!promo_applied" (click)="open_promo_code_popup()">Promo code?</span>
                                <span *ngIf="promo_applied">
                                    Promo Applied
                                    <span class="ml-1 rb-blue-small-text cursor-pointer" (click)="open_promo_code_popup()">Edit</span>
                                </span>
                            </div>
                            <div class="col-6 driver-info">
                                <span
                                    class="rb-mid-text rb-small-size cursor-pointer"
                                    data-target="#premiumOption"
                                    (click)="open_premium_option_popup()"
                                >
                                    <div class="d-flex justify-content-start">
                                        <div class="align-self-center mr-1 mb-1">
                                            <img src="assets/images/star.svg" style="width: 16px" />
                                        </div>
                                        <div class="align-self-center">
                                            <span>
                                                Premium Option
                                                <span *ngIf="premiumOptionLabel">
                                                    {{ premiumOptionLabel }}
                                                </span>
                                            </span>
                                        </div>
                                    </div>
                                </span>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex justify-content-around rb-btn mt-5">
                            <div>
                                <button class="btn btn-secondary border-0 font-light btn-left m-2 px-3 py-1" (click)="closePopup()">Cancel</button>
                            </div>

                            <div *ngIf="from == 'list-detail'">
                                <button class="btn btn-sm rb-btn-dark-blue btn-secondary border-0 m-2 px-3 py-1" (click)="emitQueued('list-detail')">
                                    Save This Fare
                                </button>
                            </div>

                            <div *ngIf="from != 'ride-request' && from != 'list-detail'">
                                <button class="btn btn-sm rb-btn-dark-blue btn-secondary border-0 m-2 px-3 py-1" (click)="emitQueued('queued')">
                                    Queue & Save this Fare
                                </button>
                            </div>

                            <div *ngIf="from == 'ride-request'">
                                <button class="btn btn-sm rb-btn-dark-blue btn-secondary border-0 m-2 px-3 py-1" (click)="emitQueued('now')">
                                    Request Now
                                </button>
                                <button class="btn btn-sm btn-primary border-0 m-2 px-3 py-1" (click)="emitQueued('later')">Book Later</button>
                            </div>
                        </div>
                    </div>
                </ng-container>

                <!-- Calculation -->
                <ng-container *ngIf="menuActive === 'calculation'">
                    <div class="more-info">
                        <div class="row ml-3 mr-3">
                            <div class="col-12">
                                <div class="info mb-3">Trip Information</div>
                            </div>
                            <div class="col-6 label mb-3">Corporate Add on</div>
                            <div class="col-6 value mb-3">
                                <div>
                                    {{ corporateAddOnValue.value == null ? '$00.00' : '$' + corporateAddOnValue.value }}
                                    <mat-icon
                                        class="text-dark-blue fs-16 stop-propagation cursor-pointer"
                                        data-toggle="dropdown"
                                        id="dropdown"
                                        (click)="openModal()"
                                    >
                                        edit
                                    </mat-icon>
                                    <div class="dropdown-menu text-grey" aria-labelledby="download">
                                        <div class="corporate-add-on">
                                            <div class="box" stop-propagation>
                                                <div class="d-flex">
                                                    <span class="input-group-text border-0 fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                                                    <input
                                                        #modalInput
                                                        (click)="handleInputClick()"
                                                        placeholder="0"
                                                        onkeypress="return /[0-9]|\./i.test(event.key)"
                                                        type="number"
                                                        [formControl]="corporateAddOnValue"
                                                        class="border-0 fs-16 fw-600 text-dark-blue"
                                                    />
                                                </div>
                                                <div class="label mt-2 fw-600 fs-12 text-center">Corporate Add On</div>
                                            </div>
                                            <div class="d-flex justify-content-between m-3">
                                                <div class="dismiss-button cursor-pointer" (click)="dismiss()">Dismiss</div>
                                                <div
                                                    class="apply-button cursor-pointer"
                                                    (click)="applyCorporateAddOn()"
                                                    [class.disabled]="corporateAddOnValue.invalid || corporateAddOnValue?.value === null"
                                                >
                                                    Apply
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="col-6 label mb-3">Driver Payout</div>
                            <div class="col-6 value mb-3">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div>
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <ng-container *ngIf="!recalculate_price_loading">
                                    {{ recalculate_state?.submerchant_driver_payout | currency: 'USD' }}
                                    <span
                                        class="deducted-price value ml-2 align-self-center"
                                        [hidden]="recalculate_state?.submerchant_driver_payout == fare_breakdown?.submerchant_driver_payout"
                                    >
                                        {{ fare_breakdown?.submerchant_driver_payout | currency: 'USD' }}
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-6 label mb-3">Car Base Take</div>
                            <div class="col-6 value mb-3">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div>
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <ng-container *ngIf="!recalculate_price_loading">
                                    <ng-container *ngIf="recalculate_type === 'now'">
                                        {{ recalculate_state?.submerchant_client_payout | currency: 'USD' }}
                                    </ng-container>
                                    <ng-container *ngIf="recalculate_type === 'later'">
                                        {{ recalculate_state?.submerchant_client_payout_schedule | currency: 'USD' }}
                                    </ng-container>
                                    <span
                                        class="deducted-price value ml-2 align-self-center"
                                        [hidden]="
                                            (recalculate_type === 'now' &&
                                                recalculate_state?.submerchant_client_payout == fare_breakdown?.submerchant_client_payout) ||
                                            (recalculate_type === 'later' &&
                                                recalculate_state?.submerchant_client_payout_schedule ==
                                                    fare_breakdown?.submerchant_client_payout_schedule)
                                        "
                                    >
                                        <ng-container *ngIf="recalculate_type === 'now'">
                                            {{ fare_breakdown?.submerchant_client_payout | currency: 'USD' }}
                                        </ng-container>
                                        <ng-container *ngIf="recalculate_type === 'later'">
                                            {{ fare_breakdown.submerchant_client_payout_schedule | currency: 'USD' }}
                                        </ng-container>
                                    </span>
                                </ng-container>
                            </div>
                            <div class="col-6 label mb-3">Qudos Tech Fee</div>
                            <div class="col-6 value mb-3">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div>
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <ng-container *ngIf="!recalculate_price_loading">
                                    <ng-container *ngIf="recalculate_type === 'now'">
                                        {{ recalculate_state?.qudos_service_fee | currency: 'USD' }}
                                    </ng-container>
                                    <ng-container *ngIf="recalculate_type === 'later'">
                                        {{ recalculate_state.qudos_service_fee_schedule | currency: 'USD' }}
                                    </ng-container>
                                    <span
                                        class="deducted-price value ml-2 align-self-center"
                                        [hidden]="
                                            (recalculate_type === 'now' &&
                                                recalculate_state?.qudos_service_fee == fare_breakdown?.qudos_service_fee) ||
                                            (recalculate_type === 'later' &&
                                                recalculate_state?.qudos_service_fee_schedule == fare_breakdown?.qudos_service_fee_schedule)
                                        "
                                    >
                                        <ng-container *ngIf="recalculate_type === 'now'">
                                            {{ fare_breakdown?.qudos_service_fee | currency: 'USD' }}
                                        </ng-container>
                                        <ng-container *ngIf="recalculate_type === 'later'">
                                            {{ fare_breakdown.qudos_service_fee_schedule | currency: 'USD' }}
                                        </ng-container>
                                    </span>
                                </ng-container>
                            </div>
                        </div>
                        <hr />
                        <div class="row ml-3 mr-3">
                            <div class="col-12">
                                <div class="info mb-3">Ride Incentives</div>
                            </div>
                            <div class="col-6 label mb-3">Ride Discount</div>
                            <div class="col-6 value mb-3">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div>
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <div class="d-flex">
                                    <div>
                                        {{ rideDiscountControl.value == null ? '$00.00' : '$' + rideDiscountControl.value }}
                                    </div>
                                    <div>
                                        <mat-icon
                                            class="text-dark-blue fs-16 stop-propagation cursor-pointer"
                                            data-toggle="dropdown"
                                            id="dropdown"
                                            (click)="openModalRide()"
                                        >
                                            lock
                                        </mat-icon>
                                        <div class="dropdown-menu text-grey" aria-labelledby="download">
                                            <!-- Pin Input -->
                                            <div class="corporate-add-on" *ngIf="!idPinValidate">
                                                <div class="box" stop-propagation>
                                                    <div class="text-center">
                                                        <input
                                                            #modalInputDiscountPin
                                                            placeholder="0000"
                                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                                            type="text"
                                                            [formControl]="discountPin"
                                                            maxlength="4"
                                                            class="border-0 fs-16 fw-600 text-dark-blue"
                                                        />
                                                    </div>
                                                    <div class="label mt-2 fw-600 fs-12 text-center">ENTER PIN</div>
                                                </div>
                                                <div class="d-flex justify-content-between m-3">
                                                    <div class="dismiss-button cursor-pointer" (click)="dismissDiscount()">Dismiss</div>
                                                    <div class="apply-button cursor-pointer" stop-propagation (click)="applyDiscount('pin')">
                                                        Apply
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Ride Discount Input -->
                                            <div class="corporate-add-on" *ngIf="idPinValidate">
                                                <div class="box" stop-propagation>
                                                    <div class="d-flex">
                                                        <span class="input-group-text border-0 fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                                                        <input
                                                            #modalInputDiscount
                                                            placeholder="0"
                                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                                            type="number"
                                                            [formControl]="rideDiscountControl"
                                                            class="border-0 fs-16 fw-600 text-dark-blue"
                                                        />
                                                    </div>
                                                    <div class="label mt-2 fw-600 fs-12 text-center">ENTER DISCOUNT</div>
                                                </div>
                                                <div class="d-flex justify-content-between m-3">
                                                    <div class="dismiss-button cursor-pointer" (click)="dismissDiscount()">Dismiss</div>
                                                    <div
                                                        class="apply-button cursor-pointer"
                                                        (click)="applyDiscount('discount')"
                                                        [class.disabled]="
                                                            rideDiscountControl.invalid ||
                                                            rideDiscountControl?.value === null ||
                                                            recalculate_state.submerchant_client_payout - recalculate_state.other_fees <=
                                                                rideDiscountControl?.value
                                                        "
                                                    >
                                                        Apply
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex justify-content-around rb-btn mt-5">
                            <div>
                                <button class="btn btn-secondary border-0 font-light btn-left m-2 px-3 py-1" (click)="closePopup()">Cancel</button>
                            </div>
                            <div>
                                <button class="btn btn-sm rb-btn-dark-blue btn-secondary border-0 m-2 px-3 py-1" (click)="applyCalculation()">
                                    Apply
                                </button>
                            </div>
                        </div>
                    </div>
                </ng-container>
                <hr />
                <div style="position: relative" class="m-4">
                    <div id="FareEstimateCelculateMap" #FareEstimateCelculateMap class="map card-shadow"></div>
                </div>
            </div>
        </div>
    </div>
</div>
