import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

@Component({
    selector: 'app-search-box',
    templateUrl: './search-box.component.html',
    styleUrls: ['./search-box.component.scss'],
})
export class SearchBoxComponent implements OnInit {
    @Input() searchFormControl: FormControl;
    @Input() placeHolder: string = 'Search';
    @Input() extraClass = 'light';
    constructor() {}

    ngOnInit(): void {}
}
