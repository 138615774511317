<div>
    <div
        class="d-flex justify-content-center align-items-center px-2 live-label fs-13 cursor-pointer"
        [class.active]="is_live"
        [class.py-0]="minimal"
        *ngIf="isDesktop"
    >
        <div
            [popover]="live_mas_trip_popover"
            [containerClass]="containerClass"
            [outsideClick]="true"
            #liveMasTripDropdown="bs-popover"
            (click)="live_mas_trip_reference = liveMasTripDropdown"
            placement="bottom"
            container="body"
        >
            <ng-container *ngIf="!is_live">GO LIVE</ng-container>
            <ng-container *ngIf="is_live">LIVE</ng-container>
            <span>
                <mat-icon class="va-middle">expand_more</mat-icon>
            </span>
        </div>
        <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
    </div>

    <div
        class="d-flex justify-content-center align-items-center px-2 live-label fs-13 cursor-pointer"
        [class.active]="is_live"
        [class.py-0]="minimal"
        *ngIf="!isDesktop"
    >
        <div [containerClass]="containerClass" (click)="openModal(template)">
            <ng-container *ngIf="!is_live">GO LIVE</ng-container>
            <ng-container *ngIf="is_live">LIVE</ng-container>
            <span>
                <mat-icon class="va-middle">expand_more</mat-icon>
            </span>
        </div>
        <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
    </div>
    <ng-template #elseTpl><ng-content></ng-content></ng-template>
    <ng-template #header>
        <div class="py-3 px-4 fw-600 text-dark-blue fs-16">
            <ng-container *ngIf="is_live">Remove from live trip</ng-container>
            <ng-container *ngIf="!is_live">Add to Live Trips</ng-container>
        </div>
    </ng-template>
    <ng-template #body>
        <div class="p-4 text-grey fs-13">
            <ng-container *ngIf="is_live">Do you want to remove this trip from Live Trip List?</ng-container>
            <ng-container *ngIf="!is_live">Do you want to add this to Live Trip List?</ng-container>
        </div>
    </ng-template>

    <!-- Desktop Popup -->
    <ng-template #live_mas_trip_popover>
        <div class="touch-popover">
            <ng-container *ngTemplateOutlet="header"></ng-container>
            <hr class="light light-color" />
            <ng-container *ngTemplateOutlet="body"></ng-container>
            <hr class="light light-color" />
            <div class="d-flex justify-content-end text-blue2 fs-13 fw-600 py-3 px-4">
                <div class="cursor-pointer mx-4" (click)="live_mas_trip_reference.hide()">Dismiss</div>
                <div class="cursor-pointer mx-4" [class.text-danger]="is_live" [class.disabled]="isLoading" (click)="onUpdate()">
                    <ng-container *ngIf="is_live">
                        <ng-container *ngIf="isLoading">Removing...</ng-container>
                        <ng-container *ngIf="!isLoading">Remove</ng-container>
                    </ng-container>
                    <ng-container *ngIf="!is_live">
                        <ng-container *ngIf="isLoading">Adding Trip...</ng-container>
                        <ng-container *ngIf="!isLoading">Add Trip</ng-container>
                    </ng-container>
                </div>
            </div>
        </div>
    </ng-template>

    <!-- Mobile Popup -->
    <ng-template #template>
        <div class="modal-header">
            <ng-container *ngTemplateOutlet="header"></ng-container>
        </div>
        <div class="modal-body py-3 px-4">
            <ng-container *ngTemplateOutlet="body"></ng-container>
        </div>
        <div class="modal-footer d-flex justify-content-between text-blue2 fs-13 fw-600 py-3 px-4">
            <div class="cursor-pointer py-3 px-4 fw-600" (click)="modalRef.hide()">Dismiss</div>
            <div class="cursor-pointer py-3 px-4 fw-600" [class.text-danger]="is_live" [class.disabled]="isLoading" (click)="onUpdate()">
                <ng-container *ngIf="is_live">
                    <ng-container *ngIf="isLoading">Removing...</ng-container>
                    <ng-container *ngIf="!isLoading">Remove</ng-container>
                </ng-container>
                <ng-container *ngIf="!is_live">
                    <ng-container *ngIf="isLoading">Adding Trip...</ng-container>
                    <ng-container *ngIf="!isLoading">Add Trip</ng-container>
                </ng-container>
            </div>
        </div>
    </ng-template>
</div>
