<div class="d-flex justify-content-center align-items-center px-2 prepaid-label fs-13" [ngClass]="state">
    <!-- Desktop View -->
    <div
        [popover]="prepaid_trip_popover"
        [containerClass]="containerClass + ' ml-4'"
        #prepaidPopover="bs-popover"
        (onShown)="prepaid_trip_reference = prepaidPopover; onPopoverOpen()"
        placement="bottom"
        container="body"
        class="cursor-pointer"
        *ngIf="isDesktop"
    >
        <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
        <span>
            <mat-icon class="va-middle">expand_more</mat-icon>
        </span>
    </div>

    <!-- Mobile View -->
    <div [containerClass]="containerClass + ' ml-4'" (click)="openModal(template); onPopoverOpen()" class="cursor-pointer" *ngIf="!isDesktop">
        <!-- Show the label as passsed -->
        <ng-container *ngTemplateOutlet="elseTpl"></ng-container>
        <span>
            <mat-icon class="va-middle">expand_more</mat-icon>
        </span>
    </div>
</div>

<ng-template #elseTpl><ng-content></ng-content></ng-template>

<!-- Desktop Popup -->
<ng-template #prepaid_trip_popover>
    <div class="touch-popover">
        <div class="py-3 px-4 text-dark-blue fs-16 d-flex">
            <div class="fw-600">Add to Prepaid Trips</div>
            <div class="flex-fill"></div>
            <div *ngIf="trip_payload?.trip_leg_id">Trip ID: {{ trip_payload?.trip_leg_id }}</div>
            <div *ngIf="trip_payload?.trip_leg_id" class="mx-1">|</div>
            <div>Trip Mileage: {{ trip_payload?.miles | number: '1.1' }}</div>
            <!-- <div class="vertical-line mx-3"></div>
            <div class="text-blue">
                
            </div> -->
        </div>
        <hr class="light light-color" />
        <div class="text-dark-blue px-4 fs-13 my-4">
            <div class="d-flex flex-column flex-md-row justify-content-between">
                <div class="w-350">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Driver Payout</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.submerchant_driver_payout | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <div>Qudos Driver Network Fee</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.driver_network_fee_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <div>Toll</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.toll | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mt-3 fw-600">
                        <div>Prepaid Cost (Total)</div>
                        <div class="text-blue">
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.total_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="mobile-none vertical-line mx-4" style="border-color: #828282"></div>
                <div class="md:d-none">
                    <hr />
                </div>
                <div class="w-350">
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            Mas Payout
                            <span class="cursor-pointer pl-1" (click)="open_leg_cost_info()" *ngIf="trip_payload?.invoice_number">
                                <i style="color: #323f50" class="fa fa-exclamation-circle"></i>
                            </span>
                            <span class="pl-1 text-grey" *ngIf="!trip_payload?.invoice_number">( Non Mas Trip )</span>
                        </div>
                        <div>
                            <!-- Suggested Fare Loading -->
                            <ng-container *ngIf="mas_trip_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!mas_trip_loading">
                                <ng-container *ngIf="trip_payload?.invoice_number && trip_payload?.leg_cost">
                                    {{ trip_payload?.leg_cost | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                                </ng-container>
                                <ng-container *ngIf="!trip_payload?.invoice_number || !trip_payload?.leg_cost">N/A</ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <div>Prepaid Cost (Total)</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.total_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <br />

                    <div class="d-flex justify-content-between mt-3 fw-600">
                        <div>Net Revenue</div>
                        <div class="text-blue">
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                <ng-container *ngIf="trip_payload?.leg_cost">
                                    {{
                                        (trip_payload?.leg_cost || 0) - fare_detail?.total_amount
                                            | currency: 'USD':'symbol':utilityService.show_currency_precision
                                    }}
                                </ng-container>
                                <ng-container *ngIf="!trip_payload?.leg_cost">
                                    {{ fare_detail?.total_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="light light-color" />
        <div class="d-flex fs-13 py-3 px-4">
            <div class="text-grey d-flex align-items-center">
                <div class="text-grey">Avoid Highways</div>
                <div class="custom-control custom-switch ml-2">
                    <input
                        type="checkbox"
                        class="custom-control-input"
                        id="willCallSwitch"
                        [(ngModel)]="avoidHighways"
                        (ngModelChange)="getSuggestedFare()"
                    />
                    <label class="custom-control-label fs-14 fw-600 dark-color" for="willCallSwitch"></label>
                </div>
                <!-- <ng-container *ngIf="state !== 'prepaid'">Do you want to add this trip to Prepaid Trip list?</ng-container>
                <ng-container *ngIf="state === 'prepaid'">Do you want to remove this trip from Prepaid Trip list?</ng-container> -->
            </div>
            <div class="flex-fill"></div>
            <div class="cursor-pointer mx-4 text-blue2 fw-600" (click)="prepaid_trip_reference.hide()">Dismiss</div>
            <div
                class="cursor-pointer mx-4 text-blue2 fw-600"
                (click)="
                    onAddTrip.emit({ total_amount: fare_detail?.total_amount, driver_pay: fare_detail?.submerchant_driver_payout });
                    prepaid_trip_reference.hide()
                "
                [class.disabled]="suggested_fare_loading || disableAction"
                *ngIf="state !== 'prepaid'"
            >
                Add Trip
            </div>
            <div
                class="cursor-pointer mx-4 text-red2 fw-600"
                (click)="onRemoveTrip.emit(); prepaid_trip_reference.hide()"
                [class.disabled]="suggested_fare_loading || disableAction"
                *ngIf="state === 'prepaid'"
            >
                Remove Trip
            </div>
        </div>
    </div>
</ng-template>

<ng-template #prepaid_loading_template>
    <app-loading type="placeholder" width="45px" margin="0px" borderRadius="4px" height="12px"></app-loading>
</ng-template>

<!-- Mobile Popup -->
<ng-template #template>
    <div class="modal-header">
        <div class="py-3 px-4 text-dark-blue fs-16">
            <div class="fw-600">Add to Prepaid Trips</div>
            <div class="flex-fill"></div>
            <div *ngIf="trip_payload?.trip_leg_id">Trip ID: {{ trip_payload?.trip_leg_id }}</div>
            <div *ngIf="trip_payload?.trip_leg_id" class="mx-1">|</div>
            <div>Trip Mileage: {{ trip_payload?.miles | number: '1.1' }}</div>
        </div>
    </div>
    <div class="modal-body">
        <div class="text-dark-blue px-4 fs-13 my-4">
            <div class="d-flex flex-column flex-md-row justify-content-between">
                <div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>Driver Payout</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.submerchant_driver_payout | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <div>Qudos Driver Network Fee</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.driver_network_fee_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <div>Toll</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.toll | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center mt-3 fw-600">
                        <div>Prepaid Cost (Total)</div>
                        <div class="text-blue">
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.total_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="mobile-none vertical-line mx-4" style="border-color: #828282"></div>
                <div class="md:d-none">
                    <hr />
                </div>
                <div>
                    <div class="d-flex justify-content-between align-items-center">
                        <div>
                            Mas Payout
                            <span class="cursor-pointer pl-1" (click)="open_leg_cost_info()" *ngIf="trip_payload?.invoice_number">
                                <i style="color: #323f50" class="fa fa-exclamation-circle"></i>
                            </span>
                            <span class="pl-1 text-grey" *ngIf="!trip_payload?.invoice_number">( Non Mas Trip )</span>
                        </div>
                        <div>
                            <!-- Suggested Fare Loading -->
                            <ng-container *ngIf="mas_trip_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!mas_trip_loading">
                                <ng-container *ngIf="trip_payload?.invoice_number && trip_payload?.leg_cost">
                                    {{ trip_payload?.leg_cost | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                                </ng-container>
                                <ng-container *ngIf="!trip_payload?.invoice_number || !trip_payload?.leg_cost">N/A</ng-container>
                            </ng-container>
                        </div>
                    </div>

                    <div class="d-flex justify-content-between align-items-center">
                        <div>Prepaid Cost (Total)</div>
                        <div>
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                {{ fare_detail?.total_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>

                    <br />

                    <div class="d-flex justify-content-between mt-3 fw-600">
                        <div>Net Revenue</div>
                        <div class="text-blue">
                            <ng-container *ngIf="suggested_fare_loading">
                                <ng-container [ngTemplateOutlet]="prepaid_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!suggested_fare_loading">
                                <ng-container *ngIf="trip_payload?.leg_cost">
                                    {{
                                        (trip_payload?.leg_cost || 0) - fare_detail?.total_amount
                                            | currency: 'USD':'symbol':utilityService.show_currency_precision
                                    }}
                                </ng-container>
                                <ng-container *ngIf="!trip_payload?.leg_cost">
                                    {{ fare_detail?.total_amount | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                                </ng-container>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <div class="md:d-none">
                    <hr />
                </div>
                <div class="text-grey d-flex align-items-center justify-content-between">
                    <div class="text-grey">Avoid Highways</div>
                    <div class="custom-control custom-switch ml-2">
                        <input
                            type="checkbox"
                            class="custom-control-input"
                            id="willCallSwitch"
                            [(ngModel)]="avoidHighways"
                            (ngModelChange)="getSuggestedFare()"
                        />
                        <label class="custom-control-label fs-14 fw-600 dark-color" for="willCallSwitch"></label>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal-footer d-flex justify-content-between">
        <div class="text-grey py-3 px-4 fw-600" (click)="modalRef.hide()">Dismiss</div>
        <div
            class="text-blue2 py-3 px-4 fw-600"
            (click)="onAddTrip.emit({ total_amount: fare_detail?.total_amount, driver_pay: fare_detail?.submerchant_driver_payout }); modalRef.hide()"
            [class.disabled]="suggested_fare_loading || disableAction"
            *ngIf="state !== 'prepaid'"
        >
            Add Trip
        </div>
        <div
            class="text-red2 py-3 px-4 fw-600"
            (click)="onRemoveTrip.emit(); modalRef.hide()"
            [class.disabled]="suggested_fare_loading || disableAction"
            *ngIf="state === 'prepaid'"
        >
            Remove Trip
        </div>
    </div>
</ng-template>
