<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <app-trip-roster-header
            [hideTitleLayer]="minimal_setup"
            [preventRouteAction]="minimal_setup"
            (onTabChange)="onTabChange.emit($event)"
            headerTitle="Trip Roster"
            [tabList]="utilityService.settingHeaderConfig.active_trips.tabs"
            activeTab="Unassigned"
            [searchControl]="searchForm"
            buttonText="Export CSV"
            (buttonClick)="generateCSV()"
            [subTabList]="unassignedSubTabList"
            [activeSubTab]="activeSubTab"
            (subTabClick)="onSubTabChanged($event)"
            [showStartSessionAction]="true"
            [filterOption]="true"
            [filters]="headerOptions"
            (onFilterEvent)="onFilterEvent($event)"
            [isFilterActive]="isFilterActive"
        >
            <!-- Render Rider Earning Overview and Prepaid rides -->
            <app-ride-earning-overview
                ride_earning_overview
                [hideRiderAnalytics]="true"
                [hidePrepaidLabel]="true"
                [prepaidDate]="active_trip_date_picker"
                [refetchTrigger]="refetchPrepaidSubject"
            ></app-ride-earning-overview>
            <!-- <div class="d-flex mx-2" filter_options>
                <div class="d-flex align-items-center">
                    <div
                        class="text-spacegrey fs-13 align-self-center fw-600 cursor-pointer"
                        (click)="toggleStandingFilter(!isStandingOrderTrip)"

                    >
                        Standing Order
                        <span *ngIf="!isStandingOrderTrip">On</span>
                        <span *ngIf="isStandingOrderTrip">Off</span>
                        :
                        <span class="text-blue-secondary">{{ alter_standing_trips }}</span>
                    </div>
                </div>
                <div>
                    <a
                        data-target="initialDropdown"
                        class="touch-border-frame tripsbtn"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        style="padding-right: 28px !important"
                    >
                        <span class="dropdown-toggle">
                            <span class="light-weight">Trip Leg</span>
                            :
                            <span class="label">{{ filter_type | titlecase }}</span>
                        </span>
                    </a>
                    <div class="dropdown-menu cd-menu first card-shadow" aria-labelledby="initialDropdown">
                        <div class="dropdown-item cd-item cursor-pointer" (click)="onFilterApplied('all')">All</div>
                        <div class="dropdown-item cd-item cursor-pointer" (click)="onFilterApplied('initial')">Initial</div>
                    </div>
                </div>
                <div class="mx-1">
                    <app-time-range-picker
                        labelType="icon"
                        [default]="{ start: null, end: null }"
                        (onTimeChange)="onTimePickerChange($event)"
                        [reset]="resetSubject.asObservable()"
                    ></app-time-range-picker>
                </div>
            </div> -->
        </app-trip-roster-header>
    </div>

    <div class="table-container trip-roster touch-scrollbar" (scroll)="onScroll($event)">
        <div class="page-header"></div>
        <table class="table table-responsive trips dtrips">
            <thead #tableHeader>
                <tr class="frow">
                    <th scope="col" style="min-width: 70px">Action</th>
                    <th scope="col" style="opacity: 1; min-width: 150px">
                        <div class="dropdown broker-status">
                            <a
                                data-target="brokerDropdown"
                                class="touch-border-frame tripsbtn"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span class="dropdown-toggle">
                                    <span class="light-weight">{{ masService.brokerStatusLabel }}</span>
                                    <img class="dropdown-polygon" src="/assets/images/Polygon.svg" alt="" />
                                </span>
                            </a>
                            <div class="dropdown-menu cd-menu first card-shadow" aria-labelledby="brokerDropdown">
                                <div class="dropdown-item cd-item cursor-pointer" (click)="selectRideBroker('All Brokers')">All Brokers</div>
                                <div
                                    class="dropdown-item cd-item cursor-pointer"
                                    *ngFor="let broker of this.listRideBrokers"
                                    (click)="selectRideBroker(broker.company_name)"
                                >
                                    {{ broker.company_name }}
                                </div>
                            </div>
                        </div>
                    </th>
                    <!-- <th scope="col" style="min-width: 160px">Invoice & Trip ID</th> -->
                    <th scope="col" style="min-width: 160px">Assign Driver</th>
                    <!-- <th scope="col">Rider Details</th> -->
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Search Riders" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="cursor-pointer" style="min-width: 200px" (click)="toggleAttestationSort()">
                        Pickup Date & Time
                        <i *ngIf="sort_order === 'desc'" class="fa fa-arrow-down" aria-hidden="true"></i>
                        <i *ngIf="sort_order === 'asc'" class="fa fa-arrow-up" aria-hidden="true"></i>
                    </th>

                    <th scope="col" class="large">
                        <div class="w-100 px-2 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input
                                            class="m-0 border-0 mx-2"
                                            type="text"
                                            placeholder="Search Pickup"
                                            [(ngModel)]="pickup_search"
                                            (ngModelChange)="processFilters()"
                                        />
                                    </div>
                                    <div>
                                        <i *ngIf="!pickup_search" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            *ngIf="pickup_search"
                                            (click)="pickup_search = null"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 px-2 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill text-left">
                                        <input
                                            class="m-0 border-0 mx-2"
                                            type="text"
                                            placeholder="Search Drop off"
                                            [(ngModel)]="dropoff_search"
                                            (ngModelChange)="processFilters()"
                                        />
                                    </div>
                                    <div>
                                        <i *ngIf="!dropoff_search" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            *ngIf="dropoff_search"
                                            (click)="dropoff_search = null"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <!-- <th scope="col">Pickup Location</th>
                    <th scope="col">Drop off Location</th> -->
                    <th scope="col">Leg Mileage</th>
                </tr>
            </thead>

            <tbody *ngIf="isLoading && mas_trips?.length == 0">
                <tr class="no-hover">
                    <td colspan="9" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="mas_trips && mas_trips.length > 0" class="break-word">
                <tr
                    *ngFor="
                        let trip of mas_trips
                            | paginate
                                : {
                                      id: 'activeTrips',
                                      itemsPerPage: itemsPerPage,
                                      currentPage: pageNo,
                                      totalItems: total_MAS_items
                                  };
                        let i = index
                    "
                    class="bg-placeholder-trip"
                    [ngClass]="trip?.active_class + ' ' + trip?.inactive_class"
                >
                    <td class="book_btns align-left">
                        <div class="bd-highlight highlight align-self-center mr-1 tool" data-tip="More Options">
                            <img data-toggle="dropdown" class="hover-shadow rounded" id="dropdown" src="/assets/images/more_horiz_rounded.svg" />
                            <div
                                class="dropdown-menu cd-menu grey-item left-0 card-shadow text-grey w-auto"
                                style="width: auto !important"
                                aria-labelledby="download"
                            >
                                <div class="dropdown-item cd-item custom-go-prepaid-icon">
                                    <app-prepaid-trip-popover
                                        [trip_payload]="{
                                            pickup_latitude: trip.pickupAddress?.lat,
                                            pickup_longitude: trip.pickupAddress?.lon,
                                            destination_latitude: trip.dropoffAddress?.lat,
                                            destination_longitude: trip.dropoffAddress?.lon,
                                            car_type: 1,
                                            pickup_date: trip?.pickup_datetime,
                                            trip_leg_id: trip?.tripLegId,
                                            invoice_number: trip?.invoiceNumber,
                                            miles: trip?.legMileage
                                        }"
                                        (onAddTrip)="queue_mas_trip(trip, false, $event?.total_amount)"
                                    >
                                        Go Prepaid
                                    </app-prepaid-trip-popover>
                                </div>
                                <div class="dropdown-item cd-item" (click)="book_mas_ride(trip)">Dispatch this Trip</div>
                                <div class="dropdown-item cd-item" (click)="cancelTrip(trip)">Cancel this Trip</div>
                                <div class="dropdown-item cd-item" (click)="open_live_map_view(i)">View map</div>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div class="d-flex align-items-center">
                            <div class="broker-value">
                                {{
                                    trip?.type === 'sentryMS'
                                        ? trip?.trip_type === 'marketplace'
                                            ? 'SMS-MP'
                                            : trip?.trip_type === 'assigned'
                                            ? 'SMS-TPT'
                                            : trip?.type
                                        : trip?.type
                                }}
                                <!-- <app-prepaid-trip-popover
                                    [trip_payload]="{
                                        pickup_latitude: trip.pickupAddress?.lat,
                                        pickup_longitude: trip.pickupAddress?.lon,
                                        destination_latitude: trip.dropoffAddress?.lat,
                                        destination_longitude: trip.dropoffAddress?.lon,
                                        car_type: 1,
                                        pickup_date: trip?.pickup_datetime,
                                        trip_leg_id: trip?.tripLegId,
                                        invoice_number: trip?.invoiceNumber,
                                        miles: trip?.legMileage
                                    }"
                                    (onAddTrip)="queue_mas_trip(trip, false, $event?.total_amount)"
                                >
                                    GO PREPAID
                                </app-prepaid-trip-popover> -->
                            </div>
                            <div class="mx-2"></div>
                            <div
                                class="tool"
                                [class.disable-icon]="loading_unassigned_trip_container?.includes('queuing:' + trip?.tripLegId)"
                                (click)="queue_mas_trip(trip)"
                                data-tip="Queue this Trip"
                            >
                                <img class="image-icon h-17 cursor-pointer d-flex" src="/assets/images/queue.svg" />
                            </div>
                        </div>
                    </td>

                    <!-- <td>
                        <app-live-queue-trip-popover [fromUnassigned]="true" (onChange)="queue_mas_trip(trip, true)">
                            <div class="d-flex align-items-center">
                                <div>|</div>
                                <div style="height: 22px" class="ml-1" (click)="open_mas_trip_info_popup(trip)">
                                    <img style="height: 31px" src="/assets/images/white-bordered-exclamation.svg" alt="trip_info" />
                                </div>
                            </div>
                        </app-live-queue-trip-popover>
                    </td> -->

                    <td>
                        <ng-container
                            *ngIf="
                                loading_unassigned_trip_container?.includes('assigning:' + trip?.tripLegId) ||
                                loading_unassigned_trip_container?.includes('queuing:' + trip?.tripLegId) ||
                                loading_unassigned_trip_container?.includes('canceling:' + trip?.tripLegId)
                            "
                        >
                            <div class="px-3 py-1 br-6 fw-600 fs-11 sticky-loading-box">Updating...</div>
                        </ng-container>
                        <ng-container
                            *ngIf="
                                !loading_unassigned_trip_container?.includes('assigning:' + trip?.tripLegId) &&
                                !loading_unassigned_trip_container?.includes('queuing:' + trip?.tripLegId) &&
                                !loading_unassigned_trip_container?.includes('canceling:' + trip?.tripLegId)
                            "
                        >
                            <div class="cmodal" align="right" style="width: 160px">
                                <div class="dropdown">
                                    <span
                                        class="w-100"
                                        type="button"
                                        id="affiliation_menu"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                        (click)="reset_driver_results('select_driver_dropdown_input:' + trip?.tripLegId)"
                                    >
                                        <div class="d-flex placeholder align-items-center small fw-600">
                                            <div>Assign Driver</div>
                                            <div class="mx-1"><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                                            <div class="mx-1 op-7" stop-propagation>|</div>
                                            <div stop-propagation>
                                                <!-- <img src="assets/images/preferred_driver_icon.svg" alt="preferred_driver"> -->
                                                <app-preferred-driver-list
                                                    [user_info]="{ user_name: trip?.rider_name, user_mobile: trip?.user_mobile }"
                                                    (onAssign)="assign_driver($event, trip)"
                                                    view_type="popover"
                                                ></app-preferred-driver-list>
                                            </div>
                                        </div>
                                    </span>
                                    <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                                    <div
                                        class="p-0 overflow-auto dropdown-menu auto cd-menu first card-shadow"
                                        style="width: 230px !important; max-height: 300px"
                                        id="trip_status_menu"
                                        aria-labelledby="affiliation_menu"
                                    >
                                        <div class="px-2 py-3 search-box">
                                            <span>
                                                <input
                                                    [id]="'select_driver_dropdown_input:' + trip?.tripLegId"
                                                    [formControl]="driver_search_control"
                                                    class="m-0 border-0"
                                                    style="width: 79%"
                                                    type="text"
                                                    placeholder=" Search Drivers"
                                                />
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <ng-container *ngIf="cached_drivers?.length">
                                            <div
                                                *ngFor="let driver of cached_drivers"
                                                class="dropdown-item cursor-pointer fs-13 d-flex"
                                                (click)="assign_driver(driver, trip)"
                                            >
                                                <div>
                                                    {{ driver.driver_name }}
                                                </div>
                                                <div class="tlc-image">
                                                    <div class="ml-2 tool tool-right" data-tip="TLC Active" *ngIf="driver?.is_tlc_active == '1'">
                                                        <img src="assets/images/circle-check.svg" alt="tlc_active" />
                                                    </div>
                                                    <div class="ml-2 tool tool-right" data-tip="TLC Inactive" *ngIf="driver?.is_tlc_active == '0'">
                                                        <img src="assets/images/circle-x.svg" alt="tlc_active" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="mb-1">
                                                <hr />
                                            </div>
                                        </ng-container>
                                        <ng-container *ngIf="!driverLoading">
                                            <div
                                                *ngFor="let driver of drivers"
                                                class="dropdown-item cursor-pointer fs-13 d-flex"
                                                (click)="assign_driver(driver, trip)"
                                            >
                                                <div>
                                                    {{ driver.driver_name }}
                                                </div>
                                                <div class="tlc-image">
                                                    <div class="ml-2 tool tool-right" data-tip="TLC Active" *ngIf="driver?.is_tlc_active == '1'">
                                                        <img src="assets/images/circle-check.svg" alt="tlc_active" />
                                                    </div>
                                                    <div class="ml-2 tool tool-right" data-tip="TLC Inactive" *ngIf="driver?.is_tlc_active == '0'">
                                                        <img src="assets/images/circle-x.svg" alt="tlc_active" />
                                                    </div>
                                                </div>
                                            </div>
                                        </ng-container>

                                        <div *ngIf="driverLoading" class="mx-2">
                                            <app-loading
                                                *ngFor="let item of [0, 1, 2, 3, 4, 5]"
                                                height="20px"
                                                borderRadius="2px"
                                                type="placeholder"
                                            ></app-loading>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                    <td>
                        <span class="cursor-pointer hover-text-highlight" (click)="view_rider_detail(trip?.rider_name, trip?.user_mobile)">
                            {{ trip?.rider_name }}
                        </span>
                        <br />
                        {{ trip?.user_mobile | phoneFormat }}
                        <mat-icon
                            class="text-dark-blue fs-16 ml-1"
                            style="vertical-align: middle; padding-top: 2px"
                            stop-propagation
                            (click)="edit_passenger_info(trip)"
                        >
                            edit
                        </mat-icon>
                    </td>
                    <td>
                        <ng-container *ngIf="trip?.pickupDate">
                            {{ trip?.pickup_datetime | date: 'MMMM d, y' }}
                            <br />
                        </ng-container>
                        <ng-container *ngIf="trip?.pickupTime">
                            {{ trip?.pickup_datetime | date: 'h:mm a' }}
                        </ng-container>
                        <ng-container *ngIf="!trip?.pickupDate && !trip?.pickupTime">N/A</ng-container>
                    </td>
                    <td class="break-word-format">
                        <ng-container *ngIf="trip?.pickup_location_address">
                            {{ trip?.pickup_location_address }}
                            <span class="fw-600 cursor-pointer text-dark-grey view-in-live-map" (click)="open_live_map_view(i)">View map</span>
                        </ng-container>
                        <ng-container *ngIf="!trip?.pickup_location_address">N/A</ng-container>
                    </td>
                    <td>
                        <ng-container *ngIf="trip?.dropoff_location_address">
                            {{ trip?.dropoff_location_address }}
                        </ng-container>
                        <ng-container *ngIf="!trip?.dropoff_location_address">N/A</ng-container>
                    </td>
                    <td style="text-align: left !important">{{ trip?.legMileage | number: '1.2' }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="mas_trips && mas_trips.length == 0 && !isLoading">
                <tr class="no-hover">
                    <td colspan="9" class="text-center">No Data Found!</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="custom_pagination_v2">
    <div class="d-flex flex-row-reverse">
        <ng-container *ngIf="!isLoading">
            <div class="align-self-center" *ngIf="showPagination">
                <pagination-controls id="activeTrips" responsive="true" (pageChange)="getPage($event)"></pagination-controls>
            </div>

            <div class="p-2 bd-highlight highlight align-self-center ml-3 dropdown cursor-pointer">
                <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                    <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                    <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
                </div>
                <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                    <div
                        *ngFor="let item of unassign_display_filter_list"
                        class="dropdown-item text-center cd-item text-spacegrey"
                        (click)="getPage(1, item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="text-spacegrey fs-13 align-self-center fw-600 ml-3">
                Results:
                <span class="text-blue-secondary">{{ total_MAS_items }}</span>
            </div>

            <!-- <div class="text-spacegrey fs-13 align-self-center fw-600 cursor-pointer" (click)="toggleStandingFilter(!isStandingOrderTrip)">
                Standing Order
                <span *ngIf="!isStandingOrderTrip">On</span>
                <span *ngIf="isStandingOrderTrip">Off</span>
                :
                <span class="text-blue-secondary">{{ alter_standing_trips }}</span>
            </div> -->
        </ng-container>
        <ng-container *ngIf="isLoading">
            <app-loading type="placeholder" count="1" width="560px" height="24px"></app-loading>
        </ng-container>

        <div class="flex-fill"></div>
        <div class="search-box light bottom-search d-none d-md-block" *ngIf="showBottomSearchBox">
            <span class="input-group">
                <div class="d-flex w-100">
                    <div class="flex-fill text-left">
                        <input
                            class="m-0 border-0 w-100 grey-placeholder"
                            type="text"
                            placeholder="Search Drop off"
                            [(ngModel)]="dropoff_search"
                            (ngModelChange)="processFilters()"
                        />
                    </div>
                    <div>
                        <i *ngIf="!dropoff_search" class="fa fa-search pr-0" aria-hidden="true"></i>
                        <mat-icon *ngIf="dropoff_search" (click)="dropoff_search = null" class="cursor-pointer" style="vertical-align: middle">
                            close
                        </mat-icon>
                    </div>
                </div>
            </span>
        </div>
        <div class="mr-3 search-box light bottom-search d-none d-md-block" *ngIf="showBottomSearchBox">
            <span class="input-group">
                <div class="d-flex w-100">
                    <div class="flex-fill">
                        <input
                            class="m-0 border-0 w-100 grey-placeholder"
                            type="text"
                            placeholder="Search Pickup"
                            [(ngModel)]="pickup_search"
                            (ngModelChange)="processFilters()"
                        />
                    </div>
                    <div>
                        <i *ngIf="!pickup_search" class="fa fa-search pr-0" aria-hidden="true"></i>
                        <mat-icon *ngIf="pickup_search" (click)="pickup_search = null" class="cursor-pointer" style="vertical-align: middle">
                            close
                        </mat-icon>
                    </div>
                </div>
            </span>
        </div>
        <div class="mr-3 ml-4 search-box light bottom-search d-none d-md-block" *ngIf="showBottomSearchBox">
            <span class="input-group">
                <div class="d-flex w-100">
                    <div class="flex-fill">
                        <input [formControl]="searchForm" class="m-0 border-0 w-100 grey-placeholder" type="text" placeholder="Search Riders" />
                    </div>
                    <div>
                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                        <mat-icon *ngIf="searchForm.value" (click)="searchForm.reset()" class="cursor-pointer" style="vertical-align: middle">
                            close
                        </mat-icon>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>

<app-fare-estimate [booking]="trip_to_book" (onBooking)="onDispatched()"></app-fare-estimate>

<!-- Add Fav Modal -->
<div id="acceptSentryMS" class="modal fade" role="dialog" data-keyboard="false" data-backdrop="static">
    <div class="modal-dialog modal-dialog-auto modal-dialog-centered">
        <div class="modal-content shadow">
            <div class="mx-5 mt-5 mb-2 title">Accept SentryMS - Marketplace</div>
            <div class="modal-body p-0">
                <hr class="confirm mb-0" />
                <div class="message-bg">
                    <div class="message p-5">Are you sure you want to accept & add this Trip?</div>
                </div>
                <hr class="confirm mt-0" />
                <div align="center" class="m-3">
                    <button (click)="cancelTripModal()" class="btn btn-primary btn-left m-2 px-3 py-1" data-dismiss="modal" type="submit">
                        Cancel
                    </button>
                    <button class="btn btn-secondary btn-right m-2 px-3 py-1" data-dismiss="modal" (click)="acceptTrip()">Yes</button>
                </div>
            </div>
        </div>
    </div>
</div>
