import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IBank } from 'src/app/models/business-center.model';
import { Driver } from 'src/app/models/driver.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-select-driver-modal',
    templateUrl: './select-driver-modal.component.html',
    styleUrls: ['./select-driver-modal.component.scss'],
})
export class SelectDriverModalComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private httpService: HttpService,
        public utilityService: UtilityService,
        private cookieService: CookieService
    ) {}

    selected_driver: Partial<Driver>;

    title: string = 'Add Preferred Driver';
    public event: EventEmitter<any> = new EventEmitter();
    private subscriptionList$: Subscription = new Subscription();
    driverLoading: boolean = false;
    drivers: Array<Driver> = [];
    // confirm_transfer: boolean = false;

    bankAccounts: Array<IBank> = [];
    driver_search_control: FormControl = new FormControl();
    ngOnInit(): void {
        this.getDrivers();
        this.subscriptionList$.add(
            this.driver_search_control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                value = value.trim();
                this.getDrivers(value);
            })
        );
    }

    getDrivers(searchString: string = '') {
        this.driverLoading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: 3, // All Associated Driver
        };

        if (searchString) {
            params.searchFlag = 1;
            params.searchString = searchString;
        }

        this.subscriptionList$.add(
            this.httpService
                .post(environment.urlC + 'get_all_driver', params)
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;

                    if (data.flag !== 101 && data.flag !== 807) {
                        this.drivers = data?.drivers;
                    }
                })
                .add(() => {
                    this.driverLoading = false;
                })
        );
    }

    onAction() {
        if (!this.selected_driver) return;
        this.event.emit(this.selected_driver);
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }
}
