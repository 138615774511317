<div class="main_outer">
    <div class="profile_top profile2">
        <div class="row">
            <div class="col-md-7 pl-0">
                <div class="d-flex justify-content-between">
                    <div class="new-ui">
                        <h5>
                            <ng-container *ngIf="tripDetail?.total_with_tax">
                                {{ tripDetail?.total_with_tax | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                            <ng-container *ngIf="!tripDetail?.total_with_tax">NA</ng-container>

                            <span>Completed</span>
                        </h5>
                        <p>Session ID: {{ tripDetail?.session_id || 'NA' }} | Leg ID: {{ tripDetail?.session_id || 'NA' }}</p>
                    </div>
                    <!-- Mobile View -->
                    <div class="btn-group" dropdown container="body" placement="bottom right">
                        <button
                            id="button-basic"
                            dropdownToggle
                            type="button"
                            class="cursor-pointer mobile-icon-btn square-35 ml-2 round d-md-none"
                            aria-controls="dropdown-basic"
                        >
                            <img src="assets/images/more_option.svg" />
                        </button>
                        <ul
                            id="dropdown-basic"
                            *dropdownMenu
                            class="dropdown-menu cd-menu left-unset first card-shadow dropdown-menu-right"
                            role="menu"
                            aria-labelledby="button-basic"
                        >
                            <div class="d-md-none text-dark-blue fs-16 m-4 mb-2">Select Options</div>
                            <li role="menuitem" (click)="sendInvoice(0)">
                                <div class="dropdown-item blue cd-item cursor-pointer">Corporate</div>
                            </li>
                            <li role="menuitem" (click)="sendInvoice(1)">
                                <div class="dropdown-item blue cd-item cursor-pointer">Rider</div>
                            </li>
                            <li role="menuitem" (click)="sendInvoiceToBoth()">
                                <div class="dropdown-item blue cd-item cursor-pointer">Both</div>
                            </li>

                            <div class="d-md-none cursor-pointer my-4 text-center text-blue-secondary" (click)="filterOptionReference.hide()">
                                Dismiss
                            </div>
                        </ul>
                    </div>
                </div>
                <div class="mt-5">
                    <div class="touch-tabs d-flex justify-content-between">
                        <div class="d-flex overflow-auto">
                            <div class="align-self-end tab" [class.active]="tab_type === 'trip_info'" (click)="tab_type = 'trip_info'">Trip Info</div>

                            <div class="align-self-end tab ml-4" [class.active]="tab_type === 'fare_breakdown'" (click)="tab_type = 'fare_breakdown'">
                                Fare Breakdown
                            </div>

                            <div
                                class="align-self-end tab ml-4"
                                [class.active]="tab_type === 'milestone_detail'"
                                (click)="tab_type = 'milestone_detail'"
                            >
                                Milestones
                            </div>

                            <div class="align-self-end tab ml-4" [class.active]="tab_type === 'trip_activity'" (click)="tab_type = 'trip_activity'">
                                Trip Activity
                            </div>

                            <div class="align-self-end tab ml-4 d-md-none" [class.active]="tab_type === 'view_map'" (click)="tab_type = 'view_map'">
                                View Map
                            </div>
                        </div>
                        <div class="d-none d-md-block">
                            <button class="px-4 br-6 fs-13 fw-600 text-white btn btn-primary">
                                <img src="assets/img/SVG/print.svg" />
                                &nbsp; Print
                            </button>

                            <div class="btn-group ml-3" dropdown>
                                <button
                                    id="button-basic"
                                    dropdownToggle
                                    type="button"
                                    class="btn btn-primary-color br-6 btn-primary dropdown-toggle fs-13 fw-600 px-4"
                                    aria-controls="dropdown-basic"
                                >
                                    Resend Invoice
                                    <span class="caret"></span>
                                </button>
                                <ul
                                    id="dropdown-basic"
                                    *dropdownMenu
                                    class="dropdown-menu cd-menu first card-shadow dropdown-menu-right"
                                    role="menu"
                                    aria-labelledby="button-basic"
                                >
                                    <li role="menuitem" (click)="sendInvoice(0)">
                                        <div class="dropdown-item blue cd-item cursor-pointer">Corporate</div>
                                    </li>
                                    <li role="menuitem" (click)="sendInvoice(1)">
                                        <div class="dropdown-item blue cd-item cursor-pointer">Rider</div>
                                    </li>
                                    <li role="menuitem" (click)="sendInvoiceToBoth()">
                                        <div class="dropdown-item blue cd-item cursor-pointer">Both</div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <hr class="light" />

                    <!-- Trip Info  -->
                    <ng-container *ngIf="tab_type === 'trip_info'">
                        <div class="row main-sub-heading mt-4">
                            <!--  Passenger & Assign Information -->
                            <div class="col-md-6 col-sm-12">
                                <h2>Passenger & Assign Information</h2>
                                <div class="row">
                                    <!-- Rider Full Name  -->
                                    <div class="col-6 mb-3 info-label">Rider Full Name</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.user_name || 'NA' }}
                                    </div>

                                    <!-- Mobile Number  -->
                                    <div class="col-6 mb-3 info-label">Mobile Number</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.user_mobile || 'NA' }}
                                    </div>

                                    <div class="col-6 mb-3 info-label">Pickup Location</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.pickup_location_address || 'NA' }}
                                    </div>

                                    <div class="col-6 mb-3 info-label">Drop off Location</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.manual_destination_address || 'NA' }}
                                    </div>
                                </div>
                            </div>

                            <!--  Other Details -->
                            <div class="col-md-6 col-sm-12">
                                <h2>Other Details</h2>
                                <div class="row">
                                    <!-- Invoice Number  -->
                                    <div class="col-6 mb-3 info-label">Invoice Number</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.invoice_number || 'NA' }}
                                    </div>

                                    <!-- Driver Signature  -->
                                    <div class="col-6 mb-3 info-label">Driver Signature</div>
                                    <div class="col-6 mb-3 info-text">NA</div>

                                    <!-- Rider Signature -->
                                    <div class="col-6 mb-3 info-label">Rider Signature</div>
                                    <div class="col-6 mb-3 info-text-link">View</div>

                                    <!-- Notes -->
                                    <div class="col-6 mb-3 info-label">Notes</div>
                                    <div class="col-6 mb-3 info-text-link">Add</div>
                                </div>
                            </div>

                            <!-- Assigned Car Service Co & Driver Info -->
                            <div class="col-md-6 col-sm-12 mt-4">
                                <h2>Assigned Car Service Co & Driver Info</h2>
                                <div class="row">
                                    <!-- Corporate Details  -->
                                    <div class="col-6 mb-3 info-label">Corporate Details</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.business_name || 'NA' }}
                                        <br />
                                        {{ tripDetail?.mobile || 'NA' }}
                                    </div>

                                    <!-- Car Service Co  -->
                                    <div class="col-6 mb-3 info-label">Car Service Co</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.first_name || 'NA' }}
                                        <br />
                                        {{ tripDetail?.user_mobile || 'NA' }}
                                    </div>

                                    <!-- Driver Details -->
                                    <div class="col-6 mb-3 info-label">Driver Details</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.driver_name || 'NA' }}
                                        <br />
                                        {{ tripDetail?.driver_mobile || 'NA' }}
                                    </div>

                                    <!-- Car Details -->
                                    <div class="col-6 mb-3 info-label">Car Details</div>
                                    <div class="col-6 mb-3 info-text">
                                        <span>{{ tripDetail?.car_name || 'NA' }}</span>
                                        , {{ tripDetail?.car_model || 'NA' }}
                                        <br />
                                        <span>{{ tripDetail?.car_no || 'NA' }}</span>
                                        , {{ tripDetail?.car_make || 'NA' }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Fare Breaddown -->
                    <ng-container *ngIf="tab_type === 'fare_breakdown'">
                        <div class="row main-sub-heading mt-4">
                            <!--  Fare Details -->
                            <div class="col-md-6 col-sm-12">
                                <h2>Fare Details</h2>
                                <div class="row">
                                    <!-- Qudos tech fee  -->
                                    <div class="col-6 mb-3 info-label">Qudos tech fee</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.master_merchant_pay !== undefined && tripDetail?.master_merchant_pay !== null
                                                ? (tripDetail?.master_merchant_pay | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>

                                    <!-- Corporate add on  -->
                                    <div class="col-6 mb-3 info-label">Corporate add on</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.corporate_addon !== undefined && tripDetail?.corporate_addon !== null
                                                ? (tripDetail?.corporate_addon | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>

                                    <!-- Base Fare -->
                                    <div class="col-6 mb-3 info-label">Base Fare</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.base_fare !== undefined && tripDetail?.base_fare !== null
                                                ? (tripDetail?.base_fare | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>

                                    <!-- Est distance fare -->
                                    <div class="col-6 mb-3 info-label">Est distance fare</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.distance_fare || 'NA' | currency: 'USD' }}
                                    </div>

                                    <!-- Est time fare -->
                                    <div class="col-6 mb-3 info-label">Est time fare</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.time_fare || 'NA' | currency: 'USD' }}
                                    </div>

                                    <!-- Tip -->
                                    <div class="col-6 mb-3 info-label">Tip</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.driver_tip !== undefined && tripDetail?.driver_tip !== null
                                                ? (tripDetail?.driver_tip | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>

                                    <!-- Ride Discount -->
                                    <div class="col-6 mb-3 info-label">Ride Discount</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.ride_discount !== undefined && tripDetail?.ride_discount !== null
                                                ? (tripDetail?.ride_discount | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>
                                </div>
                            </div>

                            <!--  Other Fess -->
                            <div class="col-md-6 col-sm-12">
                                <h2>Other Fess</h2>
                                <div class="row">
                                    <!-- Toll  -->
                                    <div class="col-6 mb-3 info-label">Toll</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.toll !== undefined && tripDetail?.toll !== null ? (tripDetail?.toll | currency: 'USD') : 'NA'
                                        }}
                                    </div>

                                    <!-- Sales tax  -->
                                    <div class="col-6 mb-3 info-label">Sales tax</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{ tripDetail?.sales_tax || 'NA' | currency: 'USD' }}
                                    </div>

                                    <!-- Congestion tax -->
                                    <div class="col-6 mb-3 info-label">Congestion tax</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.congestion_tax !== undefined && tripDetail?.congestion_tax !== null
                                                ? (tripDetail?.congestion_tax | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>

                                    <!-- Black car fund -->
                                    <div class="col-6 mb-3 info-label">Black car fund</div>
                                    <div class="col-6 mb-3 info-text">
                                        {{
                                            tripDetail?.black_car_fund !== undefined && tripDetail?.black_car_fund !== null
                                                ? (tripDetail?.black_car_fund | currency: 'USD')
                                                : 'NA'
                                        }}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Milestone -->
                    <ng-container *ngIf="tab_type === 'milestone_detail'">
                        <div class="row main-sub-heading mt-4">
                            <div class="col-12">
                                <h2>Assigned trip information</h2>
                            </div>
                            <div class="col-12">
                                <div>
                                    <table class="touch-table">
                                        <tr>
                                            <td>
                                                <i style="color: #323f50; font-size: 20px" class="fa fa-exclamation-circle"></i>
                                            </td>
                                            <td>Status</td>
                                            <td>Completed</td>
                                        </tr>

                                        <tr>
                                            <td>
                                                <img src="assets/images/milestones_svgs/request_pickup.svg" alt="icon" />
                                            </td>
                                            <td>Request Pickup</td>
                                            <td>
                                                <span *ngIf="trip?.pickup_date && trip?.pickup_time">
                                                    {{ trip?.pickup_date | date: 'MMMM d, y' }} | {{ trip?.pickup_time }}
                                                    (EST) |
                                                </span>
                                                {{ trip?.pickup_location || 'NA' }}
                                            </td>
                                        </tr>

                                        <tr>
                                            <td><img src="assets/images/milestones_svgs/request_dropoff.svg" alt="icon" /></td>
                                            <td>Request Drop Off</td>
                                            <!-- TODO: Update dropoff_date, and dropoff_time key to drop_time ( It'll be a Date Object ) -->
                                            <td>
                                                <span *ngIf="trip?.ride_milestone != null && trip?.dropoff_date && trip?.dropoff_time">
                                                    {{ trip?.dropoff_date || '' | date: 'MMMM d, y' }} | {{ trip?.dropoff_time }} (EST) |
                                                </span>
                                                <span *ngIf="trip?.ride_milestone == null && trip?.drop_time">
                                                    {{ trip?.drop_time || '' | date: 'MMMM d, y' }} | {{ trip?.drop_time | date: 'hh:mm a' }} (EST) |
                                                </span>
                                                {{ trip?.dropoff_location || 'NA' }}
                                            </td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div class="col-12 mt-4">
                                <h2>Assigned trip information</h2>
                            </div>
                            <div class="col-12">
                                <table class="touch-table">
                                    <tr>
                                        <td><img src="assets/images/milestones_svgs/start_pickup.svg" alt="icon" /></td>
                                        <td>Start Pickup</td>
                                        <td>
                                            <span *ngIf="trip?.dispatch_date">
                                                {{ trip?.dispatch_date | date: 'MMMM d, y |
                                                hh:mm a' }}
                                                (EST) |
                                            </span>
                                            {{ trip?.dispatch_location || 'NA' }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><img src="assets/images/milestones_svgs/arrived.svg" alt="icon" /></td>
                                        <td>Arrived</td>
                                        <td>
                                            <span *ngIf="trip?.arrived_date">
                                                {{ trip?.arrived_date | date: 'MMMM d, y |
                                                hh:mm a' }}
                                                (EST) |
                                            </span>
                                            {{ trip?.arrived_location || 'NA' }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><img src="assets/images/milestones_svgs/start_trip.svg" alt="icon" /></td>
                                        <td>Start Trip</td>
                                        <td>
                                            <span *ngIf="trip?.starttrip_date">
                                                {{
                                                    trip?.starttrip_date | date: 'MMMM d, y |
                                                hh:mm a'
                                                }}
                                                (EST) |
                                            </span>
                                            {{ trip?.starttrip_location || 'NA' }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><img src="assets/images/milestones_svgs/arrived_dropoff.svg" alt="icon" /></td>
                                        <td>Arrived at Drop Off</td>
                                        <td>
                                            <span *ngIf="trip?.arrived_dropoff_date">
                                                {{ trip?.arrived_dropoff_date | date: 'MMMM d, y | hh:mm a' }} (EST) |
                                            </span>
                                            {{ trip?.arrived_dropoff_location || 'NA' }}
                                        </td>
                                    </tr>

                                    <tr>
                                        <td><img src="assets/images/milestones_svgs/end_trip.svg" alt="icon" /></td>
                                        <td>End Trip</td>
                                        <td>
                                            <span *ngIf="trip?.endtrip_date">
                                                {{ trip?.endtrip_date | date: 'MMMM d, y |
                                                hh:mm a' }}
                                                (EST) |
                                            </span>
                                            {{ trip?.endtrip_location || 'NA' }}
                                        </td>
                                    </tr>
                                </table>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Trip Activity -->
                    <ng-container *ngIf="tab_type === 'trip_activity'">
                        <div class="table-container trip-roster touch-scrollbar">
                            <table class="table table-responsive trips dtrips">
                                <thead #tableHeader>
                                    <tr class="frow">
                                        <th scope="col">Log ID #</th>
                                        <th scope="col">Date & Time</th>
                                        <th scope="col">
                                            <div class="dropdown ml-2">
                                                <span
                                                    type="button"
                                                    id="trip_activity_status"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <div class="d-flex justify-content-between placeholder">Status</div>
                                                </span>
                                            </div>
                                        </th>
                                        <th scope="col" style="text-align: left !important">Description</th>
                                    </tr>
                                </thead>

                                <tbody *ngIf="trip_activity && trip_activity.length > 0" class="break-word">
                                    <tr *ngFor="let activity of trip_activity">
                                        <td>
                                            {{ activity.id }}
                                        </td>
                                        <td>
                                            {{ activity.created_at | date: 'MMM dd, yyyy' }}
                                            <br />
                                            {{ activity.created_at | date: 'hh:mm a' }}
                                        </td>
                                        <td>
                                            {{ activity.status | capitalizeWithSpaces }}
                                        </td>
                                        <td style="text-align: left !important">
                                            {{ activity.description }}
                                        </td>
                                    </tr>
                                </tbody>

                                <tbody *ngIf="trip_activity && trip_activity.length == 0">
                                    <tr class="no-hover">
                                        <td colspan="9" class="text-center">No Data Found!</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </ng-container>
                    <div [ngClass]="{ 'view-map': tab_type === 'view_map' }" class="view-maps">
                        <div class="map-height" *ngIf="isMobileScreen">
                            <ng-container *ngTemplateOutlet="googleMap"></ng-container>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-md-5 map-height" *ngIf="!isMobileScreen">
                <ng-container *ngTemplateOutlet="googleMap"></ng-container>
            </div>
        </div>
    </div>
</div>
<!-- Google Map ng template -->
<ng-template #googleMap>
    <div id="map" class="map card-shadow mt-3"></div>
    <div *ngIf="imgLoading" align="center" class="img_loading">
        <app-loading></app-loading>
    </div>

    <!-- <app-google-map (driver_pickup_ETA)="driver_pickup_ETA = $event" (pickup_dropoff_ETA)="pickup_dropoff_ETA = $event"
        (encodedPolyline)="encodedPolyline = $event; disable_print_btn = false"
        [mapConfig]="mapConfig"></app-google-map> -->
</ng-template>
