import { Component, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { QueueTripModalComponent } from '../queue-trip-modal/queue-trip-modal.component';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { ContactModalComponent } from '../contact-modal/contact-modal.component';
import { AddPassengerModalComponent } from '../add-passenger-modal/add-passenger-modal.component';
import { UserService } from '../../services/user/user.service';
import { Router } from '@angular/router';

enum ShortcutEnum {
    ADD_QUEUE_TRIP = 'ADD_QUEUE_TRIP',
    ADD_PASSENGER = 'ADD_PASSENGER',
    SEND_RIDER_LINK = 'SEND_RIDER_LINK',
    NEW_RIDE_REQUEST = 'NEW_RIDE_REQUEST',
}

@Component({
    selector: 'app-add-shortcut-popover',
    templateUrl: './add-shortcut-popover.component.html',
    styleUrls: ['./add-shortcut-popover.component.scss'],
})
export class AddShortcutPopoverComponent implements OnInit {
    constructor(
        private modalService: BsModalService,
        private utilityService: UtilityService,
        private userService: UserService,
        private router: Router
    ) {}
    add_shortcut_popover_reference: PopoverDirective;

    shortcutOptions: Array<{ title: string; type: ShortcutEnum }> = [
        { title: 'Add a queue trip', type: ShortcutEnum.ADD_QUEUE_TRIP },
        { title: 'Add a new passenger', type: ShortcutEnum.ADD_PASSENGER },
        { title: 'Send a rider app link', type: ShortcutEnum.SEND_RIDER_LINK },
        { title: 'New Rider Request', type: ShortcutEnum.NEW_RIDE_REQUEST },
    ];
    isMobileScreen = false;

    ngOnInit(): void {
        this.checkScreenWidth();
        // Subscribe to window resize event to dynamically update the screen width
        window.addEventListener('resize', () => this.checkScreenWidth());
    }

    private checkScreenWidth(): void {
        this.isMobileScreen = window.innerWidth <= 767;
    }

    onShortcutSelect(type: ShortcutEnum) {
        switch (type) {
            case ShortcutEnum.ADD_QUEUE_TRIP:
                this.add_queue_trip();
                break;

            case ShortcutEnum.ADD_PASSENGER:
                this.add_new_passenger();
                break;

            case ShortcutEnum.SEND_RIDER_LINK:
                this.send_rider_link();
                break;

            case ShortcutEnum.NEW_RIDE_REQUEST:
                this.new_ride_request();
                break;
        }
        if (this.add_shortcut_popover_reference) {
            this.add_shortcut_popover_reference.hide();
        }
    }

    add_queue_trip() {
        this.router.navigateByUrl('corporate/active-trips/queued');
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                isMobilePopup: this.isMobileScreen,
            },
            class: this.isMobileScreen ? 'queud-sortcut modal-lg' : 'bg-none modal-dialog-centered modal-dialog-large w-auto modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(QueueTripModalComponent, initialState);
        modalref.content.event.subscribe((res: { queued: boolean; mas_trip_id: any }) => {
            if (res?.queued) {
                this.router.navigateByUrl('corporate/active-trips/queued/' + res?.mas_trip_id);
                // if (this.router.url == '/corporate/active-trips/queued') {
                //     this.utilityService.sendToQueuedTrip();
                // } else {
                //     this.router.navigateByUrl('corporate/active-trips/queued'+ res?.mas_trip_id);
                // }
            }
        });
    }

    new_ride_request() {
        this.router.navigate(['/', 'corporate', 'live-tracking']);
        this.utilityService.showNewRideButton(false);
    }

    add_new_passenger() {
        // Add new Passenger
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode: 'add',
                mobileOnly: true,
            },
            class: 'bg-none modal-dialog-centered modal-dialog-large w-auto modal-lg',
        };
        this.modalService.show(AddPassengerModalComponent, initialState);
    }

    send_rider_link() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode: 'add',
                actionButton: 'Send',
                mobileOnly: true,
                title: 'Send rider app link',
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((action: { mobile: string }) => {
            this.utilityService.loading = true;
            this.userService
                .send_invites(action.mobile)
                .subscribe((res) => {
                    if (res?.error) {
                        this.utilityService.toast('error', res?.error || res?.message);
                    } else {
                        this.utilityService.toast('success', res?.message);
                    }
                })
                .add(() => {
                    this.utilityService.loading = false;
                });
            modalref.hide();
        });
    }
}
