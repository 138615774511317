import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { concatMap } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IAddBalanceParams } from 'src/app/models/business-center.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

enum BusinessApis {
    GET_WALLET_BALANCE = 'get_wallet_balance',
    LOAD_WALLET_PAYMENT_HISTORY = 'load_wallet_payment_history',
    LOAD_WALLET_TRANS_HISTORY = 'load_wallet_trans_history',
    ADD_WALLET_BALANCE = 'add_wallet_balance',
    RIDE_ANALYTICS = 'ride_analytics',
    OVERVIEW_HOME_ANALYTICS = 'home_analytics',
    GET_HOME_ANALYTICS_BY_TYPE = 'home_analytics_by_type',
}

enum earningAPI {
    GET_EARNING_OVERVIEW = 'get_earning_overview',
}

@Injectable({
    providedIn: 'root',
})
export class BusinessCenterService {
    constructor(private httpService: HttpService, private cookieService: CookieService, private utilityService: UtilityService) {}

    get_topup_history(corporate_id: number | string = this.utilityService?.corporateModel?.corporate_id) {
        return this.httpService.post(environment.urlWC + BusinessApis.LOAD_WALLET_PAYMENT_HISTORY, {
            corporate_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_wallet_balance(corporate_id: number | string = this.utilityService?.corporateModel?.corporate_id) {
        return this.httpService.post(environment.urlWC + BusinessApis.GET_WALLET_BALANCE, {
            corporate_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_transaction_history(
        limit: number = 200,
        offset: number = 0,
        corporate_id: number | string = this.utilityService.corporateModel?.corporate_id
    ) {
        return this.httpService.post(environment.urlWC + BusinessApis.LOAD_WALLET_TRANS_HISTORY, {
            corporate_id,
            web_access_token: this.cookieService.get('web_access_token'),
            limit,
            offset,
        });
    }

    add_wallet_balance(params: IAddBalanceParams) {
        params.web_access_token = this.cookieService.get('web_access_token');
        params.corporate_id = this.utilityService?.corporateModel?.corporate_id;
        return this.httpService.getIpAddress().pipe(
            concatMap((client_ip) => {
                params.client_ip = client_ip;
                return this.httpService.post(environment.urlWC + BusinessApis.ADD_WALLET_BALANCE, params);
            })
        );
    }

    get_rider_analytics(payload: { start_date?: string; end_date?: string; prepaid_start_date?: string; prepaid_end_date?: string }) {
        return this.httpService.post(environment.urlWC + BusinessApis.RIDE_ANALYTICS, {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: moment().utcOffset(),
            ...payload,
        });
    }

    get_overview_analytics(payload: { request_type: number }) {
        return this.httpService.post(environment.urlC + BusinessApis.OVERVIEW_HOME_ANALYTICS, {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: moment().utcOffset(),
            ...payload,
        });
    }

    get_home_analytics_by_type(payload: { request_type: number }) {
        return this.httpService.post(environment.urlC + BusinessApis.GET_HOME_ANALYTICS_BY_TYPE, {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: moment().utcOffset(),
            ...payload,
        });
    }

    // Earning
    earning_get_home_analytics_by_type() {
        return this.httpService.post(environment.urlWC + earningAPI.GET_EARNING_OVERVIEW, {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: moment().utcOffset(),
        });
    }
}
