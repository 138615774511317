import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { Time_Picker } from 'src/app/core/components/time-range-picker/time-range-picker.component';
import { CarsOptions } from 'src/app/core/services/utility/static-data.config';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { LiveTripInfoComponent } from 'src/app/corporate/components/live-trip-info/live-trip-info.component';
import { TripBookingService } from 'src/app/corporate/services/trip-booking/trip-booking.service';
import { TripsService } from 'src/app/corporate/services/trips/trips.service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'app-dispatched-trips',
    templateUrl: './dispatched-trips.component.html',
    styleUrls: ['./dispatched-trips.component.scss'],
})
export class DispatchedTripsComponent implements OnInit {
    constructor(
        public utilityService: UtilityService,
        private router: Router,
        private httpService: HttpService,
        private cookieService: CookieService,
        private tripBookingService: TripBookingService,
        private modalService: BsModalService,
        private tripsService: TripsService
    ) {}

    // As we need same clonned logic under rider detail page, so these parameters are for Rider Detail Page usage only, as per now
    @Input() minimal_setup: boolean = false;
    @Input() rider_name_pre_filter: string;
    @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();
    @Input() prevent_tab_view: boolean = false;
    @Input() driver_id_pre_filter: string;

    showPagination: boolean = false;
    searchForm: FormControl = new FormControl('');
    isLoading: boolean = false;
    myTrips: Array<any> = [];
    showHeaderShadow: boolean = false;

    driver_search_control: FormControl = new FormControl('');
    selected_driver: any;

    pageNo: number = 1;
    itemsPerPage: number = this.utilityService.default_display_filter;
    totalItems: number = 0;
    selected_date: {
        date: Date;
        time_hour: any;
        time_minute: any;
    } = { date: new Date(), time_hour: '01', time_minute: '00' };

    minutesList: Array<any> = Array.from({ length: 60 }, (_, x) => (x < 10 ? `0${x}` : x));
    hoursList: Array<{ value: string; title: string }> = Array.from({ length: 24 }, (_, x: number) => {
        const x_string = x < 10 ? '0' + x.toString() : x.toString();
        return { value: x_string, title: `${x_string} (${x % 12 || 12} ${x < 12 ? 'AM' : 'PM'} )` };
    });

    activeSubTab: 'On-Demand' | 'Scheduled' | 'All Dispatched' = 'All Dispatched';
    active_trip_range_picker: FormGroup = new FormGroup({
        start: new FormControl(moment()),
        end: new FormControl(moment().add(1, 'days')),
    });

    time_picker: { start: any; end: any } = {
        start: '',
        end: '',
    };
    resetSubject: Subject<any> = new Subject<any>();

    ngOnInit(): void {
        this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            value = value.trim();
            this.getMyTrips(1);
        });

        this.driver_search_control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            this.getMyTrips(1);
        });

        if (this.rider_name_pre_filter) {
            this.searchForm.patchValue(this.rider_name_pre_filter);
            this.searchForm.disable();
        } else {
            this.getMyTrips(1);
        }
    }

    changeActiveSubTab(newType: 'On-Demand' | 'Scheduled' | 'All Dispatched') {
        this.activeSubTab = newType;
        this.searchForm.reset('', { emitEvent: false });
        this.selected_date = { date: new Date(), time_hour: '01', time_minute: '00' };
        this.getMyTrips(1);
    }

    public async getMyTrips(pageNo: number, itemsPerPage: number = this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
        this.pageNo = pageNo;
        const skip = (pageNo - 1) * this.itemsPerPage;
        this.utilityService.updateQueryParams({ page: pageNo });

        let payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: `${this.itemsPerPage}`,
            offset: skip,
            requestType: 3,
            is_schedule: this.activeSubTab === 'Scheduled' ? 1 : 0,
            all: this.activeSubTab === 'All Dispatched' ? 1 : 0,
        };

        if (this.searchForm.value) {
            payload.searchFlag = 1;
            payload.searchString = this.searchForm.value;
        }

        if (this.driver_search_control.value) {
            payload.driver_filter = 1;
            payload.driver_searchString = this.driver_search_control.value;
        }

        if (this.driver_id_pre_filter) {
            payload.driver_id = this.driver_id_pre_filter;
        }

        // if (this.selected_driver) {
        //     payload.driver_filter = 1;
        //     payload.driver_searchString = this.selected_driver?.driver_name;
        // }
        this.isLoading = true;
        this.myTrips = [];

        this.httpService.post(environment.urlC + 'ride_history', payload).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.flag == 101) {
                this.router.navigate(['/', 'corporate_login']);
            }
            if (data.flag == 502) {
                this.processTrips(data);
            } else this.isLoading = false;
        });
    }

    public processTrips(data: any): void {
        this.myTrips = [];
        this.totalItems = data.total_length;
        const Bookings = data.my_trips;

        Bookings.forEach((bookingData: any) => {
            if (bookingData.ride_status > 3) {
                return false;
            }
            const d = bookingData;
            d.payment_method = bookingData.payment_method || 'Card';

            d.ride_status = this.utilityService.getRideStatus(bookingData.ride_status, bookingData.request_status);
            d.ride_status_text_class = this.getButtonClass(d.ride_status);
            d.total_with_tax = Number(bookingData?.total_with_tax)?.toFixed(2) || '';
            //d.total_with_tax = parseFloat(d.total_with_tax);

            if (!d.car_name) {
                d.car_name = CarsOptions.find((x) => x.car_type == d.car_type)['car_name'];
            }

            if (bookingData.pickup_time) {
                const dt = new Date(d.pickup_time);
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                const raw = dt.toISOString();
                d.pickup_time = raw;
            }
            if (bookingData.start_time) {
                d.start_time = bookingData.start_time;
                const dt = new Date(d.start_time);
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                d.start_time = dt.toISOString();
            }
            if (
                d.ride_status != 'Completed' &&
                d.ride_status != 'Unknown' &&
                d.ride_status != 'Cancelled by driver' &&
                d.ride_status != 'Cancelled by admin' &&
                d.ride_status != 'Missed by driver'
            ) {
                this.myTrips.push(d);
            }
        });
        this.isLoading = false;
        $('.collapse').removeClass('show');
        this._setShowPagination();
    }

    private _setShowPagination() {
        this.showPagination = this.myTrips?.length > 0 && !this.isLoading;

        //this.myTrips?.length > 0 && (this.myTrips?.length >= 10 || this.pageNo != 1) && !this.isLoading && this.totalItems > this.itemsPerPage;
    }

    public getButtonClass(status: string): string {
        const green = ['Accepted'];
        const blue = ['Assigning', 'Picking Up', 'Arrived', 'En Route', 'Completed'];

        if (green.includes(status)) return 'green';
        else if (blue.includes(status)) return 'blue';
        else return 'black';
    }

    onTimePickerChange(time_picker: Time_Picker) {
        this.time_picker = time_picker;
    }

    private resetTimePicker() {
        this.resetSubject.next();
    }

    filterByDriver(driver: any) {
        this.selected_driver = driver;
        this.getMyTrips(1);
    }

    onCancelRide(trip: any) {
        console.log(trip);
        this.utilityService
            .confirm({
                heading: 'Cancel Ride?',
                description: `Are you sure you want to cancel the ${trip?.is_schedule == '0' ? 'Regular' : 'Scheduled'} ride?`,
                leftButtonText: 'No',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.utilityService.loading = true;

                    this.tripBookingService.cancelRide(trip?.session_id).subscribe(
                        (data) => {
                            if (typeof data == 'string') data = JSON.parse(data);
                            this.utilityService.loading = false;
                            if (data.error || data.flag == 0) {
                                this.utilityService.toast('error', data.error || data.message, '');
                                return;
                            } else {
                                // this.rideCancelButtonRef.nativeElement.click();
                                this.utilityService.toast('success', 'Ride Cancelled Successfully', '');
                                this.getMyTrips(1);
                            }
                        },
                        (err) => {
                            console.log('error', err);
                            this.utilityService.toast('error', err.message || 'Something went wrong!');
                            this.utilityService.loading = false;
                        }
                    );
                }
            });
    }

    live_trip_modal_ref: BsModalRef;
    async open_live_map_view(index: number) {
        const trip = this.myTrips[index];
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                // trip: this.tripsService.parse_ride_history_to_live_trip_info(trip),
                is_mas_trip: false,
                left_action_text: 'Unassign',
                right_action_text: trip?.ride_status === 3 ? 'Re-Dispatch' : 'Dispatch',
                trip_type: 'ongoing',
                trip_index: index,
                total_trips: this.myTrips,
            },
            // ignoreBackdropClick: true,
            ...this.utilityService.mapViewModal,
        };
        // this.utilityService.loading = false;
        this.live_trip_modal_ref = this.modalService.show(LiveTripInfoComponent, initialState);
        this.live_trip_modal_ref.content.event.subscribe((data: { action: 'Unassign' | 'Dispatch' | 'Re-Dispatch' | null; trip: any }) => {
            const action = data?.action;
            if (action === 'Unassign') {
                // this.update_assigned_trip(trip, {}, 'unassign');
            } else if (action === 'Dispatch' || action === 'Re-Dispatch') {
                // this.book_mas_ride(trip);
            }
        });
    }
}
