import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'app-message-popover',
    templateUrl: './message-popover.component.html',
    styleUrls: ['./message-popover.component.scss'],
})
export class MessagePopoverComponent implements OnInit {
    @Input() messageTitle: string = 'Message';
    @Input() messageDescription: string = 'Info';
    // @Input() widthClass: 'w-260' | 'w-350' = 'w-260';
    @Input() containerClass: string = 'w-260 br-6';
    @Input() delay: number = 0;
    @Input() actionText: string;
    @Input() triggers: string = 'mouseover:mouseleave';
    @Output() onAction: EventEmitter<void> = new EventEmitter<void>();

    messagePopoverReference: PopoverDirective;
    constructor() {}

    ngOnInit(): void {}
}
