import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import * as moment from 'moment';
import { Observable, Subscription } from 'rxjs';
import * as _ from 'lodash';
import { padStart } from 'lodash';

export type Time_Picker = { start: any; end: any };

@Component({
    selector: 'app-time-range-picker',
    templateUrl: './time-range-picker.component.html',
    styleUrls: ['./time-range-picker.component.scss'],
})
export class TimeRangePickerComponent implements OnInit {
    constructor() {}
    // private eventsSubscription: Subscription;

    @Input() default: Time_Picker = {
        start: padStart(moment().hour().toString(), 2, '0'),
        end: padStart(moment().endOf('day').hour().toString(), 2, '0'),
    };
    @Input() reset: Observable<void>;
    @Input() viewType: 'normal' | 'minimal' = 'normal';
    @Input() labelType: 'text' | 'icon' = 'text';
    @Input() colorTheme: 'normal' | 'white' = 'normal';
    @Input() customClass: string;

    time_picker_form: Time_Picker = {
        start: 'placeholder',
        end: 'placeholder',
    };
    time_picker: Time_Picker;

    hoursList: Array<{ value: string; title: string }> = Array.from({ length: 24 }, (_, x: number) => {
        const x_string = padStart(x.toString(), 2, '0');
        return { value: x_string, title: `${x_string} (${x % 12 || 12} ${x < 12 ? 'AM' : 'PM'} )` };
    });

    @Output() onTimeChange = new EventEmitter<Time_Picker>();

    ngOnInit() {
        // this.eventsSubscription = this.reset.subscribe((value: any) => {
        //     console.log('VALUE => ', value);
        //     this.resetTimePicker(value);
        // });

        if (this.default) {
            this.time_picker_form = { start: 'placeholder', end: 'placeholder' };
            if (this.default.end && this.default.start) {
                this.time_picker = this.default;
                this.time_picker_form = this.default;
            }
            this.applyTimePicker();
        }
    }

    // ngOnDestroy() {
    //     if (this.eventsSubscription) this.eventsSubscription.unsubscribe();
    // }

    applyTimePicker() {
        const { start: start_value, end: end_value } = this.time_picker_form;
        if (start_value && end_value && start_value != 'placeholder' && end_value != 'placeholder') {
            this.time_picker = this.time_picker_form;
            this.onTimeChange.emit(this.time_picker_form);
        }
        if (start_value === '' && end_value === '') this.time_picker_form = { start: 'placeholder', end: 'placeholder' };
    }

    resetTimePicker(value?: { start: any; end: any }) {
        this.time_picker_form = { start: 'placeholder', end: 'placeholder' };

        if (value) {
            this.time_picker = value;
            this.time_picker_form = value;
        } else {
            this.time_picker = { start: '', end: '' };
        }
        this.onTimeChange.emit(this.time_picker);
    }
}
