<div class="touch-popup" role="document">
    <div class="modal-content selection-dialog">
        <div class="modal-body p-0">
            <div class="touch-header pt-1 t-margin">
                <div class="d-flex justify-content-between mt-3 mb-2">
                    <div class="medium-text dark-color align-self-center">
                        <div class="p-0 pb-1 my-0">Trip Details</div>
                    </div>

                    <div class="d-flex justify-content-end pb-1">
                        <div class="my-0 mr-auto align-self-center text-center">
                            <div class="medium-text dark-color">
                                Invoice #
                                <span *ngIf="trip?.trips">{{ trip?.trips[0]?.invoiceNumber || '' }}</span>
                            </div>
                        </div>
                        <div class="mx-3 medium-text blue-color touch-btn align-self-center">
                            <button class="p-1 text-white btn btn-primary" (click)="utilityService.print('trip-information')">
                                <img src="assets/img/SVG/print.svg" />
                                &nbsp; Print
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-content t-margin my-4 mas-layout" id="trip-information" *ngIf="trip">
                <div class="d-flex">
                    <div class="overview">
                        <div id="general_information">
                            <div class="fs-14 semiTitle dark-color">General Information</div>
                            <div class="mt-2">
                                <MasTable
                                    *ngIf="trip?.trips"
                                    [data]="[
                                        {
                                            key: 'Medical Provider NPI',
                                            value: trip?.trips[0]?.medProviderNPI || ''
                                        },
                                        {
                                            key: 'Medicaid Number',
                                            value: trip?.trips[0]?.medicaidNumber || ''
                                        },
                                        {
                                            key: 'Invoice Number',
                                            value: trip?.trips[0]?.invoiceNumber || ''
                                        }
                                    ]"
                                ></MasTable>

                                <div class="dark-color mt-3 fs-14">
                                    <b>Please take notice: FILING FALSE STATEMENTS IS A CRIME</b>
                                    <br />
                                    If you attest to a trip that did not take place, you are filing a false statement and committing a crime.
                                </div>
                            </div>
                        </div>

                        <div id="rider_information" class="mt-3">
                            <div class="fs-14 semiTitle dark-color">Ride Information</div>
                            <MasTable
                                [data]="[
                                    {
                                        key: 'Trip for',
                                        value:
                                            trip?.trips[0]?.firstName +
                                                ' ' +
                                                trip?.trips[0]?.lastName +
                                                ' ' +
                                                trip?.trips[0]?.formated_recipient_phone || trip?.trips[0]?.recipientPhone
                                    },
                                    {
                                        key: 'Called in by',
                                        value: trip?.trips[0]?.callerName + ' ' + trip?.trips[0]?.formated_caller_phone || trip?.trips[0]?.callerPhone
                                    },
                                    { key: 'Transport Type', value: trip?.trips[0]?.transportType || '' },
                                    { key: 'PROC Code', value: trip?.trips[0]?.primaryProcedureCode || '' },
                                    { key: 'MOD', value: trip?.trips[0]?.primaryProcedureCodeModifier || '' }
                                ]"
                            ></MasTable>
                        </div>

                        <div id="other_information" class="mt-3">
                            <div class="fs-14 semiTitle dark-color">Other Information</div>
                            <MasTable
                                [data]="[
                                    {
                                        key: 'Additional Rider(s)',
                                        value: trip?.trips[0]?.instructions?.additionalRiders === true ? 'Yes' : 'No' || ''
                                    },
                                    { key: 'Car Seats', value: trip?.trips[0]?.instructions?.carSeats === true ? 'Yes' : 'No' || '' },
                                    { key: 'Service Animal', value: trip?.trips[0]?.instructions?.serviceAnimal === true ? 'Yes' : 'No' || '' }
                                ]"
                            ></MasTable>
                        </div>
                        <hr />
                        <div id="latest_vendor_activity" class="mt-3">
                            <div class="fs-14 d-flex">
                                <div class="semiTitle dark-color">Latest Vendor Activity Logs</div>
                                <div class="ml-3 cursor-pointer blue-color" (click)="utilityService.showModal('vendor_activity_logs')">View more</div>
                            </div>
                            <MasTable
                                [data]="[
                                    { key: 'Action Date & Time', value: trip?.logs ? trip?.logs[0]?.datetime || '' : '' },
                                    { key: 'Action', value: trip?.logs ? trip?.logs[0]?.action || '' : '' },
                                    { key: 'First Name', value: trip?.logs ? trip?.logs[0]?.firstName || '' : '' },
                                    { key: 'Last Name', value: trip?.logs ? trip?.logs[0]?.lastName || '' : '' }
                                ]"
                            ></MasTable>
                        </div>
                    </div>

                    <!-- vertical divider -->
                    <div class="vertical-line mx-4"></div>
                    <!-- vertical divider -->

                    <div class="trip-info">
                        <div id="trip_information1" class="mt-3">
                            <div class="fs-14 d-flex">
                                <div class="semiTitle dark-color">Trip Information #1</div>
                                <div class="ml-3 cursor-pointer grey">This trip is 1 of 2 legs</div>
                            </div>
                            <MasTable
                                [data]="[
                                    { key: 'Pickup Date & Time', value: trip?.trips[0]?.formated_pickup_date_time },
                                    {
                                        key: 'Pickup Location',
                                        value:
                                            (trip?.trips[0]?.pickupAddress?.address || '') +
                                            ' ' +
                                            (trip?.trips[0]?.pickupAddress?.address2 || '') +
                                            ', ' +
                                            (trip?.trips[0]?.pickupAddress?.city || '') +
                                            ', ' +
                                            (trip?.trips[0]?.pickupAddress?.state || '') +
                                            ', ' +
                                            (trip?.trips[0]?.pickupAddress?.zip || '')
                                    },
                                    {
                                        key: 'Drop Off  Location',
                                        value:
                                            (trip?.trips[0]?.dropoffAddress?.address || '') +
                                            ' ' +
                                            (trip?.trips[0]?.dropoffAddress?.address2 || '') +
                                            ', ' +
                                            (trip?.trips[0]?.dropoffAddress?.city || '') +
                                            ', ' +
                                            (trip?.trips[0]?.dropoffAddress?.state || '') +
                                            ', ' +
                                            (trip?.trips[0]?.dropoffAddress?.zip || '')
                                    },
                                    { key: 'Leg 1 Status', value: trip?.trips[0]?.tripLegStatus === '1' ? 'Active' : 'Inactive' }
                                ]"
                            ></MasTable>
                        </div>
                        <hr />
                        <div class="mt-3">
                            <table class="w-100">
                                <tr>
                                    <th>Seconday service</th>
                                    <th>Proc Code</th>
                                    <th>Proc Code Mod</th>
                                    <th>Units or Mileage</th>
                                </tr>
                                <tr *ngFor="let item of trip?.trips[0]?.secondary_services || []">
                                    <td>{{ item?.service_name }}</td>
                                    <td>{{ item?.service_procedure_code }}</td>
                                    <td>{{ item?.service_procedure_code_modifier }}</td>
                                    <td>{{ item?.service_rate }}</td>
                                </tr>
                                <tr *ngIf="!trip?.trips[0]?.secondary_services || trip?.trips[0]?.secondary_services?.length === 0">
                                    <td>None</td>
                                    <td class="text-center">-</td>
                                    <td class="text-center">-</td>
                                    <td class="text-center">-</td>
                                </tr>
                            </table>
                        </div>
                        <hr />
                        <div id="trip_information2" class="mt-3">
                            <div class="fs-14 d-flex">
                                <div class="semiTitle dark-color">Trip Information #2</div>
                                <div class="ml-3 cursor-pointer grey">This trip is 2 of 2 legs</div>
                            </div>
                            <MasTable
                                [data]="[
                                    { key: 'Pickup Date & Time', value: trip?.trips[1]?.formated_pickup_date_time },
                                    {
                                        key: 'Pickup Location',
                                        value:
                                            (trip?.trips[1]?.pickupAddress?.address || '') +
                                            ' ' +
                                            (trip?.trips[1]?.pickupAddress?.address2 || '') +
                                            ', ' +
                                            (trip?.trips[1]?.pickupAddress?.city || '') +
                                            ', ' +
                                            (trip?.trips[1]?.pickupAddress?.state || '') +
                                            ', ' +
                                            (trip?.trips[1]?.pickupAddress?.zip || '')
                                    },
                                    {
                                        key: 'Drop Off  Location',
                                        value:
                                            (trip?.trips[1]?.dropoffAddress?.address || '') +
                                            ' ' +
                                            (trip?.trips[1]?.dropoffAddress?.address2 || '') +
                                            ', ' +
                                            (trip?.trips[1]?.dropoffAddress?.city || '') +
                                            ', ' +
                                            (trip?.trips[1]?.dropoffAddress?.state || '') +
                                            ', ' +
                                            (trip?.trips[1]?.dropoffAddress?.zip || '')
                                    },
                                    { key: 'Leg 2 Status', value: trip?.trips[1]?.tripLegStatus === '1' ? 'Active' : 'Inactive' }
                                ]"
                            ></MasTable>
                        </div>

                        <hr />
                        <div class="mt-3">
                            <table class="w-100">
                                <tr>
                                    <th>Seconday service</th>
                                    <th>Proc Code</th>
                                    <th>Proc Code Mod</th>
                                    <th>Units or Mileage</th>
                                </tr>
                                <tr *ngFor="let item of trip?.trips[1]?.secondary_services || []">
                                    <td>{{ item?.service_name }}</td>
                                    <td>{{ item?.service_procedure_code }}</td>
                                    <td>{{ item?.service_procedure_code_modifier }}</td>
                                    <td>{{ item?.service_rate }}</td>
                                </tr>
                                <tr *ngIf="!trip?.trips[1]?.secondary_services || trip?.trips[1]?.secondary_services?.length === 0">
                                    <td>None</td>
                                    <td class="text-center">-</td>
                                    <td class="text-center">-</td>
                                    <td class="text-center">-</td>
                                </tr>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="light" />

            <div class="touch-bottom t-margin my-4">
                <div align="center">
                    <button class="cursor-pointer text-white px-4 py-1 btn btn-primary" (click)="closePopup()">Back to Ride Request</button>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- <div class="modal-content shadow-sm border-0" style="border-radius: 10px"></div> -->

<div
    class="modal fade p-2 touch-popup"
    id="vendor_activity_logs"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
>
    <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content selection-dialog">
            <div class="modal-content shadow-sm border-0" style="border-radius: 10px">
                <div class="touch-header pt-1 t-margin my-4">
                    <div class="large-medium-text dark-color align-self-center">
                        <div class="p-0 pb-1 my-0">Vendor Activity Logs</div>
                    </div>
                </div>
                <hr class="light" />
                <div class="touch-content t-margin my-4 mas-layout" style="max-height: 60vh; overflow: auto" id="trip-information" *ngIf="trip">
                    <table matSort class="w-100 vendor_log mx-2" (matSortChange)="sortVendorLogs($event)">
                        <tr class="my-2">
                            <th mat-sort-header="datentime" class="border-0">Date & Time</th>
                            <th>Full Name</th>
                            <th>Action</th>
                        </tr>
                        <tr *ngFor="let item of sortedVendorLog || []">
                            <td>{{ item?.datetime | date: 'MMMM dd, y h:mm a' }}</td>
                            <td>{{ item?.firstName }} {{ item?.lastName }}</td>
                            <td>{{ item?.action }}</td>
                        </tr>
                    </table>
                </div>
                <hr class="light" />

                <div class="touch-bottom t-margin my-4">
                    <div align="center">
                        <button
                            class="cursor-pointer text-white px-4 py-1 btn btn-primary"
                            (click)="utilityService.hideModal('vendor_activity_logs')"
                        >
                            Close
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
