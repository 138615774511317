<header class="header header--white">
    <div class="container">
        <div class="row">
            <div class="col-12">
                <div class="header__content">
                    <a class="header__logo">
                        <img src="/assets/images/logo.png" alt="logo" />
                    </a>
                    <nav class="header__nav">
                        <a (click)="startAgain()">Home</a>
                        <div class="header__social">
                            <a href="https://twitter.com/ridequdos"><i class="icon-tw"></i></a>
                            <a href="https://www.facebook.com/QudosRide/"><i class="icon-fb"></i></a>
                            <a href="https://www.linkedin.com/company/ridequdos/"><i class="icon-linkedin"></i></a>
                            <a href="https://www.instagram.com/qudosride/"><i class="icon-instagram"></i></a>
                        </div>
                    </nav>
                    <button class="header__menu" type="button">
                        <span></span>
                        <span></span>
                        <span></span>
                    </button>
                </div>
            </div>
        </div>
    </div>
</header>
<section class="sign">
    <div class="container" id="login_container">
        <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="sign__content">
                    <div class="row">
                        <div class="popup-main-content">
                            <!--Start Inner Row-->
                            <div id="main-login" class="row" *ngIf="activeMode === 'login'">
                                <div class="col-md-6">
                                    <h3>Corporate Sign In</h3>
                                    <span style="color: #212529">
                                        Qudos creates the opportunity for our drivers to build the business they truly want.
                                    </span>
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form">
                                        <form name="sign-in" autocomplete="off" class="form-icon">
                                            <label>Mobile Number</label>
                                            <div>
                                                <ngx-intl-tel-input
                                                    [cssClass]="'custom'"
                                                    [attr.autocomplete]="false"
                                                    [resetSearchContainer]="true"
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true"
                                                    [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="selectedCountryISO"
                                                    [maxLength]="10"
                                                    [tooltipField]="TooltipLabel.Name"
                                                    [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    name="phone"
                                                    [inputId]="'mobile'"
                                                    [formControl]="phoneNumber"
                                                    [customPlaceholder]="'Enter Mobile Number'"
                                                ></ngx-intl-tel-input>
                                            </div>
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                    <label>
                                                        Password
                                                        <a id="forget" (click)="forgotStart()">
                                                            <strong>Forgot it?</strong>
                                                        </a>
                                                    </label>
                                                </div>
                                                <div>
                                                    <span
                                                        class="text-blue fw-600 cursor-pointer"
                                                        (click)="staffLoginForm.reset(); utilityService.showModal('staff_login')"
                                                    >
                                                        Staff login here
                                                    </span>
                                                </div>
                                            </div>
                                            <div class="row-input">
                                                <span class="icon_input position-absolute">
                                                    <!-- <img src="assets/images/key.png" /> -->
                                                    <i class="fa fa-lock" aria-hidden="true"></i>
                                                </span>
                                                <input
                                                    id="password"
                                                    value=""
                                                    [disabled]="isDisable"
                                                    (keydown.enter)="loginDriverFn(false)"
                                                    name="password"
                                                    [(ngModel)]="loginDriver.password"
                                                    class="password"
                                                    name="password"
                                                    type="password"
                                                    placeholder="Enter Password"
                                                    focus="true"
                                                    autocomplete="new-password"
                                                    minlength="6"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    required
                                                />
                                            </div>
                                            <button
                                                class="submit btn btn-primary userloginButton w-100"
                                                (click)="loginDriverFn(false)"
                                                type="button"
                                                [disabled]="isDisable"
                                            >
                                                Login
                                            </button>
                                            <button
                                                class="btn btn-dark signup_btn submit mb-2 w-100"
                                                [routerLink]="'/corporate_signup'"
                                                type="button"
                                            >
                                                Signup
                                            </button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!--End inner Row-->

                            <!--Start Inner Row1-->
                            <div id="reset1" *ngIf="activeMode === 'forgot'" class="row">
                                <div class="col-md-6">
                                    <h3>Reset Password</h3>
                                    Enter your number and we will send you a OTP to reset your password.
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form">
                                        <form name="login">
                                            <label>Mobile Number</label>
                                            <div class="row-input" style="margin-bottom: 15px !important">
                                                <div>
                                                    <ngx-intl-tel-input
                                                        [cssClass]="'custom'"
                                                        [resetSearchContainer]="true"
                                                        [preferredCountries]="preferredCountries"
                                                        [enableAutoCountrySelect]="true"
                                                        [enablePlaceholder]="true"
                                                        [searchCountryFlag]="true"
                                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                        [selectFirstCountry]="false"
                                                        [selectedCountryISO]="selectedCountryISO"
                                                        [maxLength]="10"
                                                        [tooltipField]="TooltipLabel.Name"
                                                        [phoneValidation]="true"
                                                        [separateDialCode]="separateDialCode"
                                                        name="forgetMobile"
                                                        [inputId]="'mobile1'"
                                                        [formControl]="forgotMobileNumber"
                                                    ></ngx-intl-tel-input>
                                                </div>
                                            </div>
                                            <button id="reset-2" class="submit" type="button" style="margin-bottom: 5px" (click)="requestOTP()">
                                                Send OTP
                                            </button>
                                            <!-- <button id="reset-2" class="submit" type="button"  ng-click="showVerify()">Verify</button> -->
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!--Start Inner Row1-->

                            <!--Start Inner Row2-->
                            <div id="reset2" *ngIf="activeMode === 'verify-otp'" class="row">
                                <div id="main-hidden" class="col-md-5">
                                    <h3>OTP Confirmation</h3>
                                </div>
                                <div id="main-hidden1" class="col-md-7">
                                    Whether our users prefer a familiar face for the majority of their rides or want a particular type of car for a
                                    particular situation, Qudos can deliver an experience that is consistent with our rider's wants.
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form">
                                        <br />
                                        <p>Confirm your mobile number to reset your password</p>
                                        <form name="login">
                                            <label>Enter OTP sent to your Mobile number</label>
                                            <div class="row-input">
                                                <input
                                                    id="otp"
                                                    type="tel"
                                                    [(ngModel)]="otp"
                                                    [ngModelOptions]="{ standalone: true }"
                                                    (keypress)="isNumberKeys($event)"
                                                    maxlength="4"
                                                    inputmode="numeric"
                                                    placeholder="0000"
                                                    required
                                                />
                                                <div class="row row-input a-color">
                                                    <div class="col-xs-5">
                                                        <button class="btn-links" [disabled]="DisableRegenrate" (click)="regenerateOTP()">
                                                            Resend OTP
                                                        </button>
                                                    </div>
                                                    <div class="col-xs-7">
                                                        <a (click)="changeNum()">Change Mobile number</a>
                                                    </div>
                                                </div>
                                            </div>
                                            <!-- [disabled]="DisableVerifyOtp" -->
                                            <button id="reset-3" class="submit top-margin" type="button" (click)="verifyOTP()">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!--Start Inner Row2-->

                            <!--Start Inner Row3-->
                            <div id="reset3" *ngIf="activeMode === 'reset-password'" class="row">
                                <div class="col-md-6">
                                    <h3>Reset Password</h3>
                                    Qudos continues to act as the catalyst for lasting connections between drivers and riders. Hang on there, you will
                                    get back in track in no time.
                                </div>
                                <div class="col-md-6">
                                    <div class="login-form">
                                        <form name="login">
                                            <label>Enter New Password</label>
                                            <div class="row-input">
                                                <span class="icon_input position-absolute">
                                                    <img src="assets/images/key.png" />
                                                </span>
                                                <input
                                                    id="passwordn"
                                                    value=""
                                                    name="passwordn"
                                                    [(ngModel)]="resetPass.password"
                                                    class="password"
                                                    type="password"
                                                    placeholder="Enter new password"
                                                />
                                            </div>
                                            <label>Confirm New Password</label>
                                            <div class="row-input">
                                                <span class="icon_input position-absolute">
                                                    <img src="assets/images/key.png" />
                                                </span>
                                                <input
                                                    id="passwordnc"
                                                    value=""
                                                    name="passwordnc"
                                                    [(ngModel)]="resetPass.confirmpassword"
                                                    class="password"
                                                    type="password"
                                                    placeholder="Re-enter new password"
                                                />
                                            </div>
                                            <!-- [disabled]="onloadDisble" -->
                                            <button id="reset-4" class="submit" type="button" (click)="setPassword()">Submit</button>
                                        </form>
                                    </div>
                                </div>
                            </div>
                            <!--Start Inner Row3-->

                            <!--Start Inner Row4-->

                            <!--  [hidden]="(!loginMode&&!forgotMode&&!resetMode&&!otpVerifyMode&&resetSuccess)" -->

                            <div id="reset4" *ngIf="activeMode === 'reset-success-message'" class="row row-input">
                                <div class="col-md-12">
                                    <p class="small-show"><img src="assets/img/tick.png" alt="sucess" /></p>
                                </div>
                                <div class="col-md-6">
                                    <h3>Reset Successfully</h3>
                                    <p class="top-margin">Your Password has been successfuly changed, you can now continue to login.</p>
                                    <div class="row clear">
                                        <div class="col-md-12">
                                            <a id="login-main" (click)="switchToLogin()" class="submit-login submit">Go to Login</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 small-hide">
                                    <p align="center">
                                        <img src="assets/img/tick.png" width="180" height="180" alt="sucess" />
                                    </p>
                                </div>
                            </div>
                            <!--End Inner Row4-->
                        </div>
                        <!--End Main Content Popup-->
                    </div>
                    <!--End Row-->
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!loadings" class="loaders">
        <div class="loaders-wrapper">
            <img src="assets/images/custom_loader.svg" alt="loading..." />
        </div>
    </div>
</section>

<div
    class="modal fade"
    id="staff_login"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div
        class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg"
        role="document"
        style="min-width: 630px !important; max-width: fit-content !important"
    >
        <div class="modal-content">
            <div class="touch-popup">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4">
                        <div class="medium-text dark-color">Staff login</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="bg-light-blue pr-3 pr-md-5">
                    <!-- mx-5 my-4 -->
                    <div class="touch-content px-5 py-4">
                        <form [formGroup]="staffLoginForm">
                            <div class="d-flex my-2 justify-content-end">
                                <div class="mx-2 align-self-center fs-13 dark-color fw-600">Main Account Mobile</div>
                                <div
                                    class="ml-2 touch-tel-input"
                                    [class.error]="staffLoginForm.get('mobile')?.touched && staffLoginForm.get('mobile')?.invalid"
                                >
                                    <ngx-intl-tel-input
                                        [cssClass]="'custom'"
                                        [attr.autocomplete]="false"
                                        [resetSearchContainer]="true"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="selectedCountryISO"
                                        [maxLength]="10"
                                        [tooltipField]="TooltipLabel.Name"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        name="phone"
                                        [inputId]="'mobile'"
                                        formControlName="mobile"
                                        [customPlaceholder]="'Mobile Number'"
                                    ></ngx-intl-tel-input>
                                </div>
                            </div>
                            <div class="d-flex my-2 justify-content-end">
                                <div class="mx-2 align-self-center fs-13 dark-color fw-600">Staff Mobile Number</div>
                                <div
                                    class="ml-2 touch-tel-input"
                                    [class.error]="staffLoginForm.get('staff_mobile')?.touched && staffLoginForm.get('staff_mobile')?.invalid"
                                >
                                    <!-- <input type="text" class="touch-input fs-13 w-100 p-1 px-2" placeholder="Staff full name here" #mas_api_key ngModel /> -->
                                    <ngx-intl-tel-input
                                        [cssClass]="'custom'"
                                        [attr.autocomplete]="false"
                                        [resetSearchContainer]="true"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="staffSelectedCountryISO"
                                        [maxLength]="10"
                                        [tooltipField]="TooltipLabel.Name"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        name="phone"
                                        [inputId]="'mobile'"
                                        formControlName="staff_mobile"
                                        [customPlaceholder]="'Mobile Number'"
                                    ></ngx-intl-tel-input>
                                </div>
                                <!-- [formControl]="" -->
                            </div>
                            <div class="d-flex my-2 justify-content-end">
                                <div class="mx-2 align-self-center fs-13 dark-color fw-600">Password</div>
                                <div class="ml-2">
                                    <input
                                        type="password"
                                        [class.error]="staffLoginForm.get('password')?.touched && staffLoginForm.get('password')?.invalid"
                                        class="touch-input fs-13 w-100 p-1 px-2"
                                        formControlName="password"
                                        placeholder="Password here"
                                    />
                                </div>
                            </div>
                        </form>
                        <!-- <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div> -->
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
                    <button class="btn btn-secondary btn-light text-white p-2 mx-2 br-6" data-dismiss="modal" (click)="staffLoginForm.reset()">
                        Cancel
                    </button>
                    <button
                        class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6"
                        [disabled]="staffLoginForm.invalid || loadings"
                        (click)="staffLogin()"
                    >
                        <ng-container *ngIf="!loadings">Login</ng-container>
                        <ng-container *ngIf="loadings">
                            <div class="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </div>
                        </ng-container>
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
