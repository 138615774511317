import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { IPARAMS_GET_RIDE_BY_TYPE } from 'src/app/models/trips.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { ILiveTripInfo } from '../../components/live-trip-info/live-trip-info.component';

enum TripAPIS {
    GET_CAR_TYPE = 'get_area_fare',
    GET_RIDES_BY_TYPE = 'get_rides_by_type',
    GET_RIDE_BY_SESSION = 'get_ride_by_session',
    FETCH_TRIP = 'fetch_trip',
    GET_TRIP_PAYMENT_LINK = 'get_payment_link',
    SEND_TRIP_PAYMENT_LINK = 'send_payment_link',
    SEND_AGENT_TRIP_PAYMENT_LINK = 'send_agent_payment_link',
}

@Injectable({
    providedIn: 'root',
})
export class TripsService {
    constructor(private httpService: HttpService, private cookieService: CookieService) {}

    get_car_type(limit: number = 100, offset: number = 0) {
        return this.httpService.post(environment?.urlWC + TripAPIS.GET_CAR_TYPE, {
            web_access_token: this.cookieService.get('web_access_token'),
            limit,
            offset,
        });
    }

    parse_ride_history_to_live_trip_info(trip: any): ILiveTripInfo {
        return {
            driver_id: trip?.driver_id,
            driver_name: trip?.driver_name,
            driver_mobile: trip?.driver_mobile,
            rider_name: trip?.user_name,
            rider_mobile: trip?.user_mobile,
            pickup_datetime: trip?.pickup_time,
            pickup_location: trip?.pickup_location_address,
            dropoff_location: trip?.manual_destination_address,
            pickup_latitude: trip?.pickup_latitude,
            pickup_longitude: trip?.pickup_longitude,
            dropoff_latitude: trip?.manual_destination_latitude,
            dropoff_longitude: trip?.manual_destination_longitude,
        };
    }

    get_rides_by_type(payload: IPARAMS_GET_RIDE_BY_TYPE) {
        return this.httpService.post(environment.urlWC + TripAPIS.GET_RIDES_BY_TYPE, {
            ...payload,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_ride_by_session(session_id: string) {
        return this.httpService.post(environment.urlWC + TripAPIS.GET_RIDE_BY_SESSION, {
            session_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    // used for fetching trip by invoice number or trip leg id
    fetch_trip(search: string = '', limit = 10) {
        return this.httpService.post(environment.urlWC + TripAPIS.FETCH_TRIP, {
            search,
            limit,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_payment_link(mas_trip_id: string) {
        return this.httpService.post(environment.urlWC + TripAPIS.GET_TRIP_PAYMENT_LINK, {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id,
        });
    }

    send_payment_link(send_on: string, mas_trip_id: string, type: any) {
        const APIENDPOINT = type === 'agent' ? TripAPIS.SEND_AGENT_TRIP_PAYMENT_LINK : TripAPIS.SEND_TRIP_PAYMENT_LINK;
        return this.httpService.post(environment.urlWC + APIENDPOINT, {
            send_on,
            mas_trip_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }
}
