import { Component, EventEmitter, Input, OnInit, Output, TemplateRef } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { MasQueueService } from '../../services/mas-queue/mas-queue.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-live-queue-trip-popover',
    templateUrl: './live-queue-trip-popover.component.html',
    styleUrls: ['./live-queue-trip-popover.component.scss'],
})
export class LiveQueueTripPopoverComponent implements OnInit {
    constructor(private mas_queue_service: MasQueueService, private utilityService: UtilityService, private modalService: BsModalService) {}

    modalRef: BsModalRef;
    @Input() mas_trip_id: number;
    @Input() is_live: boolean = false;
    @Input() minimal: boolean = false;
    @Input() fromUnassigned: boolean = false;
    isLoading: boolean = false;
    @Input() containerClass: string = 'w-350 br-6 preferred_driver';

    @Output() onChange: EventEmitter<void> = new EventEmitter<void>();
    live_mas_trip_reference: PopoverDirective;

    isDesktop: boolean = true;

    ngOnInit(): void {
        if (window.innerWidth < 991) {
            this.isDesktop = false;
        }
    }

    onUpdate(go_live: number = this.is_live ? 0 : 1) {
        if (this.fromUnassigned) {
            this.onChange.emit();
            this.isDesktop ? this.live_mas_trip_reference.hide() : this.modalRef.hide();
            return;
        }
        this.isLoading = true;
        this.mas_queue_service
            .update_live_status(this.mas_trip_id, go_live)
            .subscribe(
                (res: { flag: number; message?: string; log?: string; error?: string }) => {
                    if (res?.flag === 2203) {
                        this.onChange.emit();
                        this.isDesktop ? this.live_mas_trip_reference.hide() : this.modalRef.hide();
                        this.is_live = true;
                        this.utilityService.toast('success', 'Trip is now Live.');
                    } else {
                        this.utilityService.toast('error', res?.message || res?.log || res?.error || 'Something went wrong');
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.isLoading = false;
            });
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
        });
    }
}
