<ng-template #fare_estimate_popup>
    <div class="modal-content selection-dialog">
        <div class="modal-body">
            <div class="text-right">
                <button type="button" aria-label="Close" (click)="close_fare_popup()">
                    <img src="/assets/images/modalClose.svg" alt="closeIcon" />
                </button>
            </div>
            <div class="panel-body mt-3">
                <div class="row booking-model-component px-1">
                    <div class="col-md-6">
                        <div class="rb-mid-text mt-3 mb-2">
                            Fare Estimates
                            <span *ngIf="mas_active_trip" class="mas_unassigned">MAS</span>
                            <span class="rb-blue-small-text fs-13 cursor-pointer" (click)="open_recalculate_popup(active_fare_breakdown_flag)">
                                Recalculate
                            </span>
                            <ng-container *ngIf="fare_factor != 1">
                                <span class="text-spacegrey mx-2">|</span>
                                <span
                                    class="rb-blue-small-text fs-13 cursor-pointer"
                                    (click)="fare_factor_display = 1; fare_factor = 1; getFareEstimate(0, false)"
                                >
                                    Reset
                                </span>
                            </ng-container>
                        </div>

                        <div class="d-flex justify-content-between">
                            <div>
                                <div class="mt-2 m-0">
                                    <!-- <div class="rb-grey-mid-text d-flex fw-600">
                                        <div
                                            class="cursor-pointer"
                                            (click)="active_fare_breakdown_flag = 'now'"
                                            [class.text-blue]="active_fare_breakdown_flag === 'now'"
                                        >
                                            Request Now
                                        </div>
                                        <div class="mx-2">|</div>
                                        <div
                                            class="cursor-pointer"
                                            (click)="active_fare_breakdown_flag = 'later'"
                                            [class.text-blue]="active_fare_breakdown_flag === 'later'"
                                        >
                                            Book Later
                                        </div>
                                    </div> -->
                                    <div class="d-flex mt-2">
                                        <app-select-car-type-popover
                                            [carTypes]="carDriverOptions"
                                            [selectedCarType]="booking.car_type"
                                            (onCarTypeChange)="changeCarType($event)"
                                        >
                                            <span class="otherOption">
                                                <span>Other Options</span>
                                                <span class="ml-2"><i class="fa fa-sort-desc" aria-hidden="true"></i></span>
                                            </span>
                                        </app-select-car-type-popover>
                                    </div>
                                    <div *ngIf="!fareEstimatePriceLoading">
                                        <ng-container *ngIf="active_fare_breakdown_flag === 'now'">
                                            <span class="rb-large-text">${{ booking?.estimated_fare | number: '1.2-2' }}</span>
                                            <span *ngIf="promo_applied" class="original-price ml-1">
                                                ${{ booking?.estimated_fare_old | number: '1.2-2' }}
                                            </span>
                                        </ng-container>
                                        <ng-container *ngIf="active_fare_breakdown_flag === 'later'">
                                            <span class="rb-large-text">${{ booking.estimated_fare_later | number: '1.2-2' }}</span>
                                            <span *ngIf="promo_applied" class="original-price ml-1">
                                                ${{ booking?.estimated_fare_later_old | number: '1.2-2' }}
                                            </span>
                                        </ng-container>
                                    </div>
                                    <div *ngIf="fareEstimatePriceLoading">
                                        <app-loading height="13px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                    <div class="rb-blue-small-text cursor-pointer" (click)="open_fare_breakdown_popup(active_fare_breakdown_flag)">
                                        View Fare Breakdown
                                    </div>
                                </div>
                            </div>
                            <div class="align-self-center">
                                <img src="/assets/images/divider_large_grey.svg" alt="divider_large" />
                            </div>
                            <div>
                                <div class="mt-2 m-0">
                                    <div class="text-dark-blue fw-600">Trip Estimate</div>
                                    <table class="rb-detail-table">
                                        <tr>
                                            <td style="min-width: 5rem">
                                                <div class="my-1">Miles</div>
                                            </td>
                                            <td class="text-break">{{ trip_distance }}</td>
                                        </tr>
                                        <tr>
                                            <td style="min-width: 5rem">Minutes</td>
                                            <td class="text-break">{{ trip_duration }}</td>
                                        </tr>
                                    </table>
                                </div>
                            </div>
                            <div *ngIf="mobileSize">
                                <img
                                    class="rb-img-2 cursor-pointer img-responsive"
                                    [src]="booking.route_directions"
                                    alt="Route"
                                    (click)="utilityService.showModal('ride-image-zoom')"
                                />
                            </div>
                        </div>
                        <hr />
                        <div class="d-flex">
                            <div class="rb-mid-text my-2">
                                <span *ngIf="mas_active_trip">MAS</span>
                                Trip Information
                            </div>
                            <div
                                *ngIf="mas_active_trip"
                                class="ml-2 rb-blue-small-text align-self-center cursor-pointer"
                                (click)="open_mas_trip_info_popup()"
                            >
                                View Trip Details
                            </div>
                        </div>

                        <div class="my-3">
                            <table class="rb-detail-table">
                                <tr *ngIf="mas_active_trip">
                                    <td>
                                        <div class="my-1">Leg Cost (MAS)</div>
                                    </td>
                                    <td class="text-break">
                                        {{ mas_total_leg_cost | currency: 'USD' }}
                                        <span class="cursor-pointer pl-1" style="margin-left: -3px" (click)="open_leg_cost_breakdown()">
                                            <i style="color: #323f50" class="fa fa-exclamation-circle"></i>
                                        </span>
                                        | {{ mas_active_trip?.legMileage }} miles
                                    </td>
                                </tr>
                                <tr *ngIf="mas_active_trip">
                                    <td>
                                        <div class="my-1">Pickup time</div>
                                    </td>
                                    <td class="text-break">
                                        {{ mas_active_trip?.pickupDate | date: 'MMMM d, y | EEEE' }} {{ mas_active_trip?.pickup_time }} - (EDT)
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="my-1">Pickup Location</div>
                                    </td>
                                    <td class="text-break">{{ booking.pickup_location_address?.toString()?.replaceAll('/', ' ') }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="my-1">Drop Off Location</div>
                                    </td>
                                    <td class="text-break">{{ booking.manual_destination_address?.toString()?.replaceAll('/', ' ') }}</td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="my-1">Avoid Tolls</div>
                                    </td>
                                    <td class="text-break">
                                        <div class="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="avoidTollSwitch"
                                                [(ngModel)]="avoid_tolls"
                                                (change)="this.getFareEstimate(0, false); initializeMap()"
                                            />
                                            <label class="custom-control-label fs-14 fw-600 dark-color" for="avoidTollSwitch"></label>
                                        </div>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <div class="my-1">Avoid Highways</div>
                                    </td>
                                    <td class="text-break">
                                        <div class="custom-control custom-switch">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input"
                                                id="avoidHighwaysSwitch"
                                                [(ngModel)]="avoid_highways"
                                                (change)="this.getFareEstimate(0, false); initializeMap()"
                                            />
                                            <label class="custom-control-label fs-14 fw-600 dark-color" for="avoidHighwaysSwitch"></label>
                                        </div>
                                    </td>
                                </tr>
                            </table>
                        </div>
                        <hr *ngIf="!openFromQueueModal" />
                        <div class="d-flex justify-content-between" *ngIf="!openFromQueueModal">
                            <div class="rb-mid-text rb-small-size cursor-pointer align-self-center">Driver Information</div>
                            <div class="cmodal py-1 w-50 align-self-center" align="right">
                                <div class="dropdown">
                                    <span
                                        class="w-100"
                                        type="button"
                                        id="affiliation_menu"
                                        data-toggle="dropdown"
                                        aria-haspopup="true"
                                        aria-expanded="false"
                                    >
                                        <div class="d-flex justify-content-between placeholder small fw-600">
                                            <div>
                                                <ng-container *ngIf="!selected_driver">Select Driver</ng-container>
                                                <ng-container *ngIf="selected_driver">{{ selected_driver?.driver_name }}</ng-container>
                                            </div>
                                            <div class="align-self-center"><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                                        </div>
                                    </span>
                                    <div
                                        class="dropdown-menu p-0 card-shadow w-100 overflow-auto"
                                        id="trip_status_menu"
                                        aria-labelledby="affiliation_menu"
                                    >
                                        <div class="px-2 py-3 search-box">
                                            <span>
                                                <input
                                                    [formControl]="driverSearchControl"
                                                    class="m-0 border-0"
                                                    style="width: 79%"
                                                    type="text"
                                                    placeholder=" Search Drivers"
                                                />
                                                <i class="fa fa-search" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <div
                                            class="dropdown-item cursor-pointer fs-13"
                                            (click)="selected_driver = { driver_name: 'Random Driver', driver_id: '' }"
                                        >
                                            Random Driver
                                        </div>
                                        <div
                                            *ngFor="let driver of drivers"
                                            class="dropdown-item cursor-pointer fs-13"
                                            (click)="selected_driver = driver; booking.driver_id = driver?.driver_id; booking.driver_selected = 1"
                                        >
                                            {{ driver.driver_name }}
                                            <span class="fs-11">{{ driver.vehicle_number }}</span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <hr />
                        <ng-container *ngIf="!requestPending">
                            <div class="my-2 row">
                                <div class="col my-auto">
                                    <span *ngIf="!promo_applied" class="rb-mid-text rb-small-size cursor-pointer" (click)="open_promo_code_popup()">
                                        <i class="fa fa-tag" aria-hidden="true"></i>
                                        Promo Code?
                                    </span>
                                    <span *ngIf="promo_applied" class="rb-mid-text rb-small-size promo-applied">
                                        <i class="fa fa-tag" aria-hidden="true"></i>
                                        Promo Applied
                                        <span class="ml-1 rb-blue-small-text cursor-pointer" (click)="open_promo_code_popup()">Edit</span>
                                    </span>
                                </div>
                                <div class="col-auto">
                                    <img src="assets/images/large_divider.svg" alt="large_divider" />
                                </div>
                                <div class="col my-auto" align="right">
                                    <span
                                        class="rb-mid-text rb-small-size cursor-pointer"
                                        data-target="#premiumOption"
                                        (click)="open_premium_option_popup()"
                                    >
                                        <div class="d-flex justify-content-end">
                                            <div class="align-self-center mr-1 mb-1">
                                                <img src="assets/images/star.svg" style="width: 16px" />
                                            </div>
                                            <div class="align-self-center">
                                                <span>
                                                    Premium Option
                                                    <span *ngIf="premiumOptionLabel">
                                                        {{ premiumOptionLabel }}
                                                    </span>
                                                </span>
                                            </div>
                                        </div>
                                    </span>
                                </div>
                            </div>
                            <hr />
                            <div class="my-4 rb-btn" *ngIf="openFromQueueModal">
                                <div class="text-center mt-4">
                                    <button class="btn btn-sm rb-btn-dark-blue ml-0 mr-3 btn-secondary border-0" (click)="emitQueued()">
                                        Queue & Save this Fare
                                    </button>

                                    <button (click)="close_fare_popup()" class="btn btn-secondary border-0 font-light btn-left m-2 px-3 py-1">
                                        Cancel
                                    </button>
                                </div>
                            </div>
                            <div class="my-4 rb-btn" *ngIf="!openFromQueueModal">
                                <div class="d-flex justify-content-around">
                                    <ng-container *ngIf="booking?.type !== 'suggested_fare'">
                                        <div>
                                            <button
                                                class="btn btn-sm rb-btn-dark-blue ml-0 mr-3 btn-secondary border-0"
                                                [disabled]="DisableOnload"
                                                (click)="initiate_request_and_confirm_payment('now')"
                                            >
                                                <span *ngIf="mas_active_trip">Dispatch Now</span>
                                                <span *ngIf="!mas_active_trip">Request Now</span>
                                            </button>
                                        </div>
                                        <div>
                                            <button
                                                class="btn btn-sm rb-btn-blue mx-3 btn-primary border-0"
                                                (click)="initiate_schedule_datetime_selection()"
                                            >
                                                <span *ngIf="mas_active_trip">Dispatch Later</span>
                                                <span *ngIf="!mas_active_trip">Book Later</span>
                                            </button>
                                        </div>
                                    </ng-container>
                                    <ng-container *ngIf="booking?.type === 'suggested_fare'">
                                        <div>
                                            <button
                                                class="btn btn-sm rb-btn-dark-blue ml-0 mr-3 btn-secondary border-0"
                                                (click)="
                                                    onSaveFare.emit({
                                                        value: fare_breakdown?.value_regular,
                                                        fare_factor: fare_factor,
                                                        driver_payout: fare_breakdown?.submerchant_driver_payout,
                                                        avoid_highways: this.avoid_highways,
                                                        avoid_tolls: this.avoid_tolls
                                                    });
                                                    modalRef.hide()
                                                "
                                            >
                                                Save This Fare
                                            </button>
                                        </div>
                                        <div *ngIf="booking?.showCreatePayout">
                                            <button
                                                class="btn btn-sm btn btn-mint-green text-white ml-0 mr-3 border-0"
                                                (click)="onCreatePayout.emit(fare_breakdown?.value_regular); modalRef.hide()"
                                            >
                                                Create Payout
                                            </button>
                                        </div>
                                    </ng-container>
                                </div>
                                <div class="text-center mt-4">
                                    <button (click)="close_fare_popup()" class="btn btn-sm mx-3 border-0 dismiss-button">Dismiss</button>
                                </div>
                            </div>
                        </ng-container>

                        <div class="text-center" *ngIf="requestPending">
                            <span class="rb-grey-mid-text bold mt-4" style="font-size: 1.05rem">Waiting for rider to confirm the payment</span>
                            <div class="col-md-12 my-3">
                                <app-loading></app-loading>
                            </div>
                        </div>
                    </div>

                    <!-- GOOGLE MAP -->
                    <div class="col-md-6" style="position: relative">
                        <div id="map" class="map card-shadow"></div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
