<span
    [containerClass]="containerClass"
    [triggers]="triggers"
    placement="bottom"
    [popover]="message_popover"
    [delay]="delay"
    #messagePopover="bs-popover"
    (onShown)="messagePopoverReference = messagePopover"
>
    <ng-content></ng-content>
</span>

<ng-template #message_popover>
    <div class="touch-popover">
        <div class="m-3 text-grey">
            <div class="mb-2 fw-600">{{ messageTitle }}</div>
            <div [innerHtml]="messageDescription"></div>
        </div>

        <div *ngIf="actionText" class="mt-3">
            <hr class="light light-color" />
            <div class="d-flex justify-content-end text-blue2 fs-13 fw-600 py-3 px-4">
                <div class="cursor-pointer ml-2 mr-3" (click)="messagePopoverReference.hide()">Dismiss</div>
                <div class="cursor-pointer mx-2" (click)="messagePopoverReference.hide(); onAction.emit()">{{ actionText }}</div>
            </div>
        </div>
    </div>
</ng-template>
