<div *ngIf="activeMobileMenu == 'viewMap'" class="queue d-md-none" (click)="add_queue_tripMobile()">+ queue</div>
<div class="topSpacing livetracking">
    <!-- Only Mobile Screen Start-->
    <div class="ml-3 tab-view-mobile" *ngIf="isMobileScreen">
        <div class="touch-tabs d-flex justify-content-between">
            <div class="d-flex">
                <div
                    class="align-self-end tab active"
                    (click)="activeMobileMenu = 'addRide'; newRideModeSelect()"
                    [ngClass]="{ active: activeMobileMenu == 'addRide' }"
                >
                    Add New Trip
                </div>
                <div class="ml-3 tab">|</div>
                <div class="align-self-end tab ml-4" (click)="activeMobileMenu = 'viewMap'" [ngClass]="{ active: activeMobileMenu == 'viewMap' }">
                    View Map
                </div>
            </div>
        </div>
    </div>
    <hr class="mt-0" *ngIf="isMobileScreen && (activeMobileMenu == 'addRide' || activeMobileMenu == 'viewMap')" />
    <ng-contaier *ngTemplateOutlet="mobileAddRideRequest"></ng-contaier>
    <!-- Only Mobile Screen End-->

    <div id="Trips" [ngClass]="{ mobileDesktop: activeMobileMenu != 'viewMap' }">
        <div class="row mx-0 pl-1 pr-3 pb-3">
            <div class="col-auto" id="leftSidebar" [class.minimize]="leftSideClosed && mobileSize">
                <div class="page-new p-0">
                    <div style="padding: 8px; padding-top: 0px; padding-right: 0px; margin: 0">
                        <!-- [class.pb-2]="!(third_party_enabled && date_range_picker.value['start'] && date_range_picker.value['end'])" -->
                        <div class="search card-shadow">
                            <div *ngIf="third_party_enabled && third_party_integration === 'MAS'" class="search-box row mx-2">
                                <!-- <button class="btn btn-primary col-auto my-auto refresh-btn border-btn">
                                    <mat-icon>autorenew</mat-icon>
                                    Refresh
                                </button> -->
                                <!-- <div style="color:#828282" class="justify-content-center align-self-center mr-1"> | </div> -->
                                <!-- <div class="ml-2 col-auto p-0 my-auto divider">
                                    <img src="assets/images/divider_black.svg" alt="divider" />
                                </div> -->
                                <div class="col py-0 d-flex px-2 search-input">
                                    <div class="justify-content-center align-self-center">
                                        <i style="color: #828282" class="fa fa-search"></i>
                                    </div>
                                    <div>
                                        <input
                                            type="text"
                                            id="search"
                                            name="search"
                                            placeholder="Search"
                                            class="form-control pr-0 fs-13"
                                            autocomplete="off"
                                            [formControl]="searchForm"
                                        />
                                        <!-- *ngIf="searchForm.value" -->
                                    </div>
                                    <div class="my-auto" *ngIf="searchForm.value">
                                        <!-- <i (click)="searchForm.reset()" class="ml-1 fa fa-times"></i> -->
                                        <mat-icon (click)="searchForm.reset()" class="cursor-pointer" style="vertical-align: middle">close</mat-icon>
                                    </div>
                                </div>
                            </div>
                            <div *ngIf="!third_party_enabled" class="row mx-2">
                                <div class="col-3 p-0 my-auto" style="max-width: 24%">
                                    <!-- <span (click)="picker.open()"> Mar 21 </span> -->
                                    <span class="ml-2">
                                        <span (click)="picker.open()" class="rideDateText">{{ rideDateText }}</span>
                                        <input
                                            [(ngModel)]="rideDate"
                                            [matDatepicker]="picker"
                                            style="width: 0px; height: 0px; z-index: -1; position: absolute; top: 18px; left: 7px; visibility: hidden"
                                            (dateChange)="onRideFilter($event.target.value)"
                                        />
                                        <mat-datepicker #picker></mat-datepicker>
                                    </span>
                                </div>
                                <!-- <div style="color:#828282" class="justify-content-center align-self-center mr-1"> | </div> -->
                                <div class="col-auto p-0 my-auto divider">
                                    <img src="assets/images/divider_black.svg" alt="divider" />
                                </div>
                                <div class="col py-0 d-flex px-2 search-input">
                                    <div class="justify-content-center align-self-center">
                                        <i style="color: #828282" class="fa fa-search"></i>
                                    </div>
                                    <div>
                                        <input
                                            type="search"
                                            id="search"
                                            name="search"
                                            placeholder="Type to Search"
                                            class="form-control pr-0"
                                            [formControl]="searchForm"
                                            autocomplete="off"
                                        />
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div class="row tab-bar m-2 no-select">
                                <!-- DONE NEW Trip Tabs -->

                                <div
                                    class="col px-0 mr-1"
                                    [class.active]="selectedIndex == 0"
                                    [class.lite]="dateFiltered"
                                    (click)="filterRideType = ''; setSelectedTab(0); collapseDisc(); resetRiderSearch()"
                                >
                                    Completed
                                </div>

                                <div
                                    class="col px-0"
                                    [class.active]="selectedIndex == 1"
                                    [class.lite]="dateFiltered"
                                    (click)="filterRideType = ''; setSelectedTab(1); collapseDisc(); resetRiderSearch()"
                                >
                                    Ongoing
                                </div>

                                <div
                                    class="col px-0"
                                    [class.active]="selectedIndex == 2"
                                    [class.lite]="dateFiltered"
                                    (click)="filterRideType = ''; setSelectedTab(2); collapseDisc(); resetRiderSearch()"
                                >
                                    Trip Roster
                                </div>
                            </div>
                            <div class="d-flex tab-bar sb-section justify-content-center">
                                <!-- DONE new sub trip tabs -->

                                <ng-container *ngIf="selectedIndex == 0">
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'alltrips'"
                                        (click)="changeFilterRideType('alltrips'); resetRiderSearch()"
                                    >
                                        All Trips
                                    </div>
                                    <div class="px-1 sb-active">|</div>
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'dispatched'"
                                        (click)="changeFilterRideType('dispatched'); resetRiderSearch()"
                                    >
                                        Dispatched
                                    </div>
                                    <div class="px-1 sb-active">|</div>
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'assigned'"
                                        (click)="changeFilterRideType('assigned'); resetRiderSearch()"
                                    >
                                        Assigned
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="selectedIndex == 1">
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'alltrips'"
                                        (click)="changeFilterRideType('alltrips'); resetRiderSearch()"
                                    >
                                        All Trips
                                    </div>
                                    <div class="px-1 sb-active">|</div>
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'dispatched'"
                                        (click)="changeFilterRideType('dispatched'); resetRiderSearch()"
                                    >
                                        Dispatched
                                    </div>
                                    <div class="px-1 sb-active">|</div>
                                    <div class="px-2" [class.sb-active]="filterRideType === 'assigned'" (click)="changeFilterRideType('assigned')">
                                        Assigned
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="selectedIndex == 2">
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'alltrips'"
                                        (click)="changeFilterRideType('alltrips'); resetRiderSearch()"
                                    >
                                        All Trips
                                    </div>
                                    <div class="px-1 sb-active">|</div>
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'queue-trip'"
                                        (click)="changeFilterRideType('queue-trip'); resetRiderSearch()"
                                    >
                                        Queued
                                    </div>
                                    <div class="px-1 sb-active">|</div>
                                    <div
                                        class="px-2"
                                        [class.sb-active]="filterRideType === 'assigned'"
                                        (click)="changeFilterRideType('assigned'); resetRiderSearch()"
                                    >
                                        Assigned
                                    </div>
                                </ng-container>
                            </div>
                            <div
                                *ngIf="third_party_enabled"
                                class="d-flex tab-bar date-range-section p-1 py-2 m-0 bg-dark text-white justify-content-center position-relative"
                            >
                                <div class="touch-dropdown mr-2 soft-border pr-0">
                                    <app-time-range-picker
                                        viewType="minimal"
                                        labelType="icon"
                                        colorTheme="white"
                                        (onTimeChange)="onTimePickerChange($event)"
                                        [default]="{ start: null, end: null }"
                                    ></app-time-range-picker>
                                </div>
                                <div class="px-1 align-self-center">|</div>
                                <div class="soft-border" (click)="masDatePicker.open(); resetRiderSearch()">
                                    <span>
                                        <ng-container *ngIf="mas_date_filter_type === 'single'">
                                            {{ mas_date_picker.value | date: 'MMMM d' }}
                                        </ng-container>
                                        <ng-container *ngIf="mas_date_filter_type === 'range'">
                                            {{ date_range_picker.value['start'] | date: 'MMMM d' }} -
                                            {{ date_range_picker.value['end'] | date: 'MMMM d' }}
                                        </ng-container>

                                        <mat-icon class="cursor-pointer align-middle ml-1" style="font-size: 18px">date_range</mat-icon>
                                    </span>

                                    <input
                                        [formControl]="mas_date_picker"
                                        [matDatepicker]="masDatePicker"
                                        style="width: 0px; height: 0px; z-index: -1; position: absolute; left: 16px; top: 40px; visibility: hidden"
                                    />
                                    <mat-datepicker #masDatePicker></mat-datepicker>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div
                        #trip_data_list
                        class="trip_detail_data"
                        [class.trip_roster]="third_party_enabled && selectedIndex === 2 && filterRideType === 'assigned'"
                        style="padding-left: 8px"
                    >
                        <div *ngIf="isRideLoading || mas_list_loading" align="center" class="p-0">
                            <app-loading type="placeholder" [placeholderLength]="15" height="90px"></app-loading>
                        </div>

                        <ng-container *ngIf="!isRideLoading && !mas_list_loading">
                            <!-- Completed -->
                            <ng-container *ngIf="selectedIndex < 2">
                                <div
                                    class="card-shadow"
                                    *ngFor="
                                        let data of completedOngoingTrips
                                            | StringFilter: 'user_name':searchString.trim()
                                            | DateFilter: 'drop_time':rideDate;
                                        let i = index
                                    "
                                    (click)="openTripDetails(data, 'active-socket-trip')"
                                    [class.active_trip_data]="this.active_trip_data === 'active-socket-trip-' + data.session_id"
                                >
                                    <!-- Completed Trips -->
                                    <!-- <ng-container *ngIf="selectedIndex === 0"> -->

                                    <!-- Common for all trips -->
                                    <div class="d-flex justify-content-between">
                                        <div class="user-name">
                                            {{ data.user_name | ShortText | titlecase }}
                                            <!-- <span *ngIf="data.user_name?.length >= 20">...</span> -->
                                        </div>
                                        <div *ngIf="data?.third_party_ride" class="mas">MAS</div>
                                    </div>

                                    <div class="time">
                                        {{ data?.display_date | date: 'MM/dd/yyyy' }} | {{ data?.display_date | date: 'hh:mm a' }} - (
                                        <span *ngIf="!data.offset">{{ data.tzName }}</span>
                                        {{ data.offset | timezone }} )
                                    </div>

                                    <ng-container>
                                        <ng-container *ngIf="selectedIndex === 0">
                                            <div class="status-label">
                                                Completed
                                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2">Assigned</span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="selectedIndex === 1">
                                            <div class="status-label status-color" [ngClass]="data.ride_status_text_class">
                                                <span class="cursor-pointer">
                                                    {{ data.ride_status_text }}
                                                </span>
                                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2">Assigned</span>
                                            </div>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <div class="bg-light no-ride-found-label cursor-default" *ngIf="total_rides === 0">No results found!</div>
                            </ng-container>

                            <!-- Dispatched -->
                            <!-- Socket: remove -->
                            <!-- <ng-container *ngIf="selectedIndex === 1 && false">
                                <ng-container>
                                    <ng-container *ngIf="!filterRideType || filterRideType === 'scheduled'">
                                        <div
                                            class="card-shadow"
                                            *ngFor="
                                                let data of scheduledrides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: 'upcoming';
                                                let i = index
                                            "
                                            (click)="openTripDetails(data, 'scheduled-ride')"
                                            [class.active_trip_data]="this.active_trip_data === 'scheduled-ride-' + data.session_id"
                                        >
                                            <div class="d-flex justify-content-between">
                                                <div class="user-name">
                                                    {{ data.user_name | ShortText | titlecase }}
                                                </div>
                                                <div *ngIf="data?.third_party_ride" class="mas">MAS</div>
                                            </div>
                                            <div class="time">
                                                {{ data.pickup_time_processed | date: 'MM/dd/yyyy' }} |
                                                {{ data.pickup_time_processed | date: 'hh:mm a' }} - (
                                                <span *ngIf="!data.offset">{{ data.tzName }}</span>
                                                {{ data.offset | timezone }} )
                                            </div>
                                            <div class="status-label status-color" [ngClass]="data.ride_status_text_class">
                                                <span class="cursor-pointer">
                                                    {{ data.ride_status_text }}
                                                </span>
                                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2">Assigned</span>
                                            </div>
                                        </div>
                                        <div
                                            class="bg-light no-ride-found-label cursor-default"
                                            *ngIf="
                                                (
                                                    scheduledrides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: 'upcoming'
                                                ).length === 0 && filterRideType
                                            "
                                        >
                                            No results found!
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="!filterRideType || filterRideType === 'on-demand'">
                                        <div
                                            class="card-shadow"
                                            *ngFor="
                                                let data of regular_rides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: 'ongoing';
                                                let i = index
                                            "
                                            (click)="openTripDetails(data, 'regular-ride')"
                                            [class.active_trip_data]="this.active_trip_data === 'regular-ride-' + data.session_id"
                                        >
                                            <div class="d-flex justify-content-between">
                                                <div class="user-name">
                                                    {{ data.user_name | ShortText | titlecase }}
                                                </div>
                                                <div *ngIf="data?.third_party_ride" class="mas">MAS</div>
                                            </div>
                                            <div class="time">
                                                {{ data.date | date: 'MM/dd/yyyy' }} | {{ data.date | date: 'hh:mm a' }}
                                                - (
                                                <span *ngIf="!data.offset">{{ data.tzName }}</span>
                                                {{ data.offset | timezone }} )
                                            </div>
                                            <div class="status-label status-color" [ngClass]="data.ride_status_text_class">
                                                <span class="cursor-pointer">
                                                    {{ data.ride_status_text }}
                                                </span>
                                                <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2">Assigned</span>
                                            </div>
                                        </div>
                                        <div
                                            class="bg-light no-ride-found-label cursor-default"
                                            *ngIf="
                                                (
                                                    regular_rides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: 'ongoing'
                                                ).length === 0 && filterRideType
                                            "
                                        >
                                            No results found!
                                        </div>
                                    </ng-container>

                                    <ng-container>
                                        <div
                                            class="bg-light no-ride-found-label cursor-default"
                                            *ngIf="
                                                !filterRideType &&
                                                (
                                                    scheduledrides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: 'upcoming'
                                                ).length === 0 &&
                                                (
                                                    regular_rides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: 'ongoing'
                                                ).length === 0
                                            "
                                        >
                                            No results found!
                                        </div>
                                    </ng-container>
                                </ng-container>

                                <ng-container *ngIf="filterRideType === 'cancelled'">
                                    <div
                                        class="card-shadow"
                                        *ngFor="
                                            let data of regular_scheduled_trips
                                                | StringFilter: 'user_name':searchString.trim()
                                                | DateFilter: 'date':rideDate
                                                | CustomRideFilter: 'cancelled';
                                            let i = index
                                        "
                                        (click)="openTripDetails(data, 'dispatched-mas-trips')"
                                        [class.active_trip_data]="this.active_trip_data === 'dispatched-mas-trips-' + data.session_id"
                                    >
                                        <div class="d-flex justify-content-between">
                                            <div class="user-name">
                                                {{ data.user_name | ShortText | titlecase }}
                                            </div>
                                            <div *ngIf="data?.third_party_ride" class="mas">MAS</div>
                                        </div>
                                        <div class="time">

                                            <ng-container *ngIf="data?.is_schedule">
                                                {{ data.pickup_time_processed | date: 'MM/dd/yyyy' }} |
                                                {{ data.pickup_time_processed | date: 'hh:mm a' }}
                                            </ng-container>

                                            <ng-container *ngIf="!data?.is_schedule">
                                                {{ data.date | date: 'MM/dd/yyyy' }} | {{ data.date | date: 'hh:mm a' }}
                                            </ng-container>

                                            - (
                                            <span *ngIf="!data.offset">{{ data.tzName }}</span>
                                            {{ data.offset | timezone }} )
                                        </div>
                                        <div class="status-label status-color" [ngClass]="data.ride_status_text_class">
                                            <span class="cursor-pointer">
                                                {{ data.ride_status_text }}
                                            </span>
                                            <span *ngIf="data?.is_assigned_trip" class="badge badge-dark-2">Assigned</span>
                                        </div>
                                    </div>

                                    <div
                                        class="bg-light no-ride-found-label cursor-default"
                                        *ngIf="
                                            (
                                                regular_scheduled_trips
                                                | StringFilter: 'user_name':searchString.trim()
                                                | DateFilter: 'date':rideDate
                                                | CustomRideFilter: 'cancelled'
                                            ).length === 0
                                        "
                                    >
                                        No results found!
                                    </div>
                                </ng-container>
                            </ng-container> -->

                            <!-- Trip Roster -->
                            <ng-container *ngIf="selectedIndex === 2">
                                <!-- Change this for queued and assigned view -->
                                <ng-container *ngIf="filterRideType === 'alltrips'">
                                    <div
                                        *ngFor="let data of all_queued_assigned_trips; let i = index"
                                        class="card-shadow"
                                        [class.active_trip_data]="this.active_trip_data === 'scheduled-mas-trip-' + data?.mas_trip_id"
                                        (click)="open_mas_trip_details(data)"
                                    >
                                        <ng-container *ngIf="data?.is_assigned_trip">
                                            <div class="user-name">
                                                {{ data?.user_name | titlecase | ShortText }}
                                                <br />
                                                <span style="opacity: 0.8">
                                                    {{ data?.requested_driver_names }}
                                                </span>
                                            </div>
                                            <div class="status-label text-grey">
                                                {{ data?.pickup_datetime | date: 'MM/dd/yyyy' }} | {{ data.pickup_datetime | date: 'hh:mm a' }} -
                                                (EDT)
                                            </div>
                                            <div class="time status-color">
                                                <span
                                                    class="cursor-pointer"
                                                    *ngIf="!data?.next_milestone"
                                                    [ngClass]="{
                                                        'text-dark-blue': data?.assigned_status === 'pending',
                                                        'text-red': data?.assigned_status === 'declined',
                                                        'text-green': data?.assigned_status === 'confirmed',
                                                        'text-red': data?.assigned_status === 'cancelled',
                                                        'text-orange': data?.assigned_status === 'completed'
                                                    }"
                                                >
                                                    {{ data?.assigned_status | titlecase }}
                                                </span>
                                                <!-- If next milestone is available show the next milestone based label, and show green text for confirmed status -->
                                                <span
                                                    *ngIf="data?.next_milestone"
                                                    class="status-color"
                                                    [ngClass]="{
                                                        blue: data?.ride_milestone !== 0,
                                                        'text-green': data?.ride_milestone == 0
                                                    }"
                                                >
                                                    {{ data?.ride_milestone_text }}
                                                </span>
                                                <span class="text-grey">
                                                    <span *ngIf="data?.session_id">&nbsp;|&nbsp;Trip ID {{ data?.session_id }}</span>
                                                    <!-- <span *ngIf="data?.mas_trip_id">&nbsp;|&nbsp;#{{ data?.mas_trip_id }}</span> -->
                                                </span>
                                            </div>
                                        </ng-container>

                                        <ng-container *ngIf="!data?.is_assigned_trip">
                                            <div class="user-name">
                                                {{ data?.user_name | titlecase | ShortText }}
                                                <br />
                                                <span style="opacity: 0.8">
                                                    {{ data?.requested_driver_names }}
                                                </span>
                                            </div>
                                            <div class="status-label text-grey">
                                                {{ data?.pickup_datetime | date: 'MM/dd/yyyy' }} | {{ data.pickup_datetime | date: 'hh:mm a' }} -
                                                (EDT)
                                            </div>
                                            <div class="time status-color">
                                                <span
                                                    class="cursor-pointer"
                                                    *ngIf="data?.ride_status != 0"
                                                    [ngClass]="{
                                                        'text-dark-blue': data?.assigned_status === 'pending',
                                                        'text-red': data?.assigned_status === 'declined',
                                                        'text-green': data?.assigned_status === 'confirmed',
                                                        'text-red': data?.assigned_status === 'cancelled',
                                                        'text-orange': data?.assigned_status === 'completed'
                                                    }"
                                                >
                                                    {{ data?.assigned_status | titlecase }}
                                                </span>
                                                <span *ngIf="data?.ride_status == 0" class="text-blue">Active</span>
                                                <span class="text-grey">
                                                    <span *ngIf="data?.session_id">&nbsp;|&nbsp;Trip ID {{ data?.session_id }}</span>
                                                    <!-- <span *ngIf="data?.mas_trip_id">&nbsp;|&nbsp;#{{ data?.mas_trip_id }}</span> -->
                                                </span>
                                            </div>
                                            <div class="time status-color d-flex">
                                                <div class="align-self-center">
                                                    <!-- <i *ngIf="data?.confirmed_drivers_count === 0" class="text-dark-blue fs-16 fa fa-exclamation-circle"></i>
                                                    <i *ngIf="data?.confirmed_drivers_count > 0" class="text-green fs-16 fa fa-exclamation-circle"></i> -->
                                                    <img
                                                        *ngIf="data?.confirmed_drivers_count"
                                                        style="height: 13px; fill: #0d8b5e"
                                                        src="assets/images/exclamation-circle.svg"
                                                        alt="Confirmed Drivers"
                                                    />
                                                    <img
                                                        *ngIf="!data?.confirmed_drivers_count"
                                                        style="height: 13px"
                                                        src="assets/images/exclamation-circle.svg"
                                                        alt="Confirmed Drivers"
                                                    />
                                                    <span class="text-grey ml-1">({{ data?.confirmed_drivers_count }})</span>
                                                </div>
                                                <div class="mx-1 text-grey">|</div>
                                                <div class="align-self-center">
                                                    <img
                                                        *ngIf="data?.interested_drivers_count"
                                                        style="height: 16px"
                                                        src="assets/images/thumbs_up.svg"
                                                        alt="Interested Drivers"
                                                    />
                                                    <img
                                                        *ngIf="!data?.interested_drivers_count"
                                                        style="height: 16px"
                                                        src="assets/images/thumbs_up_dark.png"
                                                        alt="Interested Drivers"
                                                    />
                                                    <span class="text-grey ml-1">({{ data?.interested_drivers_count }})</span>
                                                </div>
                                                <div class="flex-fill"></div>
                                                <div stop-propagation>
                                                    <app-live-queue-trip-popover
                                                        [mas_trip_id]="data?.mas_trip_id"
                                                        [is_live]="data?.go_live || false"
                                                        [minimal]="true"
                                                        containerClass="br-6 preferred_driver"
                                                        (onChange)="filterRideType === 'alltrips' ? get_all_trips() : get_queued_trips()"
                                                    ></app-live-queue-trip-popover>
                                                </div>
                                            </div>
                                        </ng-container>
                                    </div>

                                    <div class="bg-light no-ride-found-label cursor-default" *ngIf="!all_queued_assigned_trips?.length">
                                        No results found!
                                    </div>
                                </ng-container>

                                <ng-container *ngIf="filterRideType === 'assigned'">
                                    <div
                                        *ngFor="let data of assigned_trips; let i = index"
                                        class="card-shadow"
                                        [class.active_trip_data]="this.active_trip_data === 'scheduled-mas-trip-' + data?.mas_trip_id"
                                        (click)="open_mas_trip_details(data)"
                                    >
                                        <div class="user-name">
                                            {{ data?.user_name | titlecase | ShortText }}
                                            <br />
                                            <span style="opacity: 0.8">
                                                {{ data?.requested_driver_names }}
                                            </span>
                                        </div>
                                        <div class="status-label text-grey">
                                            {{ data?.pickup_datetime | date: 'MM/dd/yyyy' }} | {{ data.pickup_datetime | date: 'hh:mm a' }} - (EDT)
                                        </div>
                                        <div class="time status-color">
                                            <span
                                                class="cursor-pointer"
                                                *ngIf="!data?.next_milestone"
                                                [ngClass]="{
                                                    'text-dark-blue': data?.assigned_status === 'pending',
                                                    'text-red': data?.assigned_status === 'declined',
                                                    'text-green': data?.assigned_status === 'confirmed',
                                                    'text-red': data?.assigned_status === 'cancelled',
                                                    'text-orange': data?.assigned_status === 'completed'
                                                }"
                                            >
                                                {{ data?.assigned_status | titlecase }}
                                            </span>
                                            <!-- If next milestone is available show the next milestone based label, and show green text for confirmed status -->
                                            <span
                                                *ngIf="data?.next_milestone"
                                                class="status-color"
                                                [ngClass]="{
                                                    blue: data?.ride_milestone !== 0,
                                                    'text-green': data?.ride_milestone == 0
                                                }"
                                            >
                                                {{ data?.ride_milestone_text }}
                                            </span>
                                            <span class="text-grey">
                                                <span *ngIf="data?.session_id">&nbsp;|&nbsp;Trip ID {{ data?.session_id }}</span>
                                                <!-- <span *ngIf="data?.mas_trip_id">&nbsp;|&nbsp;#{{ data?.mas_trip_id }}</span> -->
                                            </span>
                                        </div>
                                    </div>

                                    <div class="bg-light no-ride-found-label cursor-default" *ngIf="!assigned_trips?.length">No results found!</div>
                                </ng-container>

                                <ng-container *ngIf="filterRideType === 'queue-trip'">
                                    <div
                                        *ngFor="let data of queued_trips; let i = index"
                                        class="card-shadow"
                                        [class.active_trip_data]="this.active_trip_data === 'scheduled-mas-trip-' + data?.mas_trip_id"
                                        (click)="open_mas_trip_details(data); getQueuedDrivers(data?.mas_trip_id)"
                                    >
                                        <div class="user-name">
                                            {{ data?.user_name | titlecase | ShortText }}
                                            <br />
                                            <span style="opacity: 0.8">
                                                {{ data?.requested_driver_names }}
                                            </span>
                                        </div>
                                        <div class="status-label text-grey">
                                            {{ data?.pickup_datetime | date: 'MM/dd/yyyy' }} | {{ data.pickup_datetime | date: 'hh:mm a' }} - (EDT)
                                        </div>
                                        <div class="time status-color">
                                            <span class="text-blue">Active</span>
                                            <span class="text-grey">
                                                <span *ngIf="data?.session_id">&nbsp;|&nbsp;Trip ID {{ data?.session_id }}</span>
                                                <!-- <span *ngIf="data?.mas_trip_id">&nbsp;|&nbsp;#{{ data?.mas_trip_id }}</span> -->
                                            </span>
                                        </div>
                                        <div class="time status-color d-flex">
                                            <div class="align-self-center">
                                                <!-- <i *ngIf="data?.confirmed_drivers_count === 0" class="text-dark-blue fs-16 fa fa-exclamation-circle"></i>
                                                <i *ngIf="data?.confirmed_drivers_count > 0" class="text-green fs-16 fa fa-exclamation-circle"></i> -->
                                                <img
                                                    *ngIf="data?.confirmed_drivers_count"
                                                    style="height: 13px; fill: #0d8b5e"
                                                    src="assets/images/exclamation-circle.svg"
                                                    alt="Confirmed Drivers"
                                                />
                                                <img
                                                    *ngIf="!data?.confirmed_drivers_count"
                                                    style="height: 13px"
                                                    src="assets/images/exclamation-circle.svg"
                                                    alt="Confirmed Drivers"
                                                />
                                                <span class="text-grey ml-1">({{ data?.confirmed_drivers_count }})</span>
                                            </div>
                                            <div class="mx-1 text-grey">|</div>
                                            <div class="align-self-center">
                                                <img
                                                    *ngIf="data?.interested_drivers_count"
                                                    style="height: 16px"
                                                    src="assets/images/thumbs_up.svg"
                                                    alt="Interested Drivers"
                                                />
                                                <img
                                                    *ngIf="!data?.interested_drivers_count"
                                                    style="height: 16px"
                                                    src="assets/images/thumbs_up_dark.png"
                                                    alt="Interested Drivers"
                                                />
                                                <span class="text-grey ml-1">({{ data?.interested_drivers_count }})</span>
                                            </div>
                                            <div class="flex-fill"></div>
                                            <div stop-propagation>
                                                <app-live-queue-trip-popover
                                                    [mas_trip_id]="data?.mas_trip_id"
                                                    [is_live]="data?.go_live || false"
                                                    [minimal]="true"
                                                    containerClass="br-6 preferred_driver"
                                                    (onChange)="get_queued_trips()"
                                                ></app-live-queue-trip-popover>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="bg-light no-ride-found-label cursor-default" *ngIf="!queued_trips?.length">No results found!</div>
                                </ng-container>
                            </ng-container>

                            <!-- NG-TEMPLATE -->
                        </ng-container>
                    </div>

                    <!-- ------------------------ -->
                    <div style="padding: 0px 8px">
                        <div id="page-navigation-bar" [class.h-auto]="third_party_enabled" class="card-shadow">
                            <div
                                *ngIf="third_party_enabled && filterRideType === 'assigned'"
                                class="fs-13 d-flex tab-bar touch-dropdown p-1 m-0 bg-dark text-white justify-content-center position-relative"
                            >
                                <a
                                    data-target="mas_assigned_filter_type"
                                    class="btn btn-outline-primary btn-md dropdown dropright"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span class="dropdown-toggle">
                                        <span class="light-weight">Status:</span>
                                        :
                                        <span>{{ assigned_status_filter || 'all' | titlecase }}</span>
                                    </span>
                                    &nbsp;
                                </a>
                                <div class="dropdown-menu cd-menu card-shadow" aria-labelledby="mas_assigned_filter_type">
                                    <div
                                        *ngFor="let item of assigned_filter_list"
                                        class="dropdown-item cd-item cursor-pointer"
                                        (click)="filter_assigned_status(item === 'all' ? null : item)"
                                    >
                                        {{ item | titlecase }}
                                    </div>
                                </div>
                            </div>

                            <!-- TODO REMOVE -->
                            <!-- <div
                                *ngIf="third_party_enabled && filterRideType === 'alltrips'"
                                class="d-none fs-13 d-flex tab-bar touch-dropdown p-1 m-0 bg-dark text-white justify-content-center position-relative"
                            >
                                <a
                                    data-target="mas_filter_type"
                                    class="btn btn-outline-primary btn-md dropdown dropright"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span class="dropdown-toggle">
                                        <span class="light-weight">Trip Leg</span>
                                        :
                                        <span>{{ mas_filter_type | titlecase }}</span>
                                    </span>
                                    &nbsp;
                                </a>
                                <div class="dropdown-menu cd-menu card-shadow" aria-labelledby="mas_filter_type">
                                    <div class="dropdown-item cd-item cursor-pointer">All</div>
                                    <div class="dropdown-item cd-item cursor-pointer">Initial</div>
                                </div>
                            </div> -->

                            <div
                                *ngIf="third_party_enabled && filterRideType === 'queue-trip'"
                                class="fs-13 d-flex tab-bar touch-dropdown p-1 m-0 bg-dark text-white justify-content-center position-relative"
                            >
                                <a
                                    data-target="queue_filter_type"
                                    class="btn btn-outline-primary btn-md dropdown dropright"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <span class="dropdown-toggle">
                                        <span class="light-weight">Queued:</span>
                                        :
                                        <span>
                                            {{ queue_filter_type | titlecase }}
                                            <span *ngIf="queue_filter_type === 'live'">Trips</span>
                                        </span>
                                    </span>
                                    &nbsp;
                                </a>
                                <div class="dropdown-menu cd-menu card-shadow" aria-labelledby="queue_filter_type">
                                    <div class="dropdown-item cd-item cursor-pointer" (click)="queue_filter_type = 'all'; get_queued_trips()">
                                        All
                                    </div>
                                    <div class="dropdown-item cd-item cursor-pointer" (click)="queue_filter_type = 'live'; get_queued_trips()">
                                        Live Trips
                                    </div>
                                </div>
                            </div>
                            <hr />

                            <div class="page-content disable-select" align="center">
                                <!-- <ng-container *ngIf="selectedIndex <= 1">
                                    <ng-container *ngIf="filterRideType !== 'completed'">
                                        <ng-container *ngIf="selectedIndex === 0">
                                            {{
                                                (
                                                    scheduledrides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: filterRideType
                                                ).length || '-'
                                            }}
                                            of
                                            {{
                                                (
                                                    scheduledrides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: filterRideType
                                                ).length || '-'
                                            }}
                                        </ng-container>

                                        <ng-container *ngIf="selectedIndex === 1">
                                            {{
                                                (
                                                    regular_rides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: filterRideType
                                                ).length || '-'
                                            }}
                                            of
                                            {{
                                                (
                                                    regular_rides
                                                    | StringFilter: 'user_name':searchString.trim()
                                                    | DateFilter: 'pickup_time_processed':rideDate
                                                    | CustomRideFilter: filterRideType
                                                ).length || '-'
                                            }}
                                        </ng-container>
                                    </ng-container>

                                    <ng-container *ngIf="filterRideType === 'completed'">
                                        {{
                                            (
                                                completedRides
                                                | StringFilter: 'user_name':searchString.trim()
                                                | DateFilter: 'pickup_time_processed':rideDate
                                                | CustomRideFilter: (selectedIndex === 0 ? 'scheduled' : 'on-demand')
                                            ).length || '-'
                                        }}
                                        of
                                        {{
                                            (
                                                completedRides
                                                | StringFilter: 'user_name':searchString.trim()
                                                | DateFilter: 'pickup_time_processed':rideDate
                                                | CustomRideFilter: (selectedIndex === 0 ? 'scheduled' : 'on-demand')
                                            ).length || '-'
                                        }}
                                    </ng-container>
                                </ng-container> -->
                                <ng-container>
                                    {{ trip_to || '-' }}
                                    of
                                    {{ total_rides || '-' }}
                                </ng-container>
                                &nbsp;&nbsp;
                                <button class="cursor-pointer" [disabled]="current_page === 1" (click)="pageChanged(current_page - 1, 'scheduled')">
                                    Previous
                                </button>
                                &nbsp;
                                <button class="cursor-pointer" [disabled]="current_page === 1" (click)="pageChanged(1, 'scheduled')">
                                    {{ '|<' }}
                                </button>
                                &nbsp;
                                <button
                                    class="cursor-pointer"
                                    [disabled]="current_page === total_pages"
                                    (click)="pageChanged(total_pages, 'scheduled')"
                                >
                                    >|
                                </button>
                                &nbsp;
                                <button
                                    class="cursor-pointer"
                                    [disabled]="current_page === total_pages"
                                    (click)="pageChanged(current_page + 1, 'scheduled')"
                                >
                                    Next
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="middle-bar-handle invisible col-auto px-1" [class.d-none]="!ipadSize"></div>

            <!-- 'col-auto': middleBarClosed && ipadSize, -->
            <div
                class="px-1"
                [ngClass]="{
                    'responsive-middleBar animate': ipadSize,
                    'left-0': leftSideClosed,
                    'normal-width': !middleBarClosed,
                    col: !ipadSize
                }"
            >
                <div>
                    <!-- [class.d-none]="middleBarClosed" -->
                    <div
                        class="middle-bar card-shadow"
                        [ngClass]="{ 'card-shadow': !ipadSize || (ipadSize && !middleBarClosed), 'active-background': !newRideMode }"
                        id="expandedDiv"
                    >
                        <div
                            class="tab-panel no-select"
                            style="position: -webkit-sticky; position: sticky; top: 0px; background-color: white; z-index: 2"
                        >
                            <div class="row" *ngIf="returnRideMode">
                                <div class="col-auto px-0 ml-3 mr-auto">
                                    <div class="active-tab cursor-pointer">Return Ride Request</div>
                                </div>
                                <div class="col-auto label px-1" style="font-weight: 600; margin-top: -5px">
                                    <img src="assets/images/divider_black.svg" alt="divider" />
                                    &nbsp; {{ tripnumber }}
                                </div>
                            </div>

                            <div class="row" *ngIf="tripDetailMode">
                                <div class="col-auto px-0 ml-3">
                                    <div class="cursor-pointer" [class.active-tab]="active_trip_tab == 0" (click)="active_trip_tab = 0">
                                        Trip Details
                                    </div>
                                </div>
                                <div class="col-auto px-0">
                                    <div class="cursor-pointer" [class.active-tab]="active_trip_tab == 1" (click)="active_trip_tab = 1">
                                        Request Status
                                    </div>
                                </div>
                                <div class="col-auto px-0" *ngIf="rideInFocus?.mas_trip_id">
                                    <div
                                        class="cursor-pointer"
                                        [class.active-tab]="active_trip_tab == 2"
                                        (click)="active_trip_tab = 2; get_trip_activity(rideInFocus?.mas_trip_id)"
                                    >
                                        Trip Activity
                                    </div>
                                </div>
                                <div class="col-auto label px-1 ml-auto" style="font-weight: 600; margin-top: -5px" *ngIf="tripnumber">
                                    <img src="assets/images/divider_black.svg" alt="divider" />
                                    &nbsp; {{ tripnumber }}
                                </div>
                            </div>

                            <div class="row" [hidden]="!newRideMode">
                                <div class="col-auto mr-auto px-0 ml-3">
                                    <div class="active-tab cursor-pointer">New Ride Request</div>
                                </div>
                                <div class="col-auto label px-1" style="margin-top: -5px">
                                    <img src="assets/images/divider_black.svg" alt="divider" />
                                    &nbsp; Enter Trip Details
                                </div>
                            </div>
                        </div>

                        <div class="nr-section no-select" [hidden]="!newRideMode">
                            <ng-container *ngIf="activeMobileMenu == 'viewMap'">
                                <div class="my-3 py-2">
                                    <div class="row m-1" style="position: relative">
                                        <div class="col-auto ml-1">
                                            <!-- <img src="assets/images/person.svg" alt="person_icon"> -->
                                            <i class="fa fa-user" style="color: grey"></i>
                                            &nbsp;&nbsp;&nbsp;
                                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                        </div>
                                        <div class="col input-box mt-1 mb-2">
                                            <input
                                                id="userName"
                                                [(ngModel)]="booking.user_name"
                                                type="search"
                                                onkeypress="return /[a-z ]/i.test(event.key)"
                                                (focus)="getNewUsers(1)"
                                                [ngModelOptions]="{ debounce: 400 }"
                                                class="autocompleteClass"
                                                autocomplete="off"
                                                (blur)="closeAC()"
                                                (keyup.escape)="closeAC(1)"
                                                (ngModelChange)="replaceNumber('userName'); getNewUsers(1)"
                                            />

                                            <label for="userName" [class.active-input-state]="booking.user_name">Passenger Name</label>
                                        </div>

                                        <!-- name suggestion box -->

                                        <!-- style="max-width: 300px;margin-top:20px; margin-left:6px" -->
                                        <div class="myautocomplete card-shadow" [ngClass]="{ hidden: closeAutocomplete == 1 }">
                                            <div class="name-list">
                                                <div
                                                    class="name-item mt-1"
                                                    *ngFor="let user of Users | StringFilter: 'user_name':booking?.user_name?.trim(); let i = index"
                                                    (click)="selectThisUser(user.user_id, user.user_mobile, user.user_name, user.user_email)"
                                                >
                                                    <div class="row-inline mx-1 py-2 cursor-pointer">
                                                        <div class="col-auto">
                                                            <!-- <img src="assets/images/person.svg" alt="person_icon"> -->
                                                            <i class="fa fa-user dynamic_padding" style="color: grey; font-size: 16px"></i>

                                                            <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
                                                        </div>
                                                        <div class="col-auto input-box">
                                                            <div>
                                                                <span class="mr-2">
                                                                    {{ user.user_name | ShortText: 8 | titlecase }}
                                                                </span>

                                                                <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
                                                            </div>
                                                        </div>
                                                        <div class="col-auto my-auto">
                                                            {{ user.user_mobile }}
                                                        </div>
                                                    </div>
                                                </div>
                                                <li
                                                    *ngIf="
                                                        Users?.length == 0 ||
                                                        (Users | StringFilter: 'user_name':booking?.user_name?.trim()).length === 0
                                                    "
                                                    class="text-center m-3"
                                                >
                                                    No User Found...
                                                </li>
                                            </div>
                                            <hr style="padding: 2px; margin: 0 15px 15px" />

                                            <div class="text-center my-3 mx-4 button-wrapper" style="z-index: 3">
                                                <div class="row">
                                                    <!-- <div class="col-5 px-1">
                                                        <button class="animate-show btn btn-dark button-black m-0"
                                                            (click)="openAddUser()">
                                                            <i class="fa fa-user"></i>
                                                            &nbsp; Signup New User
                                                        </button>
                                                    </div> -->
                                                    <div class="col-12 px-1">
                                                        <button
                                                            (click)="closeAC(); getNewUsers()"
                                                            [disabled]="DisableSRR"
                                                            class="animate-show btn btn-primary mobile-view-add-trip-button"
                                                        >
                                                            Ride Request w/out Signup &nbsp;
                                                            <i class="fa fa-chevron-right"></i>
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <!-- name suggestion box -->
                                    </div>
                                    <hr />
                                </div>

                                <div class="my-3 py-2">
                                    <div class="row m-1 mb-2">
                                        <div class="col-auto ml-1">
                                            <i class="fa fa-phone" style="color: grey"></i>
                                            &nbsp;&nbsp;&nbsp;
                                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                        </div>
                                        <div class="col input-box mt-1">
                                            <div class="row-input" id="live-track-mobile-field">
                                                <ngx-intl-tel-input
                                                    [cssClass]="'borderless-input iphone-bg'"
                                                    type="search"
                                                    [resetSearchContainer]="true"
                                                    [preferredCountries]="preferredCountries"
                                                    [enableAutoCountrySelect]="true"
                                                    [enablePlaceholder]="true"
                                                    [searchCountryFlag]="true"
                                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                    [selectFirstCountry]="false"
                                                    [selectedCountryISO]="countryToSet"
                                                    [tooltipField]="TooltipLabel.Name"
                                                    [phoneValidation]="true"
                                                    [separateDialCode]="separateDialCode"
                                                    name="phone"
                                                    [inputId]="'phone'"
                                                    [formControl]="phoneNumber"
                                                    [customPlaceholder]="' '"
                                                    [label]="'Phone Number'"
                                                    [maxLength]="promoCountryCode == '+1-' ? 10 : 12"
                                                    (countryChange)="onCountryChange($event)"
                                                    (ngModelChange)="replaceText('phone')"
                                                ></ngx-intl-tel-input>
                                            </div>
                                        </div>
                                    </div>
                                    <input type="hidden" name="extTwo" id="extPopTwo" />
                                    <hr />
                                </div>
                            </ng-container>
                            <div class="my-3 py-2">
                                <div class="row m-1 mr-2">
                                    <div class="col-auto ml-1">
                                        <img src="assets/images/add-queue/calendar.svg" alt="divider_blue" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2">
                                        <label (click)="open_time_selection_popup()">
                                            {{ booking.timeInThatArea ? (booking.timeInThatArea | date: 'MMMM dd, y EEEE hh:mm a') : 'Pickup Now' }}
                                        </label>
                                        <label for="dateTimeMobile" class="active-input-state">Pickup Date & Time</label>
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <!-- <div class="position-relative w-100"> -->
                            <div class="my-3 py-2">
                                <div class="row m-1 mr-2">
                                    <div class="col-auto ml-1">
                                        <!-- <img src="assets/images/pickup_location.svg" alt="pickup_icon" /> -->
                                        <img
                                            (click)="getCurrentLocation('pickup_location')"
                                            src="assets/images/add-queue/location.svg"
                                            alt="divider_blue"
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2">
                                        <span *ngIf="isPickupLocation" class="locationLoading">Loading...</span>
                                        <input
                                            id="pickup"
                                            type="search"
                                            class="autocomplete"
                                            [(ngModel)]="booking.pickup_text"
                                            (change)="showDirection(1)"
                                            (blur)="findPlace('pickup')"
                                            placeholder=""
                                        />
                                        <label for="pickup" [class.active-input-state]="booking.pickup_text">Pickup Location</label>
                                    </div>
                                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer">
                                        <img (click)="swapLocation()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                                    </div>
                                </div>
                                <hr />
                            </div>
                            <!-- <div class="swapper main ">
                                        <img class="img-swap" (click)="swapLocation()" src="/assets/img/swap.png" alt="SWAP" />
                                    </div> -->
                            <!-- </div> -->
                            <div class="my-3 py-2">
                                <!-- <label>Drop off Location</label>
                                        <input id="drop" class="form-control autocomplete" [(ngModel)]="booking.drop_text"
                                        (change)="showDirection(1)" ng-blur="findPlace('drop')"
                                        placeholder="Enter drop off location" /> -->

                                <div class="row m-1 mr-2">
                                    <div class="col-auto ml-1">
                                        <!-- <img src="assets/images/drop_location.svg" alt="drop_location_icon" /> -->
                                        <img
                                            (click)="getCurrentLocation('dropoff_location')"
                                            src="assets/images/add-queue/location.svg"
                                            alt="divider_blue"
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2">
                                        <span *ngIf="isDropOffLocation" class="locationLoading">Loading...</span>
                                        <input
                                            #dropInput
                                            id="drop"
                                            type="search"
                                            class="autocomplete"
                                            [(ngModel)]="booking.drop_text"
                                            (change)="showDirection(1)"
                                            (blur)="findPlace('drop')"
                                            placeholder=""
                                        />
                                        <label for="drop" [class.active-input-state]="booking.drop_text">
                                            Drop off Location
                                            <span class="route-option" (click)="$event.preventDefault(); openRouteOptionModal()">
                                                &nbsp; Route Option
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer text-white">
                                        <img (click)="swapLocation()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <!-- Not in Use -->
                            <div class="my-3 py-2" hidden>
                                <div class="row m-1 mb-0" style="position: relative">
                                    <div class="col-auto ml-1">
                                        <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2" style="position: static">
                                        <div class="dropdown">
                                            <span
                                                class="dropdown-toggle"
                                                type="button"
                                                id="ride_type_dropdown"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                {{
                                                    carsOptions[findIndex_from_objectArray('car_type', this.booking.car_type, carsOptions)]
                                                        ?.car_name || 'Ride Type'
                                                }}
                                            </span>
                                            <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                                            <div class="dropdown-menu card-shadow pt-2 pb-3" id="ride_type_menu" aria-labelledby="ride_type_dropdown">
                                                <ng-container *ngFor="let obj of carsOptions; let i = index">
                                                    <div class="dropdown-item p-0">
                                                        <div class="row my-1 cursor-pointer p-0" (click)="selectCarType(obj)">
                                                            <div class="col-auto ml-4">
                                                                <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                                &nbsp;&nbsp;
                                                                <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                            </div>
                                                            <div class="col mt-1 item-label p-0">
                                                                {{ obj.car_name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        class="col-auto mr-3"
                                        class="more-info cursor-pointer"
                                        (click)="carSelectorMode()"
                                        [class.invisible]="ipadSize && middleBarClosed"
                                    >
                                        <i class="fa fa-exclamation-circle"></i>
                                        &nbsp;
                                        <span class="no-select">More info</span>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div class="my-3 py-2">
                                <div class="row m-1" style="position: relative">
                                    <div class="col-auto ml-1">
                                        <!-- <img src="assets/images/prefer_fav.svg" alt="prefer_fav_icon" /> -->
                                        <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col-auto pl-0 my-auto">
                                        <div class="more-info cursor-pointer" style="margin-left: -3px" (click)="carSelectorMode()">
                                            <i class="fa fa-exclamation-circle text-white"></i>
                                        </div>
                                    </div>
                                    <div class="col input-box mt-1 mb-2" style="position: static">
                                        <ng-container [ngTemplateOutlet]="select_car_type_dropdown"></ng-container>
                                    </div>
                                    <div class="col-auto my-auto pr-0" (click)="DriverSelectorMode()" [class.invisible]="ipadSize && middleBarClosed">
                                        <div class="more-info cursor-pointer">
                                            <i class="fa fa-heart mr-1"></i>
                                            <span class="show mr-1 no-select">Select Driver</span>
                                            <i class="fa fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div class="my-2 py-1" id="health_checkbox">
                                <div class="row m-1">
                                    <div class="col-auto ml-2 mt-2" style="width: 42px">
                                        <div class="custom-checkbox" (click)="health_policy_checkbox = !health_policy_checkbox">
                                            <i *ngIf="health_policy_checkbox" class="fa fa-check-square" aria-hidden="true"></i>
                                            <i *ngIf="!health_policy_checkbox" class="fa fa-square-o" aria-hidden="true"></i>
                                        </div>
                                        <!-- &nbsp;&nbsp;&nbsp; -->
                                    </div>
                                    <div class="col input-box disable-select">
                                        <label class="cursor-default" (click)="health_policy_checkbox = !health_policy_checkbox">
                                            I confirm to adhere to the latest CDC policies to stay informed, review health and safety measures taken
                                            about COVID-19.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="rr-section" [hidden]="!returnRideMode">
                            <div class="mx-2">
                                <div class="row col-md-12 mt-4 mb-1">
                                    <!-- col-lg-12 col-sm-12 col-md-12 col-xs-12 -->
                                    <div class="col-8 mr-auto">
                                        <label style="margin-bottom: 3px" class="l1">Passenger</label>
                                        <br />
                                        <label class="l2 text-break">
                                            {{ user_name | ShortText: 25 | titlecase }}
                                            <!-- <span *ngIf="user_name?.length >= 25">...</span> -->
                                            <br />
                                            {{ user_mobile }}
                                        </label>
                                    </div>
                                    <div class="col-auto">
                                        <img src="{{ user_image }}" *ngIf="user_image" style="border-radius: 50%" class="center-block image" />
                                        <img
                                            src="http://qudos-s3.s3.amazonaws.com/user_profile/user.png"
                                            *ngIf="!user_image"
                                            style="border-radius: 50%"
                                            class="center-block image dummy"
                                        />
                                    </div>
                                </div>
                            </div>

                            <div class="my-3 py-2">
                                <div class="row m-1 mb-0" style="position: relative" hidden>
                                    <div class="col-auto ml-1">
                                        <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2" style="position: static">
                                        <div class="dropdown">
                                            <span
                                                class="dropdown-toggle"
                                                type="button"
                                                id="ride_type_dropdown_return"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                                #currentRideType
                                            >
                                                {{
                                                    carsOptions[findIndex_from_objectArray('car_type', this.booking.car_type, carsOptions)]
                                                        ?.car_name || 'Ride Type'
                                                }}
                                            </span>
                                            <div
                                                class="dropdown-menu card-shadow pt-2 pb-3"
                                                id="ride_type_menu_return"
                                                style="transform: translate3d(0px, 397px, 0px) !important"
                                                aria-labelledby="ride_type_dropdown_return"
                                            >
                                                <ng-container *ngFor="let obj of carsOptions; let i = index">
                                                    <div class="dropdown-item p-0">
                                                        <div class="row my-1 cursor-pointer p-0" (click)="selectCarType(obj)">
                                                            <div class="col-auto ml-4">
                                                                <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                                &nbsp;&nbsp;
                                                                <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                            </div>
                                                            <div class="col mt-1 item-label p-0">
                                                                {{ obj.car_name }}
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr />
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        class="col-auto mr-2"
                                        class="more-info cursor-pointer"
                                        (click)="carSelectorMode()"
                                        [class.invisible]="ipadSize && middleBarClosed"
                                    >
                                        <i class="fa fa-exclamation-circle"></i>
                                        &nbsp;
                                        <span class="no-select">More info</span>
                                    </div>
                                </div>
                                <div class="row m-1 mb-0" style="position: relative">
                                    <div class="col-auto ml-1">
                                        <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col-auto pl-0 my-auto">
                                        <div class="more-info cursor-pointer" style="margin-left: -3px" (click)="carSelectorMode()">
                                            <i class="fa fa-exclamation-circle text-white"></i>
                                        </div>
                                    </div>
                                    <div class="col input-box mt-1 mb-2" style="position: static">
                                        <ng-container [ngTemplateOutlet]="select_car_type_dropdown"></ng-container>
                                    </div>
                                    <div class="col-auto my-auto pr-0" (click)="DriverSelectorMode()" [class.invisible]="ipadSize && middleBarClosed">
                                        <div class="more-info cursor-pointer">
                                            <i class="fa fa-heart mr-1"></i>
                                            <span class="show mr-1 no-select">Select Driver</span>
                                            <i class="fa fa-chevron-right"></i>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div class="my-3 py-2">
                                <div class="row m-1 mr-2">
                                    <div class="col-auto ml-1">
                                        <img src="assets/images/pickup_location.svg" alt="pickup_icon" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2">
                                        <input
                                            id="return-pickup"
                                            type="search"
                                            name="pickup"
                                            class="autocomplete"
                                            [(ngModel)]="booking.pickup_text_return"
                                            (change)="showDirection(1)"
                                            (blur)="findPlace('return-pickup')"
                                            placeholder=""
                                        />
                                        <label for="return-pickup" [class.active-input-state]="booking.pickup_text_return">Pickup Location</label>
                                    </div>
                                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer">
                                        <img (click)="swapLocationforReturn()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div class="my-3 py-2">
                                <div class="row m-1 mr-2">
                                    <div class="col-auto ml-1">
                                        <img src="assets/images/drop_location.svg" alt="drop_location_icon" />
                                        &nbsp;&nbsp;&nbsp;
                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                    </div>
                                    <div class="col input-box mt-1 mb-2">
                                        <input
                                            id="return-drop"
                                            type="search"
                                            name="drop"
                                            class="autocomplete"
                                            [(ngModel)]="booking.drop_text_return"
                                            (change)="showDirection(1)"
                                            (blur)="findPlace('return-drop')"
                                            placeholder=""
                                        />
                                        <label for="return-drop" [class.active-input-state]="booking.drop_text_return">
                                            Drop off Location
                                            <span class="route-option" (click)="$event.preventDefault(); openRouteOptionModal()">
                                                &nbsp; Route Option
                                            </span>
                                        </label>
                                    </div>
                                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer">
                                        <img (click)="swapLocationforReturn()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                                    </div>
                                </div>
                                <hr />
                            </div>

                            <div class="my-2 py-1" id="health_checkbox2">
                                <div class="row m-1">
                                    <div class="col-auto ml-2 mt-2" style="width: 42px">
                                        <div class="custom-checkbox" (click)="health_policy_checkbox = !health_policy_checkbox">
                                            <i *ngIf="health_policy_checkbox" class="fa fa-check-square" aria-hidden="true"></i>
                                            <i *ngIf="!health_policy_checkbox" class="fa fa-square-o" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                    <div class="col input-box disable-select">
                                        <label class="cursor-default" (click)="health_policy_checkbox = !health_policy_checkbox">
                                            I confirm to adhere to the latest CDC policies to stay informed, review health and safety measures taken
                                            about COVID-19.
                                        </label>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div class="table td-section" [hidden]="!tripDetailMode">
                            <div class="tdrs">
                                <div [hidden]="!tripDetailMode" *ngIf="active_trip_tab == 0">
                                    <div class="mx-2 mb-1 mt-4">
                                        <div class="col-auto mb-1" *ngIf="selectedIndex === 2 && rideInFocus?.invoice_number">
                                            <label class="l1">
                                                Invoice Number & Trip ID (Leg)
                                                <span class="cursor-pointer text-blue" (click)="open_mas_trip_info_popup()">View Trip Details</span>
                                            </label>
                                            <br />
                                            <label class="l2" *ngIf="!is_mas_loading">{{ mas_invoice_number }} | {{ mas_leg_id }}</label>
                                            <app-loading *ngIf="is_mas_loading" height="16px" borderRadius="4px" type="placeholder"></app-loading>
                                        </div>
                                        <div class="row col-md-12 mb-1">
                                            <!-- col-lg-12 col-sm-12 col-md-12 col-xs-12 -->
                                            <div class="col-8 mr-auto">
                                                <label class="l1">
                                                    Passenger
                                                    <span
                                                        *ngIf="selectedIndex === 2"
                                                        class="text-blue cursor-pointer"
                                                        (click)="view_passenger_trip(user_mobile)"
                                                    >
                                                        View Passenger's Trips
                                                    </span>
                                                </label>
                                                <br />
                                                <label class="l2 text-break" *ngIf="!is_mas_loading">
                                                    <span>{{ user_name | ShortText: 25 | titlecase }}</span>
                                                    <span
                                                        *ngIf="selectedIndex === 2"
                                                        class="text-blue cursor-pointer fs-11 fw-normal"
                                                        (click)="edit_mas_passenger()"
                                                    >
                                                        Edit
                                                    </span>
                                                    <!-- <span *ngIf="user_name?.length >= 25">...</span> -->
                                                    <br />
                                                    {{ user_mobile }}
                                                </label>
                                                <app-loading *ngIf="is_mas_loading" height="16px" borderRadius="4px" type="placeholder"></app-loading>
                                            </div>
                                            <div class="col-auto">
                                                <ng-container *ngIf="!is_mas_loading">
                                                    <img
                                                        src="{{ user_image }}"
                                                        *ngIf="user_image"
                                                        style="border-radius: 50%"
                                                        class="center-block image"
                                                    />
                                                    <img
                                                        src="http://qudos-s3.s3.amazonaws.com/user_profile/user.png"
                                                        *ngIf="!user_image"
                                                        style="border-radius: 50%"
                                                        class="center-block image dummy"
                                                    />
                                                </ng-container>
                                                <ngx-skeleton-loader
                                                    *ngIf="is_mas_loading"
                                                    height="22px"
                                                    appearance="circle"
                                                    [theme]="{
                                                        width: '60px',
                                                        height: '60px'
                                                    }"
                                                ></ngx-skeleton-loader>
                                            </div>
                                        </div>

                                        <div class="col-auto mb-1" [hidden]="selectedIndex === 2">
                                            <label class="l1">Ride Type</label>
                                            <br />
                                            <label class="l2">
                                                {{ car_name }}
                                                <span *ngIf="rideInFocus?.car_info">| {{ rideInFocus?.car_info }}</span>
                                                <ng-container *ngIf="ride_essential == 1">
                                                    -
                                                    <span style="font-size: 14px; font-weight: 600; color: #28a745">Essentials</span>
                                                </ng-container>
                                            </label>
                                        </div>

                                        <!-- col-lg-12 col-sm-12 col-md-12 col-xs-12 -->
                                        <div class="col-auto mb-1">
                                            <!-- enabled when trip roster is not selected -->
                                            <label *ngIf="selectedIndex !== 2" class="l1">Status</label>

                                            <!-- enabled when trip roster is selected -->
                                            <label *ngIf="selectedIndex === 2" class="l1">
                                                <!-- & Leg Cost(MAS) & Leg Mileage shows only for mas trips -->
                                                Ride Status
                                                <span *ngIf="third_party_enabled">& Leg Cost(MAS) & Leg Mileage</span>
                                            </label>
                                            <br />

                                            <span class="hidden hide">|| {{ showStatus }} ||</span>

                                            <label class="l2" *ngIf="!is_mas_loading">
                                                <span [innerHTML]="showStatus"></span>
                                                <ng-container *ngIf="selectedIndex === 2 && third_party_enabled">
                                                    <span>| {{ mas_total_leg_cost || booking?.estimated_fare || 0 | currency: 'USD' }}</span>
                                                    <!-- <span> | {{ mas_mileage | number: '1.2-2' }} miles </span> -->
                                                    <span *ngIf="rideInFocus?.paid == 1" class="paid_label">PAID</span>
                                                </ng-container>

                                                <!-- <span *ngIf="selectedIndex === 2 && third_party_enabled">
                                                    | {{ mas_total_leg_cost || booking?.estimated_fare | currency: 'USD' }} |
                                                    {{ mas_mileage | number: '1.2-2' }} miles
                                                </span> -->
                                            </label>
                                            <app-loading *ngIf="is_mas_loading" height="16px" borderRadius="4px" type="placeholder"></app-loading>
                                        </div>

                                        <div class="col-auto mb-1" *ngIf="mas_active_trip">
                                            <label class="l1">Pickup Date and Time</label>
                                            <br />
                                            <ng-container *ngIf="!is_mas_loading">
                                                <label class="l2" *ngIf="selectedIndex !== 2">
                                                    {{ rideInFocus?.pickup_time | date: 'MMM d, y | EEE h:mm a' }}
                                                </label>
                                                <label class="l2" *ngIf="selectedIndex === 2">
                                                    {{ mas_pickup_date | date: 'MMMM d, y | EEEE' }} {{ mas_pickup_time }} - (EDT)
                                                    <mat-icon
                                                        class="text-dark-blue fs-16 ml-1 cursor-pointer"
                                                        (click)="edit_assigned_trip_time(this.active_assigned_trip)"
                                                    >
                                                        edit
                                                    </mat-icon>
                                                </label>
                                            </ng-container>
                                            <app-loading *ngIf="is_mas_loading" height="16px" borderRadius="4px" type="placeholder"></app-loading>
                                        </div>

                                        <!-- col-lg-12 col-sm-12 col-md-12 col-xs-12 -->

                                        <ng-container *ngIf="!mas_active_trip">
                                            <div class="col-auto mb-1">
                                                <label class="l1">Pickup Location</label>
                                                <br />
                                                <label class="l2" *ngIf="!is_mas_loading">{{ pickup_location_address }}</label>
                                                <app-loading *ngIf="is_mas_loading" height="16px" borderRadius="4px" type="placeholder"></app-loading>
                                            </div>

                                            <div class="col-auto mb-1">
                                                <label class="l1">
                                                    Dropoff Location
                                                    <span class="route-option" (click)="$event.preventDefault(); openRouteOptionModal()">
                                                        &nbsp; Route Option
                                                    </span>
                                                </label>
                                                <br />
                                                <label class="l2" *ngIf="!is_mas_loading">{{ manual_destination_address }}</label>
                                                <app-loading *ngIf="is_mas_loading" height="16px" borderRadius="4px" type="placeholder"></app-loading>
                                            </div>
                                        </ng-container>

                                        <div [hidden]="!mas_active_trip">
                                            <div class="my-3 py-2">
                                                <div class="row m-1 mr-2">
                                                    <div class="col-auto ml-1">
                                                        <img src="assets/images/pickup_location.svg" alt="pickup_icon" />
                                                        &nbsp;&nbsp;&nbsp;
                                                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                    </div>
                                                    <div class="col input-box mt-1 mb-2">
                                                        <input
                                                            id="mas-pickup"
                                                            type="search"
                                                            class="autocomplete"
                                                            [(ngModel)]="booking.pickup_text"
                                                            (change)="showDirection(1)"
                                                            (blur)="findPlace('mas-pickup')"
                                                            placeholder=""
                                                        />
                                                        <label for="mas-pickup" [class.active-input-state]="booking.pickup_text">
                                                            Pickup Location MAS
                                                        </label>
                                                    </div>
                                                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer">
                                                        <img (click)="swapLocation()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                                                    </div>
                                                </div>
                                                <hr />
                                            </div>
                                            <div class="row m-1 mr-2">
                                                <div class="col-auto ml-1">
                                                    <img src="assets/images/drop_location.svg" alt="drop_location_icon" />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                </div>
                                                <div class="col input-box mt-1 mb-2">
                                                    <input
                                                        id="mas-drop"
                                                        type="search"
                                                        class="autocomplete"
                                                        [(ngModel)]="booking.drop_text"
                                                        (change)="showDirection(1)"
                                                        (blur)="findPlace('mas-drop')"
                                                        placeholder=""
                                                    />
                                                    <label for="mas-drop" [class.active-input-state]="booking.drop_text">
                                                        Drop off Location
                                                        <span class="route-option" (click)="$event.preventDefault(); openRouteOptionModal()">
                                                            &nbsp; Route Option
                                                        </span>
                                                    </label>
                                                </div>
                                                <div class="col-auto mr-2" class="form-miles-label cursor-pointer" *ngIf="pickup_dropoff_distance">
                                                    <!-- <img (click)="swapLocation()" src="assets/images/swap_arrow.svg" alt="SWAP" /> -->
                                                    {{ pickup_dropoff_distance }}
                                                </div>
                                                <hr />
                                            </div>
                                        </div>

                                        <!-- col-lg-12 col-sm-12 col-md-12 col-xs-12 -->

                                        <hr />
                                    </div>

                                    <div class="mx-2 my-3" *ngIf="selectedIndex !== 2">
                                        <div class="row col-md-12 mt-2 mb-2 mb-1">
                                            <div class="col-8 mr-auto">
                                                <label class="user_type l1">Driver</label>
                                                <br />
                                                <!-- <label class="user_name l2" [hidden]="!(rideMissed != 'Yes')"> -->
                                                <label class="user_name l2 text-break" *ngIf="driver_name && driver_mobile">
                                                    {{ driver_name }}
                                                    <br />
                                                    {{ driver_mobile }}
                                                </label>
                                                <!-- <label class="user_name l2" [hidden]="!(rideMissed == 'Yes')">No Driver available</label> -->
                                                <label class="user_name l2" *ngIf="!driver_name || !driver_mobile">No Driver available</label>
                                            </div>
                                            <div class="col-auto">
                                                <img
                                                    *ngIf="driver_image"
                                                    [src]="driver_image"
                                                    style="border-radius: 50%"
                                                    class="center-block image"
                                                />
                                                <img
                                                    *ngIf="!driver_image"
                                                    src="http://qudos-s3.s3.amazonaws.com/user_profile/user.png"
                                                    style="border-radius: 50%"
                                                    class="center-block image dummy"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-auto" [hidden]="!(rideMissed != 'Yes')">
                                            <label class="user_type l1">Car</label>
                                            <br />
                                            <label class="user_name l2 text-break">
                                                {{ rideInFocus.car_name }} {{ rideInFocus.car_make }} {{ rideInFocus.car_model }}
                                                {{ rideInFocus.car_color }}
                                                {{ rideInFocus.car_year }}
                                                {{ rideInFocus.car_no }}
                                            </label>
                                        </div>
                                    </div>

                                    <!-- Show More Action for Mas Trip ( Ongoing and Completed) -->
                                    <div class="mx-2 my-3" *ngIf="rideInFocus?.mas_trip_id && selectedIndex !== 2">
                                        <hr />
                                        <div class="col-auto mt-3">
                                            <ng-container [ngTemplateOutlet]="assign_queue_more_action"></ng-container>
                                        </div>
                                    </div>
                                    <div class="mx-2 my-3" *ngIf="selectedIndex === 2">
                                        <div class="py-2" *ngIf="rideInFocus?.is_assigned_trip">
                                            <div class="row m-1" style="position: relative">
                                                <div class="col-auto ml-1">
                                                    <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                </div>
                                                <div class="input-box position-relative">
                                                    <div class="cmodal mt-1 mb-2" style="position: static">
                                                        <div class="dropdown dropright">
                                                            <span
                                                                (click)="findDistance($event)"
                                                                type="button"
                                                                id="prefer_fav_dropdown"
                                                                data-toggle="dropdown"
                                                                aria-haspopup="true"
                                                                aria-expanded="false"
                                                            >
                                                                {{ this.selected_driver?.driver_name }}
                                                                <span *ngIf="selected_driver?.driver_mobile">
                                                                    | {{ selected_driver?.driver_mobile }}
                                                                </span>
                                                                &nbsp;
                                                                <mat-icon class="text-dark-blue" style="font-size: 14px">edit</mat-icon>
                                                            </span>
                                                            <div
                                                                class="dropdown-menu up card-shadow cmodal py-0 first"
                                                                id="prefer_ride_menu"
                                                                aria-labelledby="prefer_fav_dropdown"
                                                                style="width: 250px !important"
                                                            >
                                                                <div class="p-2 search-box">
                                                                    <div class="input-group input-box py-0">
                                                                        <input
                                                                            type="text"
                                                                            class="form-control"
                                                                            [formControl]="driverSearchControl"
                                                                            placeholder="Search Other Drivers"
                                                                        />
                                                                        <div class="input-group-append m-auto">
                                                                            <span class="input-group-text">
                                                                                <i class="fa fa-search" aria-hidden="true"></i>
                                                                            </span>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    style="max-height: 108px"
                                                                    [ngClass]="{ 'overflow-auto': !driverLoading, 'overflow-hidden': driverLoading }"
                                                                >
                                                                    <ng-container *ngIf="!driverLoading">
                                                                        <ng-container *ngFor="let driver of drivers">
                                                                            <div
                                                                                class="dropdown-item cursor-pointer fs-13"
                                                                                (click)="update_assigned_driver(driver, 'update')"
                                                                                *ngIf="
                                                                                    driver?.driver_id.toString() !==
                                                                                    selected_driver?.driver_id?.toString()
                                                                                "
                                                                            >
                                                                                {{ driver.driver_name }}
                                                                            </div>
                                                                        </ng-container>
                                                                    </ng-container>
                                                                    <div *ngIf="driverLoading" class="mx-2">
                                                                        <app-loading
                                                                            [placeholderLength]="5"
                                                                            height="20px"
                                                                            borderRadius="2px"
                                                                            type="placeholder"
                                                                        ></app-loading>
                                                                    </div>
                                                                </div>
                                                                <hr class="light" />
                                                                <div class="d-flex justify-content-around text-blue-secondary fs-13 my-2">
                                                                    <div class="cursor-pointer">Dismiss</div>
                                                                    <div class="cursor-pointer" (click)="update_assigned_driver()">Unassign</div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <label [class.active-input-state]="selected_driver">Assigned Driver</label>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>

                                        <div class="py-2" *ngIf="!rideInFocus?.is_assigned_trip">
                                            <div class="row m-1" style="position: relative">
                                                <div class="col-auto ml-1">
                                                    <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                </div>
                                                <div class="col px-0">
                                                    <ng-container [ngTemplateOutlet]="select_driver_dropdown"></ng-container>
                                                </div>

                                                <div class="col-auto px-0 cursor-pointer" (click)="showQueuedDrivers('confirmed')">
                                                    <img
                                                        *ngIf="data?.confirmed_drivers_count"
                                                        style="height: 13px; fill: #0d8b5e"
                                                        src="assets/images/exclamation-circle.svg"
                                                        alt="Confirmed Drivers"
                                                    />
                                                    <img
                                                        *ngIf="!data?.confirmed_drivers_count"
                                                        style="height: 13px"
                                                        src="assets/images/exclamation-circle.svg"
                                                        alt="Confirmed Drivers"
                                                    />
                                                    <span class="text-grey fs-13">({{ active_assigned_trip?.interested_drivers_count }})</span>
                                                </div>
                                                <div class="col-auto px-0 ml-2 cursor-pointer" (click)="showQueuedDrivers('interested')">
                                                    <img
                                                        *ngIf="active_assigned_trip?.interested_drivers_count"
                                                        src="assets/images/thumbs_up.svg"
                                                        alt="Interested Drivers"
                                                    />
                                                    <img
                                                        *ngIf="!active_assigned_trip?.interested_drivers_count"
                                                        style="height: 20px"
                                                        src="assets/images/thumbs_up_dark.png"
                                                        alt="Interested Drivers"
                                                    />
                                                    <span class="text-grey fs-13">({{ active_assigned_trip?.interested_drivers_count }})</span>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>

                                        <div class="py-2">
                                            <div class="row m-1" style="position: relative">
                                                <div class="col-auto ml-1">
                                                    <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                    &nbsp;&nbsp;&nbsp;
                                                    <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                </div>
                                                <div class="col-auto pl-0 my-auto">
                                                    <div class="more-info cursor-pointer" style="margin-left: -3px" (click)="carSelectorMode()">
                                                        <i class="fa fa-exclamation-circle text-white"></i>
                                                    </div>
                                                </div>
                                                <div class="col input-box mt-1 mb-2" style="position: static">
                                                    <ng-container [ngTemplateOutlet]="select_car_type_dropdown"></ng-container>
                                                </div>
                                                <div
                                                    [hidden]="filterRideType === 'assigned'"
                                                    class="col-auto my-auto pr-0"
                                                    (click)="DriverSelectorMode()"
                                                    [class.invisible]="ipadSize && middleBarClosed"
                                                >
                                                    <div class="more-info cursor-pointer">
                                                        <i class="fa fa-heart mr-1"></i>
                                                        <span class="show mr-1 no-select">Select Driver</span>
                                                        <i class="fa fa-chevron-right"></i>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr />
                                        </div>

                                        <!-- More Option -->
                                        <div class="my-3">
                                            <div class="col-auto mt-3">
                                                <ng-container [ngTemplateOutlet]="assign_queue_more_action"></ng-container>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="my-2 py-1" id="health_checkbox2">
                                            <div class="row m-1">
                                                <div class="col-auto ml-2 mt-2" style="width: 42px">
                                                    <div class="custom-checkbox" (click)="health_policy_checkbox = !health_policy_checkbox">
                                                        <i *ngIf="health_policy_checkbox" class="fa fa-check-square" aria-hidden="true"></i>
                                                        <i *ngIf="!health_policy_checkbox" class="fa fa-square-o" aria-hidden="true"></i>
                                                    </div>
                                                </div>
                                                <div class="col input-box disable-select">
                                                    <label class="cursor-default" (click)="health_policy_checkbox = !health_policy_checkbox">
                                                        I confirm to adhere to the latest CDC policies to stay informed, review health and safety
                                                        measures taken about COVID-19.
                                                    </label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div *ngIf="active_trip_tab == 1" class="mt-4">
                                    <!-- formLeft col-lg-12 col-sm-12 col-md-12 col-xs-12 mt-4  -->
                                    <div class="mb-3 mx-2">
                                        <div class="row col-md-12 mt-4 mb-1">
                                            <div class="col-8 pl-0 mr-auto">
                                                <div class="col-auto mb-2" [hidden]="!(is_schedule != '1')">
                                                    <label class="l1">Date/Time of Request Sent</label>
                                                    <br />
                                                    <label class="l2" data-key="payment_date">
                                                        <span *ngIf="payment_date">
                                                            {{ payment_date | date: 'MM/dd/yyyy hh:mm a' }}
                                                            {{ payment_date ? '( ' + payment_timezone + ' )' : '' }}
                                                        </span>
                                                        <span *ngIf="!payment_date">-</span>
                                                    </label>
                                                    <br />
                                                </div>
                                                <div [hidden]="!(is_schedule == '1')" class="col-auto mb-2">
                                                    <label class="l1">Date/Time of Request Accepted</label>
                                                    <br />
                                                    <label class="l2" data-key="schedule_calender_accept_time">
                                                        <span *ngIf="schedule_calender_accept_time">
                                                            {{ schedule_calender_accept_time | date: 'MM/dd/yyyy HH:mm a' }}
                                                            <!-- {{ schedule_calender_accept_time ? '(' + tzName + ')' : '' }} -->
                                                            (
                                                            <span *ngIf="!offset">{{ tzName }}</span>
                                                            {{ offset | timezone }} )
                                                        </span>
                                                        <span *ngIf="!schedule_calender_accept_time">-</span>
                                                    </label>
                                                    <br />
                                                </div>
                                                <div class="col-auto mb-2">
                                                    <label class="l1">Date/Time of Pickup</label>
                                                    <br />
                                                    <label class="l2" data-key="pickup_time_raw">
                                                        <span *ngIf="pickup_time_raw">
                                                            {{ pickup_time_raw | date: 'MM/dd/yyyy hh:mm a' }}
                                                            <!-- {{ pickup_time_raw ? '(' + tzName + ')' : '' }} -->
                                                            (
                                                            <span *ngIf="!offset">{{ tzName }}</span>
                                                            {{ offset | timezone }} )
                                                        </span>
                                                        <span *ngIf="!pickup_time_raw">-</span>
                                                    </label>
                                                    <br />
                                                </div>
                                            </div>
                                            <div class="col-auto">
                                                <img
                                                    [src]="user_image"
                                                    style="border-radius: 50%"
                                                    class="center-block image"
                                                    onerror="src='assets/images/person_placeholder.png'"
                                                />
                                            </div>
                                            <div class="col-auto mb-1">
                                                <label class="l1">Pickup Location</label>
                                                <br />
                                                <label class="l2">{{ pickup_location_address }}</label>
                                            </div>
                                        </div>
                                    </div>

                                    <hr />

                                    <ng-container *ngIf="driverNames">
                                        <div class="my-3 mx-2">
                                            <div *ngFor="let driver of driverNames; let i = index" class="col-auto mb-2">
                                                <label class="l1">{{ driver.request_sent_label }}</label>
                                                <br />
                                                <label class="l2" *ngIf="driver.names" [innerHTML]="driver.names"></label>
                                                <label class="l2" *ngIf="!driver.names">No Drivers Available in Range</label>
                                            </div>
                                            <div *ngIf="driverNames?.length === 0" class="col-auto">
                                                <label class="l2">No Drivers Available in Range</label>
                                            </div>
                                        </div>
                                        <hr />
                                        <div class="col-auto mb-2 my-3 mx-2">
                                            <label class="l1">Accepted By:</label>
                                            <br />
                                            <label class="l2">{{ driver_name }} {{ driver_mobile }}</label>
                                            <br />
                                        </div>
                                    </ng-container>
                                </div>

                                <div *ngIf="active_trip_tab == 2" class="mt-4">
                                    <ng-container *ngIf="trip_activity_loading">
                                        <div class="mx-4">
                                            <app-loading height="60px" borderRadius="6px" type="placeholder" placeholderLength="10"></app-loading>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="!trip_activity_loading">
                                        <div *ngFor="let activity of trip_activity; let index = index">
                                            <hr *ngIf="index > 0" />
                                            <div class="mx-4 my-2">
                                                <div class="text-dark-blue fs-14 fw-600">{{ activity?.status | capitalizeWithSpaces }}</div>
                                                <div class="text-grey">{{ activity.description }}</div>
                                                <div class="text-grey">
                                                    {{ activity.created_at | date: 'MMM dd, yyyy' }} |
                                                    {{ activity.created_at | date: 'hh:mm a' }} (EDT)
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>
                        </div>

                        <div [class.invisible]="ipadSize && middleBarClosed">
                            <hr />
                            <div class="bottom-buttons">
                                <div
                                    class="text-center btn_cs button-wrapper w-100"
                                    [ngClass]="{ 'send-ride-queued-ride': newRideMode }"
                                    *ngIf="newRideMode"
                                >
                                    <!-- bookRideButton pull-right -->
                                    <!-- getDataAndshowPopup() -->
                                    <button
                                        (click)="getDataAndshowPopup(); from = 'ride-request'"
                                        [disabled]="DisableSRR"
                                        class="animate-show btn btn-primary btn_css mr-3"
                                    >
                                        Dispatch Trip &nbsp;
                                        <!-- <i style="font-size: 13px" class="fa fa-chevron-right"></i> -->
                                        <mat-icon class="send">send</mat-icon>
                                    </button>
                                    <button
                                        (click)="
                                            getDataAndshowPopup({ healthCheck: true, loading: true, skip: false, addQueued: true });
                                            from = 'home-queue'
                                        "
                                        [disabled]="DisableSRR"
                                        class="animate-show btn btn-dark button-black btn_css"
                                    >
                                        Queue Trip &nbsp;
                                    </button>
                                </div>

                                <div class="text-center btn_cs button-wrapper row px-3" *ngIf="!newRideMode" [ngClass]="{ 'w-100': !newRideMode }">
                                    <ng-container *ngIf="returnRideMode || (selectedIndex === 2 && !newRideMode)">
                                        <div class="col" *ngIf="selectedIndex !== 2">
                                            <!-- <a (click)="cancelReturn()"
                                            class="button-white pull-right animate-show mt-3">Cancel</a> -->
                                            <button class="animate-show btn btn-dark button-black btn_css" (click)="cancelReturn()">Cancel</button>
                                        </div>
                                        <div
                                            class="col"
                                            [hidden]="
                                                selectedIndex === 2 &&
                                                (mas_trip_status === 'cancelled' ||
                                                    showStatus?.includes('Accepted') ||
                                                    showStatus?.includes('Assigning'))
                                            "
                                        >
                                            <ng-container *ngIf="selectedIndex !== 2">
                                                <button
                                                    (click)="getDataAndshowPopup()"
                                                    [disabled]="DisableSRR"
                                                    class="animate-show btn btn-primary btn_css"
                                                >
                                                    Dispatch Trip &nbsp;
                                                    <mat-icon class="send">send</mat-icon>
                                                </button>
                                            </ng-container>
                                            <ng-container *ngIf="selectedIndex === 2">
                                                <button
                                                    (click)="getDataAndshowPopup({ healthCheck: true, loading: true, skip: true, addQueued: false })"
                                                    [disabled]="DisableSRR"
                                                    class="animate-show btn btn-primary btn_css"
                                                >
                                                    <span *ngIf="rideInFocus?.next_milestone !== 3">Dispatch Trip &nbsp;</span>
                                                    <mat-icon class="send">send</mat-icon>
                                                </button>
                                            </ng-container>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="tripDetailMode">
                                        <div class="col" *ngIf="ride_status == 4">
                                            <button
                                                (click)="returnscheduleRide(rideInFocus); showReturn()"
                                                class="animate-show btn btn-secondary button-black btn_css"
                                            >
                                                Return Ride Request &nbsp;
                                                <mat-icon class="send">send</mat-icon>
                                                <!-- <i style="font-size:13px" class="fa fa-chevron-right"></i> -->
                                            </button>
                                        </div>

                                        <div class="col" *ngIf="ride_status == 4">
                                            <button (click)="sendInvoiceOption(rideInFocus)" class="animate-show btn btn-primary btn_css">
                                                Send Invoice
                                            </button>
                                        </div>

                                        <div class="col" *ngIf="ride_status == 3 && is_schedule == 1 && rideText == 'Yes'">
                                            <button
                                                (click)="returnscheduleRide(rideInFocus); showReturn()"
                                                class="animate-show btn btn-secondary button-black btn_css"
                                            >
                                                Schedule a Return Ride?
                                                <!-- &nbsp;
                                                <i style="font-size:13px" class="fa fa-chevron-right"></i> -->
                                            </button>
                                        </div>

                                        <div class="col" *ngIf="ride_status == 3 && is_schedule != 1 && rideText == 'Yes'">
                                            <button
                                                (click)="returnscheduleRide(rideInFocus); showReturn()"
                                                class="animate-show btn btn-dark button-black btn_css"
                                            >
                                                Request a Return Ride?
                                                <!-- &nbsp;
                                                <i style="font-size:13px" class="fa fa-chevron-right"></i> -->
                                            </button>
                                        </div>

                                        <div class="col" *ngIf="rideText == 'Cancelled' || rideText == 'Missed'">
                                            <button
                                                (click)="reTryRide(rideInFocus); showReturn()"
                                                class="animate-show btn btn-secondary button-black btn_css"
                                            >
                                                Retry Ride Request? &nbsp;
                                                <mat-icon class="send">send</mat-icon>
                                            </button>
                                        </div>

                                        <div class="col" *ngIf="is_schedule != '0' && ride_status != 1 && rideText == 'Yes'">
                                            <button
                                                (click)="rescheduleRide(rideInFocus)"
                                                class="animate-show btn btn-dark button-black btn_css"
                                                data-toggle="modal"
                                                data-target="#reschedule"
                                            >
                                                Reschedule Ride
                                                <!-- &nbsp;
                                                <i style="font-size:13px" class="fa fa-chevron-right"></i> -->
                                            </button>
                                        </div>

                                        <div class="col" *ngIf="rideText == 'Yes'">
                                            <button (click)="cancelRidePopup(session_id)" class="animate-show btn btn-dark button-black btn_css">
                                                Cancel Ride
                                            </button>
                                        </div>

                                        <!-- Milestone status for Ongoing Trip -->
                                        <ng-container
                                            *ngIf="selectedIndex >= 1 && rideInFocus?.is_assigned_trip && rideInFocus?.is_live_milestone_trip"
                                        >
                                            <div class="col">
                                                <button
                                                    class="animate-show btn btn_css text-white"
                                                    [ngClass]="rideInFocus?.ride_milestone_class"
                                                    (click)="update_assigned_milestone(rideInFocus?.mas_trip_id, rideInFocus?.next_milestone)"
                                                >
                                                    {{ rideInFocus?.next_milestone_text }}
                                                </button>
                                            </div>

                                            <div class="col" *ngIf="rideInFocus?.next_milestone === 3">
                                                <button
                                                    class="animate-show btn btn_css text-white btn-danger"
                                                    (click)="update_assigned_milestone(rideInFocus?.mas_trip_id, 6)"
                                                >
                                                    No Show ?
                                                </button>
                                            </div>
                                        </ng-container>
                                        <div class="col-auto" *ngIf="selectedIndex === 2">
                                            <img
                                                data-toggle="dropdown"
                                                class="hover-shadow rounded mt-1"
                                                id="dropdown"
                                                src="/assets/images/more_horiz_rounded.svg"
                                            />
                                            <div class="dropdown-menu cd-menu grey-item left-0 card-shadow text-grey" aria-labelledby="download">
                                                <!-- Queued Trip -->
                                                <ng-container *ngIf="!rideInFocus?.is_assigned_trip" [ngTemplateOutlet]="queuedAction"></ng-container>

                                                <!-- Assigned Trip -->
                                                <ng-container
                                                    *ngIf="rideInFocus?.is_assigned_trip"
                                                    [ngTemplateOutlet]="assignedAction"
                                                ></ng-container>

                                                <!-- <div class="dropdown-item cd-item" stop-propagation>
                                                    ABCD
                                                </div> -->
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>

                                <div class="text-center btn_cs button-wrapper row px-3 w-50 d-md-none" *ngIf="!newRideMode">
                                    <button class="animate-show btn btn-primary btn_css" (click)="newRideModeSelect()">
                                        New &nbsp;
                                        <mat-icon class="send">send</mat-icon>
                                    </button>
                                </div>
                            </div>
                        </div>

                        <!-- </div> -->
                    </div>

                    <div class="position-absolute middle-bar-handle" [class.card-shadow]="middleBarClosed" [class.opened]="!middleBarClosed">
                        <div class="switch-1" *ngIf="mobileSize" [ngStyle]="{ background: newRideMode || middleBarClosed ? 'white' : '#f8fafc' }">
                            <!-- <i *ngIf="!middleBarClosed" class="fa fa-times-circle" aria-hidden="true"></i>
                            <i *ngIf="middleBarClosed" class="fa fa-arrow-circle-right" aria-hidden="true"></i> -->
                            <i [hidden]="!leftSideClosed" (click)="open_leftSideBar()" class="fa fa-history" aria-hidden="true"></i>
                            <i [hidden]="leftSideClosed" (click)="leftSideClosed = true" class="fa fa-clock-o" aria-hidden="true"></i>
                        </div>
                        <div class="switch-2" [ngStyle]="{ background: newRideMode || middleBarClosed ? 'white' : '#f8fafc' }">
                            <!-- <i *ngIf="!middleBarClosed" class="fa fa-times-circle" aria-hidden="true"></i>
                            <i *ngIf="middleBarClosed" class="fa fa-arrow-circle-right" aria-hidden="true"></i> -->
                            <img [hidden]="!middleBarClosed" (click)="open_middleBar()" src="assets/images/arrow_right_dark_blue.svg" alt="" />
                            <img [hidden]="middleBarClosed" (click)="middleBarClosed = true" src="assets/images/cancel_dark_blue.svg" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <!--Left Expandable Bar Ends Here-->

            <!--Right Expandable Starts Ends Here-->

            <!-- col-lg-6 col-xl-7 -->
            <!-- col -->
            <div class="rightSideBar card-shadow" [ngClass]="{ 'col-6': !ipadSize, col: ipadSize }" id="mapCustom" [hidden]="!MapShow">
                <div class="page-nopad map-control row pt-1">
                    <div class="col-sm-6" style="padding: 5px">
                        <div class="row map-input-container card-shadow">
                            <div class="col-auto left-icon p-1 pl-3">
                                <img src="assets/images/drop_location.svg" alt="location_icon" />
                                &nbsp;
                                <img src="assets/images/divider_black.svg" alt="divider_black" />
                            </div>
                            <div class="col p-1">
                                <input
                                    type="search"
                                    style="padding-top: 5px; padding-bottom: 5px"
                                    id="searchLocation"
                                    name="search"
                                    class="borderless-input autocomplete"
                                    placeholder="Search Location"
                                    [(ngModel)]="searchLocation"
                                />
                            </div>
                            <div class="col-auto right-icon pt-2">
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                    </div>
                    <div class="col-sm-6" style="padding: 5px">
                        <div class="row map-input-container card-shadow">
                            <div class="col-auto left-icon p-1 pl-3">
                                <img src="assets/images/prefer_fav.svg" alt="prefer_fav_icon" />
                                &nbsp;
                                <img src="assets/images/divider_black.svg" alt="divider_black" />
                            </div>
                            <div class="col p-1">
                                <input
                                    type="search"
                                    id="searchDriver"
                                    style="padding-top: 5px; padding-bottom: 5px"
                                    name="search"
                                    class="borderless-input"
                                    placeholder="Search Driver"
                                    [(ngModel)]="searchDriver"
                                    [ngModelOptions]="{ debounce: 400 }"
                                    (change)="searchD()"
                                />
                            </div>
                            <div class="col-auto right-icon pt-2">
                                <i class="fa fa-search"></i>
                            </div>
                        </div>
                    </div>
                </div>

                <div style="border-radius: 5px" id="map" class="map-b"></div>

                <div class="map-bottom-container p-2 px-4">
                    <div class="row my-1">
                        <div class="col-auto mr-auto">
                            <div class="control-box">
                                <span class="highlight align-middle" (click)="zoomOut()">
                                    <i class="fa fa-minus" aria-hidden="true"></i>
                                </span>
                                <span class="align-middle">|</span>
                                <span class="highlight align-middle" (click)="zoomIn()">
                                    <i class="fa fa-plus" aria-hidden="true"></i>
                                </span>
                            </div>
                        </div>
                        <div class="col-auto px-1">
                            <div class="control-box" (click)="enableDrivers()">
                                <span [class.span-active]="driverEnable">
                                    SHOW
                                    <span *ngIf="driverEnable">DRIVERS</span>
                                </span>
                                <span [class.span-inactive]="!driverEnable">
                                    HIDE
                                    <span *ngIf="!driverEnable">DRIVERS</span>
                                </span>
                            </div>
                        </div>
                        <div class="col-auto px-1">
                            <div class="control-box">
                                <span (click)="fullScreen()">
                                    <img style="margin-bottom: 1px" src="assets/images/scan_tag.svg" alt="" />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!--Map Div Ends Here-->

            <!-- col-lg-8 col-xl-7 -->
            <div
                class="right-side-bar animate-show carshow card-shadow p-0 pb-4 car-container position-relative"
                (scroll)="scrollButtonEnable($event)"
                [ngClass]="{ 'col-6': !ipadSize, col: ipadSize }"
                *ngIf="!MapShow"
                #carShow
            >
                <div
                    class="tab-panel"
                    style="margin: 0 auto; position: -webkit-sticky; position: sticky; top: 0px; background-color: white; z-index: 2"
                >
                    <div class="row">
                        <div class="ml-4 px-0 col-auto cursor-pointer" (click)="showDirection(1)">Ride Direction</div>
                        <div
                            class="px-0 col-auto cursor-pointer"
                            (click)="!force ? showCar() : donothing()"
                            [class.active-tab]="CarShow"
                            [ngClass]="{ 'active-tab': CarShow, 'mr-auto': !(selectedIndex === 2 && filterRideType === 'queue-trip') }"
                        >
                            Ride Type
                        </div>

                        <div class="px-0 col-auto cursor-pointer" [class.active-tab]="UserShow" (click)="showUser()">QudosFave Drivers</div>
                        <div
                            class="px-0 col-auto cursor-pointer mr-auto"
                            *ngIf="selectedIndex === 2 && filterRideType === 'queue-trip'"
                            [class.active-tab]="QueuedDriverShow"
                            (click)="showQueuedDrivers()"
                        >
                            Queued Drivers
                        </div>

                        <div class="col-auto px-2">
                            <img src="assets/images/divider_black.svg" alt="divider" />
                            &nbsp;&nbsp;&nbsp;

                            <ng-container *ngIf="CarShow || QueuedDriverShow">
                                <span style="color: #1f8dc6; cursor: pointer" (click)="showDirection(1)">
                                    <i class="fa fa-angle-left"></i>
                                    &nbsp; Back
                                </span>
                            </ng-container>

                            <ng-container *ngIf="UserShow">
                                <span style="color: #1f8dc6; cursor: pointer" (click)="showCar()">
                                    <i class="fa fa-angle-left"></i>
                                    &nbsp; Back
                                </span>
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div *ngIf="CarShow" class="pt-2 ride-type">
                    <div *ngFor="let car of cars_option; let i = index">
                        <div class="row card-shadow ride-row m-4 p-0">
                            <div class="col-3 p-0 my-auto text-center">
                                <img class="img-fluid m-0 ride-img" [src]="car.car_image" alt="car_image" />
                            </div>
                            <div class="col">
                                <div class="my-3">
                                    <div class="car-title">
                                        <!-- {{car.carClass}}: -->
                                        {{ car.car_name }}
                                    </div>
                                    <div class="ride-feature mt-2">
                                        Free 30 mins wait time for airport pickups, 5 mins for all others
                                        <br />
                                        <br />
                                        Includes meet and greet
                                        <br />
                                        <br />
                                        Free Cancellation up to 6 hour before pickup
                                    </div>
                                </div>
                            </div>
                            <div class="col-3 my-auto mr-1 text-center">
                                <div class="row justify-content-md-center my-2 ride-cost">${{ car.value_regular | number }}</div>

                                <div class="row justify-content-md-center my-2">
                                    <button
                                        class="ride-select-button btn btn-primary"
                                        (click)="selectCarClass(car.car_type, car.value_regular, car.value_scheduled)"
                                        *ngIf="booking.car_type == car.car_type"
                                    >
                                        <span class="selected">
                                            <img style="padding: 6px" src="assets/images/correct_icon.svg" />
                                        </span>
                                        <span *ngIf="windowSize.width >= 805 || !windowSize.width">&nbsp; Selected</span>
                                    </button>

                                    <button
                                        class="ride-select-button btn btn-primary"
                                        (click)="selectCarClass(car.car_type, car.value_regular, car.value_scheduled)"
                                        *ngIf="booking.car_type != car.car_type"
                                    >
                                        Select
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="UserShow" class="pt-2" style="position: relative">
                    <div
                        class="tab-panel"
                        style="margin: 0 auto; position: -webkit-sticky; position: sticky; top: 0px; background-color: white; z-index: 3"
                    >
                        <div class="row">
                            <div class="ml-4 px-0 col-auto cursor-pointer" (click)="switchMode('m')" [class.active-tab]="myQudosMode == 1">
                                <!-- My QudosFave™ -->
                                My QudosFave
                            </div>
                            <div class="px-0 col-auto cursor-pointer" (click)="switchMode('u')" [class.active-tab]="UserQudosMode == 1">
                                <!-- User QudosFave™ -->
                                Rider's QudosFave
                            </div>

                            <div class="px-0 col-auto cursor-pointer mr-auto" [class.active-tab]="AllQudosMode == 1" (click)="switchMode('a')">
                                Explore
                            </div>

                            <div class="col-auto px-0">
                                <div class="d-flex">
                                    <div class="mr-3" style="margin-top: -6px">
                                        <img src="assets/images/divider_black.svg" alt="divider" />
                                    </div>
                                    <div>
                                        <i class="fa fa-search"></i>
                                        &nbsp;&nbsp;
                                    </div>
                                    <div>
                                        <input
                                            type="search"
                                            placeholder="Type to Search"
                                            [ngModelOptions]="{ debounce: 200 }"
                                            class="borderless-input grey-placeholder"
                                            [(ngModel)]="searchQudosFave"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- myQudosMode -->
                    <div class="card-outline-secondary my-4" [hidden]="!myQudosMode">
                        <div class="row driverarea" *ngIf="!driver_data_loading">
                            <!--  New  design of card -->
                            <ng-container *ngFor="let carToSelect of cars_option; let i = index">
                                <div
                                    class="p-3 m-1 card_box d-flex"
                                    *ngIf="booking.car_type == carToSelect.car_type"
                                    [ngClass]="{ active: booking.select_car_type == carToSelect.car_type }"
                                >
                                    <div class="left_side">
                                        <div class="img_section">
                                            <img
                                                [src]="carToSelect.car_image"
                                                onerror="src='assets/images/person_placeholder.png'"
                                                class="img_fluid img_contain"
                                            />
                                        </div>
                                    </div>
                                    <div class="right_side pl-3">
                                        <span class="title_card">
                                            {{ carToSelect.car_name | slice: 0:12 }}
                                            <span *ngIf="carToSelect?.length > 12">...</span>
                                        </span>
                                        <div class="subtitle_card">
                                            <p class="mb-0">{{ carToSelect.similar }}</p>
                                        </div>
                                        <button
                                            type="button"
                                            (click)="slect(carToSelect.car_type, 1)"
                                            *ngIf="booking.select_car_type == carToSelect.car_type"
                                            class="btn btn-primary cs_btn focus_out text-white"
                                        >
                                            <i class="fa fa-check-circle mr-1 text-dark" aria-hidden="true"></i>
                                            Selected
                                        </button>
                                        <button
                                            type="button"
                                            (click)="slect(carToSelect.car_type, 1)"
                                            *ngIf="booking.select_car_type != carToSelect.car_type"
                                            class="btn btn-primary cs_btn focus_out text-white"
                                        >
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                            <!--  New  design of card end -->

                            <div class="col-md-12 text-center" [hidden]="corporateFavDriver.length != 0">
                                <h3 style="font-family: poppins; font-size: 1.4rem; font-weight: 400; color: #828282">No Driver Found</h3>
                            </div>

                            <div
                                class="p-3 m-1 card_box d-flex"
                                *ngFor="let driver of corporateFavDriver | StringFilter: 'driver_name':searchQudosFave.trim(); let i = index"
                                [hidden]="corporateFavDriver.length < 0"
                                [ngClass]="{
                                    unverified: driver.all_docs_verified != 1,
                                    active: booking.driver_id == driver.driver_id
                                }"
                            >
                                <div class="left_side">
                                    <div class="img_section">
                                        <img [src]="driver.driver_image" onerror="src='assets/images/person_placeholder.png'" class="img_fluid" />
                                    </div>
                                </div>
                                <div class="right_side pl-3">
                                    <span class="title_card">
                                        {{ driver?.driver_name | slice: 0:12 }}
                                        <span *ngIf="driver?.driver_name?.length > 12">...</span>
                                    </span>
                                    <div class="subtitle_card">
                                        <p class="mb-0">
                                            <!-- {{driver.car_make}} {{driver.car_model}} <br> {{driver.car_year}} {{driver.car_color}} -->
                                            {{ driver?.car_name }}, {{ driver?.car_make }}
                                            <br />
                                            {{ driver?.car_model }},
                                            {{ driver?.car_no }}
                                        </p>
                                    </div>
                                    <button
                                        type="button"
                                        *ngIf="booking.driver_id == driver.driver_id"
                                        class="btn btn-primary cs_btn focus_out text-white"
                                    >
                                        <i class="fa fa-check-circle mr-1 text-dark" aria-hidden="true"></i>
                                        Selected
                                    </button>
                                    <button
                                        type="button"
                                        (click)="slect(driver?.driver_id); selected_driver = driver"
                                        *ngIf="booking.driver_id != driver.driver_id"
                                        class="btn btn-primary cs_btn focus_out text-white"
                                    >
                                        Select
                                    </button>
                                    <button
                                        type="button"
                                        (click)="assign_mas_driver(driver)"
                                        *ngIf="filterRideType === 'alltrips'"
                                        class="btn btn-secondary btn-dark-blue btn cs_btn focus_out text-white pe-none"
                                        style="bottom: 50px"
                                    >
                                        Assign
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div class="d-inline text-center" *ngIf="driver_data_loading">
                            <app-loading type="placeholder" show="grid" [placeholderLength]="8"></app-loading>
                        </div>
                    </div>

                    <!-- UserQudosMode -->
                    <!-- col-lg-12 col-xl-11  -->
                    <div class="card-outline-secondary my-4" style="margin: 0 auto" [hidden]="!UserQudosMode">
                        <div class="row driverarea">
                            <ng-container *ngFor="let carToSelect of cars_option; let i = index">
                                <div
                                    class="card_box d-flex p-3 m-1"
                                    *ngIf="booking.car_type == carToSelect.car_type"
                                    [class.active]="booking.select_car_type == carToSelect.car_type"
                                >
                                    <div class="left_side">
                                        <div class="img_section">
                                            <img
                                                [src]="carToSelect.car_image"
                                                onerror="src='assets/images/person_placeholder.png'"
                                                class="img_fluid img_contain"
                                            />
                                        </div>
                                    </div>
                                    <div class="right_side pl-3">
                                        <span class="title_card">
                                            {{ carToSelect.car_name | slice: 0:12 }}
                                            <span *ngIf="carToSelect.car_name.length > 12">...</span>
                                        </span>
                                        <div class="subtitle_card">
                                            <p class="mb-0">{{ carToSelect.similar }}</p>
                                        </div>
                                        <button
                                            type="button"
                                            [hidden]="booking.select_car_type != carToSelect.car_type"
                                            (click)="slect(carToSelect.car_type, 1)"
                                            class="btn btn-primary cs_btn focus_out text-white"
                                        >
                                            <i class="fa fa-check-circle mr-1 text-dark" aria-hidden="true"></i>
                                            Selected
                                        </button>
                                        <button
                                            type="button"
                                            [hidden]="booking.select_car_type == carToSelect.car_type"
                                            (click)="slect(carToSelect.car_type, 1)"
                                            class="btn btn-primary cs_btn focus_out text-white"
                                        >
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </ng-container>

                            <div class="col-md-12 text-center" [hidden]="userFavDriver.length != 0">
                                <h3 style="font-family: poppins; font-size: 1.4rem; font-weight: 400; color: #828282">No Driver Found</h3>
                            </div>

                            <div
                                class="card_box d-flex p-3 m-1"
                                [hidden]="userFavDriver.length <= 0"
                                *ngFor="let userDriver of userFavDriver | StringFilter: 'driver_name':searchQudosFave.trim(); let i = index"
                                [ngClass]="{ unverified: userDriver.all_docs_verified == 0, active: booking.driver_id == userDriver.driver_id }"
                            >
                                <div class="left_side">
                                    <div class="img_section">
                                        <img
                                            src="{{ userDriver.driver_image }}"
                                            onerror="src='assets/images/person_placeholder.png'"
                                            class="img_fluid"
                                        />
                                    </div>
                                </div>
                                <div class="right_side pl-3">
                                    <span class="title_card">
                                        {{ userDriver.driver_name | slice: 0:12 }}
                                        <span *ngIf="userDriver?.driver_name?.length > 12">...</span>
                                    </span>
                                    <div class="subtitle_card">
                                        <p class="mb-0">
                                            <!-- {{userDriver.car_make}} {{userDriver.car_model}} <br> {{userDriver.car_year}} {{userDriver.car_color}} -->
                                            {{ userDriver?.car_name }}, {{ userDriver?.car_make }}
                                            <br />
                                            {{ userDriver?.car_model }}, {{ userDriver?.car_no }}
                                        </p>
                                    </div>

                                    <div [hidden]="userDriver.all_docs_verified !== 1">
                                        <button
                                            class="cs_btn focus_out text-white"
                                            [hidden]="booking.driver_id !== userDriver.driver_id"
                                            (click)="slect(userDriver.driver_id)"
                                        >
                                            <i class="fa fa-check-circle mr-1 text-dark" aria-hidden="true"></i>
                                            Selected
                                        </button>

                                        <button
                                            class="cs_btn focus_out text-white"
                                            [hidden]="booking.driver_id == userDriver.driver_id"
                                            (click)="slect(userDriver.driver_id)"
                                        >
                                            Select
                                        </button>
                                    </div>

                                    <div [hidden]="userDriver.all_docs_verified !== 0">
                                        <button class="cs_btn focus_out text-white" [hidden]="booking.driver_id == userDriver.driver_id">
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <!-- AllQudosMode -->
                    <!-- col-lg-12 col-xl-11 -->
                    <div class="card-outline-secondary my-4" style="margin: 0 auto" [hidden]="!AllQudosMode">
                        <div class="row driverarea">
                            <!--  New  design of card -->
                            <ng-container *ngFor="let carToSelect of cars_option; let i = index">
                                <div
                                    class="card_box d-flex p-3 m-1"
                                    *ngIf="booking.car_type == carToSelect.car_type"
                                    [class.active]="booking.select_car_type == carToSelect.car_type"
                                >
                                    <div class="left_side">
                                        <div class="img_section">
                                            <img
                                                [src]="carToSelect.car_image"
                                                onerror="src='assets/images/person_placeholder.png'"
                                                class="img_fluid img_contain"
                                            />
                                        </div>
                                    </div>
                                    <div class="right_side pl-3">
                                        <span class="title_card">
                                            {{ carToSelect.car_name | slice: 0:12 }}
                                            <span *ngIf="carToSelect.car_name?.length > 12">...</span>
                                        </span>
                                        <div class="subtitle_card">
                                            <p class="mb-0">{{ carToSelect.similar }}</p>
                                        </div>
                                        <button
                                            type="button"
                                            [hidden]="booking.select_car_type != carToSelect.car_type"
                                            class="cs_btn focus_out text-white"
                                        >
                                            <i class="fa fa-check-circle mr-1 text-dark" aria-hidden="true"></i>
                                            Selected
                                        </button>
                                        <button
                                            type="button"
                                            [hidden]="booking.select_car_type == carToSelect.car_type"
                                            (click)="slect(carToSelect.car_type, 1)"
                                            class="cs_btn focus_out text-white"
                                        >
                                            Select
                                        </button>
                                    </div>
                                </div>
                            </ng-container>
                            <!--  New  design of card end -->

                            <div class="col-md-12 text-center" [hidden]="all_drivers.length !== 0">
                                <h3 style="font-family: poppins; color: #828282; font-size: 1.4rem; font-weight: 400">No Driver Found</h3>
                            </div>

                            <!--  New  design of card -->
                            <div
                                class="card_box d-flex p-3 m-1"
                                [hidden]="!(all_drivers.length > 0)"
                                *ngFor="let driver of all_drivers | StringFilter: 'driver_name':searchQudosFave.trim()"
                                [ngClass]="{ unverified: driver.all_docs_verified == 0, active: booking.driver_id == driver.driver_id }"
                            >
                                <div class="left_side">
                                    <div class="img_section">
                                        <img [src]="driver.driver_image" onerror="src='assets/images/person_placeholder.png'" class="img_fluid" />
                                    </div>
                                </div>
                                <div class="right_side pl-3">
                                    <span class="title_card" *ngIf="driver.driver_name.length > 10">{{ driver.driver_name | slice: 0:12 }}...</span>
                                    <span class="title_card" *ngIf="driver.driver_name.length <= 10">
                                        {{ driver.driver_name }}
                                    </span>
                                    <div class="subtitle_card">
                                        <p class="mb-0">
                                            <!-- {{driver.car_make}} {{driver.car_model}} <br> {{driver.car_year}} {{driver.car_color}} -->
                                            {{ driver?.car_name }}, {{ driver?.car_make }}
                                            <br />
                                            {{ driver.car_model }},
                                            {{ driver?.car_no }}

                                            <br />
                                            Active: {{ driver.is_available }}, Verified: {{ driver.all_docs_verified }}
                                        </p>
                                    </div>

                                    <div [hidden]="driver.all_docs_verified != 1">
                                        <button
                                            class="cs_btn focus_out text-white"
                                            [hidden]="booking.driver_id != driver.driver_id"
                                            (click)="slect(driver.driver_id)"
                                        >
                                            <i class="fa fa-check-circle mr-1 text-dark" aria-hidden="true"></i>
                                            Selected
                                        </button>

                                        <button
                                            class="cs_btn focus_out text-white"
                                            [hidden]="booking.driver_id == driver.driver_id"
                                            (click)="slect(driver.driver_id); addToQudosFave(driver.driver_id)"
                                        >
                                            Add & Select
                                        </button>
                                    </div>
                                    <div [hidden]="driver.all_docs_verified != 0">
                                        <button class="cs_btn focus_out text-white" [hidden]="booking.driver_id == driver.driver_id">
                                            Add & Select
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <!--  New  design of card end -->
                        </div>
                    </div>
                </div>

                <div *ngIf="QueuedDriverShow" class="pt-2" style="position: relative">
                    <div
                        class="tab-panel"
                        style="margin: 0 auto; position: -webkit-sticky; position: sticky; top: 0px; background-color: white; z-index: 3"
                    >
                        <div class="row">
                            <div
                                class="ml-4 px-0 col-auto cursor-pointer"
                                (click)="queued_driver_mode = 'confirmed'"
                                [class.active-tab]="queued_driver_mode === 'confirmed'"
                            >
                                Confirmed Drivers
                            </div>
                            <div
                                class="px-0 col-auto cursor-pointer mr-auto"
                                (click)="queued_driver_mode = 'interested'"
                                [class.active-tab]="queued_driver_mode === 'interested'"
                            >
                                Interested Drivers
                            </div>

                            <div class="col-auto px-0">
                                <div class="d-flex">
                                    <div class="mr-3" style="margin-top: -6px">
                                        <img src="assets/images/divider_black.svg" alt="divider" />
                                    </div>
                                    <div>
                                        <i class="fa fa-search"></i>
                                        &nbsp;&nbsp;
                                    </div>
                                    <div>
                                        <input
                                            type="search"
                                            placeholder="Type to Search"
                                            [ngModelOptions]="{ debounce: 200 }"
                                            class="borderless-input grey-placeholder"
                                            [(ngModel)]="searchQudosFave"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div class="card-outline-secondary my-4" style="margin: 0 auto" *ngIf="queued_driver_mode === 'confirmed'">
                        <div class="row driverarea" *ngIf="confirmed_driver_list.length">
                            <div
                                class="card_box d-flex p-3 m-1"
                                *ngFor="let userDriver of confirmed_driver_list | StringFilter: 'driver_name':searchQudosFave.trim(); let i = index"
                                [ngClass]="{ unverified: userDriver.all_docs_verified == 0, active: booking.driver_id == userDriver.driver_id }"
                            >
                                <div class="left_side">
                                    <div class="img_section">
                                        <img [src]="userDriver.driver_image" onerror="src='assets/images/person_placeholder.png'" class="img_fluid" />
                                    </div>
                                </div>
                                <div class="right_side pl-3">
                                    <span class="title_card">
                                        {{ userDriver.driver_name | slice: 0:12 }}
                                        <span *ngIf="userDriver?.driver_name?.length > 12">...</span>
                                    </span>
                                    <div class="subtitle_card">
                                        <p class="mb-0">
                                            <!-- {{userDriver.car_make}} {{userDriver.car_model}} <br> {{userDriver.car_year}} {{userDriver.car_color}} -->
                                            {{ userDriver?.car_name || '--' }}, {{ userDriver?.car_make || '--' }}
                                            <br />
                                            {{ userDriver?.car_model || '--' }}, {{ userDriver?.car_no || '--' }}
                                        </p>
                                    </div>

                                    <div>
                                        <button
                                            type="button"
                                            (click)="assign_mas_driver(userDriver)"
                                            class="btn btn-primary cs_btn focus_out text-white"
                                        >
                                            Assign
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-secondary btn-dark-blue btn cs_btn focus_out text-white pe-none"
                                            style="bottom: 50px"
                                            (click)="
                                                select_this_driver(userDriver);
                                                getDataAndshowPopup({ healthCheck: true, loading: true, skip: true, addQueued: false })
                                            "
                                        >
                                            Dispatch
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center bg-light p-4 m-3 br-6" *ngIf="confirmed_driver_list?.length === 0">
                            <div style="font-family: poppins" class="text-grey fs-16">No Driver Found</div>
                        </div>
                    </div>

                    <div class="card-outline-secondary my-4" style="margin: 0 auto" *ngIf="queued_driver_mode === 'interested'">
                        <div class="row driverarea" *ngIf="interested_driver_list?.length">
                            <div
                                class="card_box d-flex p-3 m-1"
                                [hidden]="interested_driver_list.length <= 0"
                                *ngFor="let userDriver of interested_driver_list | StringFilter: 'driver_name':searchQudosFave.trim(); let i = index"
                                [ngClass]="{ unverified: userDriver.all_docs_verified == 0, active: booking.driver_id == userDriver.driver_id }"
                            >
                                <div class="left_side">
                                    <div class="img_section">
                                        <img [src]="userDriver.driver_image" onerror="src='assets/images/person_placeholder.png'" class="img_fluid" />
                                    </div>
                                </div>
                                <div class="right_side pl-3">
                                    <span class="title_card">
                                        {{ userDriver.driver_name | slice: 0:12 }}
                                        <span *ngIf="userDriver?.driver_name?.length > 12">...</span>
                                    </span>
                                    <div class="subtitle_card">
                                        <p class="mb-0">
                                            <!-- {{userDriver.car_make}} {{userDriver.car_model}} <br> {{userDriver.car_year}} {{userDriver.car_color}} -->
                                            {{ userDriver?.car_name || '--' }}, {{ userDriver?.car_make || '--' }}
                                            <br />
                                            {{ userDriver?.car_model || '--' }}, {{ userDriver?.car_no || '--' }}
                                        </p>
                                    </div>

                                    <div>
                                        <button
                                            type="button"
                                            (click)="assign_mas_driver(userDriver)"
                                            class="btn btn-primary cs_btn focus_out text-white"
                                        >
                                            Assign
                                        </button>
                                        <button
                                            type="button"
                                            class="btn btn-secondary btn-dark-blue btn cs_btn focus_out text-white pe-none"
                                            style="bottom: 50px"
                                            (click)="
                                                select_this_driver(userDriver);
                                                getDataAndshowPopup({ healthCheck: true, loading: true, skip: true, addQueued: false })
                                            "
                                        >
                                            Dispatch
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="text-center bg-light p-4 m-3 br-6" *ngIf="interested_driver_list?.length === 0">
                            <div style="font-family: poppins" class="text-grey fs-16">No Driver Found</div>
                        </div>
                    </div>
                </div>

                <div id="carScrollTop" class="position-fixed cursor-pointer" [hidden]="!carScrollEnable">
                    <span class="card-shadow py-1 px-2" (click)="scrollTopCarShowPanel()">
                        <!-- <i class="fa fa-arrow-up" aria-hidden="true"></i> -->
                        <img src="assets/images/arrow_up.svg" alt="" />
                    </span>
                </div>
            </div>
        </div>
    </div>

    <!-- Live Map Modal --->

    <div
        class="modal fade"
        id="reschedule"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" style="width: 700px !important" role="document">
            <div class="modal-content">
                <div class="mx-5 mt-5 mb-2 title">Reschedule The Ride</div>
                <!-- <div class="modal-body schedule-screen ">
                </div> -->
                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            <!-- <div id="exTab3" class="container"> -->
                            <!-- <div class="tab-pane container active " id="1b"> -->
                            <div [hidden]="!doc.successMsg" class="alert alert-success text-center">
                                {{ doc.successMsg }}
                            </div>
                            <div [hidden]="!doc.errorMsg" class="alert alert-danger text-center">
                                {{ doc.errorMsg }}
                            </div>
                            <div class="row m-auto">
                                <!-- <div class="col-sm-6 px-2"> -->
                                <div class="col-md-6 px-2 mb-0">
                                    <div class="d-flex justify-content-between schedule-date" (click)="rescheduleDate.open()">
                                        <div class="text" *ngIf="bookingDate">{{ bookingDate | date: 'y-MM-dd (EEEE)' }}</div>
                                        <div class="cal-icon no-icon">
                                            <img src="assets/images/calender_icon.svg" alt="" />
                                        </div>
                                    </div>
                                    <input
                                        [(ngModel)]="bookingDate"
                                        readonly
                                        [matDatepicker]="rescheduleDate"
                                        class="invisible mat-date-input"
                                        [min]="minDate"
                                        [max]="maxDate_6_Day"
                                        (dateChange)="onDateChange($event.target.value)"
                                    />
                                    <mat-datepicker #rescheduleDate></mat-datepicker>
                                </div>

                                <div class="col-md-3 form-group px-2">
                                    <select
                                        name="time"
                                        [(ngModel)]="rebooking.time_hour"
                                        class="form-control select-highlight text-dark-blue"
                                        id="time"
                                        style="height: 50px"
                                    >
                                        <option *ngFor="let option of hoursList" [value]="option.value">{{ option.title }}</option>
                                    </select>
                                </div>
                                <div class="col-sm-3 form-group px-2">
                                    <!-- <label>Minute</label> -->
                                    <select
                                        name="time"
                                        [(ngModel)]="rebooking.time_minutes"
                                        class="form-control select-highlight text-dark-blue"
                                        id="time-min"
                                        style="height: 50px"
                                    >
                                        <option [value]="m" *ngFor="let m of minutesList">
                                            <span *ngIf="m < 10">0{{ m }} mins</span>
                                            <span *ngIf="m >= 10">{{ m }} mins</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                            <!-- </div> -->
                            <!-- </div> -->
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button
                            class="btn btn-secondary border-0 btn-left m-2 px-3 py-1 border-0"
                            data-dismiss="modal"
                            style="background-color: #707683 !important"
                        >
                            Cancel
                        </button>

                        <button
                            class="btn btn-secondary border-0 btn-right m-2 px-3 py-1 border-0"
                            (click)="rescheduleNow()"
                            [disabled]="DisableOnReschd"
                        >
                            Reschedule
                        </button>

                        <!-- <button type="submit" (click)="rescheduleNow()" class="btn btn-primary btn-md" [disabled]="DisableOnReschd" [disabled]="!rebooking.date||!rebooking.time_hour||!booking.time_minutes" style="border:1px solid #999;">
                            Reschedule Ride
                        </button> -->
                    </div>
                </div>
            </div>
        </div>
    </div>
    <!--OLD MODALS ABOVE for Live page-->

    <div
        class="modal fade"
        id="request_ride_later"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        style="z-index: 1230 !important"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document" style="width: 700px !important">
            <div class="modal-content booking-model-component card-shadow">
                <div class="mx-5 mt-5 mb-2 title">Choose Date and Time</div>

                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            <div class="panel-body">
                                <div class="row m-auto" [hidden]="!stepTimeSelector && 0">
                                    <div class="col-md-6 px-2 mb-0">
                                        <div class="d-flex justify-content-between schedule-date" (click)="scheduleDate.open()">
                                            <div class="text">{{ bookingDate | date: 'y-MM-dd (EEEE)' }}</div>
                                            <div class="cal-icon no-select">
                                                <img src="assets/images/calender_icon.svg" alt="" />
                                            </div>
                                        </div>
                                        <input
                                            [(ngModel)]="bookingDate"
                                            readonly
                                            [matDatepicker]="scheduleDate"
                                            class="invisible mat-date-input"
                                            [min]="minDate"
                                            [max]="maxDate_6_Day"
                                            (dateChange)="onDateChange($event.target.value)"
                                        />
                                        <mat-datepicker #scheduleDate></mat-datepicker>
                                    </div>
                                    <div class="col-md-3 px-2 form-group">
                                        <select
                                            name="time"
                                            [(ngModel)]="booking.time_hour"
                                            class="form-control select-field-spacing select-highlight text-dark-blue"
                                            id="time"
                                            style="height: 50px"
                                        >
                                            <option *ngFor="let option of hoursList" [value]="option.value">{{ option.title }}</option>
                                        </select>
                                    </div>
                                    <div class="col-md-3 px-2 form-group">
                                        <!-- <label>Minute</label> -->
                                        <select
                                            name="time"
                                            [(ngModel)]="booking.time_minutes"
                                            class="form-control select-field-spacing select-highlight text-dark-blue"
                                            id="time-min"
                                            style="height: 50px"
                                        >
                                            <option [value]="m" *ngFor="let m of minutesList">
                                                <span *ngIf="m < 10">0{{ m }} mins</span>
                                                <span *ngIf="m >= 10">{{ m }} mins</span>
                                            </option>
                                        </select>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button
                            class="btn btn-secondary border-0 btn-left m-2 px-3 py-1"
                            data-dismiss="modal"
                            style="background-color: #707683 !important"
                        >
                            Cancel
                        </button>

                        <button class="btn btn-secondary border-0 btn-right m-2 px-3 py-1" (click)="timeSelected()" [disabled]="DisableOnload">
                            Book the Ride
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade p-2"
        id="add_to_account"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered" role="document">
            <div class="modal-content selection-dialog">
                <div class="modal-body">
                    <div class="text-right">
                        <button type="button" aria-label="Close" data-dismiss="modal" (click)="resetCorporateAddOnAndRideDiscount()">
                            <img src="/assets/images/modalClose.svg" alt="closeIcon" />
                        </button>
                    </div>
                    <!-- [hidden]="!(booking.manual_destination_address != '')" -->
                    <div class="panel-body">
                        <div class="row booking-model-component px-1">
                            <div class="col-md-6">
                                <div class="rb-mid-text mt-3 mb-2">
                                    Fare Estimates
                                    <span *ngIf="mas_active_trip" class="mas_unassigned">MAS</span>
                                    <span
                                        class="rb-blue-small-text fs-13 cursor-pointer"
                                        [class.disabled-text]="fareEstimatePriceLoading"
                                        (click)="open_recalculate_popup(active_fare_breakdown_flag)"
                                    >
                                        Recalculate
                                    </span>
                                    <ng-container *ngIf="fare_factor != 1">
                                        <span class="text-spacegrey mx-2">|</span>
                                        <span class="rb-blue-small-text fs-13 cursor-pointer" (click)="fare_factor_display = 1; recalculate()">
                                            Reset
                                        </span>
                                    </ng-container>
                                </div>

                                <div class="d-flex justify-content-between">
                                    <div>
                                        <div class="mt-2 m-0">
                                            <!-- <div class="rb-grey-mid-text d-flex fw-600">
                                                <div
                                                    class="cursor-pointer"
                                                    (click)="active_fare_breakdown_flag = 'now'"
                                                    [class.text-blue]="active_fare_breakdown_flag === 'now'"
                                                >
                                                    Request Now
                                                </div>
                                                <div class="mx-2">|</div>
                                                <div
                                                    class="cursor-pointer"
                                                    (click)="active_fare_breakdown_flag = 'later'"
                                                    [class.text-blue]="active_fare_breakdown_flag === 'later'"
                                                >
                                                    Book Later
                                                </div>
                                            </div> -->
                                            <div class="d-flex mt-2">
                                                <app-select-car-type-popover
                                                    [carTypes]="carDriverOptions"
                                                    [selectedCarType]="booking.select_car_type"
                                                    (onCarTypeChange)="selectCarType($event, true)"
                                                >
                                                    <span class="otherOption">
                                                        <span>Other Options</span>
                                                        <span class="ml-2"><i class="fa fa-sort-desc" aria-hidden="true"></i></span>
                                                    </span>
                                                </app-select-car-type-popover>
                                            </div>
                                            <div *ngIf="!fareEstimatePriceLoading">
                                                <ng-container *ngIf="active_fare_breakdown_flag === 'now'">
                                                    <span class="rb-large-text">${{ booking.estimated_fare | number: '1.2-2' }}</span>
                                                    <span *ngIf="promo_applied" class="original-price ml-1">
                                                        ${{ booking.estimated_fare_old | number: '1.2-2' }}
                                                    </span>
                                                </ng-container>
                                                <ng-container *ngIf="active_fare_breakdown_flag === 'later'">
                                                    <span class="rb-large-text">${{ booking.estimated_fare_later | number: '1.2-2' }}</span>
                                                    <span *ngIf="promo_applied" class="original-price ml-1">
                                                        ${{ booking.estimated_fare_later_old | number: '1.2-2' }}
                                                    </span>
                                                </ng-container>
                                            </div>
                                            <div *ngIf="fareEstimatePriceLoading">
                                                <app-loading height="13px" borderRadius="2px" type="placeholder"></app-loading>
                                            </div>
                                            <div
                                                class="rb-blue-small-text cursor-pointer"
                                                [class.disabled-text]="fareEstimatePriceLoading"
                                                (click)="utilityService.showModal('fareBreakdown')"
                                            >
                                                View Fare Breakdown
                                            </div>
                                        </div>
                                    </div>
                                    <div class="align-self-center">
                                        <img src="/assets/images/divider_large_grey.svg" alt="divider_large" />
                                    </div>

                                    <div>
                                        <div class="mt-2 m-0">
                                            <div class="text-dark-blue fw-600">Trip Estimate</div>
                                            <table class="rb-detail-table">
                                                <tr>
                                                    <td style="min-width: 5rem">
                                                        <div class="my-1">Miles</div>
                                                    </td>
                                                    <td class="text-break">{{ fare_trip_distance }}</td>
                                                </tr>
                                                <tr>
                                                    <td style="min-width: 5rem">Minutes</td>
                                                    <td class="text-break">{{ fare_trip_duration }}</td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                <div class="d-flex">
                                    <div class="rb-mid-text my-2">
                                        <span *ngIf="mas_active_trip">MAS</span>
                                        Trip Information
                                    </div>
                                    <div
                                        *ngIf="mas_active_trip"
                                        class="ml-2 rb-blue-small-text align-self-center cursor-pointer"
                                        (click)="open_mas_trip_info_popup()"
                                    >
                                        View Trip Details
                                    </div>
                                </div>

                                <div class="my-3">
                                    <table class="rb-detail-table">
                                        <tr *ngIf="mas_active_trip">
                                            <td>
                                                <div class="my-1">Leg Cost (MAS)</div>
                                            </td>
                                            <td class="text-break">
                                                {{ mas_total_leg_cost | currency: 'USD' }}
                                                <span class="cursor-pointer pl-1" style="margin-left: -3px" (click)="open_leg_cost_breakdown()">
                                                    <i style="color: #323f50" class="fa fa-exclamation-circle"></i>
                                                </span>
                                                | {{ mas_mileage }} miles
                                            </td>
                                        </tr>
                                        <tr *ngIf="mas_active_trip">
                                            <td>
                                                <div class="my-1">Pickup time</div>
                                            </td>
                                            <td class="text-break">{{ mas_pickup_date | date: 'MMMM d, y | EEEE' }} {{ mas_pickup_time }} - (EDT)</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="my-1">Pickup Location</div>
                                            </td>
                                            <td class="text-break">{{ booking.pickup_location_address?.toString()?.replaceAll('/', ' ') }}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="my-1">Drop Off Location</div>
                                            </td>
                                            <td class="text-break">{{ booking.manual_destination_address?.toString()?.replaceAll('/', ' ') }}</td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="my-1">Avoid Tolls</div>
                                            </td>
                                            <td class="text-break">
                                                <ng-container [formGroup]="routeOptionForm">
                                                    <div class="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="avoidTollSwitch"
                                                            formControlName="avoidTolls"
                                                            (change)="this.getDirectionsForBooking(true); initializeFareEsimateMap()"
                                                        />
                                                        <label class="custom-control-label fs-14 fw-600 dark-color" for="avoidTollSwitch"></label>
                                                    </div>
                                                </ng-container>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>
                                                <div class="my-1">Avoid Highways</div>
                                            </td>
                                            <td class="text-break">
                                                <ng-container [formGroup]="routeOptionForm">
                                                    <div class="custom-control custom-switch">
                                                        <input
                                                            type="checkbox"
                                                            class="custom-control-input"
                                                            id="avoidHighwaysSwitch"
                                                            formControlName="avoidHighways"
                                                            (change)="this.getDirectionsForBooking(true); initializeFareEsimateMap()"
                                                        />
                                                        <label class="custom-control-label fs-14 fw-600 dark-color" for="avoidHighwaysSwitch"></label>
                                                    </div>
                                                </ng-container>
                                            </td>
                                        </tr>
                                    </table>
                                </div>

                                <hr />

                                <div class="d-flex justify-content-between">
                                    <div class="rb-mid-text rb-small-size cursor-pointer align-self-center">Driver Information</div>
                                    <div class="cmodal py-1 w-50 align-self-center" align="right">
                                        <div class="dropdown">
                                            <span
                                                class="w-100"
                                                type="button"
                                                id="affiliation_menu"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <div class="d-flex justify-content-between placeholder small fw-600">
                                                    <div>
                                                        <ng-container *ngIf="!selected_driver">Select Driver</ng-container>
                                                        <ng-container *ngIf="selected_driver">{{ selected_driver?.driver_name }}</ng-container>
                                                    </div>
                                                    <div class="align-self-center"><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                                                </div>
                                            </span>
                                            <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                                            <div
                                                class="dropdown-menu p-0 card-shadow w-100 overflow-auto"
                                                id="trip_status_menu"
                                                aria-labelledby="affiliation_menu"
                                            >
                                                <div class="px-2 py-3 search-box">
                                                    <span>
                                                        <input
                                                            [formControl]="driverSearchControl"
                                                            class="m-0 border-0"
                                                            style="width: 79%"
                                                            type="text"
                                                            placeholder=" Search Drivers"
                                                        />
                                                        <i class="fa fa-search" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div
                                                    class="dropdown-item cursor-pointer fs-13"
                                                    (click)="selected_driver = { driver_name: 'Random Driver', driver_id: '' }"
                                                >
                                                    Random Driver
                                                </div>
                                                <ng-container *ngFor="let driver of drivers">
                                                    <div
                                                        class="dropdown-item cursor-pointer fs-13"
                                                        *ngIf="driver?.driver_id !== selected_driver?.driver_id"
                                                        (click)="select_this_driver(driver)"
                                                    >
                                                        {{ driver.driver_name }}
                                                        <span class="fs-11">{{ driver.vehicle_number }}</span>
                                                    </div>
                                                </ng-container>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <hr />

                                <!-- If Authorization Request is still in progress then SHOW Waiting loader else SHOW buttons to book Ride (Conditional - Now,later) -->
                                <ng-container *ngIf="!requestPending">
                                    <div class="my-2 row">
                                        <div class="col my-auto">
                                            <span *ngIf="!promo_applied" class="rb-mid-text rb-small-size cursor-pointer" (click)="promoPopupShow()">
                                                <i class="fa fa-tag" aria-hidden="true"></i>
                                                Promo Code?
                                            </span>
                                            <span *ngIf="promo_applied" class="rb-mid-text rb-small-size promo-applied">
                                                <i class="fa fa-tag" aria-hidden="true"></i>
                                                Promo Applied
                                                <span class="ml-1 rb-blue-small-text cursor-pointer" (click)="promoPopupShow()">Edit</span>
                                            </span>
                                        </div>
                                        <div class="col-auto">
                                            <img src="assets/images/large_divider.svg" alt="large_divider" />
                                        </div>
                                        <div class="col my-auto" align="right">
                                            <span
                                                class="rb-mid-text rb-small-size cursor-pointer"
                                                data-target="#premiumOption"
                                                (click)="premiumOptionControl()"
                                            >
                                                <div class="d-flex justify-content-end">
                                                    <div class="align-self-center mr-1 mb-1">
                                                        <!-- <i class="fa fa-star mr-1" style="background-color: #334D6E;padding:3px;color:white;font-size:9px;border-radius:50%" aria-hidden="true">
                                                        </i> -->
                                                        <img src="assets/images/star.svg" style="width: 16px" />
                                                    </div>
                                                    <div class="align-self-center">
                                                        <span>
                                                            Premium Option
                                                            <span *ngIf="premiumOptionLabel">
                                                                {{ premiumOptionLabel }}
                                                            </span>
                                                        </span>
                                                    </div>
                                                </div>
                                            </span>
                                        </div>
                                    </div>
                                    <hr />

                                    <!-- class="my-4 rb-btn d-flex justify-content-between" -->
                                    <!-- flex-row -->
                                    <div class="my-4 rb-btn">
                                        <!-- non mas ride -->
                                        <div class="d-flex justify-content-around">
                                            <ng-container *ngIf="!booking.addQueued">
                                                <div>
                                                    <button
                                                        class="btn btn-sm rb-btn-dark-blue ml-0 mr-3 btn-secondary border-0"
                                                        [disabled]="DisableOnload"
                                                        (click)="timeSelected('now')"
                                                    >
                                                        <span *ngIf="mas_active_trip">Dispatch Now</span>
                                                        <span *ngIf="!mas_active_trip">Request Now</span>
                                                    </button>
                                                </div>
                                                <div>
                                                    <button class="btn btn-sm rb-btn-blue mx-3 btn-primary border-0" (click)="timeScheduler()">
                                                        <span *ngIf="mas_active_trip">Dispatch Later</span>
                                                        <span *ngIf="!mas_active_trip">Book Later</span>
                                                    </button>
                                                </div>
                                            </ng-container>

                                            <ng-container *ngIf="booking.addQueued">
                                                <div>
                                                    <button
                                                        (click)="queue_a_new_rider()"
                                                        class="btn btn-sm rb-btn-dark-blue ml-0 mr-3 btn-secondary border-0"
                                                    >
                                                        Queue & Save this fare
                                                    </button>
                                                </div>
                                            </ng-container>
                                            <div>
                                                <button
                                                    data-dismiss="modal"
                                                    (click)="cancelAndUnselect()"
                                                    class="btn btn-sm rb-btn-grey mx-3 btn-secondary border-0"
                                                >
                                                    Cancel
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </ng-container>

                                <div class="text-center" *ngIf="requestPending">
                                    <span class="rb-grey-mid-text bold mt-4" style="font-size: 1.05rem">
                                        Waiting for rider to confirm the payment
                                    </span>
                                    <div class="col-md-12 my-3">
                                        <app-loading></app-loading>
                                    </div>
                                </div>
                            </div>
                            <div class="col-md-6" style="position: relative">
                                <div id="fare_estimate_map" class="card-shadow"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- This Modal will be displayed to select the card to choose, in Case of only one card enabled or Retry ride, this step is bypassed to use  default card -->

    <div class="modal fade" id="payment_step" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-xl" role="document">
            <div class="modal-content">
                <div class="modal-body px-0">
                    <div class="ps-header mt-4 px-4 mx-2 row">
                        <div class="col-auto p-0 mr-auto title">Select Payment Method</div>
                        <div class="col-auto my-auto">
                            <button
                                class="btn btn-outline-primary p-add-button btn-md"
                                (click)="clearStripeCard(); utilityService.showModal('add_card')"
                            >
                                Add Card
                            </button>
                            <div class="search_input">
                                <input [(ngModel)]="searchCard" type="search" class="form-control" placeholder="Search" />
                            </div>
                        </div>
                    </div>
                    <div class="ps-body mt-3">
                        <div class="ps-nav px-4">
                            <div
                                [hidden]="!settings.bothEnabled && settings.RiderCardEnabled"
                                class="mx-2 pb-2 cursor-pointer"
                                [class.active]="corporateCards"
                                (click)="showCardList('corporate')"
                            >
                                Corporate Card
                            </div>
                            <div
                                [hidden]="!settings.bothEnabled && settings.CorpCardEnabled"
                                class="mx-2 pb-2 cursor-pointer"
                                [class.active]="riderCards"
                                (click)="showCardList('rider')"
                            >
                                Rider Card
                            </div>
                        </div>
                        <hr />
                        <div class="ps-table">
                            <table class="table mb-0" *ngIf="corporateCards">
                                <thead>
                                    <tr>
                                        <th scope="col" class="fc">ID</th>
                                        <!-- <th scope="col">Users</th> -->
                                        <th scope="col">Card Type</th>
                                        <th scope="col">Card Number</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">&nbsp; Default</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="Cards.length > 0">
                                    <tr
                                        class="content"
                                        *ngFor="let card of Cards | StringFilter: 'last4_digits':searchCard.trim(); let i = index"
                                        (click)="selectThisCard('ci' + i)"
                                    >
                                        <td class="fc">{{ card.id }}</td>
                                        <td>{{ card.card_type }}</td>
                                        <td>**{{ card.last4_digits }}</td>
                                        <td>{{ card.card_added_on | date: 'MM/dd/yyyy ' }}</td>
                                        <td>
                                            <div *ngIf="card.default_card" class="badge badge-secondary default_card text-white py-1 px-2">
                                                Default
                                            </div>
                                            <button
                                                *ngIf="!card.default_card"
                                                (click)="!card.default_card ? setDefaultCard(card.id) : doNothing()"
                                                class="cardDefaultLabel"
                                            >
                                                Make Default
                                            </button>
                                        </td>
                                        <td>
                                            <!-- <label class="radiobox">
                                                <input type="radio" name="corporateCard" [checked]="card.default_card ? 1 : 0"
                                                    [value]="card.id" [(ngModel)]="booking.cardIdCorporate" />
                                                <span class="checkmark"></span>
                                            </label> -->
                                            <input
                                                name="corporateCard"
                                                [checked]="card.default_card ? 1 : 0 || booking.cardIdCorporate == card.id"
                                                [value]="card.id"
                                                type="radio"
                                                (change)="booking.cardIdCorporate = card.id"
                                                [id]="'ci' + i"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="(Cards | StringFilter: 'last4_digits':searchCard.trim()).length === 0 && !cardLoading">
                                    <tr>
                                        <td colspan="6" class="text-center cursor-default">No Card Found</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="cardLoading && Cards.length == 0">
                                    <tr>
                                        <td colspan="6" class="text-center">
                                            <app-loading type="placeholder"></app-loading>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>

                            <table class="table mb-0" *ngIf="riderCards">
                                <thead>
                                    <tr>
                                        <th scope="col" class="fc">ID</th>
                                        <!-- <th scope="col">Users</th> -->
                                        <th scope="col">Card Type</th>
                                        <th scope="col">Card Number</th>
                                        <th scope="col">Date</th>
                                        <th scope="col">&nbsp; Default</th>
                                        <th scope="col">Action</th>
                                    </tr>
                                </thead>
                                <tbody *ngIf="RiderCards.length > 0">
                                    <tr
                                        class="content"
                                        *ngFor="let card of RiderCards | StringFilter: 'last4_digits':searchCard.trim(); let i = index"
                                        (click)="selectThisCard('rci' + i)"
                                    >
                                        <td class="fc">{{ card.id }}</td>
                                        <td>{{ card.card_type }}</td>
                                        <td>**{{ card.last4_digits }}</td>
                                        <td>{{ card.card_added_on | date: 'MM/dd/yyyy ' }}</td>
                                        <td>
                                            <div *ngIf="card.default_card" class="badge badge-secondary default_card text-white py-1 px-2">
                                                Default
                                            </div>
                                            <button
                                                *ngIf="!card.default_card"
                                                (click)="!card.default_card ? setDefaultCard(card.id) : doNothing()"
                                                class="cardDefaultLabel"
                                            >
                                                Make Default
                                            </button>
                                        </td>
                                        <td>
                                            <!-- <label class="radiobox">
                                                <input type="radio" name="rideCard" [checked]="(card.default_card ? 1 : 0)"
                                                    [value]="card.id" [(ngModel)]="booking.cardIdUser" />
                                                <span class="checkmark"></span>
                                            </label> -->
                                            <input
                                                name="riderCard"
                                                type="radio"
                                                [checked]="(card.default_card ? 1 : 0) || booking.cardIdUser == card.id"
                                                [value]="card.id"
                                                (change)="booking.cardIdUser = card.id"
                                                [id]="'rci' + i"
                                            />
                                        </td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="(RiderCards | StringFilter: 'last4_digits':searchCard.trim()).length === 0 && !cardLoading">
                                    <tr>
                                        <td colspan="6" class="text-center cursor-default">No Card Found</td>
                                    </tr>
                                </tbody>
                                <tbody *ngIf="cardLoading && RiderCards.length == 0">
                                    <tr>
                                        <td colspan="6" class="text-center">
                                            <app-loading type="placeholder"></app-loading>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <hr style="margin: 0px !important" />

                        <div class="ps-bottom" align="center">
                            <!-- Conditional buttons based on CARD selected for Rider or Corporate -->
                            <ng-container *ngIf="booking.card_user_type == 1">
                                <!-- Conditional buttons based on Ride selected for Corporate Card (Now or later) -->
                                <button class="btn btn-secondary border-0" (click)="BookRideRightNow()" *ngIf="paymentForNow">Select Payment</button>

                                <button class="btn btn-secondary border-0" (click)="BookRideLater()" *ngIf="paymentForLater">Select Payment</button>
                            </ng-container>

                            <!-- Conditional buttons based on CARD selected for Rider , In this case we need to Ask for Authorization -->
                            <ng-container *ngIf="booking.card_user_type == 0">
                                <button class="btn btn-secondary border-0" (click)="checkRiderAuthorization()">Select Payment</button>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal"
        id="request_right_now"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="mx-5 mt-5 mb-2 title">Requesting a ride now</div>

                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5 text-center">
                            <!-- <div class="col-md-12"><img class="loader-img mt-2" src="assets/images/loader.gif" /></div> -->
                            <app-loading></app-loading>
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button
                            class="btn btn-primary btn-left m-2 px-3 py-1"
                            [disabled]="DisbleOnBRN || !session_id"
                            data-dismiss="modal"
                            (click)="cancelRide(session_id)"
                        >
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="request_right_now_1"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="mx-5 mt-5 mb-2 title">Ride Request</div>

                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            You are requesting now a ride for
                            <br />
                            <span class="userTopick1">
                                {{ booking.user_name }}
                            </span>
                            to be pickup at
                            <span class="pickup-location">
                                {{ booking.pickup_location_address }}
                            </span>
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button class="btn btn-primary btn-left m-2 px-3 py-1" data-dismiss="modal">Cancel</button>

                        <button class="btn btn-secondary btn-right m-2 px-3 py-1" (click)="BookRideRightNow()">Yes</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="show_confirmation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="mx-5 mt-5 mb-2 title">Confirmation</div>

                <div class="modal-body p-0" [hidden]="!booking.confirmNow">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            A driver will come and Pick
                            <span class="userTopick">
                                <strong>{{ booking.user_name }}</strong>
                            </span>
                            at the requested time at
                            <span class="pickup-location">
                                <strong>{{ booking.pickup_location_address }}</strong>
                            </span>
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button class="btn btn-secondary btn-right m-2 px-3 py-1" (click)="closeConfirm()">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="show_reschedule_confirmation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="mx-5 mt-5 mb-2 title">Reschedule Confirmation</div>

                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            A driver will come and Pick
                            <span class="userTopick">
                                <strong>{{ rTrip.user_name }}</strong>
                            </span>
                            at the requested time at
                            <span class="pickup-location">
                                <strong>{{ rTrip.pickup_location_address }}</strong>
                            </span>
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button class="btn btn-secondary btn-right m-2 px-3 py-1" data-dismiss="modal">OK</button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="show_PaymentError" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <!-- <div class="modal-dialog modal-dialog-centered modal-lg" role="document"> -->
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered">
            <div class="modal-content shadow">
                <div class="mx-5 mt-5 mb-2 title">
                    <span *ngIf="invalid_card">Payment Unsuccessful</span>
                    <span *ngIf="!invalid_card">Add New Card</span>
                </div>

                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            <span *ngIf="invalid_card">Please update your Card Details.</span>
                            <span *ngIf="!invalid_card">Please Add your Card Details.</span>
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button
                            data-toggle="modal"
                            class="btn btn-primary btn-left m-2 px-3 py-1"
                            (click)="closePayment(); clearStripeCard(); utilityService.showModal('add_card')"
                        >
                            Add Card
                        </button>

                        <button *ngIf="invalid_card" class="btn btn-secondary btn-right m-2 px-3 py-1" (click)="closeandCard()" data-dismiss="modal">
                            Update
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="modal fade" id="showRiderAuthorization" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <!-- <div class="modal-dialog modal-dialog-centered modal-lg" role="document"> -->
        <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document">
            <div class="modal-content shadow">
                <div class="mx-5 mt-5 mb-2 title">Rider's payment method?</div>

                <div class="modal-body p-0">
                    <hr class="confirm mb-0" />
                    <div class="message-bg">
                        <div class="message p-5">
                            <!-- You are requesting a ride on behalf of the rider's payment method, we will send a message to the rider to confirm authorization to proceed. -->
                            You are requesting in behalf of the rider's payment method, do you still want to proceed?
                        </div>
                    </div>
                    <hr class="confirm mt-0" />

                    <div align="center" class="m-3">
                        <button class="btn btn-primary btn-left m-2 px-3 py-1" data-dismiss="modal">Cancel</button>

                        <button *ngIf="paymentForNow" (click)="sendRiderCardAuthorization('Now')" class="btn btn-secondary btn-right m-2 px-3 py-1">
                            Yes, Please Send Request
                        </button>
                        <button
                            *ngIf="paymentForLater"
                            (click)="sendRiderCardAuthorization('Later')"
                            class="btn btn-secondary btn-right m-2 px-3 py-1"
                        >
                            Yes, Please Send Request
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" id="rider_payment_otp" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="row modaltitle">
                    <div class="col-sm-12">
                        <button type="button" class="close" data-dismiss="modal" style="font-size: 32px; float: right; padding: 5px">&times;</button>
                    </div>
                </div>
                <div class="modal-body">
                    <div class="row modalrow">
                        <div class="col-md-8 offset-md-2 text-center">
                            <p>An OTP is sent to {{ userMobileToAdd }} and need to be entered below for the Ride request to proceed.</p>
                        </div>
                        <div class="col-sm-6 text-center col-sm-offset-3">
                            <label>Enter OTP</label>
                            <div class="input-container text-center">
                                <input
                                    type="text"
                                    placeholder="0000"
                                    onkeypress="return isNumberKeys(event)"
                                    maxlength="4"
                                    [(ngModel)]="booking.cardOTP"
                                    class="form-control text-center"
                                />
                            </div>
                        </div>
                        <div class="col-sm-6 text-center col-sm-offset-3">
                            <div class="text-center">
                                <a class="callToAction" (click)="reSendRiderOTP()">Resend OTP</a>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-sm-12 addbtn">
                            <div class="btn-align">
                                <!-- Conditional buttons based on Ride selected for now or later-->

                                <button (click)="BookRideRightNow()" *ngIf="paymentForNow" class="btn btn-primary ride-confirm-btn button-black">
                                    Process Request
                                </button>
                                <button (click)="BookRideLater()" *ngIf="paymentForLater" class="btn btn-primary ride-confirm-btn button-black">
                                    Process Request
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="promoCodeEnter"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content card-shadow booking-model-component">
                <!-- <div class="row modaltitle" style="float: right;padding: 0;padding-right: 6px;">
                    <div class="col-sm-12">
                        Enter Promo
                    </div>
                </div> -->
                <div class="mx-5 mt-5 mb-2 rb-large-text d-flex justify-content-between">
                    <div>Enter Promo Code</div>
                    <div *ngIf="promo_applied">
                        <button (click)="booking.promo_code = appliedPromoCode" type="button" class="close" data-dismiss="modal">&times;</button>
                    </div>
                </div>
                <hr class="confirm" />
                <div class="modal-body my-2 mx-5">
                    <div class="row modalrow">
                        <!-- <div class="col-sm-8 col-sm-offset-3" style="margin:0 auto;"> -->
                        <div class="input-container">
                            <input type="search" placeholder="Enter Promo Code" [(ngModel)]="booking.promo_code" class="form-control" />
                        </div>
                        <!-- </div> -->
                    </div>
                </div>
                <hr class="confirm" />
                <div class="row rb-btn mt-1 mb-4">
                    <div class="col-3"></div>
                    <div class="col-3 text-center">
                        <button *ngIf="!promo_applied" data-dismiss="modal" class="btn btn btn-secondary border-0 p-2 rb-btn-grey ride-confirm-btn">
                            Cancel
                        </button>
                        <button
                            *ngIf="promo_applied"
                            data-dismiss="modal"
                            (click)="closeandCancel()"
                            class="btn btn-secondary border-0 p-2 rb-btn-grey ride-confirm-btn"
                        >
                            Clear
                        </button>
                    </div>
                    <div class="col-3 text-center">
                        <button (click)="closeandApply()" class="btn btn-secondary border-0 p-2 rb-btn-dark-blue ride-confirm-btn">Apply</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="premiumOption"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content card-shadow booking-model-component">
                <div class="mx-5 mt-5 rb-large-text" style="font-size: 1.4rem">Add Premium Options</div>
                <hr class="confirm" />
                <div class="modal-body mb-2 mx-5 p-0">
                    <form [formGroup]="premiumOption">
                        <table class="table borderless">
                            <thead class="tb-heading">
                                <tr>
                                    <th>Premium</th>
                                    <th>Fee</th>
                                    <th>Add</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr
                                    class="content"
                                    (click)="premiumOption.value['plexiglass_partition'] ? po1.click() : confirmPremiumOption('plexiglass_partition')"
                                >
                                    <td class="p-3">Cars with Plexiglass Partition</td>
                                    <td class="p-3">No Fee</td>
                                    <td class="p-3 text-center">
                                        <!-- <div class="form-check"> -->
                                        <input style="pointer-events: none" formControlName="plexiglass_partition" type="checkbox" value="" #po1 />
                                        <!-- </div> -->
                                    </td>
                                </tr>
                                <tr
                                    class="content"
                                    (click)="premiumOption.value['through_door_service'] ? po2.click() : confirmPremiumOption('through_door_service')"
                                >
                                    <td class="p-3">Through the Door Service</td>
                                    <td class="p-3">$ 10.00</td>
                                    <td class="p-3 text-center">
                                        <!-- <div class="form-check"> -->
                                        <input
                                            style="pointer-events: none"
                                            formControlName="through_door_service"
                                            (click)="$event.stopPropagation()"
                                            type="checkbox"
                                            value=""
                                            #po2
                                        />
                                        <!-- </div> -->
                                    </td>
                                </tr>
                                <tr
                                    class="content"
                                    (click)="premiumOption.value['pet_friendly_car'] ? po3.click() : confirmPremiumOption('pet_friendly_car')"
                                >
                                    <td class="p-3">Pet Friendly Car</td>
                                    <td class="p-3">$ 7.00</td>
                                    <td class="p-3 text-center">
                                        <!-- <div class="form-check"> -->
                                        <input
                                            formControlName="pet_friendly_car"
                                            style="pointer-events: none"
                                            (click)="$event.stopPropagation()"
                                            type="checkbox"
                                            value=""
                                            #po3
                                        />
                                        <!-- </div> -->
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </form>
                </div>
                <hr class="confirm" />
                <div class="row rb-btn mt-1 mb-4">
                    <div class="col-3"></div>
                    <div class="col-3 text-center">
                        <button data-dismiss="modal" class="btn btn btn-secondary border-0 p-2 rb-btn-grey ride-confirm-btn" (click)="cancelOption()">
                            Cancel
                        </button>
                    </div>
                    <div class="col-3 text-center">
                        <button
                            data-dismiss="modal"
                            class="btn btn-secondary border-0 p-2 rb-btn-dark-blue ride-confirm-btn"
                            (click)="updateOption()"
                        >
                            <ng-container *ngIf="!premiumOptionLabel">Add Option</ng-container>
                            <ng-container *ngIf="premiumOptionLabel == 'Added'">Update Option</ng-container>
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade touch-popup"
        id="premiumOption_Confirmation"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!-- style="min-width: 550px !important; max-width: fit-content !important" -->
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="max-width: 650px !important">
            <div class="modal-content shadow-sm border-0">
                <div class="touch-header pt-1 t-margin my-4">
                    <div class="large-medium-text dark-color align-self-center">
                        <div class="p-0 pb-1 my-0">{{ premium_option_confirmation[activeOption]?.title }}</div>
                    </div>
                </div>
                <hr class="light" />
                <div class="touch-content t-margin my-4 dark-color">
                    <div class="d-flex justify-content-center">
                        <div class="align-self-center m-3">
                            <img [src]="premium_option_confirmation[activeOption]?.imageUrl" alt="pet_friendly_rides" />
                        </div>
                        <div
                            class="align-self-center m-3 dark-color"
                            style="max-width: 260px"
                            [innerHTML]="premium_option_confirmation[activeOption]?.description"
                        >
                            <!-- Whether you’re visiting a family or doing your regular routine, there is always a room for your beloved pet.
                            <br><br>We will find drivers who agreed to accept pets in their car. -->
                        </div>
                    </div>
                </div>
                <hr class="light" />

                <div class="touch-bottom t-margin my-4">
                    <div class="d-flex justify-content-around">
                        <div class="cursor-pointer blue-color" data-dismiss="modal">Cancel</div>

                        <div class="cursor-pointer blue-color" data-dismiss="modal" (click)="confirmPremiumOptionAction()">Please add to my ride</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade"
        id="add_card"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog modal-dialog-centered card-modal" role="document">
            <div class="modal-content shadow">
                <div class="row modaltitle">
                    <div class="mx-4 mx-sm-5 mt-4 mb-2 title">Add {{ corporateCards ? 'Corporate' : 'Rider' }} Card</div>
                </div>
                <hr class="confirm my-0" />
                <div class="modal-body centered-text">
                    <div class="col-sm-12">
                        <form enctype="multipart/form-data" action="#" method="post" name="myForm" id="payment-form">
                            <div class="touch-content my-4" *ngIf="!user_email && riderCards">
                                <div class="my-1">
                                    <div class="input-container text-center">
                                        <input
                                            type="email"
                                            placeholder="Enter rider email"
                                            [(ngModel)]="cardForEmail"
                                            (keyup)="show_email_error = false"
                                            [ngModelOptions]="{ standalone: true }"
                                            class="form-control soft-input"
                                            [class.error]="show_email_error"
                                            [email]="true"
                                        />
                                    </div>
                                </div>
                            </div>
                            <div class="modalrow my-3" [class.StripeElement--invalid]="cardError">
                                <ngx-stripe-card
                                    [options]="cardOptions"
                                    [elementsOptions]="elementsOptions"
                                    (change)="onStripeCardChange($event)"
                                    #stripeElement
                                ></ngx-stripe-card>
                                <div class="col-sm-12 text-center my-2">
                                    <div id="card-errors" role="alert">{{ cardError }}</div>
                                </div>
                            </div>
                            <div align="center">
                                <button data-dismiss="modal" class="btn btn-primary btn-left m-2 px-3 py-1">Cancel</button>
                                <button type="submit" (click)="onSaveCard()" class="btn btn-secondary btn-right m-2 px-3 py-1">Save Card</button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade touch-popup" id="add_myUser" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg" role="document">
            <div class="modal-content">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 large-text dark-color">
                        <div class="mr-3 p-0 pb-1 my-0">Add user to account</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-content mx-5 my-4">
                    <div class="mb-2 medium-text dark-color">
                        An OTP is sent to {{ userMobileToAdd }} and need to be entered below for the new ride request to proceed.
                    </div>

                    <div class="my-3">
                        <label class="dark-color">Enter OTP</label>
                        <div class="input-container text-center" style="width: 250px">
                            <input
                                type="text"
                                placeholder="0000"
                                onkeypress="return isNumberKeys(event)"
                                maxlength="4"
                                [(ngModel)]="otpToAdd"
                                class="form-control text-center otp-field"
                            />
                            <input type="hidden" [(ngModel)]="userToAdd" class="form-control" />
                            <input type="hidden" [(ngModel)]="userMobileToAdd" class="form-control" />
                        </div>
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-bottom mx-5 my-4">
                    <div class="d-flex justify-content-between">
                        <div class="d-flex">
                            <button class="btn btn-primary left px-4" data-dismiss="modal" [disabled]="DisableResnd" (click)="reAddUser()">
                                Resend OTP
                            </button>

                            <button class="btn btn-primary mx-2" (click)="autoFillOTP()">View OTP Sent</button>
                        </div>

                        <!-- <button class="btn btn-primary right px-4" [disabled]="onInvalidOtp" (click)="completeUserAdd()">Add User</button> -->
                        <button class="btn btn-primary right px-4" (click)="completeUserAdd()">Add User</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade touch-popup"
        id="subAddress_Option"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div class="modal-dialog touch-popup fare-breakdown-modal modal-dialog-centered" role="document">
            <div class="modal-content">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 large-text dark-color">
                        <div class="mr-3 p-0 pb-1 my-0">Select Location</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-content mx-5 my-4">
                    <ul class="list-group list-group-flush px-5" *ngIf="subAddresses.list.length > 0">
                        <li
                            *ngFor="let item of subAddresses?.list"
                            (click)="selectSubaddress(item)"
                            class="list-group-item list-group-item-action py-3 text-center dark-color cursor-pointer"
                            data-dismiss="modal"
                        >
                            {{ item?.main_text }}
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade touch-popup"
        id="route_option"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div
            class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg"
            role="document"
            style="min-width: 550px !important; max-width: fit-content !important"
        >
            <div class="modal-content">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 large-text dark-color">
                        <div class="mr-3 p-0 pb-1 my-0">Route Options</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-content mx-5 my-4">
                    <form [formGroup]="routeOptionForm" class="mx-3 dark-color">
                        <div class="d-flex justify-content-between my-2 px-3 py-2 touch-list-item" (click)="ro1.click()">
                            <div>Avoid highways</div>
                            <div><input type="checkbox" #ro1 (click)="$event.stopPropagation()" formControlName="avoidHighways" /></div>
                        </div>
                        <div class="d-flex justify-content-between my-2 px-3 py-2 touch-list-item" (click)="ro2.click()">
                            <div>Avoid tolls</div>
                            <div><input type="checkbox" #ro2 (click)="$event.stopPropagation()" formControlName="avoidTolls" /></div>
                        </div>
                        <div class="d-flex justify-content-between my-2 px-3 py-2 touch-list-item" (click)="ro3.click()">
                            <div>Avoid ferries</div>
                            <div><input type="checkbox" #ro3 (click)="$event.stopPropagation()" formControlName="avoidFerries" /></div>
                        </div>
                    </form>
                </div>
                <hr class="light" />
                <div class="touch-bottom mx-5 my-3" align="center">
                    <button
                        class="btn btn-secondary border-0 font-light btn-left m-2 px-3 py-1"
                        data-dismiss="modal"
                        (click)="updateRouteOption('cancel')"
                    >
                        Cancel
                    </button>

                    <button
                        class="btn btn-secondary border-0 font-light btn-right m-2 px-3 py-1"
                        data-dismiss="modal"
                        (click)="updateRouteOption('use')"
                    >
                        Use Route
                    </button>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade touch-popup"
        id="search_trip_by_rider"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div
            class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg"
            role="document"
            style="min-width: 550px !important; max-width: fit-content !important"
        >
            <div class="modal-content">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 large-medium-text dark-color">
                        <div class="mr-3 p-0 pb-1 my-0">Search Trips by Rider</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-content m-5">
                    <div class="cmodal py-1 w-100">
                        <div class="dropdown">
                            <span class="w-100" type="button" id="affiliation_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <div class="d-flex justify-content-between placeholder small fw-600">
                                    <div>
                                        <ng-container *ngIf="!selected_rider">Search rider's name</ng-container>
                                        <ng-container *ngIf="selected_rider">{{ selected_rider?.user_name }}</ng-container>
                                    </div>
                                    <div class="align-self-center"><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                                </div>
                            </span>
                            <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                            <div class="dropdown-menu p-0 card-shadow w-100 overflow-auto" id="trip_status_menu" aria-labelledby="affiliation_menu">
                                <div class="w-100 px-2 py-3 search-box">
                                    <span>
                                        <!-- [formControl]="" -->
                                        <input
                                            class="m-0 border-0"
                                            [formControl]="riderSearchForm"
                                            style="width: 89%"
                                            type="text"
                                            placeholder="Search Riders"
                                        />
                                        <i class="fa fa-search" aria-hidden="true"></i>
                                    </span>
                                </div>
                                <div *ngFor="let rider of riderList" class="dropdown-item cursor-pointer fs-13" (click)="selected_rider = rider">
                                    <b>{{ rider.user_name }}</b>
                                    {{ rider.user_mobile }}
                                </div>
                                <div *ngIf="rider_loading && riderList.length === 0" align="center" class="p-2">
                                    <ng-container *ngFor="let item of [0, 1, 2, 3, 4, 5]">
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </ng-container>
                                </div>
                            </div>
                            <div class="mt-3">
                                <span (click)="riderMasTripRangePicker.open()" class="fs-13 fw-600 cursor-pointer">
                                    <span class="text-spacegrey">Trip Filters</span>
                                    <span
                                        class="text-blue-secondary"
                                        *ngIf="rider_mas_trip_ranger_picker?.value['start'] && rider_mas_trip_ranger_picker?.value['end']"
                                    >
                                        : {{ rider_mas_trip_ranger_picker?.value['start'] | date: 'MMMM d' }} -
                                        {{ rider_mas_trip_ranger_picker?.value['end'] | date: 'MMMM d' }}
                                    </span>
                                </span>
                                <mat-date-range-input
                                    class="fs-9"
                                    style="width: 0px; height: 0px; visibility: hidden"
                                    [formGroup]="rider_mas_trip_ranger_picker"
                                    [rangePicker]="riderMasTripRangePicker"
                                >
                                    <input
                                        matStartDate
                                        formControlName="start"
                                        placeholder="Start date"
                                        style="width: 0px; height: 0px; visibility: hidden"
                                    />
                                    <input
                                        matEndDate
                                        formControlName="end"
                                        placeholder="End date"
                                        style="width: 0px; height: 0px; visibility: hidden"
                                    />
                                </mat-date-range-input>
                                <mat-date-range-picker #riderMasTripRangePicker></mat-date-range-picker>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="light" />
                <div class="touch-bottom t-margin my-4">
                    <div class="d-flex justify-content-around">
                        <div class="cursor-pointer blue-color" data-dismiss="modal" (click)="selected_rider = null">Back</div>
                        <div class="cursor-pointer blue-color" data-dismiss="modal" (click)="view_rider_trip()">View Trips</div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div
        class="modal fade touch-popup"
        id="send_invoice_option"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
    >
        <div
            class="modal-dialog modal-dialog-centered modal-lg"
            role="document"
            style="min-width: 550px !important; max-width: fit-content !important"
        >
            <div class="modal-content">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 large-text dark-color">
                        <div class="mr-3 p-0 pb-1 my-0">Send invoice to</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-content mx-5 my-4">
                    <ul class="list-group list-group-flush px-5">
                        <li
                            (click)="sendInvoice(0)"
                            class="list-group-item list-group-item-action py-3 text-center dark-color cursor-pointer"
                            data-dismiss="modal"
                        >
                            Corporate
                        </li>
                        <li
                            (click)="sendInvoice(1)"
                            class="list-group-item list-group-item-action py-3 text-center dark-color cursor-pointer"
                            data-dismiss="modal"
                        >
                            Rider
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>

    <!-- data-backdrop="static" -->
    <div
        class="modal fade touch-popup"
        id="fareBreakdown"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <!-- style="min-width: 550px !important; max-width: fit-content !important" -->
        <div class="modal-dialog-centered modal-dialog touch-popup fare-breakdown-modal" role="document">
            <div class="modal-content shadow-sm border-0" style="border-radius: 10px">
                <div class="touch-header pt-1 t-margin">
                    <div class="d-flex justify-content-between mt-3 mb-2">
                        <div class="medium-text dark-color align-self-center">
                            <div class="p-0 pb-1 my-0">Fare Breakdown</div>
                        </div>

                        <div class="d-flex justify-content-end pb-1">
                            <div class="mr-2 my-0 align-self-center">
                                <img src="assets/images/divider_black.svg" alt="divider" />
                            </div>
                            <div class="mx-3 my-0 mr-auto align-self-center text-center">
                                <div class="medium-text blue-color">
                                    $
                                    {{
                                        (active_fare_breakdown_flag === 'now' ? booking?.estimated_fare : booking?.estimated_fare_later)
                                            | number: '1.2-2'
                                    }}
                                </div>
                                <div class="deducted-price dark-color" *ngIf="fare_breakdown?.discount">
                                    $
                                    {{
                                        (active_fare_breakdown_flag === 'now' ? booking?.estimated_fare_old : booking?.estimated_fare_later_old)
                                            | number: '1.2-2'
                                    }}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="light" />
                <div class="touch-content t-margin my-4">
                    <div class="estimation-detail">
                        Your estimated fare will be displayed before the trip. Adjustments are made if actual route has toll or subject to government
                        mandated fees and taxes that differs from the estimated routes. Fares are based on rates below and other applicable tolls,
                        surcharges, adjustments and fees
                    </div>

                    <!-- estimate-table -->
                    <div class="my-4 estimation-list dark-color">
                        <div class="my-3 d-flex justify-content-between" *ngIf="fare_breakdown?.discount && fare_breakdown?.discount != 0">
                            <div>Ride discounts applied</div>
                            <!-- <div>$ 2.00</div> -->
                            <div *ngIf="fare_breakdown?.discount">$ {{ fare_breakdown?.discount | number: '1.2-2' }}</div>
                        </div>

                        <div class="my-4">
                            <div
                                class="d-flex justify-content-between"
                                *ngIf="
                                    (active_fare_breakdown_flag === 'now' && fare_breakdown?.qudos_service_fee != 0) ||
                                    (active_fare_breakdown_flag === 'later' && fare_breakdown?.qudos_service_fee_schedule != 0)
                                "
                            >
                                <div>Qudos service fee</div>
                                <div>
                                    $
                                    {{
                                        (active_fare_breakdown_flag == 'now'
                                            ? fare_breakdown?.qudos_service_fee
                                            : fare_breakdown?.qudos_service_fee_schedule
                                        ) | number: '1.2-2'
                                    }}
                                </div>
                            </div>
                            <!-- <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.base_fare != 0">
                                <div>Base fare</div>
                                <div>$ {{ fare_breakdown?.base_fare | number: '1.2-2' }}</div>
                            </div>

                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.estimated_fare_distance != 0">
                                <div>Estimated distance fare</div>
                                <div>$ {{ fare_breakdown?.estimated_fare_distance | number: '1.2-2' }}</div>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.estimated_fare_time != 0">
                                <div>Estimated time fare</div>
                                <div>$ {{ fare_breakdown?.estimated_fare_time | number: '1.2-2' }}</div>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.estimated_tolls != 0">
                                <div>Estimated Tolls</div>
                                <div>$ {{ fare_breakdown?.estimated_tolls | number: '1.2-2' }}</div>
                            </div> -->
                            <div class="d-flex justify-content-between">
                                <div>Booking & Car Service Fee</div>
                                <div>
                                    $
                                    {{
                                        fare_breakdown?.estimated_fare_distance + fare_breakdown?.estimated_fare_time + fare_breakdown?.base_fare
                                            | number: '1.2-2'
                                    }}
                                </div>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.congestionCharge != 0">
                                <div>Congestion Tax</div>
                                <div>$ {{ fare_breakdown?.congestionCharge | number: '1.2-2' }}</div>
                            </div>
                        </div>

                        <div class="my-4">
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.sales_tax != 0">
                                <div>Sales tax</div>
                                <div>$ {{ fare_breakdown?.sales_tax | number: '1.2-2' }}</div>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.black_car_fund != 0">
                                <div>Black car fund</div>
                                <div>$ {{ fare_breakdown?.black_car_fund | number: '1.2-2' }}</div>
                            </div>
                            <div class="d-flex justify-content-between">
                                <div>Toll</div>
                                <div>$ {{ fare_breakdown?.estimated_tolls | number: '1.2-2' }}</div>
                            </div>
                            <div
                                class="d-flex justify-content-between"
                                *ngIf="fare_breakdown?.estimated_surcharges != 0 && active_fare_breakdown_flag !== 'now'"
                            >
                                <div>Estimated surcharges</div>
                                <div>$ {{ fare_breakdown?.estimated_surcharges | number: '1.2-2' }}</div>
                            </div>
                        </div>

                        <div class="my-4">
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.pet_friendly_car != 0">
                                <div>Pet friendly fee</div>
                                <div>$ {{ fare_breakdown?.pet_friendly_car | number: '1.2-2' }}</div>
                            </div>
                            <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.through_door_service != 0">
                                <div>Through the door fee</div>
                                <div>$ {{ fare_breakdown?.through_door_service | number: '1.2-2' }}</div>
                            </div>
                        </div>
                    </div>

                    <div class="mb-4" class="estimation-detail">
                        Additional wait times charges may apply to your trip if the driver has waited more than the minimum wait time.
                    </div>
                </div>
                <hr class="light" />

                <div class="touch-bottom t-margin my-4">
                    <div class="d-flex justify-content-around">
                        <div class="cursor-pointer blue-color" data-dismiss="modal">Back</div>

                        <ng-container *ngIf="active_fare_breakdown_flag === 'now'">
                            <div class="cursor-pointer blue-color" data-dismiss="modal" (click)="timeSelected('now')">Send the ride now</div>
                        </ng-container>
                        <ng-container *ngIf="active_fare_breakdown_flag === 'later'">
                            <div
                                class="cursor-pointer blue-color"
                                data-dismiss="modal"
                                (click)="utilityService.showModal('request_ride_later'); timeScheduler()"
                            >
                                Book the ride
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- <div
        class="modal fade p-2 touch-popup"
        id="mas_trip_info"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
    >
        <div class="modal-dialog max-modal modal-dialog-centered" role="document">
            <div class="modal-content selection-dialog">
                <app-mas-trip-info [trip]="mas_active_trip"></app-mas-trip-info>
            </div>
        </div>
    </div> -->

    <div class="modal fade" id="ride-image-zoom" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
        <div class="modal-dialog shadow-sm modal-dialog-centered modal-lg" role="document">
            <div class="modal-content m-2 p-3 booking-model-component">
                <img class="ride-image-zoom img-responsive" [src]="booking.route_directions" alt="Route" />
                <div align="center" class="rb-btn mt-2">
                    <button class="btn btn-sm rb-btn-dark-blue btn-secondary border-0" data-dismiss="modal">Ok</button>
                </div>
            </div>
        </div>
    </div>

    <div class="modal fade" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true"></div>

    <div
        class="modal fade touch-popup"
        id="edit_mas_passenger_detail"
        tabindex="-1"
        role="dialog"
        aria-labelledby="exampleModalCenterTitle"
        aria-hidden="true"
        data-backdrop="static"
    >
        <div
            class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg"
            role="document"
            style="min-width: 630px !important; max-width: fit-content !important"
        >
            <div class="modal-content">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4">
                        <div class="medium-text dark-color">Edit Passenger</div>
                    </div>
                </div>

                <hr class="light" />
                <div class="bg-light-blue pr-3 pr-md-5">
                    <div class="touch-content mx-5 my-4 py-2">
                        <form [formGroup]="edit_mas_passenger_detail_form">
                            <div class="d-flex my-2 justify-content-end">
                                <div class="mx-2 align-self-center fs-13 dark-color fw-600">Passenger Name</div>
                                <div class="ml-2">
                                    <input
                                        [class.error]="
                                            edit_mas_passenger_detail_form.get('name')?.touched && edit_mas_passenger_detail_form.get('name')?.invalid
                                        "
                                        type="text"
                                        class="touch-input fs-13 w-100 p-1 px-2"
                                        formControlName="name"
                                        placeholder="Passenger name here"
                                    />
                                </div>
                            </div>
                            <div class="d-flex my-2 justify-content-end">
                                <div class="mx-2 align-self-center fs-13 dark-color fw-600">Mobile Number</div>
                                <div
                                    class="ml-2 touch-tel-input"
                                    [class.error]="
                                        edit_mas_passenger_detail_form.get('mobile')?.touched && edit_mas_passenger_detail_form.get('mobile')?.invalid
                                    "
                                >
                                    <ngx-intl-tel-input
                                        [cssClass]="'custom'"
                                        [attr.autocomplete]="false"
                                        [resetSearchContainer]="true"
                                        [preferredCountries]="preferredCountries"
                                        [enableAutoCountrySelect]="true"
                                        [enablePlaceholder]="true"
                                        [searchCountryFlag]="true"
                                        [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                        [selectFirstCountry]="false"
                                        [selectedCountryISO]="edit_passenger_selected_country_iso"
                                        [maxLength]="10"
                                        [tooltipField]="TooltipLabel.Name"
                                        [phoneValidation]="true"
                                        [separateDialCode]="separateDialCode"
                                        name="phone"
                                        [inputId]="'edit-mobile'"
                                        formControlName="mobile"
                                        [customPlaceholder]="'Mobile Number'"
                                        style="background-color: white"
                                    ></ngx-intl-tel-input>
                                </div>
                            </div>
                        </form>
                        <!-- <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div> -->
                    </div>
                </div>

                <hr class="light" />
                <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
                    <button
                        class="btn btn-secondary btn-light text-white p-2 mx-2 br-6"
                        data-dismiss="modal"
                        (click)="edit_mas_passenger_detail_form.reset()"
                    >
                        Cancel
                    </button>
                    <button
                        [disabled]="edit_mas_passenger_detail_form.invalid"
                        class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6"
                        (click)="update_mas_passenger_detail()"
                    >
                        Update
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- Recalculate Pop up -->
<div
    class="modal fade touch-popup recalculate"
    id="recalculate_popup"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-centered modal-lg touch-popup recalculate" role="document">
        <div class="modal-content border-0">
            <div class="row">
                <div class="col-md-6 col-sm-6 fare-estimate-border">
                    <div class="touch-header pt-2">
                        <div class="mx-5 my-4 medium-text">
                            <div class="text-center">
                                <span class="dark-color">Fare Estimates</span>
                                <!-- <span class="mas ml-1">MAS</span> -->
                            </div>
                        </div>
                    </div>

                    <div class="touch-content text-center dark-color" align="center">
                        <div class="text-grey my-3">New Total Fare</div>

                        <div class="fs-26 fw-600 dark-color my-3">
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div class="w-25">
                                    <app-loading height="20px" borderRadius="2px" type="placeholder"></app-loading>
                                </div>
                            </div>
                            <ng-container *ngIf="!recalculate_price_loading">
                                <ng-container *ngIf="recalculate_state?.recalculate_type === 'now'">
                                    {{ recalculate_state?.value_regular | currency: 'USD' }}
                                </ng-container>
                                <ng-container *ngIf="recalculate_state?.recalculate_type === 'later'">
                                    {{ recalculate_state.value_scheduled | currency: 'USD' }}
                                </ng-container>
                            </ng-container>
                        </div>
                        <div
                            class="fs-14 dark-color deducted-price my-3"
                            [hidden]="
                                (recalculate_state?.recalculate_type === 'now' && recalculate_state?.value_regular == booking?.estimated_fare) ||
                                (recalculate_state?.recalculate_type === 'later' &&
                                    recalculate_state?.value_scheduled == booking?.estimated_fare_later)
                            "
                        >
                            <ng-container *ngIf="recalculate_state?.recalculate_type === 'now'">
                                {{ booking?.estimated_fare | currency: 'USD' }}
                            </ng-container>
                            <ng-container *ngIf="recalculate_state?.recalculate_type === 'later'">
                                {{ booking.estimated_fare_later | currency: 'USD' }}
                            </ng-container>
                        </div>

                        <div align="center" class="my-4">
                            <div class="divider"></div>
                        </div>

                        <div class="mt-3 mb-4">
                            <div class="text-grey mb-3">Select new fare</div>
                            <ng-container *ngIf="!ismultipliear">
                                <ng-container *ngIf="!custom_recalculate_multiplier">
                                    <select
                                        id="mySelect"
                                        name="recalculate"
                                        [(ngModel)]="fare_factor_display"
                                        class="touch-input pl-2 w-25"
                                        (ngModelChange)="get_new_fare_estimateion(fare_factor_display)"
                                    >
                                        <option *ngFor="let option of recalculate_options" [value]="option.fare_factor">
                                            <span class="value-regular">${{ option.value_regular | number: '1.2-2' }}</span>
                                        </option>
                                    </select>
                                    <i class="fa fa-chevron-down" aria-hidden="true"></i>
                                </ng-container>
                                <div *ngIf="custom_recalculate_multiplier" class="d-flex justify-content-center">
                                    <div>
                                        <input
                                            name="recalculate"
                                            type="text"
                                            [(ngModel)]="fare_factor_display"
                                            class="text-center left touch-input"
                                            (keydown)="select_button_flag = true"
                                            [class.error]="fare_factor_display < 0"
                                        />
                                    </div>
                                    <div class="right touch-input right align-self-center my-auto" [class.error]="fare_factor_display < 0">X</div>
                                </div>
                            </ng-container>
                            <ng-container *ngIf="ismultipliear">
                                <div class="d-flex justify-content-center">
                                    <div class="touch-input">
                                        <app-loading height="20px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                            </ng-container>
                            <div class="mt-3">
                                <div class="cursor-pointer text-blue" (click)="toggle_recalculate_type()">
                                    <span *ngIf="!custom_recalculate_multiplier">Custom</span>
                                    <span *ngIf="custom_recalculate_multiplier">Back</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- Vertical Line -->
                <!-- <div class="vertical-line mx-4 my-5"></div> -->
                <!-- *ngIf="mas_active_trip" -->
                <div class="col-md-6 col-sm-6 mb-3">
                    <div class="touch-header pt-2">
                        <div class="mx-5 my-4 medium-text">
                            <div class="text-center">
                                <span class="dark-color">Payout Breakdown</span>
                            </div>
                        </div>
                    </div>

                    <div class="touch-content text-center dark-color" align="center">
                        <!-- Corporate Add On -->
                        <ng-container>
                            <div class="text-grey mt-3">Corporate Add On</div>
                            <div class="dark-color">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div class="w-50">
                                        <app-loading height="16px" borderRadius="2px" type="placehxolder"></app-loading>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="fs-16 fw-600">
                                        {{ corporate_addon }}
                                    </div>
                                    <div>
                                        <mat-icon
                                            class="text-dark-blue fs-16 stop-propagation cursor-pointer"
                                            data-toggle="dropdown"
                                            id="dropdown"
                                            (click)="openModal()"
                                        >
                                            edit
                                        </mat-icon>
                                        <div class="dropdown-menu text-grey" aria-labelledby="download">
                                            <div class="corporate-add-on">
                                                <div class="box" stop-propagation>
                                                    <div class="d-flex">
                                                        <span class="input-group-text border-0 fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                                                        <input
                                                            placeholder="0"
                                                            #modalInput
                                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                                            type="number"
                                                            [formControl]="corporateAddOnValue"
                                                            class="border-0 fs-16 fw-600 text-dark-blue"
                                                        />
                                                    </div>
                                                    <div class="label mt-2 fw-600 fs-12 text-center">Corporate Add On</div>
                                                </div>
                                                <div class="d-flex justify-content-between m-3">
                                                    <div class="dismiss-button cursor-pointer" (click)="dismissCorporateAddon()">Dismiss</div>
                                                    <div
                                                        class="apply-button cursor-pointer"
                                                        (click)="corporateAddOn()"
                                                        [class.disabled]="corporateAddOnValue.invalid || corporateAddOnValue?.value === null"
                                                    >
                                                        Apply
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Driver payout -->
                        <ng-container>
                            <div class="text-grey mt-3">Driver Payout</div>

                            <div class="dark-color">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div class="w-50">
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="fs-16 fw-600">
                                        <ng-container *ngIf="!recalculate_price_loading">
                                            {{ recalculate_state?.submerchant_driver_payout | currency: 'USD' }}
                                        </ng-container>
                                    </div>
                                    <div
                                        class="dark-color deducted-price fs-14 fw-600 ml-2 align-self-center"
                                        [hidden]="recalculate_state?.submerchant_driver_payout == fare_breakdown?.submerchant_driver_payout"
                                    >
                                        {{ fare_breakdown?.submerchant_driver_payout | currency: 'USD' }}
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <!-- Car Base Take -->
                        <ng-container>
                            <div class="text-grey mt-3">Car Base Take</div>

                            <div class="dark-color">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div class="w-50">
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="fs-16 fw-600">
                                        <ng-container *ngIf="!recalculate_price_loading">
                                            <ng-container *ngIf="recalculate_state?.recalculate_type === 'now'">
                                                {{ recalculate_state?.submerchant_client_payout | currency: 'USD' }}
                                            </ng-container>
                                            <ng-container *ngIf="recalculate_state?.recalculate_type === 'later'">
                                                {{ recalculate_state.submerchant_client_payout_schedule | currency: 'USD' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div
                                        class="dark-color deducted-price fs-14 fw-600 ml-2 align-self-center"
                                        [hidden]="
                                            (recalculate_state?.recalculate_type === 'now' &&
                                                recalculate_state?.submerchant_client_payout == fare_breakdown?.submerchant_client_payout) ||
                                            (recalculate_state?.recalculate_type === 'later' &&
                                                recalculate_state?.submerchant_client_payout_schedule ==
                                                    fare_breakdown?.submerchant_client_payout_schedule)
                                        "
                                    >
                                        <ng-container *ngIf="recalculate_state?.recalculate_type === 'now'">
                                            {{ fare_breakdown?.submerchant_client_payout | currency: 'USD' }}
                                        </ng-container>
                                        <ng-container *ngIf="recalculate_state?.recalculate_type === 'later'">
                                            {{ booking.submerchant_client_payout_schedule | currency: 'USD' }}
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>

                        <ng-container>
                            <div class="text-grey mt-3">Qudos Fee</div>

                            <div class="dark-color">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div class="w-50">
                                        <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="fs-16 fw-600">
                                        <ng-container *ngIf="!recalculate_price_loading">
                                            <ng-container *ngIf="recalculate_state?.recalculate_type === 'now'">
                                                {{ recalculate_state?.qudos_service_fee | currency: 'USD' }}
                                            </ng-container>
                                            <ng-container *ngIf="recalculate_state?.recalculate_type === 'later'">
                                                {{ recalculate_state.qudos_service_fee_schedule | currency: 'USD' }}
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <div
                                        class="dark-color deducted-price fs-14 fw-600 ml-2 align-self-center"
                                        [hidden]="
                                            (recalculate_state?.recalculate_type === 'now' &&
                                                recalculate_state?.qudos_service_fee == fare_breakdown?.qudos_service_fee) ||
                                            (recalculate_state?.recalculate_type === 'later' &&
                                                recalculate_state?.qudos_service_fee_schedule == fare_breakdown?.qudos_service_fee_schedule)
                                        "
                                    >
                                        <ng-container *ngIf="recalculate_state?.recalculate_type === 'now'">
                                            {{ fare_breakdown?.qudos_service_fee | currency: 'USD' }}
                                        </ng-container>
                                        <ng-container *ngIf="recalculate_state?.recalculate_type === 'later'">
                                            {{ fare_breakdown.qudos_service_fee_schedule | currency: 'USD' }}
                                        </ng-container>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>

                    <!-- Ride Incentives -->
                    <div class="touch-header pt-2">
                        <div class="mx-5 my-4 medium-text">
                            <div class="text-center">
                                <span class="dark-color">Ride Incentives</span>
                            </div>
                        </div>
                    </div>
                    <div class="touch-content text-center dark-color" align="center">
                        <!-- Ride Discount -->
                        <ng-container>
                            <div class="text-grey mt-3">Ride Discount</div>
                            <div class="dark-color">
                                <div *ngIf="recalculate_price_loading" align="center">
                                    <div class="w-50">
                                        <app-loading height="16px" borderRadius="2px" type="placehxolder"></app-loading>
                                    </div>
                                </div>
                                <div class="d-flex justify-content-center">
                                    <div class="fs-16 fw-600">
                                        {{ rideDiscount.value == null ? '$00.00' : '$' + rideDiscount.value }}
                                    </div>
                                    <div>
                                        <mat-icon
                                            class="text-dark-blue fs-16 stop-propagation cursor-pointer"
                                            data-toggle="dropdown"
                                            id="dropdown"
                                            (click)="openModalRide()"
                                        >
                                            lock
                                        </mat-icon>
                                        <div class="dropdown-menu text-grey" aria-labelledby="download">
                                            <!-- Pin Input -->
                                            <div class="corporate-add-on" *ngIf="!idPinValidate">
                                                <div class="box" stop-propagation>
                                                    <div class="text-center">
                                                        <input
                                                            #modalInputDiscountPin
                                                            placeholder="0000"
                                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                                            type="text"
                                                            [formControl]="discountPin"
                                                            maxlength="4"
                                                            class="border-0 fs-16 fw-600 text-dark-blue"
                                                        />
                                                    </div>
                                                    <div class="label mt-2 fw-600 fs-12 text-center">ENTER PIN</div>
                                                </div>
                                                <div class="d-flex justify-content-between m-3">
                                                    <div class="dismiss-button cursor-pointer" (click)="dismissDiscount()">Dismiss</div>
                                                    <div class="apply-button cursor-pointer" stop-propagation (click)="applyDiscount('pin')">
                                                        Apply
                                                    </div>
                                                </div>
                                            </div>

                                            <!-- Ride Discount Input -->
                                            <div class="corporate-add-on" *ngIf="idPinValidate">
                                                <div class="box" stop-propagation>
                                                    <div class="d-flex">
                                                        <span class="input-group-text border-0 fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                                                        <input
                                                            #modalInputDiscount
                                                            placeholder="0"
                                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                                            type="number"
                                                            [formControl]="rideDiscount"
                                                            class="border-0 fs-16 fw-600 text-dark-blue"
                                                        />
                                                    </div>
                                                    <div class="label mt-2 fw-600 fs-12 text-center">ENTER DISCOUNT</div>
                                                </div>
                                                <div class="d-flex justify-content-between m-3">
                                                    <div class="dismiss-button cursor-pointer" (click)="dismissDiscount()">Dismiss</div>
                                                    <div
                                                        class="apply-button cursor-pointer"
                                                        (click)="applyDiscount('discount')"
                                                        [class.disabled]="
                                                            rideDiscount.invalid ||
                                                            rideDiscount?.value === null ||
                                                            recalculate_state.submerchant_client_payout - recalculate_state.other_fees <=
                                                                rideDiscountControl?.value
                                                        "
                                                    >
                                                        Apply
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-bottom mx-5 my-3" align="center">
                <button
                    class="btn btn-secondary border-0 font-light btn-left m-2 re-calculate"
                    data-dismiss="modal"
                    (click)="
                        fare_factor_display = 1;
                        select_button_flag = false;
                        custom_recalculate_multiplier = false;
                        resetCorporateAddOnAndRideDiscount()
                    "
                    [disabled]="recalculate_price_loading"
                >
                    Cancel
                </button>

                <!-- Select button, Only Visible if custom recalculate option is on -->
                <ng-container *ngIf="select_button_flag">
                    <button
                        class="btn btn-secondary border-0 font-light btn-right m-2 re-calculate"
                        data-dismiss="modal"
                        (click)="get_new_fare_estimateion(fare_factor_display); select_button_flag = false"
                        [disabled]="recalculate_price_loading"
                    >
                        Select
                    </button>
                </ng-container>
                <ng-container *ngIf="!select_button_flag">
                    <button
                        class="btn btn-secondary border-0 font-light btn-right m-2 re-calculate"
                        data-dismiss="modal"
                        (click)="recalculate()"
                        [disabled]="recalculate_price_loading"
                    >
                        Apply
                    </button>
                </ng-container>
            </div>
        </div>
    </div>
</div>

<ng-template #select_car_type_dropdown>
    <div class="dropdown">
        <!-- (click)="findDistance($event);mas_active_trip ? null : getDataAndshowPopup({ healthCheck: false, loading: false, skip: false, addQueued: false })" -->
        <span class="dropdown-toggle" type="button" id="prefer_fav_dropdown" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
            {{ carDriverOptions[findIndex_from_objectArray('car_type', this.booking.car_type, carDriverOptions)]?.car_name || 'Ride Type' }}
        </span>
        <div class="dropdown-menu card-shadow py-2" id="prefer_ride_menu" aria-labelledby="prefer_fav_dropdown">
            <ng-container *ngFor="let obj of carDriverOptions; let i = index">
                <div class="dropdown-item p-0">
                    <div class="row my-1 cursor-pointer p-0" (click)="selectCarType(obj)">
                        <div class="col-auto ml-4">
                            <img src="assets/images/prefer_fav.svg" alt="ride_type_icon" />
                            &nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col mt-1 item-label p-0">{{ obj.car_name }}</div>
                        <div class="col-auto item-label mr-4">
                            <ng-container *ngIf="!fareEstimatePriceLoading && obj?.value_regular && !mas_list_loading">
                                {{ obj?.value_regular | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                            <ng-container *ngIf="fareEstimatePriceLoading">
                                <app-loading height="12px" width="50px" borderRadius="4px" type="placeholder"></app-loading>
                            </ng-container>
                        </div>
                    </div>
                </div>
                <hr *ngIf="i !== carDriverOptions?.length - 1" />
            </ng-container>
        </div>
    </div>
</ng-template>

<ng-template #select_driver_dropdown>
    <span class="w-100" type="button" id="affiliation_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
        <div class="placeholder small">
            <div class="d-flex stop-propagation text-grey">
                <div clas="align-self-center">Select Driver</div>
                <div class="align-self-center stop-propagation mx-1 mb-1">
                    <i class="fa fa-sort-desc" aria-hidden="true" style="vertical-align: text-top"></i>
                </div>
            </div>
        </div>
    </span>
    <div
        class="p-0 overflow-auto dropdown-menu auto cd-menu first card-shadow"
        style="width: 300px !important; max-height: 300px !important"
        id="trip_status_menu"
        aria-labelledby="affiliation_menu"
    >
        <div class="px-2 py-3 search-box">
            <span>
                <input
                    id="select_driver_dropdown_from_queued"
                    [formControl]="driverSearchControl"
                    stop-propagation
                    class="m-0 border-0"
                    style="width: 86%"
                    type="text"
                    placeholder=" Search Drivers"
                />
                <i class="fa fa-search" aria-hidden="true"></i>
            </span>
        </div>
        <div style="max-height: 150px" class="overflow-auto">
            <ng-container *ngIf="!driverLoading">
                <div
                    *ngFor="let driver of drivers"
                    class="dropdown-item fs-13 d-flex justify-content-between cursor-pointer"
                    (click)="assign_mas_driver(driver)"
                >
                    <div class="text-dark-blue">{{ driver.driver_name }}</div>
                    <div
                        class="cursor-pointer alert-driver"
                        [class.disable-icon]="recent_driver_alert_logs?.includes('q:' + rideInFocus?.mas_trip_id + ':' + driver?.driver_id)"
                        (click)="$event.stopPropagation(); send_driver_alert(rideInFocus?.mas_trip_id, driver?.driver_id)"
                    >
                        <img src="assets/images/send_arrow.svg" alt="Alert Driver" />
                    </div>
                </div>
            </ng-container>

            <div *ngIf="driverLoading" class="mx-2">
                <app-loading *ngFor="let item of [0, 1, 2, 3, 4, 5]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #assign_queue_more_action>
    <label class="user_type l1">More Action</label>
    <br />
    <label class="user_name l2 text-break">
        <div class="cursor-pointer my-1" (click)="getDataAndshowPopup({ healthCheck: false, loading: true, skip: false })">
            Check Fare Estimate
            <span *ngIf="!third_party_enabled" class="mr-2">: {{ booking?.estimated_fare | currency: 'USD' }}</span>
            <i class="fa fa-caret-right" aria-hidden="true"></i>
            <span *ngIf="rideInFocus?.paid == 1 && !third_party_enabled" class="paid_label ml-2">PAID</span>
        </div>
        <div class="cursor-pointer my-1" (click)="send_payment_link_to_rider(rideInFocus, 'base')">
            Send Payment Link (Base)
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </div>
        <div class="cursor-pointer my-1" (click)="send_payment_link_to_rider(rideInFocus, 'agent')">
            Send Payment Link (Agent)
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </div>
        <div class="cursor-pointer my-1" (click)="get_payment_link(rideInFocus?.mas_trip_id)">
            View Payment Link
            <i class="fa fa-caret-right" aria-hidden="true"></i>
        </div>
    </label>
</ng-template>

<ng-template #mobileAddRideRequest>
    <div class="middle-bar m-0 mobile-view-queued" [ngClass]="{ mobileDesktop: !isMobileScreen || activeMobileMenu == 'viewMap' }">
        <div class="nr-section no-select">
            <ng-container *ngIf="activeMobileMenu == 'addRide'">
                <div class="my-3 py-2">
                    <div class="row m-1" style="position: relative">
                        <div class="col-auto ml-1">
                            <i class="fa fa-user" style="color: grey"></i>
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box mt-1 mb-2">
                            <input
                                id="userName"
                                [(ngModel)]="booking.user_name"
                                type="search"
                                onkeypress="return /[a-z ]/i.test(event.key)"
                                (focus)="getNewUsers(1)"
                                [ngModelOptions]="{ debounce: 400 }"
                                class="autocompleteClass"
                                autocomplete="off"
                                (blur)="closeAC()"
                                (keyup.escape)="closeAC(1)"
                                (ngModelChange)="replaceNumber('userName'); getNewUsers(1)"
                            />

                            <label for="userName" [class.active-input-state]="booking.user_name">Passenger Name</label>
                        </div>
                        <div class="myautocomplete card-shadow" [ngClass]="{ hidden: closeAutocomplete == 1 }">
                            <div class="name-list">
                                <div
                                    class="name-item mt-1"
                                    *ngFor="let user of Users | StringFilter: 'user_name':booking?.user_name?.trim(); let i = index"
                                    (click)="selectThisUser(user.user_id, user.user_mobile, user.user_name, user.user_email)"
                                >
                                    <div class="row-inline mx-1 py-2 cursor-pointer">
                                        <div class="col-auto">
                                            <i class="fa fa-user dynamic_padding" style="color: grey; font-size: 16px"></i>
                                            <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
                                        </div>
                                        <div class="col-auto input-box">
                                            <div>
                                                <span class="mr-2">
                                                    {{ user.user_name | ShortText: 8 | titlecase }}
                                                </span>
                                                <img src="assets/images/divider_blue_small.svg" alt="divider_blue" />
                                            </div>
                                        </div>
                                        <div class="col-auto my-auto">
                                            {{ user.user_mobile }}
                                        </div>
                                    </div>
                                </div>
                                <li
                                    *ngIf="Users?.length == 0 || (Users | StringFilter: 'user_name':booking?.user_name?.trim()).length === 0"
                                    class="text-center m-3"
                                >
                                    No User Found...
                                </li>
                            </div>
                            <hr style="padding: 2px; margin: 0 15px 15px" />

                            <div class="text-center my-3 mx-4" style="z-index: 3">
                                <div class="row">
                                    <!-- <div class="col-5 px-1">
                                        <button class="animate-show btn btn-dark button-black m-0" (click)="openAddUser()">
                                            <i class="fa fa-user"></i>
                                            &nbsp; Signup New User
                                        </button>
                                    </div> -->
                                    <div class="col-12 px-1">
                                        <button
                                            (click)="closeAC(); getNewUsers()"
                                            [disabled]="DisableSRR"
                                            class="animate-show btn btn-primary mobile-view-add-trip-button"
                                        >
                                            Ride Request w/out Signup &nbsp;
                                            <i class="fa fa-chevron-right"></i>
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />
                </div>

                <div class="my-3 py-2">
                    <div class="row m-1 mb-2">
                        <div class="col-auto ml-1">
                            <i class="fa fa-phone" style="color: grey"></i>
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box mt-1">
                            <div class="row-input" id="live-track-mobile-field">
                                <ngx-intl-tel-input
                                    [cssClass]="'borderless-input iphone-bg'"
                                    type="search"
                                    [resetSearchContainer]="true"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="countryToSet"
                                    [maxLength]="promoCountryCode == '+1-' ? 10 : 12"
                                    [tooltipField]="TooltipLabel.Name"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode"
                                    name="phone"
                                    [inputId]="'phone'"
                                    [formControl]="phoneNumber"
                                    [customPlaceholder]="' '"
                                    [label]="'Phone Number'"
                                    (countryChange)="onCountryChange($event)"
                                    (ngModelChange)="replaceText('phone')"
                                ></ngx-intl-tel-input>
                            </div>
                        </div>
                    </div>
                    <input type="hidden" name="extTwo" id="extPopTwo" />
                    <hr />
                </div>
            </ng-container>
            <div class="my-3 py-2">
                <div class="row m-1 mr-2">
                    <div class="col-auto ml-1">
                        <img src="assets/images/add-queue/calendar.svg" alt="divider_blue" />
                        &nbsp;&nbsp;&nbsp;
                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                    </div>
                    <div class="col input-box mt-1 mb-2">
                        <label (click)="open_time_selection_popup()">
                            {{ booking.timeInThatArea ? (booking.timeInThatArea | date: 'MMMM dd, y EEEE hh:mm a') : 'Pickup Now' }}
                        </label>
                        <label for="dateTimeMobile" class="active-input-state">Pickup Date & Time</label>
                    </div>
                </div>
                <hr />
            </div>

            <div class="my-3 py-2">
                <div class="row m-1 mr-2">
                    <div class="col-auto ml-1">
                        <img (click)="getCurrentLocation('pickup_location')" src="assets/images/add-queue/location.svg" alt="divider_blue" />
                        &nbsp;&nbsp;&nbsp;
                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                    </div>
                    <div class="col input-box mt-1 mb-2">
                        <span *ngIf="isPickupLocation" class="locationLoading">Loading...</span>
                        <input
                            id="mobile-pickup"
                            type="search"
                            class="autocomplete"
                            [(ngModel)]="booking.pickup_text"
                            (change)="showDirection(1)"
                            (blur)="findPlace('mobile-pickup')"
                            placeholder=""
                        />
                        <label for="mobile-pickup" [class.active-input-state]="booking.pickup_text">Pickup Location</label>
                    </div>
                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer">
                        <img (click)="swapLocation()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                    </div>
                </div>
                <hr />
            </div>
            <div class="my-3 py-2">
                <div class="row m-1 mr-2">
                    <div class="col-auto ml-1">
                        <img (click)="getCurrentLocation('dropoff_location')" src="assets/images/add-queue/location.svg" alt="divider_blue" />
                        &nbsp;&nbsp;&nbsp;
                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                    </div>
                    <div class="col input-box mt-1 mb-2">
                        <span *ngIf="isDropOffLocation" class="locationLoading">Loading...</span>
                        <input
                            #dropInput
                            id="mobile-drop"
                            type="search"
                            class="autocomplete"
                            [(ngModel)]="booking.drop_text"
                            (change)="showDirection(1)"
                            (blur)="findPlace('mobile-drop')"
                            placeholder=""
                        />
                        <label for="mobile-drop" [class.active-input-state]="booking.drop_text">
                            Drop off Location
                            <span class="route-option" (click)="$event.preventDefault(); openRouteOptionModal()">&nbsp; Route Option</span>
                        </label>
                    </div>
                    <div class="col-auto mr-2" class="swap-arrow cursor-pointer">
                        <img (click)="swapLocation()" src="assets/images/swap_arrow.svg" alt="SWAP" />
                    </div>
                </div>
                <hr />
            </div>

            <!-- Not in Use -->
            <div class="my-3 py-2" hidden>
                <div class="row m-1 mb-0" style="position: relative">
                    <div class="col-auto ml-1">
                        <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                        &nbsp;&nbsp;&nbsp;
                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                    </div>
                    <div class="col input-box mt-1 mb-2" style="position: static">
                        <div class="dropdown">
                            <span
                                class="dropdown-toggle"
                                type="button"
                                id="ride_type_dropdown"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                {{ carsOptions[findIndex_from_objectArray('car_type', this.booking.car_type, carsOptions)]?.car_name || 'Ride Type' }}
                            </span>
                            <div class="dropdown-menu card-shadow pt-2 pb-3" id="ride_type_menu" aria-labelledby="ride_type_dropdown">
                                <ng-container *ngFor="let obj of carsOptions; let i = index">
                                    <div class="dropdown-item p-0">
                                        <div class="row my-1 cursor-pointer p-0" (click)="selectCarType(obj)">
                                            <div class="col-auto ml-4">
                                                <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                &nbsp;&nbsp;
                                                <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                            </div>
                                            <div class="col mt-1 item-label p-0">
                                                {{ obj.car_name }}
                                            </div>
                                        </div>
                                    </div>
                                    <hr />
                                </ng-container>
                            </div>
                        </div>
                    </div>

                    <div
                        class="col-auto mr-3"
                        class="more-info cursor-pointer"
                        (click)="carSelectorMode()"
                        [class.invisible]="ipadSize && middleBarClosed"
                    >
                        <i class="fa fa-exclamation-circle"></i>
                        &nbsp;
                        <span class="no-select">More info</span>
                    </div>
                </div>
                <hr />
            </div>

            <div class="my-3 py-2">
                <div class="row m-1" style="position: relative">
                    <div class="col-auto ml-1">
                        <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                        &nbsp;&nbsp;&nbsp;
                        <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                    </div>
                    <div class="col-auto pl-0 my-auto">
                        <div class="more-info cursor-pointer" style="margin-left: -3px" (click)="carSelectorMode()">
                            <i class="fa fa-exclamation-circle text-white"></i>
                        </div>
                    </div>
                    <div class="col input-box mt-1 mb-2" style="position: static">
                        <ng-container [ngTemplateOutlet]="select_car_type_dropdown"></ng-container>
                    </div>
                    <!-- <div class="col-auto my-auto pr-0" (click)="DriverSelectorMode()" [class.invisible]="ipadSize && middleBarClosed">
                        <div class="more-info cursor-pointer">
                            <i class="fa fa-heart mr-1"></i>
                            <span class="show mr-1 no-select">Select Driver</span>
                            <i class="fa fa-chevron-right"></i>
                        </div>
                    </div> -->
                </div>
                <hr />
            </div>

            <div class="my-2 py-1" id="health_checkbox">
                <div class="row m-1">
                    <div class="col-auto ml-2 mt-2" style="width: 42px">
                        <div class="custom-checkbox" (click)="health_policy_checkbox = !health_policy_checkbox">
                            <i *ngIf="health_policy_checkbox" class="fa fa-check-square" aria-hidden="true"></i>
                            <i *ngIf="!health_policy_checkbox" class="fa fa-square-o" aria-hidden="true"></i>
                        </div>
                    </div>
                    <div class="col input-box disable-select">
                        <label class="cursor-default" (click)="health_policy_checkbox = !health_policy_checkbox">
                            I confirm to adhere to the latest CDC policies to stay informed, review health and safety measures taken about COVID-19.
                        </label>
                    </div>
                </div>
            </div>
        </div>
        <hr />
        <div class="mt-4">
            <div class="btn_cs mobile-view-add-buttons">
                <button
                    (click)="getDataAndshowPopup(); from = 'ride-request'"
                    [disabled]="DisableSRR"
                    class="animate-show btn btn-primary btn_css mr-3 mobile-view-add-trip-button"
                >
                    Dispatch Trip &nbsp;
                    <mat-icon class="send">send</mat-icon>
                </button>
                <button
                    (click)="getDataAndshowPopup({ healthCheck: true, loading: true, skip: false, addQueued: true }); from = 'home-queue'"
                    [disabled]="DisableSRR"
                    class="animate-show btn btn-dark button-black btn_css mobile-view-add-trip-button"
                >
                    Queue Trip &nbsp;
                </button>
            </div>
        </div>
    </div>
</ng-template>

<!-- QUEUED ACTION MENU -->
<ng-template #queuedAction>
    <!-- <li role="menuitem">
        <div
            class="dropdown-item blue cd-item cursor-pointer"
            (click)="update_queued_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, status: 1 })"
            *ngIf="rideInFocus?.invoice_number"
        >
            Unqueue
        </div>
    </li> -->
    <li role="menuitem" *ngIf="rideInFocus?.ride_status !== 3">
        <div
            class="dropdown-item blue cd-item cursor-pointer"
            (click)="update_queued_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, status: 1, ride_status: 5 })"
        >
            Cancel
        </div>
    </li>
    <li role="menuitem" *ngIf="rideInFocus?.ride_status !== 3">
        <div
            class="dropdown-item blue cd-item cursor-pointer"
            (click)="update_queued_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, status: 1, ride_status: 4 })"
        >
            Complete
        </div>
    </li>
    <li role="menuitem">
        <div
            class="dropdown-item blue cd-item cursor-pointer"
            (click)="update_queued_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, ride_confirmed: rideInFocus?.ride_confirmed ? 0 : 1 }, true)"
        >
            <ng-container *ngIf="rideInFocus?.ride_confirmed">Unconfirm Rider</ng-container>
            <ng-container *ngIf="!rideInFocus?.ride_confirmed">Confirm Rider</ng-container>
        </div>
    </li>
</ng-template>

<!-- ASSIGNED ACTION MENU -->
<ng-template #assignedAction>
    <li role="menuitem">
        <div
            class="dropdown-item blue cd-item cursor-pointer"
            (click)="update_assigned_trip(rideInFocus, {}, rideInFocus?.invoice_number ? 'unassign' : 'queuing back')"
        >
            <ng-container *ngIf="rideInFocus?.invoice_number">Unassign</ng-container>
            <ng-container *ngIf="!rideInFocus?.invoice_number">Queue Back</ng-container>
        </div>
    </li>
    <li role="menuitem" *ngIf="rideInFocus?.ride_status !== 3">
        <ng-container *ngIf="rideInFocus?.ride_status === 5">
            <div class="dropdown-item blue cd-item cursor-pointer" (click)="handleUnCancelTrip(rideInFocus)">Uncancel</div>
        </ng-container>
        <ng-container *ngIf="rideInFocus?.ride_status !== 5">
            <div
                class="dropdown-item blue cd-item cursor-pointer"
                (click)="update_assign_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, ride_status: 5 })"
            >
                Cancel
            </div>
        </ng-container>
    </li>
    <li role="menuitem" *ngIf="rideInFocus?.ride_status !== 3">
        <ng-container *ngIf="rideInFocus?.ride_status === 4">
            <div class="dropdown-item blue cd-item cursor-pointer" (click)="update_assigned_trip(rideInFocus, {}, 'unassign', 'uncompleting')">
                UnComplete
            </div>
        </ng-container>
        <ng-container *ngIf="rideInFocus?.ride_status !== 4">
            <div
                class="dropdown-item blue cd-item cursor-pointer"
                (click)="update_assign_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, ride_status: 4 })"
            >
                Complete
            </div>
        </ng-container>
    </li>
    <li role="menuitem">
        <div
            class="dropdown-item blue cd-item cursor-pointer"
            (click)="update_assign_mas_trip({ mas_trip_id: rideInFocus?.mas_trip_id, ride_confirmed: rideInFocus?.ride_confirmed ? 0 : 1 }, true)"
        >
            <ng-container *ngIf="rideInFocus?.ride_confirmed">Unconfirm Rider</ng-container>
            <ng-container *ngIf="!rideInFocus?.ride_confirmed">Confirm Rider</ng-container>
        </div>
    </li>
</ng-template>
