export interface Notification {
    alert_id: number | string;
    session_id: number | string;
    message: string;
    flag: number | string;
    is_read: number | string;
    multi_date: any;
    subject: string;
    created_at: Date | string;
    type?: string;
    identifier?: number | string;
    metadata?: any;
    pickup_date?: Date | any;
}

export enum NotificationType {
    MAS_QUEUE = 'mas_queue_action',
    MAS_QUEUE_LIVE_ACTION = 'mas_queue_live_action',
    MAS_ASSIGN = 'mas_assign_action',
    DISPATCHED_NOW = 'dispatched-now',
    CANCEL_RIDE_REQUEST = 'canceled_ride_request',
    NO_AVAILABLE_DRIVER = 'no_available_driver',
}

/**
 * ------------------ response demo ------------------
 * {
    "alert_id": 44222,
    "session_id": 44424,
    "message": "There are no available driver to accept your scheduled ride for Harp Farmer on Nov 13, 2021 (Saturday) 12:57 PM with pickup location at New York, NY, USA . Please try again with another driver. ",
    "flag": 1,
    "is_read": 1,
    "multi_date": null,
    "subject": "Lapsed Schedule Ride Request",
    "created_at": "2021-11-13T09:27:50.000Z"
}
 */
