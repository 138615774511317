// capitalize-with-spaces.pipe.ts

import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'capitalizeWithSpaces',
})
export class CapitalizeWithSpacesPipe implements PipeTransform {
    transform(value: string): string {
        // Replace underscores with spaces
        let result = value.replace(/_/g, ' ');

        // Uppercase the first letter of each word
        result = result.replace(/\b\w/g, (match) => match.toUpperCase());

        return result;
    }
}
