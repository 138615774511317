<div class="modal-dialog touch-popup fare-breakdown-modal" role="document">
    <div class="modal-content shadow-sm border-0" style="border-radius: 10px">
        <div class="touch-header pt-1 t-margin">
            <div class="d-flex justify-content-between mt-3 mb-2">
                <div class="medium-text dark-color align-self-center">
                    <div class="p-0 pb-1 my-0">Fare Breakdown</div>
                </div>
                <div class="d-flex justify-content-end pb-1">
                    <div class="mr-2 my-0 align-self-center">
                        <img src="assets/images/divider_black.svg" alt="divider" />
                    </div>
                    <div class="mx-3 my-0 mr-auto align-self-center text-center">
                        <div class="medium-text blue-color">
                            $
                            {{
                                (active_fare_breakdown_flag === 'now' ? fare_breakdown?.value_regular : fare_breakdown?.value_scheduled)
                                    | number: '1.2-2'
                            }}
                        </div>
                        <div class="deducted-price dark-color" *ngIf="fare_breakdown?.discount">
                            $
                            {{
                                (active_fare_breakdown_flag === 'now'
                                    ? fare_breakdown?.regular_ride_without_discount
                                    : fare_breakdown?.schedule_ride_without_discount
                                ) | number: '1.2-2'
                            }}
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="light" />
        <div class="touch-content t-margin my-4">
            <div class="estimation-detail">
                Your estimated fare will be displayed before the trip. Adjustments are made if actual route has toll or subject to government mandated
                fees and taxes that differs from the estimated routes. Fares are based on rates below and other applicable tolls, surcharges,
                adjustments and fees
            </div>

            <!-- estimate-table -->
            <div class="my-4 estimation-list dark-color">
                <div class="my-3 d-flex justify-content-between" *ngIf="fare_breakdown?.discount && fare_breakdown?.discount != 0">
                    <div>Ride discounts applied</div>
                    <!-- <div>$ 2.00</div> -->
                    <div *ngIf="fare_breakdown?.discount">$ {{ fare_breakdown?.discount | number: '1.2-2' }}</div>
                </div>

                <div class="my-4">
                    <div
                        class="d-flex justify-content-between"
                        *ngIf="
                            (active_fare_breakdown_flag === 'now' && fare_breakdown?.qudos_service_fee != 0) ||
                            (active_fare_breakdown_flag === 'later' && fare_breakdown?.qudos_service_fee_schedule != 0)
                        "
                    >
                        <div>Qudos service fee</div>
                        <div>
                            $
                            {{
                                (active_fare_breakdown_flag == 'now' ? fare_breakdown?.qudos_service_fee : fare_breakdown?.qudos_service_fee_schedule)
                                    | number: '1.2-2'
                            }}
                        </div>
                    </div>
                    <!-- <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.base_fare != 0">
                        <div>Base fare</div>
                        <div>$ {{ fare_breakdown?.base_fare | number: '1.2-2' }}</div>
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.estimated_fare_distance != 0">
                        <div>Estimated distance fare</div>
                        <div>$ {{ fare_breakdown?.estimated_fare_distance | number: '1.2-2' }}</div>
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.estimated_fare_time != 0">
                        <div>Estimated time fare</div>
                        <div>$ {{ fare_breakdown?.estimated_fare_time | number: '1.2-2' }}</div>
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.estimated_tolls != 0">
                        <div>Estimated Tolls</div>
                        <div>$ {{ fare_breakdown?.estimated_tolls | number: '1.2-2' }}</div>
                    </div> -->

                    <div class="d-flex justify-content-between">
                        <div>Booking & Car Service Fee</div>
                        <!-- fare_breakdown?.corporate_addon -->
                        <div>
                            $
                            {{
                                fare_breakdown?.estimated_fare_distance + fare_breakdown?.estimated_fare_time + fare_breakdown?.base_fare
                                    | number: '1.2-2'
                            }}
                        </div>
                    </div>

                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.congestionCharge != 0">
                        <div>Congestion Tax</div>
                        <div>$ {{ fare_breakdown?.congestionCharge | number: '1.2-2' }}</div>
                    </div>
                </div>

                <div class="my-4">
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.sales_tax != 0">
                        <div>Sales tax</div>
                        <div>$ {{ fare_breakdown?.sales_tax | number: '1.2-2' }}</div>
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.black_car_fund != 0">
                        <div>Black car fund</div>
                        <div>$ {{ fare_breakdown?.black_car_fund | number: '1.2-2' }}</div>
                    </div>
                    <div class="d-flex justify-content-between">
                        <div>Toll</div>
                        <div>$ {{ fare_breakdown?.estimated_tolls | number: '1.2-2' }}</div>
                    </div>
                    <div
                        class="d-flex justify-content-between"
                        *ngIf="fare_breakdown?.estimated_surcharges != 0 && active_fare_breakdown_flag !== 'now'"
                    >
                        <div>Estimated surcharges</div>
                        <div>$ {{ fare_breakdown?.estimated_surcharges | number: '1.2-2' }}</div>
                    </div>
                </div>

                <div class="my-4">
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.pet_friendly_car != 0">
                        <div>Pet friendly fee</div>
                        <div>$ {{ fare_breakdown?.pet_friendly_car | number: '1.2-2' }}</div>
                    </div>
                    <div class="d-flex justify-content-between" *ngIf="fare_breakdown?.through_door_service != 0">
                        <div>Through the door fee</div>
                        <div>$ {{ fare_breakdown?.through_door_service | number: '1.2-2' }}</div>
                    </div>
                </div>
            </div>

            <div class="mb-4" class="estimation-detail">
                Additional wait times charges may apply to your trip if the driver has waited more than the minimum wait time.
            </div>
        </div>
        <hr class="light" />

        <div class="touch-bottom t-margin my-4">
            <div class="d-flex justify-content-around">
                <div class="cursor-pointer blue-color" (click)="triggerEvent('cancel')">Back</div>

                <ng-container *ngIf="active_fare_breakdown_flag === 'now'">
                    <div class="cursor-pointer blue-color" (click)="triggerEvent('book-ride')">Send the ride now</div>
                </ng-container>
                <ng-container *ngIf="active_fare_breakdown_flag === 'later'">
                    <div class="cursor-pointer blue-color" (click)="triggerEvent()">Book the ride</div>
                </ng-container>
            </div>
        </div>
    </div>
</div>
