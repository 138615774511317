<div class="d-flex mobile-responsive">
    <div class="d-flex overview-box py-md-1 px-md-3 br-6 p-4" *ngIf="!hideRiderAnalytics">
        <div class="d-md-none fs-16 fw-600">Assigned Info</div>
        <div class="btn-group" dropdown>
            <div id="button-basic" dropdownToggle class="dropdown-toggle cursor-pointer d-flex align-items-center" aria-controls="dropdown-basic">
                <div>
                    <mat-date-range-input
                        class="fs-9"
                        style="width: 0px; height: 0px; visibility: hidden; position: relative; top: 15px"
                        [formGroup]="date_range_form"
                        [rangePicker]="customDateRange"
                    >
                        <input matStartDate formControlName="start" placeholder="Start date" style="width: 0px; height: 0px; visibility: hidden" />
                        <input matEndDate formControlName="end" placeholder="End date" style="width: 0px; height: 0px; visibility: hidden" />
                    </mat-date-range-input>
                    <mat-date-range-picker #customDateRange></mat-date-range-picker>
                </div>
                <div>
                    {{ view_type }} - (
                    <ng-container [ngTemplateOutlet]="date_range"></ng-container>
                    )
                </div>
                <div class="caret"></div>
            </div>
            <ul
                id="dropdown-basic"
                *dropdownMenu
                class="dropdown-menu cd-menu first card-shadow left-0 mt-1"
                role="menu"
                aria-labelledby="button-basic"
            >
                <div
                    *ngFor="let item of view_type_list"
                    class="dropdown-item blue cd-item cursor-pointer"
                    (click)="update_rider_analytics(item, customDateRange)"
                >
                    {{ item }}
                </div>
            </ul>
        </div>
        <div class="pipe">|</div>
        <div>
            Gross Pay:
            <ng-container *ngIf="!show_gross_payout">--</ng-container>
            <ng-container *ngIf="show_gross_payout">
                {{ show_gross_payout | currency: 'USD':'symbol':utilityService.show_currency_precision }}
            </ng-container>
        </div>
        <div class="pipe">|</div>
        <div>Rides: {{ show_ride || 0 }}</div>
        <div class="pipe">|</div>
        <div>
            Driver Pay:
            <ng-container *ngIf="!show_driver_payout">--</ng-container>
            <ng-container *ngIf="show_driver_payout">
                {{ show_driver_payout | currency: 'USD':'symbol':utilityService.show_currency_precision }}
            </ng-container>
        </div>
    </div>
    <hr class="my-0 border-0 d-md-none" *ngIf="!hidePrepaidLabel && !hidePrepaidLabel" />
    <div class="d-inline d-flex overview-box bg-green py-md-1 px-md-3 br-6 ml-md-2 p-4" *ngIf="!hidePrepaidLabel">
        <div class="d-md-none fs-16 fw-600">Prepaid Info</div>
        <div>
            Prepaid Total:
            <span class="ml-1 fw-600">
                <span *ngIf="ride_analytics?.daily_prepaid_total">
                    {{ ride_analytics?.daily_prepaid_total | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                </span>
                <span *ngIf="!ride_analytics?.daily_prepaid_total">--</span>
            </span>
        </div>

        <div class="mx-1 pipe">|</div>
        <div class="fw-600">
            <span class="d-md-none">Prepaid</span>
            Trips: {{ ride_analytics?.total_prepaid_ride || '--' }}
        </div>
    </div>
</div>

<ng-template #date_range>
    {{ date_range_form?.value?.start | date: 'MMM d' }}
    <ng-container *ngIf="view_type !== 'Daily'">- {{ date_range_form?.value?.end | date: 'MMM d' }}</ng-container>
</ng-template>
