<div class="page-header" [class.pt-0]="!config?.headerTitle">
    <div *ngIf="config?.headerTitle">
        <div class="top_head d-flex justify-content-between align-items-start">
            <!-- Header Title -->
            <div class="d-flex">
                <h2 class="mb-0">{{ config?.headerTitle }}</h2>
                <div
                    *ngIf="config?.showStartSession"
                    class="ml-2 cursor-pointer session-icon tool"
                    data-tip="Start Session"
                    (click)="start_mas_session()"
                >
                    <img src="assets/images/power_settings.svg" />
                </div>
            </div>

            <div class="top_right">
                <div class="mobile-view">
                    <div
                        *ngIf="config?.search"
                        class="cursor-pointer mobile-icon-btn square-35 round"
                        [class.active]="searchControl?.value"
                        [popover]="search_popover"
                        #searchPopover="bs-popover"
                        containerClass="br-6 z-index-1000 mr-3 w-260"
                        placement="bottom"
                        [outsideClick]="true"
                        (onShown)="searchPopoverReference = searchPopover"
                    >
                        <img src="assets/images/search.svg" />
                    </div>
                    <div
                        *ngIf="config?.moreOptionList && config?.moreOptionList?.length"
                        class="cursor-pointer mobile-icon-btn square-35 ml-2 round"
                        [popover]="more_option_popover"
                        #moreOptionPopover="bs-popover"
                        containerClass="br-6 z-index-1000 mr-3 w-350"
                        placement="bottom"
                        (onShown)="moreOptionPopoverReference = moreOptionPopover"
                    >
                        <img src="assets/images/more_option.svg" />
                    </div>
                </div>
                <div class="mobile-none">
                    <ng-container [ngTemplateOutlet]="optionMenu"></ng-container>
                </div>
            </div>
        </div>

        <div class="text-dark-blue" *ngIf="config?.headerSubTitle">
            <h4 class="fw-normal">{{ config?.headerSubTitle }}</h4>
        </div>

        <div class="mt-4">
            <ng-container *ngIf="config?.tabs">
                <ng-container [ngTemplateOutlet]="tabView"></ng-container>
            </ng-container>

            <ng-container *ngIf="preventTabView">
                <ng-content select="[tab_view_replacement]"></ng-content>
            </ng-container>
        </div>
    </div>
</div>

<ng-template #tabView>
    <div class="touch-tabs d-flex no-scrollbar w-100 overflow-auto">
        <div
            *ngFor="let item of config?.tabs?.items"
            [class.active]="item.title == config?.tabs?.activeTab"
            [class.transition-none]="!this.isDesktop"
            class="tab mr-3"
            (click)="changeTab(item)"
        >
            {{ item.title }}
        </div>
    </div>
    <hr class="my-0 py-0 -ml-40 light-color" *ngIf="config?.subTabs" />
    <div class="d-flex justify-content-between">
        <div class="touch-tabs d-flex mt-3 no-scrollbar w-100 subtabs" *ngIf="config?.subTabs">
            <div
                *ngFor="let item of config?.subTabs?.items"
                [class.active]="item.title === config?.subTabs?.activeSubTab"
                [class.transition-none]="!this.isDesktop"
                class="tab mr-3"
            >
                <div class="d-flex">
                    <div (click)="onSubTabChange(item)">{{ item.title }}</div>
                    <div><ng-content *ngIf="item.suffix" [select]="item.suffix_content_selector"></ng-content></div>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #optionMenu>
    <div class="d-flex more-option">
        <ng-container *ngFor="let item of config?.moreOptionList">
            <div *ngIf="item.type === 'BUTTON'">
                <a *ngIf="!item.cssClass" class="btn btn-outline-primary btn-md tripsbtn exportBtn" (click)="emitEvent(item)">{{ item?.text }}</a>
                <button *ngIf="item.cssClass" [disabled]="item.disabled" [ngClass]="item.cssClass" (click)="emitEvent(item)">{{ item?.text }}</button>
            </div>

            <div *ngIf="item.type === 'DROPDOWN'">
                <div>
                    <a class="btn btn-outline-primary btn-md tripsbtn exportBtn" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                        <span class="dropdown-toggle">
                            <span class="light-weight">{{ item?.text }}</span>
                            :
                            <span class="label">
                                <ng-container *ngIf="item?.value">
                                    <ng-container *ngIf="item.optionType === 'object'">{{ item?.value[item?.optionKey] || '-' }}</ng-container>
                                    <ng-container *ngIf="!item.optionType || item.optionType === 'string'">{{ item.value | titlecase }}</ng-container>
                                </ng-container>
                                <ng-container *ngIf="!item?.value">All</ng-container>
                            </span>
                            <i
                                *ngIf="item.value"
                                (click)="$event.stopPropagation(); item.value = null; emitEvent(item)"
                                class="ml-1 fa fa-times"
                                aria-hidden="true"
                            ></i>
                        </span>
                    </a>
                    <div class="dropdown-menu cd-menu first card-shadow" aria-labelledby="download">
                        <div
                            *ngFor="let option of item.options"
                            class="dropdown-item cd-item cursor-pointer"
                            (click)="item.value = option; emitEvent(item)"
                        >
                            <ng-container *ngIf="item.optionType === 'object'">{{ option[item?.optionKey] || '-' }}</ng-container>
                            <ng-container *ngIf="!item.optionType || item.optionType === 'string'">{{ option | titlecase }}</ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div *ngIf="item.type === 'NORMAL_DATE'">
                <a class="btn btn-outline-primary btn-md tripsbtn exportBtn" (click)="filterDatePicker.open()">
                    <span class="light-weight">Filter</span>
                    :
                    <span *ngIf="!item.form?.value" class="label">Date</span>
                    <span *ngIf="item.form?.value" class="label">{{ item.form?.value | date: 'd/M/y' }}</span>
                    <span>
                        <input
                            [max]="maxDate"
                            [formControl]="item.form"
                            readonly
                            placeholder="Date"
                            [matDatepicker]="filterDatePicker"
                            class="border-0 cursor-pointer label date-picker"
                        />
                        <mat-datepicker #filterDatePicker></mat-datepicker>
                        <i *ngIf="item.form?.value" (click)="item.form.reset()" class="ml-1 fa fa-times" aria-hidden="true"></i>
                    </span>
                </a>
            </div>
            <div *ngIf="item.type === 'RANGE_DATE'">
                <a class="btn btn-outline-primary btn-md tripsbtn exportBtn position-relative" (click)="filterRangeDatePicker.open()">
                    <span class="light-weight">Filter</span>
                    :
                    <span *ngIf="!item.form?.value?.start || !item.form?.value?.end" class="label">Date</span>
                    <span *ngIf="item.form?.value?.start && item.form?.value?.end" class="label">
                        {{ item.form?.value?.start | date: 'MMM d, y' }} - {{ item.form?.value?.end | date: 'MMM d, y' }}

                        <i (click)="$event.stopPropagation(); item.form?.reset()" class="ml-1 fa fa-times text-grey fw-600" aria-hidden="true"></i>
                    </span>
                    <span>
                        <mat-date-range-input
                            [max]="maxDate"
                            class="fs-9"
                            style="width: 0px; height: 0px; visibility: hidden; position: absolute; left: 0"
                            [formGroup]="item.form"
                            [rangePicker]="filterRangeDatePicker"
                        >
                            <input matStartDate formControlName="start" placeholder="Start date" />
                            <input matEndDate formControlName="end" placeholder="End date" />
                        </mat-date-range-input>
                        <mat-date-range-picker #filterRangeDatePicker></mat-date-range-picker>
                    </span>
                </a>
            </div>
        </ng-container>

        <div class="search_input mobile-none" *ngIf="config?.search">
            <input type="search" class="form-control" placeholder="Search" [formControl]="config?.search?.form" />
        </div>
    </div>
</ng-template>

<ng-template #search_popover>
    <div class="touch-popover p-2">
        <div>
            <input *ngIf="config?.search" class="form-control large_search_input" placeholder="Search" [formControl]="config?.search?.form" />
        </div>
        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2">
            <div
                [class.disabled]="!config?.search?.form?.value"
                class="cursor-pointer"
                (click)="config?.search?.form.reset(); searchPopoverReference.hide()"
            >
                Clear
            </div>
            <div class="cursor-pointer" (click)="searchPopoverReference.hide()">Okay</div>
        </div>
    </div>
</ng-template>

<ng-template #more_option_popover>
    <div class="touch-popover">
        <div class="p-4">
            <div class="text-dark-blue fs-16 mb-2 fw-600">More Options</div>
            <hr class="my-0 border-0" />
            <div>
                <ng-container [ngTemplateOutlet]="optionMenu"></ng-container>
            </div>
        </div>
        <hr class="my-0 border-0" />
        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2">
            <div class="cursor-pointer" (click)="moreOptionPopoverReference.hide()">Close</div>
        </div>
    </div>
</ng-template>
