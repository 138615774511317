export const SettingHeaderConfig = {
    network: {
        rider: [
            {
                title: 'My Riders',
                url: '/corporate/riders/my-riders',
            },
            {
                title: 'Explore',
                url: '/corporate/riders/all-riders',
            },
        ],
        qudosfave: [
            {
                // title: 'My QudosFave',
                title: 'My Drivers',
                url: '/corporate/drivers/my-drivers',
            },
            {
                title: 'Explore',
                url: '/corporate/drivers/all-drivers',
            },
        ],

        qudos_fave_mas: [
            {
                title: 'All Drivers',
                url: '/corporate/drivers/all-drivers?tab=all',
            },
            {
                title: 'Non-MAS Drivers',
                url: '/corporate/drivers/all-drivers?tab=non-mas',
            },
            {
                title: 'MAS Drivers',
                url: '/corporate/drivers/all-drivers?tab=mas',
            },
        ],
    },
    settings: [
        {
            title: 'My Cards',
            url: '/corporate/cards',
        },
        {
            title: 'My Documents',
            url: '/corporate/documents',
        },
        {
            title: 'My Staff',
            url: '/corporate/staff',
        },
        // {
        //     title: 'Ride Settings',
        //     url: '/corporate/ride-settings',
        // },
        {
            title: 'More',
            url: '/corporate/more-settings',
        },
    ],
    business_center: [
        {
            title: 'Overview',
            url: '/corporate/business-center/overview',
        },
        {
            title: 'Transaction History',
            url: '/corporate/business-center/transaction-history',
        },
        {
            title: 'Balance',
            url: '/corporate/business-center/balance',
        },
        {
            title: 'Top-Ups',
            url: '/corporate/business-center/topups',
        },
        {
            title: 'Payment Method',
            url: '/corporate/business-center/payment-method',
        },
    ],
    notification: [
        {
            title: 'Notifications',
            url: '/corporate/notifications/list',
        },
        {
            title: 'Notification Settings',
            url: '/corporate/notifications/settings',
        },
    ],
    trips: {
        tabs: [
            { title: 'On-Demand Rides', url: '' }, // url must be handled by events, because of routing complexity, require refactoring
            { title: 'Scheduled Rides', url: '' }, // url must be handled by events, because of routing complexity, require refactoring
        ],
        subtabs: {
            trips_ongoing: [
                {
                    title: 'Completed trips',
                    url: '/corporate/completed-trips',
                },
                {
                    title: 'Cancelled trips',
                    url: '/corporate/cancelled-trips',
                },
                {
                    title: 'Ongoing trips',
                    url: '/corporate/scheduled-trips',
                },
            ],
            trips_upcoming: [
                {
                    title: 'Completed trips',
                    url: '/corporate/completed-trips',
                },
                {
                    title: 'Cancelled trips',
                    url: '/corporate/cancelled-trips',
                },
                {
                    title: 'Upcoming trips',
                    url: '/corporate/scheduled-trips',
                },
            ],
        },
    },
    trips_ongoing: [
        {
            title: 'Completed trips',
            url: '/corporate/completed-trips',
        },
        {
            title: 'Cancelled trips',
            url: '/corporate/cancelled-trips',
        },
        {
            title: 'Ongoing trips',
            url: '/corporate/scheduled-trips',
        },
    ],
    trips_upcoming: [
        {
            title: 'Completed trips',
            url: '/corporate/completed-trips',
        },
        {
            title: 'Cancelled trips',
            url: '/corporate/cancelled-trips',
        },
        {
            title: 'Upcoming trips',
            url: '/corporate/scheduled-trips',
        },
    ],
    attestation: {
        tabs: [
            {
                title: 'All',
                url: '/corporate/attestation/all',
            },
            {
                title: 'Dispatched',
                url: '/corporate/attestation/dispatched',
            },
            {
                title: 'Assigned',
                url: '/corporate/attestation/assigned',
            },
        ],
        sub_tabs: {
            Assigned: [
                {
                    title: 'All Day',
                    url: '/corporate/attestation/assigned',
                },
                {
                    title: 'Active',
                    url: '/corporate/attestation/assigned',
                },
                // {
                //     title: 'Past Only',
                //     url: '/corporate/attestation/assigned',
                // },
            ],
        },
    },
    active_trips: {
        tabs: [
            {
                title: 'Unassigned',
                url: '/corporate/active-trips/unassigned',
            },
            {
                title: 'Queued',
                url: '/corporate/active-trips/queued',
            },
            {
                title: 'Assigned',
                url: '/corporate/active-trips/assigned',
            },
            {
                title: 'All Trips',
                url: '/corporate/active-trips/all-trips',
            },
            {
                title: 'Dispatched',
                url: '/corporate/active-trips/dispatched',
            },
            {
                title: 'Completed',
                url: '/corporate/active-trips/completed',
            },
        ],

        sub_tabs: {
            Completed: [
                {
                    title: 'All Trips',
                    url: '/corporate/active-trips/completed',
                },
                {
                    title: 'Scheduled Trips',
                    url: '/corporate/active-trips/completed/scheduled',
                },
                {
                    title: 'On-Demand Trips',
                    url: '/corporate/active-trips/completed/on-demand',
                },
            ],
            Dispatched: [
                {
                    title: 'All Dispatched',
                    url: '/corporate/active-trips/dispatched',
                },
                {
                    title: 'Scheduled',
                    url: '/corporate/active-trips/dispatched',
                },
                {
                    title: 'On-Demand',
                    url: '/corporate/active-trips/dispatched',
                },
            ],
            Assigned: [
                {
                    title: 'All Day',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Active',
                    url: '/corporate/active-trips/assigned',
                },
                // {
                //     title: 'Past Only',
                //     url: '/corporate/active-trips/assigned',
                // },
                {
                    title: 'Completed',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Confirmed',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Cancelled',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Pending',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Dispatched',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Manual',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Prepaid',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Live',
                    url: '/corporate/active-trips/assigned',
                },
            ],
            All_Trips: [
                {
                    title: 'All Day',
                    url: '/corporate/active-trips/assigned',
                },
                // {
                //     title: 'Active',
                //     url: '/corporate/active-trips/assigned',
                // },
                {
                    title: 'Confirmed',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Interested',
                    url: '/corporate/active-trips/assigned',
                },
                {
                    title: 'Live',
                    url: '/corporate/active-trips/queued',
                    suffix: true,
                    suffix_content_selector: '[all_live_trip_suffix]',
                },
            ],
            Queued: [
                {
                    title: 'All Day',
                    url: '/corporate/active-trips/queued',
                },
                {
                    title: 'Active',
                    url: '/corporate/active-trips/queued',
                },
                // {
                //     title: 'Past Only',
                //     url: '/corporate/active-trips/queued',
                // },
                {
                    title: 'Confirmed',
                    url: '/corporate/active-trips/queued',
                },
                {
                    title: 'Interested',
                    url: '/corporate/active-trips/queued',
                },
                {
                    title: 'Dispatched',
                    url: '/corporate/active-trips/queued',
                },
                {
                    title: 'Manual',
                    url: '/corporate/active-trips/queued',
                },
                {
                    title: 'Prepaid',
                    url: '/corporate/active-trips/queued',
                },
                {
                    title: 'Live',
                    url: '/corporate/active-trips/queued',
                    suffix: true,
                    suffix_content_selector: '[queued_live_trip_suffix]',
                },
            ],
            Unassigned: [
                {
                    title: 'All Day',
                    url: '/corporate/active-trips/unassigned',
                },
                {
                    title: 'Active',
                    url: '/corporate/active-trips/unassigned',
                },
                // {
                //     title: 'Past Only',
                //     url: '/corporate/active-trips/unassigned',
                // },
                {
                    title: 'Initial',
                    url: '/corporate/active-trips/unassigned',
                },
                {
                    title: 'Cancelled',
                    url: '/corporate/active-trips/unassigned',
                },
            ],
        },
    },
    reports: {
        tlc_rides: [{ title: 'All Trips', url: '/corporate/reports/reports/tlc-rides-report' }],
    },
    transaction_history: {
        transaction_type: [
            {
                title: 'All Trips',
                url: '/corporate/transaction-history-all',
            },
            {
                title: 'Paid',
                url: '/corporate/transaction-history-paid',
            },
            {
                title: 'Unpaid',
                url: '/corporate/transaction-history-unpaid',
            },
        ],
    },
};
