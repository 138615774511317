import { Component, EventEmitter, OnInit, TemplateRef } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import * as StaticDataConfig from './../../../core/services/utility/static-data.config';

@Component({
    selector: 'app-premium-options',
    templateUrl: './premium-options.component.html',
    styleUrls: ['./premium-options.component.scss'],
})
export class PremiumOptionsComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef, private modalService: BsModalService, private utilityService: UtilityService) {}

    public event: EventEmitter<any> = new EventEmitter();
    premiumOption: FormGroup = new FormGroup({
        pet_friendly_car: new FormControl(false),
        plexiglass_partition: new FormControl(false),
        through_door_service: new FormControl(false),
    });
    premiumOption_Backup: any;
    activeOption: string;
    premium_option_confirmation = StaticDataConfig.PremiumOptionConfirmation;
    // premiumOptionLabel: string;
    confirm_modal_ref: BsModalRef;

    ngOnInit(): void {}

    // triggerEvent() {
    //   this.bsModalRef.hide();
    //   this.event.emit();
    // }

    updateOption() {
        this.event.emit(this.premiumOption.value);
        this.bsModalRef.hide();
    }

    open_confirmation_popup(template: TemplateRef<any>, option: 'plexiglass_partition' | 'through_door_service' | 'pet_friendly_car') {
        this.activeOption = option;
        this.confirm_modal_ref = this.modalService.show(template, { id: Date.now(), ...this.utilityService.ngxModalOption });
    }

    confirmPremiumOptionAction() {
        this.premiumOption.get(this.activeOption).patchValue(true);
        this.confirm_modal_ref.hide();
    }
}
