<section class="sign" style="padding-bottom: 20px; padding: 20px 0; margin-top: 0; padding: 0; padding-bottom: 70px">
    <div class="container">
        <div class="row">
            <div class="col-12 col-xl-10 offset-xl-1">
                <div class="sign__content page">
                    <!--Start Row Sign Up-->
                    <div class="row">
                        <div class="popup-main-content form-icon">
                            <!--Start Sign up Step 1-->
                            <div class="row row-input">
                                <div class="col-md-12 pull-right text-right mb-4">
                                    <button class="bak-buton pull-right text-right" [routerLink]="['/', 'corporate', 'riders', 'my-riders']">
                                        Back
                                    </button>
                                </div>
                                <!-- ui-sref="corporate.myUsers" -->
                                <div id="main-hidden" class="col-md-5">
                                    <h3>Rider Sign Up</h3>
                                    <ul id="ny" class="signup-step">
                                        <li id="li-step1" class="active"><a id="1-step"></a></li>
                                        <li id="li-step2"><a id="2-step"></a></li>
                                    </ul>
                                    <ul id="no-ny" class="signup-step hidden">
                                        <li id="li-step4"><a id="4-step"></a></li>
                                        <li id="li-step5" class="active"><a id="5-step"></a></li>
                                        <li id="li-step6"><a id="6-step"></a></li>
                                    </ul>
                                </div>
                                <div id="main-hidden1" class="col-md-7">
                                    Qudos platform creates an opportunity to build long-standing relationships only trusted service providers can
                                    offer.
                                </div>
                            </div>
                            <!--End Sign up Step 1-->

                            <!--Start Sign up Step 6-->
                            <div id="signup-6" [hidden]="!step1" class="row row-input">
                                <div class="row row-input">
                                    <div class="col-md-6">
                                        <label>
                                            First Name
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/user.png" alt="user-icon" /> -->
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </span>

                                        <input
                                            id="fname1"
                                            class="user-input text-capitalize"
                                            name="fname"
                                            [(ngModel)]="riderUser.fname"
                                            type="text"
                                            placeholder="Enter First Name"
                                            onkeypress="return /[a-z ]/i.test(event.key)"
                                            (ngModelChange)="replaceNumber('fname1')"
                                        />
                                    </div>
                                    <!-- onkeypress="return /[a-z ]/i.test(event.key)" -->
                                    <div class="col-md-6">
                                        <label>
                                            Last Name
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/user.png" alt="user-icon" /> -->
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </span>

                                        <input
                                            id="lname1"
                                            class="user-input text-capitalize"
                                            name="lname"
                                            [(ngModel)]="riderUser.lname"
                                            type="text"
                                            placeholder="Enter Last Name"
                                            onkeypress="return /[a-z ]/i.test(event.key)"
                                            (ngModelChange)="replaceNumber('lname1')"
                                        />
                                    </div>
                                </div>

                                <div class="row row-input">
                                    <div class="col-md-6">
                                        <label>
                                            Mobile Number
                                            <span class="mandatory">*</span>
                                        </label>
                                        <div class="row-input">
                                            <ngx-intl-tel-input
                                                [cssClass]="'custom'"
                                                [resetSearchContainer]="true"
                                                [preferredCountries]="preferredCountries"
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="countryToSet"
                                                [maxLength]="10"
                                                [tooltipField]="TooltipLabel.Name"
                                                [phoneValidation]="true"
                                                [separateDialCode]="separateDialCode"
                                                name="phone"
                                                [inputId]="'phone'"
                                                [formControl]="mobileNumber"
                                            ></ngx-intl-tel-input>
                                            <!-- onpaste="return false;" -->
                                        </div>
                                        <input type="hidden" name="extTwo" id="extPopTwo" />
                                    </div>

                                    <div class="col-md-6">
                                        <label>
                                            Email Address
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/mail.png" alt="mail-icon" /> -->
                                            <i class="fa fa-envelope" aria-hidden="true"></i>
                                        </span>
                                        <input
                                            id="email1"
                                            class="email-add"
                                            name="lname"
                                            autocomplete="off"
                                            [(ngModel)]="riderUser.email"
                                            type="email"
                                            placeholder="Enter your email"
                                        />
                                    </div>
                                </div>

                                <div class="row row-input">
                                    <div class="col-md-6">
                                        <label>
                                            State
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/location.png" width="15px" alt="location-icon" /> -->
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>

                                        <!-- <input
                                            name="state"
                                            type="text"
                                            class="location-add"
                                            [(ngModel)]="riderUser.state"
                                            id="state"
                                            placeholder="Enter State"
                                            onkeypress="return /[a-z ]/i.test(event.key)"
                                        /> -->
                                        <select id="state" [(ngModel)]="riderUser.state" class="select-state" name="state">
                                            <option *ngFor="let state of states; trackBy: trackByStateId">
                                                {{ state.state_name }}
                                            </option>
                                        </select>
                                    </div>

                                    <div class="col-md-6">
                                        <label>
                                            City
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/location.png" width="15px" alt="location-icon" /> -->
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>

                                        <input
                                            name="city"
                                            type="text"
                                            class="location-add text-capitalize"
                                            [(ngModel)]="riderUser.city"
                                            id="city"
                                            placeholder="Enter City"
                                            onkeypress="return /[a-z ]/i.test(event.key)"
                                            (ngModelChange)="replaceNumber('city')"
                                        />
                                    </div>
                                </div>

                                <div class="row row-input">
                                    <div class="col-md-6">
                                        <label>
                                            Zip Code
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/location.png" width="15px" alt="location-icon" /> -->
                                            <i class="fa fa-map-marker" aria-hidden="true"></i>
                                        </span>

                                        <input
                                            name="zipcode"
                                            type="number"
                                            id="zipcode"
                                            onkeypress="return /[0-9 ]/i.test(event.key)"
                                            [maxLength]="6"
                                            class="location-add cursor-pointer"
                                            [(ngModel)]="riderUser.zipcode"
                                            (onkeyup)="trimZip()"
                                            placeholder="Enter Zip Code"
                                            oninput="javascript: if (this.value.length > this.maxLength) this.value = this.value.slice(0, this.maxLength);"
                                        />
                                    </div>

                                    <div class="col-md-6">
                                        <label>
                                            Car Base Affiliation
                                            <span class="mandatory">*</span>
                                        </label>
                                        <span class="icon_input_signup position-absolute">
                                            <!-- <img src="/assets/img/user.png" /> -->
                                            <i class="fa fa-user" aria-hidden="true"></i>
                                        </span>

                                        <input
                                            class="cursor-pointer"
                                            readonly
                                            (focus)="open_car_affiliation()"
                                            id="ename"
                                            autocomplete="disabled"
                                            name="lname"
                                            [value]="current_affiliation?.business_name"
                                            type="text"
                                            placeholder="Select Car Base Affiliation"
                                        />
                                    </div>

                                    <div class="row row-input">
                                        <div class="col-md-6">
                                            <label>Referring code</label>
                                            <input
                                                id="referral"
                                                class="user-input"
                                                name="referral"
                                                type="text"
                                                (onkeyup)="cleanCity()"
                                                placeholder="Optional"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <div class="col-md-6 but-next">
                                    <button id="fifth-step" [disabled]="disableMobileHit" (click)="hitCheckMobile()" class="submit btn btn-primary">
                                        Next
                                    </button>
                                    &nbsp;
                                </div>
                            </div>
                            <!--End Sign up Step 6-->

                            <!--Start Sign up Step 7-->

                            <div id="signup-7" [hidden]="!step2" class="row row-input">
                                <div class="col-md-6 top-margin">
                                    <p><strong>Confirm your Mobile Number</strong></p>
                                    <label>Enter OTP sent to the mobile number</label>
                                    <input
                                        id="otpToAdd"
                                        onkeypress="return /[0-9 ]/i.test(event.key)"
                                        maxlength="4"
                                        type="text"
                                        [(ngModel)]="otpToAdd"
                                        name="verify-phone"
                                        class="phone-verify"
                                        placeholder="0000"
                                        maxlength="4"
                                        inputmode="numeric"
                                    />
                                    <div class="row row-input a-color">
                                        <div class="col-xs-5">Know the Rider?</div>
                                    </div>

                                    <div class="row row-input" style="margin-top: 10px !important; margin-bottom: 15px !important">
                                        <a
                                            id="getOtpCode"
                                            class="submit button-black"
                                            style="background-color: #000 !important; color: #fff !important"
                                        >
                                            Get OTP Code
                                        </a>
                                    </div>

                                    <div class="row row-input a-color">
                                        <div class="col">
                                            <button class="btn btn-primary" [disabled]="DisableResnd" id="resendOtp" (click)="reAddUser()">
                                                Resend OTP
                                            </button>
                                        </div>
                                        <div class="col-xs-7 hidden">
                                            <a id="changeMobile" (click)="showSignup1()">Change Mobile number</a>
                                        </div>
                                    </div>

                                    <div class="row row-input top-margin">
                                        <button id="six-step" (click)="riderSignUp()" [disabled]="onriderSignUp" class="submit">
                                            Create the Rider Account
                                        </button>
                                    </div>

                                    <div class="row row-input top-margin a-color"></div>
                                </div>
                            </div>
                            <!-- Start Sign Up Step 8-->
                            <div id="signup-8" [hidden]="!step3" class="row row-input">
                                <div class="col-md-12">
                                    <p class="small-show"><img src="/assets/img/mail-sucess.png" alt="sucess" /></p>
                                </div>
                                <div class="col-md-6">
                                    <h3>Welcome to Qudos</h3>
                                    <p class="top-margin">We are excited to welcome you to a truly curated service that only Qudos can offer.</p>
                                    <div class="row clear">
                                        <div class="col-md-6">
                                            <a href="riderlogin.html" class="submit-veri">Ok, Thanks!</a>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-6 small-hide">
                                    <p align="center">
                                        <img src="/assets/img/mail-sucess.png" width="200" height="200" alt="sucess" />
                                    </p>
                                </div>
                            </div>
                            <!-- End Sign Up Step 8-->
                            <!-- </form> -->
                        </div>
                    </div>
                    <!--End Row Sign Up-->
                </div>
            </div>
        </div>
    </div>
    <div [hidden]="!loading" class="loaders">
        <div class="loaders-wrapper">
            <img src="assets/images/custom_loader.svg" alt="loading..." />
        </div>
    </div>
</section>

<div
    class="modal cmodal fade p-2"
    id="car_affiliation"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 626px">
        <div class="modal-content selection-dialog">
            <CarBaseAffiliation (onCarAffiliationSave)="current_affiliation = $event"></CarBaseAffiliation>
        </div>
    </div>
</div>
