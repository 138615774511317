<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <app-trip-roster-header
            [hideTitleLayer]="minimal_setup"
            [preventTabView]="prevent_tab_view"
            [preventRouteAction]="minimal_setup"
            (onTabChange)="onTabChange.emit($event)"
            headerTitle="Trip Roster"
            [tabList]="utilityService.settingHeaderConfig.active_trips.tabs"
            activeTab="Dispatched"
            [searchControl]="searchForm"
            [subTabList]="utilityService.settingHeaderConfig.active_trips.sub_tabs['Dispatched']"
            [activeSubTab]="activeSubTab"
            (subTabClick)="changeActiveSubTab($event)"
            [showStartSessionAction]="true"
        ></app-trip-roster-header>
    </div>
    <!-- Tables
      ================================================== -->
    <div class="table-container trip-roster touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0">
        <div class="page-header"></div>
        <table class="table table-responsive trips dtrips">
            <thead>
                <tr class="frow">
                    <th scope="col">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Invoice & Trip ID</th>
                    <!-- <th scope="col">Driver Details</th> -->
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input
                                            [formControl]="driver_search_control"
                                            class="m-0 border-0 w-100"
                                            type="text"
                                            placeholder=" Search Drivers"
                                        />
                                    </div>
                                    <div>
                                        <i *ngIf="!driver_search_control.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            *ngIf="driver_search_control.value"
                                            (click)="driver_search_control.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <!-- <th scope="col">Rider Details</th> -->
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Search Riders" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="cursor-pointer large">Pickup Date & Time</th>
                    <th scope="col">Pickup Location</th>
                    <th scope="col">Drop off Location</th>
                </tr>
            </thead>

            <tbody *ngIf="isLoading && myTrips?.length == 0">
                <tr class="no-hover">
                    <td colspan="8" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="myTrips && myTrips.length > 0" class="break-word">
                <tr
                    *ngFor="
                        let trip of myTrips
                            | paginate
                                : {
                                      id: 'dispatched_trips',
                                      itemsPerPage: itemsPerPage,
                                      currentPage: pageNo,
                                      totalItems: totalItems
                                  };
                        let i = index
                    "
                >
                    <td class="book_btns tcenter">
                        <div class="p-2 bd-highlight highlight align-self-center">
                            <mat-icon data-toggle="dropdown" id="dropdown">more_horiz</mat-icon>
                            <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download">
                                <div class="dropdown-item cd-item" (click)="onCancelRide(trip)">Cancel Ride</div>
                                <div class="dropdown-item cd-item" (click)="utilityService.showModal('reschedule_time_picker_modal')">Reschedule</div>
                                <div class="dropdown-item cd-item">View Trip Details</div>
                            </div>
                        </div>
                    </td>
                    <td class="status-color" [ngClass]="trip?.ride_status_text_class">
                        {{ trip?.ride_status_text }}
                    </td>
                    <td>{{ trip?.session_id }}</td>
                    <!-- <td>{{ trip?.trip_leg_id }}</td> -->
                    <td>
                        {{ trip?.driver_name | ShortText: 15 }}
                        <br />
                        {{ trip?.driver_mobile }}
                    </td>
                    <td>
                        {{ trip?.user_name | ShortText: 15 }}
                        <br />
                        {{ trip?.user_mobile }}
                    </td>
                    <td class="tcenter">
                        <!-- {{ trip?.pickup_time | date: 'MMMM d, y h:mm a' }} -->
                        {{ trip?.pickup_time | date: 'MMMM d, y' }}
                        <br />
                        {{ trip?.pickup_time | date: 'h:mm a' }}
                    </td>
                    <td>
                        {{ trip?.pickup_location_address }}
                        <span class="fw-600 cursor-pointer text-dark-grey view-in-live-map cursor-pointer" (click)="open_live_map_view(i)">
                            View map
                        </span>
                    </td>
                    <td style="text-align: left !important">{{ trip?.manual_destination_address }}</td>
                </tr>
            </tbody>
            <tbody *ngIf="myTrips && myTrips.length == 0 && !isLoading">
                <tr class="no-hover">
                    <td colspan="8" class="text-center">No Data Found!</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="custom_pagination_v2">
    <ng-container *ngIf="!isLoading">
        <div class="d-flex flex-row-reverse align-items-center">
            <div *ngIf="showPagination">
                <pagination-controls id="dispatched_trips" responsive="true" (pageChange)="getMyTrips($event)"></pagination-controls>
            </div>

            <div class="p-2 bd-highlight highlight ml-3 dropdown cursor-pointer">
                <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                    <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                    <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
                </div>
                <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                    <div
                        *ngFor="let item of utilityService.display_filter_list"
                        class="dropdown-item text-center cd-item text-spacegrey"
                        (click)="getMyTrips(1, item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="text-spacegrey fs-13 fw-600">
                <!-- {{ pageNo * itemsPerPage >= totalItems ? totalItems : pageNo * itemsPerPage }} of {{ totalItems }} -->
                Results:
                <span class="text-blue-secondary">{{ totalItems }}</span>
            </div>
        </div>
    </ng-container>
    <ng-container *ngIf="isLoading">
        <app-loading type="placeholder" count="1" width="430px" height="24px"></app-loading>
    </ng-container>
</div>

<div
    class="modal fade"
    id="reschedule_time_picker_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style="z-index: 1230 !important"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document" style="width: 700px !important">
        <div class="modal-content booking-model-component card-shadow">
            <div class="mx-5 mt-5 mb-2 title">Reschedule the ride</div>

            <div class="modal-body p-0">
                <hr class="confirm mb-0" />
                <div class="message-bg">
                    <div class="message p-5">
                        <div class="panel-body">
                            <div class="row m-auto">
                                <div class="col-md-6 px-2 mb-0">
                                    <div class="d-flex justify-content-between schedule-date" (click)="reschedule_dispatched.open()">
                                        <div class="text">{{ selected_date?.date | date: 'y-MM-dd (EEEE)' }}</div>
                                        <div class="cal-icon no-select">
                                            <img src="assets/images/calender_icon.svg" alt="" />
                                        </div>
                                    </div>
                                    <input
                                        [(ngModel)]="selected_date.date"
                                        readonly
                                        [matDatepicker]="reschedule_dispatched"
                                        class="invisible mat-date-input"
                                    />
                                    <mat-datepicker #reschedule_dispatched></mat-datepicker>
                                </div>
                                <div class="col-md-3 px-2 form-group">
                                    <select
                                        name="time"
                                        [(ngModel)]="selected_date.time_hour"
                                        class="form-control select-field-spacing select-highlight text-dark-blue"
                                        id="time"
                                        style="height: 50px"
                                    >
                                        <option *ngFor="let option of hoursList" [value]="option.value">{{ option.title }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 px-2 form-group">
                                    <!-- <label>Minute</label> -->
                                    <select
                                        name="time"
                                        [(ngModel)]="selected_date.time_minute"
                                        class="form-control select-field-spacing select-highlight text-dark-blue"
                                        id="time-min"
                                        style="height: 50px"
                                    >
                                        <option [value]="m" *ngFor="let m of minutesList">
                                            <!-- <span *ngIf="m < 10">0{{ m }} mins</span> -->
                                            <span>{{ m }} mins</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="confirm mt-0" />

                <div align="center" class="m-3">
                    <button
                        class="btn btn-secondary border-0 btn-left m-2 px-3 py-1"
                        style="background-color: #707683 !important"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>

                    <button class="btn btn-secondary border-0 btn-right m-2 px-3 py-1">Reschedule</button>
                </div>
            </div>
        </div>
    </div>
</div>
