import { Component, OnInit } from '@angular/core';
import { HttpService } from '../../../services/http/http.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from './../../../core/services/utility/utility.service';
import { CookieService } from 'ngx-cookie-service';
import { FormControl } from '@angular/forms';

import { Card } from 'src/app/models/card.model';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { AddCardComponent } from '../../components/add-card/add-card.component';
import { IOptionItem, ISettingHeaderConfig, OptionType } from 'src/app/models/setting-header.model';

declare const jQuery: any;
const $: any = jQuery;
@Component({
    selector: 'app-cards',
    templateUrl: './cards.component.html',
    styleUrls: ['./cards.component.scss'],
})
export class CardsComponent implements OnInit {
    isLoading: boolean = false; // data load waiting loader
    Cards: Array<Card> = []; // Users that are being displayed
    AllCards: Array<any> = []; // All Users
    onLoad: boolean;
    buttonClicked: number = 0;
    pageNo: number = 1;
    itemsPerPage: number = this.utilityService.default_display_filter;
    doc: any;
    totalItems: number;
    OnloadDisable: boolean;
    type: number;
    searchString: string = '';
    searchForm: FormControl = new FormControl();

    showPagination: boolean;

    showHeaderShadow: boolean = false;

    pageHeaderConfig: ISettingHeaderConfig = {
        headerTitle: 'My Cards',
        tabs: {
            items: this.utilityService.settingHeaderConfig.settings,
            activeTab: 'My Cards',
        },
        search: {
            form: this.searchForm,
            placeholder: 'Search',
            type: OptionType.SEARCH,
        },
        moreOptionList: [{ type: OptionType.BUTTON, id: 'action-btn', text: 'Add Card', emitEvent: true }],
    };

    constructor(
        private modalService: BsModalService,
        private httpService: HttpService,
        private cookieService: CookieService,
        public utilityService: UtilityService
    ) {}

    ngOnInit(): void {
        this.searchForm.valueChanges.subscribe((newVal) => {
            if (this.searchString.trim() === newVal.trim() && newVal) {
                return;
            }
            this.searchString = newVal.trim();
            this.searchCards();
        });

        this.initTable();
    }

    onPageHeaderEvent(event: IOptionItem) {
        if (event.id === 'action-btn') {
            this.addCard();
        }
    }

    public initTable(from?: 'add_card') {
        this.isLoading = true;
        this.httpService
            .post(environment.urlC + 'list_credit_cards', {
                web_access_token: this.cookieService.get('web_access_token'),
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag == 101) {
                    // this.showCredentialsAlert();
                }
                if (data.flag == 807) {
                    this.totalItems = data.resultData.length;
                } else {
                    this.Cards = [];
                    this.totalItems = data.count;

                    const Cards = data.list_credit_cards;

                    Cards.forEach((card, ind) => {
                        const d = card;
                        if (card.card_added_on) {
                            d.card_added_on = card.card_added_on;

                            if (Date.parse(d.card_added_on)) {
                                const dt = new Date(d.card_added_on);

                                dt.setMinutes(dt.getMinutes() - d.offset);
                                const raw = dt.toISOString();
                                d.card_added_on = raw;
                            }
                        }
                        this.Cards.push(d);
                    });
                    this.AllCards = this.Cards;
                    this.getPage(this.pageNo);
                    if (from === 'add_card' && this.AllCards.length === 1) {
                        this.setDefaultCard(this.AllCards[0]?.id, true);
                    } else {
                        this.isLoading = false;
                    }
                }
            });
    }

    addCard() {
        const initialState: ModalOptions = {
            id: Date.now(),
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(AddCardComponent, initialState);
        modalref.content.event.subscribe((res: { status: 'success' | 'fail'; data: any }) => {
            if (res.status === 'success') {
                this.initTable();
            }
        });
    }

    private _setShowPagination() {
        this.showPagination =
            ((this.Cards && this.Cards?.length >= this.itemsPerPage) || this.pageNo != 1) &&
            !this.searchString &&
            this.AllCards?.length > this.itemsPerPage;
    }

    getPage(page: any = this.pageNo, itemsPerPage = this.itemsPerPage) {
        // this.Users = [];
        this.pageNo = page;
        this.itemsPerPage = itemsPerPage;
        const slimit = (page - 1) * this.itemsPerPage;
        const elimit = slimit + this.itemsPerPage;
        this.Cards = [...this.AllCards].splice(slimit, elimit);

        this._setShowPagination();
    }

    searchCards() {
        if (!this.searchString) {
            this.Cards = this.AllCards;
            return;
        }
        this.pageNo = 1;

        this.Cards = this.AllCards.filter((x) => {
            if (x.last4_digits.includes(this.searchString)) {
                return true;
            } else {
                return false;
            }
        });
    }

    public setDefaultCard(card_id: any, silent: boolean = false) {
        // console.log('Card length => ', this.Cards.length);
        this.onLoad = true;
        if (card_id) {
            this.httpService
                .post(environment.urlC + 'change_default_card', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    card_id: card_id,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;
                    if (data.error) {
                        setTimeout(() => {
                            this.onLoad = false;
                        }, 1500);
                        this.utilityService.toast('error', data.error, '');
                        return;
                    } else {
                        setTimeout(() => {
                            this.onLoad = false;
                        }, 1500);
                        if (!silent) this.utilityService.toast('success', 'Default card updated', '');
                        this.initTable();
                    }
                });
        }
    }

    public showCardAlert() {
        // Resolved once we move to ngx modal of add card
        // this.clearStripeCard(stripeElement);

        this.utilityService
            .confirm({
                heading: 'Add a new card',
                description: 'Please add new card before deletion of last card can proceed.',
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes, Add Card',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.utilityService.showModal('add_to_account');
                }
            });
    }

    public confirmRemoveCard(card: any): void {
        // this.card_to_remove = card;
        // $('#confirm_remove_card').modal('show');
        this.utilityService
            .confirm({
                heading: 'Remove Card?',
                description: 'Are you sure you want to remove this card?',
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.deleteCard(card);
                }
            });
    }

    public deleteCard(card: any) {
        this.utilityService.loading = true;
        if (!card) {
            return;
        }
        // this.utilityService.confirm("delete This Card").then((result) => {
        // if (result.value) {
        if (card.id) {
            this.httpService
                .post(environment.urlC + 'delete_card', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    card_id: card.id,
                })
                .subscribe(
                    (data) => {
                        this.utilityService.loading = false;
                        if (typeof data == 'string') data = JSON.parse(data);

                        if (data.flag == 304) {
                            this.showCardAlert();
                        } else if (data.error || data.flag == 0) {
                            this.utilityService.alert('error', data.error || data.message);
                            return;
                        } else {
                            this.utilityService.toast('success', 'Card Removed Successfully', '');
                            this.initTable();
                        }
                    },
                    (err) => {
                        this.utilityService.loading = false;
                    }
                );
        }
        this.utilityService.loading = false;
    }
}
