import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';
import { filter } from 'rxjs/operators';

@Pipe({
    name: 'DateFilter',
})
export class DateFilterPipe implements PipeTransform {
    transform(list: Array<Object>, key: any, query?: any): any {
        if (!list) return null;
        if (!key) return null;
        if (!query) return list;
        query = moment(query).format('DD-MM-YYYY');
        // console.log("formated query => ", query);

        const filteredList = list.filter((item) => {
            const d = moment(item[key]).format('DD-MM-YYYY');
            // console.log("MATCH", query, "with", d);
            // return moment(query).isSame(d); // true
            return d === query;
            // return item[key].toLowerCase().includes(query);
        });

        return filteredList;
    }
}
