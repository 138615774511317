import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { IRiderNote, NotePriority, PriorityList, PriorityMap } from 'src/app/models/notes.model';

@Component({
    selector: 'app-add-note-modal',
    templateUrl: './add-note-modal.component.html',
    styleUrls: ['./add-note-modal.component.scss'],
})
export class AddNoteModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    noteForm: FormGroup = new FormGroup({
        title: new FormControl('', [Validators.required]),
        priority: new FormControl(PriorityList[0], [Validators.required]),
        description: new FormControl('', [Validators.required]),
    });
    mode: 'edit' | 'add' = 'add';
    title: string = 'Add Note';
    priorityList: Array<string> = PriorityList;
    current_note: IRiderNote;

    public event: EventEmitter<any> = new EventEmitter();
    ngOnInit(): void {
        if (this.mode === 'edit' && this.current_note) {
            this.title = 'Edit Note';
            this.noteForm.patchValue({
                title: this.current_note?.title,
                priority: PriorityMap[this.current_note?.priority],
                description: this.current_note?.description,
            });
        }
    }

    onAction() {
        if (this.noteForm.invalid) return;
        this.event.emit(this.noteForm.value);
    }
}
