import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StripeService } from 'ngx-stripe';
import { Observable } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { ICreatePayout } from 'src/app/models/business-center.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

enum BankAPIs {
    ADD_BANK = 'add_bank',
    UPDATE_BANK = 'update_bank',
    DELETE_BANK = 'delete_bank',
    LIST_BANK = 'list_bank',
    VERIFY_BANK = 'verify_bank',
    MAKE_WALLET_PAYMENT = 'make_wallet_payment',
    PLAID_LINK_TOKEN = 'plaid_link_token',
}

@Injectable({
    providedIn: 'root',
})
export class BankService {
    constructor(private httpService: HttpService, private cookieService: CookieService, private utilityService: UtilityService) {}

    add_bank_account(payload: { sourceToken?: string; account_id?: string; public_token?: string }) {
        return this.httpService.post(environment.urlWC + BankAPIs.ADD_BANK, {
            corporate_id: this.utilityService?.corporateModel?.corporate_id,
            ...payload,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    update_bank_account(name: string, bank_id: number | string) {
        return this.httpService.post(environment?.urlWC + BankAPIs.UPDATE_BANK, {
            corporate_id: this.utilityService?.corporateModel?.corporate_id,
            name,
            bank_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    remove_abank_account(bank_id: number | string) {
        return this.httpService.post(environment?.urlWC + BankAPIs.DELETE_BANK, {
            corporate_id: this.utilityService?.corporateModel?.corporate_id,
            bank_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_bank_accounts(params: {
        corporate_id?: number | string;
        limit: number;
        offset: number;
        web_access_token?: string;
        searchFlag?: number;
        searchString?: string;
    }) {
        params.web_access_token = this.cookieService.get('web_access_token');
        params.corporate_id = this.utilityService.corporateModel?.corporate_id;
        return this.httpService.post(environment.urlWC + BankAPIs.LIST_BANK, params);
    }

    verify_bank_account(params: { bank_id: string; amount1: number; amount2: number }): Observable<any> {
        return this.httpService.post(environment.urlWC + BankAPIs.VERIFY_BANK, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...params,
        });
    }

    create_payout(payload: ICreatePayout) {
        return this.httpService.postJSON(environment.urlWC + BankAPIs.MAKE_WALLET_PAYMENT, {
            ...payload,
            web_access_token: this.cookieService.get('web_access_token'),
            corporate_id: this.utilityService?.corporateModel?.corporate_id,
        });
    }

    get_plaid_link_token() {
        return this.httpService.postJSON(environment.urlWC + BankAPIs.PLAID_LINK_TOKEN, {
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }
}
