import { Directive, ElementRef, OnInit, AfterViewInit } from '@angular/core';

@Directive({
    selector: '[appAutofocus]',
})
export class AutofocusDirective implements OnInit, AfterViewInit {
    constructor(private elementRef?: ElementRef) {}

    ngOnInit(): void {
        this.elementRef.nativeElement.focus();
    }

    ngAfterViewInit(): void {
        setTimeout(() => {
            this.elementRef.nativeElement.focus();
        }, 10);
    }
}
