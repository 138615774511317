<div class="page-header" [class.pt-0]="hideTitleLayer">
    <div *ngIf="!hideTitleLayer">
        <div class="top_head d-flex justify-content-between align-items-start">
            <div class="d-flex">
                <h2 class="mb-0">{{ headerTitle }}</h2>
                <div *ngIf="showAutoAttestationMenu" class="ml-2 cursor-pointer align-self-center">
                    <ng-content select="[attestation_setting_menu]"></ng-content>
                </div>
                <div
                    *ngIf="showStartSessionAction"
                    class="ml-2 cursor-pointer session-icon tool"
                    data-tip="Start Session"
                    (click)="start_mas_session()"
                >
                    <img src="assets/images/power_settings.svg" />
                </div>
            </div>
            <div class="top_right">
                <!-- Mobile View -->
                <div class="mobile-view">
                    <div *ngIf="queuedTripTabConfigEnabled">
                        <div
                            data-target="statusDropdown"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                            class="cursor-pointer mobile-icon-btn add-queue-trip square-35 ml-2 round no-caret"
                        >
                            <span class="dropdown-toggle">
                                <img src="assets/images/plus_icon.svg" />
                            </span>
                        </div>
                        <div
                            class="dropdown-menu cd-menu card-shadow p-3"
                            aria-labelledby="statusDropdown"
                            style="left: -120px !important; width: 194px"
                        >
                            <div class="text-dark-blue fs-16 px-2 pt-2">Queue Trips</div>
                            <div class="dropdown-item px-2 br-4 cd-item cursor-pointer text-grey" (click)="on_queue_trip_item_click(1)">
                                Queue a trip
                            </div>
                        </div>
                    </div>

                    <div
                        class="cursor-pointer mobile-icon-btn square-35 ml-2 round"
                        [class.active]="searchControl?.value"
                        [popover]="search_popover"
                        #searchPopover="bs-popover"
                        containerClass="br-6 z-index-1000 mr-3 w-260"
                        placement="bottom"
                        [outsideClick]="true"
                        (onShown)="searchPopoverReference = searchPopover"
                    >
                        <img src="assets/images/search.svg" />
                    </div>
                    <div
                        class="cursor-pointer mobile-icon-btn square-35 ml-2 round"
                        [popover]="more_option_popover"
                        #moreOptionPopover="bs-popover"
                        containerClass="br-6 z-index-1000 mr-3 w-350"
                        placement="bottom"
                        (onShown)="moreOptionPopoverReference = moreOptionPopover"
                    >
                        <img src="assets/images/more_option.svg" />
                    </div>
                </div>

                <div class="mobile-none d-flex">
                    <div>
                        <ng-container [ngTemplateOutlet]="ride_analytics"></ng-container>
                    </div>
                    <div *ngIf="queuedTripTabConfigEnabled">
                        <a
                            data-target="statusDropdown"
                            class="btn btn-outline-primary btn-md tripsbtn exportBtn"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <span class="dropdown-toggle">
                                <span class="light-weight">Queue Trip (S)</span>
                            </span>
                        </a>
                        <div class="dropdown-menu cd-menu first card-shadow" aria-labelledby="statusDropdown">
                            <div class="dropdown-item cd-item cursor-pointer" (click)="on_queue_trip_item_click(1)">Queue a trip</div>
                        </div>
                    </div>

                    <div>
                        <a *ngIf="buttonText" class="btn btn-outline-primary btn-md tripsbtn exportBtn" (click)="onButtonClick()">{{ buttonText }}</a>
                    </div>
                    <div class="search_input">
                        <input *ngIf="searchControl" type="search" class="form-control" placeholder="Search" [formControl]="searchControl" />
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="mt-4">
        <ng-container *ngIf="!preventTabView">
            <div class="d-flex justify-content-between align-items-end mt-2">
                <div class="touch-tabs d-flex no-scrollbar overflow-auto">
                    <div
                        *ngFor="let item of tabList"
                        [class.active]="item.title === activeTab"
                        [class.transition-none]="!this.isDesktop"
                        class="tab mr-3"
                        (click)="changeTab(item)"
                    >
                        {{ item.title }}
                    </div>
                </div>
                <ng-container *ngIf="!subTabList?.length" [ngTemplateOutlet]="filter_option_trigger"></ng-container>
                <div class="d-flex justify-content-end mobile-none">
                    <ng-container [ngTemplateOutlet]="middle_action_button"></ng-container>
                </div>
                <div *ngIf="!subTabList?.length" class="d-flex justify-content-end mobile-none">
                    <ng-container [ngTemplateOutlet]="filter_options"></ng-container>
                </div>
            </div>
            <hr class="my-0 py-0 -ml-40 light-color" *ngIf="subTabList?.length" />
        </ng-container>

        <div class="d-flex justify-content-between align-items-end" *ngIf="subTabList?.length">
            <div class="touch-tabs d-flex mt-3 no-scrollbar subtabs">
                <div
                    *ngFor="let item of subTabList"
                    [class.active]="item.title === activeSubTab"
                    [class.transition-none]="!this.isDesktop"
                    class="tab mr-3"
                >
                    <div class="d-flex">
                        <div (click)="onSubTabClick(item.title)">{{ item.title }}</div>
                        <div><ng-content *ngIf="item.suffix" [select]="item.suffix_content_selector"></ng-content></div>
                    </div>
                </div>
            </div>
            <ng-container [ngTemplateOutlet]="filter_option_trigger"></ng-container>
            <div class="mobile-none">
                <ng-container [ngTemplateOutlet]="filter_options"></ng-container>
            </div>
        </div>

        <!-- Tab View Replacement -->
        <div *ngIf="preventTabView" class="mt-2">
            <ng-content select="[tab_view_replacement]"></ng-content>
        </div>
    </div>
</div>

<!-- UI related Templates -->
<ng-template #ride_analytics>
    <ng-content select="[ride_earning_overview]"></ng-content>
</ng-template>

<ng-template #middle_action_button>
    <ng-content select="[middle_action_button]"></ng-content>
</ng-template>

<ng-template #filter_options>
    <div *ngIf="filterOption" class="p-2 d-flex flex-column justify-content-between flex-column-reverse h-100 mr-md-4">
        <div class="d-flex align-items-center filters w-100 mt-1">
            <div *ngFor="let item of filters" [ngClass]="{ 'd-md-none w-100': item.type === 'DIVIDER' }">
                <div class="fit-content position-relative p-1" *ngIf="item.type === 'RANGE_DATE' || item.type === 'NORMAL_DATE'">
                    <ng-container *ngIf="item?.type === 'NORMAL_DATE'">
                        <span (click)="date_picker.open()" class="fs-13 fw-600 cursor-pointer">
                            <mat-icon class="cursor-pointer align-middle ml-1 text-dark-blue fs-18" style="height: 20px">date_range</mat-icon>
                            <span class="text-blue-secondary" *ngIf="item?.form?.value">: {{ item?.form.value | date: 'MMMM d' }}</span>
                        </span>

                        <input
                            [formControl]="item?.form"
                            readonly
                            placeholder="Date"
                            [matDatepicker]="date_picker"
                            class="border-0 cursor-pointer label date-picker"
                            style="width: 0px; height: 0px; visibility: hidden; position: absolute; right: -10px; top: 30px"
                        />
                        <mat-datepicker #date_picker></mat-datepicker>
                    </ng-container>

                    <ng-container *ngIf="item?.type === 'RANGE_DATE'">
                        <span (click)="tailDateRangePicker.open()" class="fs-13 fw-600 cursor-pointer">
                            <mat-icon class="cursor-pointer align-middle ml-1 text-dark-blue fs-18" style="height: 20px">date_range</mat-icon>
                            <span class="text-blue-secondary" *ngIf="item?.form?.value?.start && item?.form?.value?.end">
                                : {{ item?.form.value?.start | date: 'MMMM d' }} -
                                {{ item?.form.value?.end | date: 'MMMM d' }}
                            </span>
                        </span>
                        <mat-date-range-input
                            class="fs-9"
                            style="width: 0px; height: 0px; visibility: hidden; position: absolute; right: 0px"
                            [formGroup]="item?.form"
                            [rangePicker]="tailDateRangePicker"
                        >
                            <input matStartDate formControlName="start" placeholder="Start date" />
                            <input matEndDate formControlName="end" placeholder="End date" />
                        </mat-date-range-input>
                        <mat-date-range-picker #tailDateRangePicker>
                            <mat-date-range-picker-actions>
                                <button mat-button matDateRangePickerCancel>Cancel</button>
                                <button mat-raised-button color="primary" matDateRangePickerApply>Apply</button>
                            </mat-date-range-picker-actions>
                        </mat-date-range-picker>
                    </ng-container>
                </div>

                <div *ngIf="item.type === 'TIME'" class="fit-content">
                    <app-time-range-picker
                        labelType="icon"
                        customClass="fs-13"
                        [default]="item.value"
                        (onTimeChange)="item.value = $event; emitFilterEvent(item)"
                    ></app-time-range-picker>
                </div>

                <div class="btn-group fit-content" dropdown *ngIf="item.type === 'DROPDOWN'">
                    <div class="dropdown p-1">
                        <span
                            class="w-100"
                            type="button"
                            [id]="item.id + '-trigger'"
                            data-toggle="dropdown"
                            aria-haspopup="true"
                            aria-expanded="false"
                        >
                            <div class="placeholder small border-0 p-0 dropdown-toggle fs-13">
                                <span>{{ item?.text }}</span>
                                <span class="text-blue-secondary fw-600">
                                    {{ item?.value | titlecase }}
                                </span>
                            </div>
                        </span>
                        <div
                            class="overflow-auto dropdown-menu cd-menu first left-unset card-shadow py-3 px-0"
                            style="width: 200px !important; max-height: 300px"
                            [id]="item.id + '-dropdown'"
                            [attr.aria-labelledby]="item.id + '-trigger'"
                        >
                            <div
                                class="dropdown-item cd-item cursor-pointer fs-13 pl-4"
                                *ngFor="let option of item.options"
                                (click)="item.value = option; emitFilterEvent(item)"
                            >
                                {{ option | titlecase }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    *ngIf="item.type === 'TOGGLE'"
                    class="cursor-pointer fs-13 p-1 fit-content"
                    [ngClass]="item.cssClass"
                    (click)="emitFilterEvent(item)"
                >
                    <span class="text-spacegrey fw-600 cursor-pointer">{{ item?.text }} :</span>
                    <span class="text-blue-secondary fw-600" *ngIf="item?.value">
                        {{ item?.value }}
                    </span>
                </div>

                <div *ngIf="item.type === 'DIVIDER'" class="mobile-view justify-content-center w-100" [ngClass]="item.cssClass">
                    <hr class="light w-75" />
                </div>
            </div>
        </div>
    </div>
</ng-template>

<!-- Popovers -->

<ng-template #search_popover>
    <div class="touch-popover p-2">
        <div>
            <input *ngIf="searchControl" class="form-control large_search_input" placeholder="Search" [formControl]="searchControl" />
        </div>
        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2">
            <div [class.disabled]="!searchControl?.value" class="cursor-pointer" (click)="searchControl.reset(); searchPopoverReference.hide()">
                Clear
            </div>
            <div class="cursor-pointer" (click)="searchPopoverReference.hide()">Okay</div>
        </div>
    </div>
</ng-template>

<ng-template #more_option_popover>
    <div class="touch-popover">
        <div class="p-4">
            <div class="text-dark-blue fs-16 mb-2 fw-600">More Options</div>
            <div
                *ngIf="buttonText"
                class="text-grey fs-14 hover-text-blue cursor-pointer"
                (click)="onButtonClick(); moreOptionPopoverReference.hide()"
            >
                {{ buttonText }}
            </div>
            <div class="mt-2 text-grey fs-14 hover-text-blue cursor-pointer" *ngIf="queuedTripTabConfigEnabled" (click)="on_queue_trip_item_click(1)">
                Queue a trip
            </div>
            <div class="mt-2">
                <ng-container [ngTemplateOutlet]="middle_action_button"></ng-container>
            </div>
        </div>
        <hr class="my-0 border-0" />
        <ng-container [ngTemplateOutlet]="ride_analytics"></ng-container>
        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-3">
            <div class="cursor-pointer" (click)="moreOptionPopoverReference.hide()">Close</div>
        </div>
    </div>
</ng-template>

<ng-template #filter_option_trigger>
    <div class="d-md-none" *ngIf="filterOption">
        <div
            class="cursor-pointer mobile-icon-btn square-51 d-flex align-items-center justify-content-center"
            [popover]="filter_option_popover"
            #filterOptionPopover="bs-popover"
            containerClass="z-index-1000 w-100vw h-100vh mr-0 transform-none"
            placement="bottom"
            (onShown)="filterOptionReference = filterOptionPopover"
        >
            <img src="assets/images/filter.svg" />
        </div>
    </div>
</ng-template>

<ng-template #filter_option_popover>
    <div class="touch-popover">
        <div class="p-sm-5 p-4 m-3">
            <div class="d-flex justify-content-between">
                <div class="fs-16">
                    <span class="text-dark-blue">Filter</span>
                    <ng-container *ngIf="isFilterActive">
                        <span>:</span>
                        <span class="text-blue-secondary ml-2 cursor-pointer" (click)="emitFilterEvent({ emitEvent: true, id: 'RESET' })">Reset</span>
                    </ng-container>
                </div>
                <div>
                    <button (click)="filterOptionReference.hide()" mat-icon-button><mat-icon class="text-dark-blue">close</mat-icon></button>
                </div>
            </div>

            <div class="filter-option-container">
                <ng-container [ngTemplateOutlet]="filter_options"></ng-container>
            </div>

            <div class="mt-5 d-flex justify-content-center filter-option-action fs-15 fw-600">
                <div class="cursor-pointer mx-4 text-dark-blue" (click)="filterOptionReference.hide()">Dismiss</div>
                <div class="cursor-pointer mx-4 text-blue-secondary" (click)="filterOptionReference.hide(); applyFilter()">Apply</div>
            </div>
        </div>
    </div>
</ng-template>
