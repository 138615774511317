import { Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IOptionItem } from 'src/app/models/setting-header.model';

@Component({
    selector: 'app-trip-roster-header',
    templateUrl: './trip-roster-header.component.html',
    styleUrls: ['./trip-roster-header.component.scss', './../../../../assets/styles/setting-header.style.scss'],
})
export class TripRosterHeaderComponent implements OnInit, OnChanges {
    constructor(private router: Router, private route: ActivatedRoute, private utilityService: UtilityService) {}

    maxDate: Date = new Date();
    @Input() isFilterActive: boolean = false;
    @Input() headerTitle: string; // header title
    @Input() hideTitleLayer: boolean = false;
    searchPopoverReference: PopoverDirective;
    moreOptionPopoverReference: PopoverDirective;
    filterOptionReference: PopoverDirective;

    @Input() showStartSessionAction: boolean = false;
    @Input() showAutoAttestationMenu: boolean = false;

    @Input() tabList: Array<tabs>; // header tabs list
    @Input() activeTab: string; // current active tab from tablist

    // Render minimal layer for setting header, and as a result only subtabs and sub tab actions will be available.

    @Input() preventRouteAction: boolean = false;
    @Input() preventTabView: boolean = false;

    @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

    @Input() subTabList: Array<tabs>;
    @Input() activeSubTab: string;
    @Output() subTabClick = new EventEmitter<string>();

    @Input() searchControl: FormControl;

    @Input() buttonText: string; // button is enabled if button text is passed!
    @Output() buttonClick = new EventEmitter<void>(); // button click event, only work if button text is passed!

    @Input() middle_action_btn: string;
    @Output() onMiddleActionBtnClick = new EventEmitter<void>();

    @Input() filterOption: boolean = false;
    @Input() filters: IOptionItem[] = [];
    @Output() onFilterEvent: EventEmitter<any> = new EventEmitter<any>();

    @Input() queuedTripTabConfigEnabled: boolean = false;
    @Output() onQueueTripItemSelected = new EventEmitter<number>();

    isDesktop: boolean = true;

    ngOnInit(): void {
        if (window.innerWidth < 991) {
            this.isDesktop = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.filters?.currentValue) {
            this.filters = changes.filters.currentValue;
        }
    }

    onButtonClick() {
        this.buttonClick.emit();
    }

    on_queue_trip_item_click(value: number) {
        this.onQueueTripItemSelected.emit(value);
    }

    onSubTabClick(type: string) {
        this.subTabClick.emit(type);
    }

    changeTab(item: { url: string; title: string }) {
        console.log(this.router?.url);
        const queryParams = this.route?.snapshot?.queryParams;

        if (!this.preventRouteAction) {
            this.router.navigate([item.url], { queryParams: queryParams });
        } else {
            // for rider detail page usage
            this.onTabChange.emit(item.title);
        }
    }

    start_mas_session() {
        this.utilityService.start_mas_session();
    }

    emitFilterEvent(event: IOptionItem) {
        if (event.emitEvent) this.onFilterEvent.emit(event);
    }
}

interface tabs {
    title: string;
    url: string;
}
