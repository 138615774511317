import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../services/http/http.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from './../../../core/services/utility/utility.service';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Document } from 'src/app/models/document.model';
import { IOptionItem, ISettingHeaderConfig, OptionType } from 'src/app/models/setting-header.model';

declare const jQuery: any;
const $: any = jQuery;

@Component({
    selector: 'app-documents',
    templateUrl: './documents.component.html',
    styleUrls: ['./documents.component.scss'],
})
export class DocumentsComponent implements OnInit {
    docs: Array<Document> = [];
    loading: boolean = false;
    doc: any = {
        expiry_date: new Date(),
    };
    // type: number = 0;
    submitText: string;
    editDoc: boolean;

    totalItems: any;
    documentTypes: any;
    totalTypes: number;
    validate: any;
    doc_id: any;
    showUpdate: number;
    edit_expiry: any;

    ridePage: number = 0;
    skip: number = 0;
    buttonClicked: number = 0;
    pop: any = {
        myDate: new Date(),
        isOpen: false,
    };
    myTrips: Array<any> = [];
    submitUpdate: string = 'Update';

    min_date: Date = new Date();
    showHeaderShadow: boolean = false;

    @ViewChild('mydocupload') fileToUpload: ElementRef;

    pageHeaderConfig: ISettingHeaderConfig = {
        headerTitle: 'My Documents',
        tabs: {
            items: this.utilityService.settingHeaderConfig.settings,
            activeTab: 'My Documents',
        },
        moreOptionList: [],
    };

    constructor(
        private router: Router,
        private httpService: HttpService,
        private cookieService: CookieService,
        public utilityService: UtilityService
    ) {}

    ngOnInit(): void {
        this.min_date.setDate(this.min_date.getDate() + 1);
        this.initTable();
    }

    onPageHeaderEvent(event: IOptionItem) {
        if (event.id === 'action-btn') {
            this.addDocDialog();
        }
    }

    public editDocument(doc) {
        this.submitUpdate = 'Update';
        if (!this.editDoc) {
            this.editDoc = true;
            return;
        }
        this.addEditDocType(doc, 1);
    }
    public initTable() {
        let data = {};
        this.docs = null;
        this.httpService.post(environment.urlC + 'get_doc_types', data).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            this.documentTypes = data.doc_types;
            this.totalTypes = this.documentTypes.length;

            this.httpService
                .post(environment.urlC + 'corporate_docs', {
                    web_access_token: this.cookieService.get('web_access_token'),
                })
                .subscribe((data) => {
                    if (typeof data == 'string') {
                        data = JSON.parse(data);
                    } else {
                        data = data;
                    }
                    // if (data.flag == 101) {
                    //     this.showCredentialsAlert();
                    // }
                    if (data.flag == 808) {
                        this.totalItems = data.driver_docs.length;
                        if (this.totalTypes > this.totalItems) {
                            this.pageHeaderConfig.moreOptionList.push({
                                type: OptionType.BUTTON,
                                id: 'action-btn',
                                text: 'Add Documents',
                                emitEvent: true,
                            });
                        }
                        this.docs = data.driver_docs;
                    }
                });
        });
    }

    public addEditDocType(doc: any, type: any) {
        if (doc.expiry_date === '') {
            this.utilityService.customAlert('error', 'Select Expiry Date');
            return false;
        } else {
            if (new Date() >= new Date(doc.expiry_date)) {
                // if (nowmilliseconds >= givenmilliseconds) {
                this.utilityService.customAlert('error', 'Please select a valid date');
                return false;
            }
        }

        if (!doc.docType) {
            this.utilityService.customAlert('error', 'Select Document Type');
            return false;
        }
        if (!doc.doc_file) {
            if (doc.capturedImage) {
                doc.doc_file = doc.capturedImage;
            } else {
                if (type == 0) {
                    this.utilityService.customAlert('error', 'Please select document to upload');
                    return false;
                }
            }
        }

        const payload: Document | any = {
            web_access_token: this.cookieService.get('web_access_token'),
            document_type_id: doc.docType,
            reminder_before: '30',
            expiry_date: moment(doc.expiry_date).format('YYYY-MM-DD'),
        };

        if (type == 1) {
            if (doc.doc_file) {
                payload['doc_file'] = doc.doc_file;
            } else {
                payload['doc_file'] = '';
            }
        } else {
            payload['doc_file'] = doc.doc_file;
        }

        if (type == 0) {
            this.submitText = 'Uploading...';

            if (this.buttonClicked === 1) {
                return false;
            } else {
                this.loading = true;
                this.buttonClicked = 1;
                this.httpService.fileUpload(environment.urlC + 'upload_corporate_doc', payload).subscribe((data) => {
                    if (data.flag == 1303) {
                        this.submitText = 'Add Document';
                        this.buttonClicked = 0;
                        this.utilityService.alert('success', 'Document Added Successfully', '');
                        $('#document_dialog').modal('hide');
                        $('.modal-backdrop').hide();
                        setTimeout(() => {
                            this.initTable();
                            // location.reload();
                        }, 200);
                        this.loading = false;
                    }
                    if (data.flag == 1302) {
                        this.buttonClicked = 0;
                        this.submitText = 'Add Document';
                        // $('#document_dialog').modal('hide');
                        this.loading = false;
                        this.utilityService.alert('error', 'A valid document of this type already exist for this corporate.', '');
                    }
                });
            }
        } else {
            this.submitUpdate = 'Updating...';
            if (this.buttonClicked === 1) {
                return false;
            } else {
                this.loading = true;
                this.buttonClicked = 1;
                payload['doc_id'] = doc.doc_id;
                this.httpService.fileUpload(environment.urlC + 'edit_corporate_doc', payload).subscribe((data) => {
                    this.buttonClicked = 0;
                    if (data.flag == 1308) {
                        this.utilityService.toast('success', 'Document Updated Successfully', '');

                        this.editDoc = false;
                        setTimeout(() => {
                            this.initTable();
                        }, 200);
                        this.loading = false;
                        $('#document_dialog_edit').modal('hide');
                    }
                    if (data.flag == 1302) {
                        this.loading = false;
                        this.utilityService.toast('error', 'A valid document of this type already exist for this corporate.', '');
                    }
                });
            }
        }
    }

    public addDocDialog() {
        this.submitText = 'Add Document';
        this.submitUpdate = 'Update';
        this.doc.expiry_date = new Date(moment().add(1, 'days').toString());
        this.doc.document_url = '';
        this.doc.doc_id = '';

        this.doc.doc_file_name = '';
        this.doc.doc_file = null;
        this.doc.docType = '';
        this.fileToUpload.nativeElement.value = '';
        this.utilityService.showModal('document_dialog');
    }

    public viewDocDialog(doc, edit) {
        this.editDoc = edit;
        this.doc.expiry_date = '';
        this.doc.expiry_date = new Date(doc.expiry_date);

        this.doc.reminder_before = doc.reminder_before;
        this.doc.document_url = doc.document_url;
        this.doc.doc_id = doc.doc_id;
        this.showUpdate = 0;
        this.documentTypes.forEach((x) => {
            if (x.document_type_id == doc.document_type_id) {
                this.doc.docTypeName = doc.document_name;
                this.doc.document_type_id = doc.document_type_id;
                this.doc.docType = x;
                return;
            }
        });

        this.doc.doc_file_name = '';
        this.utilityService.showModal('document_dialog_edit');
    }

    public viewDocEditDialog(doc, edit) {
        this.submitUpdate = 'Update';
        this.editDoc = edit;
        this.doc.expiry_date = '';
        this.edit_expiry = new Date(doc.expiry_date);
        this.edit_expiry = this.edit_expiry.toDateString();

        this.doc.expiry_date = new Date(doc.expiry_date);
        this.doc.reminder_before = doc.reminder_before;
        this.doc.document_url = doc.document_url;
        this.doc.doc_id = doc.doc_id;
        this.showUpdate = 1;

        this.documentTypes.forEach((x) => {
            if (x.document_type_id == doc.document_type_id) {
                this.doc.docTypeName = doc.document_name;
                this.doc.document_type_id = doc.document_type_id;
                this.doc.docType = x;
                return;
            }
        });

        this.doc.doc_file_name = '';
        this.utilityService.showModal('document_dialog_edit');
    }

    public file_to_upload(files) {
        this.processfile(files[0]);
        const reader = new FileReader();
        reader.onload = (e) => {
            this.doc.document_url = e.target.result;
        };
        reader.readAsDataURL(files[0]);
        this.doc.doc_file_name = files[0].name;
        // this.$apply();
    }

    public processfile(file) {
        if (!/image/i.test(file.type)) {
            this.utilityService.toast('error', 'File ' + file.name + ' is not an image.', '');
            return false;
        }

        // read the files
        const reader = new FileReader();
        reader.readAsArrayBuffer(file);

        reader.onload = (event) => {
            // blob stuff
            const blob = new Blob([event.target.result]); // create blob...
            window.URL = window.URL || window.webkitURL;
            const blobURL = window.URL.createObjectURL(blob); // and get it's URL

            // helper Image object
            const image = new Image();
            image.src = blobURL;
            //preview.appendChild(image); // preview commented out, I am using the canvas instead
            image.onload = () => {
                // have to wait till it's loaded
                const resized = this.resizeMe(image); // send it to canvas
                this.dataURItoBlob(resized);
            };
        };
    }

    public resizeMe(img: any) {
        let canvas = document.createElement('canvas');

        let width = img.width;
        let height = img.height;
        let max_width = 1024;
        let max_height = 720;
        // calculate the width and height, constraining the proportions
        if (width > height) {
            if (width > max_width) {
                //height *= max_width / width;
                height = Math.round((height *= max_width / width));
                width = max_width;
            }
        } else {
            if (height > max_height) {
                //width *= max_height / height;
                width = Math.round((width *= max_height / height));
                height = max_height;
            }
        }

        // resize the canvas and draw the image data into it
        canvas.width = width;
        canvas.height = height;
        const ctx = canvas.getContext('2d');
        ctx.drawImage(img, 0, 0, width, height);

        // preview.appendChild(canvas); // do the actual resized preview

        return canvas.toDataURL('image/jpeg', 0.7); // get the data from canvas as 70% JPG (can be also PNG, etc.)
    }
    public dataURItoBlob(dataURI) {
        const byteString = atob(dataURI.split(',')[1]);
        const ab = new ArrayBuffer(byteString.length);
        const ia = new Uint8Array(ab);
        for (let i = 0; i < byteString.length; i++) {
            ia[i] = byteString.charCodeAt(i);
        }
        const blob = new Blob([ab], {
            type: 'image/jpeg',
        });
        this.doc.doc_file = blob;
    }
    public viewDetails(modalIndex) {
        const tripDetails = this.myTrips[modalIndex];

        localStorage.setItem('userTripDetails', JSON.stringify(tripDetails));
        // $state.go("affiliate.rideDetails");
        this.router.navigate(['/', 'corporate_login']);
    }

    public onFilePicker() {
        $('#mydocupload').click();
    }

    public onFilePickerInput($event) {
        console.log('file picke event called;');
    }

    public viewDocument(url: string) {
        window.open(url);
    }

    public removeDocument(doc_id: string | number) {
        this.utilityService.alert('warning', 'In Progress');
        // this.httpService.post(environment.urlC + 'delete_corporate_doc', {doc_id}).subscribe(res => {
        //     this.utilityService.toast('success', 'Document removed Successfully', '');
        // }, (err) => {
        //     this.utilityService.toast('error', 'Error while removing document', '');
        // })
    }
}
