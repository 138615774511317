import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { ActivatedRoute, Router } from '@angular/router';
import { Primary_CarsDriverOptions } from '../../services/utility/static-data.config';
import { UtilityService } from '../../services/utility/utility.service';
import { IOptionItem, ISettingHeaderConfig } from 'src/app/models/setting-header.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'SettingHeader',
    templateUrl: './setting-header.component.html',
    styleUrls: ['./setting-header.component.scss', './../../../../assets/styles/setting-header.style.scss'],
    providers: [{ provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy }],
})
export class SettingHeaderComponent implements OnInit, OnChanges {
    constructor(private router: Router, private route: ActivatedRoute, private utilityService: UtilityService) {}

    @Input() config: ISettingHeaderConfig;
    @Output() onEvent: EventEmitter<IOptionItem> = new EventEmitter<IOptionItem>();
    searchPopoverReference: PopoverDirective;
    moreOptionPopoverReference: PopoverDirective;
    filterOptionReference: PopoverDirective;

    maxDate: Date = new Date();

    isDesktop: boolean = true;

    ngOnInit(): void {
        if (window.innerWidth < 991) {
            this.isDesktop = false;
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.config.currentValue) {
            this.config = changes.config.currentValue;
        }
    }

    onSubTabChange(item: { title: string; url: string }) {
        const queryParams = this.route?.snapshot?.queryParams;

        if (!this.config?.subTabs?.preventRouteAction) {
            this.router.navigate([item.url], { queryParams: queryParams });
        } else {
            this.config.subTabs.activeSubTab = item.title;
            this.emitEvent({ id: 'subtab', value: item.title, emitEvent: true });
        }
    }

    changeTab(item: { url: string; title: string }) {
        const queryParams = this.route?.snapshot?.queryParams;

        if (!this.config?.tabs?.preventRouteAction) {
            this.router.navigate([item.url], { queryParams: queryParams });
        } else {
            this.emitEvent({ id: 'tab', value: item.title, emitEvent: true });
        }
    }

    start_mas_session() {
        this.utilityService.start_mas_session();
    }

    emitEvent(item: IOptionItem) {
        if (item.emitEvent) {
            this.onEvent.next(item);
        }
    }
}
