<div class="modal-dialog modal-dialog-centered modal-lg touch-popup m-0" role="document">
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-5 my-4">
                <div class="medium-text dark-color">{{ title }}</div>
            </div>
        </div>

        <hr class="light" />
        <div class="bg-light-blue pr-3 pr-md-5">
            <div class="touch-content mx-5 my-4 py-2">
                <div class="d-flex my-2 justify-content-end">
                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">Driver</div>
                    <div class="ml-2 btn-group" dropdown [insideClick]="true" #bankDropdownRef="bs-dropdown">
                        <div dropdownToggle class="touch-input fs-13 w-100 p-1 px-2 d-flex justify-content-between cursor-pointer">
                            <div>
                                <!-- {{ fundForm.get('account_holder_type')?.value | titlecase}} -->
                                <span *ngIf="!selected_driver">Select Driver</span>
                                <span *ngIf="selected_driver">
                                    {{ selected_driver?.driver_name | ShortText }}
                                </span>
                            </div>
                            <div><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                        </div>
                        <ul
                            id="dropdown-basic"
                            style="min-height: 100px"
                            *dropdownMenu
                            class="dropdown-menu cd-menu first card-shadow w-100 left-0"
                            role="menu"
                            aria-labelledby="button-basic"
                        >
                            <div class="px-1 search-box light pb-2">
                                <span class="input-group br-4">
                                    <div class="d-flex w-100">
                                        <div class="flex-fill">
                                            <input
                                                [formControl]="driver_search_control"
                                                class="m-0 border-0 w-100 text-dark-grey"
                                                type="text"
                                                placeholder=" Search Drivers"
                                            />
                                        </div>
                                        <div>
                                            <i class="fa fa-search pr-0" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </span>
                            </div>
                            <div style="max-height: 200px" class="overflow-auto touch-scrollbar">
                                <ng-container *ngIf="!driverLoading">
                                    <li *ngFor="let driver of drivers" role="menuitem" (click)="selected_driver = driver; bankDropdownRef.hide()">
                                        <div class="dropdown-item blue cd-item cursor-pointer px-3">
                                            <b>{{ driver.driver_name | ShortText: 15 }}</b>
                                            <span class="fs-11 ml-1">{{ driver.driver_mobile }}</span>
                                        </div>
                                    </li>
                                    <div *ngIf="drivers?.length === 0" class="d-flex justify-content-center">
                                        <div class="text-grey mt-2 fw-600 fs-11">No driver found</div>
                                    </div>
                                </ng-container>
                                <div *ngIf="driverLoading" class="mx-2">
                                    <app-loading *ngFor="let item of [0, 1, 2]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                                </div>
                            </div>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
            <button class="btn btn-secondary btn-dark text-white p-2 mx-2 br-6" (click)="bsModalRef.hide()">Dismiss</button>
            <button [disabled]="!selected_driver" class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6" (click)="onAction()">
                Add
            </button>
        </div>
    </div>
</div>
