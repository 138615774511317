import { Component, Input, OnDestroy, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { Driver, IPreferredDriver } from 'src/app/models/driver.model';
import { DriverService } from '../../services/driver/driver.service';
import { UserService } from '../../services/user/user.service';
import { SelectDriverModalComponent } from '../select-driver-modal/select-driver-modal.component';

@Component({
    selector: 'app-preferred-driver-list',
    templateUrl: './preferred-driver-list.component.html',
    styleUrls: ['./preferred-driver-list.component.scss'],
})
export class PreferredDriverListComponent implements OnInit, OnDestroy {
    constructor(
        public utilityService: UtilityService,
        private modalService: BsModalService,
        private driverService: DriverService,
        private userService: UserService
    ) {}

    modalRef: BsModalRef;
    @Input() view_type: 'list' | 'popover' = 'list';
    @Input() user_info: {
        user_id?: number | string;
        user_name?: string; // required when user_id does not exist at front-side, i.e. Unassigned Tab
        user_mobile?: string; // required when user_id does not exist at front-side, i.e. Unassigned Tab
    };

    @Output() onAssign: EventEmitter<IPreferredDriver> = new EventEmitter<IPreferredDriver>();

    preferred_driver_reference: PopoverDirective;
    preferred_driver_list: Array<IPreferredDriver> = [];
    selected_driver: IPreferredDriver;
    private subscriptionList$: Subscription = new Subscription();
    isLoading: boolean = false;

    isDesktop: boolean = true;

    ngOnInit(): void {
        if (window.innerWidth < 991) {
            this.isDesktop = false;
        }
        if (this.view_type === 'list') {
            this.get_preferred_drivers();
        }
    }

    get_preferred_drivers(silent: boolean = false) {
        if (!silent) this.isLoading = true;
        this.subscriptionList$.add(
            this.driverService
                .get_preferred_driver(this.user_info.user_id)
                .subscribe(
                    (res: { flag: number; user_prefer_driver: Array<IPreferredDriver>; log?: string; error?: string }) => {
                        if (res?.flag === 1507) {
                            this.preferred_driver_list = res?.user_prefer_driver;
                        } else {
                            this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                    }
                )
                .add(() => {
                    this.isLoading = false;
                })
        );
    }

    add_or_update_preferred_driver(mode: 'add' | 'edit', driver?: IPreferredDriver) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode,
                title: mode === 'add' ? 'Add Preferred Drvier' : 'Edit preferred driver',
                selected_driver: driver,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(SelectDriverModalComponent, initialState);
        modalref.content.event.subscribe((selected_driver: Driver) => {
            if (selected_driver) {
                this.utilityService.loading = true;

                // Add contact
                if (mode === 'add') {
                    this.subscriptionList$.add(
                        this.driverService
                            .add_preferred_driver(this.user_info.user_id, selected_driver.driver_id)
                            .subscribe(
                                (res: { flag: number; log: string; error?: string }) => {
                                    if (res?.flag === 1507) {
                                        this.get_preferred_drivers(true);
                                        modalref.hide();
                                        this.utilityService.toast('success', 'Driver added successfully');
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                } else {
                    // Edit Contact
                    this.subscriptionList$.add(
                        this.driverService
                            .edit_preferred_driver({ id: driver?.id, user_id: driver?.user_id, driver_id: selected_driver.driver_id })
                            .subscribe(
                                (res: { flag: number; log: string; error?: string }) => {
                                    if (res?.flag === 1507) {
                                        this.get_preferred_drivers(true);
                                        modalref.hide();
                                        this.utilityService.toast('success', 'Driver updated successfully');
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                }
            }
        });
    }

    delete_preferred_driver(driver: IPreferredDriver) {
        this.subscriptionList$.add(
            this.utilityService
                .confirm({
                    heading: 'Remove preferred driver',
                    description: `Are you sure you want to remove <u>${driver?.driver_name}</u> from Preferred Driver?`,
                    leftButtonText: 'Cancel',
                    rightButtonText: 'Yes',
                })
                .subscribe((event: { action: 'left' | 'right' }) => {
                    if (event?.action === 'right') {
                        this.utilityService.loading = true;
                        this.subscriptionList$.add(
                            this.driverService
                                .delete_preferred_driver(driver?.id)
                                .subscribe(
                                    (res: { flag: number; log?: string; error?: string }) => {
                                        if (res?.flag === 1507) {
                                            this.get_preferred_drivers(true);
                                            this.utilityService.toast('success', 'Driver removed successfully');
                                        } else {
                                            this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                        }
                                    },
                                    (err) => {
                                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                                    }
                                )
                                .add(() => {
                                    this.utilityService.loading = false;
                                })
                        );
                    }
                })
        );
    }

    fetch_popover_info() {
        // User id exists
        if (this.user_info.user_id) {
            this.get_preferred_drivers();
        } else if (this.user_info?.user_name && this.user_info?.user_mobile) {
            this.isLoading = true;
            // Check/Register and fetch
            // await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            this.subscriptionList$.add(
                this.userService
                    .new_user_register(this.user_info?.user_mobile, this.user_info.user_name, 1)
                    .subscribe((res: { flag: number; error?: string; user_id: number; log: string }) => {
                        console.log('Res => ', res);
                        if (res?.user_id) {
                            this.user_info.user_id = res?.user_id;
                            this.get_preferred_drivers();
                        }
                    })
            );
        }
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
        });
    }
}
