import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { HttpService } from '../../../services/http/http.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from './../../../core/services/utility/utility.service';
import { CarsOptions } from 'src/app/core/services/utility/static-data.config';
import { CookieService } from 'ngx-cookie-service';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import { debounceTime } from 'rxjs/operators';
import { Trip } from 'src/app/models/trips.model';
import * as moment from 'moment';
import { IOptionItem, ISettingHeaderConfig, OptionType } from 'src/app/models/setting-header.model';

const $: any = jQuery;
@Component({
    selector: 'app-trips',
    templateUrl: './trips.component.html',
    styleUrls: ['./trips.component.scss'],
})
export class TripsComponent implements OnInit {
    isLoading: boolean = false; // data load waiting loader
    myTrips: Array<Trip> = [];
    skip: number = 0;
    pageNo: number = 1;
    itemsPerPage: number = this.utilityService.default_display_filter;
    ridePage: number = 0;
    ridesType: string = this.utilityService.trip_ride_type || '0';
    totalItems: number;
    // filterDate: Date;
    filterDateForm: FormControl = new FormControl();
    searchForm: FormControl = new FormControl();
    searchUser: string = '';
    showPagination: boolean = false;
    activeTrips: string = this.route.snapshot.data?.type; // completed, cancelled, scheduled
    requestType: any = {
        completed: '1',
        cancelled: '2',
        scheduled: '3',
    };

    showHeaderShadow: boolean = false;

    @ViewChild('trips') tripsPanelRef: ElementRef;

    pageHeaderConfig: ISettingHeaderConfig = {
        headerTitle: 'Trip History',
        tabs: {
            items: this.utilityService.settingHeaderConfig.trips.tabs,
            activeTab: this.ridesType === '0' ? 'On-Demand Rides' : 'Scheduled Rides',
            preventRouteAction: true,
        },
        subTabs: {
            items: this.utilityService.settingHeaderConfig.trips.subtabs?.trips_ongoing,
            activeSubTab: '',
        },
        search: {
            form: this.searchForm,
            placeholder: 'Search',
            type: OptionType.SEARCH,
        },
        moreOptionList: [
            { type: OptionType.NORMAL_DATE, id: 'filter-date', form: this.filterDateForm },
            { type: OptionType.BUTTON, id: 'export-csv', text: 'Export CSV', emitEvent: true },
        ],
    };

    onPageHeaderEvent(event: IOptionItem) {
        switch (event.id) {
            case 'export-csv':
                this.getCSV();
                break;

            case 'tab': // preventRouteAction: true
                this.setRideType(event.value === 'On-Demand Rides' ? '0' : '1');
                this.pageHeaderConfig.subTabs.items =
                    event.value === 'On-Demand Rides'
                        ? this.utilityService.settingHeaderConfig.trips.subtabs.trips_ongoing
                        : this.utilityService.settingHeaderConfig.trips.subtabs.trips_upcoming;
                this.pageHeaderConfig.tabs.activeTab = event.value;
                break;
        }
    }

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private httpService: HttpService,
        private cookieService: CookieService,
        public utilityService: UtilityService
    ) {}

    ngOnInit(): void {
        this.setActiveSubTab();

        this.route.queryParams.subscribe((params) => {
            if (params.page) {
                this.pageNo = params.page;
                this.skip = this.itemsPerPage * (this.pageNo - 1);
            }
            this.getRideHistory();
        });

        this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((newVal) => {
            if (newVal && newVal.trim() === this.searchUser) return;

            this.searchUser = newVal.trim();
            this.pageNo = 1;
            this.skip = 0;
            this.utilityService.updateQueryParams({ page: 1 });
            this.getRideHistory();
        });

        this.filterDateForm.valueChanges.subscribe((res) => {
            this.getRideHistory();
        });
    }

    public setRideType(rideType: string) {
        if (rideType === this.ridesType) {
            return;
        }

        this.skip = 0;
        this.pageNo = 1;

        this.utilityService.setRideType(rideType);
        this.ridesType = this.utilityService.trip_ride_type;
        this.setActiveSubTab();
        this.myTrips = [];
        this.getRideHistory();
    }

    public setActiveSubTab(): void {
        if (this.activeTrips === 'completed') {
            this.pageHeaderConfig.subTabs.activeSubTab = 'Completed trips';
        } else if (this.activeTrips === 'cancelled') {
            this.pageHeaderConfig.subTabs.activeSubTab = 'Cancelled trips';
        } else if (this.activeTrips === 'scheduled') {
            this.pageHeaderConfig.subTabs.activeSubTab = this.ridesType === '0' ? 'Ongoing trips' : 'Upcoming trips';
        }
    }

    public getRideHistory(pageNo = 1, itemsPerPage = this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
        this.pageNo = pageNo;
        this.skip = (pageNo - 1) * this.itemsPerPage;

        let payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: `${this.itemsPerPage}`,
            offset: `${this.skip}`,
            requestType: this.requestType[this.activeTrips],
            is_schedule: this.ridesType,
        };

        if (this.searchUser) {
            payload.searchFlag = '1';
            payload.searchString = this.searchUser;
        }

        if (this.filterDateForm?.value) {
            payload.date_filter = '1';
            payload.start_date = moment(this.filterDateForm?.value).startOf('day').toISOString();
            payload.end_date = moment(this.filterDateForm?.value).endOf('day').toISOString();
        }
        this.isLoading = true;
        this.myTrips = [];

        this.httpService.post(environment.urlC + 'ride_history', payload).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.flag == 101) {
                this.router.navigate(['/', 'corporate_login']);
            }
            if (data.flag == 502) {
                this.processTrips(data);
            } else this.isLoading = false;
        });
    }

    public getCSV() {
        if (this.myTrips.length > 0) {
            let csvContent = '';

            let csvKey = [
                'Session Id',
                'Status',
                'Total Payment',
                'Car Type',
                'Rider Full Name',
                'Driver Full Name',
                'Accept Time',
                'Pickup Time',
                'Pickup location',
                // 'Requested Drop off location',
                'Drop off location',
            ];

            if (this.activeTrips === 'cancelled') {
                csvKey = [
                    'Session Id',
                    'Status',
                    'Total Payment',
                    'Car Type',
                    'Rider Full Name',
                    'Driver Full Name',
                    'Date and Time',
                    'Pickup location',
                    // 'Requested Drop off location',
                    'Drop off location',
                ];
            }

            csvContent += csvKey + '\r\n';

            // const tripData = ;

            this.myTrips.forEach((bookingData: any) => {
                if (this.activeTrips === 'completed') {
                    if (bookingData.ride_status > 4 || bookingData.ride_status == 0) {
                        return false;
                    }
                } else if (this.activeTrips === 'cancelled') {
                    if (bookingData.is_active == 0 && bookingData.ride_status == 0) {
                        bookingData.ride_status = 10; //custom status 10 for lapsed ride
                    }
                    if (bookingData.ride_status < 5 || bookingData.ride_status == 0) {
                        return false;
                    }
                } else if (this.activeTrips === 'scheduled') {
                    if (bookingData.ride_status > 3) {
                        return false;
                    }
                }

                // console.log("request_status", bookingData.request_status);
                // bookingData.ride_status = this.utilityService.getRideStatus(bookingData.ride_status, bookingData.request_status);

                if (this.activeTrips === 'scheduled') {
                    if (bookingData.pickup_time) {
                        bookingData.pickup_time = bookingData.pickup_time;

                        const dt = new Date(bookingData.pickup_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        // const raw = dt.toISOString();
                        bookingData.pickup_time = dt.toISOString();
                    }
                    if (bookingData.start_time) {
                        bookingData.start_time = bookingData.start_time;

                        const dt = new Date(bookingData.start_time);
                        dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                        // const raw = dt.toISOString();
                        bookingData.start_time = dt.toISOString();
                    }
                }

                if (
                    (this.activeTrips === 'scheduled' && bookingData.ride_status != 'Completed' && bookingData.ride_status != 'Missed by driver') ||
                    (this.activeTrips === 'completed' && bookingData.ride_status == 'Completed') ||
                    (this.activeTrips === 'cancelled' &&
                        (bookingData.ride_status == 'Cancelled by driver' ||
                            bookingData.ride_status == 'Cancelled by rider' ||
                            bookingData.ride_status == 'Cancelled by corporate' ||
                            bookingData.ride_status == 'Ride Lapsed' ||
                            bookingData.ride_status == 'Missed by driver' ||
                            bookingData.ride_status == 'Unsuccessful payment'))
                ) {
                    const obj = bookingData;

                    let row = [
                        obj['session_id'] || '-',
                        obj['ride_status'] || '-',
                        obj['total_with_tax'] + ' paid via ' + obj['payment_method'] || '-',
                        obj['car_name'] || '-',
                        obj['user_name'] || '-',
                        obj['driver_name'] || '-',
                        obj['accept_time'] ? moment(obj['accept_time']).format('MMM DD YYYY hh:mm A') : '-',
                        obj['arrival_time'] ? moment(obj['arrival_time']).format('MMM DD YYYY hh:mm A') : '-',
                        // obj['accept_time'] || '-',
                        // obj['arrival_time'] || '-',
                        obj['pickup_location_address']?.replace(/[,#]/g, ' ') || '-',
                        obj['manual_destination_address']?.replace(/[,#]/g, ' ') || '-',
                    ];

                    if (this.activeTrips === 'cancelled') {
                        row = [
                            obj['session_id'] || '-',
                            obj['ride_status'] || '-',
                            obj['total_with_tax'] + ' paid via ' + obj['payment_method'] || '-',
                            obj['car_name'] || '-',
                            obj['user_name'] || '-',
                            obj['driver_name'] || '-',
                            obj['sent_at'] ? moment(obj['accept_time']).format('MMM DD YYYY hh:mm A') : '-',
                            obj['pickup_location_address']?.replace(/[,#]/g, ' ') || '-',
                            obj['manual_destination_address']?.replace(/[,#]/g, ' ') || '-',
                        ];
                    }

                    csvContent += row + '\r\n';
                }
            });

            const title = (this.ridesType == '0' ? 'On-Demand' : 'Scheduled') + `-${this.pageHeaderConfig.subTabs?.activeSubTab}.csv`;
            this.utilityService.generate_csv(title, csvContent);
        }
    }

    public viewDetails(session_id: any) {
        // const tripDetails = this.myTrips[modalIndex];
        // tripDetails.ride_type = this.activeTrips;
        // localStorage.setItem('userTripDetails', JSON.stringify(tripDetails));
        // this.router.navigate(['/', 'corporate', 'detail-view']);
        this.router.navigate(['/', 'corporate', 'trip', session_id]);
    }

    public processTrips(data: any): void {
        this.myTrips = [];
        this.totalItems = data.total_length;
        const Bookings = data.my_trips;

        Bookings.forEach((bookingData: any) => {
            if (this.activeTrips === 'completed') {
                if (bookingData.ride_status > 4 || bookingData.ride_status == 0) {
                    return false;
                }
            } else if (this.activeTrips === 'cancelled') {
                if (bookingData.request_status == 5) {
                    bookingData.ride_status = 5; //custom status 5 for Cancelled ride
                } else if (bookingData.is_active == 0 && bookingData.ride_status == 0) {
                    bookingData.ride_status = 10; //custom status 10 for lapsed ride
                }
                if (bookingData.ride_status < 5 || bookingData.ride_status == 0) {
                    return false;
                }
            } else if (this.activeTrips === 'scheduled') {
                if (bookingData.ride_status > 3) {
                    return false;
                }
            }
            const d = bookingData;
            d.payment_method = bookingData.payment_method || 'Card';

            d.ride_status = this.utilityService.getRideStatus(bookingData.ride_status, bookingData.request_status);

            d.total_with_tax = Number(bookingData?.total_with_tax)?.toFixed(2) || '';
            //d.total_with_tax = parseFloat(d.total_with_tax);

            if (!d.car_name) {
                d.car_name = CarsOptions.find((x) => x.car_type == d.car_type)['car_name'];
            }

            if (bookingData.pickup_time) {
                const dt = new Date(d.pickup_time);
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                const raw = dt.toISOString();
                d.pickup_time = raw;
            }
            if (bookingData.start_time) {
                d.start_time = bookingData.start_time;
                const dt = new Date(d.start_time);
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                d.start_time = dt.toISOString();
            }
            if (
                (this.activeTrips === 'scheduled' &&
                    d.ride_status != 'Completed' &&
                    d.ride_status != 'Unknown' &&
                    d.ride_status != 'Cancelled by driver' &&
                    d.ride_status != 'Cancelled by admin' &&
                    d.ride_status != 'Missed by driver') ||
                (this.activeTrips === 'cancelled' &&
                    (d.ride_status == 'Cancelled by driver' ||
                        d.ride_status == 'Cancelled by rider' ||
                        d.ride_status == 'Cancelled by corporate' ||
                        d.ride_status == 'Cancelled by admin' ||
                        d.ride_status == 'Ride Lapsed' ||
                        d.ride_status == 'Missed by driver' ||
                        d.ride_status == 'Unsuccessful payment')) ||
                (this.activeTrips === 'completed' && d.ride_status == 'Completed')
            ) {
                d.sent_at = this.utilityService.getLocalTime(d.sent_at);
                this.myTrips.push(d);
            }
        });
        this.isLoading = false;
        $('.collapse').removeClass('show');
        this._setShowPagination();
    }

    private _setShowPagination() {
        this.showPagination =
            this.myTrips?.length > 0 &&
            // && (this.myTrips?.length >= 10 || this.pageNo != 1)
            !this.isLoading;
        // && this.totalItems > this.itemsPerPage;
    }
}
