import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { MileStone } from 'src/app/models/assigned-trips.model';
import { Notification } from 'src/app/models/notification.model';
import { environment } from 'src/environments/environment';
import { HttpService } from '../../../services/http/http.service';
import { MasService } from '../mas/mas.service';
import { reSubmitMASStatus, reSubmitStatus } from 'src/app/models/trips.model';

export type AssignedStatusType = 'confirmed' | 'dispatched' | 'declined' | 'pending' | 'completed' | 'cancelled' | 'no show';

@Injectable({
    providedIn: 'root',
})
export class MasAssignService {
    constructor(
        private cookieService: CookieService,
        private httpService: HttpService,
        private utilityService: UtilityService,
        private masService: MasService
    ) {}

    public mas_assigned_notification: Subject<Notification> = new Subject<Notification>();

    mas_trip_assign_driver_params(
        trip: any,
        driver: any,
        trip_type: 'unassigned' | 'queued' = 'unassigned',
        queued_trip?: any,
        send_caller_info: boolean = false
    ): Observable<{ flag: any; log: string; error: string }> {
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            first_name: trip?.firstName,
            last_name: trip?.lastName,
            user_mobile: this.utilityService.purify_mobile_no(trip?.recipientPhone),
            pickup_date: trip?.pickupDate,
            pickup_latitude: trip?.pickupAddress?.lat,
            pickup_longitude: trip?.pickupAddress?.lon,
            pickup_location: trip?.pickup_location_address,
            dropoff_location: trip?.dropoff_location_address,
            dropoff_latitude: trip?.dropoffAddress?.lat,
            dropoff_longitude: trip?.dropoffAddress?.lon,
            invoice_number: trip?.invoiceNumber,
            trip_leg_id: trip?.tripLegId,
            pickup_time: trip?.pickup_time,
            driver_id: driver?.driver_id,
            offset: new Date().getTimezoneOffset() * -1,
            confirmed_driver_id: driver?.type === 'confirmed' ? driver?.driver_id : 0,
            miles: trip?.legMileage || 0,
            zip_code: trip?.pickupAddress?.zip,
            trip_leg_sequence: trip?.tripLegSequence || 0,
            leg_cost: this.masService.calculate_total_leg_cost(trip?.legMileage, trip?.legCost, trip?.secondary_services) || 0,
        };

        // If trip is type of queued, then use location and time from our db.
        if (trip_type === 'queued' && queued_trip) {
            payload.pickup_latitude = queued_trip?.pickup_latitude;
            payload.pickup_longitude = queued_trip?.pickup_longitude;
            payload.pickup_location = queued_trip?.pickup_location;
            payload.dropoff_location = queued_trip?.dropoff_location;
            payload.dropoff_latitude = queued_trip?.dropoff_latitude;
            payload.dropoff_longitude = queued_trip?.dropoff_longitude;
            payload.pickup_time = queued_trip?.pickup_time;
            payload.zip_code = queued_trip?.zip_code;
            payload.miles = queued_trip?.miles;
            payload.ride_confirmed = queued_trip?.ride_confirmed ? 1 : 0;
            payload.isUTC = 1;

            if (queued_trip?.is_prepaid) {
                payload.is_prepaid = 1;
                payload.prepaid_amount = queued_trip?.prepaid_amount;
            }
        }

        if (trip?.mas_trip_id) {
            payload.mas_trip_id = trip?.mas_trip_id;
        }

        if (trip?.car_type) {
            payload.car_type = trip?.car_type;
        }

        if (trip?.ride_estimate_time) {
            payload.ride_estimate_time = trip?.ride_estimate_time;
        }

        const congestion_tax = this.utilityService.get_secondary_service({ trip, key: 'service_name', value: 'Congestion Fee Single' });
        if (congestion_tax) {
            payload.congestion_tax = congestion_tax?.service_rate;
        }

        if (trip?.ride_status === 6) {
            payload.ride_status = 6;
        }

        if (trip_type === 'unassigned') {
            payload.callerUserId = trip?.user_id;
            if (send_caller_info && trip?.user_id && trip?.callerName && trip?.callerPhone) {
                payload.callerName = trip?.callerName;
                payload.callerPhone = this.utilityService.purify_mobile_no(trip?.callerPhone);
            }

            if (trip?.DOB) {
                payload.date_of_birth = trip?.DOB;
            }
            if (trip?.gender) {
                payload.gender = ['M', 'F']?.indexOf(trip?.gender);
            }
            (payload.ride_broker_id = trip?.ride_broker_id), (payload.ride_broker_name = trip?.type);
            if (trip?.type === 'sentryMS') {
                payload.sentryms_trip_type = trip?.trip_type;
                payload.broker_vehicle_id = trip.vehicleId ? trip.vehicleId : '';
                payload.broker_driver_id = trip.driverId ? trip.driverId : '';
            }
        }

        return payload; // this.httpService.post(environment.urlWC + 'assign_driver_mas_trip', payload);
    }

    assign_driver(payload: any) {
        if (!payload?.web_access_token) {
            payload.web_access_token = this.cookieService.get('web_access_token');
        }

        return this.httpService.post(environment.urlWC + 'assign_driver_mas_trip', payload);
    }

    // trip: any, session_identifier: any = localStorage.getItem('session_identifier')
    cancel_trip(trip: any) {
        const date_time = moment.utc(`${trip?.pickupDate} ${trip?.pickup_time}`);
        const params = {
            web_access_token: this.cookieService.get('web_access_token'),
            first_name: trip?.firstName,
            last_name: trip?.lastName,
            user_mobile: this.utilityService.purify_mobile_no(trip?.recipientPhone),
            pickup_date: date_time.format('MM/DD/yyyy'),
            pickup_latitude: trip?.pickupAddress?.lat,
            pickup_longitude: trip?.pickupAddress?.lon,
            pickup_location: trip?.pickup_location_address,
            dropoff_location: trip?.dropoff_location_address,
            dropoff_latitude: trip?.dropoffAddress?.lat,
            dropoff_longitude: trip?.dropoffAddress?.lon,
            invoice_number: trip?.invoiceNumber,
            trip_leg_id: trip?.tripLegId,
            pickup_time: date_time.format('hh:mm a'),
            driver_id: 0,
            offset: new Date().getTimezoneOffset() * -1,
            car_type: trip?.car_type || 1,
            trip_leg_sequence: trip?.tripLegSequence || 0,
            ride_broker_id: trip?.ride_broker_id,
            ride_broker_name: trip?.type,
            // session_identifier,
        };

        return this.httpService.post(environment.urlWC + 'cancel_mas_trip', params);
    }

    get_assign_driver_mas_trip(trip_leg_id: string): Observable<any> {
        return this.httpService.post(environment.urlWC + 'get_assign_driver_mas_trip', {
            web_access_token: this.cookieService.get('web_access_token'),
            trip_leg_id,
        });
    }

    get_assign_mas_roster_trip_info(
        payload: Partial<{ limit: number; offset: number; sort_order?: any; searchFlag: number; searchString?: string; sort_by?: string }>
    ): Observable<any> {
        return this.httpService.post(environment.urlWC + 'get_assign_mas_roster_trip_info', payload).pipe(
            map((res: { trips: any }) => {
                res.trips = res?.trips?.map((item) => {
                    item.pickup_datetime = new Date(
                        moment
                            .utc(item?.pickup_date + ' ' + item?.pickup_time)
                            .local()
                            .toString()
                    );
                    return item;
                });
                return res;
            })
        );
    }

    update_assigned_driver(payload: any): Observable<any> {
        return this.httpService.post(environment.urlWC + 'edit_assign_driver_mas_trip', payload);
    }

    get_assigned_status(trip: any): AssignedStatusType {
        if (trip?.ride_status === 4) {
            return 'completed';
        }

        if (trip?.ride_status === 5 || trip?.ride_status === 11) {
            return 'cancelled';
        }

        if (trip?.ride_status === 7) {
            return 'no show';
        }

        if (trip?.ride_status === 3) {
            return 'dispatched';
        }

        if (!trip?.driver_id && !trip?.declined_driver) {
            return 'pending';
        } else if (trip?.declined_driver) {
            return 'declined';
        } else if (trip?.driver_id) {
            return 'confirmed';
        }
        return 'pending';
    }

    // get_next_milestone(ride_milestone: number, ride_status: number): string {
    //     if(ride_status !== Assinged_Ride_Status.CONFIRMED) return '';
    // }

    async filter_out_assigned_trip(all_mas_trips: Array<any>) {
        const trip_leg_id_list = all_mas_trips.map((trip: any) => trip?.tripLegId) || [];
        let assignedLeg: any = new Set();

        return new Promise(async (resolve, reject) => {
            const { trips: driver_trip_map } = await this.get_assign_driver_mas_trip(trip_leg_id_list.join()).pipe(first()).toPromise();
            driver_trip_map?.forEach((x: any) => {
                assignedLeg.add(x?.trip_leg_id?.toString());
            });
            assignedLeg = [...assignedLeg] || [];
            resolve(all_mas_trips.filter((x) => !assignedLeg?.includes(x?.tripLegId)));
        });
    }

    edit_assign_mas_trip(payload: {
        mas_trip_id: number | string;
        pickup_time?: string;
        pickup_date?: string;
        ride_status?: any;
        car_type?: number;
        third_party_amount?: number;
        gross_fare_amount?: number;
        fare_factor?: number;
        user_toll?: number;
        avoidHighways?: any;
        avoid_tolls?: any;
        will_call?: number;
        pickup_location?: string;
        pickup_latitude?: string;
        pickup_longitude?: string;
        dropoff_location?: string;
        dropoff_latitude?: string;
        dropoff_longitude?: string;
        ride_confirmed?: number;
        show_driver_payout?: number;
        is_prepaid?: number;
        prepaid_amount?: number;
    }) {
        const params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id: payload.mas_trip_id,
        };

        if (payload.pickup_location) {
            params.pickup_location = payload.pickup_location;
            params.pickup_latitude = payload.pickup_latitude;
            params.pickup_longitude = payload.pickup_longitude;
            params.dropoff_location = payload.dropoff_location;
            params.dropoff_latitude = payload.dropoff_latitude;
            params.dropoff_longitude = payload.dropoff_longitude;
        }

        if (payload?.is_prepaid !== null) {
            params.is_prepaid = payload.is_prepaid;
        }
        if (payload?.prepaid_amount !== null) {
            params.prepaid_amount = payload?.prepaid_amount;
        }

        if (payload?.avoidHighways !== null) {
            params.avoidHighways = payload?.avoidHighways;
        }

        if (payload?.avoid_tolls !== null) {
            params.avoid_tolls = payload?.avoid_tolls;
        }

        if (payload.ride_status != null) {
            params.ride_status = payload.ride_status;
        } else {
            if (payload.pickup_time && payload.pickup_date) {
                params.pickup_time = payload.pickup_time;
                params.pickup_date = payload.pickup_date;
            }
            if (payload.car_type) {
                params.car_type = payload.car_type;
            }

            if (payload.third_party_amount) {
                params.third_party_amount = payload.third_party_amount;
            }

            if (payload?.gross_fare_amount) {
                params.gross_fare_amount = payload.gross_fare_amount;
            }

            if (payload?.fare_factor) {
                params.fare_factor = payload.fare_factor;
            }

            if (payload.will_call === 0 || payload?.will_call === 1) {
                params.will_call = payload?.will_call;
            }
            if (payload.user_toll) {
                params.user_toll = payload.user_toll;
            }
            if (payload.ride_confirmed !== null) {
                params.ride_confirmed = payload.ride_confirmed;
            }
            if (payload?.show_driver_payout === 0 || payload?.show_driver_payout === 1) {
                params.show_driver_payout = payload.show_driver_payout;
            }
        }

        return this.httpService.post(environment.urlWC + 'edit_assign_mas_trip', params);
    }

    get_assigned_trip_info(mas_trip_id: number | string) {
        return this.httpService.post(environment.urlWC + 'get_assgined_trip_info', {
            mas_trip_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    update_ride_milestone(mas_trip_id: number | string, milestone: MileStone) {
        return this.httpService.post(environment.urlWC + 'update_ride_milestone', {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id,
            milestone,
            date: moment().toISOString(),
        });
    }

    resubmitMasStatus(payload: reSubmitStatus) {
        return this.httpService.post(environment.urlWC + 'resubmit_mas_status', payload);
    }

    resubmitMasStatusWithLatLong(payload: reSubmitMASStatus) {
        return this.httpService.post(environment.urlWC + 'resubmit_mas_status', payload);
    }
}
