<div class="d-flex">
    <div *ngIf="amount_type === 'normal'">
        {{ amount || '0' | currency: 'USD' }}
    </div>
    <div *ngIf="allowEdit">
        <span
            class="cursor-pointer"
            [popover]="amount_popover"
            #amountPopover="bs-popover"
            containerClass="br-6 z-index-1000"
            placement="bottom"
            [outsideClick]="true"
            (click)="amount_popover_reference = amountPopover; popoverTriggered.emit(); update_amount(this.amount)"
        >
            <mat-icon class="text-dark-blue fs-14 ml-1" *ngIf="amount_type === 'normal'">edit</mat-icon>
            <ng-container *ngIf="amount_type === 'percentage'">
                <img style="fill: #323f50; height: 20px" src="assets/images/setting_gear.svg" alt="" />
            </ng-container>
        </span>
    </div>
</div>

<ng-template #amount_popover>
    <div class="touch-popover">
        <!-- Normal Amount Type -->
        <ng-container *ngIf="amount_type === 'normal'">
            <div
                class="input-group w-auto touch-currency-input py-2 px-3 m-3 w-100 d-flex"
                [class.pulse]="isLoading"
                [class.flex-row-reverse]="reverse_secondary_position"
            >
                <div class="flex-fill">
                    <div class="d-flex mx-1">
                        <div class="input-group-prepend border-0">
                            <span class="input-group-text fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                        </div>
                        <input
                            appAutofocus
                            onkeypress="return /[0-9]|\./i.test(event.key)"
                            type="number"
                            class="border-0 w-100px fs-16 fw-600 text-dark-blue"
                            [ngClass]="{ 'w-100px': !enable_secondary_amount, 'w-50px': enable_secondary_amount }"
                            [formControl]="amountControl"
                        />
                    </div>
                    <div style="color: #707683" class="fw-600 fs-12 text-center">{{ label }}</div>
                </div>

                <div *ngIf="enable_secondary_amount" class="flex-fill mx-1">
                    <div class="d-flex">
                        <div class="input-group-prepend border-0">
                            <span class="input-group-text fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                        </div>
                        <input
                            appAutofocus
                            onkeypress="return /[0-9]|\./i.test(event.key)"
                            type="number"
                            class="border-0 w-100px fs-16 fw-600 text-dark-blue w-50px"
                            [readonly]="readonly_secondary_amount"
                            [formControl]="secondaryAmountControl"
                        />
                    </div>
                    <div style="color: #707683" class="fw-600 fs-12 text-center">{{ secondary_label }}</div>
                </div>
            </div>
        </ng-container>

        <!-- Percentage Amount Type, this setup also include the label -->
        <ng-container *ngIf="amount_type === 'percentage'">
            <div
                class="touch-currency-input py-2 px-3 m-3 bordered"
                [class.error]="amountControl.touched && amountControl.invalid"
                [class.pulse]="isLoading"
            >
                <div class="input-group w-auto mb-2 justify-content-center">
                    <input
                        appAutofocus
                        onkeypress="return /[0-9]|\./i.test(event.key)"
                        type="number"
                        style="width: 50px"
                        class="border-0 fs-16 fw-600 text-dark-blue text-right"
                        [formControl]="amountControl"
                    />

                    <div class="input-group-prepend border-0">
                        <span class="input-group-text fs-16 fw-600 text-dark-blue" id="basic-addon1">%</span>
                    </div>
                </div>
                <div style="color: #707683" class="fw-600">{{ label }}</div>
            </div>
        </ng-container>

        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2">
            <div class="cursor-pointer" (click)="amount_popover_reference.hide()">Dismiss</div>
            <div
                class="cursor-pointer"
                [class.disabled]="amountControl.invalid || amountControl?.value === amount"
                (click)="amount_popover_reference.hide(); onSave.emit(amountControl?.value)"
            >
                Save
            </div>
        </div>

        <!-- Extra Content If Passed -->
        <ng-content select="[amount_popover_extra_body]"></ng-content>
    </div>
</ng-template>
