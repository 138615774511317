import { Component, EventEmitter, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { Sort } from '@angular/material/sort';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilityService } from '../../services/utility/utility.service';
@Component({
    selector: 'app-mas-trip-info',
    templateUrl: './mas-trip-info.component.html',
    styleUrls: ['./mas-trip-info.component.scss'],
})
export class MasTripInfoComponent implements OnInit {
    trip: any;
    sortedVendorLog: Array<any> = [];

    public event: EventEmitter<any> = new EventEmitter();
    constructor(public utilityService: UtilityService, public bsModalRef: BsModalRef) {}

    ngOnInit(): void {
        this.sortVendorLogs({ active: 'datentime', direction: 'desc' });

        if (this.trip?.trips.length > 0) {
            this.trip.trips[0].formated_pickup_date_time = this.formatDate(this.trip?.trips[0]?.pickupDate, this.trip?.trips[0]?.pickupTime);

            // format number
            this.trip.trips[0].formated_recipient_phone = this.formatPhoneNumber(this.trip?.trips[0]?.recipientPhone);
            this.trip.trips[0].formated_caller_phone = this.formatPhoneNumber(this.trip?.trips[0]?.callerPhone);
        }

        if (this?.trip?.trips.length > 1) {
            this.trip.trips[1].formated_pickup_date_time = this.formatDate(this.trip?.trips[1]?.pickupDate, this.trip?.trips[1]?.pickupTime);
        }
    }

    formatPhoneNumber(phoneNumberString) {
        const cleaned = ('' + phoneNumberString).replace(/\D/g, '');
        const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return '(' + match[1] + ') ' + match[2] + '-' + match[3];
        }
        return null;
    }

    formatDate(date: string, time: string) {
        if (time) time = moment(`${time.slice(0, 2)}:${time.slice(2, 4)}`, 'hh:mm a').format('hh:mm a');
        date = moment(date).format('MMMM D, YYYY dddd');
        return `${date} ${time}`;
    }

    sortVendorLogs(sort: Sort) {
        if (!this.trip?.logs) return;
        const data = this.trip.logs.slice();
        if (!sort.active || sort.direction === '') {
            this.sortedVendorLog = data;
            return;
        }
        this.sortedVendorLog = data.sort((a, b) => {
            const isAsc = sort.direction === 'asc';
            switch (sort.active) {
                case 'datentime':
                    return this.compare(new Date(a.datetime), new Date(b.datetime), isAsc);
                default:
                    return 0;
            }
        });
    }

    compare(a: any, b: any, isAsc: boolean) {
        return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
    }

    closePopup() {
        this.bsModalRef.hide();
    }
}

@Component({
    selector: 'MasTable',
    template: `
        <table class="fs-13">
            <tr *ngFor="let row of data">
                <td class="py-1">
                    {{ row.key }}
                </td>
                <td class="py-1">{{ row.value }}</td>
            </tr>
        </table>
    `,
    styleUrls: ['./mas-trip-info.component.scss'],
})
export class MasTable {
    @Input() data: { key: string; value: string };
}
