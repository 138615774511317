export const environment = {
  production: false,
  name: 'dev',
  url: "https://customer_dev.ridequdos.com/",//"http://18.211.241.237:6503/",
  urlV: "https://partner_dev.ridequdos.com/vendor/",//"http://18.211.241.237:6509/vendor/",
  // urlWC: "https://corp_dev.ridequdos.com/",//"http://18.211.241.237:6501/",
  urlWC: "https://cd.ridequdos.com/",//"http://18.211.241.237:6501/",
  urlC: "https://cd.ridequdos.com/corporate/",//"http://18.211.241.237:6501/corporate/",
  stripeKey: "pk_test_x0nIalqvXaliqoYO9Qdcb94Q",
  SocketURL: "https://socketsdev.ridequdos.com",//"18.211.241.237:6508",
  mapKey: "AIzaSyDVxUnImtx75BtZS3yQJKhh_XgKFbWcKaE",
  vendorBaseURL: "https://qudos.dev/",//"http://qudos.tech/",
  authURL: "https://api.qudos.dev/auth/",
};
