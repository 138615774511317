import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable, Subject } from 'rxjs';
import { IAdvanceNote, IRiderNote, MasNote } from 'src/app/models/notes.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

enum NoteApis {
    MAS_GET_NOTES = 'get_notes_mas_trip',
    MAS_ADD_NOTES = 'add_notes_mas_trip',
    MAS_EDIT_NOTES = 'edit_notes_mas_trip',

    // User Section
    USER_GET_NOTES = 'get_user_notes',
    USER_ADD_NOTES = 'add_user_notes',
    USER_UPDATE_NOTES = 'update_user_notes',
    USER_REMOVE_NOTES = 'remove_user_notes',

    // Advance Section
    GET_ADVANCE_USER_NOTE = 'get_advance_user_notes',
    SAVE_ADVANCE_NOTE = 'set_advance_user_notes',
}

@Injectable({
    providedIn: 'root',
})
export class NotesService {
    constructor(private httpService: HttpService, private cookieService: CookieService) {}
    public triggerCreateRiderDriverNote: Subject<void> = new Subject<void>();

    // MAS Note Section

    // TODO: remove double response type once array suppport is deployed in the api
    get_mas_notes(mas_trip_id: any): Observable<{ notes: MasNote | MasNote }> {
        return this.httpService.post(environment.urlWC + NoteApis.MAS_GET_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id,
        });
    }

    add_mas_notes(mas_trip_id: string | number, notes: any): Observable<{ flag: number; log: string }> {
        return this.httpService.post(environment.urlWC + NoteApis.MAS_ADD_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id,
            notes,
        });
    }

    update_mas_note(note_id: string | number, note: any): Observable<{ flag: number; log: string }> {
        return this.httpService.post(environment.urlWC + NoteApis.MAS_EDIT_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            notes_id: note_id,
            notes: note,
        });
    }

    // qudos_user_type: 1 for rider, 3 for driver
    get_user_notes(user_id: number | string, qudos_user_type: number = 1) {
        return this.httpService.post(environment.urlWC + NoteApis.USER_GET_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            user_id,
            qudos_user_type,
        });
    }

    // qudos_user_type: 1 for rider, 3 for driver
    add_user_notes(note: { user_id: number | string; title: string; priority: number; desc: string; qudos_user_type?: number }) {
        return this.httpService.post(environment.urlWC + NoteApis.USER_ADD_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...note,
        });
    }

    update_user_notes(note: any) {
        return this.httpService.post(environment.urlWC + NoteApis.USER_UPDATE_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...note,
        });
    }

    remove_user_note(notes_id: number | string) {
        return this.httpService.post(environment.urlWC + NoteApis.USER_REMOVE_NOTES, {
            web_access_token: this.cookieService.get('web_access_token'),
            notes_id,
        });
    }

    // advance user note
    get_advanced_note(user_id: number, user_type: number = 0) {
        return this.httpService.post(environment.urlWC + NoteApis.GET_ADVANCE_USER_NOTE, {
            web_access_token: this.cookieService.get('web_access_token'),
            qudos_user_id: user_id,
            qudos_user_type: user_type,
        });
    }

    save_advanced_note(payload: Partial<IAdvanceNote>) {
        return this.httpService.post(environment.urlWC + NoteApis.SAVE_ADVANCE_NOTE, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...payload,
        });
    }
}
