<div class="main_outer pagination pt-0">
    <div>
        <SettingHeader [config]="pageHeaderConfig" (onEvent)="onPageHeaderEvent($event)"></SettingHeader>
    </div>

    <div class="table-container touch-scrollbar">
        <table class="table table-responsive-md trips_detail dtrips trips" #trips>
            <thead>
                <tr class="frow">
                    <th scope="col">Action</th>
                    <th scope="col">Status</th>
                    <th scope="col">Earnings</th>
                    <th scope="col">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="ID #" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Care Base" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Driver" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Rider" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">Date & Time</th>
                    <th scope="col" class="large">Pickup Location</th>
                    <th scope="col" class="large">Drop off Location</th>
                    <th scope="col">Fare</th>
                </tr>
            </thead>

            <tbody>
                <tr class="bg-placeholder-trip">
                    <td class="book_btns align-left">
                        <div class="d-flex">
                            <div class="bd-highlight highlight align-self-center mr-1 tool stop-propagation" data-tip="More Options">
                                <img data-toggle="dropdown" class="hover-shadow rounded" id="dropdown" src="/assets/images/more_horiz_rounded.svg" />
                                <div class="dropdown-menu cd-menu grey-item left-0 card-shadow text-grey" aria-labelledby="download">
                                    <div class="dropdown-item cd-item">View Details</div>
                                    <div class="dropdown-item cd-item">Send Payment Link</div>
                                </div>
                            </div>
                        </div>
                    </td>
                    <td class="book_btns align-left">Paid</td>
                    <td class="book_btns align-left">
                        <span class="fw-600 cursor-pointer text-dark-grey view-in-live-map">$ 23.22</span>
                    </td>
                    <td class="book_btns align-left">124442252</td>
                    <td class="book_btns align-left">
                        Onix Transportation
                        <br />
                        +1 12522522
                    </td>
                    <td class="book_btns align-left">
                        Halle Berry
                        <br />
                        +1 12522522
                    </td>
                    <td class="book_btns align-left">
                        Halle Berry
                        <br />
                        +1 12522522
                    </td>
                    <td class="book_btns align-left">
                        June 14, 2021
                        <br />
                        2:35
                    </td>
                    <td class="book_btns align-left">1969 Thebchada rajkot</td>
                    <td class="book_btns align-left">1969 Thebchada rajkot</td>
                    <td class="book_btns align-left">$100.100</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
