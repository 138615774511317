import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'CarBaseAffiliation',
    templateUrl: './car-base-affiliation.component.html',
    styleUrls: ['./car-base-affiliation.component.scss'],
})
export class CarBaseAffiliationComponent implements OnInit {
    constructor(private httpService: HttpService) {}
    @Output() onCarAffiliationSave = new EventEmitter<any>();
    @Output() OnEntityChange = new EventEmitter<void>();
    @Input() placeholder: any;

    searchAffiliation: string = '';
    current_affiliation: any;
    searchControl: FormControl = new FormControl('');

    car_affiliation: Array<any>;
    limit: number = 10;
    totalCount: number;
    affiliation_loading: boolean = false;
    searchbox_highlight: boolean = false;

    ngOnInit(): void {
        if (this.placeholder) this.current_affiliation = this.placeholder;
        this.getCarAffiliation();

        this.searchControl.valueChanges.subscribe((newVal) => {
            if (newVal.trim() === this.searchAffiliation.trim()) return;

            this.searchAffiliation = newVal.trim();
            this.limit = 10;
            this.getCarAffiliation();
        });
    }

    scrollLock: boolean = false;
    public onScollMenu(event: any) {
        const scrollTop = event.srcElement.scrollTop;
        const offsetHeight = event.srcElement.offsetHeight;

        const position = scrollTop + offsetHeight;
        const max = event.srcElement.scrollHeight;
        if (this.totalCount > 10 && position >= max && !this.scrollLock) {
            // console.log("position => ", position, "max => ", max);
            this.limit += 10;
            if (this.limit > this.totalCount) {
                this.limit = this.totalCount;
            }
            // this.limit = ( this.limit + 10 ) % this.totalCount;
            // console.log(this.limit);
            this.scrollLock = true;
            this.getCarAffiliation(this.limit);
        }

        // for search box styling
        if (scrollTop == 0) this.searchbox_highlight = false;
        else this.searchbox_highlight = true;
    }

    public getCarAffiliation(limit = 10) {
        this.affiliation_loading = true;
        let params: any = { limit: limit };
        if (this.searchAffiliation) {
            params.searchFlag = 1;
            params.searchString = this.searchAffiliation;
        }
        this.httpService.post(environment.urlC + 'get_car_base', params).subscribe(
            (data) => {
                if (!data?.flag) {
                    this.car_affiliation = data?.carBase;
                    this.totalCount = data?.totalCount;
                    this.scrollLock = false;
                    // console.log("Car Affiliation ==> ", data);
                    this.affiliation_loading = false;
                }
            },
            (e) => {
                console.log('error => ', e);
            }
        );
    }

    public emitCarAffiliation(): void {
        this.onCarAffiliationSave.emit(this.current_affiliation);
    }

    public emitOnEntityChange() {
        this.OnEntityChange.emit();
    }

    public onCancel() {
        if (this.placeholder) this.current_affiliation = Object.assign({}, this.placeholder);
        else this.current_affiliation = null;
        this.emitCarAffiliation();
    }
}
