<div class="touch-popup" role="document">
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-4 mx-md-5 my-4 d-flex justify-content-between flex-column flex-md-row">
                <div class="medium-text dark-color">{{ title }}</div>
                <div
                    class="medium-text text-blue-secondary fs-13 ml-0 ml-md-2 cursor-pointer"
                    *ngIf="showHeaderSuffixLabel"
                    (click)="event.emit({ onSuffixHeaderClicked: true })"
                >
                    {{ showHeaderSuffixLabel }}
                </div>
            </div>
        </div>

        <hr class="light" />
        <div class="bg-light-blue">
            <div class="touch-content mx-4 mx-md-5 my-4 py-2">
                <form [formGroup]="contactForm">
                    <div class="d-flex my-2 justify-content-center">
                        <table>
                            <tr *ngIf="!mobileOnly">
                                <td>
                                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">
                                        Passenger
                                        <span class="d-md-inline-block d-none">Name</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="ml-2">
                                        <input
                                            [class.error]="contactForm.get('name')?.touched && contactForm.get('name')?.invalid"
                                            type="text"
                                            class="touch-input w-100 p-1 px-2"
                                            formControlName="name"
                                            placeholder="Passenger name here"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">
                                        Mobile
                                        <span class="d-md-inline-block d-none">Number</span>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        class="ml-2 touch-tel-input"
                                        [class.error]="contactForm.get('mobile')?.touched && contactForm.get('mobile')?.invalid"
                                    >
                                        <ngx-intl-tel-input
                                            [cssClass]="'custom'"
                                            [attr.autocomplete]="false"
                                            [resetSearchContainer]="true"
                                            [preferredCountries]="preferredCountries"
                                            [enableAutoCountrySelect]="true"
                                            [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                            [selectFirstCountry]="false"
                                            [selectedCountryISO]="selected_country_iso"
                                            [maxLength]="selected_country_iso == 'us' ? 10 : 12"
                                            [tooltipField]="TooltipLabel.Name"
                                            [phoneValidation]="true"
                                            [separateDialCode]="separateDialCode"
                                            name="phone"
                                            [inputId]="'edit-mobile'"
                                            formControlName="mobile"
                                            [customPlaceholder]="'Mobile Number'"
                                        ></ngx-intl-tel-input>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </form>
                <!-- <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div> -->
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom d-flex justify-content-center mx-5 my-3">
            <button class="btn btn-secondary btn-light text-white p-2 mr-2 br-6" (click)="bsModalRef.hide()">Cancel</button>
            <button [disabled]="contactForm.invalid" class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6" (click)="onSaveOrUpdate()">
                {{ actionButton }}
            </button>
        </div>
    </div>
</div>
