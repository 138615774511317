import { Injectable } from '@angular/core';

declare const google: any;

@Injectable({
    providedIn: 'root',
})
export class BannerOverlay extends google.maps.OverlayView {
    position: any;
    element: any;
    map: any;

    constructor(position: any, element: any, map: any) {
        super();
        this.position = position;
        this.element = element;
        this.map = map;
        this.setMap(map);
    }

    onAdd(): void {
        const panes = this.getPanes();
        panes.overlayLayer.style['zIndex'] = 1001;
        panes.overlayLayer.appendChild(this.element);
    }

    draw(): void {
        const overlayProjection = this.getProjection();
        const point = overlayProjection.fromLatLngToDivPixel(this.position);

        const elementWidth = this.element.offsetWidth;
        const elementHeight = this.element.offsetHeight;

        const offsetX = -elementWidth / 2; // To position the element horizontally centered
        const offsetY = -elementHeight - 30; // To position the element at the bottom

        const left = point.x + offsetX;
        const top = point.y + offsetY;

        this.element.style.left = left + 'px';
        this.element.style.top = top + 'px';
        this.element.style.zIndex = '1000'; // Set a higher z-index value for the overlay element
    }

    onRemove(): void {
        this.element.parentNode.removeChild(this.element);
    }
}
