import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'phoneFormat',
})
export class PhoneFormatPipe implements PipeTransform {
    transform(value: string): string {
        if (!value) {
            return 'NA';
        }

        // Remove all non-digit characters
        const cleaned = value.replace(/\D/g, '');

        // Format the cleaned phone number
        const match = cleaned.match(/^(\d{1})(\d{3})(\d{3})(\d{4})$/);
        if (match) {
            return `+${match[1]}-(${match[2]}) ${match[3]}-${match[4]}`;
        }

        // Return the original value if it can't be formatted
        return value;
    }
}
