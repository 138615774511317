<div
    class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg touch-popup"
    role="document"
    style="min-width: 510px !important; max-width: fit-content !important"
>
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-5 my-4">
                <div class="medium-text dark-color">Create a Payout</div>
            </div>
        </div>

        <hr class="light" />
        <div class="bg-light-blue pr-3 pr-md-5">
            <div class="touch-content mx-5 my-4 py-2">
                <div class="d-flex my-2 justify-content-end align-items-start">
                    <div class="mx-2 fs-13 dark-color fw-600">Confirm Payout</div>
                    <div class="ml-2 w-260 d-flex">
                        <mat-checkbox [formControl]="confirm_form"></mat-checkbox>
                        <div class="ml-1 cursor-pointer" (click)="confirm_form?.patchValue(true)">
                            Pay
                            <b>{{ total_payout | currency: 'USD':'symbol':utilityService.show_currency_precision }}</b>
                            for
                            <b>{{ total_trips }} assigned trips</b>
                            from your
                            <b>
                                {{ available_wallet_balance | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </b>
                            available balance.
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
            <button class="btn btn-secondary btn-dark text-white p-2 mx-2 br-6" (click)="bsModalRef.hide()">Dismiss</button>
            <button
                [disabled]="confirm_form?.invalid || balanceLoading"
                class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6"
                (click)="create_payout()"
            >
                Create Payout
            </button>
        </div>
    </div>
</div>
