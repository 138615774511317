import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as moment from 'moment';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { UserService } from 'src/app/corporate/services/user/user.service';

export interface IRiderDriverInoDetail {
    full_name: string;
    gender: number;
    age?: number;
    dob: Date;
    country: string;
    state: string;
    zipcode: string;

    total_lapsed: number;
    total_request: number;
    total_cancelled: number;
    total_completed: number;
    total_regular: number;
    total_scheduled: number;
}

@Component({
    selector: 'app-rider-driver-info',
    templateUrl: './rider-driver-info.component.html',
    styleUrls: ['./rider-driver-info.component.scss'],
})
export class RiderDriverInfoComponent implements OnInit {
    @Input() type: 'driver' | 'rider' = 'rider';
    @Input() id: number; // driver or rider id based on the type
    @Output() refetch: EventEmitter<void> = new EventEmitter<void>();
    @Input() info_detail: IRiderDriverInoDetail;

    gender_form: FormControl = new FormControl();
    gender_list: string[] = ['male', 'female', 'other'];
    gender_popover_reference: PopoverDirective;

    rider_driver_information_show: boolean = true;
    ride_information_show: boolean = true;

    constructor(private utilityService: UtilityService, private userService: UserService) {}

    ngOnInit(): void {
        if (this.info_detail.dob) {
            this.info_detail.age = moment().diff(new Date(this.info_detail?.dob), 'years', false);
        }
    }

    update_gender() {
        if (!this.gender_form?.value) return;
        const params:
            | {
                  gender: number;
                  qudos_user_id?: number;
                  qudos_user_type?: number;
              }
            | any = {
            gender: this.gender_form?.value,
            qudos_user_id: this.id,
        };
        if (this.type === 'rider') {
            params.qudos_user_type = 0;
        } else if (this.type === 'driver') {
            params.qudos_user_type = 1;
        }

        this.utilityService.loading = true;
        this.userService
            .edit_associated_user_or_driver(params)
            .subscribe(
                (res) => {
                    if (res?.flag === 2203) {
                        this.refetch.emit();
                        this.utilityService.toast('success', res?.log);
                    } else {
                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.utilityService.loading = false;
            });
    }
}
