import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { map } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IFareEstimateParams } from 'src/app/models/trips.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import * as StaticDataConfig from './../../../core/services/utility/static-data.config';
import { IFareEstimate, ISuggestedFare } from 'src/app/models/fare_estimate.model';
import { Observable } from 'rxjs';
import { GetDriverData } from 'src/app/models/driver.model';
import { GoogleMapService } from '../google-map/google-map.service';
@Injectable({
    providedIn: 'root',
})
export class TripBookingService {
    constructor(
        private httpService: HttpService,
        private utilityService: UtilityService,
        private cookieService: CookieService,
        private googleMapService: GoogleMapService
    ) {}
    carTypes: any = StaticDataConfig.CarTypes;

    public getFareEstimate(params: IFareEstimateParams): Observable<IFareEstimate.FareEstimate> {
        return this.httpService.postJSON(environment.urlC + 'fare_estimate', params);
    }

    public getDriversData(params: { user_id: any; car_type: any; essential: any }, loading: boolean = false): Observable<GetDriverData.DriverData> {
        if (loading) this.utilityService.loading = true;
        return this.httpService.post(environment.urlC + 'get_ride_data', {
            web_access_token: this.cookieService.get('web_access_token'),
            user_id: params?.user_id,
            region_id: 24,
            car_type: params?.car_type,
            is_essential: params?.essential,
        });
    }

    public async getTripImage(
        cord_a: { lat: number | string; long: number | string },
        cord_b: { lat: number | string; long: number | string },
        avoidTolls: boolean = this.googleMapService.defaultAvoidTolls,
        avoidHighways: boolean = this.googleMapService.defaultAvoidHighways
    ) {
        let directionsService = new google.maps.DirectionsService();
        const origin = cord_a.lat + ', ' + cord_a.long;
        const destination = cord_b.lat + ', ' + cord_b.long;

        const request: google.maps.DirectionsRequest = {
            origin: origin,
            destination: destination,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls,
            avoidHighways,
        };
        let polylines = '0';
        return new Promise(async (resolve, reject) => {
            const { routes } = await directionsService.route(request);
            polylines = routes[0].overview_polyline;
            let imgSourcePath =
                'https://maps.googleapis.com/maps/api/staticmap?size=600x600&path=color:0x00000cd0|weight:5|enc:' +
                polylines +
                '&markers=shadow:false|scale:2|color:green|label:A|' +
                cord_a.lat +
                ',' +
                cord_a.long +
                '&markers=color:red|label:B|shadow:false|scale:2|' +
                cord_b.lat +
                ',' +
                cord_b.long +
                '&key=AIzaSyADXfBi40lkKpklejdGIWNxdkqQCKz8aKI';
            resolve({ image: imgSourcePath, polylines });
        });
    }

    public cancelRide(session_id: number | any) {
        return this.httpService.post(environment.urlC + 'cancel_ride', {
            web_access_token: this.cookieService.get('web_access_token'),
            session_id,
        });
    }

    getSuggestedFare(payload: {
        pickup_latitude: string;
        pickup_longitude: string;
        destination_latitude: string;
        destination_longitude: string;
        car_type?: number;
        avoid_tolls?: number;
        avoid_highways?: number | boolean;
        fare_factor?: string;
        mas_trip_id?: string;
    }) {
        if (payload.avoid_highways == null) {
            payload.avoid_highways = this.googleMapService.defaultAvoidHighways ? 1 : 0;
        }
        console.log(payload);
        return this.httpService
            .postJSON(environment.urlC + 'get_suggested_fare', {
                web_access_token: this.cookieService.get('web_access_token'),
                ...payload,
            })
            .pipe(
                map((res: ISuggestedFare) => {
                    if (res) {
                        // Current need at prepaid section
                        res.driver_network_fee_amount = (res?.submerchant_driver_payout * res?.driver_network_fee) / 100;
                        res.total_amount = res?.driver_network_fee_amount + res?.submerchant_driver_payout + res?.toll;
                    }
                    return res;
                })
            );
    }
}
