<span
    class="cursor-pointer"
    [popover]="select_car_driver_popover"
    #selectCarTypePopover="bs-popover"
    containerClass="br-6 z-index-1000 w-260"
    placement="bottom"
    [outsideClick]="true"
    (onShown)="select_car_type_popover_reference = selectCarTypePopover"
>
    <ng-content></ng-content>
</span>

<ng-template #select_car_driver_popover>
    <div class="touch-popover touch-container">
        <div class="bg-grayish-blue p-4 fs-14 text-dark-blue fw-600">Select Options</div>
        <div class="touch-box">
            <div class="touch-menu auto">
                <ng-container *ngFor="let item of carTypes">
                    <div
                        *ngIf="item?.value_regular"
                        class="fw-normal touch-item cursor-pointer d-flex justify-content-between"
                        [class.active-item]="item?.car_type === selectedCarType"
                        (click)="selectCarType(item)"
                    >
                        <div class="text-grey fw-600">{{ item?.car_name }}</div>
                        <div class="text-dark-blue">
                            {{ item?.value_regular | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                        </div>
                    </div>
                </ng-container>
            </div>
        </div>
        <hr class="my-0 py-0" />
        <div class="d-flex justify-content-center text-blue-secondary fs-13 fw-600 my-4">
            <div class="cursor-pointer" (click)="select_car_type_popover_reference.hide()">Dismiss</div>
        </div>
    </div>
</ng-template>
