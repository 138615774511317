import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import * as moment from 'moment';
import { debounceTime, skip } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IRideAnalytics } from 'src/app/models/business-center.model';
import { BusinessCenterService } from '../../services/business-center/business-center.service';
import { Subject } from 'rxjs';

type ViewType = 'Daily' | 'Weekly' | 'Monthly' | 'Custom';

@Component({
    selector: 'app-ride-earning-overview',
    templateUrl: './ride-earning-overview.component.html',
    styleUrls: ['./ride-earning-overview.component.scss'],
    providers: [{ provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy }],
})
export class RideEarningOverviewComponent implements OnInit, OnChanges {
    constructor(public utilityService: UtilityService, private businessCenterService: BusinessCenterService) {}
    ride_analytics: IRideAnalytics;

    @Input() hideRiderAnalytics: boolean = false;
    @Input() hidePrepaidLabel: boolean = false;

    @Input() prepaidDate: FormControl = new FormControl();
    @Input() refetchTrigger: Subject<void>;

    view_type_list: Array<ViewType> = ['Daily', 'Weekly', 'Monthly', 'Custom'];
    view_type: ViewType = 'Daily';
    date_range_form: FormGroup = new FormGroup({
        start: new FormControl(),
        end: new FormControl(),
    });

    show_ride: number = 0;
    show_gross_payout: number = 0;
    show_driver_payout: number = 0;

    ngOnInit(): void {
        this.date_range_form.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (value?.start && value?.end && this.view_type === 'Custom') {
                this.get_ride_analytics();
            }
        });

        if (this.prepaidDate) {
            this.prepaidDate.valueChanges.pipe().subscribe((value) => {
                if (value) {
                    this.get_ride_analytics();
                }
            });

            this.refetchTrigger.subscribe(() => {
                this.get_ride_analytics();
            });
        }

        this.get_ride_analytics();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes.prepaid_start_date?.currentValue || changes.prepaid_end_date?.currentValue) {
            this.get_ride_analytics();
        }
    }

    get_ride_analytics() {
        const payload: {
            start_date?: string;
            end_date?: string;
            prepaid_start_date?: string;
            prepaid_end_date?: string;
        } = {};
        if (this.date_range_form?.value?.start && this.date_range_form?.value?.end && this.view_type === 'Custom') {
            payload.start_date = moment(this.date_range_form?.value?.start).startOf('day').toISOString();
            payload.end_date = moment(this.date_range_form?.value?.end).endOf('day').toISOString();
        }
        if (this.prepaidDate?.value) {
            payload.prepaid_start_date = moment(this.prepaidDate?.value).startOf('day').toISOString();
            payload.prepaid_end_date = moment(this.prepaidDate?.value).endOf('day').toISOString();
        }

        this.businessCenterService.get_rider_analytics(payload).subscribe((res: IRideAnalytics) => {
            this.ride_analytics = res;
            this.update_rider_analytics(this.view_type);
        });
    }

    update_rider_analytics(type: ViewType, date_picker_ref: any = null) {
        this.view_type = type;
        switch (type) {
            case 'Daily':
                this.show_ride = this.ride_analytics.daily_ride_total;
                this.show_driver_payout = this.ride_analytics.daily_driver_pay_total;
                this.show_gross_payout = this.ride_analytics.daily_gross_payout_total;
                this.date_range_form.patchValue(
                    {
                        start: new Date(),
                        end: new Date(),
                    },
                    { emitEvent: false }
                );
                break;
            case 'Weekly':
                this.show_ride = this.ride_analytics.week_ride_total;
                this.show_driver_payout = this.ride_analytics.week_driver_pay_total;
                this.show_gross_payout = this.ride_analytics.week_gross_payout_total;
                this.date_range_form.patchValue(
                    {
                        start: new Date(moment().startOf('week').toString()),
                        end: new Date(moment().endOf('week').toString()),
                    },
                    { emitEvent: false }
                );
                break;
            case 'Monthly':
                this.show_ride = this.ride_analytics.month_ride_total;
                this.show_driver_payout = this.ride_analytics.month_driver_pay_total;
                this.show_gross_payout = this.ride_analytics.month_gross_payout_total;
                this.date_range_form.patchValue(
                    {
                        start: new Date(moment().startOf('month').toString()),
                        end: new Date(moment().endOf('month').toString()),
                    },
                    { emitEvent: false }
                );
                break;

            case 'Custom':
                this.show_ride = this.ride_analytics.custom_ride_total;
                this.show_driver_payout = this.ride_analytics.custom_driver_pay_total;
                this.show_gross_payout = this.ride_analytics.custom_gross_payout_total;
                if (date_picker_ref) date_picker_ref.open();
                break;
        }
    }
}
