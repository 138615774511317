import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { environment } from 'src/environments/environment';
import { TripBookingService } from '../../services/trip-booking/trip-booking.service';
import { FormControl, Validators } from '@angular/forms';
import { Subscription } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';

@Component({
    selector: 'app-recalculate-fare-estimate',
    templateUrl: './recalculate-fare-estimate.component.html',
    styleUrls: ['./recalculate-fare-estimate.component.scss'],
})
export class RecalculateFareEstimateComponent implements OnInit {
    constructor(
        private tripBookingService: TripBookingService,
        private utilityService: UtilityService,
        public bsModalRef: BsModalRef,
        private httpService: HttpService
    ) {}

    fare_factor: number = 1;
    fare_breakdown: any;
    is_mas_trip: number;
    corporate_addon: any;
    ride_discount: any;
    recalculate_type: 'now' | 'later' = 'now';
    fare_estimate_params: any;
    mas_trip_id: any;
    sales_tax: any;
    totalGrossValue: any;
    recalculate_price_loading: boolean = false;
    select_button_flag: boolean = true;
    premium_option: any;
    car_type: number = 1;
    corporateAddOnValue: FormControl = new FormControl(null, [Validators.min(1), Validators.max(100)]);
    savedcorporateAddOnValue: any = 0;
    recalculate_options: any = [];
    recalculate_state: any;
    custom_recalculate_multiplier: boolean = false;
    private subscriptionList$: Subscription = new Subscription();
    public event: EventEmitter<any> = new EventEmitter();
    isCorporateAddOn: boolean = false;
    @ViewChild('modalInput') modalInput: any;
    @ViewChild('modalInputDiscountPin') modalInputDiscountPin: any;
    @ViewChild('modalInputDiscount') modalInputDiscount: any;
    ismultipliear: boolean = true;

    // Ride Discount
    idPinValidate: boolean = false;
    discountPin: FormControl = new FormControl(null, [Validators.min(3), Validators.max(4)]);
    rideDiscountControl: FormControl = new FormControl(null, [Validators.min(1), Validators.max(100)]);

    ngOnInit(): void {
        this.getMultiplier();
        this.get_new_fare_estimateion(this.fare_factor);
        this.savedcorporateAddOnValue = this.corporate_addon;
        this.corporateAddOnValue.setValue(this.corporate_addon);
        this.discountPin.setValue(null);
        this.rideDiscountControl.setValue(this.ride_discount);
    }

    toggle_recalculate_type() {
        this.custom_recalculate_multiplier = !this.custom_recalculate_multiplier;
        if (this.custom_recalculate_multiplier) this.select_button_flag = true;
        if (this.fare_factor === 1) return;

        this.fare_factor = 1;
        this.get_new_fare_estimateion(1);
    }

    public get_new_fare_estimateion(new_fare_factor: any) {
        this.select_button_flag = false;
        if (new_fare_factor < 0) return;
        this.fare_estimate_params.fare_factor = new_fare_factor;
        this.recalculate_price_loading = true;
        if (this.premium_option) {
            this.fare_estimate_params['premium_options'] = this.premium_option;
        }
        this.tripBookingService
            .getFareEstimate(this.fare_estimate_params)
            .subscribe(
                (data) => {
                    if (data.flag != 101 && data.flag != 1316 && data.flag != 1317 && !(data.error || data.flag == 0)) {
                        const cars_option = data.estimated_fare;
                        const current_car_option: any = cars_option.find((option) => option.car_type === this.fare_estimate_params.car_type);
                        this.recalculate_state = current_car_option;
                    } else {
                        this.utilityService.toast('warning', 'Something went wrong');
                    }
                },
                (error) => {
                    this.utilityService.alert('error', 'Something went Wrong!');
                    this.utilityService.loading = false;
                }
            )
            .add(() => {
                this.recalculate_price_loading = false;
            });
    }

    apply() {
        this.bsModalRef.hide();
        const value = {
            type: this.fare_factor,
            corporateAddon: this.savedcorporateAddOnValue,
            finalFareEstimate: this.totalGrossValue,
            isApply: true,
            ride_discount: this.rideDiscountControl.value,
        };
        this.event.emit(value);
    }

    closeModal() {
        this.bsModalRef.hide();
        const value = {
            type: this.fare_factor,
            corporateAddon: this.savedcorporateAddOnValue,
            finalFareEstimate: this.totalGrossValue,
            isApply: this.isCorporateAddOn,
            ride_discount: this.rideDiscountControl.value,
        };
        this.event.emit(value);
    }

    /** Corporate Add On */
    corporateAddOn() {
        // FOr MAS Trip only
        if (this.mas_trip_id) {
            this.recalculate_price_loading = true;
            const params: any = {
                web_access_token: this.fare_estimate_params.web_access_token,
                mas_trip_id: this.mas_trip_id,
                corporate_addon: this.corporateAddOnValue.value,
            };
            this.finalGrossValue();
            params.gross_fare_amount = this.totalGrossValue;
            params.sale_tax = this.sales_tax;
            params.old_gross_fare_amount =
                this.recalculate_type === 'now' ? this.recalculate_state?.value_regular : this.recalculate_state.value_scheduled;
            this.subscriptionList$.add(
                this.httpService
                    .postJSON(environment.urlWC + 'edit_corporate_addon', params)
                    .subscribe((data) => {
                        if (data.flag === 1329) {
                            this.savedcorporateAddOnValue = this.corporateAddOnValue.value;
                            this.fare_estimate_params.corporate_addon = this.corporateAddOnValue.value;
                            this.utilityService.toast('success', data?.log);
                            this.get_new_fare_estimateion(this.fare_factor);
                            this.utilityService.sendApplyButtonClick();
                        } else {
                            this.savedcorporateAddOnValue = 0;
                            this.utilityService.toast('error', data?.message || 'Something went wrong');
                        }
                    })
                    .add(() => {
                        this.recalculate_price_loading = false;
                        this.isCorporateAddOn = true;
                    })
            );
        } else {
            // For queued trip
            this.savedcorporateAddOnValue = this.corporateAddOnValue.value;
            this.fare_estimate_params.corporate_addon = this.corporateAddOnValue.value;
            this.get_new_fare_estimateion(this.fare_factor);
        }
    }

    /** Final Gross Value update  */
    finalGrossValue() {
        let percentage = (this.sales_tax * this.corporateAddOnValue.value) / 100;
        if (this.recalculate_type === 'now') {
            this.totalGrossValue = percentage + (this.corporateAddOnValue.value - this.corporate_addon) + this.recalculate_state?.value_regular;
        } else {
            this.totalGrossValue = percentage + (this.corporateAddOnValue.value - this.corporate_addon) + this.recalculate_state.value_scheduled;
        }
    }

    /** Dismiss Corporate add on */
    dismissCorporateAddon() {
        if (this.corporateAddOnValue.value == null || this.corporateAddOnValue.value == undefined) {
            this.corporateAddOnValue.setValue(0);
        }
    }

    /** Open Modal */
    openModal() {
        setTimeout(() => {
            const inputValue = this.modalInput.nativeElement.value;
            if (inputValue === '0') {
                // Remove the 0
                this.modalInput.nativeElement.value = null;
            }
            // Focus the input
            this.modalInput.nativeElement.focus();
        }, 0);
    }

    /** Open Modal Ride */
    openModalRide() {
        console.log('this.idPinValidate', this.idPinValidate);
        setTimeout(() => {
            // Focus the input
            if (this.idPinValidate) {
                const inputValue = this.modalInputDiscount.nativeElement.value;
                if (inputValue == '0') {
                    // Remove the 0
                    this.modalInputDiscount.nativeElement.value = null;
                }
                this.modalInputDiscount.nativeElement.focus();
            } else {
                const inputValue = this.modalInputDiscountPin.nativeElement.value;
                if (inputValue == '0') {
                    // Remove the 0
                    this.modalInputDiscountPin.nativeElement.value = null;
                }
                this.modalInputDiscountPin.nativeElement.focus();
            }
        }, 0);
    }

    /** Manage Zero */
    handleInputClick() {
        const inputValue = this.corporateAddOnValue.value;
        if (inputValue == '0') {
            // If the value is "zeno," set it to an empty string or null
            this.corporateAddOnValue.setValue(null); // or this.corporateAddOnValue.setValue('');
        }
    }

    /** Multiplier Get */
    getMultiplier() {
        this.subscriptionList$.add(
            this.httpService
                .postJSON(environment.urlC + 'mapped_fare_estimate', this.fare_estimate_params)
                .subscribe((data) => {
                    this.recalculate_options = data;
                    this.ismultipliear = false;
                })
                .add(() => {
                    this.ismultipliear = false;
                })
        );
    }

    /** Apply PIN */
    applyDiscount(type: string) {
        if (type === 'pin') {
            if (this.discountPin.value == 1221) {
                this.idPinValidate = true;
                setTimeout(() => {
                    // Focus the input
                    const inputValue = this.modalInputDiscount.nativeElement.value;
                    if (inputValue == '0') {
                        // Remove the 0
                        this.modalInputDiscount.nativeElement.value = null;
                    }
                    this.modalInputDiscount.nativeElement.focus();
                }, 0);
            } else {
                this.utilityService.toast('error', 'Please enter a valid 4-digit PIN.');
                this.modalInputDiscountPin.nativeElement.focus();
            }
        } else {
            // FOr MAS Trip only
            if (this.mas_trip_id) {
                this.recalculate_price_loading = true;
                const payload = {
                    mas_trip_id: this.mas_trip_id,
                    web_access_token: this.fare_estimate_params.web_access_token,
                    ride_discount: this.rideDiscountControl.value,
                    gross_fare_amount: this.recalculate_state?.value_regular,
                    submerchant_client_payout: this.recalculate_state.submerchant_client_payout,
                    other_fees: this.recalculate_state.other_fees,
                };
                this.subscriptionList$.add(
                    this.httpService
                        .postJSON(environment.urlWC + 'edit_ride_discount', payload)
                        .subscribe((data) => {
                            if (data.flag === 1329) {
                                this.ride_discount = this.rideDiscountControl.value;
                                this.fare_estimate_params.ride_discount = this.rideDiscountControl.value;
                                this.utilityService.toast('success', data?.log);
                                this.get_new_fare_estimateion(this.fare_factor);
                            } else {
                                this.utilityService.toast('error', data?.message || 'Something went wrong');
                            }
                        })
                        .add(() => {
                            this.recalculate_price_loading = false;
                        })
                );
            } else {
                this.fare_estimate_params.ride_discount = this.rideDiscountControl.value;
                this.get_new_fare_estimateion(this.fare_factor);
            }
        }
    }

    /** DisMiss Discount */
    dismissDiscount() {
        this.discountPin.setValue(null);
        this.rideDiscountControl.setValue(this.ride_discount);
    }
}
