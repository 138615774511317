<!-- New Design -->
<div class="confirm-modal">
    <div class="modal-content">
        <div class="touch-popup">
            <div class="touch-header">
                <div class="mx-5 my-4">
                    <div
                        class="large-medium-text dark-color"
                        [ngClass]="{ success: headingType == 'success', warning: headingType == 'warning', error: headingType == 'error' }"
                    >
                        {{ heading }}
                    </div>
                </div>
            </div>

            <hr class="light" />
            <div class="bg-light-blue">
                <div class="touch-content p-5">
                    <div class="fs-14 fw-600 text-dark-blue" [innerHtml]="description"></div>
                </div>
            </div>

            <hr class="light" />
            <div class="bg-light-blue br-8">
                <div class="touch-bottom p-3 pr-3 pr-md-5" align="right">
                    <ng-container *ngIf="styleType === 'default'">
                        <button
                            *ngIf="leftButtonText"
                            class="btn btn-secondary btn-light text-white p-2 mx-2 br-6"
                            data-dismiss="modal"
                            (click)="on_button_action('left')"
                        >
                            {{ leftButtonText }}
                        </button>
                        <button
                            *ngIf="rightButtonText"
                            data-dismiss="modal"
                            class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6"
                            (click)="on_button_action('right')"
                        >
                            {{ rightButtonText }}
                        </button>
                    </ng-container>

                    <ng-container *ngIf="styleType == 'primary'">
                        <button
                            *ngIf="leftButtonText"
                            class="btn btn-secondary border-0 btn-left m-2 px-3 py-1"
                            data-dismiss="modal"
                            style="background-color: #8692a6 !important"
                            (click)="on_button_action('left')"
                        >
                            {{ leftButtonText }}
                        </button>

                        <button
                            *ngIf="rightButtonText"
                            class="btn btn-primary border-0 btn-right m-2 px-3 py-1"
                            style="background-color: #1f8dc6 !important"
                            (click)="on_button_action('right')"
                            data-dismiss="modal"
                        >
                            {{ rightButtonText }}
                        </button>
                    </ng-container>
                </div>
            </div>
        </div>
    </div>
</div>
