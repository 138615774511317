import { Component, ElementRef, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, first, map } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import * as StaticDataConfig from './../../../core/services/utility/static-data.config';
import { BsModalService, BsModalRef, ModalOptions } from 'ngx-bootstrap/modal';
import { FareBreakdownComponent } from '../fare-breakdown/fare-breakdown.component';
import { TripBookingService } from '../../services/trip-booking/trip-booking.service';
import { CardsService } from '../../services/cards/cards.service';
import { Card } from 'src/app/models/card.model';
import { SelectCardsComponent } from '../select-cards/select-cards.component';
import * as _ from 'lodash';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { RecalculateFareEstimateComponent } from '../recalculate-fare-estimate/recalculate-fare-estimate.component';
import { PromoCodeComponent } from '../promo-code/promo-code.component';
import { PremiumOptionsComponent } from '../premium-options/premium-options.component';
import { PhoneNumberModalComponent } from '../phone-number-modal/phone-number-modal.component';
import { UserService } from '../../services/user/user.service';
import { Router } from '@angular/router';
import { MasService } from '../../services/mas/mas.service';
import { GoogleMapService } from '../../services/google-map/google-map.service';
declare var google: any;
@Component({
    selector: 'app-fare-estimate',
    templateUrl: './fare-estimate.component.html',
    styleUrls: ['./fare-estimate.component.scss'],
})
export class FareEstimateComponent implements OnInit, OnChanges {
    min_ride_request_distance: any;
    max_schedule_ride_days_count: any;
    max_schedule_ride_request_distance: number;
    max_ride_request_distance: number;
    ride_estimate_distance: any;
    cars_option: any[];
    carDriverOptions: Array<any> = [];
    mas_total_leg_cost: number;
    notFav: number;
    session_id: any;
    essential: any = 0;

    active_fare_breakdown_flag: 'now' | 'later' = 'now';

    corporateCards: {
        default_card_id?: string | number;
        cards: Array<Card>;
    };
    riderCards: {
        default_card_id?: string | number;
        cards: Array<Card>;
    };
    disable_request_confirmation_action: boolean = false;
    promo_applied: any;

    @ViewChild('fare_estimate_popup') fareEstimateTemplateRef: TemplateRef<any>;
    @ViewChild('map') mapElement: ElementRef;
    map: google.maps.Map;
    trip_distance: string | number;
    trip_duration: string | number;

    constructor(
        public utilityService: UtilityService,
        public httpService: HttpService,
        public cookieService: CookieService,
        private modalService: BsModalService,
        private tripBookingService: TripBookingService,
        private cardsService: CardsService,
        private userService: UserService,
        private router: Router,
        private masService: MasService,
        private googleMapService: GoogleMapService
    ) {}

    // Input
    mas_active_trip: any;
    mas_both_leg_trip: any;
    @Input() updateQueueTripModal!: any;
    openFromQueueModal: boolean = false;
    @Output() onBookingQueued: EventEmitter<{ estimated_fare: any; corporate_addon: any; fare_factor: any }> = new EventEmitter<{
        estimated_fare: any;
        corporate_addon: any;
        ride_discount: any;
        fare_factor: any;
    }>();
    // Output
    @Output() onBooking: EventEmitter<any> = new EventEmitter<any>();
    @Output() onSaveFare: EventEmitter<{
        value: any;
        fare_factor: any;
        driver_payout: any;
        avoid_highways: any;
        avoid_tolls: any;
    }> = new EventEmitter<{
        value: any;
        fare_factor: any;
        driver_payout: any;
        avoid_highways: any;
        avoid_tolls: any;
    }>();
    @Output() onCreatePayout: EventEmitter<any> = new EventEmitter<any>();
    modalRef?: BsModalRef;

    windowSize: { width?: number; height?: number } = {};
    mobileSize: boolean = false;
    fareEstimatePriceLoading: boolean = false;
    fare_factor: number = 1; // value which will be shared for api parameters
    fare_factor_display: number = 1; // value which will be used to display the multiplier in ui.
    @Input() booking: any = {
        user_mobile: localStorage.getItem('book-ride-for'),
        proceed: 0,
    };
    promoCountryCode: any = '+1-';
    minutesList: Array<number> = Array.from({ length: 60 }, (_, x) => x);

    hoursList: Array<{ value: string; title: string }> = Array.from({ length: 24 }, (_, x: number) => {
        const x_string = x < 10 ? '0' + x.toString() : x.toString();
        return { value: x_string, title: `${x_string} (${x % 12 || 12} ${x < 12 ? 'AM' : 'PM'} )` };
    });

    fare_breakdown: any = {};
    avoid_tolls: boolean = this.googleMapService.defaultAvoidTolls;
    avoid_highways: boolean = this.googleMapService.defaultAvoidHighways;

    selected_driver: any;
    driverSearchControl: FormControl = new FormControl('');
    drivers: Array<any> = [];
    requestPending = 0;
    recalculate_price_loading: boolean = false;

    premiumOption: FormGroup = new FormGroup({
        pet_friendly_car: new FormControl(false),
        plexiglass_partition: new FormControl(false),
        through_door_service: new FormControl(false),
    });
    premiumOption_Backup: any;
    activeOption: string;
    premium_option_confirmation = StaticDataConfig.PremiumOptionConfirmation;
    premiumOptionLabel: string;

    payment_from: 'card' | 'wallet' = 'card';

    ngOnInit(): void {
        this.driverSearchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            value = value.trim();
            this.getDriver(value);
        });
        this.getDriver();
        this.directionsDisplay = new google.maps.DirectionsRenderer();
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.booking?.currentValue) {
            this.booking = changes?.booking?.currentValue;
            this.booking.autoHitApi = 1;
            this.avoid_tolls = this.booking.avoid_tolls == 1 ? true : false;
            this.avoid_highways = this.booking.avoid_highways == 1 ? true : false;
            if (this.booking?.is_mas_trip) {
                this.mas_active_trip = this.booking?.mas_active_leg_trip;
                this.mas_both_leg_trip = this.booking?.mas_both_leg_trip;
                this.mas_total_leg_cost =
                    this.masService.calculate_total_leg_cost(
                        this.mas_active_trip.legMileage,
                        this.mas_active_trip?.legCost,
                        this.mas_active_trip?.secondary_services || []
                    ) || 0;
                this.mas_active_trip.mas_total_leg_cost = this.mas_total_leg_cost;
            }

            if (this.booking.selected_driver && this.booking.selected_driver?.driver_id) {
                this.selected_driver = this.booking.selected_driver;
                this.booking.driver_id = this.selected_driver.driver_id;
            }
            this.booking.is_fav = 1;
            this.booking.offset = '330';
            this.booking.offset = new Date().getTimezoneOffset() * -1;
            this.booking.card_type = this.cardsService.userCardPaymentOption;

            if (!this.booking?.user_mobile) {
                this.open_phone_number_popup();
            } else {
                this.getFareEstimate();
            }
        }

        for (const propName in changes) {
            if (!!changes[propName].currentValue && propName == 'updateQueueTripModal') {
                console.log(changes[propName].currentValue.car_type);
                this.booking.pickup_latitude = changes[propName].currentValue.pickup_lat;
                this.booking.pickup_longitude = changes[propName].currentValue.pickup_lang;
                this.booking.destination_latitude = changes[propName].currentValue.dropoff_lat;
                this.booking.destination_longitude = changes[propName].currentValue.dropoff_lang;
                this.booking.car_type = changes[propName].currentValue.car_type;
                this.booking.user_mobile = `${changes[propName].currentValue?.phone_number?.dialCode}-${changes[propName].currentValue?.phone_number?.number}`;
                this.booking.pickup_city = changes[propName].currentValue.pickup_location;
                this.booking.pickup_location_address = changes[propName].currentValue.pickup_location;
                this.booking.manual_destination_address = changes[propName].currentValue.dropoff_location;
                this.booking.pickup_country = changes[propName].currentValue.phone_number.countryCode;
                this.booking.autoHitApi = 1;
                this.openFromQueueModal = true;
                this.getFareEstimate();
            }
        }
    }

    // for far_estimate
    getDriver(searchString: string = '') {
        this.httpService
            .post(environment.urlC + 'get_fav_driver', {
                web_access_token: this.cookieService.get('web_access_token'),
                limit: 20,
                offset: 0,
                searchFlag: searchString ? 1 : 0,
                searchString,
                tab_type: this.utilityService.MAS_Qudosfave_Map[this.booking?.is_mas_trip === 'mas' ? 'MAS Drivers' : 'All Drivers'],
            })
            .subscribe((data: any) => {
                if (data?.flag !== 101 && data?.flag !== 807) {
                    this.drivers = data?.drivers;
                }
            });
    }

    getPremiumOption() {
        let po = this.premiumOption.value;
        const keys = Object.keys(po);
        let premium_option = {};
        let flag: boolean = false;
        keys.forEach((k) => {
            if (po[k]) {
                flag = true;
                premium_option[k] = 1;
            } else {
                premium_option[k] = 0;
            }
        });
        if (flag) return premium_option;
        return false;
    }

    private resetPremiumOption() {
        this.premiumOption.reset();
        this.updatePremiumLabel();
    }

    updateOption(clear = false) {
        if (_.isEqual(this.premiumOption.value, this.premiumOption_Backup)) return;

        const po = this.premiumOption.value;

        if (clear) {
            this.resetPremiumOption();
        } else if (po['pet_friendly_car'] || po['plexiglass_partition'] || po['through_door_service']) {
            this.updatePremiumLabel('Added');
        } else {
            this.updatePremiumLabel();
        }
        this.getFareEstimate();
    }

    public cancelOption() {
        this.premiumOption.patchValue({ ...this.premiumOption_Backup });
    }

    private updatePremiumLabel(text?) {
        setTimeout(() => {
            if (text) this.premiumOptionLabel = 'Added';
            else this.premiumOptionLabel = null;
        }, 50);
    }

    public async getFareEstimate(clicked = 0, loading: boolean = true) {
        this.fareEstimatePriceLoading = true;
        if (loading) {
            this.utilityService.loading = true;
        }

        // check for mobile

        const check_mobile = await this.userService.check_mobile(this.booking.user_mobile, this.booking.user_name).pipe(first()).toPromise();
        if (check_mobile?.error && check_mobile?.flag === 1316) {
            await this.userService.new_user_register(this.booking.user_mobile, this.booking.user_name, 1).pipe(first()).toPromise();
        }

        const geocoder = new google.maps.Geocoder();

        if (this.booking.pickup_location_address || (this.booking.current_latitude && this.booking.current_longitude)) {
            const geoCoderPayload: { address?: string; location?: { lat: number; lng: number } } = {};
            if (this.booking.pickup_location_address) {
                geoCoderPayload.address = this.booking.pickup_location_address;
            }
            if (this.booking.current_latitude) {
                geoCoderPayload.location = {
                    lat: parseFloat(this.booking.current_latitude),
                    lng: parseFloat(this.booking.current_longitude),
                };
            }
            try {
                const geocoderResponse: any = await geocoder.geocode(geoCoderPayload);
                if (geocoderResponse.results.length) {
                    const place: any = geocoderResponse.results[0];
                    place.address_components.find((item) => {
                        if (item.types.includes('locality')) {
                            this.booking.pickup_city = item.long_name;
                        }
                        if (item.types.includes('country')) {
                            this.booking.pickup_country = item.long_name;
                        }
                    });
                }
            } catch (error) {
                this.booking.pickup_city = 'New York';
                this.booking.country = 'United States';
                console.log('Err', error);
            }
        }

        let params: any = {
            is_scheduled: 0,
            pickup_latitude: this.booking.pickup_latitude,
            pickup_longitude: this.booking.pickup_longitude,
            destination_latitude: this.booking.destination_latitude,
            destination_longitude: this.booking.destination_longitude,
            promo_code: this.booking.promo_code,
            web_access_token: this.cookieService.get('web_access_token'),
            car_type: this.booking.car_type,
            user_mobile: this.booking.user_mobile,
            avoid_tolls: this.avoid_tolls ? 1 : 0,
            avoid_highways: this.avoid_highways ? 1 : 0,
            fare_factor: this.fare_factor,
            pickup_city: this.booking.pickup_city,
            pickup_country: this.booking.pickup_country,
            mas_trip_id: this.booking?.mas_trip_id,
        };

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            params['premium_options'] = premium_option;
        }

        params.ride_discount = this.booking?.ride_discount;
        params.corporate_addon = this.booking?.corporate_addon;
        this.tripBookingService.getFareEstimate(params).subscribe(
            (data) => {
                if (data.flag == 101) {
                } else if (data.flag == 1316) {
                    this.utilityService.loading = false;
                    this.utilityService.toast('warning', data.error, '');
                    //this.NoUserScreen();
                    this.booking.user_id = data.user_id;
                    // this.utilityService.loading = false;
                } else if (data.flag == 1317) {
                    this.utilityService.toast('warning', data.error, '');

                    // TODO ADD USER
                    // this.shoUserAddScreen();
                    // this.addtoAccount();

                    this.booking.user_id = data.user_id;
                    this.utilityService.loading = false;
                } else if (data.error || data.flag == 0) {
                    this.utilityService.toast('error', data.error || data.message, '');
                    this.utilityService.loading = false;
                    // setTimeout(() => {
                    //   this.utilityService.loading = false;
                    // }, 500);
                    return;
                } else {
                    this.min_ride_request_distance = data.min_ride_request_distance;
                    this.max_schedule_ride_days_count = data.max_schedule_ride_days_count;
                    this.max_schedule_ride_request_distance = data.max_schedule_ride_request_distance * 1609.344;
                    this.max_ride_request_distance = data.max_ride_request_distance * 1609.344;
                    this.ride_estimate_distance = data.ride_estimate_distance;

                    if (this.ride_estimate_distance <= this.min_ride_request_distance) {
                        this.utilityService.toast('error', 'Ride Distance too short, Please choose another Destination', '');
                        this.utilityService.loading = false;
                        return false;
                    } else if (this.ride_estimate_distance > this.max_ride_request_distance) {
                        this.utilityService.toast('error', 'Destination too far, Please choose another Destination', '');
                        this.utilityService.loading = false;
                        return false;
                    }

                    // this.cars_option = [];
                    const carsData = data.estimated_fare;
                    this.carDriverOptions = carsData;

                    this.booking.user_id = data.user_id;
                    this.booking.user_name = data.user_name;
                    this.booking.ride_estimate_time = data.ride_estimate_time;
                    this.booking.ride_estimate_distance = data.ride_estimate_distance;
                    this.booking.toll = data.toll;
                    this.booking.route = data.route;
                    this.tripBookingService
                        .getTripImage(
                            { lat: this.booking.pickup_latitude, long: this.booking.pickup_longitude },
                            { lat: this.booking.destination_latitude, long: this.booking.destination_longitude },
                            this.avoid_tolls,
                            this.avoid_highways
                        )
                        .then((data: { image: string; polylines: string }) => {
                            if (data.image) {
                                this.booking.route_image = data.image;
                                this.booking.polylines = data.polylines;

                                this.booking.route_directions =
                                    'https://maps.googleapis.com/maps/api/staticmap?size=350x600&path=color:0x00000cd0|weight:5|enc:' +
                                    this.booking.polylines +
                                    '&markers=shadow:false|scale:2|color:green|label:A|' +
                                    this.booking.pickup_latitude +
                                    ',' +
                                    this.booking.pickup_longitude +
                                    '&markers=color:red|label:B|shadow:false|scale:2|' +
                                    this.booking.destination_latitude +
                                    ',' +
                                    this.booking.destination_longitude +
                                    '&key=AIzaSyADXfBi40lkKpklejdGIWNxdkqQCKz8aKI';
                            }
                        });

                    console.log(this.booking.car_type);
                    // const current_car_option = this.cars_option[this.booking.car_type - 1];
                    const current_car_option: any = carsData.find((option) => option.car_type === this.booking.car_type);
                    this.fare_breakdown = current_car_option;
                    if (this.mas_active_trip) {
                        this.mas_total_leg_cost += Number(this.fare_breakdown?.estimated_tolls || 0);
                        this.mas_active_trip.mas_total_leg_cost = this.mas_total_leg_cost;
                        this.mas_active_trip.estimated_tolls = this.fare_breakdown?.estimated_tolls;
                    }

                    const fare = current_car_option?.value_regular;
                    const sche_fare = current_car_option?.value_scheduled;

                    this.booking.estimated_fare = fare;
                    this.booking.estimated_fare_later = sche_fare;

                    this.booking.estimated_fare_original = fare;
                    this.booking.estimated_fare_later_original = sche_fare;

                    const fare_old = current_car_option?.regular_ride_without_discount;
                    const sche_fare_old = current_car_option?.schedule_ride_without_discount;
                    this.booking.estimated_fare_old = fare_old;
                    this.booking.estimated_fare_later_old = sche_fare_old;
                    this.booking.sales_tax = current_car_option?.sales_tax;
                    this.getDriversData(loading);
                    if (!this.booking.promo_code) {
                        this.booking.promo_code = '';
                    }
                    if (data.promo_data.code) {
                        this.booking.promo_code = data.promo_data.code;
                    }
                    this.booking.promo_value = data.promo_data.value;
                }
                this.fareEstimatePriceLoading = false;

                this._getPaymentCards();
            },
            (error) => {
                this.utilityService.alert('error', 'Something went Wrong!');
                this.utilityService.loading = false;
            }
        );
    }

    public getDriversData(loading: boolean = false) {
        // this.booking.date = moment(new Date()).format('YYYY-MM-DD, dddd');
        // const datedata = this.booking.date.split(', ');

        // this.booking.time = datedata[0] + ' ' + this.booking.time_hour + ':' + this.booking.time_minutes + ':00';

        // this.booking.selectedTime = new Date();

        if (this.booking.user_id) {
            if (loading) this.utilityService.loading = true;
            this.httpService
                .post(environment.urlC + 'get_ride_data', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    user_id: this.booking.user_id,
                    region_id: 24,
                    car_type: this.booking.car_type,
                    is_essential: this.essential,
                })
                .subscribe(
                    (data) => {
                        if (loading) this.utilityService.loading = true;
                        if (typeof data == 'string') data = JSON.parse(data);

                        if (data.flag == 1317) {
                            this.utilityService.toast('warning', data.error, '');
                            // TODO
                            // this.shoUserAddScreen();
                            // this.addtoAccount();
                        } else if (data.error || data.flag == 0) {
                            this.utilityService.toast('error', data.error || data.message, '');
                            // document.getElementById('card-errors').textContent = data.message;

                            // TODO
                            // this.cardError = data.message;
                            return;
                        } else {
                            // TODO
                            // this.corporateFavDriver = data.corporateFavDriver;
                            // this.userFavDriver = data.userFavDriver;
                            // this.all_drivers = data.all_drivers;
                            if (this.booking.autoHitApi && !this.booking?.is_mas_trip) {
                                // console.log('Loading= === === == >', loading);
                                if (loading) {
                                    // this.utilityService.showModal('fare_estimate_popup');
                                    this.open_fare_popup();
                                }
                            } else {
                                // TODO
                                // if (this.booking.carSelected == 0 || this.forceShowDriver) {
                                //     this.showUser();
                                // }
                            }
                        }
                        this.utilityService.loading = false;

                        if (this.booking?.is_mas_trip && loading) {
                            this.open_fare_popup();
                        }
                    },
                    (err) => {
                        this.utilityService.loading = false;
                        if (loading && this.booking.is_mas_trip) {
                            this.open_fare_popup();
                        }
                        console.log('error => ', err);
                        this.utilityService.alert('error', 'Something went wrong');
                    }
                );
        }
    }

    // requestNow()

    public async initiate_request_and_confirm_payment(type: 'now' | 'later' = 'now') {
        try {
            this.booking.date = moment(new Date()).format('YYYY-MM-DD, dddd');

            // Get Cards
            // await this._getPaymentCards().pipe(first()).toPromise();
            if (this.cardsService.userCardPaymentOption === 'both') {
                this.open_select_payment_popup(type);
            } else if (this.cardsService.userCardPaymentOption === 'corporate' && !this.corporateCards.default_card_id) {
                // Show select card pop up with corporate card default selected
                this.open_select_payment_popup(type, 'corporate');
            } else if (this.cardsService.userCardPaymentOption === 'rider' && !this.riderCards.default_card_id) {
                // show select cad pop up with rider tab selected.
                this.open_select_payment_popup(type, 'rider');
            } else {
                this.verify_payment_and_book_ride(type);
            }
        } catch (err) {
            console.log('ERR => ', err);
        }
    }

    public initiate_schedule_datetime_selection() {
        if (this.booking.is_mas_trip) {
            const pickup_time = `${this.mas_active_trip?.pickupTime.slice(0, 2)}:${this.mas_active_trip?.pickupTime.slice(2, 4)}:00`;
            const date = moment(this.mas_active_trip?.pickupDate).format('YYYY-MM-DD') + ' ' + pickup_time;
            this.booking.date = moment(date).format('YYYY-MM-DD, dddd');
            this.booking.time = moment(date).format('YYYY-MM-DD HH:mm:ss');
            // alert(this.booking.time);

            if (!moment(this.booking.time).isBefore(moment().add(1, 'hour'))) {
                this.initiate_request_and_confirm_payment('later');
                return;
            }
        }
        this.open_time_selection_popup();
    }

    // Fare Estimate functions
    // Send ride request now

    public verify_payment_and_book_ride(type: 'now' | 'later' = 'now') {
        this.disable_request_confirmation_action = true;
        if (!this.booking.card_id) {
            if (this.cardsService.userCardPaymentOption === 'both') {
                // TODO: show card error pop up proceed with add card
                alert('Please update your card error');
                // this.showPaymentAlert();
                setTimeout(() => {
                    this.disable_request_confirmation_action = false;
                }, 2500);
                return false;
            } else {
                // TODO: show card error pop up proceed with add card
                // this.utilityService.showModal('#show_cardError');
                setTimeout(() => {
                    this.disable_request_confirmation_action = false;
                }, 2500);
                return false;
            }
        }
        setTimeout(() => {
            this.disable_request_confirmation_action = false;
        }, 2500);

        if (type === 'now') {
            this.sendRideRequest();
        } else if (type == 'later') {
            this.httpService
                .post(environment.urlC + 'ride_payment_process', {
                    web_access_token: this.cookieService.get('web_access_token'),
                    card_user_type: this.booking.card_type === 'rider' ? 0 : 1,
                    mobile: this.booking.user_mobile,
                    user_id: this.booking.user_id,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;

                    if (data.flag == 101) {
                        // TODO this.buttonLaterClicked = 0;
                        // TODO this.showCredentialsAlert();
                    }
                    if (data.error || data.flag == 0) {
                        // TODO this.buttonLaterClicked = 0;
                        // this.utilityService.alert('error', data.error || data.message);
                        return;
                    } else {
                        // TODO this.buttonLaterClicked = 0;
                        this.utilityService.loading = true;
                        // $('#payment_step').modal('hide');
                        this.sendRideRequest(type);
                    }
                });
        }
    }

    public sendRideRequest(type: 'now' | 'later' = 'now') {
        // this.DisableOnload = true;
        this.disable_request_confirmation_action = true;
        // this.booking.cardId = '';
        // if (this.booking.card_user_type == 1) {
        //     this.booking.cardId = this.booking.cardIdCorporate;
        // } else {
        //     this.booking.cardId = this.booking.cardIdUser;
        // }

        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            current_latitude: this.booking.pickup_latitude,
            current_longitude: this.booking.pickup_longitude,
            estimated_fare: this.booking.estimated_fare,
            ride_estimate_distance: this.booking.ride_estimate_distance,
            ride_estimate_time: this.booking.ride_estimate_time,
            pickup_location_address: this.booking.pickup_location_address,
            latitude: this.booking.pickup_latitude,
            longitude: this.booking.pickup_longitude,
            car_type: this.booking.car_type,
            manual_destination_latitude: this.booking.destination_latitude,
            manual_destination_longitude: this.booking.destination_longitude,
            manual_destination_address: this.booking.manual_destination_address,
            toll: this.booking.toll,
            route: '',
            promo_code: this.booking.promo_code,
            user_id: this.booking.user_id,
            offset: this.booking.offset,
            is_essential: this.essential,
            is_fav: this.booking.driver_id,
            third_party_ride: 0,
            fare_factor: this.fare_factor,
            pickup_city: this.booking.pickup_city,
            pickup_country: this.booking.pickup_country,
            congestion_charge: this.fare_breakdown.congestionCharge || 0,
            payment_from: this.payment_from,
        };

        if (type === 'later') {
            params.pickup_time = this.booking.time;
            params.promo_value = this.booking.promo_value;

            // assigned_trip_status
            if (this.booking.assigned_trip_status === 'confirmed') {
                params.auto_accept = 1;
            }

            if (this.booking.driver_id) {
                params.is_fav = 1;
                params.driver_id = this.booking.driver_id?.toString();
            }
        } else {
            if (this.booking.driver_id) {
                params.is_favt = this.booking.driver_id;
            }
        }

        if (this.mas_active_trip) {
            params.third_party_ride = 1;
            // const currentTrip = this.mas_active_trip?.trips?.find((x) => x.tripLegId === this.mas_leg_id);
            const mas_trip = Object.assign({}, this.mas_both_leg_trip);
            mas_trip.trips = [this.mas_active_trip];
            mas_trip.trip_leg_id = this.mas_active_trip?.tripLegId;
            params.third_party_info = mas_trip;
        }

        if (this.booking?.mas_trip_id) {
            params.mas_trip_id = this.booking?.mas_trip_id;
        }

        if (this.notFav) {
            params.is_fav = 0;
            params.select_car_type = this.booking.select_car_type;
        }

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            params.premium_options = premium_option;
        }
        if (this.payment_from === 'card') {
            params.card_user_type = this.booking.card_type === 'rider' ? 0 : 1;
            params.card_id = this.booking.card_id;

            if (!this.booking.card_id) {
                this.requestPending = 0;

                // TODO
                // this.resetForBookingOnError();
                this.utilityService.alert('info', 'Please select a valid card.');
                // TODO
                // $('#request_right_now').modal('hide');
                setTimeout(() => {
                    // this.DisableOnload = false;
                    this.disable_request_confirmation_action = false;
                }, 2000);
                return false;
            } else {
                setTimeout(() => {
                    // this.DisableOnload = false;
                    this.disable_request_confirmation_action = false;
                }, 2000);
            }
        }

        // if (this.booking.card_user_type == 1) {
        //     this.booking.cardId = this.booking.cardIdCorporate;
        // } else {
        //     this.booking.cardId = this.booking.cardIdUser;
        // }

        // this.buttonNowClicked = 1;

        // TODO: show request now modal
        // $('#request_right_now').modal('show');

        // $('#add_to_account').modal('hide');

        let end_point_suffix = 'request_ride';
        if (type === 'later') {
            end_point_suffix = 'schedule_request';
        }

        this.httpService.postJSON(environment.urlC + end_point_suffix, params).subscribe(
            (data) => {
                this.fare_factor = 1;
                if (typeof data == 'string') data = JSON.parse(data);
                if (data?.error) {
                    this.utilityService.toast('error', data?.error);
                }
                if (data.flag == 101) {
                    // TODO
                    // this.resetForBookingOnError();
                    // this.showCredentialsAlert();
                    this.utilityService.loading = false;
                    setTimeout(() => {
                        // this.DisableOnload = false;
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                } else if (data.flag == 303) {
                    // TODO $('#request_right_now').modal('hide');

                    // TODO this.resetForBookingOnError();

                    // TODO this.showCardAlert();
                    this.utilityService.loading = false;
                    setTimeout(() => {
                        // this.DisableOnload = false;
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                } else if (data.error && data.flag == 213) {
                    // TODO
                    // $('#request_right_now').modal('hide');
                    // this.resetForBookingOnError();

                    // this.utilityService.hideModalList([
                    //     'request_right_now',
                    //     'request_right_now_1',
                    //     'rider_payment_otp',
                    //     'payment_step',
                    //     'show_confirmation',
                    //     'show_cardError',
                    // ]);
                    this.utilityService.loading = false;
                    setTimeout(() => {
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                    this.utilityService.toast('error', data.error || data.message, '');
                    return;
                } else if (data.flag == 215) {
                    // this.utilityService.hideModalList(['request_right_now', 'payment_step']);
                    this.utilityService.toast('error', data.log, '');
                } else if (data.flag == 906) {
                    this.utilityService.loading = false;

                    // TODO
                    // this.resetForBookingOnError();
                    // this.showPaymentAlert();
                    setTimeout(() => {
                        // this.DisableOnload = false;
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                    // this.openToast('error', data.error || data.message, '');
                } else if (data.error && data.flag == 921) {
                    // TODO this.resetForBookingOnError();

                    // $('#request_right_now').modal('hide');
                    this.utilityService.loading = false;
                    // alert(data.error);
                    setTimeout(() => {
                        // this.DisableOnload = false;
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                    this.utilityService.alert('error', data.error, '');
                    //this.showPaymentAlert();
                    return;
                } else if (data.error || data.flag == 0) {
                    // TODO this.resetForBookingOnError();
                    // this.utilityService.hideModalList(['request_right_now', 'request_right_now_1', 'request_right_now']),
                    this.utilityService.loading = false;
                    // this.$apply();
                    /*
      if(data.error == 'Incorrect verification code'){
        $('#rider_payment_otp').modal('show');
      }
    */
                    setTimeout(() => {
                        // this.DisableOnload = false;
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                    setTimeout(() => {
                        this.utilityService.toast('error', data.error || data.message, '');
                    }, 0);
                } else if (data.flag == 202 || data.flag == 900) {
                    this.utilityService.loading = false;
                    if (type === 'now') {
                        localStorage.setItem('defaultTab', 'reg');
                    } else if (type === 'later') {
                        localStorage.setItem('defaultTab', 'sch');
                    }

                    this.utilityService.hideModalList([
                        'add_to_account',
                        'request_right_now_1',
                        'request_ride_later',
                        'payment_step',
                        'showRiderCardError',
                        'show_PaymentError',
                        'show_confirmation',
                        'show_cardError',
                        'rider_payment_otp',
                    ]);
                    this.utilityService.loading = false;
                    // this.buttonNowClicked = 0;

                    this.session_id = data.session_id;
                    localStorage.setItem('routeOff', data.session_id);
                    this.resetPremiumOption();

                    // reset premium option
                    this.booking.promo_code = undefined;
                    // this.appliedPromoCode = '';
                    // this.promo_applied = 0;

                    if (type === 'later') {
                        this.utilityService.toast('success', 'Ride Scheduled Successfully', '');
                    } else {
                        this.utilityService.toast('success', 'Ride Booked Successfully', '');
                    }
                    this.close_fare_popup();
                    this.onBooking.next(true);
                    setTimeout(() => {
                        // $('#request_right_now').modal('hide');
                    }, 2500);
                    this.requestPending = 0;

                    setTimeout(() => {
                        // this.DisableOnload = false;
                        this.disable_request_confirmation_action = false;
                    }, 2000);
                    // this.checkForActiveRide();

                    // clear = setInterval(() => {
                    //   clearInterval(stop);
                    //   stop = undefined;
                    //   // location.reload();

                    //   // $('#request_right_now').modal('hide');
                    // }, 2500);

                    // this.$apply();
                }
            },
            () => {
                // $('#request_right_now').modal('hide');
                // this.buttonNowClicked = 0;
                this.utilityService.alert('error', 'Something went Wrong, Please check your Internet!');
                this.utilityService.loading = false;
                setTimeout(() => {
                    // this.DisableOnload = false;
                    this.disable_request_confirmation_action = false;
                }, 2000);
            }
        );
    }

    private _getPaymentCards(user_id: string = this.booking.user_id) {
        return this.cardsService.getPaymentCards(user_id).subscribe((cards) => {
            if (cards.corporateCards) {
                this.corporateCards = cards.corporateCards;
                if (this.cardsService.userCardPaymentOption === 'corporate' && this.corporateCards.default_card_id) {
                    this.booking.card_id = this.corporateCards.default_card_id;
                }
            }
            if (cards.riderCards) {
                this.riderCards = cards.riderCards;
                if (this.cardsService.userCardPaymentOption === 'rider' && this.riderCards.default_card_id) {
                    this.booking.card_id = this.riderCards.default_card_id;
                }
            }
        });
    }

    // modals
    open_leg_cost_breakdown() {
        this.masService.open_leg_cost_breakdown({
            trips: this.mas_both_leg_trip,
            toll: this.fare_breakdown?.estimated_tolls,
            trip_leg_id: this.mas_active_trip?.tripLegId,
        });
    }

    open_fare_breakdown_popup(type: 'now' | 'later') {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                fare_breakdown: this.fare_breakdown,
                active_fare_breakdown_flag: type,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(FareBreakdownComponent, initialState);
        modalref.content.event.subscribe((res: { type: 'cancel' | 'book-ride'; data: any }) => {
            console.log(res);
        });
    }

    private open_select_payment_popup(request_type: 'now' | 'later' = 'now', showCardsType: 'corporate' | 'rider' = 'corporate') {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                corporateCards: this.corporateCards,
                riderCards: this.riderCards,
                cardPassed: true,
                request_type,
                showCardsType,
                user_id: this.booking.user_id,
                user_email: this.booking.email,
                amount: this.booking.estimated_fare,
            },
            ...this.utilityService.ngxModalOption,
            // ignoreBackdropClick: true
        };

        const modalref: BsModalRef = this.modalService.show(SelectCardsComponent, initialState);
        modalref.content.event.subscribe(
            (res: { card_id: string | number; payment_from: 'corporate' | 'rider' | 'corporate_wallet'; add_funds: boolean }) => {
                if (res?.add_funds) {
                    this.modalRef.hide();
                    modalref.hide();
                    this.router.navigate(['/', 'corporate', 'business-center', 'topups'], { queryParams: { add_funds: true } });
                    return;
                } else {
                    this.utilityService.loading = true;
                    if (res?.payment_from === 'corporate_wallet') {
                        this.payment_from = 'wallet';
                        this.sendRideRequest(request_type);
                    } else {
                        this.payment_from = 'card';
                        this.booking.card_id = res.card_id;

                        // if rider type then ask for confirmation
                        this.booking.card_type = res.payment_from;
                        this.verify_payment_and_book_ride(request_type);
                        if (res.payment_from === 'rider') {
                            // TODO: Confirmation for rider card
                            // alert('Rider Card');
                            this.utilityService.loading = false;
                        }
                    }
                }
            }
        );
    }

    open_time_selection_popup() {
        const bookingDate = new Date();
        bookingDate.setHours(bookingDate.getHours() + 1);
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                minDate: new Date(),
                maxDate: moment(new Date()).add(6, 'days')['_d'],
                bookingDate,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(DateTimePickerComponent, initialState);
        modalref.content.event.subscribe((res: { date: Date }) => {
            if (res.date) {
                this.booking.date = moment(res.date).format('YYYY-MM-DD, dddd');
                this.booking.time = moment(res.date).format('YYYY-MM-DD HH:mm:ss');
                this.initiate_request_and_confirm_payment('later');
            }
        });
    }

    open_promo_code_popup() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                promo_code_applied: this.booking.promo_code ? true : false,
                promo_code: this.booking.promo_code,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(PromoCodeComponent, initialState);
        modalref.content.event.subscribe((code: string) => {
            this.promo_applied = code ? 1 : '';
            this.booking.promo_code = code || '';
            this.getFareEstimate(0, false);
        });
    }

    open_premium_option_popup() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                premiumOption: this.premiumOption,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(PremiumOptionsComponent, initialState);
        modalref.content.event.subscribe((value: any) => {
            this.premiumOption.patchValue(value);
            this.getFareEstimate(0, false);
        });
    }

    // Recalculate
    open_recalculate_popup(recalculate_type: 'now' | 'later') {
        const initialState: ModalOptions = {
            id: Date.now(),
            backdrop: 'static',
            initialState: {
                fare_factor: this.fare_factor ? this.fare_factor : 1,
                fare_breakdown: this.fare_breakdown,
                is_mas_trip: this.booking.is_mas_trip,
                recalculate_type,
                recalculate_state: this.fare_breakdown,
                mas_trip_id: this.booking?.mas_trip_id,
                corporate_addon: this.booking?.corporate_addon ? this.booking?.corporate_addon : 0,
                ride_discount: this.booking?.ride_discount ? this.booking?.ride_discount : 0,
                sales_tax: this.booking.sales_tax,
                fare_estimate_params: {
                    is_scheduled: 0,
                    pickup_latitude: this.booking.pickup_latitude,
                    pickup_longitude: this.booking.pickup_longitude,
                    destination_latitude: this.booking.destination_latitude,
                    destination_longitude: this.booking.destination_longitude,
                    promo_code: this.booking.promo_code,
                    web_access_token: this.cookieService.get('web_access_token'),
                    car_type: this.booking.car_type,
                    user_mobile: this.booking.user_mobile,
                    avoid_tolls: this.avoid_tolls ? 1 : 0,
                    avoid_highways: this.avoid_highways ? 1 : 0,
                    mas_trip_id: this.booking?.mas_trip_id,
                    corporate_addon: this.booking?.corporate_addon ? this.booking?.corporate_addon : 0,
                    ride_discount: this.booking?.ride_discount ? this.booking?.ride_discount : 0,
                },
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(RecalculateFareEstimateComponent, initialState);
        modalref.content.event.subscribe((fare_factor: any) => {
            if (fare_factor.isApply) {
                if (fare_factor.type != this.fare_factor) {
                    this.fare_factor = fare_factor.type;
                }
                this.booking.corporate_addon = fare_factor.corporateAddon;
                this.booking.ride_discount = fare_factor.ride_discount;
                if (this.openFromQueueModal) {
                    this.booking.estimated_fare = fare_factor.finalFareEstimate;
                } else {
                }
            } else {
                this.booking.ride_discount = fare_factor.ride_discount;
                this.booking.corporate_addon = fare_factor.corporateAddon;
            }
            this.getFareEstimate(0, false);
        });
    }

    changeCarType(carType: any) {
        this.booking.car_type = carType?.car_type;
        this.getFareEstimate(0, false);
    }

    open_phone_number_popup() {
        this.utilityService.loading = false;
        const modalOption: ModalOptions = {
            id: Date.now(),
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(PhoneNumberModalComponent, modalOption);
        modalref.content.event.subscribe((phone_number: { dialCode: string; number: string }) => {
            if (phone_number.number) {
                this.booking.user_mobile = `${phone_number.dialCode}-${phone_number.number}`;
                this.getFareEstimate();
            }
        });
    }

    open_mas_trip_info_popup(trips: any = this.mas_both_leg_trip) {
        this.masService.open_mas_trip_info_popup({ trips });
    }

    open_fare_popup() {
        const modalOption: ModalOptions = {
            ...this.utilityService.mapViewModal,
        };
        this.modalRef = this.modalService.show(this.fareEstimateTemplateRef, modalOption);
        this.initializeMap();
        const modalHideSub = this.modalRef.onHidden.subscribe((res: any) => {
            this.map = null;
            modalHideSub.unsubscribe();
        });
    }

    close_fare_popup() {
        this.modalRef.hide();
    }

    center: { lat: number; lng: number } = { lat: 40.73221, lng: -73.91902 };
    zoom = 11;
    directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
    directionsDisplay: google.maps.DirectionsRenderer;
    initializeMap() {
        if (!this.map) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.center, //{ lat: -34.397, lng: 150.644 },
                zoom: this.zoom,
                mapTypeId: google.maps.MapTypeId.TERRAIN,
                disableDefaultUI: true,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                streetViewControl: false,
            });
        }
        this.directionsDisplay.setMap(this.map);
        const pickup = this.booking?.pickup_latitude + ',' + this.booking?.pickup_longitude;
        const dropoff = this.booking?.destination_latitude + ',' + this.booking?.destination_longitude;
        const request: google.maps.DirectionsRequest = {
            origin: pickup,
            destination: dropoff,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: this.avoid_tolls,
            avoidHighways: this.avoid_highways,
        };
        this.directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);
                this.trip_distance = response?.routes[0]?.legs[0]?.distance?.text;
                this.trip_duration = response?.routes[0]?.legs[0]?.duration?.text;
            }
        });
    }

    /** Event Emit For Queued Trip Modal */
    emitQueued() {
        const params = {
            estimated_fare: this.booking.estimated_fare,
            corporate_addon: this.booking.corporate_addon,
            ride_discount: this.booking.ride_discount,
            fare_factor: this.fare_factor,
            avoid_tolls: this.avoid_tolls,
            avoid_highways: this.avoid_highways,
        };
        this.onBookingQueued.emit(params);
        this.close_fare_popup();
    }
}
