import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import * as _ from 'lodash';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Subscription, from } from 'rxjs';
import { debounceTime, first } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MasAssignService } from 'src/app/corporate/services/mas-assign/mas-assign.service';
import { environment } from 'src/environments/environment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { DateTimePickerComponent } from 'src/app/corporate/components/date-time-picker/date-time-picker.component';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveTripInfoComponent } from 'src/app/corporate/components/live-trip-info/live-trip-info.component';
import { MasService } from 'src/app/corporate/services/mas/mas.service';
import { MasQueueService } from 'src/app/corporate/services/mas-queue/mas-queue.service';
import { UserService } from 'src/app/corporate/services/user/user.service';
import { GoogleMapService } from 'src/app/corporate/services/google-map/google-map.service';
import { LocationPickerModalComponent } from 'src/app/corporate/components/location-picker-modal/location-picker-modal.component';
import { Assinged_Ride_Status, MileStone, MileStone_Class, MileStone_Map, Milestone_Update_Confirmation } from 'src/app/models/assigned-trips.model';
import { Notification } from 'src/app/models/notification.model';
import { ContactModalComponent } from 'src/app/corporate/components/contact-modal/contact-modal.component';
import { ISecondaryContact } from 'src/app/models/user.model';
import { DriverService } from 'src/app/corporate/services/driver/driver.service';
import { SelectionModel } from '@angular/cdk/collections';
import { CreatePayoutConfirmModalComponent } from 'src/app/corporate/components/create-payout-confirm-modal/create-payout-confirm-modal.component';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { TripBookingService } from 'src/app/corporate/services/trip-booking/trip-booking.service';
import { ISuggestedFare } from 'src/app/models/fare_estimate.model';
import { IOptionItem, OptionType } from 'src/app/models/setting-header.model';
import { TripsService } from 'src/app/corporate/services/trips/trips.service';
import { FareEstimateRecalculationComponent } from 'src/app/corporate/components/fare-estimate-recalculation/fare-estimate-recalculation.component';

// TODO: Replace it with ngx dropdown for long run
declare const jQuery: any;
const $: any = jQuery;

@Component({
    selector: 'app-assigned-trips',
    templateUrl: './assigned-trips.component.html',
    styleUrls: ['./assigned-trips.component.scss'],
    providers: [{ provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy }],
})
export class AssignedTripsComponent implements OnInit, OnDestroy {
    trip_to_book: {
        is_scheduled: number;
        pickup_latitude: any;
        corporate_addon: any;
        ride_discount: any;
        pickup_longitude: any;
        destination_longitude: any;
        destination_latitude: any;
        promo_code: string;
        car_type: number;
        fare_factor: number;
        user_mobile: any;
        date: any;
        pickup_location_address: any;
        manual_destination_address: any;
        is_mas_trip: boolean;
        mas_trip_id: number | string;
        selected_driver: { driver_id: string | number; driver_name: string };
        mas_both_leg_trip?: any;
        mas_active_leg_trip?: any;
        assigned_trip_status: 'pending' | 'dispatched' | 'confirmed' | 'declined' | 'completed' | 'cancelled' | 'dispatched' | 'no show' | '';
        type: 'normal' | 'suggested_fare';
        showCreatePayout?: boolean;
        avoid_highways: any;
        avoid_tolls: any;
    };

    total_payout: number = 0;
    @Output() onTotalPayoutChange: EventEmitter<number> = new EventEmitter<number>();
    total_payout_selection_modal = new SelectionModel(
        true, // multiple selection or not
        [] // initial selected values
    );

    constructor(
        public utilityService: UtilityService,
        private masAssignService: MasAssignService,
        private masQueueService: MasQueueService,
        public userService: UserService,
        private mapService: GoogleMapService,
        private cookieService: CookieService,
        private httpService: HttpService,
        private modalService: BsModalService,
        private route: ActivatedRoute,
        private router: Router,
        public masService: MasService,
        public driverService: DriverService,
        private tripBookingService: TripBookingService,
        private tripsService: TripsService
    ) {}

    // As we need same clonned logic under rider detail page, so these parameters are for Rider Detail Page usage only, as per now
    @Input() minimal_setup: boolean = false;
    @Input() hide_tabs: boolean = false;
    @Input() rider_name_pre_filter: string;
    @Input() driver_name_pre_filter: number;
    @Input() prevent_tab_view: boolean = false;
    @Input() use_date_range: boolean = false;
    @Input() title: string;
    @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

    total_payout_form: FormArray = new FormArray([]);

    showPagination: boolean = false;
    showBottomSearchBox: boolean = false;
    searchForm: FormControl = new FormControl('');

    driver_payout_percentage: number;
    show_all_driver_payout: number;
    leg_filter_type: 'all' | 'initial' = 'all';
    pickup_search: FormControl = new FormControl('');
    dropoff_search: FormControl = new FormControl('');

    private subscriptionList$: Subscription = new Subscription();

    isLoading: boolean = false;
    // mas_session_identifier: string;
    third_party_enabled: boolean;

    pageNo: number = 1;
    itemsPerPage: number = this.utilityService.default_display_filter;
    totalItems: number = 0;

    selected_driver: any;
    driver_search_control: FormControl = new FormControl('');
    get_driver_mode: 'all' | 'mas';
    driverLoading: boolean = false;
    drivers: Array<any> = [];
    cached_drivers: Array<any> = [];

    trips: Array<any> = [];
    riderFilterApplied: boolean = false;
    showRiderFilterIcon: 'filter' | 'reset' = 'filter';

    // assigned_filter_list: Array<string> = ['all', 'dispatched', 'confirmed', 'declined', 'pending', 'completed', 'cancelled', 'live'];
    // assigned_status_filter: AssignedStatusType = null;

    activeSubTab: 'Active' | 'All Day' | 'Completed' | 'Confirmed' | 'Cancelled' | 'Pending' | 'Manual' | 'Live' | 'Prepaid' = 'All Day';
    active_trip_date_picker: FormControl = new FormControl(this.utilityService.default_date_filter);
    active_trip_date_range_picker: FormGroup = new FormGroup({
        start: new FormControl(this.utilityService.default_date_filter),
        end: new FormControl(this.utilityService.default_date_filter),
    });

    sort_order: 'desc' | 'asc' = 'asc';

    time_picker: { start: any; end: any } = {
        start: '',
        end: '',
    };
    refetchPrepaidSubject: Subject<void> = new Subject<void>();

    // Holds current active driver state for respective action

    car_type_map: any = {};
    carTypes: any;
    active_car_type_holder: any;
    secondary_contact_popover: PopoverDirective;
    secondary_contact_loading: boolean = false;
    active_sc_user_id: number | string;
    secondary_contact_list: Array<ISecondaryContact> = [];

    assignedSubTabList: Array<any> = this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Assigned'];
    refreshActionContainer: Array<any> = [];
    paged_driver_loading: boolean = false;
    confirmed_driver_list: Array<any>;
    interested_driver_list: Array<any>;

    loading_assigned_trip_container: any[] = [];

    @ViewChild('filterAssignedDriverSearch') filterAssignedDriverSearchElementRef: ElementRef;

    active_milestone_trip_mas_trip_id: any; // for storing the mas trip id of last updated milestone trip

    headerOptions: IOptionItem[] = [
        { id: 'trip-leg-menu', type: OptionType.DROPDOWN, options: ['all', 'initial'], emitEvent: true, value: 'all', text: 'Trip Leg' },
        { id: 'time-picker', type: OptionType.TIME, emitEvent: true, value: { start: null, end: null } },
        {
            id: 'date-picker',
            type: this.use_date_range ? OptionType.RANGE_DATE : OptionType.NORMAL_DATE,
            form: this.use_date_range ? this.active_trip_date_range_picker : this.active_trip_date_picker,
        },
    ];

    ngOnInit(): void {
        this.checkScreenWidth();
        // Subscribe to window resize event to dynamically update the screen width
        window.addEventListener('resize', () => this.checkScreenWidth());

        this.fetchDriverPercentage();
        this.cached_drivers = JSON.parse(this.cookieService.get('cached_filter_driver') || '[]');
        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));
        this.third_party_enabled = driverModel.is_mas_dispatcher == 1 ? true : false;

        this._getCarType();
        this.getDrivers();
        this.getRideBrokerDropdownList();
        // Use Date Range Picker if assigned trip is called for driver or rider detail page
        // if (this.driver_name_pre_filter || this.rider_name_pre_filter) {
        //     this.use_date_range = true;
        // }

        if (this.use_date_range) {
            this.subscriptionList$.add(
                this.active_trip_date_range_picker.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                    const { start, end } = value;
                    if (start && end) {
                        const queryParams = this.route?.snapshot?.queryParams;
                        this.router.navigate([this.router.url.split('?')[0]], {
                            queryParams: { ...queryParams, date_filter: `${moment(start).format('MM/DD/yyyy')}-${moment(end).format('MM/DD/yyyy')}` },
                        });
                        this.resetTimeFilter();
                        this.get_assigned_trips();
                    }
                })
            );
        } else {
            this.active_trip_date_picker.valueChanges.subscribe((value) => {
                if (value) {
                    if (!this.rider_name_pre_filter) this.searchForm.patchValue('', { emitEvent: false });
                    this.riderFilterApplied = false;
                    this.showRiderFilterIcon = 'filter';

                    const queryParams = this.route?.snapshot?.queryParams;
                    this.router.navigate([this.router.url.split('?')[0]], {
                        queryParams: { ...queryParams, date_filter: moment(value).format('MM/DD/yyyy') },
                    });
                    this.resetTimeFilter();
                    this.get_assigned_trips();
                    this.updateSubTabView(value);
                }
            });
        }

        setTimeout(() => {
            const date_filter = this.route.snapshot.queryParams?.date_filter;
            if (this.rider_name_pre_filter) {
                this.searchForm.patchValue(this.rider_name_pre_filter);
                this.searchForm.disable();
            }
            if (this.driver_name_pre_filter) {
                this.selected_driver = {
                    driver_name: this.driver_name_pre_filter,
                };
            }
            if (date_filter) {
                if (this.use_date_range && date_filter?.includes('-')) {
                    const start = new Date(date_filter?.split('-')?.[0] || new Date());
                    const end = new Date(date_filter?.split('-')?.[1] || new Date());
                    this.active_trip_date_range_picker.patchValue({ start, end });
                } else {
                    this.active_trip_date_picker.patchValue(new Date(date_filter));
                }
            } else {
                this.get_assigned_trips();
            }
        }, 0);

        this.subscriptionList$.add(
            this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                if (!value) this.riderFilterApplied = false;
                this.get_assigned_trips();
            })
        );

        this.subscriptionList$.add(
            this.driver_search_control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                if (value) {
                    value = value.trim();
                }
                this.getDrivers(value);
            })
        );

        this.subscriptionList$.add(
            this.pickup_search.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
                this.dropoff_search.reset('', { emitEvent: false });
                this.get_assigned_trips();
            })
        );

        this.subscriptionList$.add(
            this.dropoff_search.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
                this.pickup_search.reset('', { emitEvent: false });
                this.get_assigned_trips();
            })
        );

        this.subscriptionList$.add(
            this.masAssignService.mas_assigned_notification.subscribe((notification: Notification) => {
                if (notification.identifier && this.trips.find((trip) => trip?.mas_trip_id === notification?.identifier)) {
                    if (notification?.identifier !== this.active_milestone_trip_mas_trip_id) {
                        this.loading_assigned_trip_container.push(notification?.identifier);
                        this.get_assigned_trips(this.pageNo, this.itemsPerPage, true, notification?.identifier);
                    }
                }
            })
        );

        this.subscriptionList$.add(
            this.driverService.trigger_driver_payout.subscribe(() => {
                this.create_payout();
            })
        );

        // this.get_assign_mas_roster_trip_info();

        this.subscriptionList$.add(
            this.utilityService.applyButtonClick$.subscribe(() => {
                // Handle the apply button click event here
                this.get_assigned_trips(this.pageNo, this.itemsPerPage, true);
            })
        );
    }

    get isFilterActive(): boolean {
        if (this.time_picker.start && this.time_picker.end) return true;
        if (
            this.use_date_range &&
            (!moment().isSame(moment(this.active_trip_date_range_picker?.value?.start), 'day') ||
                !moment().isSame(moment(this.active_trip_date_range_picker?.value?.end), 'day'))
        ) {
            return true;
        } else if (!moment().isSame(moment(this.active_trip_date_picker?.value), 'day')) {
            return true;
        }
        if (this.leg_filter_type != 'all') {
            return true;
        }
        return false;
    }

    resetTimeFilter() {
        this.time_picker = { start: '', end: '' };
        this.updateHeaderOption('time-picker', { value: this.time_picker });
    }

    onFilterEvent(event: IOptionItem) {
        console.log('events', event);
        switch (event.id) {
            case 'trip-leg-menu':
                if (event.value !== this.leg_filter_type) {
                    this.leg_filter_type = event.value;
                    this.get_assigned_trips(1);
                }
                break;
            case 'time-picker':
                this.time_picker = event.value;
                this.get_assigned_trips();
                break;

            case 'RESET':
                this.leg_filter_type = 'all';
                this.time_picker = { start: '', end: '' };
                this.updateHeaderOption('trip-leg-menu', { value: 'all' });
                this.updateHeaderOption('time-picker', { value: this.time_picker });
                if (this.use_date_range) this.active_trip_date_range_picker.patchValue({ start: new Date(), end: new Date() });
                else this.active_trip_date_picker.patchValue(new Date());
                break;
        }
    }

    updateHeaderOption(id: string, change: any) {
        this.headerOptions = this.headerOptions.map((item) => {
            if (item.id === id) {
                item = { ...item, ...change };
            }
            return item;
        });
    }

    private _getCarType() {
        this.subscriptionList$.add(
            this.tripsService.get_car_type().subscribe((res) => {
                if (res?.data) {
                    this.carTypes = res?.data || [];
                    this.carTypes.forEach((item) => {
                        this.car_type_map[item.car_type] = item?.car_name;
                    });
                }
            })
        );
    }

    private updateSubTabView(date: Date | any) {
        const is_future_date = moment(date).isAfter(moment().endOf('day'));
        const is_todays_date = moment().isSame(moment(date), 'day');

        this.assignedSubTabList = this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Assigned'].filter((tab: any) => {
            if (is_future_date) {
                return tab?.title !== 'Active';
            } else if (!is_todays_date) {
                return tab?.title !== 'Active';
            } else {
                return true;
            }
        });

        if (this.activeSubTab === 'Active' && !is_todays_date) {
            this.activeSubTab = 'All Day';
        }

        if (is_todays_date) {
            // this.activeSubTab = 'Active';
            this.activeSubTab = 'All Day';
        }
    }

    onSubTabChanged($event: any) {
        this.activeSubTab = $event;

        if (this.activeSubTab === 'Active') {
            this.time_picker = { start: `${moment().format('HH')}`, end: '23' };
            this.updateHeaderOption('time-picker', this.time_picker);
        } else {
            this.time_picker = { start: '', end: '' };
            this.updateHeaderOption('time-picker', this.time_picker);
        }
        this.get_assigned_trips();
    }

    public apply_driver_filter(driver: any) {
        if (driver?.driver_id === this.selected_driver?.driver_id) return;
        this.selected_driver = driver;
        this.get_assigned_trips();

        if (driver) {
            this.cached_drivers.unshift(driver);
            this.cached_drivers = _.uniqBy(this.cached_drivers, 'driver_id');
            this.cookieService.set('cached_filter_driver', JSON.stringify(this.cached_drivers));
        }
    }

    public get_assigned_trips(pageNo: number = 1, itemsPerPage: number = this.itemsPerPage, slient: boolean = false, mas_trip_id?: string | number) {
        this.pageNo = pageNo;
        this.itemsPerPage = itemsPerPage;
        if (!slient) {
            this.isLoading = true;
            this.trips = [];
        }
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: (pageNo - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
            assign_status_filter: 0,
            sort_order: this.sort_order,
            location_filter: 0,
            date_filter: 0,
        };

        if (this.activeSubTab === 'Manual') {
            payload.manual_trip = 1;
        }

        if (this.activeSubTab === 'Prepaid') {
            payload.prepaid_trip = 1;
        }

        if (this.searchForm.value) {
            payload.searchFlag = 1;
            payload.searchString = this.searchForm.value.trim();
        }

        if (this.leg_filter_type === 'initial') {
            payload.trip_leg_sequence = 1;
        }

        if (this.selected_driver) {
            payload.driver_filter = 1;
            payload.driver_searchString = this.selected_driver?.driver_name;
        }

        // if (this.assigned_status_filter) {
        //     const trip_map = { confirmed: 1, declined: 2, pending: 3, completed: 4, cancelled: 5, dispatched: 6, live: 7 };
        //     payload.assign_status_filter = trip_map[this.assigned_status_filter];
        // }

        if (['Confirmed', 'Pending', 'Declined', 'Completed', 'Cancelled', 'Dispatched', 'Live']?.includes(this.activeSubTab)) {
            const trip_map = { Confirmed: 1, Declined: 2, Pending: 3, Completed: 4, Cancelled: 5, Dispatched: 6, Live: 7 };
            payload.assign_status_filter = trip_map[this.activeSubTab];
        }

        if (this.pickup_search?.value?.trim()) {
            payload.location_filter = 1;
            payload.location_searchString = this.pickup_search.value.trim();
        }

        if (this.dropoff_search?.value?.trim()) {
            payload.location_filter = 2;
            payload.location_searchString = this.dropoff_search.value.trim();
        }

        payload.ride_broker =
            this.masService.brokerStatus == 'All Brokers'
                ? ''
                : this.masService.brokerStatus == 'SENTRY-TPT'
                ? 'SentryMS'
                : this.masService.brokerStatus == 'SENTRY-MP'
                ? 'SentryMS'
                : this.masService.brokerStatus;
        payload.trip_type =
            this.masService.brokerStatus == 'SENTRY-TPT' ? 'assigned' : this.masService.brokerStatus == 'SENTRY-MP' ? 'marketplace' : '';

        if (this.use_date_range) {
            if (this.active_trip_date_range_picker.value?.start && this.active_trip_date_range_picker.value?.end) {
                const { start, end } = this.active_trip_date_range_picker.value;
                let start_date = moment(start).startOf('day');
                let end_date = moment(end).endOf('day');

                if (this.time_picker?.start && this.time_picker?.end && start_date.isSame(end_date)) {
                    start_date = start_date.set({ hour: this.time_picker?.start, minute: 0, second: 0 });
                    end_date = end_date.set({ hour: this.time_picker?.end, minute: 59, second: 59 });
                }

                payload.date_filter = 1;
                payload.start_date = moment.utc(start_date).format('YYYY-MM-DD');
                payload.end_date = moment.utc(end_date).format('YYYY-MM-DD');

                payload.time_filter = 1;
                payload.start_time = moment.utc(start_date).format('HH:mm:ss');
                payload.end_time = moment.utc(end_date).format('HH:mm:ss');
            }
        } else {
            if (this.active_trip_date_picker.value) {
                const date = this.active_trip_date_picker.value;
                let start_date = moment(date).startOf('day');
                let end_date = moment(date).endOf('day');

                if (this.time_picker?.start && this.time_picker?.end) {
                    start_date = start_date.set({ hour: this.time_picker?.start, minute: 0, second: 0 });
                    end_date = end_date.set({ hour: this.time_picker?.end, minute: 59, second: 59 });
                }

                payload.date_filter = 1;
                payload.start_date = moment.utc(start_date).format('YYYY-MM-DD');
                payload.end_date = moment.utc(end_date).format('YYYY-MM-DD');

                payload.time_filter = 1;
                payload.start_time = moment.utc(start_date).format('HH:mm:ss');
                payload.end_time = moment.utc(end_date).format('HH:mm:ss');
            }
        }

        this.subscriptionList$.add(
            this.masAssignService
                .get_assign_mas_roster_trip_info(payload)
                .subscribe((res: { count: number; trips: Array<any>; error: string }) => {
                    if (res?.error) {
                        this.utilityService.toast('error', res.error);
                    } else {
                        this.trips = res.trips || [];
                        if (slient && mas_trip_id) {
                            this.loading_assigned_trip_container = this.loading_assigned_trip_container.filter((item) => item !== mas_trip_id);
                        } else {
                            this.trips = res?.trips || [];
                            this.totalItems = res?.count || 0;
                        }

                        this.trips = this.trips.map((trip: any) => {
                            // trip.pickup_datetime = new Date(moment(trip?.pickup_date + ' ' + trip?.pickup_time).toString());
                            trip.active_class = moment(trip?.pickup_datetime).isBetween(moment(), moment().add(1, 'hour')) ? 'bg-active-trip' : '';
                            trip.inactive_class = moment(trip?.pickup_datetime).isBefore(moment()) ? 'bg-inactive-trip' : '';
                            trip.ride_status_text = this.masAssignService.get_assigned_status(trip);
                            if (trip?.ride_milestone) {
                                trip.ride_milestone_text = trip?.ride_milestone === 5 ? 'Completed' : MileStone_Map[trip?.ride_milestone];
                                trip.ride_milestone_class = MileStone_Class[trip?.ride_milestone];
                            }

                            if (
                                // trip?.invoice_number &&
                                trip?.ride_status == Assinged_Ride_Status.CONFIRMED &&
                                ((moment().isSame(moment(trip?.pickup_datetime), 'day') &&
                                    moment(trip?.pickup_datetime).isBefore(moment().add(1, 'hour'))) ||
                                    trip?.ride_milestone > 0) &&
                                trip?.ride_milestone !== 6
                            ) {
                                trip.next_milestone = trip?.ride_milestone + 1;
                                trip.next_milestone_text = MileStone_Map[trip?.next_milestone];
                                trip.ride_milestone_class = MileStone_Class[trip?.next_milestone];
                            }

                            trip.is_assigned = true;
                            return trip;
                        });

                        if (this.riderFilterApplied) {
                            this.showRiderFilterIcon = 'reset';
                            if (['Confirmed', 'Dispatched']?.includes(this.activeSubTab)) {
                                const trip_map = { Confirmed: 1, Dispatched: 6 };
                                payload.status_filter = trip_map[this.activeSubTab] || '';
                            }
                            this.masQueueService.get_queued_trips(payload).subscribe((res) => {
                                let queued_trips = res?.trips || [];
                                queued_trips = queued_trips.map((trip: any) => {
                                    // trip.pickup_datetime = new Date(moment(trip?.pickup_date + ' ' + trip?.pickup_time).toString());
                                    trip.active_class = moment(trip?.pickup_datetime).isBetween(moment(), moment().add(1, 'hour'))
                                        ? 'bg-active-trip'
                                        : '';
                                    trip.inactive_class = moment(trip?.pickup_datetime).isBefore(moment()) ? 'bg-inactive-trip' : '';
                                    trip.is_queued = true;
                                    return trip;
                                });
                                this.trips.push(...queued_trips);
                                console.log('this.trips => ', this.trips);
                                console.log('assigned_trips => ', queued_trips);

                                if (this.sort_order === 'asc') {
                                    this.trips.sort((a, b) => Date.parse(a?.pickup_datetime) - Date.parse(b?.pickup_datetime));
                                } else if (this.sort_order === 'desc') {
                                    this.trips.sort((a, b) => Date.parse(b?.pickup_datetime) - Date.parse(a?.pickup_datetime));
                                }

                                this.isLoading = false;
                            });
                        } else {
                            this.showRiderFilterIcon = 'filter';
                            this.isLoading = false;
                        }
                    }
                })
                .add(() => {
                    this._setShowPagination();
                })
        );
    }

    onRowClick(event, mas_trip_id: number | string) {
        const classnames: string = event?.target?.className;
        console.log(classnames);
        if (classnames?.includes('stop-propagation')) return;
        this.router.navigate(['/', 'corporate', 'active-trips', 'assigned', mas_trip_id]);
    }

    //
    reset_driver_results(id?: string, driver_mode: 'all' | 'mas' = this.get_driver_mode) {
        const searchString = this.driver_search_control?.value;
        if (searchString || driver_mode != this.get_driver_mode) {
            this.get_driver_mode = driver_mode;
            this.driverLoading = true;
            this.driver_search_control.reset('');
        }
        const element: HTMLElement = document.getElementById(`${id}`) as HTMLElement;
        setTimeout(() => {
            element.focus();
        }, 0);
    }

    async assign_driver(driver: any, trip: any, type: 'confirmed' | 'interested' | 'none' = 'none') {
        if (!trip) {
            return;
        }

        console.log(trip);

        try {
            const is_mas_trip: any = trip?.invoice_number ? true : false;

            if (type === 'confirmed') {
                driver.type = type;
            }

            const pickup = new google.maps.LatLng(trip?.pickup_latitude, trip?.pickup_longitude);
            const dropoff = new google.maps.LatLng(trip?.dropoff_latitude, trip?.dropoff_longitude);

            const eta: any = await this.mapService
                .get_eta({
                    origin: pickup,
                    destination: dropoff,
                    travelMode: google.maps.TravelMode.DRIVING,
                    transitOptions: {
                        departureTime: new Date(trip.pickup_datetime),
                    },
                })
                .pipe(first())
                .toPromise();

            if (is_mas_trip) {
                const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number.toString());
                let mas_active_leg_trip: any = mas_both_leg_trip?.trips.find((x: any) => x.tripLegId.toString() === trip?.trip_leg_id.toString());

                if (!mas_active_leg_trip) {
                    this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                    this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                    return;
                }
                mas_active_leg_trip.car_type = trip?.car_type;
                mas_active_leg_trip.ride_status = trip?.ride_status;

                trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip);
                trip.ride_estimate_time = eta?.value;
            }

            const check_mobile: any = await this.userService.check_mobile(trip?.user_mobile, trip?.rider_name).pipe(first()).toPromise();
            if (check_mobile?.error && check_mobile?.flag === 1316) {
                console.log(trip?.rider_name, trip);
                await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            }

            let mas_assign: any;
            if (is_mas_trip) {
                // cause the trip object is updated if it is coming from mas
                mas_assign = await this.masAssignService
                    .assign_driver(this.masAssignService.mas_trip_assign_driver_params(trip, driver))
                    .pipe(first())
                    .toPromise();
            } else {
                mas_assign = await this.masAssignService
                    .assign_driver({
                        first_name: trip?.user_name,
                        last_name: '',
                        user_mobile: trip?.user_mobile,
                        pickup_date: trip?.pickup_date,
                        pickup_latitude: trip?.pickup_latitude,
                        pickup_longitude: trip?.pickup_longitude,
                        pickup_location: trip?.pickup_location,
                        dropoff_location: trip?.dropoff_location,
                        dropoff_latitude: trip?.dropoff_latitude,
                        dropoff_longitude: trip?.dropoff_longitude,
                        invoice_number: 0,
                        trip_leg_id: 0,
                        pickup_time: trip?.pickup_time,
                        driver_id: driver?.driver_id,
                        offset: new Date().getTimezoneOffset() * -1,
                        confirmed_driver_id: driver?.type === 'confirmed' ? driver?.driver_id : 0,
                        ride_estimate_time: eta?.value,
                        miles: 0,
                        trip_leg_sequence: 0,
                        mas_trip_id: trip?.mas_trip_id,
                        car_type: trip?.car_type,
                    })
                    .pipe(first())
                    .toPromise();
            }

            if (mas_assign?.error && mas_assign?.flag === 1316) {
                await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            }

            if (mas_assign.flag === 1322) {
                this.utilityService.toast('success', 'Driver assigned successfully');
                this.get_assigned_trips();
            } else if (mas_assign?.error) {
                this.utilityService.toast('warning', mas_assign?.error);
            }
        } catch (err) {
            console.log('error => ', err);
            this.utilityService.toast('error', err.message || 'Something went wrong');
        }

        if (driver) {
            this.cached_drivers.unshift(driver);
            this.cached_drivers = _.uniqBy(this.cached_drivers, 'driver_id');
            this.cookieService.set('cached_filter_driver', JSON.stringify(this.cached_drivers));
        }
    }

    getDrivers(searchString: string = '') {
        this.driverLoading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: this.utilityService.MAS_Qudosfave_Map[this.get_driver_mode === 'mas' ? 'MAS Drivers' : 'All Drivers'],
        };

        if (searchString) {
            params.searchFlag = 1;
            params.searchString = searchString;
        }

        this.subscriptionList$.add(
            this.httpService
                .post(environment.urlC + 'get_fav_driver', params)
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;

                    if (data.flag !== 101 && data.flag !== 807) {
                        this.drivers = data?.drivers?.map((driver: any) => {
                            driver.is_mas_driver = this.get_driver_mode === 'mas';
                            return driver;
                        });
                    }
                })
                .add(() => {
                    this.driverLoading = false;
                })
        );
    }

    updateRiderDetailFilter(trip: any, mode: 'set' | 'reset' = 'set') {
        if (mode === 'set') {
            this.searchForm.patchValue(trip?.user_name);
            this.isLoading = true;
            this.riderFilterApplied = true;
            this.showPagination = false;
        } else {
            this.searchForm.reset();
            this.isLoading = true;
            this.riderFilterApplied = false;
            this.showPagination = true;
        }
    }

    update_assigned_driver(trip: any, driver: any, type: string) {
        this.subscriptionList$.add(
            this.masAssignService
                .update_assigned_driver({
                    web_access_token: this.cookieService.get('web_access_token'),
                    driver_id: driver?.driver_id || '',
                    mas_trip_id: trip?.mas_trip_id,
                    request_type: type === 'update' ? 0 : 1,
                })
                .subscribe((res) => {
                    this.get_assigned_trips(this.pageNo, this.itemsPerPage, true, trip?.mas_trip_id);
                })
        );
    }

    /**
     * @param tripLegStatus  - please use the following key in the api : /mas_trip_roster_request to check if the trip is deleted ,cancelled or active from mas. tripLegStatus [0 -- cancelled, 1-- active, 2 -- deleted]
     */
    private fetch_mas_details(trip_date: string, invoice_number: string = '', tripLegStatus: number = -1): Promise<any> {
        if (!this.third_party_enabled) return;
        const mas_session_identifier = localStorage.getItem('session_identifier');
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            session_identifier: mas_session_identifier,
            invoice_number,
            trip_date,
        };
        if (tripLegStatus != -1) {
            payload.tripLegStatus = tripLegStatus;
        }
        return new Promise((resolve, reject) => {
            this.subscriptionList$.add(
                this.httpService.post(environment.urlWC + 'mas_trip_roster_request', payload).subscribe(
                    async (res) => {
                        if (res?.message === 'Success.') resolve(res);
                        else if (res?.error === 'Authentication Failed. There is no account linked to the key provided.') {
                            await this.utilityService.start_mas_session();
                            reject(res);
                        }
                    },
                    (err) => {
                        reject(err);
                    }
                )
            );
        });
    }

    filter_leg_sequence(status: 'all' | 'initial') {
        if (status === this.leg_filter_type) return;
        this.leg_filter_type = status;
        this.get_assigned_trips(1);
    }

    async book_mas_ride(trip: any, type: 'normal' | 'suggested_fare' = 'normal') {
        this.suggested_fare_trip_refrence = trip;
        this.utilityService.loading = true;
        if (this.live_trip_modal_ref) {
            this.live_trip_modal_ref.hide();
            this.live_trip_modal_ref = null;
        }

        if (!trip?.invoice_number) {
            this.trip_to_book = {
                is_scheduled: 0,
                pickup_latitude: trip?.pickup_latitude,
                pickup_longitude: trip?.pickup_longitude,
                destination_latitude: trip?.dropoff_latitude,
                destination_longitude: trip?.dropoff_longitude,
                promo_code: '',
                car_type: trip?.car_type,
                fare_factor: 1,
                user_mobile: trip?.user_mobile,
                date: trip?.pickup_datetime,
                pickup_location_address: trip?.pickup_location,
                manual_destination_address: trip?.dropoff_location,
                corporate_addon: trip?.corporate_addon,
                ride_discount: trip?.ride_discount,
                avoid_highways: trip?.avoid_highways,
                avoid_tolls: trip?.avoid_tolls,
                is_mas_trip: false,
                // mas_both_leg_trip,
                // mas_active_leg_trip,
                selected_driver: {
                    driver_id: trip?.sent_to,
                    driver_name: trip?.requested_driver_names,
                },
                assigned_trip_status: this.masAssignService.get_assigned_status(trip) || '',
                mas_trip_id: trip?.mas_trip_id,
                type,
                showCreatePayout: trip?.paid === 0 && trip?.ride_status === 4,
            };
        } else {
            const mas_both_leg_trip = await this.fetch_mas_details(trip?.pickup_date, trip?.invoice_number);
            let mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId.toString() === trip?.trip_leg_id.toString());

            if (!mas_active_leg_trip) {
                this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                return;
            }

            mas_active_leg_trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip);

            this.trip_to_book = {
                is_scheduled: 0,
                pickup_latitude: trip?.pickup_latitude,
                pickup_longitude: trip?.pickup_longitude,
                destination_latitude: trip?.dropoff_latitude,
                destination_longitude: trip?.dropoff_longitude,
                pickup_location_address: trip?.pickup_location,
                manual_destination_address: trip?.dropoff_location,
                promo_code: '',
                car_type: trip?.car_type,
                fare_factor: 1,
                user_mobile: trip?.user_mobile ? trip?.user_mobile : this.utilityService.purify_mobile_no(mas_active_leg_trip?.recipientPhone),
                date: mas_active_leg_trip?.pickup_datetime,
                is_mas_trip: true,
                mas_both_leg_trip,
                mas_active_leg_trip,
                selected_driver: {
                    driver_id: trip?.sent_to,
                    driver_name: trip?.requested_driver_names,
                },
                assigned_trip_status: this.masAssignService.get_assigned_status(trip) || '',
                mas_trip_id: trip?.mas_trip_id,
                corporate_addon: trip?.corporate_addon,
                ride_discount: trip?.ride_discount,
                avoid_highways: trip?.avoid_highways,
                avoid_tolls: trip?.avoid_tolls,
                type,
                showCreatePayout: trip?.paid === 0 && trip?.ride_status === 4,
            };
        }
    }

    send_payment_link_to_rider(trip: any, type: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode: 'edit',
                actionButton: 'Send',
                title: trip?.paid ? 'Send Trip Info Link' : 'Send Payment Link to Rider ',
                showHeaderSuffixLabel: 'Check fare estimate?',
                contact: {
                    name: trip?.user_name,
                    mobile: trip?.user_mobile,
                },
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((action: { mobile: string; onSuffixHeaderClicked?: boolean }) => {
            if (action?.onSuffixHeaderClicked) {
                this.book_mas_ride(trip, 'suggested_fare');
            } else {
                if (action.mobile?.includes('-')) action.mobile = action.mobile.replace('-', '');
                this.utilityService.loading = true;
                this.tripsService
                    .send_payment_link(action.mobile, trip?.mas_trip_id, type)
                    .subscribe((res) => {
                        if (res?.error) {
                            this.utilityService.toast('error', res?.error || res?.message);
                        } else {
                            this.utilityService.toast('success', res?.message);
                        }
                    })
                    .add(() => {
                        this.utilityService.loading = false;
                    });
                modalref.hide();
            }
        });
    }

    get_payment_link(mas_trip_id: string) {
        this.utilityService.loading = true;
        this.tripsService
            .get_payment_link(mas_trip_id)
            .subscribe((res) => {
                if (res?.error || !res?.referral_link) {
                    this.utilityService.toast('error', res?.error || res?.message || 'Something went wrong');
                } else {
                    window.open(res?.referral_link, '_blank');
                }
            })
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    private _setShowPagination() {
        this.showPagination =
            this.trips?.length > 0 &&
            // (this.trips?.length >= this.itemsPerPage || this.pageNo != 1) &&
            !this.isLoading;
        // && this.totalItems > this.itemsPerPage;

        // alert(this.showPagination);
    }

    update_assigned_trip(
        trip: any,
        driver: any,
        type: 'update' | 'unassign',
        operation: 'uncompleting' | 'uncancelling' | 'unassigning' | 'queuing back' = 'unassigning'
    ) {
        const config = {
            heading: `${_.startCase(operation)} a Trip`,
            description: `You are ${operation} this trip, please confirm`,
            leftButtonText: 'Cancel',
            rightButtonText: 'Yes',
        };
        if (type === 'update') {
            config.heading = 'Assigning a New Driver';
            config.description = 'You are assigning a new driver for this trip, please confirm.';
        }
        this.subscriptionList$.add(
            this.utilityService.confirm(config).subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.loading_assigned_trip_container.push(trip?.mas_trip_id);
                    this.update_assigned_driver(trip, driver, type);
                    if (this.live_trip_modal_ref) {
                        this.live_trip_modal_ref.hide();
                        this.live_trip_modal_ref = null;
                    }
                }
            })
        );
    }

    generateCSV() {
        if (!this.trips.length) return;
        let csvContent = '';
        let csvKey = [
            'Trip ID',
            'Assign ID',
            'Assigned Driver',
            'Driver Mobile',
            'Rider Name',
            'Rider Mobile',
            'Pickup Date & Time',
            'Pickup Location',
            'Dropoff Location',
            'MAS Payout',
            'Miles',
            'Driver Pay',
            'Toll',
            'Car Type',
        ];

        if (this.driver_name_pre_filter) {
            csvKey = [
                'Trip ID',
                // 'Assign ID',
                'Assigned Driver',
                // 'Driver Mobile',
                'Rider Name',
                // 'Rider Mobile',
                'Pickup Date & Time',
                'Pickup Location',
                'Dropoff Location',
                // 'MAS Payout',
                'Miles',
                'Driver Pay',
                // 'Toll',
                // 'Car Type',
            ];
        }

        csvContent += csvKey + '\r\n';

        this.trips.forEach((trip) => {
            let row = [
                trip?.trip_leg_id || '-',
                trip?.mas_trip_id || '-',
                trip?.requested_driver_names || '-',
                trip?.requested_driver_mobile || '-',
                trip?.user_name || '-',
                trip?.user_mobile || '-',
                moment(trip?.pickup_datetime).format('MMM DD YYYY hh:mm A') || '-',
                trip?.pickup_location?.replace(/[,#]/g, ' ') || '-',
                trip?.dropoff_location?.replace(/[,#]/g, ' ') || '-',
                `$${trip?.leg_cost}`,
                `${trip?.miles} Miles`,
                `$${trip?.third_party_amount}`,
                `$${trip?.user_toll}`,
                this.car_type_map[trip?.car_type],
            ];

            if (this.driver_name_pre_filter) {
                row = [
                    trip?.trip_leg_id || '-',
                    // trip?.mas_trip_id || '-',
                    trip?.requested_driver_names || '-',
                    // trip?.requested_driver_mobile || '-',
                    trip?.user_name || '-',
                    // trip?.user_mobile || '-',
                    moment(trip?.pickup_datetime).format('MMM DD YYYY hh:mm A') || '-',
                    trip?.pickup_location?.replace(/[,#]/g, ' ') || '-',
                    trip?.dropoff_location?.replace(/[,#]/g, ' ') || '-',
                    // `$${trip?.leg_cost}`,
                    `${trip?.miles} Miles`,
                    `$${trip?.third_party_amount}`,
                    // `$${trip?.user_toll}`,
                    // this.car_type_map[trip?.car_type],
                ];
            }

            csvContent += row + '\r\n';
        });

        this.utilityService.generate_csv(`Assigned trips-${moment(this.active_trip_date_picker?.value).format('Do_MMM')}`, csvContent);
    }

    toggleAttestationSort() {
        this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc';
        this.get_assigned_trips();
    }

    open_time_picker_modal(trip: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                viewMode: 'willcall+time',
                bookingDate: trip?.pickup_datetime ? new Date(trip?.pickup_datetime) : new Date(),
                rightButtonText: 'Save',
                title: 'Edit Trip Time',
                willCall: trip?.will_call === 1 ? true : false,
                date: trip?.pickup_datetime,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(DateTimePickerComponent, initialState);
        modalref.content.event.subscribe((res: { date: Date; willCall?: boolean }) => {
            console.log('res => ', res);
            if (res) {
                const payload: any = {
                    mas_trip_id: trip?.mas_trip_id,
                    pickup_time: moment.utc(res.date).format('hh:mm a'),
                    pickup_date: moment.utc(res.date).format('MM/DD/yyyy'),
                };
                if (res.willCall) {
                    payload.will_call = 1;
                } else if (res.willCall === false && trip?.will_call === 1) {
                    payload.will_call = 0;
                }
                this.update_assign_mas_trip(payload);
            }
        });
    }

    open_location_picker(trip: any) {
        const location = {
            pickup_location: {
                location: trip?.pickup_location,
                lat: trip?.pickup_latitude,
                lng: trip?.pickup_longitude,
            },
            dropoff_location: {
                location: trip?.dropoff_location,
                lat: trip?.dropoff_latitude,
                lng: trip?.dropoff_longitude,
            },
        };
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                location,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(LocationPickerModalComponent, initialState);
        modalref.content.event.subscribe((res: any) => {
            if (res) {
                const payload: any = {
                    mas_trip_id: trip?.mas_trip_id,
                    car_type: trip?.car_type,
                    pickup_time: trip?.pickup_time,
                    third_party_amount: trip?.third_party_amount,
                    user_toll: trip?.user_toll,
                    will_call: trip?.will_call ? 1 : 0,
                    pickup_location: res?.pickup_location,
                    pickup_latitude: res?.pickup_lat,
                    pickup_longitude: res?.pickup_lng,
                    dropoff_location: res?.dropoff_location,
                    dropoff_latitude: res?.dropoff_lat,
                    dropoff_longitude: res?.dropoff_lng,
                };
                if (trip?.is_prepaid) {
                    // If trip is prepaid, then refetch the suggested fare and update it from here.
                    this.tripBookingService
                        .getSuggestedFare({
                            pickup_latitude: res?.pickup_lat,
                            pickup_longitude: res?.pickup_lng,
                            destination_latitude: res?.dropoff_lat,
                            destination_longitude: res?.dropoff_lng,
                            car_type: trip?.car_type,
                            avoid_highways: trip?.avoid_highways,
                            avoid_tolls: trip?.avoid_tolls,
                            fare_factor: trip?.fare_factor || 1,
                            mas_trip_id: trip?.mas_trip_id,
                        })
                        .subscribe((suggested_fare: ISuggestedFare) => {
                            payload.prepaid_amount = suggested_fare?.total_amount;
                            payload.third_party_amount = suggested_fare?.submerchant_driver_payout;
                            payload.gross_fare_amount = suggested_fare?.submerchant_driver_payout;
                            this.update_assign_mas_trip(payload);
                        });
                } else {
                    this.update_assign_mas_trip(payload);
                }
            }
        });
    }

    update_car_type(trip: any, car_type: any) {
        const payload: any = {
            mas_trip_id: trip?.mas_trip_id,
            car_type,
            third_party_amount: trip?.third_party_amount || 0,
            user_toll: trip?.user_toll,
            will_call: trip?.will_call || 0,
            pickup_time: trip?.pickup_time,
        };
        if (trip?.is_prepaid) {
            // If trip is prepaid, then refetch the suggested fare and update it from here.
            this.tripBookingService
                .getSuggestedFare({
                    pickup_latitude: trip?.pickup_latitude,
                    pickup_longitude: trip?.pickup_longitude,
                    destination_latitude: trip?.dropoff_latitude,
                    destination_longitude: trip?.dropoff_longitude,
                    car_type,
                    avoid_highways: trip?.avoid_highways,
                    avoid_tolls: trip?.avoid_tolls,
                    fare_factor: trip?.fare_factor || 1,
                    mas_trip_id: trip?.mas_trip_id,
                })
                .subscribe((suggested_fare: ISuggestedFare) => {
                    payload.prepaid_amount = suggested_fare?.total_amount;
                    payload.third_party_amount = suggested_fare?.submerchant_driver_payout;
                    payload.gross_fare_amount = suggested_fare?.submerchant_driver_payout;
                    this.update_assign_mas_trip(payload);
                });
        } else {
            this.update_assign_mas_trip(payload);
        }
    }

    update_ride_status(trip: any, ride_status: any = null) {
        if (this.live_trip_modal_ref) {
            this.live_trip_modal_ref.hide();
            this.live_trip_modal_ref = null;
        }
        // if (ride_status === 5) {
        //     this.utilityService
        //         .confirm({
        //             heading: 'Cancel this Trip',
        //             description: 'You are CANCELLING this trip, please confirm.',
        //             leftButtonText: 'Cancel',
        //             rightButtonText: 'Yes',
        //         })
        //         .subscribe((event: { action: 'left' | 'right' }) => {
        //             if (event.action === 'right') {
        //                 this.update_assign_mas_trip({ mas_trip_id: trip?.mas_trip_id, ride_status });
        //             }
        //         });
        // } else {
        //     this.update_assign_mas_trip({ mas_trip_id: trip?.mas_trip_id, ride_status });
        // }

        this.update_assign_mas_trip({ mas_trip_id: trip?.mas_trip_id, ride_status });
    }

    update_assign_mas_trip(
        payload: {
            mas_trip_id: number | string;
            pickup_time?: string;
            pickup_date?: string;
            ride_status?: any;
            car_type?: number;
            third_party_amount?: any;
            gross_fare_amount?: any;
            fare_factor?: any;
            user_toll?: any;
            avoidHighways?: any;
            avoid_tolls?: any;
            will_call?: number;
            pickup_location?: string;
            pickup_latitude?: string;
            pickup_longitude?: string;
            dropoff_location?: string;
            dropoff_latitude?: string;
            dropoff_longitude?: string;
            ride_confirmed?: number;
            show_driver_payout?: number;
            is_prepaid?: number;
            prepaid_amount?: number;
        },
        silent_reload: boolean = true,
        skip_reload: boolean = false,
        tripRef?: any
    ) {
        if (!skip_reload) {
            this.loading_assigned_trip_container.push(payload?.mas_trip_id);
        }
        this.subscriptionList$.add(
            this.masAssignService.edit_assign_mas_trip(payload).subscribe(
                (res: { flag: number; log?: string; error?: string }) => {
                    if (res.flag === 2204) {
                        this.utilityService.toast('success', res.log);

                        if (payload.is_prepaid == 1 && tripRef && tripRef?.trip_leg_sequence == 1 && tripRef?.invoice_number) {
                            from(
                                this.masQueueService.prepaid_second_leg_trip({
                                    initial_trip_leg_id: tripRef?.trip_leg_id,
                                    invoice_number: tripRef?.invoice_number,
                                    date: moment(tripRef?.pickup_datetime).tz('America/New_York').format('MM/DD/YYYY'),
                                })
                            ).subscribe(
                                (res) => {
                                    this.get_assigned_trips(this.pageNo, this.itemsPerPage, silent_reload);
                                    this.loading_assigned_trip_container = this.loading_assigned_trip_container.filter(
                                        (item) => item !== tripRef?.mas_trip_id
                                    );
                                },
                                (err) => {
                                    console.log('Error handle_second_leg_trip', err);
                                    this.utilityService.toast('error', 'Something went wrong while marking return trip as prepaid');
                                },
                                () => {
                                    this.refetchPrepaidSubject.next();
                                }
                            );
                        } else {
                            if (!skip_reload) {
                                this.get_assigned_trips(this.pageNo, this.itemsPerPage, silent_reload, payload.mas_trip_id);
                            } else {
                                this.loading_assigned_trip_container.push(payload?.mas_trip_id);
                            }
                        }

                        // only affected when we update the price from update_driverpay_amount function
                        this.driver_pay_loading = false;
                        this.applied_suggested_payout = true;

                        if (payload?.is_prepaid != null || payload?.prepaid_amount != null) {
                            this.refetchPrepaidSubject.next();
                        }
                    } else {
                        this.utilityService.toast('error', res?.error || res?.log || 'Something went wrong');
                        this.get_assigned_trips(this.pageNo, this.itemsPerPage, silent_reload, payload.mas_trip_id);
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                    this.loading_assigned_trip_container.push(payload?.mas_trip_id);
                }
            )
        );
    }

    handleUnCancelTrip(trip: any) {
        if (trip?.invoice_number) {
            this.update_assigned_trip(trip, {}, 'unassign', 'uncancelling');
        } else {
            trip.rider_status = 0;
            this.update_ride_status(trip, 0);
        }
    }

    driver_pay_loading: boolean = false;
    applied_suggested_payout: boolean = false;
    update_driverpay_amount(trip: any) {
        this.driver_pay_loading = true;
        trip.third_party_amount = this.suggested_driver_payout;
        trip.gross_fare_amount = this.suggested_fare;
        this.update_assign_mas_trip(
            {
                mas_trip_id: trip?.mas_trip_id,
                third_party_amount: this.suggested_driver_payout,
                gross_fare_amount: this.suggested_fare,
                user_toll: trip?.user_toll,
                car_type: trip?.car_type || 1,
                pickup_time: trip?.pickup_time,
                will_call: trip?.will_call || 0,
            },
            true,
            true
        );
    }

    live_trip_modal_ref: BsModalRef;
    async open_live_map_view(index: number) {
        try {
            const initialState: ModalOptions = {
                id: Date.now(),
                initialState: {
                    left_action_text: 'Unassign',
                    right_action_text: 'Dispatch',
                    trip_type: 'assigned',
                    total_trips: this.trips,
                    trip_index: index,
                },
                ...this.utilityService.mapViewModal,
            };
            this.live_trip_modal_ref = this.modalService.show(LiveTripInfoComponent, initialState);
            this.live_trip_modal_ref.content.event.subscribe((data: { action: 'Unassign' | 'Dispatch' | 'Re-Dispatch' | null; trip: any }) => {
                const action = data?.action;
                if (action === 'Unassign') {
                    this.update_assigned_trip(data?.trip, {}, 'unassign');
                } else if (action === 'Dispatch' || action === 'Re-Dispatch') {
                    this.book_mas_ride(data?.trip);
                } else if (Object.keys(MileStone_Map).find((key) => MileStone_Map[key] === data?.action)) {
                    this.update_milestone(data?.trip?.mas_trip_id, data?.trip?.next_milestone, data?.trip);
                } else if (action === 'cancel') {
                    this.utilityService
                        .confirm({
                            heading: 'Cancel this Trip',
                            description: 'You are CANCELLING this trip, please confirm.',
                            leftButtonText: 'Cancel',
                            rightButtonText: 'Yes',
                        })
                        .subscribe((event: { action: 'left' | 'right' }) => {
                            if (event.action === 'right') {
                                this.update_ride_status(data?.trip, 5);
                            }
                        });
                }
            });
        } catch (err) {
            console.log(err);
            this.utilityService.toast('error', err?.message || 'Something went wrong');
        }
    }

    add_or_update_secondary_contact(mode: 'add' | 'edit', contact?: ISecondaryContact) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode,
                contact: { name: contact?.name, mobile: contact?.user_secondary_mobile },
                title: mode === 'edit' ? 'Edit Secondary Contact' : 'Add Secondary Contact',
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((action: { name: string; mobile: string }) => {
            if (action) {
                this.utilityService.loading = true;

                // Add contact
                if (mode === 'add') {
                    this.subscriptionList$.add(
                        this.userService
                            .add_secondary_contacts({ user_id: this.active_sc_user_id, mobile: action?.mobile, name: action?.name })
                            .subscribe(
                                (res) => {
                                    console.log(res);
                                    if (res?.flag === 1500) {
                                        this.utilityService.toast('success', `Added ${action?.name} as secondary contact`);
                                        modalref.hide();
                                        this.get_secondary_contacts(this.active_sc_user_id, true);
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                } else {
                    this.subscriptionList$.add(
                        this.userService
                            .update_seconday_contacts({ id: contact.id, mobile: action?.mobile, name: action?.name })
                            .subscribe(
                                (res) => {
                                    console.log(res);
                                    if (res?.flag === 1500) {
                                        this.utilityService.toast('success', `Updated secondary contact ${action?.name}`);
                                        modalref.hide();
                                        this.get_secondary_contacts(this.active_sc_user_id, true);
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                }

                // Edit Contact
            }
        });
    }

    get_secondary_contacts(user_id: number | string, silent: boolean = false) {
        this.active_sc_user_id = user_id;
        if (!user_id) {
            this.utilityService.toast('info', 'Trip is not associated with any rider');
            console.log('User id not found in the trip');
            return;
        }
        if (!silent) this.secondary_contact_loading = true;
        this.subscriptionList$.add(
            this.userService
                .get_secondary_contacts(user_id)
                .subscribe(
                    (res) => {
                        console.log('res => ', res);
                        if (res?.contacts) {
                            this.secondary_contact_list = res?.contacts;
                        } else {
                            this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                    }
                )
                .add(() => {
                    this.secondary_contact_loading = false;
                })
        );
    }

    remove_secondary_contact(contact: ISecondaryContact) {
        this.utilityService
            .confirm({
                heading: 'Remove secondary contact',
                description: `Are you sure you want to remove <u>${contact?.name}</u> as Secondary Contact?`,
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event?.action === 'right') {
                    this.utilityService.loading = true;
                    this.userService
                        .remove_secondary_contact(contact.id)
                        .subscribe((res) => {
                            if (res?.flag === 1500) {
                                this.utilityService.toast('success', `${contact?.name} has been removed successfully from secondary contact`);
                                this.get_secondary_contacts(contact.user_id, true);
                            } else {
                                this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                            }
                        })
                        .add(() => {
                            this.utilityService.loading = false;
                        });
                }
            });
    }

    update_milestone(mas_trip_id: number | string, next_milestone: MileStone, trip?: any) {
        this.utilityService
            .confirm({
                heading: MileStone_Map[next_milestone],
                description: Milestone_Update_Confirmation[next_milestone],
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    // this.utilityService.loading = true;
                    this.loading_assigned_trip_container.push(mas_trip_id);
                    this.active_milestone_trip_mas_trip_id = mas_trip_id;
                    this.subscriptionList$.add(
                        this.masAssignService
                            .update_ride_milestone(mas_trip_id, next_milestone)
                            .subscribe(
                                (res: { flag: number; message: string; error?: string }) => {
                                    if (res?.flag === 923) {
                                        this.utilityService.toast('success', res?.message);

                                        this.get_assigned_trips(this.pageNo, this.itemsPerPage, true, mas_trip_id);
                                        // If last milestone call then complete the trip.
                                        // if (next_milestone === 5) {
                                        //     this.update_ride_status(trip, 4);
                                        // } else {
                                        //     this.get_assigned_trips(this.pageNo, this.itemsPerPage, true, mas_trip_id);
                                        // }
                                    } else if (res?.error) {
                                        this.utilityService.toast('error', res?.error);
                                    } else {
                                        this.utilityService.toast('info', res?.message);
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                if (this.live_trip_modal_ref) {
                                    this.live_trip_modal_ref.hide();
                                    this.live_trip_modal_ref = null;
                                }
                                // this.utilityService.loading = false;
                            })
                    );
                }
            });
    }

    async onRefreshTrip(trip: any) {
        this.refreshActionContainer.push(trip?.trip_leg_id);
        if (trip?.invoice_number) {
            try {
                const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number, 2);
                if (mas_both_leg_trip?.trips?.length === 0 && mas_both_leg_trip?.message === 'No Results.') {
                    this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                    this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                } else {
                    this.refreshActionContainer = this.refreshActionContainer.filter((leg_id) => leg_id != trip?.trip_leg_id);
                }
            } catch (errr) {
                console.log('No result found');
                this.utilityService.toast('success', 'Trip not found on MAS, removing from queued');
            }
        }
        // stop loading
    }

    delete_mas_trip(leg_id: string, mas_trip_id?: string) {
        this.masService.delete_mas_trip(leg_id).subscribe((res) => {
            this.get_assigned_trips(this.pageNo, this.itemsPerPage, true, mas_trip_id);
        });

        this.refreshActionContainer = this.refreshActionContainer.filter((item) => item != leg_id);
    }

    getQueuedDrivers(mas_trip_id: any) {
        this.paged_driver_loading = true;
        this.subscriptionList$.add(
            this.masQueueService
                .get_queue_driver_info(mas_trip_id)
                .subscribe((data) => {
                    this.confirmed_driver_list = data?.confirmed_drivers || [];
                    this.interested_driver_list = data?.interested_drivers || [];
                })
                .add(() => {
                    this.paged_driver_loading = false;
                })
        );
    }

    updateDriverPercentage(payload: { third_party_percentage?: number; show_driver_payout?: number }) {
        this.subscriptionList$.add(
            this.driverService.set_driver_percentage(payload).subscribe(
                (res: { flag: number; error?: string; log?: string }) => {
                    if (res?.flag === 1329) {
                        this.utilityService.toast('success', res?.log);
                        if (payload?.show_driver_payout === 0 || payload?.show_driver_payout === 1) {
                            this.get_assigned_trips(this.pageNo, this.itemsPerPage, true);
                        }
                        this.fetchDriverPercentage();
                    } else {
                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        console.log('driver percentage error => ', res);
                    }
                },
                (err) => {
                    console.log('driver percentage error => ', err);
                }
            )
        );
    }

    fetchDriverPercentage() {
        this.driverService.featch_driver_percentage().subscribe(
            (res: { third_party_percentage: number; show_driver_payout: number }) => {
                this.driver_payout_percentage = res?.third_party_percentage;
                this.show_all_driver_payout = res?.show_driver_payout;
            },
            (err) => {
                console.log('driver percentage error => ', err);
            }
        );
    }

    @ViewChild('tableHeader') tableHeaderRef: ElementRef;
    showHeaderShadow: boolean = false;
    onScroll(event: any) {
        this.showHeaderShadow = event.srcElement.scrollTop !== 0;
        const rec = this.tableHeaderRef.nativeElement.getBoundingClientRect();
        if (rec?.bottom < 220) {
            this.showBottomSearchBox = true;
        } else {
            this.showBottomSearchBox = false;
        }
    }

    togglTripPayoutSelection(event, trip: any) {
        this.total_payout_selection_modal.toggle(trip);
        if (event?.checked) {
            this.total_payout += trip?.third_party_amount;
        } else {
            this.total_payout -= trip?.third_party_amount;
        }
        this.onTotalPayoutChange.emit(this.total_payout);
    }
    create_payout() {
        if (!this.total_payout) return;
        console.log('payout', this.total_payout);

        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                total_payout: this.total_payout,
                total_trips: this.total_payout_selection_modal.selected?.length,
                payout_payload: {
                    amount: this.total_payout,
                    ride_details: this.total_payout_selection_modal.selected.map((item) => {
                        return {
                            referance_id: Number(item?.sent_to) || item?.sent_to,
                            amount: item?.third_party_amount,
                            session_id: item?.session_id,
                            transaction_type: 1,
                            mas_trip_id: item?.mas_trip_id,
                        };
                    }),
                },
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(CreatePayoutConfirmModalComponent, initialState);
        modalref.content.event.subscribe((action: boolean) => {
            if (action) {
                this.get_assigned_trips(this.pageNo, this.itemsPerPage, true);
                this.total_payout_selection_modal.clear();
                this.total_payout = 0;
                this.onTotalPayoutChange.emit(this.total_payout);
            }
        });
    }

    suggested_fare_loading: boolean = false;
    suggested_driver_payout: number;
    suggested_fare: number;
    suggested_fare_trip_refrence: any;
    get_suggested_fare(trip: any) {
        this.suggested_fare_trip_refrence = trip;
        this.suggested_fare_loading = true;
        this.subscriptionList$.add(
            this.tripBookingService
                .getSuggestedFare({
                    pickup_latitude: trip?.pickup_latitude,
                    pickup_longitude: trip?.pickup_longitude,
                    destination_latitude: trip?.dropoff_latitude,
                    destination_longitude: trip?.dropoff_longitude,
                    car_type: trip?.car_type,
                    avoid_highways: trip?.avoid_highways,
                    avoid_tolls: trip?.avoid_tolls,
                    fare_factor: trip?.fare_factor || 1,
                    mas_trip_id: trip?.mas_trip_id,
                })
                .subscribe(
                    (res: { submerchant_driver_payout: number; value_regular: number }) => {
                        if (res?.submerchant_driver_payout == null && res?.value_regular == null) {
                            console.log(res);
                            this.utilityService.toast('error', 'Something went wrong');
                        } else {
                            this.suggested_driver_payout = res?.submerchant_driver_payout;
                            this.suggested_fare = res?.value_regular;
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                    }
                )
                .add(() => {
                    this.suggested_fare_loading = false;
                })
        );
    }

    onDispatched() {
        this.router.navigate(['/', 'corporate', 'live-tracking']);
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }

    /** Open Mobile Design Fare Estimate modal */
    async fareEstimateRecalc(trip) {
        this.utilityService.loading = true;
        if (this.live_trip_modal_ref) {
            this.live_trip_modal_ref.hide();
            this.live_trip_modal_ref = null;
        }
        if (!trip?.invoice_number) {
            this.suggested_fare_trip_refrence = trip;
        } else {
            const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number);
            const mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId.toString() === trip?.trip_leg_id.toString());
            if (!mas_active_leg_trip) {
                this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                return;
            }
            mas_active_leg_trip.pickupTime = moment(trip?.pickup_datetime).format('HHmm');
            mas_active_leg_trip.pickup_time = moment(trip?.pickup_datetime).format('hh:mm a');
            mas_active_leg_trip.pickup_datetime = new Date(
                moment(mas_active_leg_trip?.pickupDate).format('YYYY-MM-DD') + ', ' + mas_active_leg_trip?.pickup_time
            );
            this.suggested_fare_trip_refrence = trip;
        }
        this.suggested_fare_trip_refrence.pickup_lat = this.suggested_fare_trip_refrence.pickup_latitude;
        this.suggested_fare_trip_refrence.pickup_lang = this.suggested_fare_trip_refrence.pickup_longitude;
        this.suggested_fare_trip_refrence.dropoff_lat = this.suggested_fare_trip_refrence.dropoff_latitude;
        this.suggested_fare_trip_refrence.dropoff_lang = this.suggested_fare_trip_refrence.dropoff_longitude;
        this.suggested_fare_trip_refrence.userMobileListDetail = this.suggested_fare_trip_refrence.user_mobile;
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                formValue: this.suggested_fare_trip_refrence,
                from: 'list-detail',
            },
            class: 'queud-sortcut modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(FareEstimateRecalculationComponent, initialState);
        modalref.content.event.subscribe((res: any) => {
            if (res) {
                const payload = {
                    mas_trip_id: this.suggested_fare_trip_refrence.mas_trip_id,
                    avoidHighways: res.avoid_highways ? 1 : 0,
                    avoid_tolls: res.avoid_tolls ? 1 : 0,
                    car_type: res.car_type,
                    third_party_amount: res.third_party_amount,
                    gross_fare_amount: res.estimated_fare,
                    fare_factor: res.fare_factor,
                };
                this.update_assign_mas_trip(payload);
            }
        });
    }

    /** Window Resize */
    isMobileScreen = false;
    from: string;
    isActiveInput: boolean = false;
    listRideBrokers: any[] = [];
    private checkScreenWidth(): void {
        this.isMobileScreen = window.innerWidth <= 600;
    }

    /** Active Input For Ride Brokers*/
    searchActive() {
        this.isActiveInput = !this.isActiveInput;
    }

    /** Filter Data By Broker Status */
    selectRideBroker(type: string) {
        this.masService.brokerStatus = type;
        this.masService.brokerStatusLabel = type;
        this.get_assigned_trips();
    }

    /** Ride Broker Drodpwon List */
    getRideBrokerDropdownList() {
        this.httpService
            .post(environment.urlWC + 'list_ride_brokers', { web_access_token: this.cookieService.get('web_access_token') })
            .subscribe((data) => {
                if (data.flag === 2101) {
                    this.listRideBrokers = data.data;
                    this.listRideBrokers = this.listRideBrokers.filter((broker) => broker.company_name !== 'SentryMS');
                    // Add SENTRY-TP and SENTRY-MP
                    this.listRideBrokers.push({
                        id: 14,
                        company_name: 'SENTRY-TPT',
                        office_number: 'YOUR_OFFICE_NUMBER',
                        contact_person: 'YOUR_CONTACT_PERSON',
                        contact_number: 'YOUR_CONTACT_NUMBER',
                        office_address: 'YOUR_OFFICE_ADDRESS',
                        is_enabled: 1,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                        is_deleted: 0,
                    });
                    this.listRideBrokers.push({
                        id: 15,
                        company_name: 'SENTRY-MP',
                        office_number: 'YOUR_OFFICE_NUMBER',
                        contact_person: 'YOUR_CONTACT_PERSON',
                        contact_number: 'YOUR_CONTACT_NUMBER',
                        office_address: 'YOUR_OFFICE_ADDRESS',
                        is_enabled: 1,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                        is_deleted: 0,
                    });
                }
            });
    }
}
