<div class="modal-dialog modal-dialog-centered modal-lg touch-popup" role="document" style="width: 500px !important">
    <div class="modal-content shadow-sm border-0" style="border-radius: 10px">
        <div class="touch-header pt-1 t-margin">
            <div class="d-flex justify-content-between mt-3 mb-2">
                <div class="medium-text dark-color align-self-center">
                    <div class="p-0 pb-1 my-0">Leg Cost Breakdown</div>
                </div>

                <div class="d-flex justify-content-end pb-1">
                    <div class="mr-2 my-0 align-self-center">
                        <img src="assets/images/divider_black.svg" alt="divider" />
                    </div>
                    <div class="mx-3 my-0 mr-auto align-self-center text-center">
                        <div class="medium-text blue-color">
                            <ng-container *ngIf="isLoading">
                                <ng-container [ngTemplateOutlet]="legcost_item_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!isLoading">
                                {{ total_leg_cost | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <hr class="light" />
        <div class="touch-content t-margin my-4">
            <div class="estimation-detail">
                Your estimated total leg cost will be displayed before the trip. Adjustments are made on the attestation if actual route has toll or
                subject to government mandated fees and taxes that differs from the estimated authorised total leg cost. Authorized cost are based on
                rates below and other applicable tolls, surcharges, adjustments and fees. THESE ARE ONLY ESTIMATES!
            </div>

            <!-- estimate-table -->
            <div class="my-4 estimation-list dark-color">
                <div class="my-3">
                    <div class="d-flex justify-content-between">
                        <div>Primary Rate</div>
                        <div>
                            <ng-container *ngIf="isLoading">
                                <ng-container [ngTemplateOutlet]="legcost_item_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!isLoading">
                                {{ active_trip?.legCost | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>
                    <div class="d-flex">
                        <div>Leg Mileage</div>
                        <div class="mx-1">|</div>
                        <div>
                            <ng-container *ngIf="isLoading">
                                <ng-container [ngTemplateOutlet]="legcost_item_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!isLoading">
                                {{ active_trip?.legMileage | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                            </ng-container>
                        </div>
                    </div>
                </div>

                <div class="my-4">
                    <div class="fw-600 dark-color">Secondary Service</div>
                    <ng-container *ngIf="isLoading">
                        <div *ngFor="let item of [1, 2]" class="d-flex justify-content-between">
                            <div>
                                <app-loading type="placeholder" width="145px" margin="0px" borderRadius="4px" height="12px"></app-loading>
                            </div>
                            <div>
                                <app-loading type="placeholder" width="45px" margin="0px" borderRadius="4px" height="12px"></app-loading>
                            </div>
                        </div>
                    </ng-container>
                    <ng-container *ngIf="!isLoading">
                        <div *ngFor="let item of active_trip?.secondary_services" class="d-flex justify-content-between">
                            <div>
                                {{ item?.service_name }}
                                <ng-container *ngIf="item?.service_procedure_code">| {{ item?.service_procedure_code }}</ng-container>
                            </div>
                            <div>{{ item?.service_rate | currency: 'USD':'symbol':utilityService.show_currency_precision }}</div>
                        </div>
                    </ng-container>

                    <div class="d-flex justify-content-between">
                        <div>Actual Toll Charge</div>
                        <div>
                            <ng-container *ngIf="isLoading">
                                <ng-container [ngTemplateOutlet]="legcost_item_loading_template"></ng-container>
                            </ng-container>
                            <ng-container *ngIf="!isLoading">
                                <span *ngIf="toll">
                                    {{ toll | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                                </span>
                                <span *ngIf="!toll">N/A</span>
                            </ng-container>
                        </div>
                    </div>
                </div>
            </div>

            <div class="mb-4" class="estimation-detail">Additional reimbursement may apply to your trip depending on the attestation submission.</div>
        </div>
        <hr class="light" />

        <div class="touch-bottom t-margin my-4">
            <div class="d-flex justify-content-around">
                <div class="cursor-pointer blue-color" (click)="bsModalRef.hide()">Close</div>
            </div>
        </div>
    </div>
</div>

<ng-template #legcost_item_loading_template>
    <app-loading type="placeholder" width="45px" margin="0px" borderRadius="4px" height="12px"></app-loading>
</ng-template>
