<span
    class="cursor-pointer"
    [popover]="add_shortcut_popover"
    #addShortCutPopover="bs-popover"
    containerClass="br-6 z-index-1000 w-260"
    placement="bottom"
    [outsideClick]="true"
    (onShown)="add_shortcut_popover_reference = addShortCutPopover"
>
    <ng-content></ng-content>
</span>

<ng-template #add_shortcut_popover>
    <div class="touch-popover">
        <div class="bg-grayish-blue p-4 fs-14 text-dark-blue fw-600" style="border-radius: 6px 6px 0 0">Add or shortcuts</div>
        <div class="p-4">
            <div
                *ngFor="let option of shortcutOptions; let index = index"
                class="text-grey hover-bold cursor-pointer"
                (click)="onShortcutSelect(option.type)"
                [class.mt-3]="index !== 0"
            >
                {{ option.title }}
            </div>
        </div>
        <hr class="my-0 py-0" />
        <div class="d-flex justify-content-center text-blue-secondary fs-13 fw-600 my-4">
            <div class="cursor-pointer" (click)="add_shortcut_popover_reference.hide()">Dismiss</div>
        </div>
    </div>
</ng-template>
