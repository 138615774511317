import { Component, ElementRef, EventEmitter, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import * as _ from 'lodash';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { Assinged_Ride_Status, MileStone_Map } from 'src/app/models/assigned-trips.model';
import { HttpService } from 'src/app/services/http/http.service';
import { SocketioService } from 'src/app/services/socketio/socketio.service';
import { environment } from 'src/environments/environment';
import { GoogleMapService } from '../../services/google-map/google-map.service';
import { MasQueueService } from '../../services/mas-queue/mas-queue.service';
import { MasService } from '../../services/mas/mas.service';
import { TripsService } from '../../services/trips/trips.service';

export interface ILiveTripInfo {
    driver_id: string;
    driver_name: string;
    driver_mobile: string;
    rider_name: string;
    rider_mobile: string;
    pickup_datetime: string;
    pickup_location: string;
    dropoff_location: string;
    pickup_latitude: string;
    pickup_longitude: string;
    dropoff_latitude: string;
    dropoff_longitude: string;
    polylines?: string;
    mas_trip_id?: number | string;
}

@Component({
    selector: 'app-live-trip-info',
    templateUrl: './live-trip-info.component.html',
    styleUrls: ['./live-trip-info.component.scss'],
})
export class LiveTripInfoComponent implements OnInit, OnDestroy {
    constructor(
        public bsModalRef: BsModalRef,
        private socketService: SocketioService,
        private googleMapService: GoogleMapService,
        private utilityService: UtilityService,
        private cookieService: CookieService,
        private httpService: HttpService,
        private masQueueService: MasQueueService,
        private masService: MasService,
        private tripsService: TripsService
    ) {}

    showRiderDetail: boolean = true;
    trip: ILiveTripInfo;
    next_trip: ILiveTripInfo;
    prev_trip: ILiveTripInfo;
    trip_type: 'unassigned' | 'ongoing' | 'queued' | 'assigned';
    show_cancel_trip_label: boolean = false;
    is_mas_trip: boolean = true;
    is_unassigned: boolean = false;
    left_action_text: string;
    right_action_text: string;
    total_trips: Array<any>;
    trip_index: number = 0;
    public event: EventEmitter<any> = new EventEmitter();
    // mapType: google.maps.MapTypeId = google.maps.MapTypeId.SATELLITE;
    map: google.maps.Map;

    directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
    directionsDisplay: google.maps.DirectionsRenderer;

    center: { lat: number; lng: number } = { lat: 40.73221, lng: -73.91902 };
    zoom = 11;
    gettingDrivers: any;
    driver: any;

    driver_search_control: FormControl = new FormControl('');
    driverLoading: boolean = false;
    drivers: Array<any> = [];
    cached_drivers: Array<any> = [];
    selected_driver: any;

    markers: Array<{
        path?: string;
        marker: google.maps.Marker;
        time: number;
        isMoving?: boolean;
        driver_id: number | string;
        infowindow: google.maps.InfoWindow;
    }> = [];
    bounds: google.maps.LatLngBounds = new google.maps.LatLngBounds();

    @ViewChild('map') mapElement: ElementRef;

    driver_pickup_ETA: {
        text: string;
        value: number;
    };
    pickup_dropoff_ETA: {
        text: string;
        value: number;
    };

    show_driver_infowindow: boolean = true;

    isLoading: boolean = false;
    // dropoff_ETA:

    ngOnInit(): void {
        this.setupLiveTripInfo();
        if (this.trip_type === 'queued') {
            this.getDrivers();

            this.driver_search_control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                if (value) {
                    value = value.trim();
                }
                this.getDrivers(value);
            });
        }
    }

    async setupLiveTripInfo() {
        this.isLoading = true;
        this.markers = [];

        // Pre Process Prev Next flow and required variables
        const current_trip = this.total_trips?.[this.trip_index];
        const previouse_trip = this.total_trips?.[this.trip_index - 1];
        const next_trip = this.total_trips?.[this.trip_index + 1];

        this.is_mas_trip = current_trip?.invoice_number ? true : false;

        if (this.trip_type === 'assigned' || this.trip_type === 'queued') {
            this.trip = this.masService.parse_mas_assigned_or_queued_to_live_trip_info(current_trip);
            this.prev_trip = previouse_trip ? this.masService.parse_mas_assigned_or_queued_to_live_trip_info(previouse_trip) : null;
            this.next_trip = next_trip ? this.masService.parse_mas_assigned_or_queued_to_live_trip_info(next_trip) : null;

            // CHECK IF CURRENT TRIP IS ASSIGNED OR QUEUED NOT.
            if (current_trip?.is_assigned) {
                this.trip_type = 'assigned';
                this.show_cancel_trip_label = current_trip?.ride_milestone && current_trip?.ride_milestone <= 4;

                // this.show_driver_infowindow = this.trip_type === 'assigned' ? true : false;

                if (
                    current_trip?.invoice_number &&
                    current_trip?.ride_status == Assinged_Ride_Status.CONFIRMED &&
                    ((moment().isSame(moment(current_trip?.pickup_datetime), 'day') &&
                        moment(current_trip?.pickup_datetime).isBefore(moment().add(1, 'hour'))) ||
                        current_trip?.ride_milestone > 0)
                ) {
                    const next_milestone = current_trip?.ride_milestone + 1;
                    this.left_action_text = MileStone_Map[next_milestone];
                    this.total_trips[this.trip_index].next_milestone = next_milestone;
                } else {
                    this.left_action_text = 'Unassign';
                }
            } else if (current_trip?.is_queued) {
                this.trip_type = 'queued';
                this.left_action_text = 'Unqueue';
            }

            this.right_action_text = current_trip?.ride_status === 3 ? 'Re-Dispatch' : 'Dispatch';
        }
        if (this.trip_type === 'unassigned') {
            this.trip = this.masService.parse_mas_trip_to_live_trip_info(current_trip);
            this.prev_trip = previouse_trip ? this.masService.parse_mas_trip_to_live_trip_info(previouse_trip) : null;
            this.next_trip = next_trip ? this.masService.parse_mas_trip_to_live_trip_info(next_trip) : null;
        }
        if (this.trip_type === 'ongoing') {
            this.trip = this.tripsService.parse_ride_history_to_live_trip_info(current_trip);
            this.prev_trip = previouse_trip ? this.tripsService.parse_ride_history_to_live_trip_info(previouse_trip) : null;
            this.next_trip = next_trip ? this.tripsService.parse_ride_history_to_live_trip_info(next_trip) : null;
        }

        if (this.trip?.driver_id) {
            this.directionsDisplay = new google.maps.DirectionsRenderer({ preserveViewport: true });
        } else {
            this.directionsDisplay = new google.maps.DirectionsRenderer();
        }

        this.initializeMap();
        this.getLiveDrivers();
        this.listenDriverUpdates();

        this.isLoading = false;
    }

    onNavigate(action: 'next' | 'prev') {
        if (action === 'next') this.trip_index += 1;
        if (action === 'prev') this.trip_index -= 1;

        this.cleanup();
        this.ngOnInit();
    }

    initializeMap() {
        if (!this.map) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.center, //{ lat: -34.397, lng: 150.644 },
                zoom: this.zoom,
                mapTypeId: google.maps.MapTypeId.TERRAIN,
                // disableDefaultUI: true,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                streetViewControl: false,
            });

            const bannerControlDiv = document.createElement('div');
            bannerControlDiv.appendChild(this.bannerViewControl(this.map));
            this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(bannerControlDiv);
        }
        this.directionsDisplay.setMap(this.map);
        // this.directionsDisplay.setPanel(document.getElementById('panel'));

        const pickup = this.trip.pickup_latitude + ',' + this.trip.pickup_longitude;
        const dropoff = this.trip.dropoff_latitude + ',' + this.trip.dropoff_longitude;
        // console.log(this.trip?.pickup);
        const request: google.maps.DirectionsRequest = {
            origin: pickup,
            destination: dropoff,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidHighways: this.googleMapService.defaultAvoidHighways,
            avoidTolls: this.googleMapService.defaultAvoidTolls,
        };
        this.trip.polylines = 'NOT FOUND YET';
        this.directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.pickup_dropoff_ETA = {
                    text: this.googleMapService.secondsToHms(response.routes?.[0].legs?.[0]?.duration?.value),
                    value: response.routes?.[0].legs?.[0]?.duration?.value,
                };
                console.log('PICKUP_DROPOFF ETA => ', this.pickup_dropoff_ETA);
                this.directionsDisplay.setDirections(response);
                this.trip.polylines = response.routes[0].overview_polyline;
            }
        });

        // const position = { lat: 40.732139, lng: -73.866327 };
        // const marker = new google.maps.Marker({
        //     position: position,
        //     map: this.map,
        // });

        // marker.setMap(null);
    }

    public getLiveDrivers() {
        // Internal function for code resuability

        const driver_payload: any = {
            session_id: undefined,
            offset: 0,
        };
        if (this.trip?.driver_id && this.trip?.driver_name) {
            driver_payload.searchFlag = 1;
            driver_payload.searchString = this.trip?.driver_mobile || this.trip?.driver_name;
            driver_payload.limit = 10;
        } else {
            driver_payload.is_available = 1;
        }

        const driverEmittor = () => {
            this.socketService.emit('corporateDriverAvailablity', driver_payload);
        };

        driverEmittor();
        this.gettingDrivers = setInterval(() => {
            driverEmittor();
        }, this.socketService.intervalDuration);
    }

    driverAvailablitySubscription: Subscription;
    listenDriverUpdates() {
        this.driverAvailablitySubscription = this.socketService.on('corporateDriverAvailablity').subscribe((data) => {
            // console.log('corporateDriverAvailablity => ', data);
            const driver_list: Array<any> = data.data.drivers || [];
            console.log('driver_list => ', driver_list);
            // const driver = driver_list.find((driver) => Number(driver?.driver_id) === Number(this.trip?.sent_to));

            // console.log("driver_list => ", driver_list);
            // this.driver = driver_list.find((driver) => Number(driver?.driver_id) === Number(this.trip?.driver_id));

            driver_list.forEach((driver) => {
                this.syncDriverMarker(driver, Number(driver?.driver_id) === Number(this.trip?.driver_id));
                if (Number(driver?.driver_id) === Number(this.trip?.driver_id)) {
                    this.driver = driver;
                    this.getPickupETA(driver);
                }
            });
        });
    }

    getPickupETA(driver: any) {
        const driver_location = driver.current_location_latitude + ',' + driver.current_location_longitude;
        const pickup_location = this.trip.pickup_latitude + ',' + this.trip.pickup_longitude;
        const request: google.maps.DirectionsRequest = {
            origin: driver_location,
            destination: pickup_location,
            travelMode: google.maps.TravelMode.DRIVING,
            transitOptions: {
                arrivalTime: new Date(this.trip?.pickup_datetime),
            },
        };
        this.trip.polylines = 'NOT FOUND YET';
        this.directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.driver_pickup_ETA = {
                    text: this.googleMapService.secondsToHms(response.routes?.[0].legs?.[0]?.duration?.value),
                    value: response.routes?.[0].legs?.[0]?.duration?.value,
                };
            }
        });
    }

    initialDriverLoading: boolean = true;
    syncDriverMarker(driver: any, is_active_driver: boolean = false) {
        console.log('is_active_driver', is_active_driver);
        let url: string;
        let urlGoing: string;
        const image: any = {
            url: url,
            scaledSize: new google.maps.Size(33, 33),
            anchor: new google.maps.Point(16.5, 16.5),
        };

        if (driver.is_free == 1) {
            if (driver.car_type == 2) {
                url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                urlGoing = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
            } else if (driver.car_type == 1) {
                url = 'assets/img/driver_idle.svg';
                urlGoing = 'assets/img/driver_intransit.svg';
            } else if (driver.car_type == 3) {
                url = 'assets/carTypeImage/LUXE/3_White_LUXE.svg';
                urlGoing = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
            } else if (driver.car_type == 4) {
                url = 'assets/carTypeImage/Grande/3_White_Grande.svg';
                urlGoing = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
            } else {
                url = 'assets/carTypeImage/QLE/3_White_QLE.svg';
                urlGoing = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
            }
        } else {
            if (driver.car_type == 1) {
                url = 'assets/carTypeImage/QLE/2_Blue_QLE.svg';
            } else if (driver.car_type == 2) {
                url = 'assets/carTypeImage/LUXE/2_Blue_LUXE.svg';
            } else if (driver.car_type == 3) {
                url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
            } else {
                url = 'assets/carTypeImage/Grande/2_Blue_Grande.svg';
            }
        }

        let content =
            "<div id='" +
            driver.driver_id +
            "' style='font-size: 9px; padding: 8px'>" +
            "<div style=' class='p-2'>" +
            driver.driver_name.slice(0, 20) +
            '</div>';

        let infowindow: google.maps.InfoWindow = new google.maps.InfoWindow({
            content,
            disableAutoPan: true,
        });
        image.url = `${url}#${driver.driver_id}`;

        const marker: google.maps.Marker = this.setMarker(driver, { image, infowindow });

        if (this.initialDriverLoading && is_active_driver) {
            this.bounds = new google.maps.LatLngBounds();
            this.bounds.extend(new google.maps.LatLng(Number(this.trip.pickup_latitude), Number(this.trip.pickup_longitude)));
            this.bounds.extend(new google.maps.LatLng(Number(this.trip.dropoff_latitude), Number(this.trip.dropoff_longitude)));
            this.bounds.extend(marker.getPosition());
            this.map.fitBounds(this.bounds);

            this.map.setCenter(this.bounds.getCenter());
            this.map.setZoom(this.map.getZoom() - 0.5);
            this.initialDriverLoading = false;
        }
    }

    setMarker(driver: any, { image, infowindow }): google.maps.Marker {
        // set old marker to null
        // this.markers.forEach((m) => m.marker.setMap(null));

        const driver_location = new google.maps.LatLng(driver.current_location_latitude, driver.current_location_longitude);

        const driver_marker = this.markers?.find((marker) => marker.driver_id === Number(driver?.driver_id));
        if (driver_marker) {
            const markerRef = this.markers?.[0];
            let prevPos: any = markerRef.marker.getPosition();
            if (markerRef?.path?.length > 0) {
                prevPos = markerRef.path[markerRef.path.length - 1][0];
            }
            let icon: any = markerRef.marker.getIcon();
            icon.url = image.url;
            markerRef.marker.setIcon(icon);
            markerRef.marker.setMap(this.map);
            // var rotation = google.maps.geometry.spherical.computeHeading(prevPos, location);
            let rotation = google.maps.geometry.spherical.computeHeading(prevPos, driver_location);
            let fromLat = prevPos.lat();
            let fromLng = prevPos.lng();
            let toLat = driver_location.lat();
            let toLng = driver_location.lng();
            if (fromLat != toLat || fromLng != toLng) {
                let diff = Date.now() - markerRef.time;
                markerRef.time = Date.now();
                let frames: any = markerRef.path || [];
                let hasPath = false;
                if (frames?.length > 0) {
                    hasPath = true;
                }
                if (diff > 2000) {
                    diff = 1000;
                }
                if (frames.length >= 100) {
                    frames = [];
                }

                for (let percent = 0; percent < 1; percent += 0.01) {
                    let curLat = fromLat + percent * (toLat - fromLat);
                    let curLng = fromLng + percent * (toLng - fromLng);
                    frames.push([new google.maps.LatLng(curLat, curLng), rotation]);
                }
                markerRef.path = frames;
                if (!hasPath) {
                    this.move(markerRef, diff / 100);
                } else if (!markerRef.isMoving) {
                    this.move(markerRef, diff / 100);
                } else {
                    this.move(markerRef, 0.5);
                }
            }
            return markerRef.marker;
        } else {
            const marker: google.maps.Marker = new google.maps.Marker({
                position: driver_location,
                icon: image,
                map: this.map,
            });
            this.markers.push({
                time: Date.now(),
                marker,
                infowindow,
                driver_id: driver?.driver_id,
            });

            marker.addListener('click', () => {
                this.googleMapService.toggle_infowindow(infowindow, 'show', {
                    anchor: marker,
                    map: this.map,
                    shouldFocus: false,
                });
            });
            if (this.show_driver_infowindow) {
                this.googleMapService.tigger_marker(marker);
            }
            return marker;
        }
    }

    private move = (markerRef: { path?: any; time: number; marker: google.maps.Marker; isMoving?: boolean }, wait: any) => {
        if (markerRef.path.length > 0) {
            markerRef.isMoving = true;
            markerRef.marker.setPosition(markerRef.path[0][0]);
            const iconRef: any = markerRef.marker.getIcon();
            const markerImg: any = document.querySelector(`img[src="${iconRef?.url}"]`);
            if (markerImg) {
                const deg = markerRef.path[0][1];
                markerImg.style.transform = 'rotate(' + deg + 'deg)';
            }
            markerRef.path.splice(0, 1);
            setTimeout(() => {
                this.move(markerRef, wait);
            }, wait);
            let icon = markerRef.marker.getIcon();
            markerRef.marker.setIcon(icon);
        } else {
            markerRef.isMoving = false;
        }
    };

    // custom control
    bannerViewControl(map: google.maps.Map = this.map) {
        const controlButton = document.createElement('div');

        // Set CSS for the control.
        controlButton.style.backgroundColor = '#fff';
        controlButton.style.border = '2px solid #fff';
        controlButton.style.borderRadius = '3px';
        controlButton.style.boxShadow = 'rgb(0 0 0 / 30%) 0px 1px 4px -1px';
        controlButton.style.color = 'rgb(25,25,25)';
        controlButton.style.cursor = 'pointer';
        controlButton.style.fontFamily = 'Roboto,Arial,sans-serif';
        controlButton.style.fontSize = '16px';
        controlButton.style.lineHeight = '36px';
        controlButton.style.margin = '8px 10px 24px';
        controlButton.style.padding = '0 5px';
        // controlButton.style.position = 'absolute';
        // controlButton.style.bottom = '0px';
        // controlButton.style.right = '-348px';
        // controlButton.style.right = '-17vw';

        controlButton.setAttribute('id', 'bannerViewIcon');
        controlButton.innerHTML = this.vibilityIcon; // `<img src="assets/images/visibility.svg" style="width: 28px; height: 28px;">`;

        // Setup the click event listeners: simply set the map to Chicago.
        controlButton.addEventListener('click', () => {
            this.show_driver_infowindow = !this.show_driver_infowindow;
            controlButton.innerHTML = this.vibilityIcon;
            // console.log("show driver => ", this.show_driver_infowindow);
            console.log('show_driver_infowindow => ', this.show_driver_infowindow);
            this.markers.forEach((marker_ref: any) => {
                this.googleMapService.toggle_infowindow(marker_ref?.infowindow, this.show_driver_infowindow ? 'show' : 'hide', {
                    anchor: marker_ref?.marker,
                    map: this.map,
                });
            });
        });

        return controlButton;
    }

    private get vibilityIcon(): string {
        return `<img src="assets/images/${
            this.show_driver_infowindow ? 'visibility_off.svg' : 'visibility.svg'
        }" style="width: 28px; height: 28px;">`;
    }

    // ======= Driver Section =====
    reset_driver_results(id: string = 'select_driver_dropdown_from_queued') {
        const searchString = this.driver_search_control?.value;
        if (searchString) {
            this.driver_search_control.reset();
        }
        const element: HTMLElement = document.getElementById(`${id}`) as HTMLElement;
        setTimeout(() => {
            element.focus();
        }, 0);
    }

    getDrivers(searchString: string = '') {
        this.driverLoading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: this.utilityService.MAS_Qudosfave_Map[this.is_mas_trip ? 'MAS Drivers' : 'All Drivers'],
        };

        if (searchString) {
            params.searchFlag = 1;
            params.searchString = searchString;
        }

        this.httpService
            .post(environment.urlC + 'get_fav_driver', params)
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag !== 101 && data.flag !== 807) {
                    this.drivers = data?.drivers;
                }
            })
            .add(() => {
                this.driverLoading = false;
            });
    }

    on_select_driver(driver: any, type: 'cached' | 'new' = 'new') {
        this.selected_driver = {
            ...driver,
            type,
        };
    }

    recent_driver_alert_logs: Array<string | number> = [];
    send_driver_alert(mas_trip_id: number | string, driver_id: number | string) {
        this.recent_driver_alert_logs.push(`q:${mas_trip_id}:${driver_id}`);
        this.utilityService.loading = true;
        this.masQueueService
            .send_driver_alert({ mas_trip_id, driver_id, web_access_token: this.cookieService.get('web_access_token') })
            .subscribe(
                (res: { flag: number; log: string; error: string }) => {
                    if (res?.flag === 1333) {
                        this.utilityService.toast('success', 'Request sent successfully');
                    } else if (res?.error) {
                        this.utilityService.toast('error', res?.error || 'Something went wrong');
                    } else {
                        this.utilityService.toast('warning', res?.log);
                    }

                    setTimeout(() => {
                        this.release_driver_log(mas_trip_id, driver_id);
                    }, 30 * 1000);
                },
                (err) => {
                    console.log(err);
                    this.utilityService.toast('error', err?.messsage || 'Something went wrong');
                    this.release_driver_log(mas_trip_id, driver_id);
                }
            )
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    release_driver_log(mas_trip_id: number | string, driver_id: number | string) {
        this.recent_driver_alert_logs = this.recent_driver_alert_logs.filter((log) => log !== `q:${mas_trip_id}:${driver_id}`);
    }

    // ============================

    onAction(action: string) {
        const payload: { action: string; driver?: string; trip: any } = {
            action,
            trip: this.total_trips[this.trip_index],
        };
        if (action === 'assign' && this.trip_type === 'queued' && this.selected_driver) {
            payload.driver = this.selected_driver;
        }
        this.event.emit(payload);
    }

    ngOnDestroy(): void {
        this.cleanup();
    }

    cleanup() {
        clearInterval(this.gettingDrivers);
        this.driverAvailablitySubscription.unsubscribe();
        this.markers.forEach((marker) => {
            marker?.marker?.setMap(null);
        });
        this.initialDriverLoading = true;
        this.directionsDisplay.setMap(null);
    }
}
