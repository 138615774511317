import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input-r';
import { country_code } from '../../pages/live-tracking/country-list';

@Component({
    selector: 'app-phone-number-modal',
    templateUrl: './phone-number-modal.component.html',
    styleUrls: ['./phone-number-modal.component.scss'],
})
export class PhoneNumberModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    public event: EventEmitter<any> = new EventEmitter();

    phoneNumber: FormControl = new FormControl();

    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    countryToSet: string = 'US';
    separateDialCode = false;
    countries = country_code;

    ngOnInit(): void {}

    closePopup() {
        this.bsModalRef.hide();
    }

    apply() {
        const phone_no: {
            dialCode: string;
            number: string;
        } = this.phoneNumber.value;
        this.event.emit(phone_no);
        this.closePopup();
    }
}
