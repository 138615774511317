<div class="touch-popup" role="document">
    <div class="modal-content booking-model-component card-shadow">
        <div class="touch-header pt-2">
            <div class="mx-md-5 mx-4 my-4">
                <div class="medium-text dark-color">{{ title }}</div>
            </div>
        </div>

        <div [formGroup]="locationFormGroup">
            <hr class="light" />

            <div class="touch-content p-md-5 p-3">
                <table class="w-100">
                    <tr [hidden]="viewMode !== 'both' && viewMode !== 'pickup'">
                        <td>
                            <div class="align-self-center fs-13 fw-600">
                                Pickup
                                <span class="d-none d-md-inline-block">Location</span>
                            </div>
                        </td>
                        <td>
                            <div class="ml-2">
                                <input
                                    id="pickup_location"
                                    type="search"
                                    class="touch-input w-100 fs-13 p-1 px-2 autocomplete w-75"
                                    placeholder="Enter Pickup Location"
                                    formControlName="pickup_location"
                                    [class.error]="
                                        locationFormGroup?.get('pickup_location')?.touched &&
                                        (!locationFormGroup?.get('pickup_location')?.value || locationFormGroup?.get('pickup_location').invalid)
                                    "
                                />
                            </div>
                        </td>
                    </tr>

                    <tr [hidden]="viewMode !== 'both' && viewMode !== 'dropoff'">
                        <td>
                            <div class="align-self-center fs-13 fw-600">
                                Dropoff
                                <span class="d-none d-md-inline-block">Location</span>
                            </div>
                        </td>
                        <td>
                            <div class="ml-2">
                                <input
                                    id="dropoff_location"
                                    type="search"
                                    formControlName="dropoff_location"
                                    class="touch-input w-100 fs-13 p-1 px-2 autocomplete w-75"
                                    placeholder="Enter Dropoff Location"
                                    [class.error]="
                                        locationFormGroup?.get('dropoff_location')?.touched &&
                                        (!locationFormGroup?.get('dropoff_location')?.value || locationFormGroup?.get('dropoff_location').invalid)
                                    "
                                />
                            </div>
                        </td>
                    </tr>
                </table>
            </div>

            <hr class="light" />

            <div class="touch-bottom my-3" align="center">
                <button class="btn btn-secondary btn-light text-white p-2 mx-2 br-6" (click)="bsModalRef.hide()">{{ leftButtonText }}</button>
                <button class="btn btn-primary btn-right-secondary p-2 ml-2 br-6" (click)="onConfirm()">{{ rightButtonText }}</button>
            </div>
        </div>
    </div>
</div>
