import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { SocketioService } from 'src/app/services/socketio/socketio.service';
import { UtilityService } from '../../services/utility/utility.service';

@Component({
    selector: 'app-sidenav',
    templateUrl: './sidenav.component.html',
    styleUrls: ['./sidenav.component.scss'],
})
export class SidenavComponent implements OnInit {
    activeTab = '';
    totalNotifications: number = 0;
    constructor(private router: Router, public utilityService: UtilityService, private socketService: SocketioService) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            this.setActiveTab(event.url);
        });
    }

    ngOnInit(): void {
        this.setActiveTab(this.router.url);
        const driverDetail = JSON.parse(localStorage.getItem('corporateModel'));
        this.socketService.emit('corporateNotification', { corporate_id: driverDetail?.corporate_id });
        this.fetchNotificationCount();
    }

    setActiveTab(url: string) {
        if (
            url?.includes('/corporate/scheduled-trips') ||
            url?.includes('/corporate/cancelled-trips') ||
            url?.includes('/corporate/completed-trips') ||
            url?.includes('/corporate/detail-view')
        ) {
            // this.activatedRoute_trips = true;
            this.activeTab = 'trips';
        } else if (
            url?.includes('/corporate/riders/my-riders') ||
            url?.includes('/corporate/riders/all-riders') ||
            url?.includes('/corporate/detail?ref=rider')
        ) {
            // this.activatedRoute_riders = true;
            this.activeTab = 'riders';
        } else if (
            url?.includes('/corporate/drivers/all-drivers') ||
            url?.includes('/corporate/drivers/my-drivers') ||
            url.includes('/corporate/detail?ref=qudosfave')
        ) {
            // this.activatedRoute_qudosfave = true;
            this.activeTab = 'qudosfave';
        } else if (
            url?.includes('/corporate/cards') ||
            url?.includes('/corporate/documents') ||
            url?.includes('/corporate/more-settings') ||
            url?.includes('/corporate/staff')
        ) {
            // this.activatedRoute_settings = true;
            this.activeTab = 'settings';
        } else if (url?.includes('/corporate/notifications')) {
            // this.activatedRoute_notifications = true;
            this.activeTab = 'notifications';
        } else if (url?.includes('attestation')) {
            this.activeTab = 'attestation';
        } else if (url?.includes('active-trips')) {
            this.activeTab = 'active-trips';
        }
    }

    setRegularDefault() {
        this.utilityService.setRideType('0');
    }

    private fetchNotificationCount() {
        this.socketService.on('corporateNotification').subscribe((data) => {
            this.totalNotifications = data.data.count;
        });
    }
}
