<div class="timerangepicker">
    <a
        data-target="timeDropdown"
        [ngClass]="{ 'touch-border-frame mr-0 tripsbtn': viewType === 'normal' }"
        [class.minimal-filter]="viewType === 'minimal'"
        [class.white-theme]="colorTheme === 'white'"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
    >
        <span class="dropdown-toggle" [ngClass]="customClass">
            <ng-container *ngIf="labelType === 'text'">
                <span class="light-weight">Time Filter</span>
                :
            </ng-container>
            <ng-container *ngIf="labelType === 'icon'">
                <mat-icon class="fs-16 text-dark-blue" style="vertical-align: middle; padding-top: 4px; height: 25px; width: 20px">schedule</mat-icon>
                :
            </ng-container>
            <span class="label" *ngIf="time_picker?.start && time_picker?.end">
                {{ time_picker?.start % 12 || 12 }} {{ time_picker?.start < 12 ? 'AM' : 'PM' }} - {{ time_picker?.end % 12 || 12 }}
                {{ time_picker?.end < 12 ? 'AM' : 'PM' }}
            </span>
            <span class="label" *ngIf="!(time_picker?.start && time_picker?.end)">All Day</span>
        </span>
    </a>
    <div class="dropdown-menu cd-menu left-0 first card-shadow touch-input-group" style="width: 230px !important" aria-labelledby="timeDropdown">
        <div class="px-3 py-2">
            <!-- <form #time_picker="ngForm" (ngSubmit)="applyTimePicker(time_picker)"> -->
            <div class="input-group" (click)="$event.stopPropagation()">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="start_time_picker" style="padding-top: 6px">
                        <mat-icon class="fs-20">schedule</mat-icon>
                    </label>
                </div>
                <select class="input-append" [(ngModel)]="time_picker_form.start">
                    <option selected value="placeholder" disabled>Start Time</option>
                    <!-- [disabled]="time_picker_form?.end != 'placeholder' && option.value > time_picker_form?.end" -->

                    <option *ngFor="let option of hoursList" [value]="option.value">
                        {{ option.title }}
                    </option>
                </select>
            </div>

            <div align="center" class="my-1">to</div>

            <div class="input-group mb-3" (click)="$event.stopPropagation()">
                <div class="input-group-prepend">
                    <label class="input-group-text" for="end_time_picker" style="padding-top: 6px">
                        <mat-icon class="fs-20">schedule</mat-icon>
                    </label>
                </div>
                <select class="input-append" [(ngModel)]="time_picker_form.end">
                    <option selected value="placeholder" disabled>End Time</option>
                    <!-- [disabled]="time_picker_form?.start != 'placeholder' && option.value < time_picker_form?.start" -->

                    <option *ngFor="let option of hoursList" [value]="option.value">
                        {{ option.title }}
                    </option>
                </select>
            </div>

            <div class="d-flex justify-content-around text-blue-secondary fs-13 mt-2">
                <div class="cursor-pointer" (click)="resetTimePicker()">All Day</div>
                <div class="cursor-pointer" (click)="applyTimePicker()">Apply</div>
            </div>
            <!-- </form> -->
        </div>
    </div>
</div>
