<div class="d-flex">
    <span
        class="cursor-pointer"
        [popover]="live_queue_trip_setting_popover_template"
        #liveQueueTripSettingPopover="bs-popover"
        containerClass="w-260 br-6 z-index-1000 custom-position"
        container="body"
        placement="bottom"
        [adaptivePosition]="false"
        boundariesElement="viewport"
        [outsideClick]="true"
        (click)="popover_reference = liveQueueTripSettingPopover"
    >
        <ng-content></ng-content>
    </span>
</div>

<ng-template #live_queue_trip_setting_popover_template>
    <div class="touch-popover">
        <div class="text-center mt-3 fs-16 text-dark-blue fw-600">Live Trips Settings</div>
        <form [formGroup]="liveTripSettingForm">
            <div
                class="touch-currency-input py-2 px-3 m-3 bordered"
                [class.error]="liveTripSettingForm?.controls?.minutes.touched && liveTripSettingForm?.controls?.minutes.invalid"
                [class.pulse]="isLoading"
            >
                <div class="input-group w-auto mb-2 justify-content-center">
                    <input
                        appAutofocus
                        onkeypress="return /[0-9]|\./i.test(event.key)"
                        type="number"
                        style="width: 50px"
                        class="border-0 fs-16 fw-600 text-dark-blue text-right"
                        formControlName="minutes"
                    />

                    <div class="input-group-prepend border-0">
                        <span class="input-group-text fs-16 fw-600 text-dark-blue" id="basic-addon1">MINS</span>
                    </div>
                </div>
                <div class="text-center">
                    <div style="color: #707683" class="fw-600">TIME FROM PICKUP</div>
                    <div class="custom-control custom-switch ml-2">
                        <input
                            type="checkbox"
                            class="custom-control-input stop-propagation"
                            id="live_trip_minute_switch"
                            formControlName="enable_minutes"
                        />
                        <label class="custom-control-label fs-14 fw-600 dark-color stop-propagation" [attr.for]="'live_trip_minute_switch'"></label>
                    </div>
                </div>
            </div>
            <hr class="light mx-3" />
            <div
                class="touch-currency-input py-2 px-3 m-3 bordered"
                [class.error]="liveTripSettingForm?.controls?.miles.touched && liveTripSettingForm?.controls?.miles.invalid"
                [class.pulse]="isLoading"
            >
                <div class="input-group w-auto mb-2 justify-content-center">
                    <input
                        appAutofocus
                        onkeypress="return /[0-9]|\./i.test(event.key)"
                        type="number"
                        style="width: 50px"
                        class="border-0 fs-16 fw-600 text-dark-blue text-right"
                        formControlName="miles"
                    />

                    <div class="input-group-prepend border-0">
                        <span class="input-group-text fs-16 fw-600 text-dark-blue" id="basic-addon1">MILES</span>
                    </div>
                </div>

                <div class="text-center">
                    <div style="color: #707683" class="fw-600">PICKUP RADIUS</div>
                    <div class="custom-control custom-switch ml-2">
                        <input
                            type="checkbox"
                            class="custom-control-input stop-propagation"
                            id="live_trip_miles_switch"
                            formControlName="enable_miles"
                        />
                        <label class="custom-control-label fs-14 fw-600 dark-color stop-propagation" [attr.for]="'live_trip_miles_switch'"></label>
                    </div>
                </div>
            </div>
            <hr class="light mx-3" />
        </form>
        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2 mb-3">
            <div class="cursor-pointer" (click)="popover_reference.hide()">Dismiss</div>
            <div class="cursor-pointer" [class.disabled]="liveTripSettingForm.invalid" (click)="updateLiveTripSetting()">Save</div>
        </div>

        <!-- Extra Content If Passed -->
        <ng-content select="[amount_popover_extra_body]"></ng-content>
    </div>
</ng-template>
