<div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="width: 700px">
    <div class="modal-content card-shadow booking-model-component">
        <!-- <div class="row modaltitle" style="float: right;padding: 0;padding-right: 6px;">
            <div class="col-sm-12">
                Enter Promo
            </div>
        </div> -->
        <div class="mx-5 mt-5 mb-2 rb-large-text d-flex justify-content-between">
            <div>Enter Promo Code</div>
            <div *ngIf="promo_code_applied">
                <button (click)="booking.promo_code = appliedPromoCode" type="button" class="close" data-dismiss="modal">&times;</button>
            </div>
        </div>
        <hr class="confirm" />
        <div class="modal-body my-2 mx-5">
            <div class="row modalrow">
                <!-- <div class="col-sm-8 col-sm-offset-3" style="margin:0 auto;"> -->
                <div class="input-container">
                    <input type="search" placeholder="Enter Promo Code" [(ngModel)]="promo_code" class="form-control" />
                </div>
                <!-- </div> -->
            </div>
        </div>
        <hr class="confirm" />
        <div class="row rb-btn mt-1 mb-4">
            <div class="col-3"></div>
            <div class="col-3 text-center">
                <button
                    *ngIf="!promo_code_applied"
                    data-dismiss="modal"
                    class="btn btn btn-secondary border-0 p-2 rb-btn-grey ride-confirm-btn"
                    (click)="bsModalRef.hide()"
                >
                    Cancel
                </button>
                <button
                    *ngIf="promo_code_applied"
                    data-dismiss="modal"
                    (click)="closeandApply('')"
                    class="btn btn-secondary border-0 p-2 rb-btn-grey ride-confirm-btn"
                >
                    Clear
                </button>
            </div>
            <div class="col-3 text-center">
                <button (click)="closeandApply(promo_code)" class="btn btn-secondary border-0 p-2 rb-btn-dark-blue ride-confirm-btn">Apply</button>
            </div>
        </div>
    </div>
</div>
