import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'ShortText',
})
export class ShortTextPipe implements PipeTransform {
    transform(text: string, limit: number = 20): any {
        if (!text) return '-';

        text = text.trim();

        if (text?.length <= limit) {
            return text;
        }

        const textList = text.split(' ');
        if (textList.length > 1) {
            const newTextList = textList.map((item, index) => {
                // last name
                if (index == textList?.length - 1) {
                    if (item.length > limit) return item.slice(0, limit) + '...';
                    else return item;
                } else return this.shorter(item, limit / 2);
            });

            let newText = newTextList.join(' ');
            if (newText?.length > limit * 2) {
                newText = text.slice(0, limit);
            }
            return newText + '...';
        } else {
            return text.slice(0, limit) + '...';
        }
    }

    shorter(text: string, limit: number) {
        if (text?.length > limit) {
            return text[0] + '.';
        } else return text;
    }
}
