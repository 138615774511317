import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidenavComponent } from './components/sidenav/sidenav.component';
import { CorporateRoutingModule } from '../corporate/corporate-routing.module';
import { ConfirmComponent } from './components/confirm/confirm.component';
import { StringFilterPipe } from '../pipe/string-filter.pipe';
import { DateFilterPipe } from '../pipe/date-filter.pipe';
import { CustomRideFilter } from '../pipe/customride-filter.pipe';
import { TimeZonePipe } from '../pipe/timezone.pipe';
import { ShortTextPipe } from '../pipe/short-text.pipe';
import { SettingHeaderComponent } from './components/setting-header/setting-header.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatNativeDateModule } from '@angular/material/core';
import { CarBaseAffiliationComponent } from './components/car-base-affiliation/car-base-affiliation.component';
import { NgxPaginationModule } from 'ngx-pagination';
import { MasTripInfoComponent, MasTable } from './components/mas-trip-info/mas-trip-info.component';
import { MatSortModule } from '@angular/material/sort';
import { MaskTextPipe } from '../pipe/mask-text.pipe';
import { LoadingComponent } from './components/loading/loading.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { MatIconModule } from '@angular/material/icon';
import { TimeRangePickerComponent } from './components/time-range-picker/time-range-picker.component';
import { FareEstimateComponent } from '../corporate/components/fare-estimate/fare-estimate.component';
import { PopoverModule } from 'ngx-bootstrap/popover';
import { AutofocusDirective } from '../directives/autofocus/autofocus.directive';
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { NotePopoverComponent } from '../corporate/components/note-popover/note-popover.component';
import { UnassignedTripsComponent } from '../corporate/pages/active-trips/unassigned-trips/unassigned-trips.component';
import { DispatchedTripsComponent } from '../corporate/pages/active-trips/dispatched-trips/dispatched-trips.component';
import { AssignedTripsComponent } from '../corporate/pages/active-trips/assigned-trips/assigned-trips.component';
import { CompletedTripsComponent } from '../corporate/pages/active-trips/completed-trips/completed-trips.component';
import { QueuedTripsComponent } from '../corporate/pages/active-trips/queued-trips/queued-trips.component';
import { StopPropagationDirective } from '../directives/stop-propagation/stop-propagation.directive';
import { AmountPopoverComponent } from '../corporate/components/amount-popover/amount-popover.component';
import { RideEarningOverviewComponent } from '../corporate/components/ride-earning-overview/ride-earning-overview.component';
import { PreferredDriverListComponent } from '../corporate/components/preferred-driver-list/preferred-driver-list.component';
import { CreatePayoutConfirmModalComponent } from '../corporate/components/create-payout-confirm-modal/create-payout-confirm-modal.component';
import { RiderDriverHeaderComponent } from '../corporate/components/rider-driver-details/rider-driver-header/rider-driver-header.component';
import { RiderDriverInfoComponent } from '../corporate/components/rider-driver-details/rider-driver-info/rider-driver-info.component';
import { RiderDetailsNotesListComponent } from '../corporate/components/rider-detail-notes/rider-details-notes-list/rider-details-notes-list.component';
import { NgxStripeModule } from 'ngx-stripe';
import { PhoneFormatPipe } from '../pipe/phone-formate.pipe';
import { SearchBoxComponent } from '../corporate/components/search-box/search-box.component';
import { GoogleMapComponent } from '../corporate/components/google-map/google-map.component';
import { MessagePopoverComponent } from '../corporate/components/message-popover/message-popover.component';
import { LiveQueueTripPopoverComponent } from '../corporate/components/live-queue-trip-popover/live-queue-trip-popover.component';
import { LiveQueueTripSettingPopoverComponent } from '../corporate/components/live-queue-trip-setting-popover/live-queue-trip-setting-popover.component';
import { ShowDriverPopoverComponent } from '../corporate/components/show-driver-popover/show-driver-popover.component';
import { PrepaidTripPopoverComponent } from '../corporate/components/prepaid-trip-popover/prepaid-trip-popover.component';
import { TripRosterHeaderComponent } from '../corporate/components/trip-roster-header/trip-roster-header.component';
import { SelectCarTypePopoverComponent } from '../corporate/components/select-car-type-popover/select-car-type-popover.component';
import { CapitalizeWithSpacesPipe } from '../pipe/capitalize-with-spaces.pipe';
import { AllTripsComponent } from '../corporate/pages/active-trips/all-trips/all-trips.component';

const components = [
    HeaderComponent,
    FooterComponent,
    SidenavComponent,
    SettingHeaderComponent,
    TripRosterHeaderComponent,
    CarBaseAffiliationComponent,
    MasTripInfoComponent,
    ConfirmComponent,
    StringFilterPipe,
    DateFilterPipe,
    MaskTextPipe,
    TimeZonePipe,
    CustomRideFilter,
    CapitalizeWithSpacesPipe,
    ShortTextPipe,
    PhoneFormatPipe,
    AutofocusDirective,
    StopPropagationDirective,
    MasTable,
    LoadingComponent,
    TimeRangePickerComponent,
    FareEstimateComponent,
    NotePopoverComponent,
    LiveQueueTripPopoverComponent,
    PrepaidTripPopoverComponent,
    LiveQueueTripSettingPopoverComponent,
    AmountPopoverComponent,
    ShowDriverPopoverComponent,
    RideEarningOverviewComponent,
    CreatePayoutConfirmModalComponent,

    // Reason for not creating re-usable table: There are or might come in future lots of customisation in different diffferent coreners, so avoid the reusability for large element sets
    UnassignedTripsComponent,
    DispatchedTripsComponent,
    AssignedTripsComponent,
    CompletedTripsComponent,
    QueuedTripsComponent,
    AllTripsComponent,
    PreferredDriverListComponent,

    RiderDriverHeaderComponent,
    RiderDriverInfoComponent,
    RiderDetailsNotesListComponent,
    SearchBoxComponent,
    GoogleMapComponent,
    MessagePopoverComponent,
    SelectCarTypePopoverComponent,
];

const modules = [
    CommonModule,
    CorporateRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    MatDatepickerModule,
    MatNativeDateModule,
    NgxPaginationModule,
    MatSortModule,
    NgxSkeletonLoaderModule,
    MatIconModule,
    PopoverModule,
    BsDropdownModule,
    MatCheckboxModule,
    NgxStripeModule,
    // ModalModule,
    // RouterModule
];

@NgModule({
    declarations: [...components],
    imports: [...modules],
    exports: [...components, ...modules],
    providers: [MatDatepickerModule, MatNativeDateModule],
})
export class CoreModule {}
