import { ElementRef, Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import * as moment from 'moment-timezone';
import { ActivatedRoute, Router, QueryParamsHandling, Params } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { SettingHeaderConfig } from './setting-header.config';
import { Location } from '@angular/common';
declare const jQuery: any;
const $: any = jQuery;
import * as _ from 'lodash';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ConfirmComponent } from '../../components/confirm/confirm.component';
import { IClientInfo } from 'src/app/models/carbase.model';
import { ICorporateModel } from 'src/app/models/corporate.model';

declare global {
    interface Window {
        showSaveFilePicker?: (params) => Promise<any>;
    }
}
@Injectable({
    providedIn: 'root',
})
export class UtilityService {
    public _showNewRideButton = false;
    private _newRideButtonShowSubject = new Subject<boolean>();
    newRideButtonChanges = this._newRideButtonShowSubject.asObservable();
    trip_ride_type = '0';
    public authorized = new Subject<boolean>();
    public toggleMASUI = new Subject<boolean>();
    public searchTripByRider = new Subject<any>();
    public resetTripSearch = new Subject<void>();
    public highlightActiveTrip = new Subject<void>();
    public settingHeaderConfig = SettingHeaderConfig;
    public MAS_Qudosfave_Map = {
        'All Drivers': 0,
        'MAS Drivers': 1,
        'Non-MAS Drivers': 2,
    };

    display_filter_list = [10, 20, 50, 100, 200].reverse();
    display_filter_label = 'Show';
    default_display_filter = this.display_filter_list[0];
    default_date_filter = new Date();

    public MAS_Trip_Class: any = {
        Unassigned: 'mas_unassigned',
        Assigning: 'mas_assigning',
        Accepted: 'mas_accepted',
    };
    public loading: boolean = true;

    // public carsOptions: Array<any> = CarsOptions;

    // public carDriverOptions: Array<any> = CarsDriverOptions;

    show_currency_precision: string = '1.0-2';

    toastConfig = {
        closeButton: true,
    };

    public ngxModalOption: any = {
        class: 'bg-none modal-dialog-centered',
    };

    // View Map Modal
    public mapViewModal: any = {
        class: 'modal-dialog-centered modal-xl',
    };

    private applyButtonClickSubject = new Subject<void>();
    applyButtonClick$ = this.applyButtonClickSubject.asObservable();

    /** Add Bank  */
    private addBank = new Subject<void>();
    addBank$ = this.addBank.asObservable();

    /** Queued */
    private queuedTripAdding = new Subject<void>();
    queuedTripAdding$ = this.queuedTripAdding.asObservable();

    constructor(
        private toastr: ToastrService,
        private router: Router,
        private route: ActivatedRoute,
        private cookieService: CookieService,
        private httpService: HttpService,
        private location: Location,
        private modalService: BsModalService
    ) {}

    public toast(type: 'success' | 'error' | 'warning' | 'info', msg: string, title: string = '') {
        return this.toastr[type](title, msg, this.toastConfig);
    }

    public clearToast(toastId?: number) {
        if (toastId) {
            this.toastr.clear(toastId);
        } else {
            this.toastr.clear();
        }
    }

    public removeToast(toastId: number) {
        this.toastr.remove(toastId);
    }

    public alert(
        type: 'success' | 'error' | 'warning' | 'info',
        title: string,
        text?: string,
        options: {
            width?: number;
            height?: number;
            timer?: number;
            showConfirmButton?: boolean;
        } = { width: 420 }
    ) {
        this.customAlert(type, title);
    }

    public logout() {
        localStorage.removeItem('driverdata');
        localStorage.removeItem('corporateModel');
        localStorage.removeItem('session_identifier');
        localStorage.removeItem('selectedCorp');
        $('.modal').hide();
        const prms = { web_access_token: this.cookieService.get('access_token') };
        // environment.urlC + 'logout'
        this.httpService.post(environment.authURL + 'logout', prms).subscribe((data) => {
            this.cookieService.deleteAll();
            this.cookieService.delete('access_token');
            this.cookieService.delete('web_access_token');
            this.cookieService.delete('super_corp_web_access_token');
            this.router.navigate(['/', 'corporate_login']);
        });
        /**/
    }

    public openmenu() {
        $('#mynav').css('width', '100%');
        $('#backarrow').toggle();
        $('.navbar-toggler').toggle();
    }

    public closemenu(): void {
        $('#mynav').css('width', '0px');
        $('#backarrow').toggle();
        $('.navbar-toggler').toggle();
    }

    showNewRideButton(show: boolean): boolean {
        this._showNewRideButton = !!show;
        this._newRideButtonShowSubject.next(this._showNewRideButton);
        return this._showNewRideButton;
    }

    setRideType(rideType: string) {
        this.trip_ride_type = rideType;
    }

    public getTimezone(date: any, timeZone?: any): any {
        if (!timeZone) timeZone = moment.tz.guess(true);
        return moment.tz(timeZone).format('z');
    }

    public customAlert(type: string, text: string, heading: string = 'Alert!') {
        const initialState: any = {
            initialState: {
                heading,
                headingType: type,
                description: text,
                rightButtonText: 'Ok',
            },
            ...this.ngxModalOption,
            ignoreBackdropClick: true,
        };
        const modalref: BsModalRef = this.modalService.show(ConfirmComponent, initialState);
        return modalref.content.event;
    }

    public confirm(config: {
        heading: string;
        description: string;
        leftButtonText?: string;
        rightButtonText?: string;
        styleType?: 'default' | 'primary';
        headingType?: string;
    }) {
        const initialState: any = {
            initialState: {
                heading: config.heading,
                description: config.description,
                rightButtonText: config?.rightButtonText,
                leftButtonText: config?.leftButtonText,
                styleType: config?.styleType || 'default',
                headingType: config?.headingType,
            },
            ...this.ngxModalOption,
            ignoreBackdropClick: true,
        };
        const modalref: BsModalRef = this.modalService.show(ConfirmComponent, initialState);
        return modalref.content.event;
    }

    public showModal(id: string) {
        $(this.prefixHash(id)).modal('show');
    }

    public hideModal(id: string) {
        $(this.prefixHash(id)).modal('hide');
    }

    prefixHash(id: string) {
        if (!id.includes('#')) id = '#' + id;
        return id;
    }

    public hideModalList(ids: Array<string>) {
        ids.forEach((x) => {
            this.hideModal(x);
        });
    }

    public getRideStatus(ride_status: any, request_status: any) {
        switch (ride_status) {
            case 0:
                if (request_status == 0) {
                    return 'Assigning';
                } else if (request_status == 1) {
                    return 'Accepted';
                } else if (request_status == 10) {
                    return 'Missed by driver';
                }
                break;
            case 1:
                return 'Picking Up';
            case 2:
                return 'Arrived';
            case 3:
                return 'En Route';
            case 4:
                return 'Completed';
            case 5:
                return 'Cancelled by driver';
            case 6:
                return 'Cancelled by rider';
            case 7:
                return 'Cancelled by rider';
            case 8:
                return 'Unsuccessful payment';
            case 9:
                return 'Cancelled by admin';
            case 10:
                return 'Missed by driver';
            case 11:
                return 'Cancelled by corporate';
        }
    }

    sendInvoice(session_id: any, send_to: 0 | 1 = 0) {
        if (!session_id) return;
        const payload = {
            web_access_token: this.cookieService.get('web_access_token'),
            session_id: session_id,
            sent_to: send_to,
        };

        this.httpService.post(environment.urlC + 'send_invoice', payload).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.error) {
                this.toast('error', data.error, '');
            } else {
                this.toast('success', `Invoice sent successfully to ${send_to === 0 ? 'Corporate' : 'Rider'}'s email`, '');
            }
        });
    }

    public updateQueryParams(queryParams: Params, queryParamsHandling: QueryParamsHandling = 'merge'): void {
        const urlTree: any = this.router.createUrlTree([], {
            relativeTo: this.route,
            queryParams,
            queryParamsHandling,
        });

        this.location.go(urlTree.toString());
    }

    public print(id: string) {
        const printContent = document.getElementById(id);
        const WindowPrt = window.open('', '', 'left=0,top=0,width=900,height=900,toolbar=0,scrollbars=0,status=0');
        WindowPrt.document.write(printContent.innerHTML);
        WindowPrt.document.close();
        WindowPrt.focus();
        WindowPrt.print();
    }

    public get mas_session_identifier() {
        return localStorage.getItem('session_identifier');
    }

    public start_mas_session(payload: { silent: boolean } = { silent: false }): Promise<any> {
        return new Promise((resolve, reject) => {
            // this.loading = true;
            this.httpService
                .postJSON(environment.urlWC + 'mas_start_session', {
                    web_access_token: this.cookieService.get('access_token'),
                })
                .subscribe(
                    (res: any) => {
                        this.loading = false;
                        if (res?.session_identifier) {
                            if (!payload.silent) this.toast('success', 'MAS session started successfully');
                            localStorage.setItem('session_identifier', res?.session_identifier);
                            resolve(res?.session_identifier);
                        } else {
                            localStorage.removeItem('session_identifier');
                            this.toast('warning', res?.error);
                            console.error(res);
                            reject(res);
                        }
                    },
                    (err) => {
                        this.loading = false;
                        localStorage.removeItem('session_identifier');
                        this.toast('error', 'Some error occured');
                        console.log('err => ', err);
                        reject(err);
                    }
                );
        });
    }

    public pre_process_mas_trip(leg_trip: any, ref_trip: any) {
        leg_trip.pickupTime = moment(ref_trip?.pickup_datetime).format('HHmm');
        leg_trip.pickup_time = moment(ref_trip?.pickup_datetime).format('hh:mm a');
        leg_trip.pickup_location_address = `${leg_trip?.pickupAddress?.address} ${leg_trip?.pickupAddress?.address2}, ${leg_trip?.pickupAddress?.city}, ${leg_trip?.pickupAddress?.state} ${leg_trip?.pickupAddress?.zip}`;
        leg_trip.dropoff_location_address = `${leg_trip?.dropoffAddress?.address} ${leg_trip?.dropoffAddress?.address2}, ${leg_trip?.dropoffAddress?.city}, ${leg_trip?.dropoffAddress?.state} ${leg_trip?.dropoffAddress?.zip}`;
        leg_trip.pickup_datetime = new Date(moment(leg_trip?.pickupDate).format('YYYY-MM-DD') + ', ' + leg_trip?.pickup_time);
        leg_trip.user_mobile = this.purify_mobile_no(leg_trip?.recipientPhone);
        leg_trip.rider_name = leg_trip?.firstName + ' ' + leg_trip?.lastName;
        leg_trip.ref_trip = ref_trip; // Attach a full payload incase of requirement

        if (ref_trip?.mas_trip_id) {
            leg_trip.mas_trip_id = ref_trip?.mas_trip_id;
        }

        return leg_trip;
    }

    // It'll remove unrequired caluses and symboles from the mobile number,
    // Checks if a mobile number is in the format '(347)6658654' and converts it to '3476658654'.
    purify_mobile_no(number: string, prefix = '+1-') {
        if (!number) return '';
        return prefix + number.replace(new RegExp(/[(]|[)]| |-/, 'gm'), '');
    }

    /**
        Checks if a mobile number is in the format '+13476658654' and converts it to '+1-3476658654'.
        If the number is already in the desired format, it's returned as is.
     */
    reformat_mobile_no(mobileNumber) {
        const formattedNumber = mobileNumber.replace(/^\+1(\d{10})$/, '+1-$1');
        return formattedNumber || mobileNumber;
    }

    public get_secondary_service(data: {
        trip: any;
        key: 'service_name' | 'service_procedure_code' | 'service_procedure_code_modifier' | 'service_rate' | 'service_leg_id' | 'service_quantity';
        value: any;
    }) {
        return data.trip?.secondary_services?.find((service: any) => service[data.key] === data.value) || null;
    }

    // used for sidenav
    public open_trip_by_rider() {
        this.router.navigate(['/', 'corporate', 'live-tracking']);
        setTimeout(() => this.showModal('search_trip_by_rider'), 0);
    }

    setInitialFocus(ref: ElementRef) {
        console.log(ref);
        setTimeout(() => {
            // this will make the execution after the above boolean has changed
            ref.nativeElement.focus();
        }, 0);
    }

    async generate_csv(title: string, csvContent: any) {
        if (!title?.includes('.csv')) title += '.csv';
        // const encodedUri = encodeURI(csvContent);

        const supportsFileSystemAccess =
            'showSaveFilePicker' in window &&
            (() => {
                try {
                    return window.self === window.top;
                } catch {
                    return false;
                }
            })();

        if (supportsFileSystemAccess) {
            const handle: any = await window.showSaveFilePicker({ suggestedName: title });
            const writable = await handle.createWritable();
            await writable.write(csvContent);
            await writable.close();
        } else {
            const encodedUri = encodeURI(csvContent);
            const link = document.createElement('a');
            link.setAttribute('href', encodedUri);
            link.setAttribute('download', title || `${Date.now()}`);
            document.body.appendChild(link); // Required for FF
            link.click();
        }
    }

    get clientInfo(): IClientInfo {
        return JSON.parse(localStorage.getItem('clientInfo')) || ({} as IClientInfo);
    }

    get corporateModel(): ICorporateModel {
        return JSON.parse(localStorage.getItem('corporateModel')) || ({} as ICorporateModel);
    }

    getLocalTime(date: Date | any): Date | null {
        return date ? new Date(moment.utc(date).local().toString()) : null;
    }

    getAddressBreakdown(
        address: string
    ): {
        street: string;
        city: string;
    } {
        const components = address?.split(',')?.map((item) => item.trim());
        const street = components?.[0] ?? '';
        const city = components?.[1] ?? '';
        return { street, city };
    }

    sendApplyButtonClick() {
        this.applyButtonClickSubject.next();
    }

    sendSubmitBank() {
        this.addBank.next();
    }

    /** Detect event when quead trip is added  */
    sendToQueuedTrip() {
        this.queuedTripAdding.next();
    }
}
