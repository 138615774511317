import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'timezone',
})
export class TimeZonePipe implements PipeTransform {
    transform(offset: any): any {
        if (!offset) return null;
        switch (offset.toString()) {
            case '330':
                return 'IST';
            case 'Eastern Standard Time':
                return 'EST';
            case '-240':
                return 'EDT';
            case '-360':
                return 'CST';
            case '-300':
                // return 'CDT';
                return 'EST';
            case '420':
                return 'PDT';
            case '-480':
                return 'PST';
            default:
                return 'N/A';
        }
    }
}
