import { HttpResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

enum DriverAPIs {
    ADD_PREFERRED_DRIVER = 'add_user_prefer_driver',
    GET_PREFERRED_DRIVER = 'get_user_prefer_driver',
    EDIT_PREFERRED_DRIVER = 'edit_user_prefer_driver',
    DELETE_PREFERRED_DRIVER = 'delete_user_prefer_driver',
    FETCH_DRIVER_PERCENTAGE = 'featch_driver_percentage',
    SET_DRIVER_PERCENTAGE = 'set_driver_percentage',
    GET_DRIVER_DETAIL = 'driver_details',
    GET_EARNING_TRIP_HISTORY = 'get_trip_history',
    GET_DRIVER_EARNINGS = 'get_driver_earnings',
    GET_DRIVER_ANALYTICS = 'get_driver_analytics',
    DAILY_PAYOUT = 'daily_payout', // used at driver deposited amount
    GET_TRIP_COORDINATES = 'get_trip_coordinates',
}

@Injectable({
    providedIn: 'root',
})
export class DriverService {
    constructor(private httpService: HttpService, private cookieService: CookieService, private router: Router) {}

    trigger_driver_payout: Subject<void> = new Subject<void>();

    add_preferred_driver(user_id: number | string, driver_id: number | string) {
        return this.httpService.post(environment.urlWC + DriverAPIs.ADD_PREFERRED_DRIVER, {
            user_id,
            driver_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_preferred_driver(user_id: number | string) {
        return this.httpService.post(environment.urlWC + DriverAPIs.GET_PREFERRED_DRIVER, {
            user_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    edit_preferred_driver(payload: { id: number | string; user_id: number | string; driver_id: number | string }) {
        return this.httpService.post(environment.urlWC + DriverAPIs.EDIT_PREFERRED_DRIVER, {
            ...payload,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    delete_preferred_driver(id: number | string) {
        return this.httpService.post(environment.urlWC + DriverAPIs.DELETE_PREFERRED_DRIVER, {
            id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    featch_driver_percentage() {
        return this.httpService.post(environment.urlC + DriverAPIs.FETCH_DRIVER_PERCENTAGE, {
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    set_driver_percentage(payload: {
        third_party_percentage?: number;
        show_driver_payout?: number;
        go_live_minutes?: number;
        go_live_miles?: number;
        is_go_live_minutes?: number;
        is_go_live_miles?: number;
    }) {
        return this.httpService.post(environment.urlC + DriverAPIs.SET_DRIVER_PERCENTAGE, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...payload,
        });
    }

    get_driver_detail(driver_id: number) {
        return this.httpService.post(environment.urlC + DriverAPIs.GET_DRIVER_DETAIL, {
            web_access_token: this.cookieService.get('web_access_token'),
            driver_id,
        });
    }

    // Earnings

    get_earnings_history(payload: {
        driver_id: number;
        country_id?: number;
        state_id?: number;
        date_filter_flag?: number;
        start_date?: number;
        end_date?: number;
        is_export_csv?: number;
        limit?: number;
        offset?: number;
        searchFlag?: number;
        searchString?: string;
        sort_by?: string;
        sort_order?: 'ASC' | 'DESC';
    }) {
        return this.httpService.post(environment.urlWC + DriverAPIs.GET_EARNING_TRIP_HISTORY, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...payload,
        });
    }

    get_deposited_amount(payload: { driver_id: number; date_filter: number; start_date: string; end_date: string }) {
        return this.httpService.post(environment.urlWC + DriverAPIs.DAILY_PAYOUT, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...payload,
        });
    }

    // request_type 0 -> gross_volume and 1 -> complete_rides
    get_driver_earnings(driver_id: number, request_type = 0) {
        return this.httpService.post(environment.urlWC + DriverAPIs.GET_DRIVER_EARNINGS, {
            driver_id,
            request_type,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    // request_type ( 0 -> daily, 1 -> weekly, 2 -> monthly, 3 -> yearly // not required, default 0 in BE),
    // limit: 10, offset: 0
    get_driver_analytics(driver_id: number, request_type = 0, limit = 5, offset = 0) {
        return this.httpService.post(environment.urlWC + DriverAPIs.GET_DRIVER_ANALYTICS, {
            driver_id,
            request_type,
            limit,
            skip: offset,
            offset: moment().utcOffset(),
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    get_dirver_cordinates_history(payload: { mas_trip_id: any } | { driver_id: number; start_date: Date | string; end_date: Date | string }) {
        return this.httpService.post(environment.urlWC + DriverAPIs.GET_TRIP_COORDINATES, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...payload,
        });
    }

    navigateToDriverDetail(driver_id: number) {
        window.open(`/corporate/drivers/driver-detail/${driver_id}`, '_blank');
    }
}
