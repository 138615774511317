<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <!-- <SettingHeader
            headerTitle="Trip History"
            [tabList]="ridesType === '0' ? utilityService.settingHeaderConfig.trips_ongoing : utilityService.settingHeaderConfig.trips_upcoming"
            [activeTab]="activeTab"
            buttonText="Export CSV"
            (buttonClick)="getCSV()"
            [filterDateEnabled]="true"
            [filterDateForm]="filterDateForm"
            [searchControl]="searchForm"
            [tripHeaderEnabled]="true"
            [activeTripHeader]="ridesType === '0' ? 'on-demand' : 'scheduled'"
            (onDemandClick)="setRideType('0')"
            (onScheduledClick)="setRideType('1')"
        ></SettingHeader> -->
        <SettingHeader [config]="pageHeaderConfig" (onEvent)="onPageHeaderEvent($event)"></SettingHeader>
    </div>

    <div class="table-container touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0">
        <table class="table table-responsive-md trips_detail dtrips trips" #trips>
            <thead>
                <tr class="frow">
                    <th scope="col">Session ID</th>
                    <th scope="col">Riders</th>
                    <th scope="col" class="large">Date & time</th>
                    <th scope="col" class="mobilecar">Pickup Location</th>
                    <th scope="col" class="tcenter">Car Type</th>
                    <th scope="col" class="tcenter">Status</th>
                    <th scope="col" class="tcenter">Share</th>
                    <th scope="col">Payment Method</th>
                </tr>
            </thead>
            <tbody *ngIf="myTrips?.length > 0">
                <tr
                    *ngFor="
                        let trip of myTrips
                            | paginate
                                : {
                                      id: 'tripPagination',
                                      itemsPerPage: itemsPerPage,
                                      currentPage: pageNo,
                                      totalItems: totalItems
                                  };
                        let index = index
                    "
                    class="rideBody Disb cursor-pointer"
                    [hidden]="!(utilityService.trip_ride_type == trip.is_schedule)"
                    (click)="viewDetails(trip?.session_id)"
                >
                    <td>
                        {{ trip.session_id }}
                    </td>
                    <td>
                        {{ trip.user_name | ShortText | titlecase }}
                    </td>
                    <td [class.tcenter]="!trip?.sent_at">
                        <ng-container *ngIf="trip.sent_at">
                            {{ trip?.sent_at | date: 'MMMM d, y h:mm a' }} - ({{ utilityService.getTimezone(trip?.sent_at) }})
                        </ng-container>
                        <ng-container *ngIf="!trip?.sent_at">-</ng-container>
                    </td>
                    <td [class.tcenter]="!trip.pickup_location_address">
                        {{ trip.pickup_location_address || '-' }}
                    </td>
                    <td class="tcenter">
                        {{ trip.car_name || '-' }}
                    </td>
                    <td class="tcenter">
                        {{ trip.ride_status || '-' }}
                    </td>
                    <td class="tcenter">
                        {{ trip.qudos_corporate_share || '-' }}
                    </td>
                    <td>
                        <ng-container *ngIf="trip.last4_card_digit">**{{ trip.last4_card_digit }}</ng-container>
                        <ng-container *ngIf="!trip.last4_card_digit">-</ng-container>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="myTrips.length == 0 && !isLoading">
                <tr class="noride no-hover">
                    <td colspan="8" class="text-center" style="padding: 20px 87px 20px 87px !important">No Rides Yet</td>
                </tr>
            </tbody>
            <tbody *ngIf="myTrips.length === 0 && isLoading">
                <tr class="noride no-hover">
                    <td colspan="8" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="custom_pagination_v2">
    <div class="d-flex flex-row-reverse align-items-center">
        <ng-container *ngIf="!isLoading">
            <div *ngIf="showPagination">
                <pagination-controls id="tripPagination" responsive="true" (pageChange)="getRideHistory($event)"></pagination-controls>
            </div>

            <div class="p-2 bd-highlight highlight ml-3 dropdown cursor-pointer">
                <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                    <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                    <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
                </div>
                <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                    <div
                        *ngFor="let item of utilityService.display_filter_list"
                        class="dropdown-item text-center cd-item text-spacegrey"
                        (click)="getMyTrips(1, item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="text-spacegrey fs-13 fw-600">
                <!-- {{ pageNo * itemsPerPage >= totalItems ? totalItems : pageNo * itemsPerPage }} of {{ totalItems }} -->
                Results:
                <span class="text-blue-secondary">{{ totalItems }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <app-loading type="placeholder" count="1" width="430px" height="24px"></app-loading>
        </ng-container>
    </div>
</div>
