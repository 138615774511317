<div class="main_outer pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <SettingHeader [config]="pageHeaderConfig" (onEvent)="onPageHeaderEvent($event)"></SettingHeader>
    </div>

    <!-- Tables
  ================================================== -->
    <div class="table-container short touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0">
        <div class="page-header"></div>
        <table class="table table-responsive trips desktop">
            <thead>
                <tr class="frow">
                    <th scope="col">ID</th>
                    <th scope="col">Document</th>
                    <th scope="col">Document Type</th>
                    <th scope="col">Added on</th>
                    <th scope="col">Expiry Date</th>
                    <th scope="col">Status</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="!docs || docs == undefined">
                <tr class="no-hover">
                    <td colspan="7" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="2"></app-loading>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="docs?.length > 0">
                <tr *ngFor="let doc of docs; let i = index" (click)="viewDocDialog(doc, false)">
                    <td>{{ doc.doc_id }}</td>
                    <td>
                        <img [src]="doc?.document_url" onError="src = assets/images/doc.png" class="docimg" width="60" />
                    </td>
                    <td>{{ doc.document_name }}</td>
                    <td>
                        {{ doc.date_added | date: 'mediumDate' }}
                    </td>
                    <td>
                        {{ doc.expiry_date | date: 'mediumDate' }}
                    </td>
                    <td *ngIf="doc.is_verified == 1">
                        <i class="fa fa-check-circle mr-1" style="color: #32cd32; font-size: 14px; vertical-align: middle"></i>
                        Verified
                    </td>
                    <td *ngIf="doc.is_verified == 0">
                        <i class="fa fa-exclamation-circle mr-1" style="color: #ea5d08; font-size: 14px; vertical-align: middle"></i>
                        Pending
                    </td>
                    <td class="action-center">
                        <div class="d-flex flex-row justify-content-center bd-highlight mb-3">
                            <div class="p-2 bd-highlight align-self-center">
                                <mat-icon class="highlight" (click)="$event.stopPropagation(); viewDocEditDialog(doc, true)">edit</mat-icon>
                            </div>
                            <div class="p-2 bd-highlight highlight align-self-center">
                                <mat-icon (click)="$event.stopPropagation(); removeDocument(doc.doc_id)">delete_outline</mat-icon>
                            </div>
                            <div class="p-2 bd-highlight highlight align-self-center">
                                <mat-icon data-toggle="dropdown" id="dropdown">more_vert</mat-icon>
                                <div class="dropdown-menu cd-menu card-shadow" aria-labelledby="download">
                                    <div class="dropdown-item cd-item">Action</div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="docs?.length == 0">
                <tr>
                    <td colspan="6" class="text-center cursor-default">No Documents Found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div
    class="modal fade touch-popup"
    id="document_dialog"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-centered" role="document" style="min-width: 590px !important; max-width: fit-content">
        <div class="modal-content">
            <div class="touch-header pt-2">
                <div class="row mlr-2 mt-4">
                    <div class="col-auto mr-3 p-0 pb-1 my-0 active">Add Document</div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-content mlr-2 my-4">
                <div align="center" *ngIf="doc.document_url">
                    <img title="Document Image" class="img-fluid" [src]="doc.document_url" [alt]="doc.docTypeName" (click)="onFilePicker()" />
                </div>
                <div align="center" *ngIf="!doc.document_url" class="doc-placeholder" (click)="onFilePicker()">+ Upload New Document</div>

                <div class="pt-2 pb-4">
                    <span (click)="onFilePicker()" class="cursor-pointer text-primary" id="afterborder" style="cursor: pointer; font-size: 11px">
                        Upload new document
                        <input
                            type="file"
                            accept="image/x-png,image/jpeg"
                            class="form-control form-control-lg upload tlc_img"
                            style="filter: alpha(opacity=0); opacity: 0; margin-top: -71px; margin-left: -735px; width: 100%; display: block"
                            name="file_source"
                            image="image"
                            (change)="file_to_upload($event.target.files)"
                            data-button-text="Single"
                            data-class-button="btn btn-default"
                            data-classinput="form-control inline"
                            nv-file-select=""
                            uploader="uploader"
                            id="mydocupload"
                            #mydocupload
                        />
                    </span>
                </div>

                <div class="mt-4 mb-2">
                    <div class="mb-2 title">General Information</div>
                    <div>
                        <table>
                            <tr>
                                <td>Document Type :</td>
                                <td>
                                    <select class="form-control p-2" [(ngModel)]="this.doc.docType">
                                        <option value="" selected disabled>Select Document Type*</option>
                                        <option *ngFor="let documentType of documentTypes" value="{{ documentType.document_type_id }}">
                                            {{ documentType.document_name }}
                                        </option>
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td>Expiry Date :</td>
                                <td class="py-2">
                                    <mat-form-field appearance="outline">
                                        <input
                                            [(ngModel)]="doc.expiry_date"
                                            matInput
                                            readonly
                                            [matDatepicker]="dupicker"
                                            (click)="dupicker.open()"
                                            [min]="min_date"
                                        />
                                        <mat-datepicker-toggle matSuffix [for]="dupicker"></mat-datepicker-toggle>
                                        <mat-datepicker #dupicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-bottom mlr-2 my-4">
                <div class="d-flex justify-content-between">
                    <button class="btn btn-primary left px-4" data-dismiss="modal">Cancel</button>

                    <button (click)="addEditDocType(doc, 0)" [disabled]="submitText !== 'Add Document'" class="btn btn-primary right px-4">
                        {{ submitText }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>

<div
    class="modal fade touch-popup"
    id="document_dialog_edit"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-centered" role="document" style="min-width: 590px !important; max-width: fit-content">
        <div class="modal-content">
            <div class="touch-header pt-2">
                <div class="row mlr-2 mt-4">
                    <div class="col-auto p-0 pb-1 my-0 active">Document Name</div>
                    <div class="col-auto mlr-2 p-0 pb-2 my-0">
                        <img src="assets/images/divider_black.svg" alt="divider" />
                    </div>
                    <div class="col-auto p-0 pb-1 my-0 mr-auto">Document ID # {{ doc.document_type_id }}</div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-content mlr-2 my-4">
                <div align="center">
                    <img
                        class="img-fluid"
                        [src]="doc.document_url"
                        [alt]="doc.docTypeName"
                        title="Document Image"
                        (click)="showUpdate ? onFilePicker() : viewDocument(doc.document_url)"
                    />
                </div>

                <div *ngIf="showUpdate == 1" class="pt-2 pb-4">
                    <span (click)="onFilePicker()" class="cursor-pointer text-primary" style="font-size: 11px">
                        Upload new document
                        <input
                            type="file"
                            accept="image/x-png,image/jpeg"
                            class="form-control form-control-lg upload tlc_img"
                            style="filter: alpha(opacity=0); opacity: 0; margin-top: -71px; margin-left: -735px"
                            name="file_source"
                            image="image"
                            (change)="file_to_upload($event.target.files)"
                            data-button-text="Single"
                            data-class-button="btn btn-default"
                            data-classinput="form-control inline"
                            nv-file-select=""
                            uploader="uploader"
                            id="mydocuploado"
                        />
                    </span>
                </div>

                <div class="mt-4 mb-2">
                    <div class="mb-2 title">General Information</div>
                    <div>
                        <table>
                            <tr>
                                <td>Name :</td>
                                <td>{{ doc?.docTypeName }}</td>
                            </tr>
                            <tr>
                                <td>Date :</td>
                                <td [class.py-2]="showUpdate">
                                    <span *ngIf="!showUpdate">{{ doc?.expiry_date | date: 'medium' }}</span>

                                    <mat-form-field *ngIf="showUpdate" appearance="outline">
                                        <input
                                            [(ngModel)]="doc.expiry_date"
                                            matInput
                                            readonly
                                            [matDatepicker]="depicker"
                                            (click)="depicker.open()"
                                            [min]="min_date"
                                        />

                                        <mat-datepicker-toggle matSuffix [for]="depicker"></mat-datepicker-toggle>
                                        <mat-datepicker #depicker></mat-datepicker>
                                    </mat-form-field>
                                </td>
                            </tr>
                            <tr>
                                <td>Type :</td>
                                <td>{{ doc?.docType?.user_type }}</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-bottom mlr-2 my-4">
                <div class="d-flex justify-content-between">
                    <button class="btn btn-primary left px-4" data-dismiss="modal">Cancel</button>

                    <button *ngIf="showUpdate" (click)="editDocument(doc)" [disabled]="submitUpdate != 'Update'" class="btn btn-primary right px-4">
                        {{ submitUpdate }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
