import { Component, Input, OnInit, Output, EventEmitter, TemplateRef } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { TripBookingService } from '../../services/trip-booking/trip-booking.service';
import { ISuggestedFare } from 'src/app/models/fare_estimate.model';
import { MasService } from '../../services/mas/mas.service';
import * as moment from 'moment';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-prepaid-trip-popover',
    templateUrl: './prepaid-trip-popover.component.html',
    styleUrls: ['./prepaid-trip-popover.component.scss'],
})
export class PrepaidTripPopoverComponent implements OnInit {
    constructor(
        public utilityService: UtilityService,
        private tripBookingService: TripBookingService,
        private masService: MasService,
        private modalService: BsModalService
    ) {}

    modalRef: BsModalRef;
    @Input() state: 'unpaid' | 'prepaid' | '' = '';
    @Input() containerClass: string = 'prepaid-popover br-6 preferred_driver';
    @Input() disableAction: boolean = false;
    avoidHighways: boolean = true;

    @Input() trip_payload: {
        pickup_latitude: string;
        pickup_longitude: string;
        destination_latitude: string;
        destination_longitude: string;
        car_type?: number;
        mas_trip_id?: number | any;
        trip_leg_id?: number | string;

        invoice_number?: number | string;
        pickup_date?: Date | any;
        miles?: number | string;
        leg_cost?: number | string;
        fare_factor?: string;
    };
    @Output() onAddTrip: EventEmitter<any> = new EventEmitter<any>();
    @Output() onRemoveTrip: EventEmitter<void> = new EventEmitter<void>();

    suggested_fare_loading: boolean = false;
    mas_trip_loading: boolean = false;
    prepaid_trip_reference: PopoverDirective;

    fare_detail: ISuggestedFare;
    mas_both_leg: any; // If trip exists
    isDesktop: boolean = true;

    ngOnInit(): void {
        if (window.innerWidth < 991) {
            this.isDesktop = false;
        }
    }

    // Function get called when a popover is triggered
    onPopoverOpen() {
        // Assigned and Queued trip contain the saved data of mas payout but we don't have mas_payout in unassigned directly because of secondary_services from the unassigned trip list.
        if (this.trip_payload?.invoice_number && this.trip_payload?.leg_cost == null) {
            this.fetch_mas_trip();
        }

        this.getSuggestedFare();
    }

    // Fetch Mas Trip
    async fetch_mas_trip(
        pickup_date: Date | string = this.trip_payload?.pickup_date,
        invoice_number: string | number = this.trip_payload?.invoice_number
    ) {
        try {
            this.mas_trip_loading = true;
            this.mas_both_leg = await this.masService.fetch_mas_details(moment(pickup_date).format('MM/DD/yyyy'), invoice_number);
            const active_leg = this.mas_both_leg?.trips?.find((x) => x.tripLegId === this.trip_payload?.trip_leg_id);
            if (active_leg) {
                // Assign mas payout or legCost from mas payout to trip_payload
                this.trip_payload.leg_cost =
                    this.masService.calculate_total_leg_cost(active_leg?.legMileage, active_leg?.legCost, active_leg?.secondary_services) || 0;
            }
        } catch (err) {
            console.log('Something went wrong!', err);
        } finally {
            this.mas_trip_loading = false;
        }
    }

    getSuggestedFare() {
        this.suggested_fare_loading = true;
        this.tripBookingService
            .getSuggestedFare({ ...this.trip_payload, avoid_highways: this.avoidHighways ? 1 : 0 })
            .subscribe(
                (res: ISuggestedFare | any) => {
                    if (res) {
                        if (res) {
                            this.fare_detail = res;
                        } else {
                            this.utilityService.toast('error', 'Something went wrong!');
                        }
                    } else {
                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong!');
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong!');
                }
            )
            .add(() => {
                this.suggested_fare_loading = false;
            });
    }

    open_leg_cost_info() {
        let payload: any = {
            toll: this.fare_detail?.toll,
            trip_leg_id: this.trip_payload?.trip_leg_id,
        };

        if (this.mas_both_leg) {
            payload.trips = this.mas_both_leg;
        } else {
            payload.invoice_number = this.trip_payload?.invoice_number;
            payload.date = this.trip_payload?.pickup_date;
        }

        this.masService.open_leg_cost_breakdown(payload);
    }

    openModal(template: TemplateRef<any>) {
        this.modalRef = this.modalService.show(template, {
            class: 'modal-dialog-centered',
        });
    }
}
