import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'StringFilter',
})
export class StringFilterPipe implements PipeTransform {
    transform(list: Array<Object>, key: any, query?: any): any {
        if (!list || !key) return null;
        if (!query) return list;

        query = query.toLowerCase();

        return list.filter((item) => {
            return item[key]?.toLowerCase().includes(query);
        });
    }
}
