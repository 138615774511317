import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, CanActivateChild, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { HttpService } from 'src/app/services/http/http.service';
import { SocketioService } from 'src/app/services/socketio/socketio.service';
import { UtilityService } from '../services/utility/utility.service';

@Injectable({
    providedIn: 'root',
})
export class AuthGuard implements CanActivate {
    constructor(
        private socketService: SocketioService,
        private cookieService: CookieService,
        private httpService: HttpService,
        private router: Router,
        private utilityService: UtilityService
    ) {}
    canActivate(
        route: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        // fetch token from cookies
        const token = this.cookieService.get('web_access_token');
        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));

        if (!token || !driverModel) {
            this.router.navigate(['/', 'corporate_login']);
            return false;
        }

        // initiate socket connection
        this.socketService.connect();

        // emit auth prob to socket
        this.socketService.emit('auth', { session_id: 0, user_type: 'corporate', access_token: token });
        console.log('Authenticating...', token);

        // verify if auth is validated or not!
        return this.socketService.on('auth').pipe(
            map((response) => {
                if (!window.location.href?.includes('live-tracking')) {
                    console.log('LOADING FALSE');
                    this.utilityService.loading = false;
                }
                if (response.flag == true) {
                    console.log('Auth VALIDATED');
                    return true;
                } else {
                    this.utilityService.authorized.next(false);
                    // location.reload();
                    // this.utilityService.toast('info', 'Verifying auth');
                    console.log('Auth Invalid => ', response);

                    if (this.cookieService.get('loginType') === 'staff') {
                        this.utilityService.toast('error', response.error?.errorMessage);
                        this.utilityService.alert(
                            'warning',
                            'Authentification failed. Can not establish connection with socket in order to fetch trips data.'
                        );
                        return true;
                    }

                    // this.logout(token);
                    if (this.router.url == '/') {
                        console.log('Page Reload in 1s');
                        setTimeout(() => {
                            console.log('Reloading...');
                            location.reload();
                        }, 1000);
                    }
                    return false;
                }
            })
        );
    }

    logout(token) {
        localStorage.removeItem('driverdata');
        localStorage.removeItem('corporateModel');
        const prms = { web_access_token: token };
        // environment.urlC + 'logout'
        this.httpService.post(environment.authURL + 'logout', prms).subscribe((data) => {
            this.cookieService.deleteAll();
            this.cookieService.delete('access_token');
            this.cookieService.delete('web_access_token');
            console.log('Logged out!');
            this.router.navigate(['/', 'corporate_login']);
        });
    }
}
