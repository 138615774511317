import { Component, Input, OnInit, Output, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover';

@Component({
    selector: 'app-amount-popover',
    templateUrl: './amount-popover.component.html',
    styleUrls: ['./amount-popover.component.scss'],
})
export class AmountPopoverComponent implements OnInit, OnChanges {
    constructor() {}

    @Input() amount_type: 'normal' | 'percentage' = 'normal';
    @Input() label: string;
    @Input() amount: number = 0;
    @Input() allowEdit: boolean = false;
    @Input() isLoading: boolean = false;
    @Input() enable_secondary_amount: boolean = false;
    @Input() readonly_secondary_amount: boolean = false;
    @Input() reverse_secondary_position: boolean = false;
    @Input() secondary_label: string;
    @Input() secondary_amount: number = 0;

    @Output() onSave: EventEmitter<number> = new EventEmitter<number>();
    @Output() popoverTriggered: EventEmitter<void> = new EventEmitter<void>();
    amount_popover_reference: PopoverDirective;
    amountControl: FormControl = new FormControl(0, [Validators.required]);
    secondaryAmountControl: FormControl = new FormControl(0, [Validators.required]);

    ngOnInit(): void {
        if (this.amount_type === 'percentage') {
            this.amountControl.setValidators([Validators.min(0), Validators.max(100)]);
        }
    }

    ngOnChanges(changes: SimpleChanges): void {
        if (changes?.amount?.currentValue) {
            this.update_amount(changes?.amount?.currentValue);
        }
        if (changes?.secondary_amount?.currentValue) {
            this.secondaryAmountControl.patchValue(changes?.secondary_amount?.currentValue?.toFixed(2));
        }
    }

    update_amount(amount: number) {
        this.amountControl.patchValue(amount?.toFixed(2));
    }
}
