<div class="touch-popup" role="document">
    <div class="modal-content selection-dialog">
        <div class="modal-body">
            <div class="text-right">
                <button type="button" aria-label="Close" (click)="bsModalRef.hide()">
                    <img src="/assets/images/modalClose.svg" alt="closeIcon" />
                </button>
            </div>
            <div class="row touch-content mt-3">
                <div class="col-md-6">
                    <div class="trip-information">
                        <div>
                            <div class="dark-color fw-600 mb-2 fs-14">Rider Information</div>
                            <div class="mt-4 text-spacegrey" style="min-height: 49px">
                                <div>
                                    Rider Details
                                    <!-- <a data-toggle="collapse" href="#rider_detail_collapse" role="button" aria-expanded="false" aria-controls="rider_detail_collapse"
                                    (click)="showRiderDetail = !showRiderDetail">
                                        <ng-container *ngIf="showRiderDetail">Hide</ng-container>
                                        <ng-container *ngIf="!showRiderDetail">Show</ng-container>
                                      </a> -->

                                    <a>
                                        <span
                                            data-toggle="collapse"
                                            data-target="#rider_detail_collapse"
                                            role="button"
                                            aria-expanded="false"
                                            aria-controls="collapseExample"
                                            class="fs-13"
                                            style="color: #2c71c3"
                                            (click)="showRiderDetail = !showRiderDetail"
                                        >
                                            <ng-container *ngIf="showRiderDetail">Hide</ng-container>
                                            <ng-container *ngIf="!showRiderDetail">Show</ng-container>
                                        </span>
                                    </a>
                                </div>

                                <div class="noti_setting pt-2 collapse show" id="rider_detail_collapse">
                                    <div *ngIf="!isLoading" class="dark-color">{{ trip?.rider_name }} {{ trip?.rider_mobile | phoneFormat }}</div>
                                    <app-loading
                                        *ngIf="isLoading"
                                        height="15px"
                                        width="80%"
                                        borderRadius="4px"
                                        margin="0px"
                                        type="placeholder"
                                    ></app-loading>
                                </div>

                                <!-- <div data-toggle="collapse" data-target="#rider_detail_collapse" aria-expanded="false" aria-controls="rider_detail_collapse">    
                                        <div *ngIf="!isLoading" class="dark-color">{{ trip?.rider_name }} {{ trip?.rider_mobile }}</div>
                                        <app-loading *ngIf="isLoading" height="15px" width="80%" borderRadius="4px" margin="0px" type="placeholder"></app-loading>
                                  </div> -->
                            </div>

                            <div class="mt-3 text-spacegrey">
                                <div class="d-flex align-items-center">
                                    <div>Driver Details</div>
                                    <div class="cmodal ml-2" align="right" *ngIf="trip_type === 'queued'">
                                        <div class="dropdown">
                                            <span
                                                class="w-100 fw-normal fs-13"
                                                type="button"
                                                id="affiliation_menu"
                                                data-toggle="dropdown"
                                                aria-haspopup="true"
                                                aria-expanded="false"
                                            >
                                                <div style="color: #2c71c3" (click)="reset_driver_results()">Share</div>
                                            </span>
                                            <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                                            <div
                                                class="p-0 overflow-auto dropdown-menu auto cd-menu select-menu-item-style first card-shadow"
                                                style="width: 300px !important; max-height: 300px !important"
                                                id="trip_status_menu"
                                                aria-labelledby="affiliation_menu"
                                            >
                                                <div class="px-2 py-3 search-box">
                                                    <span>
                                                        <input
                                                            id="select_driver_dropdown_from_queued"
                                                            [formControl]="driver_search_control"
                                                            class="m-0 border-0"
                                                            style="width: 79%"
                                                            type="text"
                                                            placeholder=" Search Drivers"
                                                        />
                                                        <i class="fa fa-search" aria-hidden="true"></i>
                                                    </span>
                                                </div>
                                                <div style="max-height: 150px" class="overflow-auto">
                                                    <ng-container *ngIf="cached_drivers?.length">
                                                        <div
                                                            *ngFor="let driver of cached_drivers"
                                                            class="dropdown-item fs-13 d-flex justify-content-between cursor-pointer"
                                                            [class.active-item]="
                                                                driver?.driver_id === selected_driver?.driver_id && selected_driver?.type === 'cached'
                                                            "
                                                            (click)="$event.stopPropagation(); on_select_driver(driver, 'cached')"
                                                        >
                                                            <div>{{ driver?.driver_name }}</div>
                                                            <div
                                                                class="cursor-pointer alert-driver"
                                                                [class.disable-icon]="
                                                                    recent_driver_alert_logs?.includes(
                                                                        'q:' + trip?.mas_trip_id + ':' + driver?.driver_id
                                                                    )
                                                                "
                                                                (click)="
                                                                    $event.stopPropagation(); send_driver_alert(trip?.mas_trip_id, driver?.driver_id)
                                                                "
                                                            >
                                                                <img src="assets/images/send_arrow.svg" alt="Alert Driver" />
                                                            </div>
                                                        </div>
                                                        <div class="mb-1">
                                                            <hr />
                                                        </div>
                                                    </ng-container>
                                                    <ng-container *ngIf="!driverLoading">
                                                        <div
                                                            *ngFor="let driver of drivers"
                                                            class="dropdown-item fs-13 d-flex justify-content-between cursor-pointer"
                                                            [class.active-item]="
                                                                driver?.driver_id === selected_driver?.driver_id && selected_driver?.type === 'new'
                                                            "
                                                            (click)="$event.stopPropagation(); on_select_driver(driver, 'new')"
                                                        >
                                                            <div>{{ driver.driver_name }}</div>
                                                            <div
                                                                class="cursor-pointer alert-driver"
                                                                [class.disable-icon]="
                                                                    recent_driver_alert_logs?.includes(
                                                                        'q:' + trip?.mas_trip_id + ':' + driver?.driver_id
                                                                    )
                                                                "
                                                                (click)="
                                                                    $event.stopPropagation(); send_driver_alert(trip?.mas_trip_id, driver?.driver_id)
                                                                "
                                                            >
                                                                <img src="assets/images/send_arrow.svg" alt="Alert Driver" />
                                                            </div>
                                                        </div>
                                                    </ng-container>

                                                    <div *ngIf="driverLoading" class="mx-2">
                                                        <app-loading
                                                            *ngFor="let item of [0, 1, 2, 3, 4, 5]"
                                                            height="20px"
                                                            borderRadius="2px"
                                                            type="placeholder"
                                                        ></app-loading>
                                                    </div>
                                                </div>
                                                <hr class="light" />
                                                <div class="d-flex justify-content-around text-blue-secondary fs-13 my-2 p-2">
                                                    <div class="cursor-pointer" (click)="selected_driver = null">Dismiss</div>
                                                    <div class="cursor-pointer" [class.disabled-text]="!selected_driver" (click)="onAction('assign')">
                                                        Assign
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="dark-color" *ngIf="!isLoading">
                                    <ng-container *ngIf="trip?.driver_id">
                                        {{ trip?.driver_name }} {{ trip?.driver_mobile | phoneFormat }}
                                        <div class="fs-13">
                                            <span *ngIf="driver?.car_name">{{ driver?.car_name }},</span>
                                            <span *ngIf="driver?.car_make">{{ driver?.car_make }},</span>
                                            <span *ngIf="driver?.car_model">{{ driver?.car_model }},</span>
                                            <span *ngIf="driver?.car_no">{{ driver?.car_no }}</span>
                                        </div>
                                    </ng-container>
                                    <!-- <ng-container *ngIf="!trip?.driver_name">N/A</ng-container> -->
                                </div>
                                <app-loading
                                    *ngIf="isLoading"
                                    height="15px"
                                    width="80%"
                                    borderRadius="4px"
                                    margin="0px"
                                    type="placeholder"
                                ></app-loading>
                            </div>
                        </div>
                        <hr />
                        <div>
                            <div class="dark-color fw-600 mb-2 fs-14">{{ trip_type | titlecase }} Trip Information</div>
                            <div class="mt-4 text-spacegrey">
                                <div>
                                    Pickup Date & Time
                                    <span *ngIf="show_cancel_trip_label" class="fw-600 text-blue ml-2 cursor-pointer" (click)="onAction('cancel')">
                                        Cancel this trip ?
                                    </span>
                                </div>
                                <div class="dark-color" *ngIf="!isLoading">
                                    {{ trip?.pickup_datetime | date: 'MMMM d, y' }} |
                                    {{ trip?.pickup_datetime | date: 'h:mm
                                    a' }} EST
                                </div>
                                <app-loading
                                    *ngIf="isLoading"
                                    height="15px"
                                    width="80%"
                                    borderRadius="4px"
                                    margin="0px"
                                    type="placeholder"
                                ></app-loading>
                                <div class="dark-color fs-13" *ngIf="!isLoading">
                                    <ng-container *ngIf="driver_pickup_ETA?.text">Pickup ETA {{ driver_pickup_ETA?.text }}</ng-container>
                                    <span *ngIf="driver_pickup_ETA?.text && pickup_dropoff_ETA?.text">|</span>
                                    <ng-container *ngIf="pickup_dropoff_ETA?.text">Dropoff ETA {{ pickup_dropoff_ETA?.text }}</ng-container>
                                </div>
                                <div style="margin-top: -4px">
                                    <app-loading
                                        *ngIf="isLoading"
                                        height="15px"
                                        width="90%"
                                        borderRadius="4px"
                                        margin="0px"
                                        type="placeholder"
                                    ></app-loading>
                                </div>
                            </div>

                            <div class="mt-3 text-spacegrey">
                                <div>Pickup Location</div>
                                <div class="dark-color" *ngIf="!isLoading">
                                    {{ trip?.pickup_location || 'N/A' }}
                                </div>
                                <app-loading
                                    *ngIf="isLoading"
                                    height="15px"
                                    width="80%"
                                    borderRadius="4px"
                                    margin="0px"
                                    type="placeholder"
                                ></app-loading>
                            </div>
                            <div class="mt-3 text-spacegrey">
                                <div>Drop Off Location</div>
                                <div class="dark-color" *ngIf="!isLoading">
                                    {{ trip?.dropoff_location || 'N/A' }}
                                </div>
                                <app-loading
                                    *ngIf="isLoading"
                                    height="15px"
                                    width="80%"
                                    borderRadius="4px"
                                    margin="0px"
                                    type="placeholder"
                                ></app-loading>
                            </div>
                        </div>
                        <ng-container *ngIf="total_trips?.length > 1">
                            <hr />
                            <div class="d-flex">
                                <div class="flex-grow-1">
                                    <div class="dark-color fw-600 mb-2 fs-14">
                                        Previous Trip
                                        <span *ngIf="prev_trip" class="fw-600 text-blue ml-2 cursor-pointer fs-11" (click)="onNavigate('prev')">
                                            View
                                        </span>
                                    </div>
                                    <div class="mt-2 text-spacegrey fs-13">
                                        <div>Rider Name</div>
                                        <div class="dark-color">
                                            {{ prev_trip?.rider_name || '-' }}
                                        </div>
                                        <!-- <app-loading *ngIf="isLoading" height="15px" width="80%" borderRadius="4px" margin="0px" type="placeholder"></app-loading> -->
                                    </div>
                                    <div class="mt-2 text-spacegrey fs-13">
                                        <div>Pickup Date & Time</div>
                                        <div class="dark-color">
                                            <ng-container *ngIf="prev_trip">
                                                {{ prev_trip?.pickup_datetime | date: 'MMMM d, y' }} |
                                                {{ prev_trip?.pickup_datetime | date: 'h:mm a' }} EST
                                            </ng-container>
                                            <ng-container *ngIf="!prev_trip">-</ng-container>
                                        </div>
                                        <!-- <app-loading *ngIf="isLoading" height="15px" width="80%" borderRadius="4px" margin="0px" type="placeholder"></app-loading> -->
                                    </div>
                                </div>
                                <div class="vertical-line mx-3"></div>
                                <div class="flex-grow-1">
                                    <div class="dark-color fw-600 mb-2 fs-14">
                                        Next Trip
                                        <span *ngIf="next_trip" class="fw-600 text-blue ml-2 cursor-pointer fs-11" (click)="onNavigate('next')">
                                            View
                                        </span>
                                    </div>
                                    <div class="mt-2 text-spacegrey fs-13">
                                        <div>Rider Name</div>
                                        <div class="dark-color">
                                            {{ next_trip?.rider_name || '-' }}
                                        </div>
                                        <!-- <app-loading *ngIf="isLoading" height="15px" width="80%" borderRadius="4px" margin="0px" type="placeholder"></app-loading> -->
                                    </div>
                                    <div class="mt-2 text-spacegrey fs-13">
                                        <div>Pickup Date & Time</div>
                                        <div class="dark-color">
                                            <ng-container *ngIf="next_trip">
                                                {{ next_trip?.pickup_datetime | date: 'MMMM d, y' }} |
                                                {{ next_trip?.pickup_datetime | date: 'h:mm a' }} EST
                                            </ng-container>
                                            <ng-container *ngIf="!next_trip">-</ng-container>
                                        </div>
                                        <!-- <app-loading *ngIf="isLoading" height="15px" width="80%" borderRadius="4px" margin="0px" type="placeholder"></app-loading> -->
                                    </div>
                                </div>
                            </div>
                        </ng-container>
                        <hr />
                        <div class="touch-bottom mt-4">
                            <div align="center" class="d-flex justify-content-around" *ngIf="!isLoading">
                                <div *ngIf="left_action_text">
                                    <button
                                        class="cursor-pointer text-white px-4 py-1 btn btn-secondary btn-right"
                                        (click)="onAction(left_action_text)"
                                    >
                                        {{ left_action_text }}
                                    </button>
                                </div>
                                <div *ngIf="right_action_text">
                                    <button class="cursor-pointer text-white px-4 py-1 btn btn-primary" (click)="onAction(right_action_text)">
                                        {{ right_action_text }}
                                    </button>
                                </div>
                            </div>
                            <div *ngIf="isLoading">
                                <app-loading
                                    *ngIf="isLoading"
                                    [placeholderLength]="2"
                                    [space]="false"
                                    height="20px"
                                    width="120px"
                                    borderRadius="4px"
                                    margin="0px 12px"
                                    type="placeholder"
                                ></app-loading>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6">
                    <!-- GOOGLE MAP -->
                    <div [hidden]="isLoading">
                        <div id="map" class="map card-shadow"></div>
                    </div>
                    <div *ngIf="isLoading">
                        <app-loading height="436px" width="478px" borderRadius="12px" margin="0px" type="placeholder"></app-loading>
                    </div>
                </div>
            </div>
            <!-- <div class="touch-content t-margin my-4 mas-layout" id="trip-information" *ngIf="trip">
            </div> -->
        </div>
    </div>
</div>
