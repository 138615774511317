import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../services/http/http.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from '../../../core/services/utility/utility.service';
import { CookieService } from 'ngx-cookie-service';
import { CardsService } from '../../services/cards/cards.service';

declare const jQuery: any;
const $: any = jQuery;
@Component({
    selector: 'app-settings',
    templateUrl: './more-settings.component.html',
    styleUrls: ['./more-settings.component.scss'],
})
export class MoreSettingsComponent implements OnInit {
    corpCardEnabled: boolean;
    riderCardEnabled: boolean;

    isLoading: boolean = false;
    payment_setting_show: boolean = true;

    cardUpdateMessage: string;
    showHeaderShadow: boolean = false;

    constructor(
        private httpService: HttpService,
        private cookieService: CookieService,
        public utilityService: UtilityService,
        private cardService: CardsService
    ) {
        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));
    }

    ngOnInit(): void {
        this.isLoading = true;
        this.initTable();
    }

    public logout() {
        this.utilityService.logout();
    }

    public initTable() {
        this.httpService
            .post(environment.urlC + 'list_card_payment_option', {
                web_access_token: this.cookieService.get('web_access_token'),
            })
            .subscribe((data) => {
                if (typeof data == 'string') {
                    data = JSON.parse(data);
                } else {
                    data = data;
                }

                if (data.payment_type[0].card_used == 1) {
                    this.corpCardEnabled = true;
                    this.riderCardEnabled = true;
                } else if (data.payment_type[0].card_used == 2) {
                    // this.corpCardEnabled = true;
                    this.corpCardEnabled = true;
                    this.riderCardEnabled = false;
                } else if (data.payment_type[0].card_used == 3) {
                    this.corpCardEnabled = false;
                    this.riderCardEnabled = true;
                }

                if (this.isLoading) this.isLoading = false;
            });
    }

    public changeRiderCardSetting() {
        this.riderCardEnabled = !this.riderCardEnabled;
        this.cardUpdateMessage = this.riderCardEnabled
            ? 'Rider payment is enabled for your ride request payments.'
            : 'Rider payment is disabled for your ride request payments.';

        if (this.checkIfBothDisabled()) {
            this.cardUpdateMessage = 'You must enable one payment type.';
            this.updatePayment(3);
        } else {
            this.updatePayment(this.getCardType());
        }
        this._showConfirmation();
    }

    public changeCorpCardSetting() {
        this.corpCardEnabled = !this.corpCardEnabled;

        this.cardUpdateMessage = this.corpCardEnabled
            ? 'Corporate payment is enabled for your ride request payments.'
            : 'Corporate payment is disabled for your ride request payments.';

        if (this.checkIfBothDisabled()) {
            this.cardUpdateMessage = 'You must enable one payment type.';
            this.updatePayment(2);
        } else {
            this.updatePayment(this.getCardType());
        }
        this._showConfirmation();
    }

    private _showConfirmation() {
        this.utilityService.confirm({ heading: 'Payment Setting', description: this.cardUpdateMessage, rightButtonText: 'Ok' });
    }

    private checkIfBothDisabled() {
        return !this.corpCardEnabled && !this.riderCardEnabled;
    }

    private getCardType(): number {
        if (this.corpCardEnabled && this.riderCardEnabled) {
            return 1;
        } else if (this.corpCardEnabled && !this.riderCardEnabled) {
            return 2;
        } else if (!this.corpCardEnabled && this.riderCardEnabled) {
            return 3;
        }
    }

    public updatePayment(card_used: number) {
        this.httpService
            .post(environment.urlC + 'edit_corporate_card_payment_option', {
                web_access_token: this.cookieService.get('web_access_token'),
                card_used: card_used,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;
                this.initTable();
                this.cardService.listCardPaymentOption().subscribe();
            });
    }
}
