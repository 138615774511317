import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { DriverService } from '../../services/driver/driver.service';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';

@Component({
    selector: 'app-live-queue-trip-setting-popover',
    templateUrl: './live-queue-trip-setting-popover.component.html',
    styleUrls: ['./live-queue-trip-setting-popover.component.scss'],
})
export class LiveQueueTripSettingPopoverComponent implements OnInit, OnDestroy {
    constructor(private driverService: DriverService, private utilityService: UtilityService) {}

    liveTripSettingForm: FormGroup = new FormGroup({
        minutes: new FormControl(0, [Validators.required]),
        miles: new FormControl(0, [Validators.required]),
        enable_minutes: new FormControl(false),
        enable_miles: new FormControl(false),
    });
    popover_reference: PopoverDirective;
    @Input() data_passed: boolean = true;

    isLoading: boolean = false;

    private subscriptionList$: Subscription = new Subscription();

    ngOnInit(): void {
        this.fetchLiveTripSetting();
    }

    fetchLiveTripSetting() {
        this.isLoading = true;
        this.subscriptionList$.add(
            this.driverService
                .featch_driver_percentage()
                .subscribe(
                    (res: { go_live_minutes?: number; go_live_miles?: number; is_go_live_minutes: number; is_go_live_miles: number }) => {
                        this.liveTripSettingForm.patchValue({
                            minutes: res?.go_live_minutes,
                            miles: res?.go_live_miles,
                            enable_minutes: res?.is_go_live_minutes ? true : false,
                            enable_miles: res?.is_go_live_miles ? true : false,
                        });
                    },
                    (err) => {
                        console.log('driver percentage error => ', err);
                    }
                )
                .add(() => {
                    this.isLoading = false;
                })
        );
    }

    updateLiveTripSetting() {
        if (this.liveTripSettingForm?.invalid) return;
        const formValue = this.liveTripSettingForm?.value;
        this.subscriptionList$.add(
            this.driverService
                .set_driver_percentage({
                    go_live_miles: formValue?.miles,
                    go_live_minutes: formValue?.minutes,
                    is_go_live_miles: formValue?.enable_miles ? 1 : 0,
                    is_go_live_minutes: formValue?.enable_minutes ? 1 : 0,
                })
                .subscribe(
                    (res: { flag: number; error?: string; log?: string }) => {
                        if (res?.flag === 1329) {
                            this.utilityService.toast('success', res?.log);
                            this.fetchLiveTripSetting();
                        } else {
                            this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        }
                    },
                    (err) => {
                        console.log('driver percentage error => ', err);
                    }
                )
        );
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }
}
