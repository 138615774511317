import { Component, EventEmitter, Input, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input-r';
import { country_code } from '../../pages/live-tracking/country-list';
import { UtilityService } from 'src/app/core/services/utility/utility.service';

export interface IContact {
    name: string;
    mobile: string;
}

@Component({
    selector: 'app-contact-modal',
    templateUrl: './contact-modal.component.html',
    styleUrls: ['./contact-modal.component.scss'],
})
export class ContactModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef, private utilityService: UtilityService) {}
    mode: 'add' | 'edit' = 'add';
    showHeaderSuffixLabel: string = '';
    mobileOnly: boolean = false;
    actionButton: string = 'Save';
    contact: IContact;
    title: string;

    contactForm: FormGroup = new FormGroup({
        name: new FormControl('', [Validators.required, Validators.pattern(/^(\s+\S+\s*)*(?!\s).*$/)]),
        mobile: new FormControl('', [Validators.required]), // If Mobile Only
    });

    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    countryToSet: string = 'US';
    separateDialCode = false;
    countries = country_code;
    selected_country_iso: any = CountryISO.UnitedStates;

    public event: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        // IF Mobile only remove name control from the contactForm

        if (this.mobileOnly) {
            this.contactForm.controls.name.clearValidators();
        }

        if (this.mode === 'edit') {
            this.contact.mobile = this.utilityService.reformat_mobile_no(this.contact.mobile);

            this.selected_country_iso = country_code[this.contact.mobile.split('-')[0]]?.toLowerCase();
            console.log(this.contact.mobile);
            this.contactForm.patchValue({
                name: this.contact.name,
                mobile: this.contact.mobile.split('-')[1],
            });
        }
    }

    onSaveOrUpdate() {
        const contact: {
            name: string;
            mobile: {
                dialCode: string;
                number: string;
            };
        } = this.contactForm.value;

        this.event.emit({
            name: contact?.name?.trim(),
            mobile: `${contact.mobile.dialCode}-${contact.mobile.number}`,
        });
    }
}
