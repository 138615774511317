import { ChangeDetectorRef, Component, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Toast, ToastPackage, ToastrService } from 'ngx-toastr';
import { NotificationService } from '../../services/notification/notification.service';
import { Notification } from 'src/app/models/notification.model';

@Component({
    selector: 'app-notification-toast',
    templateUrl: './notification-toast.component.html',
    styleUrls: ['./notification-toast.component.scss'],
})
export class NotificationToastComponent extends Toast implements OnInit, OnDestroy {
    constructor(
        protected toastrService: ToastrService,
        public toastPackage: ToastPackage,
        private changeDetectorRef: ChangeDetectorRef,
        public notificationService: NotificationService
    ) {
        super(toastrService, toastPackage);
        this.toastrService.toastrConfig.newestOnTop = false;

        // this.toastrService.toastrConfig.maxOpened = 0;

        // Require refactoring to new ngx-toastr version
        // @ts-ignore
        this.rightActionText = this?.options?.payload?.rightActionText;

        // @ts-ignore
        this.type = this?.options?.payload?.type;

        // @ts-ignore
        this.position = this?.options?.payload?.position;

        // @ts-ignore
        this.notification_payload = this?.options?.payload?.notification_payload;

        this.timeOut = this.options.timeOut;
        this.updateProgress();
    }

    @HostListener('mouseenter')
    stickAround(): void {
        return;
    }
    @HostListener('mouseleave')
    delayedHideToast() {
        return;
    }
    // title: string = 'New Notification';
    // message: string = 'Driver John Lennon has confirmed a queued trip for today, November 3, at 3:45pm';
    rightActionText: string; // = 'View';
    type: 'detailed' | 'normal' = 'normal'; // If live render it at top view
    position: 'bottom' | 'top' = 'bottom';
    timeOut: number; // = 30 * 1000;
    activeTime: number = 0;
    progress: number = 0;
    progressInterval: any;
    active_notifications_by_type: number;
    notification_payload: Notification;

    ngOnInit(): void {
        this.changeDetectorRef.detectChanges();
        this.active_notifications_by_type = this.notificationService._notification_by_type(this.position)?.length;
    }

    action(event: any) {
        // event.stopPropagation();
        this.toastPackage.triggerAction(event);
        return false;
    }

    onDismiss() {
        if (this.notificationService.notificationRefsList?.length > 1) {
            this.notificationService.notificationRefsList.forEach((notification) => {
                if (notification.type === this.position) {
                    this.toastrService.clear(notification.id);
                }
            });
        } else {
            this.remove();
        }
    }

    updateProgress() {
        const intervalTime = 100;
        this.progressInterval = setInterval(() => {
            this.activeTime += intervalTime;
            this.progress = (this.activeTime / this.timeOut) * 100;
        }, intervalTime);
    }

    ngOnDestroy(): void {
        clearInterval(this.progressInterval);
    }
}
