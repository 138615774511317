<div class="modal-dialog modal-dialog-centered card-modal" role="document" style="width: 650px">
    <div class="modal-content shadow touch-popup">
        <div class="row modaltitle">
            <div class="mx-4 mx-sm-5 mt-4 mb-2 title">
                <span *ngIf="show_email_input">Update Email &</span>
                <span>Add {{ cardType || '' }} Card</span>
            </div>
        </div>
        <hr class="confirm my-0" />
        <div class="modal-body centered-text">
            <div class="col-sm-12">
                <form enctype="multipart/form-data" action="#" method="post" name="myForm" id="payment-form">
                    <div class="touch-content my-4" *ngIf="show_email_input">
                        <div class="my-1">
                            <div class="input-container text-center">
                                <input
                                    type="email"
                                    placeholder="Enter rider email"
                                    [(ngModel)]="user_email"
                                    (keyup)="show_email_error = false"
                                    [ngModelOptions]="{ standalone: true }"
                                    class="form-control soft-input"
                                    [class.error]="show_email_error"
                                    [email]="true"
                                />
                            </div>
                        </div>
                    </div>
                    <div class="modalrow my-3" [class.StripeElement--invalid]="cardError">
                        <ngx-stripe-card
                            [options]="cardOptions"
                            [elementsOptions]="elementsOptions"
                            (change)="onStripeCardChange($event)"
                            #stripeElement
                        ></ngx-stripe-card>
                        <div class="col-sm-12 text-center my-2">
                            <div id="card-errors" role="alert">{{ cardError }}</div>
                        </div>
                    </div>
                    <div align="center">
                        <button (click)="bsModalRef.hide()" class="btn btn-primary btn-left m-2 px-3 py-1">Cancel</button>
                        <button type="submit" (click)="onSaveCard()" class="btn btn-secondary btn-right m-2 px-3 py-1">Save Card</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</div>
