import { Component, EventEmitter, OnInit } from '@angular/core';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-date-time-picker',
    templateUrl: './date-time-picker.component.html',
    styleUrls: ['./date-time-picker.component.scss'],
})
export class DateTimePickerComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}
    title: string = 'Choose Date and Time';
    leftButtonText: string = 'Cancel';
    rightButtonText: string = 'Book the Ride';

    viewMode: 'time' | 'willcall+time' | 'date' | 'full' = 'full';
    willCall?: boolean = false;

    date: Date = new Date();

    minDate: Date;
    maxDate: Date | any;

    hour: string | number;
    minute: string | number;

    minutesList: Array<number> = Array.from({ length: 60 }, (_, x) => x);

    hoursList: Array<{ value: string; title: string }> = Array.from({ length: 24 }, (_, x: number) => {
        const x_string = x < 10 ? '0' + x.toString() : x.toString();
        return { value: x_string, title: `${x_string} (${x % 12 || 12} ${x < 12 ? 'AM' : 'PM'} )` };
    });

    public event: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        this.hour = this.date.getHours().toString();
        this.minute = this.date.getMinutes();
        this.hour = _.padStart(this.hour, 2, '0');
    }

    onConfirm() {
        this.date.setHours(Number(this.hour));
        this.date.setMinutes(Number(this.minute));

        const output_payload: { date: Date; willCall?: boolean } = {
            date: this.date,
        };

        if (this.viewMode === 'willcall+time') {
            output_payload.willCall = this.willCall;
        }
        this.event.emit(output_payload);
        this.bsModalRef.hide();
    }
}
