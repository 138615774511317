export const PremiumOptionConfirmation: any = {
    pet_friendly_car: {
        imageUrl: 'assets/images/pet_friendly_rides.svg',
        description:
            'Whether you’re visiting a family or doing your regular routine, there is always a room for your beloved pet. <br><br >We will find drivers who agreed to accept pets in their car.',
        title: 'Pet Friendly Rides',
    },
    through_door_service: {
        imageUrl: 'assets/images/through_the_door.svg',
        description:
            'We know that an extra hand is all you need to get you through the door, we will be happy to assist you. <br> <br> We will find drivers who accept giving you through the door assistance.',
        title: 'Through the door service',
    },
    plexiglass_partition: {
        imageUrl: 'assets/images/social_distancing.svg',
        description:
            'We care about your health concerns and safety needs against Covid, we listed vehicles that are equipped with social distancing partition. <br> <br> You will be matched with drivers that drive vehicles equipped with social distancing partition.',
        title: 'Social distancing partition',
    },
};

export const CarTypes = {
    NaN: {
        className: 'Standard Class',
        similar: 'Honda Accord, Cadillac MKS and BMW 3 series or similar',
        max: 4,
        luggage: 2,
    },
    'QS 4max': {
        className: 'Standard Class',
        similar: 'Honda Accord, Cadillac MKS and BMW 3 series or similar',
        max: 4,
        luggage: 2,
    },
    'QLE 6max': {
        className: 'Standard Class',
        similar: 'Toyota Highlander, Toyota Seina, Chevrolet Suburban or similiar',
        max: 6,
        luggage: 2,
    },
    'WAV 4max': {
        className: 'Standard Class',
        similar: 'Toyota Highlander, Toyota Seina, Chevrolet Suburban or similiar',
        max: 6,
        luggage: 2,
    },
    'LUXE 4max': {
        className: 'VIP Class',
        similar: 'Mercedes-Benz S-Class, BMW 7 Series, Audi A8 or similar',
        max: 4,
        luggage: 2,
    },
    'GRANDE 7max': {
        className: 'VIP Class',
        similar: 'Mercedes-Benz V-Class, Chevrolet Suburban, Cadillac Escalade or similar',
        max: 6,
        luggage: 2,
    },
    'ELITE 3max': {
        className: 'Elite Class',
        similar: 'Mercedes-Benz V-Class, Chevrolet Suburban, Cadillac Escalade or similar',
        max: 4,
        luggage: 2,
    },
    'QXL 8max': {
        className: 'QXL Class',
        similar: 'Mercedes-Benz V-Class, Chevrolet Suburban, Cadillac Escalade or similar',
        max: 6,
        luggage: 10,
    },
};

// export const Primary_CarsDriverOptions: Array<any> = [
//     { car_type: 1, car_name: 'QX', car_select: 'QX Select', car_text: 'max 4 seats' },
//     { car_type: 2, car_name: 'QXL', car_select: 'QXL Select', car_text: 'max 4 seats' },
//     { car_type: 3, car_name: 'QX-Luxe', car_select: 'QX-Luxe Select', car_text: 'max 4 seats' },
//     { car_type: 4, car_name: 'QXL-Luxe', car_select: 'QXL-Luxe Select', car_text: 'max 4 seats' },
//     { car_type: 5, car_name: 'Q-NEMT', car_select: 'Q-NEMT Select', car_text: 'max 4 seats' },
//     { car_type: 6, car_name: 'Q-Elite', car_select: 'Q-Elite Select', car_text: 'max 4 seats' },
//     { car_type: 7, car_name: 'Q-VAN', car_select: 'Q-VAN Select', car_text: 'max 4 seats' },
// ];

export const Primary_CarsDriverOptions: Array<any> = [
    { car_type: 1, car_name: 'QX', car_select: 'QX Select', car_text: 'max 4 seats' },
    { car_type: 2, car_name: 'QXL', car_select: 'QXL Select', car_text: 'max 4 seats' },
    { car_type: 3, car_name: 'Q-Luxe', car_select: 'Q-Luxe Select', car_text: 'max 4 seats' },
    { car_type: 4, car_name: 'Q-Luxe SUV', car_select: 'Q-Luxe SUV Select', car_text: 'max 4 seats' },
    { car_type: 5, car_name: 'Q-NEMT', car_select: 'Q-NEMT Select', car_text: 'max 4 seats' },
    { car_type: 6, car_name: 'Q-Elite', car_select: 'Q-Elite Select', car_text: 'max 4 seats' },
    { car_type: 7, car_name: 'Q-VAN', car_select: 'Q-VAN Select', car_text: 'max 4 seats' },
];

export const CAR_TYPE_MAP: any = {
    1: 'QX',
    2: 'QXL',
    3: 'Q-Luxe',
    4: 'Q-Luxe SUV',
    5: 'Q-NEMT',
    6: 'Q-Elite',
    7: 'Q-VAN',
};

export const CarsDriverOptions: Array<any> = [
    {
        car_type: 1,
        car_name: 'QX',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621699025044-qudos-1568805648914-qs.png',
        passenger_count: 4,
    },
    {
        car_type: 2,
        car_name: 'QL',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1613573212649-qudos-1568805724174-qle.png',
        passenger_count: 5,
    },
    {
        car_type: 3,
        car_name: 'QX-Luxe',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1620743529773-qudos-1568805764795-luxe.png',
        passenger_count: 4,
    },
    {
        car_type: 4,
        car_name: 'QL-Luxe',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1620743620116-qudos-1568805792058-grande.png',
        passenger_count: 5,
    },
    {
        car_type: 5,
        car_name: 'Q-WAV',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1613573236328-qudos-1568805813421-wav.png',
        passenger_count: 3,
    },
    {
        car_type: 6,
        car_name: 'Q-Elite',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1613573256273-qudos-1568805834264-elite.png',
        passenger_count: 3,
    },
    {
        car_type: 7,
        car_name: 'Q-VAN',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621047975079-qudos-1568805857941-qxl.png',
        passenger_count: 8,
    },
    {
        car_type: 100,
        car_name: 'X5-LUX',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621426122328-download.png',
        passenger_count: 6,
    },
    {
        car_type: 101,
        car_name: 'XR-06',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621495938357-car.jpg',
        passenger_count: 10,
    },
    {
        car_type: 102,
        car_name: 'Q123',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1628242009840-Car2.png',
        passenger_count: 6,
    },
    {
        car_type: 105,
        car_name: 'Car ABc',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621601369649-1.jpg',
        passenger_count: 7,
    },
    {
        car_type: 106,
        car_name: 'XYZ Car',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621833362491-23.jpg',
        passenger_count: 7,
    },
    {
        car_type: 107,
        car_name: 'XR-01',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621842643940-am567_pop_pioneer_exterior_front_quarter_a_v011.png',
        passenger_count: 0,
    },
    {
        car_type: 108,
        car_name: 'QQ Car',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621945159626-24.jpg',
        passenger_count: 5,
    },
    {
        car_type: 109,
        car_name: 'AM-DB11ertwdtyyudiod',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622008692259-car.jpg',
        passenger_count: 10,
    },
    {
        car_type: 110,
        car_name: 'QX-02',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622008692259-car.jpg',
        passenger_count: 5,
    },
    {
        car_type: 111,
        car_name: 'AA-1',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622206816149-road-sunset.jpg',
        passenger_count: 0,
    },
    {
        car_type: 112,
        car_name: 'xs-007',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622206816149-road-sunset.jpg',
        passenger_count: 11,
    },
    {
        car_type: 113,
        car_name: 'ZZ cars',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622206816149-road-sunset.jpg',
        passenger_count: 1,
    },
    {
        car_type: 114,
        car_name: 'CC123',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622206816149-road-sunset.jpg',
        passenger_count: 5,
    },
    {
        car_type: 115,
        car_name: 'CX12',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622206816149-road-sunset.jpg',
        passenger_count: 3,
    },
    {
        car_type: 116,
        car_name: 'Q7',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1623042484574-pexels-photo-1274260-(1).jpeg',
        passenger_count: 7,
    },
    {
        car_type: 117,
        car_name: 'Z123',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1623050067342-23.jpg',
        passenger_count: 10,
    },
    {
        car_type: 118,
        car_name: 'AZ-01',
        car_type_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        passenger_count: 10,
    },
    {
        car_type: 119,
        car_name: 'ZY Cars',
        car_type_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        passenger_count: 2,
    },
    {
        car_type: 120,
        car_name: 'zyx',
        car_type_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        passenger_count: 3,
    },
    {
        car_type: 122,
        car_name: 'Car_Classic',
        car_type_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1627362141464-Car3.png',
        passenger_count: 2,
    },
    {
        car_type: 123,
        car_name: 'Car Litee',
        car_type_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        passenger_count: 4,
    },
];

export const CarsOptions: Array<any> = [
    {
        value_regular: '50.98',
        value_scheduled: '52.44',
        car_type: 1,
        car_name: 'QX',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621699025044-qudos-1568805648914-qs.png',
        regular_ride_without_discount: '50.98',
        schedule_ride_without_discount: '52.44',
        base_fare: 3,
        estimated_fare_distance: '10.17',
        estimated_fare_time: '35.43',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '1.46',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.38',
        qudos_service_fee: '2.38',
        business_name: null,
    },
    {
        value_regular: '47.33',
        value_scheduled: '47.33',
        car_type: 2,
        car_name: 'QL',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1613573212649-qudos-1568805724174-qle.png',
        regular_ride_without_discount: '47.33',
        schedule_ride_without_discount: '47.33',
        base_fare: 3.85,
        estimated_fare_distance: '18.78',
        estimated_fare_time: '18.08',
        sales_tax: '3.61',
        black_car_fund: '1.02',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '1.99',
        qudos_service_fee: '1.99',
        business_name: null,
    },
    {
        value_regular: '64.42',
        value_scheduled: '64.42',
        car_type: 3,
        car_name: 'QX-Luxe',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1620743529773-qudos-1568805764795-luxe.png',
        regular_ride_without_discount: '64.42',
        schedule_ride_without_discount: '64.42',
        base_fare: 7,
        estimated_fare_distance: '29.34',
        estimated_fare_time: '19.06',
        sales_tax: '4.92',
        black_car_fund: '1.38',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.71',
        qudos_service_fee: '2.71',
        business_name: null,
    },
    {
        value_regular: '79.95',
        value_scheduled: '79.95',
        car_type: 4,
        car_name: 'QL-Luxe',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1620743620116-qudos-1568805792058-grande.png',
        regular_ride_without_discount: '79.95',
        schedule_ride_without_discount: '79.95',
        base_fare: 14,
        estimated_fare_distance: '35.21',
        estimated_fare_time: '19.55',
        sales_tax: '6.10',
        black_car_fund: '1.72',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '3.37',
        qudos_service_fee: '3.37',
        business_name: null,
    },
    {
        value_regular: '47.33',
        value_scheduled: '47.33',
        car_type: 5,
        car_name: 'Q-WAV',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1613573236328-qudos-1568805813421-wav.png',
        regular_ride_without_discount: '47.33',
        schedule_ride_without_discount: '47.33',
        base_fare: 3.85,
        estimated_fare_distance: '18.78',
        estimated_fare_time: '18.08',
        sales_tax: '3.61',
        black_car_fund: '1.02',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '1.99',
        qudos_service_fee: '1.99',
        business_name: null,
    },
    {
        value_regular: '55.09',
        value_scheduled: '211.32',
        car_type: 6,
        car_name: 'Q-Elite',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1613573256273-qudos-1568805834264-elite.png',
        regular_ride_without_discount: '55.09',
        schedule_ride_without_discount: '211.32',
        base_fare: 10,
        estimated_fare_distance: '11.74',
        estimated_fare_time: '29.32',
        sales_tax: '1.02',
        black_car_fund: '0.51',
        estimated_tolls: '0.00',
        estimated_surcharges: '153.17',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.50',
        qudos_service_fee: '2.50',
        business_name: null,
    },
    {
        value_regular: '289.99',
        value_scheduled: '1074.13',
        car_type: 7,
        car_name: 'Q-VAN',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621047975079-qudos-1568805857941-qxl.png',
        regular_ride_without_discount: '289.99',
        schedule_ride_without_discount: '1074.13',
        base_fare: 28,
        estimated_fare_distance: '25.74',
        estimated_fare_time: '195.47',
        sales_tax: '22.12',
        black_car_fund: '6.45',
        estimated_tolls: '0.00',
        estimated_surcharges: '720.21',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '12.21',
        qudos_service_fee: '12.21',
        business_name: null,
    },
    {
        value_regular: '118.84',
        value_scheduled: '118.84',
        car_type: 100,
        car_name: 'X5-LUX',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621426122328-download.png',
        regular_ride_without_discount: '118.84',
        schedule_ride_without_discount: '118.84',
        base_fare: 17,
        estimated_fare_distance: '35.21',
        estimated_fare_time: '61.08',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '5.55',
        qudos_service_fee: '5.55',
        business_name: null,
    },
    {
        value_regular: '33.84',
        value_scheduled: '33.84',
        car_type: 101,
        car_name: 'XR-06',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621495938357-car.jpg',
        regular_ride_without_discount: '33.84',
        schedule_ride_without_discount: '33.84',
        base_fare: 12,
        estimated_fare_distance: '7.82',
        estimated_fare_time: '12.22',
        sales_tax: '0.03',
        black_car_fund: '0.19',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '1.57',
        qudos_service_fee: '1.57',
        business_name: null,
    },
    {
        value_regular: '41.19',
        value_scheduled: '41.19',
        car_type: 102,
        car_name: 'Q123',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621601330369-1.jpg',
        regular_ride_without_discount: '41.19',
        schedule_ride_without_discount: '41.19',
        base_fare: 5,
        estimated_fare_distance: '9.39',
        estimated_fare_time: '24.43',
        sales_tax: '0.27',
        black_car_fund: '0.19',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '1.90',
        qudos_service_fee: '1.90',
        business_name: null,
    },
    {
        value_regular: '11.35',
        value_scheduled: '12.91',
        car_type: 105,
        car_name: 'Car ABc',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621601369649-1.jpg',
        regular_ride_without_discount: '11.35',
        schedule_ride_without_discount: '12.91',
        base_fare: 4,
        estimated_fare_distance: '1.56',
        estimated_fare_time: '4.89',
        sales_tax: '0.00',
        black_car_fund: '0.10',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.79',
        qudos_service_fee: '0.79',
        business_name: null,
    },
    {
        value_regular: '18.13',
        value_scheduled: '24.25',
        car_type: 106,
        car_name: 'XYZ Car',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621833362491-23.jpg',
        regular_ride_without_discount: '18.13',
        schedule_ride_without_discount: '24.25',
        base_fare: 7,
        estimated_fare_distance: '1.64',
        estimated_fare_time: '2.69',
        sales_tax: '0.17',
        black_car_fund: '0.17',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.79',
        qudos_service_fee: '0.79',
        business_name: null,
    },
    {
        value_regular: '64.23',
        value_scheduled: '124.91',
        car_type: 107,
        car_name: 'XR-01',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621842643940-am567_pop_pioneer_exterior_front_quarter_a_v011.png',
        regular_ride_without_discount: '64.23',
        schedule_ride_without_discount: '124.91',
        base_fare: 20,
        estimated_fare_distance: '15.65',
        estimated_fare_time: '24.43',
        sales_tax: '0.60',
        black_car_fund: '0.60',
        estimated_tolls: '0.00',
        estimated_surcharges: '60.08',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.94',
        qudos_service_fee: '2.94',
        business_name: null,
    },
    {
        value_regular: '16.70',
        value_scheduled: '46.06',
        car_type: 108,
        car_name: 'QQ Car',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1621945159626-24.jpg',
        regular_ride_without_discount: '16.70',
        schedule_ride_without_discount: '46.06',
        base_fare: 5.56,
        estimated_fare_distance: '1.72',
        estimated_fare_time: '2.69',
        sales_tax: '0.17',
        black_car_fund: '0.17',
        estimated_tolls: '0.00',
        estimated_surcharges: '23.43',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.79',
        qudos_service_fee: '0.79',
        business_name: null,
    },
    {
        value_regular: '50.98',
        value_scheduled: '52.44',
        car_type: 109,
        car_name: 'AM-DB11ertwdtyyudiod',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622008692259-car.jpg',
        regular_ride_without_discount: '50.98',
        schedule_ride_without_discount: '52.44',
        base_fare: 3,
        estimated_fare_distance: '10.17',
        estimated_fare_time: '35.43',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '1.46',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.38',
        qudos_service_fee: '2.38',
        business_name: null,
    },
    {
        value_regular: '48.88',
        value_scheduled: '48.88',
        car_type: 110,
        car_name: 'QX-02',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622008692259-car.jpg',
        regular_ride_without_discount: '48.88',
        schedule_ride_without_discount: '48.88',
        base_fare: 1,
        estimated_fare_distance: '10.17',
        estimated_fare_time: '35.43',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.28',
        qudos_service_fee: '2.28',
        business_name: null,
    },
    {
        value_regular: '56.92',
        value_scheduled: '56.92',
        car_type: 111,
        car_name: 'AA-1',
        car_image: '',
        regular_ride_without_discount: '56.92',
        schedule_ride_without_discount: '56.92',
        base_fare: 5,
        estimated_fare_distance: '10.17',
        estimated_fare_time: '39.09',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.66',
        qudos_service_fee: '2.66',
        business_name: null,
    },
    {
        value_regular: '23.82',
        value_scheduled: '23.82',
        car_type: 112,
        car_name: 'xs-007',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1622206816149-road-sunset.jpg',
        regular_ride_without_discount: '23.82',
        schedule_ride_without_discount: '23.82',
        base_fare: 10,
        estimated_fare_distance: '7.82',
        estimated_fare_time: '4.89',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '1.11',
        qudos_service_fee: '1.11',
        business_name: null,
    },
    {
        value_regular: '48.69',
        value_scheduled: '94.62',
        car_type: 113,
        car_name: 'ZZ cars',
        car_image: '',
        regular_ride_without_discount: '48.69',
        schedule_ride_without_discount: '94.62',
        base_fare: 6,
        estimated_fare_distance: '12.13',
        estimated_fare_time: '27.12',
        sales_tax: '0.68',
        black_car_fund: '0.54',
        estimated_tolls: '0.00',
        estimated_surcharges: '45.25',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.22',
        qudos_service_fee: '2.22',
        business_name: null,
    },
    {
        value_regular: '51.80',
        value_scheduled: '51.80',
        car_type: 114,
        car_name: 'CC123',
        car_image: '',
        regular_ride_without_discount: '51.80',
        schedule_ride_without_discount: '51.80',
        base_fare: 5,
        estimated_fare_distance: '10.17',
        estimated_fare_time: '34.21',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.42',
        qudos_service_fee: '2.42',
        business_name: null,
    },
    {
        value_regular: '46.67',
        value_scheduled: '46.67',
        car_type: 115,
        car_name: 'CX12',
        car_image: '',
        regular_ride_without_discount: '46.67',
        schedule_ride_without_discount: '46.67',
        base_fare: 5,
        estimated_fare_distance: '10.17',
        estimated_fare_time: '29.32',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '2.18',
        qudos_service_fee: '2.18',
        business_name: null,
    },
    {
        value_regular: '66.28',
        value_scheduled: '86.09',
        car_type: 116,
        car_name: 'Q7',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1623042484574-pexels-photo-1274260-(1).jpeg',
        regular_ride_without_discount: '66.28',
        schedule_ride_without_discount: '86.09',
        base_fare: 5,
        estimated_fare_distance: '8.68',
        estimated_fare_time: '29.81',
        sales_tax: '0.52',
        black_car_fund: '0.52',
        estimated_tolls: '0.00',
        estimated_surcharges: '19.57',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '21.75',
        qudos_service_fee: '21.75',
        business_name: null,
    },
    {
        value_regular: '43.46',
        value_scheduled: '43.46',
        car_type: 117,
        car_name: 'Z123',
        car_image: 'https://qudos-s3.s3.amazonaws.com/cars/car_images/qudos-1623050067342-23.jpg',
        regular_ride_without_discount: '43.46',
        schedule_ride_without_discount: '43.46',
        base_fare: 5,
        estimated_fare_distance: '9.55',
        estimated_fare_time: '27.12',
        sales_tax: '0.50',
        black_car_fund: '0.50',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.79',
        qudos_service_fee: '0.79',
        business_name: null,
    },
    {
        value_regular: '43.07',
        value_scheduled: '43.07',
        car_type: 118,
        car_name: 'AZ-01',
        car_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        regular_ride_without_discount: '43.07',
        schedule_ride_without_discount: '43.07',
        base_fare: 5,
        estimated_fare_distance: '8.68',
        estimated_fare_time: '27.37',
        sales_tax: '0.62',
        black_car_fund: '0.62',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.79',
        qudos_service_fee: '0.79',
        business_name: null,
    },
    {
        value_regular: '29.96',
        value_scheduled: '32.94',
        car_type: 119,
        car_name: 'ZY Cars',
        car_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        regular_ride_without_discount: '29.96',
        schedule_ride_without_discount: '32.94',
        base_fare: 4,
        estimated_fare_distance: '6.26',
        estimated_fare_time: '19.55',
        sales_tax: '0.07',
        black_car_fund: '0.07',
        estimated_tolls: '0.00',
        estimated_surcharges: '2.98',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.00',
        qudos_service_fee: '0.00',
        business_name: null,
    },
    {
        value_regular: '24.40',
        value_scheduled: '24.40',
        car_type: 120,
        car_name: 'zyx',
        car_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        regular_ride_without_discount: '24.40',
        schedule_ride_without_discount: '24.40',
        base_fare: 4,
        estimated_fare_distance: '8.06',
        estimated_fare_time: '12.22',
        sales_tax: '0.06',
        black_car_fund: '0.06',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.00',
        qudos_service_fee: '0.00',
        business_name: null,
    },
    {
        value_regular: '32.45',
        value_scheduled: '32.45',
        car_type: 121,
        car_name: 'Car Classic & Car Litee',
        car_image: 'https://s3-us-west-2.amazonaws.com/qudos-s3/car_images/default.png',
        regular_ride_without_discount: '32.45',
        schedule_ride_without_discount: '32.45',
        base_fare: 5,
        estimated_fare_distance: '7.90',
        estimated_fare_time: '19.55',
        sales_tax: '0.00',
        black_car_fund: '0.00',
        estimated_tolls: '0.00',
        estimated_surcharges: '0.00',
        pet_friendly_car: 0,
        through_door_service: 0,
        qudos_service_fee_schedule: '0.00',
        qudos_service_fee: '0.00',
        business_name: null,
    },
];
