<div class="modal-dialog modal-dialog-centered modal-xl" role="document" style="width: 750px">
    <div class="modal-content select-cards">
        <div class="modal-body px-0">
            <div class="ps-header mt-4 px-4 mx-2 row">
                <div class="col-auto p-0 mr-auto title">Select Payment Method</div>
                <div class="col-auto my-auto">
                    <button class="btn btn-outline-primary p-add-button btn-md" (click)="addCard()">Add Card</button>
                    <div class="search_input">
                        <input [(ngModel)]="searchCard" type="search" class="form-control" placeholder="Search" />
                    </div>
                </div>
            </div>
            <div class="ps-body mt-3">
                <div class="ps-nav px-4">
                    <div
                        *ngIf="cardsService.userCardPaymentOption !== 'rider'"
                        class="mx-2 pb-2 cursor-pointer"
                        [class.active]="showCardsType === 'corporate'"
                        (click)="setActiveTab('corporate')"
                    >
                        Corporate Card
                    </div>
                    <div
                        *ngIf="cardsService.userCardPaymentOption !== 'rider'"
                        class="mx-2 pb-2 cursor-pointer"
                        [class.active]="showCardsType === 'corporate_wallet'"
                        (click)="setActiveTab('corporate_wallet')"
                    >
                        Corporate Wallet
                    </div>
                    <div
                        *ngIf="cardsService.userCardPaymentOption !== 'corporate'"
                        class="mx-2 pb-2 cursor-pointer"
                        [class.active]="showCardsType === 'rider'"
                        (click)="setActiveTab('rider')"
                    >
                        Rider Card
                    </div>
                </div>
                <hr />
                <div class="ps-table">
                    <table class="table mb-0" *ngIf="showCardsType === 'corporate'">
                        <thead>
                            <tr>
                                <th scope="col" class="fc">ID</th>
                                <!-- <th scope="col">Users</th> -->
                                <th scope="col">Card Type</th>
                                <th scope="col">Card Number</th>
                                <th scope="col">Date</th>
                                <th scope="col">&nbsp; Default</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="corporateCards.cards.length > 0">
                            <tr
                                class="content"
                                *ngFor="let card of corporateCards.cards | StringFilter: 'last4_digits':searchCard; let i = index"
                                (click)="selectThisCard('ci' + i)"
                            >
                                <td class="fc">{{ card.id }}</td>
                                <td>{{ card.card_type }}</td>
                                <td>**{{ card.last4_digits }}</td>
                                <td>{{ card.card_added_on | date: 'MM/dd/yyyy ' }}</td>
                                <td>
                                    <div *ngIf="card.default_card" class="badge badge-secondary default_card text-white py-1 px-2">Default</div>
                                    <button *ngIf="!card.default_card" (click)="setDefaultCard(card.id)" class="cardDefaultLabel">
                                        Make Default
                                    </button>
                                </td>
                                <td>
                                    <input
                                        name="corporateCard"
                                        [checked]="card.default_card ? true : false"
                                        [value]="card.id"
                                        type="radio"
                                        (change)="selected_card_id = card.id"
                                        [id]="'ci' + i"
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="(corporateCards.cards | StringFilter: 'last4_digits':searchCard).length === 0 && !cardLoading">
                            <tr>
                                <td colspan="6" class="text-center cursor-default">No Card Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="cardLoading && corporateCards.cards.length == 0">
                            <tr>
                                <td colspan="6" class="text-center">
                                    <app-loading type="placeholder"></app-loading>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <table class="table mb-0" *ngIf="showCardsType === 'rider'">
                        <thead>
                            <tr>
                                <th scope="col" class="fc">ID</th>
                                <th scope="col">Card Type</th>
                                <th scope="col">Card Number</th>
                                <th scope="col">Date</th>
                                <th scope="col">&nbsp; Default</th>
                                <th scope="col">Action</th>
                            </tr>
                        </thead>
                        <tbody *ngIf="riderCards.cards.length > 0">
                            <tr
                                class="content"
                                *ngFor="let card of riderCards.cards | StringFilter: 'last4_digits':searchCard; let i = index"
                                (click)="selectThisCard('rci' + i)"
                            >
                                <td class="fc">{{ card.id }}</td>
                                <td>{{ card.card_type }}</td>
                                <td>**{{ card.last4_digits }}</td>
                                <td>{{ card.card_added_on | date: 'MM/dd/yyyy ' }}</td>
                                <td>
                                    <div *ngIf="card.default_card" class="badge badge-secondary default_card text-white py-1 px-2">Default</div>
                                    <button *ngIf="!card.default_card" (click)="setDefaultCard(card.id)" class="cardDefaultLabel">
                                        Make Default
                                    </button>
                                </td>
                                <td>
                                    <input
                                        name="riderCard"
                                        type="radio"
                                        [checked]="card.default_card ? true : false"
                                        [value]="card.id"
                                        (change)="selected_card_id = card.id"
                                        [id]="'rci' + i"
                                    />
                                </td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="(riderCards.cards | StringFilter: 'last4_digits':searchCard).length === 0 && !cardLoading">
                            <tr>
                                <td colspan="6" class="text-center cursor-default">No Card Found</td>
                            </tr>
                        </tbody>
                        <tbody *ngIf="cardLoading && riderCards.cards.length == 0">
                            <tr>
                                <td colspan="6" class="text-center">
                                    <app-loading type="placeholder"></app-loading>
                                </td>
                            </tr>
                        </tbody>
                    </table>

                    <div *ngIf="showCardsType === 'corporate_wallet'" class="fs-13 mt-4 mb-5 py-3 px-4">
                        <ng-container *ngIf="balanceLoading">
                            <app-loading></app-loading>
                        </ng-container>
                        <div *ngIf="!balanceLoading" class="d-flex">
                            <div class="text-dark-blue mx-2" style="width: 150px">Confirm Payment</div>
                            <div class="ml-3 d-flex flex-fill">
                                <mat-checkbox [(ngModel)]="confirm_wallet_transaction" [disabled]="amount > available_wallet_balance"></mat-checkbox>
                                <div
                                    class="ml-3 cursor-pointer text-dark-grey"
                                    (click)="confirm_wallet_transaction = amount < available_wallet_balance"
                                >
                                    <div>
                                        Pay
                                        <b>{{ amount | currency: 'USD':'symbol':utilityService.show_currency_precision }} USD</b>
                                        from available wallet balance of
                                        <span [class.text-danger]="amount > available_wallet_balance">
                                            <b>
                                                {{ available_wallet_balance | currency: 'USD':'symbol':utilityService.show_currency_precision }} USD
                                            </b>
                                        </span>
                                        .
                                    </div>
                                    <div class="mt-2 text-danger" *ngIf="amount > available_wallet_balance">
                                        No sufficient balance in your wallet to perform this request, Please add funds to your wallet!
                                        <span class="text-blue ml-1 cursor-pointer" (click)="event.emit({ add_funds: true })">Add funds?</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr style="margin: 0px !important" />

                <div class="ps-bottom" align="center">
                    <!-- Conditional buttons based on CARD selected for Rider or Corporate -->
                    <!-- Conditional buttons based on Ride selected for Corporate Card (Now or later) -->
                    <!-- <ng-container *ngIf="booking.card_user_type == 1">
                        
                        <button class="btn btn-secondary border-0" (click)="BookRideRightNow()" *ngIf="paymentForNow">Select Payment</button>

                        <button class="btn btn-secondary border-0" (click)="BookRideLater()" *ngIf="paymentForLater">Select Payment</button>
                    </ng-container> -->

                    <!-- Conditional buttons based on CARD selected for Rider , In this case we need to Ask for Authorization -->
                    <!-- <ng-container *ngIf="booking.card_user_type == 0">
                        <button class="btn btn-secondary border-0" (click)="checkRiderAuthorization()">Select Payment</button>
                    </ng-container> -->

                    <button
                        class="btn btn-secondary border-0"
                        [disabled]="showCardsType === 'corporate_wallet' && !confirm_wallet_transaction"
                        (click)="onSelect()"
                    >
                        Select Payment
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
