export interface IAssignedTrip {
    driver_id: number;
    user_name: string;
    user_mobile: string;
    pickup_location: string;
    pickup_time: string;
    pickup_date: string;
    dropoff_location: string;
    pickup_latitude: string;
    pickup_longitude: string;
    dropoff_latitude: string;
    dropoff_longitude: string;
    invoice_number: number;
    trip_leg_id: number;
    mas_trip_id: number;
    requested_driver_names: string;
    requested_driver_mobile: string;
    ride_status: number;
    offset: number;
    declined_driver?: any;
    sent_to: string;
    notes?: any;
    notes_id?: any;
    ride_category: number;
    miles: number;
    trip_mileage: number;
    congestion_tax: number;
    car_type: number;
    third_party_amount: number;
    will_call: number;
    ride_milestone: number;
    zip_code: string;
    ride_estimate_time: string;
    trip_leg_sequence: number;
    pickup_datetime: Date | any;
    ride_status_text?: string;
    paid: any;
}

// 0 -- not a filter, 1 -- confirmed , 2 -- declined , 3 -- pending, 4 -- completed, 5 -- cancelled, 6 -- dispatched 7--> live milestone 8 --> completed trip. (edited)

export enum Assinged_Ride_Status {
    NONE = 0,
    CONFIRMED = 1,
    DECLINED = 2,
    PENDING = 3,
    COMPLETED = 4,
    CANCELLED = 5,
    DISPATCHED = 6,
    LIVE_MILESTONE = 7,
    MILESTONE_COMPLETED = 8,
}

export const Assinged_Ride_Status_Map = {
    0: '',
    1: 'Confirmed',
    2: 'Declined',
    3: 'Pending',
    4: 'Completed',
    5: 'Cancelled',
    6: 'Dispatched',
    7: 'Live Milestone',
    8: 'Completed',
};

// Milestone Section

// 1 --> start pickup 2 --> arrive at pickup 3 --> start trip 4 --> arrive at drop off 5 --> end trip
export enum MileStone {
    START_PICKUP = 1,
    ARRIVE_AT_PICKUP = 2,
    START_TRIP = 3,
    ARRIVE_AT_DROPOFF = 4,
    COMPLETED = 5,
    NOSHOW = 6,
    // END_TRIP = 5,
}

export const MileStone_Map = {
    1: 'Start Pickup',
    2: 'At Pickup',
    3: 'Start Trip',
    4: 'At Dropoff',
    // 5: 'Completed',
    5: 'End Trip',
    6: 'No Show',
};

export const Milestone_Display_Label_Map = {
    0: 'Confirmed',
    1: 'Picking Up',
    2: 'Arrived',
    3: 'En Route',
    4: 'At Dropoff',
    5: 'Completed',
};

export const MileStone_Class = {
    1: 'bg-theme btn-primary',
    2: 'bg-green btn-success',
    3: 'bg-orange btn-warning',
    4: 'bg-green btn-success',
    5: 'bg-orange btn-warning',
};

export const Assigned_Status_Text_Class = {
    pending: 'text-dark-blue',
    declined: 'text-red',
    confirmed: 'text-green',
    cancelled: 'text-red',
    completed: 'text-orange',
};

export const Milestone_Update_Confirmation = {
    1: 'You are starting to pickup the rider of this trip, please confirm',
    2: 'You arrived at the pickup location, please confirm',
    3: 'You are starting this trip, please confirm',
    4: 'You arrived at the drop off location, please confirm',
    5: 'You are completing this trip, please confirm',
    6: 'No one shows at pickup location, please confirm',
};

export const TRIP_STATUS_BG_COLOR = {
    Pending: 'bg-dark-blue',
    Confirmed: 'bg-theme',
    'Picking Up': 'bg-dark-blue',
    Arrived: 'bg-orange',
    'En Route': 'bg-theme',
    'At Dropoff': 'bg-dark-blue',
    Completed: 'bg-orange',
};
