import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'CustomRideFilter',
})
export class CustomRideFilter implements PipeTransform {
    transform(list: Array<Object>, filterRideType: string): any {
        if (!list) return null;
        if (!filterRideType) return list;

        if (filterRideType === 'scheduled') {
            return list.filter((item) => {
                return item['is_schedule'] === 1 || false;
            });
        } else if (filterRideType == 'on-demand') {
            return list.filter((item) => {
                return item['is_schedule'] === 0 || false;
            });
        } else if (filterRideType == 'cancelled') {
            const cancel_status = ['Cancelled', 'Missed'];
            return list.filter((item) => {
                const rideStatus = this.getRideStatus(item['ride_status'], item['request_status'], item['is_active']);
                return cancel_status.includes(rideStatus);
            });
        } else if (filterRideType == 'ongoing' || filterRideType == 'upcoming') {
            const status: Array<string> = ['Accepted', 'Assigning', 'Picking Up', 'Arrived', 'En Route'];
            return list.filter((item) => {
                const rideStatus = this.getRideStatus(item['ride_status'], item['request_status'], item['is_active']);
                return status.includes(rideStatus);
            });
        } else if (filterRideType === 'mas_completed' || filterRideType === 'mas_on_demand') {
            return list.filter((item: any) => item?.third_party_ride === 1);
        } else if (filterRideType === 'dispatched') {
            const status: Array<string> = ['Accepted', 'Assigning', 'Picking Up', 'Arrived', 'En Route'];
            return list.filter((item: any) => {
                const rideStatus = this.getRideStatus(item['ride_status'], item['request_status'], item['is_active']);
                return status.includes(rideStatus) && item?.third_party_ride === 1;
            });
        } else return list;
    }

    // index->ride_status, req->request_status, is_active->is_active
    public getRideStatus(ride_status: any, request_status: any, is_active: any) {
        switch (ride_status) {
            case 0:
                if (request_status == 1) {
                    return 'Accepted';
                } else if (request_status == 10) {
                    //return "Lapsed";
                    return 'Missed';
                } else if (request_status == null && is_active == 0) {
                    return 'Missed';
                } else {
                    return 'Assigning';
                }
            case 1:
                return 'Picking Up';
            case 2:
                return 'Arrived';
            case 3:
                return 'En Route';
            case 4:
                return 'Completed';
            case 5:
                //return "Cancelled By Driver";
                return 'Cancelled';
            case 6:
                //return "Cancelled By Rider";
                return 'Cancelled';
            case 7:
                //return "Cancelled By Rider";
                return 'Cancelled';
            case 8:
                //return "Unsuccessful Payment";
                return 'Unsuccessful';
            case 9:
                //return "Cancelled by Admin";
                return 'Cancelled';
            case 11:
                //return "Cancelled By Corporate";
                return 'Cancelled';
            default:
                return ride_status + ' ' + request_status;
        }
    }
}
