<div class="modal-dialog modal-dialog-centered modal-lg touch-popup recalculate" role="document">
    <div class="modal-content border-0">
        <div class="row" [class.justify-content-center]="!is_mas_trip">
            <div class="col-md-6 col-sm-6 fare-estimate-border">
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 medium-text">
                        <div class="text-center">
                            <span class="dark-color">Fare Estimates</span>
                            <span class="mas ml-1" *ngIf="is_mas_trip">MAS</span>
                        </div>
                    </div>
                </div>

                <div class="touch-content text-center dark-color" align="center">
                    <div class="text-grey my-3">New Total Fare</div>
                    <div class="fs-26 fw-600 dark-color my-3">
                        <div *ngIf="recalculate_price_loading" align="center">
                            <div class="w-25">
                                <app-loading height="20px" borderRadius="2px" type="placeholder"></app-loading>
                            </div>
                        </div>
                        <ng-container *ngIf="!recalculate_price_loading">
                            <ng-container *ngIf="recalculate_type === 'now'">
                                {{ recalculate_state?.value_regular | currency: 'USD' }}
                            </ng-container>
                            <ng-container *ngIf="recalculate_type === 'later'">
                                {{ recalculate_state.value_scheduled | currency: 'USD' }}
                            </ng-container>
                        </ng-container>
                    </div>
                    <div
                        class="fs-14 dark-color deducted-price my-3"
                        *ngIf="!totalGrossValue"
                        [hidden]="
                            (recalculate_type === 'now' && recalculate_state?.value_regular == fare_breakdown?.value_regular) ||
                            (recalculate_type === 'later' && recalculate_state?.value_scheduled == fare_breakdown?.value_scheduled) ||
                            totalGrossValue
                        "
                    >
                        <ng-container *ngIf="recalculate_type === 'now'">
                            {{ fare_breakdown?.value_regular | currency: 'USD' }}
                        </ng-container>
                        <ng-container *ngIf="recalculate_type === 'later'">
                            {{ fare_breakdown.value_scheduled | currency: 'USD' }}
                        </ng-container>
                    </div>

                    <div class="fs-14 dark-color deducted-price my-3" *ngIf="totalGrossValue">
                        <ng-container *ngIf="recalculate_type === 'now'">
                            {{ fare_breakdown?.value_regular | currency: 'USD' }}
                        </ng-container>
                        <ng-container *ngIf="recalculate_type === 'later'">
                            {{ fare_breakdown.value_scheduled | currency: 'USD' }}
                        </ng-container>
                    </div>

                    <div align="center" class="my-4">
                        <div class="divider"></div>
                    </div>

                    <div class="mt-3 mb-4">
                        <div class="text-grey mb-3">Select new fare</div>
                        <ng-container *ngIf="!ismultipliear">
                            <ng-container *ngIf="!custom_recalculate_multiplier">
                                <select
                                    name="recalculate"
                                    [(ngModel)]="fare_factor"
                                    class="touch-input pl-2 w-25"
                                    (ngModelChange)="get_new_fare_estimateion(fare_factor)"
                                >
                                    <option *ngFor="let option of recalculate_options" [value]="option.fare_factor">
                                        <span class="value-regular">${{ option.value_regular | number: '1.2-2' }}</span>
                                    </option>
                                </select>
                                <i class="fa fa-chevron-down" aria-hidden="true"></i>
                            </ng-container>
                            <div *ngIf="custom_recalculate_multiplier" class="d-flex justify-content-center">
                                <div>
                                    <input
                                        name="recalculate"
                                        type="text"
                                        [(ngModel)]="fare_factor"
                                        step="0.1"
                                        class="text-center left touch-input"
                                        (keydown)="select_button_flag = true"
                                        [class.error]="fare_factor < 0"
                                    />
                                </div>
                                <div class="right touch-input right align-self-center my-auto" [class.error]="fare_factor < 0">X</div>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="ismultipliear">
                            <div class="d-flex justify-content-center">
                                <div class="touch-input">
                                    <app-loading height="20px" borderRadius="2px" type="placeholder"></app-loading>
                                </div>
                            </div>
                        </ng-container>
                        <div class="mt-3">
                            <div class="cursor-pointer text-blue" (click)="toggle_recalculate_type()">
                                <span *ngIf="!custom_recalculate_multiplier">Custom</span>
                                <span *ngIf="custom_recalculate_multiplier">Back</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="light light-color" />
            <div class="col-md-6 col-sm-6 mb-3">
                <!-- Payout Breakdown -->
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 medium-text">
                        <div class="text-center">
                            <span class="dark-color">Payout Breakdown</span>
                        </div>
                    </div>
                </div>
                <div class="touch-content text-center dark-color" align="center">
                    <!-- Corporate Add On -->
                    <ng-container>
                        <div class="text-grey mt-3">Corporate Add On</div>
                        <div class="dark-color">
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div class="w-50">
                                    <app-loading height="16px" borderRadius="2px" type="placehxolder"></app-loading>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="fs-16 fw-600">
                                    {{ savedcorporateAddOnValue }}
                                </div>
                                <div>
                                    <mat-icon
                                        class="text-dark-blue fs-16 stop-propagation cursor-pointer"
                                        data-toggle="dropdown"
                                        id="dropdown"
                                        (click)="openModal()"
                                    >
                                        edit
                                    </mat-icon>
                                    <div class="dropdown-menu text-grey" aria-labelledby="download">
                                        <div class="corporate-add-on">
                                            <div class="box" stop-propagation>
                                                <div class="d-flex">
                                                    <span class="input-group-text border-0 fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                                                    <input
                                                        #modalInput
                                                        (click)="handleInputClick()"
                                                        placeholder="0"
                                                        onkeypress="return /[0-9]|\./i.test(event.key)"
                                                        type="number"
                                                        [formControl]="corporateAddOnValue"
                                                        class="border-0 fs-16 fw-600 text-dark-blue"
                                                    />
                                                </div>
                                                <div class="label mt-2 fw-600 fs-12 text-center">Corporate Add On</div>
                                            </div>
                                            <div class="d-flex justify-content-between m-3">
                                                <div class="dismiss-button cursor-pointer" (click)="dismissCorporateAddon()">Dismiss</div>
                                                <div
                                                    class="apply-button cursor-pointer"
                                                    (click)="corporateAddOn()"
                                                    [class.disabled]="
                                                        corporateAddOnValue.invalid ||
                                                        corporateAddOnValue?.value === savedcorporateAddOnValue ||
                                                        corporateAddOnValue?.value === null
                                                    "
                                                >
                                                    Apply
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Driver payout -->
                    <ng-container>
                        <div class="text-grey mt-3">Driver Payout</div>
                        <div class="dark-color">
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div class="w-50">
                                    <app-loading height="16px" borderRadius="2px" type="placehxolder"></app-loading>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="fs-16 fw-600">
                                    <ng-container *ngIf="!recalculate_price_loading">
                                        {{ recalculate_state?.submerchant_driver_payout | currency: 'USD' }}
                                    </ng-container>
                                </div>
                                <div
                                    class="dark-color deducted-price fs-14 fw-600 ml-2 align-self-center"
                                    [hidden]="recalculate_state?.submerchant_driver_payout == fare_breakdown?.submerchant_driver_payout"
                                >
                                    {{ fare_breakdown?.submerchant_driver_payout | currency: 'USD' }}
                                </div>
                            </div>
                        </div>
                    </ng-container>

                    <!-- Car Base Take -->
                    <ng-container>
                        <div class="text-grey mt-3">Car Base Take</div>

                        <div class="dark-color">
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div class="w-50">
                                    <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="fs-16 fw-600">
                                    <ng-container *ngIf="!recalculate_price_loading">
                                        <ng-container *ngIf="recalculate_type === 'now'">
                                            {{ recalculate_state?.submerchant_client_payout | currency: 'USD' }}
                                        </ng-container>
                                        <ng-container *ngIf="recalculate_type === 'later'">
                                            {{ recalculate_state?.submerchant_client_payout_schedule | currency: 'USD' }}
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div
                                    class="dark-color deducted-price fs-14 fw-600 ml-2 align-self-center"
                                    [hidden]="
                                        (recalculate_type === 'now' &&
                                            recalculate_state?.submerchant_client_payout == fare_breakdown?.submerchant_client_payout) ||
                                        (recalculate_type === 'later' &&
                                            recalculate_state?.submerchant_client_payout_schedule ==
                                                fare_breakdown?.submerchant_client_payout_schedule)
                                    "
                                >
                                    <ng-container *ngIf="recalculate_type === 'now'">
                                        {{ fare_breakdown?.submerchant_client_payout | currency: 'USD' }}
                                    </ng-container>
                                    <ng-container *ngIf="recalculate_type === 'later'">
                                        {{ fare_breakdown.submerchant_client_payout_schedule | currency: 'USD' }}
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                    <!-- Qudos Fee -->
                    <ng-container>
                        <div class="text-grey mt-3">Qudos Fee</div>
                        <div class="dark-color">
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div class="w-50">
                                    <app-loading height="16px" borderRadius="2px" type="placeholder"></app-loading>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="fs-16 fw-600">
                                    <ng-container *ngIf="!recalculate_price_loading">
                                        <ng-container *ngIf="recalculate_type === 'now'">
                                            {{ recalculate_state?.qudos_service_fee | currency: 'USD' }}
                                        </ng-container>
                                        <ng-container *ngIf="recalculate_type === 'later'">
                                            {{ recalculate_state.qudos_service_fee_schedule | currency: 'USD' }}
                                        </ng-container>
                                    </ng-container>
                                </div>
                                <div
                                    class="dark-color deducted-price fs-14 fw-600 ml-2 align-self-center"
                                    [hidden]="
                                        (recalculate_type === 'now' && recalculate_state?.qudos_service_fee == fare_breakdown?.qudos_service_fee) ||
                                        (recalculate_type === 'later' &&
                                            recalculate_state?.qudos_service_fee_schedule == fare_breakdown?.qudos_service_fee_schedule)
                                    "
                                >
                                    <ng-container *ngIf="recalculate_type === 'now'">
                                        {{ fare_breakdown?.qudos_service_fee | currency: 'USD' }}
                                    </ng-container>
                                    <ng-container *ngIf="recalculate_type === 'later'">
                                        {{ fare_breakdown.qudos_service_fee_schedule | currency: 'USD' }}
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
                <!-- Ride Incentives -->
                <div class="touch-header pt-2">
                    <div class="mx-5 my-4 medium-text">
                        <div class="text-center">
                            <span class="dark-color">Ride Incentives</span>
                        </div>
                    </div>
                </div>
                <div class="touch-content text-center dark-color" align="center">
                    <!-- Ride Discount -->
                    <ng-container>
                        <div class="text-grey mt-3">Ride Discount</div>
                        <div class="dark-color">
                            <div *ngIf="recalculate_price_loading" align="center">
                                <div class="w-50">
                                    <app-loading height="16px" borderRadius="2px" type="placehxolder"></app-loading>
                                </div>
                            </div>
                            <div class="d-flex justify-content-center">
                                <div class="fs-16 fw-600">
                                    {{ rideDiscountControl.value == null ? '$00.00' : '$' + rideDiscountControl.value }}
                                </div>
                                <div>
                                    <mat-icon
                                        class="text-dark-blue fs-16 stop-propagation cursor-pointer"
                                        data-toggle="dropdown"
                                        id="dropdown"
                                        (click)="openModalRide()"
                                    >
                                        lock
                                    </mat-icon>
                                    <div class="dropdown-menu text-grey" aria-labelledby="download">
                                        <!-- Pin Input -->
                                        <div class="corporate-add-on" *ngIf="!idPinValidate">
                                            <div class="box" stop-propagation>
                                                <div class="text-center">
                                                    <input
                                                        #modalInputDiscountPin
                                                        placeholder="0000"
                                                        onkeypress="return /[0-9]|\./i.test(event.key)"
                                                        type="text"
                                                        [formControl]="discountPin"
                                                        maxlength="4"
                                                        class="border-0 fs-16 fw-600 text-dark-blue"
                                                    />
                                                </div>
                                                <div class="label mt-2 fw-600 fs-12 text-center">ENTER PIN</div>
                                            </div>
                                            <div class="d-flex justify-content-between m-3">
                                                <div class="dismiss-button cursor-pointer" (click)="dismissDiscount()">Dismiss</div>
                                                <div class="apply-button cursor-pointer" stop-propagation (click)="applyDiscount('pin')">Apply</div>
                                            </div>
                                        </div>

                                        <!-- Ride Discount Input -->
                                        <div class="corporate-add-on" *ngIf="idPinValidate">
                                            <div class="box" stop-propagation>
                                                <div class="d-flex">
                                                    <span class="input-group-text border-0 fs-16 fw-600 text-dark-blue" id="basic-addon1">$</span>
                                                    <input
                                                        #modalInputDiscount
                                                        placeholder="0"
                                                        onkeypress="return /[0-9]|\./i.test(event.key)"
                                                        type="number"
                                                        [formControl]="rideDiscountControl"
                                                        class="border-0 fs-16 fw-600 text-dark-blue"
                                                    />
                                                </div>
                                                <div class="label mt-2 fw-600 fs-12 text-center">ENTER DISCOUNT</div>
                                            </div>
                                            <div class="d-flex justify-content-between m-3">
                                                <div class="dismiss-button cursor-pointer" (click)="dismissDiscount()">Dismiss</div>
                                                <div
                                                    class="apply-button cursor-pointer"
                                                    (click)="applyDiscount('discount')"
                                                    [class.disabled]="
                                                        rideDiscountControl.invalid ||
                                                        rideDiscountControl?.value === null ||
                                                        recalculate_state.submerchant_client_payout - recalculate_state.other_fees <=
                                                            rideDiscountControl?.value
                                                    "
                                                >
                                                    Apply
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ng-container>
                </div>
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom m-4" align="center">
            <button
                class="btn btn-secondary border-0 font-light btn-left m-2 re-calculate"
                (click)="bsModalRef.hide(); closeModal()"
                (click)="fare_factor = 1; select_button_flag = false; custom_recalculate_multiplier = false"
                [disabled]="recalculate_price_loading"
            >
                Cancel
            </button>

            <!-- Select button, Only Visible if custom recalculate option is on -->
            <ng-container *ngIf="select_button_flag">
                <button
                    class="btn btn-secondary border-0 font-light btn-right m-2 re-calculate"
                    (click)="get_new_fare_estimateion(fare_factor); select_button_flag = false"
                    [disabled]="recalculate_price_loading"
                >
                    Select
                </button>
            </ng-container>
            <ng-container *ngIf="!select_button_flag">
                <button
                    class="btn btn-secondary border-0 font-light btn-right m-2 re-calculate"
                    (click)="apply()"
                    [disabled]="recalculate_price_loading"
                >
                    Apply
                </button>
            </ng-container>
        </div>
    </div>
</div>
