<div class="touch-popup" role="document">
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-4 mx-md-5 my-4">
                <div class="medium-text dark-color">{{ title }}</div>
            </div>
        </div>

        <hr class="light" />
        <div class="bg-light-blue">
            <div class="touch-content mx-4 mx-md-5 my-4 py-2">
                <form [formGroup]="passengerForm">
                    <div class="d-flex my-2 justify-content-center">
                        <table>
                            <tr>
                                <td>
                                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">
                                        Passenger
                                        <span class="d-md-inline-block d-none">Name</span>
                                    </div>
                                </td>
                                <td>
                                    <div class="ml-2">
                                        <input
                                            [class.error]="passengerForm.get('name')?.touched && passengerForm.get('name')?.invalid"
                                            type="text"
                                            class="touch-input w-100 p-1 px-2"
                                            formControlName="name"
                                            placeholder="Passenger name here"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">
                                        Mobile
                                        <span class="d-md-inline-block d-none">Number</span>
                                    </div>
                                </td>
                                <td>
                                    <div
                                        class="ml-2 touch-tel-input"
                                        [class.error]="passengerForm.get('mobile')?.touched && passengerForm.get('mobile')?.invalid"
                                    >
                                        <ngx-intl-tel-input
                                            [cssClass]="'custom'"
                                            [attr.autocomplete]="false"
                                            [resetSearchContainer]="true"
                                            [preferredCountries]="preferredCountries"
                                            [enableAutoCountrySelect]="true"
                                            [enablePlaceholder]="true"
                                            [searchCountryFlag]="true"
                                            [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                            [selectFirstCountry]="false"
                                            [selectedCountryISO]="selected_country_iso"
                                            [maxLength]="10"
                                            [tooltipField]="TooltipLabel.Name"
                                            [phoneValidation]="true"
                                            [separateDialCode]="separateDialCode"
                                            name="phone"
                                            [inputId]="'edit-mobile'"
                                            formControlName="mobile"
                                            [customPlaceholder]="'Mobile Number'"
                                        ></ngx-intl-tel-input>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">Email</div>
                                </td>
                                <td>
                                    <div class="ml-2">
                                        <input
                                            [class.error]="passengerForm.get('email')?.touched && passengerForm.get('email')?.invalid"
                                            type="text"
                                            class="touch-input w-100 p-1 px-2"
                                            formControlName="email"
                                            placeholder="Email"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">Card</div>
                                </td>
                                <td>
                                    <div
                                        class="modalrow touch-input ml-2 p-0 StripeElement-light"
                                        [class.StripeElement--invalid]="cardError || (isCardTouched && isCardEmpty)"
                                    >
                                        <ngx-stripe-card
                                            [options]="cardOptions"
                                            [elementsOptions]="elementsOptions"
                                            (change)="onStripeCardChange($event)"
                                            (blur)="isCardTouched = true"
                                            #stripeElement
                                        ></ngx-stripe-card>
                                        <div class="col-sm-12 text-center my-2">
                                            <div id="card-errors" role="alert">{{ cardError }}</div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </form>
                <!-- <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div> -->
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom d-flex justify-content-center mx-5 my-3">
            <button class="btn btn-secondary btn-light text-white p-2 mr-2 br-6" (click)="bsModalRef.hide()">Cancel</button>
            <button
                [disabled]="passengerForm.invalid || cardError || isCardEmpty || isLoading"
                class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6"
                (click)="onSave()"
            >
                <ng-container *ngIf="!isLoading">Save</ng-container>
                <ng-container *ngIf="isLoading">Saving...</ng-container>
            </button>
        </div>
    </div>
</div>
