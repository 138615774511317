<div
    class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg touch-popup"
    role="document"
    style="min-width: 630px !important; max-width: fit-content !important"
>
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-5 my-4">
                <div class="medium-text dark-color">Rider's Phone Number</div>
            </div>
        </div>

        <hr class="light" />
        <div class="bg-light-blue pr-3 pr-md-5">
            <div class="touch-content mx-5 my-4 py-2">
                <div class="d-flex my-2 justify-content-end">
                    <div class="mx-2 align-self-center fs-13 dark-color fw-600">Mobile Number</div>
                    <div class="ml-2 touch-tel-input" [class.error]="phoneNumber?.touched && (!phoneNumber?.value || phoneNumber.invalid)">
                        <ngx-intl-tel-input
                            [cssClass]="'custom'"
                            [attr.autocomplete]="false"
                            [resetSearchContainer]="true"
                            [enableAutoCountrySelect]="true"
                            [enablePlaceholder]="true"
                            [searchCountryFlag]="true"
                            [selectFirstCountry]="false"
                            selectedCountryISO="US"
                            [maxLength]="10"
                            [tooltipField]="TooltipLabel.Name"
                            [phoneValidation]="true"
                            [separateDialCode]="separateDialCode"
                            name="phone_no"
                            [inputId]="'phone_no'"
                            [formControl]="phoneNumber"
                            [customPlaceholder]="'Mobile Number'"
                        ></ngx-intl-tel-input>
                    </div>
                </div>
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
            <button class="btn btn-secondary btn-light text-white p-2 mx-2 br-6" (click)="closePopup()">Cancel</button>
            <button
                class="btn btn-primary btn-right-secondary p-2 ml-2 br-6"
                [disabled]="!phoneNumber?.value || phoneNumber.invalid"
                (click)="apply()"
            >
                Apply
            </button>
        </div>
    </div>
</div>
