import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';

@Component({
    selector: 'app-rider-driver-header',
    templateUrl: './rider-driver-header.component.html',
    styleUrls: ['./rider-driver-header.component.scss'],
})
export class RiderDriverHeaderComponent implements OnInit {
    @Input() image: string;
    @Input() name: string;
    @Input() email: string;
    @Input() mobile: string;
    @Input() is_fav: boolean;
    @Input() device_type: string;
    @Input() is_mas_driver: boolean = false;
    @Input() driver_tlc_status: string;
    @Input() show_active_ride_label: boolean = false;

    @Output() report_issue: EventEmitter<any> = new EventEmitter<any>();
    @Output() add_to_fav: EventEmitter<any> = new EventEmitter<any>();
    @Output() remove_from_fav: EventEmitter<any> = new EventEmitter<any>();
    constructor() {}

    ngOnInit(): void {}
}
