import { environment } from 'src/environments/environment';
import { HttpService } from './../services/http/http.service';
import { Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { SocketioService } from '../services/socketio/socketio.service';
import { UtilityService } from '../core/services/utility/utility.service';
import { NavigationEnd, Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { debounceTime, filter, map } from 'rxjs/operators';
import { FormControl, FormGroup } from '@angular/forms';
import * as moment from 'moment';
import { MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { MaxRangeSelectionStrategy } from '../core/services/utility/roster-trip-date-strategy';
import { Notification, NotificationType } from '../models/notification.model';
import { MasAssignService } from './services/mas-assign/mas-assign.service';
import { NotificationService } from './services/notification/notification.service';
import { Driver } from '../models/driver.model';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { TripsService } from './services/trips/trips.service';
import { ISearchTrip } from '../models/trips.model';
import { SettingHeaderConfig } from '../core/services/utility/setting-header.config';
declare const jQuery: any;
const $: any = jQuery;

declare const google: any;

@Component({
    selector: 'app-corporate',
    templateUrl: './corporate.component.html',
    styleUrls: ['./corporate.component.scss'],
    providers: [
        { provide: 'rangeMax', useValue: 7 },
        { provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: MaxRangeSelectionStrategy },
    ],
})
export class CorporateComponent implements OnInit, OnDestroy {
    editMode: number = 0;
    isTrackingPage: boolean = true;
    topNavigation: boolean = false;
    loading: boolean = false;
    profile: any = {};
    pop: any = {};
    is_mas_dispatcher: number = 0;
    expand_bottom_notification_circle: boolean = false;
    expand_top_notification_circle: boolean = false;

    riderSearchForm: FormControl = new FormControl('');
    rider_loading: boolean = false;
    riderList: Array<any>;
    selected_rider: any;
    rider_mas_trip_ranger_picker: FormGroup = new FormGroup({
        start: new FormControl(moment()),
        end: new FormControl(moment().add(1, 'day')),
    });

    current_car_base: {
        business_name: string;
        image: string;
        admin_id: number;
    };

    totalNotifications: number = 0;
    driverDetails: any = {};
    public isSideMenuOpened: boolean = false;

    showRideButton: boolean = false;
    showEditImageIcon: boolean = false;
    clientInfo: any;
    web_access_token: string;

    // array store the url of the page that should not contain side nav bar within it!
    ignore_SideNav_Bar: Array<string> = ['/corporate/live-tracking', '/corporate/riderSignup'];
    mobileSize: boolean;
    ipadSize: boolean;

    listenNotificationInterval: any;

    driverLoading: boolean = false;
    drivers: Array<Driver> = [];
    driver_search_control: FormControl = new FormControl();
    driverSearchPopoverReference: PopoverDirective;
    riderSearchPopoverReference: PopoverDirective;

    trip_search_control: FormControl = new FormControl();
    tripSearchList: Array<ISearchTrip> = [];
    search_trip_loading: boolean = false;
    tripSearchPopoverReference: PopoverDirective;
    profileDropdownPopoverReference: PopoverDirective;
    searchAffiliation: string = '';
    searchControl: FormControl = new FormControl('');
    car_affiliation: Array<any>;
    selectedCorp: any;
    corp_Type: any;
    isSuperCorpAccess: boolean = false;

    constructor(
        public router: Router,
        private cookieService: CookieService,
        private httpService: HttpService,
        private socketService: SocketioService,
        public utilityService: UtilityService,
        private masAssignService: MasAssignService,
        private notificationService: NotificationService,
        private tripsService: TripsService
    ) {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
            if (event.url == '/corporate/live-tracking') {
                this.isTrackingPage = true;
                this.topNavigation = false;
            } else {
                this.topNavigation = true;
                this.isTrackingPage = false;
            }
        });
    }

    ngOnInit(): void {
        this.emitNotification();
        this.listenNotification();
        this.getCarAffiliation();
        // Notification Check Interval
        this.listenNotificationInterval = setInterval(() => {
            this.emitNotification();
        }, this.notificationService.notification_refresh_interval);

        this.locationPicker();
        this.selectedCorp = JSON.parse(localStorage.getItem('selectedCorp'));
        this.web_access_token = this.cookieService.get('access_token');
        if (this.web_access_token === undefined) {
            this.showCredentialsAlert();
        }

        this.clientInfo = JSON.parse(localStorage.getItem('clientInfo'));

        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));
        this.corp_Type = driverModel?.corp_type;
        this.is_mas_dispatcher = driverModel?.is_mas_dispatcher;
        if (!this.is_mas_dispatcher) {
            localStorage.removeItem('session_identifier');

            // Remove Unassigned, and also store the value of all trips for next operation
            SettingHeaderConfig.active_trips.tabs = SettingHeaderConfig.active_trips.tabs.filter((item, index) => {
                return item.title !== 'Unassigned';
            });

            // Replace first tab with All Trips
            const allTripsIndex = SettingHeaderConfig.active_trips.tabs?.findIndex((item) => item.title === 'All Trips');
            if (allTripsIndex !== -1) {
                const allTrips: { title: string; url: string } = SettingHeaderConfig.active_trips.tabs?.splice(allTripsIndex, 1)[0];
                SettingHeaderConfig.active_trips.tabs.unshift(allTrips);
            }
        }

        // JSON.parse(localStorage.getItem('driverdata')) ||
        if (driverModel) {
            this.driverDetails = driverModel;
            this.current_car_base = {
                business_name: this.clientInfo.business_name,
                image: this.clientInfo?.image,
                admin_id: this.clientInfo?.client_id,
            };
        } else {
            this.cookieService.delete('access_token');
            this.router.navigate['corporate_login'];
        }

        this.utilityService.newRideButtonChanges.subscribe((value) => {
            this.showRideButton = value;
        });

        this.getRiderList();
        this.riderSearchForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            if (value) value = value.trim();
            this.getRiderList(value);
        });

        this.utilityService.resetTripSearch.subscribe(() => {
            this.selected_rider = null;
        });

        $('#rider_trip_search_dropdown').on('hide.bs.dropdown', function (e: any) {
            if (e.clickEvent) {
                e.preventDefault();
            }
        });

        if (window.innerWidth <= 660) {
            this.mobileSize = true;
        }

        if (window.innerWidth <= 1200) {
            this.ipadSize = true;
        }

        this.notificationService.notificationCircle.subscribe((res: { position?: 'bottom' | 'top'; active: boolean }) => {
            if (res?.position === 'top') {
                this.expand_top_notification_circle = res?.active;
            } else {
                this.expand_bottom_notification_circle = res?.active;
            }
        });

        this.notificationService.get_notification_preference();

        // request desktop notification permission
        setTimeout(() => {
            Notification.requestPermission();
            this.askForGeolocationPermission();
        }, 5000);

        this.driver_search_control.valueChanges
            .pipe(
                debounceTime(500),
                map((value) => value?.trim())
            )
            .subscribe((value) => {
                this.getDrivers(value);
            });
        this.getDrivers();

        this.trip_search_control.valueChanges
            .pipe(
                debounceTime(500),
                map((value) => value?.trim())
            )
            .subscribe((value) => {
                this.searchTripById(value);
            });

        this.searchControl.valueChanges.subscribe((newVal) => {
            if (newVal?.trim() === this.searchAffiliation?.trim()) return;

            this.searchAffiliation = newVal?.trim();
            this.getCarAffiliation();
        });
    }

    @HostListener('window:resize', ['$event'])
    onResize(event) {
        if (event.target.innerWidth > 1000) {
            this.isSideMenuOpened = false;
        }

        if (window.innerWidth <= 1200) {
            this.ipadSize = true;

            if (event.target.innerWidth <= 660) {
                this.mobileSize = true;
            } else {
                this.mobileSize = false;
            }
        } else {
            this.ipadSize = false;
        }
    }

    navigateToTripRoster() {
        if (this.corp_Type == 1) {
            this.router.navigate(['/', 'corporate', 'active-trips', this.selectedCorp?.is_mas_dispatcher ? 'unassigned' : 'all-trips']);
        } else {
            this.router.navigate(['/', 'corporate', 'active-trips', this.is_mas_dispatcher ? 'unassigned' : 'all-trips']);
        }
    }

    public toggleSideMenu() {
        this.isSideMenuOpened = !this.isSideMenuOpened;
    }

    public showCredentialsAlert() {
        this.utilityService
            .confirm({
                heading: 'Session Expired!',
                description: 'Your session has expired, you are being logged out.',
                rightButtonText: 'Ok',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.utilityService.logout();
                }
            });
    }

    public logoutConfirm() {
        this.utilityService
            .confirm({
                heading: 'Logout',
                description: 'Are you sure want to logout?',
                leftButtonText: 'Cancel',
                rightButtonText: 'Logout',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.utilityService.logout();
                }
            });
    }

    public emitNotification() {
        this.socketService.emit('corporateNotification', { corporate_id: this.driverDetails.corporate_id });
    }

    /** @description - A variable for separation of first time fetch and interval based fetch
     * @reason - totalNotifications is 0 by default, so if we get few notifications from first time call of socket. then it'll fire the notification on every reload.
     *           But it should not generate the notification on first time assignment cause it's generally not new notification but data fetching only.
     *           So if initial_notification_fetch is true then only we can calculate the difference
     */
    initial_notification_fetch: boolean = false;
    public listenNotification() {
        this.socketService.on('corporateNotification').subscribe((data) => {
            if (this.initial_notification_fetch && this.totalNotifications < data.data.count) {
                const difference = data.data.count - this.totalNotifications;
                this.getNotificationObject(difference);
            }

            this.totalNotifications = data.data.count;
            this.initial_notification_fetch = true;
        });
    }

    public closeEdit() {
        this.editMode = 0;
    }

    public locationPicker() {
        let autocomplete = {};
        let autocompletesWraps = ['base_location'];

        autocompletesWraps.forEach((name: string, index: number) => {
            if ($('#' + name).length == 0) {
                return;
            }
            autocomplete[name] = new google.maps.places.Autocomplete($('#' + name + '.autocomplete')[0]);
            google.maps.event.addListener(autocomplete[name], 'place_changed', () => {
                const place = autocomplete[name].getPlace();
                if (!place.geometry) {
                    alert('Something Went Wrong!!');
                    return;
                }
                if (name == 'base_location') {
                    const latitude = place.geometry.location.lat();
                    const longitude = place.geometry.location.lng();
                    this.pop = {
                        latitude: latitude,
                        longitude: longitude,
                    };
                    this.pop.address = place.formatted_address;
                }
            });
        });
    }

    openProfileDetail() {
        this.utilityService.showModal('profile_detail');
        this.profile = JSON.parse(localStorage.getItem('corporateModel'));

        this.editMode = 0;

        if (!this.profile.is_approved) {
            this.pop.address = this.profile.address;
            this.pop.city = this.profile.city;
            this.pop.state = this.profile.state;
            this.pop.zipcode = this.profile.zipcode;
            this.pop.latitude = this.profile.latitude;
            this.pop.longitude = this.profile.longitude;
        } else {
            this.pop = this.profile;
        }
    }

    public editProfile() {
        this.editMode = 1;
        $('#editProfile').modal('show');
    }

    public submitDetails() {
        this.utilityService.loading = true;

        const payload = {
            web_access_token: this.web_access_token,
            address: this.pop.address,
            city: this.pop.city,
            state: this.pop.state,
            zipcode: this.pop.zipcode,
            latitude: this.pop.latitude,
            longitude: this.pop.longitude,
        };

        this.httpService.post(environment.urlC + 'edit_profile', payload).subscribe(
            (data) => {
                if (typeof data == 'string') data = JSON.parse(data);

                if (data.flag == 101) {
                    this.showCredentialsAlert();
                } else if (data.error || data.flag == 0) {
                    this.utilityService.loading = false;
                    this.utilityService.alert('error', data.error || data.message);
                    return;
                } else {
                    let driverModel = JSON.parse(localStorage.getItem('corporateModel'));

                    driverModel.zipcode = this.pop.zipcode;
                    driverModel.city = this.pop.city;
                    driverModel.state = this.pop.state;
                    driverModel.address = this.pop.address;
                    driverModel.latitude = this.pop.latitude;
                    driverModel.longitude = this.pop.longitude;

                    localStorage.setItem('corporateModel', JSON.stringify(driverModel));

                    this.editMode = 0;
                    this.utilityService.toast('success', 'Profile Updated successfully', '');
                    this.utilityService.loading = false;

                    this.closeEdit();
                    this.ngOnInit();
                }
            },
            (error) => {
                this.utilityService.alert('error', 'Something went Wrong!');
            }
        );
    }

    onClickOutlet() {
        if (this.isSideMenuOpened) this.isSideMenuOpened = this.isSideMenuOpened = false;
    }

    onNewRide() {
        this.router.navigate(['/', 'corporate', 'live-tracking']);
        this.utilityService.showNewRideButton(false);
    }

    on_provider_connect(auth_key: string, category: number = 1) {
        this.httpService
            .post(environment.urlC + 'third_party_integration', {
                web_access_token: this.web_access_token,
                auth_key,
                category,
            })
            .subscribe(
                (res: any) => {
                    if (res?.flag === 1320) {
                        this.utilityService.toast('success', res?.log);
                    } else {
                        this.utilityService.toast('warning', res?.log);
                    }
                    this.utilityService.hideModal('mas_login');
                },
                (err) => {
                    this.utilityService.toast('error', 'Something went wrong');
                    this.utilityService.hideModal('mas_login');
                }
            );
    }

    on_edit_car_affiliation() {
        this.utilityService.showModal('edit_car_affiliation');
    }

    update_car_base(carbase: any) {
        if (carbase?.admin_id != this.current_car_base?.admin_id) {
            this.httpService
                .post(environment.urlC + 'update_default_car_base_affiliation', {
                    web_access_token: this.web_access_token,
                    client_id: carbase?.admin_id,
                })
                .subscribe(
                    (res) => {
                        this.refetch_client_info();
                    },
                    (err) => {
                        this.utilityService.alert('error', err.message);
                    }
                );
        }
    }

    refetch_client_info() {
        const url_login_token = environment.urlC + 'get_info';
        this.httpService
            .post(url_login_token, {
                web_access_token: this.web_access_token,
            })
            .subscribe((resp) => {
                const clientInfo = resp?.clientInfo;
                localStorage.setItem('clientInfo', JSON.stringify(clientInfo));
                this.ngOnInit();
            });
    }

    async start_mas_session() {
        await this.utilityService.start_mas_session();
        this.utilityService.toggleMASUI.next(true);
    }

    private getRiderList(searchString: string = '') {
        this.riderList = [];
        this.rider_loading = true;
        const params = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            searchFlag: searchString ? 1 : 0,
            searchString: searchString,
        };

        this.httpService
            .post(environment.urlC + 'associated_user_list', params)
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                if (data.flag == 101) {
                    this.router.navigate(['/', 'corporate_login']);
                }
                if (data.flag == 502) {
                } else {
                    this.riderList = data.users;
                }
            })
            .add(() => {
                this.rider_loading = false;
            });
    }

    searchTripByRider() {
        if (!this.router.url.includes('live-tracking')) this.router.navigate(['/', 'corporate', 'live-tracking']);
        setTimeout(() => {
            const date = this.rider_mas_trip_ranger_picker.value;
            this.utilityService.searchTripByRider.next({
                date_range: date,
                selected_rider: this.selected_rider,
            });
            this.closeRiderDropdown();
        }, 0);
    }

    closeRiderDropdown() {
        $('#rider_date_menu').dropdown('toggle');
    }

    getNotificationObject(newNotificationCount: number = 1, refreshOffset: number = this.utilityService.default_display_filter) {
        this.notificationService
            .getNotification({
                web_access_token: this.cookieService.get('web_access_token'),
                limit: refreshOffset,
                offset: 0,
            })
            .subscribe((data: { flag: number; corporate_notificatons: Array<Notification> }) => {
                if (data.flag !== 807 && data.flag !== 101) {
                    const notifications = (data.corporate_notificatons || [])?.slice(0, newNotificationCount)?.reverse();
                    notifications.forEach((notification_object: Notification) => {
                        if (notification_object) {
                            if (notification_object?.type?.includes(NotificationType.MAS_ASSIGN)) {
                                this.masAssignService.mas_assigned_notification.next(notification_object);
                            }
                            this.notificationService.triggerNotification({ notification: notification_object });
                        }
                    });

                    // Update the notification list in notification page.
                    this.notificationService.syncNewNotification.next(data);
                }
            });
    }
    getDrivers(searchString: string = '') {
        this.driverLoading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: 3, // All Associated Driver
        };

        if (searchString) {
            params.searchFlag = 1;
            params.searchString = searchString;
        }

        this.httpService
            .post(environment.urlC + 'get_all_driver', params)
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag !== 101 && data.flag !== 807) {
                    this.drivers = data?.drivers;
                }
            })
            .add(() => {
                this.driverLoading = false;
            });
    }

    // Search trip by invoice or leg id
    searchTripById(search: string = '') {
        this.search_trip_loading = true;

        this.tripsService
            .fetch_trip(search)
            .subscribe((res: { trip: Array<ISearchTrip> }) => {
                this.tripSearchList = res?.trip?.slice(0, 10);
            })
            .add(() => {
                this.search_trip_loading = false;
            });
    }

    public visitDriverPage(driver_id: number) {
        if (this.driverSearchPopoverReference) this.driverSearchPopoverReference.hide();
        this.driverSearchPopoverReference = null;
        window.location.href = `${window.origin}/corporate/drivers/driver-detail/${driver_id}`;
    }
    public visitRiderPage(user_id: number) {
        if (this.riderSearchPopoverReference) this.riderSearchPopoverReference.hide();
        this.riderSearchPopoverReference = null;
        window.location.href = `${window.origin}/corporate/riders/rider-detail/${user_id}`;
    }

    public visitTripDetailPage(trip: ISearchTrip) {
        const type = trip?.is_assigned_trip ? 'assigned' : 'queued';
        window.location.href = `${window.origin}/corporate/active-trips/${type}/${trip?.mas_trip_id}`;
    }

    closeProfileDropdown() {
        if (this.profileDropdownPopoverReference) {
            this.profileDropdownPopoverReference.hide();
        }
    }

    ngOnDestroy(): void {
        clearInterval(this.listenNotificationInterval);
    }

    askForGeolocationPermission(): void {
        if ('geolocation' in navigator) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    console.log('Geolocation permission granted:');
                    // Do something with the coordinates, e.g., store in a variable or send to a server
                },
                (error) => {
                    console.error('Geolocation permission denied:', error);
                }
            );
        } else {
            console.error('Geolocation is not supported by this browser.');
        }
    }

    public getCarAffiliation(limit = 20) {
        let params: any = { limit: limit };
        if (this.searchAffiliation) {
            params.searchFlag = 1;
            params.searchString = this.searchAffiliation;
        }
        this.httpService.post(environment.urlC + 'get_corporates', params).subscribe(
            (data) => {
                if (!data?.flag) {
                    this.car_affiliation = data?.carBase;
                    if (!params?.searchFlag) {
                        this.getAllCarBase(data.totalCount);
                    }
                }
            },
            (e) => {
                console.log('error => ', e);
            }
        );
    }

    getAllCarBase(limit = 20) {
        let params: any = { limit: limit };
        if (this.searchAffiliation) {
            params.searchFlag = 1;
            params.searchString = this.searchAffiliation;
        }
        this.httpService.post(environment.urlC + 'get_corporates', params).subscribe(
            (data) => {
                if (!data?.flag) {
                    this.car_affiliation = data?.carBase;
                }
            },
            (e) => {
                console.log('error => ', e);
            }
        );
    }

    resetSearch(event: Event): void {
        this.searchControl.reset();
        event.stopPropagation();
        this.searchAffiliation = '';
        this.getCarAffiliation();
    }

    mobileSelectCorp(event: Event, type: any): void {
        if (type === 'open') {
            this.isSuperCorpAccess = true;
        } else {
            this.isSuperCorpAccess = false;
        }
        event.stopPropagation();
    }

    /** Select the Corp Account */
    selectCorpAccount(data: any) {
        const payload = {
            web_access_token: this.cookieService.get('super_corp_web_access_token'),
            corporate_id: data.corporate_id,
        };
        this.httpService.post(environment.urlC + 'access_corp_by_supercorp', payload).subscribe(
            (res) => {
                this.cookieService.set('web_access_token', res.corpInfo.web_access_token);
                this.cookieService.set('access_token', res.corpInfo.web_access_token);
                const selectedCorp = {
                    corporate_id: data.corporate_id,
                    business_name: data.business_name,
                    is_mas_dispatcher: res.corpInfo.is_mas_dispatcher,
                };
                // this.setSelectedCorp(res.corpInfo.web_access_token);
                localStorage.setItem('selectedCorp', JSON.stringify(selectedCorp));
                let driverData: any = JSON.parse(localStorage.getItem('corporateModel'));
                if (driverData) {
                    driverData.is_mas_dispatcher = res.corpInfo.is_mas_dispatcher;
                    const updatedDriverData = JSON.stringify(driverData);
                    localStorage.setItem('corporateModel', updatedDriverData);
                } else {
                    console.log('No driver data found in localStorage');
                }
                location.reload();
            },
            (e) => {
                console.log('error => ', e);
            }
        );
    }

    /** Back to Super Corp */
    backToSuperCorp() {
        this.cookieService.set('web_access_token', this.cookieService.get('super_corp_web_access_token'));
        localStorage.removeItem('selectedCorp');
        let driverData: any = JSON.parse(localStorage.getItem('corporateModel'));
        if (driverData) {
            driverData.is_mas_dispatcher = 0;
            const updatedDriverData = JSON.stringify(driverData);
            localStorage.setItem('corporateModel', updatedDriverData);
        } else {
            console.log('No driver data found in localStorage');
        }
        location.reload();
    }

    /** Set Selected Corp  */
    // setSelectedCorp(token:any) {
    //     const params = {
    //         web_access_token: token,
    //     };
    //     const url_login_token = environment.urlC + 'get_info';
    //     this.httpService.post(url_login_token, params).subscribe(
    //         (resp) => {
    //             if (typeof resp == 'string') resp = JSON.parse(resp);
    //             if (resp.error) {
    //                 this.utilityService.toast('error', resp.error, '');
    //             } else {
    //                 const corpInfo = resp.corpInfo;
    //                 const driverModel = {
    //                     first_name: corpInfo.first_name,
    //                     driver_name: corpInfo.first_name + ' ' + corpInfo.last_name,
    //                     driver_image: corpInfo.image,
    //                     driver_mobile: corpInfo.mobile,
    //                     driver_email: corpInfo.email,
    //                     referral_code: corpInfo.referral_code,
    //                     zipcode: corpInfo.zipcode,
    //                     state_id: corpInfo.state_id,
    //                     corporate_id: corpInfo.corporate_id,
    //                     subtype_id: corpInfo.subtype_id,
    //                     location: corpInfo.city,
    //                     state: corpInfo.state,
    //                     city: corpInfo.city,
    //                     address: corpInfo.address,
    //                     latitude: corpInfo.latitude,
    //                     longitude: corpInfo.longitude,
    //                     is_mas_dispatcher: corpInfo.is_mas_dispatcher || 0,
    //                     corp_type: corpInfo.corp_type,
    //                 };
    //                 localStorage.setItem('corporateModel', JSON.stringify(driverModel));
    //                 location.reload();
    //             }
    //         },
    //         (err) => {
    //         }
    //     );
    // }
}
