import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { RiderDetailTabType } from '../../pages/riders/rider-detail/rider-detail.component';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    constructor(private httpService: HttpService, private cookieService: CookieService, private router: Router) {}

    /**
     * @param user_mobile - Must include country code with the mobile number  -> <COUNTRY_CODE>-<MOBILE_NUMBER>
     * @param user_name - name of the user
     * @returns http response
     */
    public check_mobile(user_mobile: string, user_name: string): Observable<any> {
        return this.httpService.post(environment.urlC + 'check_mobile', {
            user_mobile: user_mobile, //this.promoCountryCode + this.booking.user_mobile,
            user_name: user_name,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    /**
     * Create a new user if not exists, It can also be used to get user_id when user exists
     * @param user_mobile - Must include country code with the mobile number  -> <COUNTRY_CODE>-<MOBILE_NUMBER>
     * @param user_name - name of the user
     * @param proceed_without_add - 1 if true else 0
     * @returns http response
     *
     */
    public new_user_register(user_mobile: string, user_name: string, proceed_without_add: number): Observable<any> {
        return this.httpService.post(environment.urlC + 'new_user_register', {
            user_mobile,
            user_name,
            web_access_token: this.cookieService.get('web_access_token'),
            proceed_without_add: proceed_without_add,
        });
    }

    public get_associated_rider_list(payload: { limit?: number; offset?: number; searchString: string }) {
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: payload?.limit || 10,
            offset: payload?.offset || 0,
        };
        if (payload.searchString) {
            params.searchFlag = 1;
            params.searchString = payload.searchString;
        }

        return this.httpService.post(environment.urlC + 'associated_user_list', params);
    }

    /**
     * @param user_id -> user id
     * @param user_email -> email id of the user
     * @return HttpResponse as Observable
     * @description - Can be used for updating the email of provided user id
     */
    public update_registered_user_info(params: { user_id: string | number; user_email: string }): Observable<any> {
        return this.httpService.post(environment.urlC + 'update_registered_user_info', {
            ...params,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    // This API can be Used for user and driver both
    /**
     * @param qudos_user_id -> user id or driver id
     * @param qudos_user_type -> 0 for rider and 1 for driver, default 0
     * @param gender -> 0 for male, 1 for female and 2 for other
     */
    public edit_associated_user_or_driver(params: { qudos_user_id: number; qudos_user_type: number; gender: number }) {
        return this.httpService.post(environment.urlC + 'edit_associated_user', {
            ...params,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    public get_rider_detail(user_id: number | string) {
        return this.httpService.post(environment.urlC + 'rider_detail', {
            user_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    // ======= Secondary Contacts =======

    get_secondary_contacts(user_id: number | string) {
        return this.httpService.post(environment.urlWC + 'get_user_secondary_contact', {
            user_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    add_secondary_contacts(data: { user_id: number | string; name: string; mobile: string }) {
        return this.httpService.post(environment.urlWC + 'add_user_secondary_contact', {
            user_id: data.user_id,
            name: data.name,
            user_secondary_mobile: data.mobile,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    update_seconday_contacts(data: { id: number | string; name: string; mobile: string }) {
        return this.httpService.post(environment.urlWC + 'update_user_secondary_contact', {
            id: data.id,
            name: data.name,
            user_secondary_mobile: data.mobile,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    remove_secondary_contact(id: number | string) {
        return this.httpService.post(environment.urlWC + 'delete_user_secondary_contact', {
            id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    send_invites(send_on: string) {
        return this.httpService.post(environment.urlWC + 'send_invites', {
            send_on,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    // =======

    navigateToUserDetail(rider_id: string, active_tab: RiderDetailTabType = 'passenger') {
        window.open(`/corporate/riders/rider-detail/${rider_id}?active_tab=${active_tab}`, '_blank');
    }
}
