import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'src/environments/environment';
import { UtilityService } from './core/services/utility/utility.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
})
export class AppComponent {
    envName: string;

    userActivity: any;
    inactivityInterval: number = 5; // minute

    constructor(public utilityService: UtilityService, private router: Router) {
        if (!environment.production) {
            this.envName = environment.name;
        }

        this.startInterval();
    }

    @HostListener('document:keypress')
    handleKeyboardEvent() {
        clearInterval(this.userActivity);
        this.startInterval();
    }

    @HostListener('window:mousemove') handleMouseEvent() {
        clearInterval(this.userActivity);
        this.startInterval();
    }

    @HostListener('window:offline')
    setNetworkOffline(): void {
        this.utilityService.toast('warning', 'Check your network connection');
    }

    @HostListener('window:online')
    setNetworkOnline(): void {
        this.utilityService.clearToast();
        this.utilityService.toast('success', 'Online again! reloading the page');
        setTimeout(() => {
            // document.location.reload();
        }, 2000);
    }

    startInterval() {
        if (
            (this.envName && this.envName === 'dev') ||
            this.router.url.includes('corporate_login') ||
            this.router.url.includes('corporate_signup') ||
            this.router.url.includes('riderSignup')
        )
            return;
        this.userActivity = setInterval(() => {
            location.reload();
        }, this.inactivityInterval * 60 * 1000);
    }
}
