<div class="notification-card m-4" [class.large]="type === 'detailed'">
    <div class="navbar py-1 px-3 text-light d-flex justify-content-between align-items-center">
        <div class="fw-600">
            {{ title }}
        </div>
        <div class="action-btn" (click)="remove()">
            <mat-icon>close</mat-icon>
        </div>
    </div>
    <mat-progress-bar mode="determinate" [value]="progress"></mat-progress-bar>

    <div class="p-4 text-grey fw-600 fs-14 spaced-message">
        {{ message }}
    </div>
    <ng-container *ngIf="type === 'detailed' && notification_payload?.metadata">
        <hr class="light" />
        <div class="p-4 text-grey fs-14 spaced-message">
            <div>
                <span class="mr-2">Driver Info:</span>
                <span class="extra-dark">
                    {{ notification_payload?.metadata?.driverInfo?.driver_name }}
                    {{ notification_payload?.metadata?.driverInfo?.driver_mobile | phoneFormat }}
                </span>
            </div>
            <div>
                <span class="mr-2">Car Info:</span>
                <span class="extra-dark">{{ notification_payload?.metadata?.carInfo }}</span>
            </div>
        </div>
        <hr class="light" />
    </ng-container>

    <div class="d-flex justify-content-around p-3 fs-14">
        <!-- Custom Integration -->
        <ng-container *ngIf="type === 'detailed'">
            <div class="text-blue2 action-btn" (click)="action(rightActionText); remove()" *ngIf="rightActionText">
                {{ rightActionText }}
            </div>

            <div class="text-blue2 action-btn" (click)="action('View Map'); remove()" *ngIf="rightActionText">
                {{ 'View Map' }}
            </div>

            |

            <div class="text-blue2 action-btn" (click)="onDismiss()" [class.disabled]="active_notifications_by_type <= 1">Dimiss All</div>
            <div class="text-blue2 action-btn" (click)="remove()">Dismiss</div>
        </ng-container>

        <!-- Standard Integration -->
        <ng-container *ngIf="type === 'normal'">
            <div class="text-blue2 action-btn" (click)="onDismiss()">
                <ng-container *ngIf="active_notifications_by_type > 1">Dimiss All</ng-container>
                <ng-container *ngIf="active_notifications_by_type <= 1">Dismiss</ng-container>
            </div>
            <div class="text-blue2 action-btn" (click)="action(rightActionText); remove()" *ngIf="rightActionText">
                {{ rightActionText }}
            </div>
        </ng-container>
    </div>
</div>
