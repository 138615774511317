<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <SettingHeader [config]="pageHeaderConfig" (onEvent)="onPageHeaderEvent($event)"></SettingHeader>
    </div>

    <!-- Tables
================================================== -->
    <div class="table-container short touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0">
        <div class="page-header"></div>
        <table class="table table-responsive trips desktop">
            <thead>
                <tr class="frow">
                    <th scope="col">ID</th>
                    <th scope="col">Profile</th>
                    <th scope="col">Added on</th>
                    <th scope="col">Mobile #</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="isLoading && Staffs?.length == 0">
                <tr class="no-hover">
                    <td colspan="6" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="Staffs.length > 0">
                <!-- ng-repeat="doc in docs track by $index" -->
                <tr
                    *ngFor="
                        let member of Staffs | paginate: { id: 'myStaff', itemsPerPage: itemsPerPage, currentPage: page, totalItems: totalItems };
                        let i = index
                    "
                >
                    <td>{{ member.corporate_id }}</td>
                    <td>{{ member.staff_name }}</td>

                    <td>
                        {{ member.added_on | date: 'MMM dd yyyy, hh:mm a' }}
                        <!-- <br />
                            {{ member.added_on | date: 'hh:mm a' }} -->
                    </td>

                    <td>
                        {{ member.staff_mobile }}
                    </td>
                    <td class="action-center" *ngIf="userRole === 'admin' || loggedInDriverData.corporate_id !== member.corporate_id">
                        <div class="d-flex flex-row justify-content-center bd-highlight">
                            <div class="p-2 bd-highlight align-self-center">
                                <mat-icon
                                    class="highlight"
                                    (click)="$event.stopPropagation(); staffForm.get('password').clearValidators(); openEditStaffMemberModal(member)"
                                >
                                    edit
                                </mat-icon>
                            </div>
                            <div class="p-2 bd-highlight highlight align-self-center" *ngIf="userRole === 'admin'">
                                <mat-icon class="highlight" (click)="$event.stopPropagation(); confirmRemoveStaff(member)">delete_outline</mat-icon>
                            </div>
                            <div class="p-2 bd-highlight highlight align-self-center">
                                <mat-icon data-toggle="dropdown" id="dropdown">more_vert</mat-icon>
                                <div class="dropdown-menu cd-menu card-shadow" aria-labelledby="download"></div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="Staffs.length == 0 && !isLoading">
                <tr class="no-hover">
                    <td colspan="5" class="text-center">No Staff Found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="custom_pagination_v2">
    <div class="d-flex flex-row-reverse align-items-center">
        <ng-container *ngIf="!isLoading">
            <div *ngIf="showPagination">
                <pagination-controls id="myStaff" responsive="true" (pageChange)="get_staffs($event)"></pagination-controls>
            </div>

            <div class="p-2 bd-highlight highlight ml-3 dropdown cursor-pointer">
                <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                    <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                    <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
                </div>
                <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                    <div
                        *ngFor="let item of utilityService.display_filter_list"
                        class="dropdown-item text-center cd-item text-spacegrey"
                        (click)="get_staffs(1, item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="text-spacegrey fs-13 fw-600">
                <!-- {{ page * itemsPerPage >= totalItems ? totalItems : page * itemsPerPage }} of {{ totalItems }} -->
                Results:
                <span class="text-blue-secondary">{{ this.AllCards?.length }}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="isLoading">
            <app-loading type="placeholder" count="1" width="430px" height="24px"></app-loading>
        </ng-container>
    </div>
</div>

<div
    class="modal fade touch-popup"
    id="add_staff"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    data-backdrop="static"
>
    <div
        class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg"
        role="document"
        style="min-width: 630px !important; max-width: fit-content !important"
    >
        <div class="modal-content">
            <div class="touch-header pt-2">
                <div class="mx-5 my-4">
                    <!-- <div class="p-0 pb-1 my-0 d-flex justify-content-between"> -->
                    <div class="medium-text dark-color">
                        <span *ngIf="editStaff">Edit</span>
                        <span *ngIf="!editStaff">Add</span>
                        Staff
                    </div>
                    <!-- <div class="blue-color cursor-pointer">More Info about MAS</div> -->
                    <!-- </div> -->
                </div>
            </div>

            <hr class="light" />
            <div class="bg-light-blue pr-3 pr-md-5">
                <div class="touch-content mx-5 my-4 py-2">
                    <form [formGroup]="staffForm">
                        <div class="d-flex my-2 justify-content-end">
                            <div class="mx-2 align-self-center fs-13 dark-color fw-600">Staff Full Name</div>
                            <div class="ml-2">
                                <input
                                    type="text"
                                    [class.error]="staffForm.get('staff_name')?.touched && staffForm.get('staff_name')?.invalid"
                                    class="touch-input fs-13 w-100 p-1 px-2"
                                    formControlName="staff_name"
                                    placeholder="Staff full name here"
                                />
                            </div>
                        </div>
                        <div class="d-flex my-2 justify-content-end">
                            <div class="mx-2 align-self-center fs-13 dark-color fw-600">Mobile Number</div>
                            <div
                                class="ml-2 touch-tel-input"
                                [class.error]="staffForm.get('staff_mobile')?.touched && staffForm.get('staff_mobile')?.invalid"
                            >
                                <!-- <input type="text" class="touch-input fs-13 w-100 p-1 px-2" placeholder="Staff full name here" #mas_api_key ngModel /> -->
                                <ngx-intl-tel-input
                                    [cssClass]="'custom'"
                                    [attr.autocomplete]="false"
                                    [resetSearchContainer]="true"
                                    [preferredCountries]="preferredCountries"
                                    [enableAutoCountrySelect]="true"
                                    [enablePlaceholder]="true"
                                    [searchCountryFlag]="true"
                                    [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                    [selectFirstCountry]="false"
                                    [selectedCountryISO]="selectedCountryISO"
                                    [maxLength]="10"
                                    [tooltipField]="TooltipLabel.Name"
                                    [phoneValidation]="true"
                                    [separateDialCode]="separateDialCode"
                                    name="phone"
                                    [inputId]="'mobile'"
                                    formControlName="staff_mobile"
                                    [customPlaceholder]="'Mobile Number'"
                                ></ngx-intl-tel-input>
                            </div>
                            <!-- [formControl]="" -->
                        </div>
                        <div class="d-flex my-2 justify-content-end">
                            <div class="mx-2 align-self-center fs-13 dark-color fw-600">Password</div>
                            <div class="ml-2">
                                <input
                                    type="password"
                                    [class.error]="staffForm.get('password')?.touched && staffForm.get('password')?.invalid"
                                    class="touch-input fs-13 w-100 p-1 px-2"
                                    formControlName="password"
                                    placeholder="Password here"
                                />
                            </div>
                        </div>
                    </form>
                    <!-- <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div> -->
                </div>
            </div>

            <hr class="light" />
            <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
                <button class="btn btn-secondary btn-light text-white p-2 mx-2 br-6" data-dismiss="modal" (click)="staffForm.reset()">Cancel</button>
                <button class="btn btn-primary btn-right-secondary p-2 ml-2 br-6" [disabled]="staffForm.invalid" (click)="onStaffSubmit()">
                    <span *ngIf="!editStaff">Add New</span>
                    <span *ngIf="editStaff">Update</span>
                    Staff
                </button>
            </div>
        </div>
    </div>
</div>
