<table class="table table-responsive-md trips_detail dtrips trips">
    <thead>
        <tr class="frow">
            <th scope="col">Action</th>
            <th scope="col">Notes ID</th>
            <th scope="col">Title</th>
            <th scope="col">Priority</th>
            <th scope="col" class="tleft">Description</th>
        </tr>
    </thead>
    <tbody *ngIf="rider_notes?.length > 0">
        <ng-container *ngFor="let note of rider_notes">
            <tr class="rideBody Disb cursor-pointer">
                <td>
                    <div class="bd-highlight highlight align-self-center mr-1">
                        <img
                            data-toggle="dropdown"
                            style="height: 30px"
                            class="hover-shadow rounded"
                            id="dropdown"
                            src="/assets/images/more_horiz_rounded.svg"
                        />
                        <div class="dropdown-menu cd-menu left-0 card-shadow text-grey" aria-labelledby="download">
                            <div class="dropdown-item cd-item grey-item" (click)="add_or_update_note('edit', note)">Edit</div>
                            <div class="dropdown-item cd-item text-danger" (click)="remove_rider_note(note)">Remove</div>
                        </div>
                    </div>
                </td>
                <td>
                    <span>{{ note?.notes_id }}</span>
                </td>
                <td>
                    {{ note?.title | titlecase }}
                </td>
                <td>
                    <div
                        class="badge badge-light fs-12"
                        [ngClass]="{
                            'text-secondary badge-grey': note?.priority === 1,
                            'badge-orange': note?.priority === 2,
                            'text-danger badge-inactive': note?.priority === 3
                        }"
                    >
                        {{ priorityMap[note?.priority] || 'NA' }}
                    </div>
                </td>
                <td class="tleft">{{ note?.description }}</td>
            </tr>
        </ng-container>
    </tbody>

    <tbody *ngIf="rider_notes?.length == 0 && !isLoading">
        <tr class="noride no-hover">
            <td colspan="9" class="text-center" style="padding: 20px 87px 20px 87px !important">No Notes Yet</td>
        </tr>
    </tbody>
    <tbody *ngIf="rider_notes?.length === 0 && isLoading">
        <tr class="noride no-hover">
            <td colspan="9" class="text-center px-3">
                <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
            </td>
        </tr>
    </tbody>
</table>
