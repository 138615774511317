<div class="cmodal">
    <div class="title">
        <span *ngIf="!placeholder">Select</span>
        <span *ngIf="placeholder">Edit</span>
        Car Base Affiliation
    </div>

    <hr />

    <div class="middle-section">
        <div class="row my-2">
            <div class="col-3 left my-auto">
                <span *ngIf="placeholder">Current</span>
                <span *ngIf="!placeholder">Car Base</span>
            </div>
            <div class="col-9 right d-flex">
                <div>
                    <img [src]="current_affiliation?.image" onerror="src= 'assets/images/person_placeholder.png'" alt="image" />
                </div>
                <div class="align-self-center mx-3">
                    <ng-container *ngIf="!current_affiliation">
                        Name of Car Base Name longest LLC
                        <br />
                        State, Country
                    </ng-container>
                    <ng-container *ngIf="current_affiliation">
                        {{ current_affiliation?.business_name | slice: 0:30 }}
                        <ng-container *ngIf="current_affiliation?.business_name?.length > 30">...</ng-container>
                        <br />
                        <span>
                            {{ current_affiliation?.state_name }}
                            <ng-container *ngIf="current_affiliation?.country_name">, {{ current_affiliation?.country_name }}</ng-container>
                        </span>
                    </ng-container>
                </div>
            </div>
        </div>
        <div class="row mt-4">
            <div class="col-3 left my-auto">
                <span *ngIf="!placeholder">Car Base Name</span>
                <span *ngIf="placeholder">Others</span>
            </div>
            <div class="col-9">
                <div class="dropdown">
                    <span
                        class="w-100"
                        type="button"
                        id="affiliation_menu"
                        data-toggle="dropdown"
                        aria-haspopup="true"
                        aria-expanded="false"
                        (click)="searchControl.reset('')"
                    >
                        <div class="d-flex justify-content-between placeholder">
                            <div *ngIf="current_affiliation?.business_name">
                                {{ current_affiliation?.business_name | slice: 0:50 }}
                                <ng-container *ngIf="current_affiliation?.business_name?.length > 50">...</ng-container>
                            </div>
                            <div *ngIf="!current_affiliation?.business_name">Select Car Base Affiliation</div>
                            <div><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                        </div>
                    </span>
                    <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                    <div
                        (scroll)="onScollMenu($event)"
                        class="dropdown-menu p-0 card-shadow w-100"
                        id="ride_type_menu"
                        aria-labelledby="affiliation_menu"
                    >
                        <div class="w-100 px-2 py-3 search-box" [class.search-shadow]="searchbox_highlight">
                            <span>
                                <input class="m-0 border-0" [formControl]="searchControl" type="text" placeholder="Search Car Base" />
                                <i class="fa fa-search" aria-hidden="true"></i>
                            </span>
                        </div>
                        <ng-container *ngFor="let item of car_affiliation">
                            <div
                                class="dropdown-item cursor-pointer"
                                (click)="current_affiliation = item; searchControl.reset(''); emitOnEntityChange()"
                            >
                                <span class="di-title">
                                    {{ item?.business_name | slice: 0:20 }}
                                    <span *ngIf="item.business_name?.length > 20">... &nbsp;</span>
                                </span>
                                <span class="di-subtitle">
                                    {{ item?.state_name }}
                                    <span *ngIf="item?.country_name">, {{ item?.country_name }}</span>
                                </span>
                            </div>
                        </ng-container>
                        <div *ngIf="affiliation_loading" class="my-3" align="center">
                            <!-- <i class="fa fa-spinner fa-pulse"></i> -->
                            <app-loading></app-loading>
                        </div>
                        <div *ngIf="car_affiliation?.length == 0" align="center">
                            <span class="text-center p-2 text-secondary" style="font-weight: normal">No result found!</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <hr />

    <div class="bottom-section">
        <div align="right">
            <button class="btn btn-secondary left px-5 mx-2" data-dismiss="modal" (click)="onCancel()">Cancel</button>

            <button class="btn btn-primary right px-5 ml-2" data-dismiss="modal" (click)="emitCarAffiliation()">Save</button>
        </div>
    </div>
</div>
