<div class="main_outer pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <SettingHeader
            [config]="{ headerTitle: 'More Settings', tabs: { items: utilityService.settingHeaderConfig.settings, activeTab: 'More' } }"
        ></SettingHeader>
    </div>

    <div class="table-container short touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0">
        <div class="page-header"></div>

        <div class="setting_outer">
            <div class="setting_heading">
                <h2>
                    Payment Settings
                    <a>
                        <span
                            data-toggle="collapse"
                            data-target="#settings"
                            role="button"
                            aria-expanded="false"
                            aria-controls="collapseExample"
                            style="color: #2c71c3"
                            (click)="payment_setting_show = !payment_setting_show"
                        >
                            <ng-container *ngIf="payment_setting_show">Show Less</ng-container>
                            <ng-container *ngIf="!payment_setting_show">Show More</ng-container>
                        </span>
                    </a>
                </h2>
            </div>
            <div *ngIf="isLoading" class="m-2" align="center">
                <app-loading></app-loading>
            </div>
            <div class="noti_setting pt-2 collapse show" id="settings" *ngIf="!isLoading">
                <div class="media align-items-center">
                    <div class="media-body" style="flex: 0.7">
                        <h2>Rider Card Payment</h2>
                        <p>Payment of use is the rider card</p>
                    </div>
                    <div class="media-left">
                        <label class="switch">
                            <input
                                type="checkbox"
                                [class.checked]="riderCardEnabled"
                                (change)="changeRiderCardSetting()"
                                [checked]="riderCardEnabled"
                            />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
                <div class="media mt-3 align-items-center">
                    <div class="media-body" style="flex: 0.7">
                        <h2>Corporate Card Payment</h2>
                        <p>Payment of use is the corporate card</p>
                    </div>
                    <div class="media-left">
                        <label class="switch">
                            <input type="checkbox" [class.checked]="corpCardEnabled" (change)="changeCorpCardSetting()" [checked]="corpCardEnabled" />
                            <span class="slider round"></span>
                        </label>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
