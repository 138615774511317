<div class="modal-dialog modal-dialog-centered modal-lg" role="document" style="min-width: 750px">
    <div class="modal-content card-shadow booking-model-component">
        <div class="mx-5 mt-5 rb-large-text" style="font-size: 1.4rem">Add Premium Options</div>
        <hr class="confirm" />
        <div class="modal-body mb-2 mx-5 p-0">
            <form [formGroup]="premiumOption">
                <table class="table borderless">
                    <thead class="tb-heading">
                        <tr>
                            <th>Premium</th>
                            <th>Fee</th>
                            <th>Add</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr
                            class="content"
                            (click)="
                                premiumOption.value['plexiglass_partition']
                                    ? po1.click()
                                    : open_confirmation_popup(confirmActionPopup, 'plexiglass_partition')
                            "
                        >
                            <td class="p-3">Cars with Plexiglass Partition</td>
                            <td class="p-3">No Fee</td>
                            <td class="p-3 text-center">
                                <!-- <div class="form-check"> -->
                                <input style="pointer-events: none" formControlName="plexiglass_partition" type="checkbox" value="" #po1 />
                                <!-- </div> -->
                            </td>
                        </tr>
                        <tr
                            class="content"
                            (click)="
                                premiumOption.value['through_door_service']
                                    ? po2.click()
                                    : open_confirmation_popup(confirmActionPopup, 'through_door_service')
                            "
                        >
                            <td class="p-3">Through the Door Service</td>
                            <td class="p-3">$ 10.00</td>
                            <td class="p-3 text-center">
                                <!-- <div class="form-check"> -->
                                <input
                                    style="pointer-events: none"
                                    formControlName="through_door_service"
                                    (click)="$event.stopPropagation()"
                                    type="checkbox"
                                    value=""
                                    #po2
                                />
                                <!-- </div> -->
                            </td>
                        </tr>
                        <tr
                            class="content"
                            (click)="
                                premiumOption.value['pet_friendly_car']
                                    ? po3.click()
                                    : open_confirmation_popup(confirmActionPopup, 'pet_friendly_car')
                            "
                        >
                            <td class="p-3">Pet Friendly Car</td>
                            <td class="p-3">$ 7.00</td>
                            <td class="p-3 text-center">
                                <!-- <div class="form-check"> -->
                                <input
                                    formControlName="pet_friendly_car"
                                    style="pointer-events: none"
                                    (click)="$event.stopPropagation()"
                                    type="checkbox"
                                    value=""
                                    #po3
                                />
                                <!-- </div> -->
                            </td>
                        </tr>
                    </tbody>
                </table>
            </form>
        </div>
        <hr class="confirm" />
        <div class="row rb-btn mt-1 mb-4">
            <div class="col-3"></div>
            <div class="col-3 text-center">
                <button class="btn btn btn-secondary border-0 p-2 rb-btn-grey ride-confirm-btn" (click)="bsModalRef.hide()">Cancel</button>
            </div>
            <div class="col-3 text-center">
                <button class="btn btn-secondary border-0 p-2 rb-btn-dark-blue ride-confirm-btn" (click)="updateOption()">
                    <!-- <ng-container *ngIf="!premiumOptionLabel">Add Option</ng-container> -->
                    <ng-container>Update Option</ng-container>
                </button>
            </div>
        </div>
    </div>
</div>

<ng-template #confirmActionPopup>
    <div class="modal-dialog modal-dialog-centered modal-lg touch-popup" role="document" style="max-width: 650px !important">
        <div class="modal-content shadow-sm border-0">
            <div class="touch-header pt-1 t-margin my-4">
                <div class="large-medium-text dark-color align-self-center">
                    <div class="p-0 pb-1 my-0">{{ premium_option_confirmation[activeOption]?.title }}</div>
                </div>
            </div>
            <hr class="light" />
            <div class="touch-content t-margin my-4 dark-color">
                <div class="d-flex justify-content-center">
                    <div class="align-self-center m-3">
                        <img [src]="premium_option_confirmation[activeOption]?.imageUrl" alt="pet_friendly_rides" />
                    </div>
                    <div
                        class="align-self-center m-3 dark-color"
                        style="max-width: 260px"
                        [innerHTML]="premium_option_confirmation[activeOption]?.description"
                    >
                        <!-- Whether you’re visiting a family or doing your regular routine, there is always a room for your beloved pet.
                        <br><br>We will find drivers who agreed to accept pets in their car. -->
                    </div>
                </div>
            </div>
            <hr class="light" />

            <div class="touch-bottom t-margin my-4">
                <div class="d-flex justify-content-around">
                    <div class="cursor-pointer blue-color" (click)="confirm_modal_ref.hide()">Cancel</div>

                    <div class="cursor-pointer blue-color" (click)="confirmPremiumOptionAction()">Please add to my ride</div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
