<div class="w-100 search-box" [ngClass]="extraClass">
    <span class="input-group">
        <div class="d-flex w-100">
            <div class="flex-fill">
                <input [formControl]="searchFormControl" class="m-0 border-0 w-100" type="text" [placeholder]="placeHolder" />
            </div>
            <div>
                <i *ngIf="!searchFormControl.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                <mat-icon *ngIf="searchFormControl.value" (click)="searchFormControl.reset()" class="cursor-pointer" style="vertical-align: middle">
                    close
                </mat-icon>
            </div>
        </div>
    </span>
</div>
