import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IRiderNote, MasNote, NotePriority } from 'src/app/models/notes.model';
import { NotesService } from '../../services/notes/notes.service';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-note-popover',
    templateUrl: './note-popover.component.html',
    styleUrls: ['./note-popover.component.scss'],
})
export class NotePopoverComponent implements OnInit, OnDestroy {
    constructor(private notesService: NotesService, private utilityService: UtilityService, private userService: UserService) {}

    @Input() noteListType: 'rider' | 'trip' = 'trip';
    @Input() labelType: 'icon' | 'note' = 'icon'; // note_view is only applicable when noteListType = trip, currently we are using it under assign queue detail page.
    @Input() user_id: string;
    @Input() user_name: string;
    @Input() mas_trip_id: string;
    @Input() new_note_placeholder: string = 'Write a note here';
    isLoading: boolean = false;

    private subscriptionList$: Subscription = new Subscription();
    title: { rider: string; trip: string } = { rider: 'Riders Notes', trip: 'Current Trip Notes' };
    note_popover_reference: PopoverDirective;
    @Input() notes: MasNote[] = [];
    rider_notes: IRiderNote[] = [];
    newNoteForm = new FormControl('', [Validators.required]);

    // Required for showing two input fields
    editNoteForm = new FormControl('', [Validators.required]);

    edit_note: MasNote | IRiderNote;
    new_note_mode: boolean = false;

    ngOnInit(): void {
        if (this.labelType === 'note') {
            this.get_mas_notes();
        }
    }

    // Trip Notes Section

    get_mas_notes(mas_trip_id: any = this.mas_trip_id) {
        if (!this.mas_trip_id) return;

        this.new_note_mode = false;
        this.resetForms();
        this.isLoading = true;

        this.subscriptionList$.add(
            this.notesService
                .get_mas_notes(mas_trip_id)
                .subscribe((res) => {
                    // REMOVE in production
                    console.log(res);
                    if (res.notes) {
                        if (Array.isArray(res.notes)) {
                            this.notes = res.notes;
                        } else {
                            this.notes = [res.notes];
                        }
                    } else {
                        this.new_note_mode = true;
                    }
                })
                .add(() => {
                    this.isLoading = false;
                })
        );
    }

    add_note(mas_trip_id: string = this.mas_trip_id) {
        const note = this.newNoteForm?.value?.trim();
        this.subscriptionList$.add(
            this.notesService.add_mas_notes(mas_trip_id, note).subscribe((res) => {
                this.utilityService.toast('success', res.log);
                if (res.flag === 2203) {
                    this.get_mas_notes(mas_trip_id);
                }
            })
        );
    }

    update_note(note_ref: MasNote) {
        const note = this.editNoteForm.value?.trim();
        if (!note) {
            this.utilityService.toast('warning', 'Can not add empty note');
            return;
        }
        this.subscriptionList$.add(
            this.notesService.update_mas_note(this.edit_note.notes_id, note).subscribe((res) => {
                if (res.flag === 2203) {
                    note_ref.notes = note; // Ignore the get api call and update the ref;
                    this.edit_note = null;
                    this.editNoteForm.reset();
                    this.utilityService.toast('success', res.log);
                }
            })
        );
    }

    // Rider Note Sections

    get_rider_notes() {
        this.edit_note = null;
        if (this.noteListType !== 'rider') return;
        this.isLoading = true;
        this.resetForms();
        this.notesService
            .get_user_notes(this?.user_id)
            .subscribe(
                (res: any | { flag: number; notes: IRiderNote[]; log: string; error?: string }) => {
                    if (res?.flag === 2203) {
                        this.rider_notes = res?.notes;
                    } else {
                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.isLoading = false;
            });
    }

    add_rider_note() {
        const note_description = this.newNoteForm.value.trim();
        if (!note_description) {
            this.utilityService.toast('warning', 'Can not add empty note');
            return;
        }

        this.notesService
            .add_user_notes({
                user_id: this.user_id,
                title: 'NA',
                priority: NotePriority.Normal,
                desc: note_description,
            })
            .subscribe(
                (res: { flag: number; log: string; error?: string }) => {
                    if (res?.flag === 2203) {
                        this.utilityService.toast('success', 'Note added successfully');
                        this.newNoteForm.reset();
                        this.get_rider_notes();
                    } else {
                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            );
    }

    update_rider_note(note_ref: IRiderNote) {
        const note_description = this.editNoteForm.value?.trim();
        if (!note_description) {
            this.utilityService.toast('warning', 'Can not add empty note');
            return;
        }

        this.notesService
            .update_user_notes({
                ...note_ref,
                qudos_user_id: note_ref.qudos_user_id,
                notes_id: note_ref.notes_id,
                desc: note_description,
            })
            .subscribe((res: { flag: number; log: string; error?: string }) => {
                // console.log("res => ", res);
                if (res?.flag === 2203) {
                    this.utilityService.toast('success', res?.log);
                    this.edit_note = null;
                    this.editNoteForm.reset();
                    note_ref.description = note_description;
                    this.new_note_mode = true;
                } else {
                    this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                }
            });
    }

    navigateToRiderDetailNote() {
        // this.router.navigate(['/', 'corporate', 'detail'], { queryParams: { ref: 'rider', rider_name: this.user_name, active_tab: 'notes' } });
        this.userService.navigateToUserDetail(this.user_id, 'notes');
    }

    resetForms() {
        this.newNoteForm.reset();
        this.editNoteForm.reset();
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }
}
