import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { UtilityService } from 'src/app/core/services/utility/utility.service';

@Component({
    selector: 'app-select-car-type-popover',
    templateUrl: './select-car-type-popover.component.html',
    styleUrls: ['./select-car-type-popover.component.scss'],
})
export class SelectCarTypePopoverComponent implements OnInit {
    constructor(public utilityService: UtilityService) {}
    @Input() carTypes: any[] = [];
    @Input() selectedCarType: any;
    @Output() onCarTypeChange: EventEmitter<any> = new EventEmitter<any>();

    select_car_type_popover_reference: PopoverDirective;

    ngOnInit(): void {}

    selectCarType(carType: any) {
        console.log(carType, this.selectedCarType);
        if (carType.car_type != this.selectedCarType) {
            console.log('EMIT');
            this.onCarTypeChange.emit(carType);
            this.selectedCarType = carType.car_type;
        }

        if (this.select_car_type_popover_reference) {
            this.select_car_type_popover_reference.hide();
        }
    }
}
