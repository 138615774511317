import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-promo-code',
    templateUrl: './promo-code.component.html',
    styleUrls: ['./promo-code.component.scss'],
})
export class PromoCodeComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    public event: EventEmitter<any> = new EventEmitter();

    promo_code: string;
    promo_code_applied: boolean = false;

    ngOnInit(): void {}

    closeandApply(promo_code: string) {
        this.event.emit(promo_code);
        this.bsModalRef.hide();
    }
}
