import { Component, OnInit } from '@angular/core';
import * as moment from 'moment';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { MasService } from '../../services/mas/mas.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';

@Component({
    selector: 'app-leg-cost-breakdown',
    templateUrl: './leg-cost-breakdown.component.html',
    styleUrls: ['./leg-cost-breakdown.component.scss'],
})
export class LegCostBreakdownComponent implements OnInit {
    trips: any; // If passed then trip_leg_id, date and invoice_number will be ignored
    trip_leg_id: string; // must be passed
    date: string; // considered if mas_trip is not passed
    invoice_number: string; // considered if mas_trip is not passed
    active_trip: any; // internal variable
    isLoading: boolean = false; // internal variable
    total_leg_cost: number; // internal variable
    toll?: number; // internal variable

    constructor(public bsModalRef: BsModalRef, private masService: MasService, public utilityService: UtilityService) {}

    ngOnInit(): void {
        this.fetch_mas_trips();
    }

    // Fetch Mas Trip
    async fetch_mas_trips() {
        try {
            this.isLoading = true;
            if (!this.trips) {
                this.trips = await this.masService.fetch_mas_details(moment(this.date).format('MM/DD/yyyy'), this.invoice_number);
            }
            this.active_trip = this.trips?.trips?.find((x: any) => x.tripLegId === this.trip_leg_id?.toString());
            if (this.active_trip) {
                this.total_leg_cost = this.masService.calculate_total_leg_cost(
                    this.active_trip?.legMileage,
                    this.active_trip?.legCost,
                    this.active_trip?.secondary_services
                );
            }
            this.isLoading = false;
        } catch (err) {
            console.log('Something went wrong!', err);
        } finally {
            this.isLoading = false;
        }
    }
}
