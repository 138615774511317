import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { chunk } from 'lodash';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { Subject } from 'rxjs';
import { Observable } from 'rxjs/internal/Observable';
import { debounceTime, first } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { HttpService } from 'src/app/services/http/http.service';
import { MasAssignService } from 'src/app/corporate/services/mas-assign/mas-assign.service';
import { environment } from 'src/environments/environment';
import { UserService } from 'src/app/corporate/services/user/user.service';
import { MasQueueService } from 'src/app/corporate/services/mas-queue/mas-queue.service';
import * as _ from 'lodash';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { LiveTripInfoComponent } from 'src/app/corporate/components/live-trip-info/live-trip-info.component';
import { MasService } from 'src/app/corporate/services/mas/mas.service';
import { GoogleMapService } from 'src/app/corporate/services/google-map/google-map.service';
import { ContactModalComponent } from 'src/app/corporate/components/contact-modal/contact-modal.component';
import { IOptionItem, OptionType } from 'src/app/models/setting-header.model';

declare const jQuery: any;
const $: any = jQuery;

// Add a class to store resolve and reject functions
class TripAcceptancePromise {
    resolve: (value?: boolean | PromiseLike<boolean>) => void;
    reject: (reason?: any) => void;
}

@Component({
    selector: 'app-unassigned-trips',
    templateUrl: './unassigned-trips.component.html',
    styleUrls: ['./unassigned-trips.component.scss'],
})
export class UnassignedTripsComponent implements OnInit {
    constructor(
        public utilityService: UtilityService,
        private route: ActivatedRoute,
        private httpService: HttpService,
        private cookieService: CookieService,
        private masAssigService: MasAssignService,
        public userService: UserService,
        private masQueueService: MasQueueService,
        private router: Router,
        private modalService: BsModalService,
        public masService: MasService,
        private mapService: GoogleMapService
    ) {}

    // As we need same clonned logic under rider detail page, so these parameters are for Rider Detail Page usage only, as per now
    @Input() minimal_setup: boolean = false;
    @Input() rider_name_pre_filter: string;
    use_date_range: boolean = false;
    @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

    hoursList: Array<{ value: string; title: string }> = Array.from({ length: 24 }, (_, x: number) => {
        const x_string = x < 10 ? '0' + x.toString() : x.toString();
        return { value: x_string, title: `${x_string} (${x % 12 || 12} ${x < 12 ? 'AM' : 'PM'} )` };
    });
    showPagination: boolean = false;
    showBottomSearchBox: boolean = false;
    searchForm: FormControl = new FormControl('');
    pickup_search: string = '';
    dropoff_search: string = '';

    time_picker: { start: any; end: any } = {
        start: '',
        end: '',
    };
    refetchPrepaidSubject: Subject<void> = new Subject<void>();

    isLoading: boolean = false;
    mas_session_identifier: string;
    all_mas_trips: Array<any> = [];
    backup_all_mas_trips: Array<any> = []; // for search filter
    mas_trips: Array<any> = [];
    total_MAS_items: number = 0;
    pageNo: number = 1;

    unassign_display_filter_list: Array<number> = [200, 100, 50];
    itemsPerPage: number = 200;

    third_party_enabled: boolean = false;
    sort_order: 'asc' | 'desc' = 'desc';
    filter_type: 'initial' | 'all' = 'all';
    mytime: Date = new Date();
    isMeridian = false;
    paged_driver_loading: boolean = false;
    unassignedSubTabList = this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Unassigned'];

    activeSubTab: 'Active' | 'All Day' | 'Initial Leg' | 'Cancelled' = 'All Day';
    active_trip_date_picker: FormControl = new FormControl(this.utilityService.default_date_filter);
    active_trip_date_range_picker: FormGroup = new FormGroup({
        start: new FormControl(this.utilityService.default_date_filter),
        end: new FormControl(this.utilityService.default_date_filter),
    });

    drivers: Array<any> = [];
    driverLoading: boolean = false;
    driver_search_control: FormControl = new FormControl('');
    assinged_trips: Array<any> = [];

    trip_to_book: any;
    cached_drivers: Array<any> = [];

    // disable queue or assign
    loading_unassigned_trip_container: Array<any> = [];

    alter_standing_trips: number;
    isStandingOrderTrip: boolean = true;
    isCancelOrderTrip: boolean = false;
    listRideBrokers: any[] = [];
    headerOptions: IOptionItem[] = [
        { id: 'standing-order', type: OptionType.TOGGLE, emitEvent: true, text: `Standing Order ${this.isStandingOrderTrip ? 'Off' : 'On'}` },
        { id: 'trip-leg-menu', type: OptionType.DROPDOWN, options: ['all', 'initial'], emitEvent: true, value: 'all', text: 'Trip Leg' },
        { id: 'divider', type: OptionType.DIVIDER },
        { id: 'time-picker', type: OptionType.TIME, emitEvent: true, value: { start: null, end: null } },
        {
            id: 'date-picker',
            type: this.use_date_range ? OptionType.RANGE_DATE : OptionType.NORMAL_DATE,
            form: this.use_date_range ? this.active_trip_date_range_picker : this.active_trip_date_picker,
        },
    ];
    private tripAcceptancePromise: TripAcceptancePromise | null = null;

    ngOnInit(): void {
        this.cached_drivers = JSON.parse(this.cookieService.get('cached_filter_driver') || '[]');
        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));
        // console.log("driverModel>>", driverModel);
        this.third_party_enabled = driverModel.is_mas_dispatcher == 1 ? true : false;
        // console.log("this.third_party_enabled>>", this.third_party_enabled);
        // return;
        // this.route.paramMap.subscribe((paramMap) => {
        //     this.pageNo = 1;
        //     this.searchForm.reset();

        //     if (this.third_party_enabled) {
        //         this.mas_session_identifier = localStorage.getItem('session_identifier');
        //         // this.fetch_mas_trips(
        //         //     this.mas_session_identifier,
        //         //     this.active_trip_date_picker.value,
        //         //     this.active_trip_date_picker.value,
        //         //     true // have to pass true at initial level cause time filter is applied already!
        //         // );
        //     }
        // });

        if (!this.third_party_enabled) {
            this.router.navigate(['/']);
            // this.utilityService.alert('info', 'Mas Dispatcher is not enabled');
        } else {
            this.mas_session_identifier = localStorage.getItem('session_identifier');
        }

        if (this.rider_name_pre_filter) {
            this.use_date_range = true;
        }

        if (this.use_date_range) {
            this.active_trip_date_range_picker.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                const { start, end } = value;
                if (start && end) {
                    const queryParams = this.route?.snapshot?.queryParams;
                    this.router.navigate([this.router.url.split('?')[0]], {
                        queryParams: { ...queryParams, date_filter: `${moment(start).format('MM/DD/yyyy')}-${moment(end).format('MM/DD/yyyy')}` },
                    });

                    this.resetTimeFilter();
                    this.updateSubTabView(new Date());
                    this.isStandingOrderTrip = true;
                    this.fetch_mas_trips(this.mas_session_identifier, value?.start, value?.end);
                }
            });
        } else {
            this.active_trip_date_picker.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                this.resetTimeFilter();
                if (!this.rider_name_pre_filter) this.searchForm.patchValue('', { emitEvent: false });
                if (this.third_party_enabled && value && this.mas_session_identifier) {
                    const queryParams = this.route?.snapshot?.queryParams;
                    this.router.navigate([this.router.url.split('?')[0]], {
                        queryParams: { ...queryParams, date_filter: moment(value).format('MM/DD/yyyy') },
                    });

                    this.updateSubTabView(value);
                    this.isStandingOrderTrip = true;
                    this.fetch_mas_trips(this.mas_session_identifier, value, value);
                }
            });
        }

        setTimeout(() => {
            if (this.rider_name_pre_filter) {
                this.searchForm.patchValue(this.rider_name_pre_filter);
                this.searchForm.disable();
            }

            const date_filter = this.route.snapshot.queryParams?.date_filter;
            if (date_filter) {
                if (this.use_date_range && date_filter?.includes('-')) {
                    const start = new Date(date_filter?.split('-')?.[0] || new Date());
                    const end = new Date(date_filter?.split('-')?.[1] || new Date());
                    this.active_trip_date_range_picker.patchValue({ start, end });
                } else {
                    this.active_trip_date_picker.patchValue(new Date(date_filter));
                }
            } else {
                this.fetch_mas_trips(
                    this.mas_session_identifier,
                    this.active_trip_date_picker.value,
                    this.active_trip_date_picker.value,
                    true // have to pass true at initial level cause time filter is applied already!
                );
            }
        }, 0);

        this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.processFilters();
        });

        this.driver_search_control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
            this.getDrivers(value);
        });
        this.getDrivers();
        this.getRideBrokerDropdownList();
    }

    get isFilterActive(): boolean {
        if (this.time_picker.start && this.time_picker.end) return true;

        if (
            this.use_date_range &&
            (!moment().isSame(moment(this.active_trip_date_range_picker?.value?.start), 'day') ||
                !moment().isSame(moment(this.active_trip_date_range_picker?.value?.end), 'day'))
        ) {
            return true;
        } else if (!moment().isSame(moment(this.active_trip_date_picker?.value), 'day')) {
            return true;
        }

        if (this.filter_type != 'all') {
            return true;
        }

        if (!this.isStandingOrderTrip) return true;

        return false;
    }

    resetTimeFilter(value: { start: any; end: any } = { start: '', end: '' }) {
        this.time_picker = value;
        this.updateHeaderOption('time-picker', { value: this.time_picker });
        this.processFilters();
    }

    onFilterEvent(event: IOptionItem) {
        switch (event.id) {
            case 'trip-leg-menu':
                this.filter_type = event.value;
                this.processFilters();
                this.toggleAttestationSort(this.sort_order);
                this.getPage(1);
                break;
            case 'time-picker':
                this.time_picker = event.value;
                this.processFilters();
                break;

            case 'standing-order':
                this.isStandingOrderTrip = !this.isStandingOrderTrip;
                this.updateHeaderOption('standing-order', { text: `Standing Order ${this.isStandingOrderTrip ? 'Off' : 'On'}` });
                this.fetch_mas_trips(this.mas_session_identifier, this.active_trip_date_picker.value, this.active_trip_date_picker.value, true);
                break;

            case 'RESET':
                this.isStandingOrderTrip = true;
                this.filter_type = 'all';
                this.time_picker = { start: '', end: '' };
                this.updateHeaderOption('trip-leg-menu', { value: 'all' });
                this.updateHeaderOption('time-picker', { value: this.time_picker });
                this.processFilters();
                if (this.use_date_range) this.active_trip_date_range_picker.patchValue({ start: new Date(), end: new Date() });
                else this.active_trip_date_picker.patchValue(new Date());
                break;
        }
    }

    updateHeaderOption(id: string, change: any) {
        this.headerOptions = this.headerOptions.map((item) => {
            if (item.id === id) {
                item = { ...item, ...change };
            }
            return item;
        });
    }

    private updateSubTabView(date: any) {
        const is_future_date = moment(date).isAfter(moment().endOf('day'));
        const is_todays_date = moment().isSame(moment(date), 'day');

        console.log('is_tdaoy =>', is_todays_date);

        this.unassignedSubTabList = this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Unassigned'].filter((tab: any) => {
            if (is_future_date) {
                return tab?.title !== 'Active';
            } else if (!is_todays_date) {
                return tab?.title !== 'Active';
            } else {
                return true;
            }
        });

        if (!is_todays_date && this.activeSubTab === 'Active') {
            this.activeSubTab = 'All Day';
        }

        if (is_todays_date) {
            // this.activeSubTab = 'Active';
            this.activeSubTab = 'All Day';
        }
    }

    sub_tab_based_filter() {
        if (this.activeSubTab === 'Active') this.all_mas_trips = this.all_mas_trips?.filter((x) => x?.status === 'Eligible');
        this.all_mas_trips = this.masService.filter_cancel_order(this.isCancelOrderTrip, this.all_mas_trips);
    }

    onSubTabChanged(tab: any) {
        this.activeSubTab = tab;
        this.filter_type = 'all';
        this.isCancelOrderTrip = false;

        if (this.activeSubTab === 'Active') {
            this.resetTimeFilter({ start: `${moment().format('HH')}`, end: '23' });
        }

        if (tab === 'All Day') {
            this.resetTimeFilter();
        }

        if (tab === 'Initial Leg') {
            this.filter_type = 'initial';
        }

        if (tab === 'Cancelled') {
            this.isCancelOrderTrip = true;
            this.resetTimeFilter();
        }
    }

    fetch_flag: boolean = true;
    private async fetch_mas_trips(session_identifier: string, start_date: any, end_date: any, apply_filter: boolean = false) {
        this.mas_session_identifier = localStorage.getItem('session_identifier');
        this.isLoading = true;
        this.updateHeaderOption('standing-order', { cssClass: 'disable-icon' });
        this.mas_trips = [];
        this.pageNo = 1;
        const date_list = [];
        this.all_mas_trips = [];
        for (const m = moment(start_date); m.isSameOrBefore(end_date); m.add(1, 'days')) {
            date_list.push(m.format('MM/DD/yyyy'));
        }
        console.log(apply_filter);
        const trip_container = date_list.map((date) => {
            return new Promise(async (resolve, reject) => {
                try {
                    const { trips, message } = await this.masService.fetch_mas_details(date, '', -1, false, apply_filter);
                    resolve(trips);
                } catch (err) {
                    if (
                        (err?.message === 'Session Expired. This session has expired. Please start a new session and resubmit your request.' ||
                            err?.error === 'Authentication Failed. There is no account linked to the key provided.') &&
                        this.fetch_flag
                    ) {
                        await this.utilityService.start_mas_session();
                        // this.mas_session_identifier = localStorage.getItem('session_identifier');
                        session_identifier = this.mas_session_identifier;
                        this.fetch_mas_trips(session_identifier, start_date, end_date, apply_filter);
                        this.fetch_flag = false;
                    }
                    resolve(false);
                }
            }).then(async (trips: any) => {
                if (!trips) return trips;
                const mas_trips = trips.map((trip: any) => {
                    trip.rider_name = trip?.firstName + ' ' + trip?.lastName;
                    trip.user_mobile = trip?.recipientPhone ? this.utilityService.purify_mobile_no(trip?.recipientPhone) : '';
                    trip.pickup_date = trip?.pickupDate;
                    trip.pickup_time = this.masService.get_mas_pickup_time(trip); //moment(`${trip?.pickupTime.slice(0, 2)}:${trip?.pickupTime.slice(2, 4)}`, 'hh:mm a').format('hh:mm a');
                    trip.pickup_location_address = `${trip?.pickupAddress?.address || ''} ${
                        trip?.pickupAddress?.address2 ? trip.pickupAddress.address2 + ',' : ''
                    } ${trip?.pickupAddress?.city ? trip.pickupAddress.city + ',' : ''} ${trip?.pickupAddress?.state || ''} ${
                        trip?.pickupAddress?.zip || ''
                    }`;
                    trip.dropoff_location_address = `${trip?.dropoffAddress?.address || ''} ${
                        trip?.dropoffAddress?.address2 ? trip.dropoffAddress.address2 + ',' : ''
                    } ${trip?.dropoffAddress?.city ? trip.dropoffAddress.city + ',' : ''} ${trip?.dropoffAddress?.state || ''} ${
                        trip?.dropoffAddress?.zip || ''
                    }`;
                    // trip.pickup_location_address = trip?.pickup_location_address === ' , ,  ' ? null : trip?.pickup_location_address;
                    // trip.dropoff_location_address = trip?.dropoff_location_address === ' , ,  ' ? null : trip?.dropoff_location_address;
                    trip.pickup_datetime = this.masService.get_mas_pickup_date_time(trip); // new Date(moment(trip?.pickupDate + ' ' + trip?.pickup_time).toString());
                    trip.active_class = moment(trip?.pickup_datetime).isBetween(moment(), moment().add(1, 'hour')) ? 'bg-active-trip' : '';
                    trip.inactive_class = moment(trip?.pickup_datetime).isBefore(moment()) ? 'bg-inactive-trip' : '';
                    return trip;
                });
                this.all_mas_trips.push(...mas_trips);

                return mas_trips;
            });
        });

        await Promise.all(trip_container);
        try {
            const active_rides: any = await this.get_mas_ride_details().pipe(first()).toPromise();
            const active_leg_id = active_rides?.all_rides?.map((ride: any) => ride.trip_leg_id) || [];

            // this.all_mas_trips = this.masService.filter_cancel_order(this.isCancelOrderTrip, this.all_mas_trips);

            this.all_mas_trips = this.all_mas_trips.filter(
                (ride: any) => !active_leg_id?.includes(ride?.tripLegId) // && !this.masService.ignoreStatus?.includes(ride?.status)
            );
            this.all_mas_trips = (await this.masAssigService.filter_out_assigned_trip(this.all_mas_trips)) as [];

            this.alter_standing_trips =
                this.all_mas_trips?.length - this.masService.filter_standing_order(this.isStandingOrderTrip, this.all_mas_trips)?.length; // this.all_mas_trips?.length - filter_standing_trips?.length;
            this.updateHeaderOption('standing-order', { value: this.alter_standing_trips });

            this.all_mas_trips = this.masService.filter_standing_order(this.isStandingOrderTrip, this.all_mas_trips);

            if (this.rider_name_pre_filter) {
                this.all_mas_trips = this.all_mas_trips.filter(
                    (trip: any) => (trip?.firstName + ' ' + trip?.lastName).toLowerCase() === this.rider_name_pre_filter.toLowerCase()
                );
            }
        } catch (err) {
            console.log('err => ', err);
        }
        // pickup_datetime
        this.toggleAttestationSort('asc');

        // this.all_mas_trips = this.all_mas_trips.filter((trip) => trip.rider_name.toLowerCase() === (this.tripDetail?.user_name).toLowerCase());

        this.backup_all_mas_trips = [...this.all_mas_trips];
        if (this.activeSubTab != 'Cancelled') this.all_mas_trips = this.masService.filter_cancel_order(this.isCancelOrderTrip, this.all_mas_trips);
        this.sub_tab_based_filter();

        this.isLoading = false;
        this.updateHeaderOption('standing-order', { cssClass: '' });
        if (apply_filter) {
            this.processFilters();
        } else {
            this.getPage(1);
        }
        // alert('false');
    }

    cancelTrip(trip: any) {
        this.loading_unassigned_trip_container.push(`canceling:${trip?.tripLegId}`);
        // this.utilityService
        //     .confirm({
        //         heading: 'Cancel this Trip',
        //         description: 'You are CANCELLING this trip, please confirm.',
        //         leftButtonText: 'Dismiss',
        //         rightButtonText: 'Yes',
        //     })
        //     .subscribe((event: { action: 'left' | 'right' }) => {
        //         if (event.action === 'right') {
        this.masAssigService
            .cancel_trip(trip)
            .subscribe(
                (res: { flag: number; log: string; error: string }) => {
                    if (res.flag === 1326) {
                        if (this.live_trip_modal_ref) {
                            this.live_trip_modal_ref.hide();
                            this.live_trip_modal_ref = null;
                        }
                        this.utilityService.toast('success', 'Mas trip ride cancelled successfully');
                        this.all_mas_trips = this.all_mas_trips.filter((item) => item?.tripLegId != trip?.tripLegId);
                        this.backup_all_mas_trips = this.all_mas_trips;
                        this.getPage(this.pageNo);
                    } else {
                        console.log('res => ', res);
                        this.utilityService.toast('error', res?.log || res.error || 'Something went wrong');
                    }
                    // this.fetch_mas_trips(this.mas_session_identifier, this.active_trip_date_picker.value, this.active_trip_date_picker.value, true);
                },
                (err: any) => {
                    console.log(err);
                    this.utilityService.toast('error', err.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.loading_unassigned_trip_container = this.loading_unassigned_trip_container.filter(
                    (item) => item !== `canceling:${trip?.tripLegId}`
                );
            });
        //     }
        // });
    }

    getPage(pageNo: number, itemsPerPage: number = this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
        this.pageNo = pageNo;
        this.total_MAS_items = this.all_mas_trips.length;
        this.showPagination = this.total_MAS_items > 0 && !this.isLoading;
        if (this.total_MAS_items < 1) {
            this.mas_trips = [];
            return;
        }
        this.mas_trips = chunk(this.all_mas_trips, this.itemsPerPage)[this.pageNo - 1];
    }

    // private fetch_mas_details(trip_date: string, invoice_number: string = ''): Promise<any> {
    //     return new Promise((resolve, reject) => {
    //         this.httpService
    //             .post(environment.urlWC + 'mas_trip_roster_request', {
    //                 web_access_token: this.cookieService.get('web_access_token'),
    //                 session_identifier: this.mas_session_identifier,
    //                 invoice_number,
    //                 trip_date,
    //             })
    //             .subscribe(
    //                 (res) => {
    //                     if (res?.message === 'Success.') resolve(res);
    //                     else reject(res);
    //                 },
    //                 (err) => {
    //                     reject(err);
    //                 }
    //             );
    //     });
    // }

    private get_mas_ride_details(): Observable<any> {
        return this.httpService.post(environment.urlC + 'ride_details', {
            web_access_token: this.cookieService.get('web_access_token'),
            all_trip_flag: 1,
        });
    }

    public getStatus(index, req, is_active) {
        switch (index) {
            case 0:
                if (req == 1) {
                    return 'Accepted';
                } else if (req == 10 || (req == null && is_active == 0)) {
                    return 'Missed';
                } else {
                    return 'Assigning';
                }
            case 1:
                return 'Picking Up';
            case 2:
                return 'Arrived';
            case 3:
                return 'En Route';
            case 4:
                return 'Completed';
            case 5:
            case 6:
            case 7:
            case 9:
            case 11:
                return 'Cancelled';
            case 8:
                return 'Unsuccessful';
            default:
                return index + ' ' + req;
        }

        /**
        5 -> Cancelled By Driver;
        6 -> Cancelled By Rider;
        7 -> Cancelled By Rider;
        8 -> Unsuccessful Payment;
        9 -> Cancelled by Admin;
        11 -> Cancelled By Corporate;
        */
    }

    async book_mas_ride(trip: any) {
        this.utilityService.loading = true;
        const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickupDate, trip?.invoiceNumber);
        const mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId === trip?.tripLegId);

        const check_mobile = await this.userService.check_mobile(trip?.user_mobile, trip?.rider_name).pipe(first()).toPromise();
        if (check_mobile?.error && check_mobile?.flag === 1316) {
            const create_user = await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
        }

        if (this.live_trip_modal_ref) {
            this.live_trip_modal_ref.hide();
            this.live_trip_modal_ref = null;
        }

        this.trip_to_book = {
            is_scheduled: 0,
            pickup_latitude: trip?.pickupAddress?.lat,
            pickup_longitude: trip?.pickupAddress?.lon,
            destination_longitude: trip?.dropoffAddress?.lon,
            destination_latitude: trip?.dropoffAddress?.lat,
            promo_code: '',
            car_type: trip?.car_type ? trip?.car_type : 1,
            fare_factor: 1,
            user_mobile: trip?.user_mobile,
            user_name: trip?.rider_name,
            date: trip?.pickup_datetime,
            pickup_location_address: trip?.pickup_location_address,
            manual_destination_address: trip?.dropoff_location_address,
            is_mas_trip: true,
            // mas_active_trip: trip,
            mas_both_leg_trip,
            mas_active_leg_trip,
        };
    }

    open_mas_trip_info_popup(trip: any) {
        this.masService.open_mas_trip_info_popup({
            date: trip?.pickupDate,
            invoice_number: trip?.invoiceNumber,
        });
    }

    toggleAttestationSort(order?: 'asc' | 'desc') {
        if (order) this.sort_order = order;
        else this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc';

        if (this.sort_order === 'asc') {
            this.all_mas_trips.sort((a, b) => Date.parse(a?.pickup_datetime) - Date.parse(b?.pickup_datetime));
        } else if (this.sort_order === 'desc') {
            this.all_mas_trips.sort((a, b) => Date.parse(b?.pickup_datetime) - Date.parse(a?.pickup_datetime));
        }
        this.getPage(1);
    }

    toggleCancelledTrip(flag: boolean) {
        this.isCancelOrderTrip = flag ? true : false;
        this.fetch_mas_trips(this.mas_session_identifier, this.active_trip_date_picker.value, this.active_trip_date_picker.value, true);
    }

    // works for search, pickup location, drop location, and time
    processFilters() {
        if (!this.searchForm.value && !this.pickup_search && !this.dropoff_search && !(this.time_picker.start && this.time_picker.end)) {
            this.all_mas_trips = [...this.backup_all_mas_trips];
        } else {
            this.all_mas_trips = this.backup_all_mas_trips.filter((trip: any) => {
                // search_index
                if (this.searchForm.value) {
                    return (
                        trip?.rider_name?.toLowerCase().includes(this.searchForm.value.trim()) ||
                        trip?.invoiceNumber.toString()?.includes(this.searchForm.value.trim()) ||
                        trip?.tripLegId?.toString()?.includes(this.searchForm.value.trim()) ||
                        trip?.user_mobile.toString()?.includes(this.searchForm.value.trim())
                    );
                }

                // pickup search
                if (this.pickup_search && trip?.pickup_location_address) {
                    return trip?.pickup_location_address?.toLowerCase()?.includes(this.pickup_search.toLowerCase());
                }

                // pickup search
                if (this.dropoff_search && trip?.dropoff_location_address) {
                    return trip?.dropoff_location_address?.toLowerCase()?.includes(this.dropoff_search.toLowerCase());
                }

                if (this.time_picker.start && this.time_picker.end) {
                    const start_time = moment(this.active_trip_date_picker?.value).set({ hour: this.time_picker.start });
                    const end_time = moment(this.active_trip_date_picker?.value).set({ hour: this.time_picker.end, minute: 59 });

                    return moment(trip?.pickup_datetime).isBetween(moment(start_time), moment(end_time));
                }

                return false;
            });
        }

        if (this.filter_type === 'initial') {
            this.all_mas_trips = this.all_mas_trips.filter((trip) => trip?.tripLegSequence === '1');
        }

        this.sub_tab_based_filter();
        this.getPage(1);
    }

    reset_driver_results(id?: string) {
        const searchString = this.driver_search_control?.value;
        if (searchString) {
            this.driver_search_control.reset();
        }
        const element: HTMLElement = document.getElementById(`${id}`) as HTMLElement;
        setTimeout(() => {
            element.focus();
        }, 0);
    }

    getDrivers(searchString: string = '', type: 'mas' | 'all' = 'mas') {
        this.driverLoading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: this.utilityService.MAS_Qudosfave_Map[type === 'mas' ? 'MAS Drivers' : 'All Drivers'],
        };

        if (searchString) {
            params.searchFlag = 1;
            params.searchString = searchString;
        }

        this.httpService
            .post(environment.urlC + 'get_fav_driver', params)
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;

                if (data.flag !== 101 && data.flag !== 807) {
                    this.drivers = data.drivers;
                    // this.isLoading = false;
                }
            })
            .add(() => {
                this.driverLoading = false;
            });
    }

    // Confirmation pop up for assigning trip
    // confirm_assign_driver(driver: any, trip: any) {
    //     this.utilityService
    //         .confirm({
    //             heading: 'Assigning a Driver',
    //             description: `You are assigning ${driver?.driver_name} for this trip, please confirm.`,
    //             leftButtonText: 'Cancel',
    //             rightButtonText: 'Yes',
    //         })
    //         .subscribe((event: { action: 'left' | 'right' }) => {
    //             if (event.action === 'right') {
    //                 this.assign_driver(driver, trip);
    //             }
    //         });
    // }

    async assign_driver(driver: any, trip: any) {
        console.log('drriver: ', driver);
        console.log('trip: ', trip);
        if (!trip) {
            return;
        }
        // Show the modal only if trip_type is 'marketplace' and no other modal is currently awaiting response
        if (trip.trip_type === 'marketplace' && !this.tripAcceptancePromise) {
            $('#acceptSentryMS').modal('show');
            // Create a new object of TripAcceptancePromise and store it
            this.tripAcceptancePromise = new TripAcceptancePromise();
            try {
                // Create a promise and wait for the user's response
                const accepted = await new Promise<boolean>((resolve, reject) => {
                    // Assign resolve and reject functions to the promise object
                    this.tripAcceptancePromise!.resolve = resolve;
                    this.tripAcceptancePromise!.reject = reject;
                });
                // Reset the promise after the user responds
                this.tripAcceptancePromise = null;
                if (!accepted) {
                    return; // Exit the function if the user cancels the trip
                }
            } catch (err) {
                console.log('Error:', err);
                this.tripAcceptancePromise = null; // Reset the promise in case of an error
                return; // Exit the function if there's an error
            }
        }

        try {
            // this.utilityService.loading = true;
            const pickup = new google.maps.LatLng(trip.pickupAddress?.lat, trip?.pickupAddress?.lon);
            const dropoff = new google.maps.LatLng(trip.dropoffAddress?.lat, trip?.dropoffAddress?.lon);

            let eta: any;

            this.loading_unassigned_trip_container.push(`assigning:${trip?.tripLegId}`);

            try {
                eta = await this.mapService
                    .get_eta({
                        origin: pickup,
                        destination: dropoff,
                        travelMode: google.maps.TravelMode.DRIVING,
                        transitOptions: {
                            departureTime: new Date(trip.pickup_datetime),
                        },
                    })
                    .pipe(first())
                    .toPromise();
            } catch (error) {
                console.log('error while fetchin eta time', error);
            }
            if (trip?.type != 'sentryMS') {
                const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickupDate, trip?.invoiceNumber.toString());
                let mas_active_leg_trip = mas_both_leg_trip?.trips.find((x: any) => x.tripLegId.toString() === trip?.tripLegId.toString());
                if (mas_active_leg_trip) {
                    // Chances are, mobile or ridername were updated for Unassigned trip so clone it from the trip ref.
                    mas_active_leg_trip.recipientPhone = trip?.recipientPhone;
                    mas_active_leg_trip.firstName = trip?.firstName;
                    mas_active_leg_trip.lastName = trip?.lastName;
                    trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip); // attach a original trip as ref_trip
                }
            }
            trip.ride_estimate_time = eta?.value || null;
            const user = await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            trip.user_id = user?.user_id;

            const mas_assign = await this.masAssigService
                .assign_driver(
                    this.masAssigService.mas_trip_assign_driver_params(trip, driver, 'unassigned', trip, trip?.rider_name !== trip?.callerName)
                )
                .pipe(first())
                .toPromise();

            if (mas_assign.flag === 1322) {
                this.utilityService.toast('success', 'Driver assigned successfully');
                this.all_mas_trips = this.all_mas_trips.filter((item) => item?.tripLegId != trip?.tripLegId);
                this.getPage(1);
            } else if (mas_assign?.error) {
                this.utilityService.toast('warning', mas_assign?.error);
            }
        } catch (err) {
            console.log('error => ', err);
            this.utilityService.toast('error', err.message || 'Something went wrong');
        } finally {
            // this.utilityService.loading = false;
            this.loading_unassigned_trip_container = this.loading_unassigned_trip_container.filter(
                (item) => item !== `assigning:${trip?.ref_trip?.tripLegId}`
            );
        }

        if (driver) {
            this.cached_drivers.unshift(driver);
            this.cached_drivers = _.uniqBy(this.cached_drivers, 'driver_id');
            this.cookieService.set('cached_filter_driver', JSON.stringify(this.cached_drivers));
        }
    }

    // Method to handle user's response when accepting the trip
    acceptTrip() {
        // Resolve the promise with true when the user clicks 'Yes'
        if (this.tripAcceptancePromise) {
            this.tripAcceptancePromise.resolve(true);
        }
    }

    cancelTripModal() {
        // Reject the promise with false when the user cancels the trip
        if (this.tripAcceptancePromise) {
            this.tripAcceptancePromise.reject(false);
        }
    }

    async queue_mas_trip(trip: any, go_live: boolean = false, prepaid_amount?: number) {
        // Show the modal only if trip_type is 'marketplace' and no other modal is currently awaiting response
        if (trip.trip_type === 'marketplace' && !this.tripAcceptancePromise) {
            $('#acceptSentryMS').modal('show');
            // Create a new object of TripAcceptancePromise and store it
            this.tripAcceptancePromise = new TripAcceptancePromise();
            try {
                // Create a promise and wait for the user's response
                const accepted = await new Promise<boolean>((resolve, reject) => {
                    // Assign resolve and reject functions to the promise object
                    this.tripAcceptancePromise!.resolve = resolve;
                    this.tripAcceptancePromise!.reject = reject;
                });
                // Reset the promise after the user responds
                this.tripAcceptancePromise = null;
                if (!accepted) {
                    return; // Exit the function if the user cancels the trip
                }
            } catch (err) {
                console.log('Error:', err);
                this.tripAcceptancePromise = null; // Reset the promise in case of an error
                return; // Exit the function if there's an error
            }
        }
        try {
            this.loading_unassigned_trip_container.push(`queuing:${trip?.tripLegId}`);
            const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickupDate, trip?.invoiceNumber.toString());
            let mas_active_leg_trip = mas_both_leg_trip?.trips.find((x: any) => x.tripLegId.toString() === trip?.tripLegId.toString());
            if (mas_active_leg_trip) {
                // Chances are, mobile or ridername were updated for Unassigned trip so clone it from the trip ref.
                mas_active_leg_trip.recipientPhone = trip?.recipientPhone;
                mas_active_leg_trip.firstName = trip?.firstName;
                mas_active_leg_trip.lastName = trip?.lastName;

                trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip);
            }
            const user = await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            trip.user_id = user?.user_id;

            const pickup = new google.maps.LatLng(trip.pickupAddress?.lat, trip?.pickupAddress?.lon);
            const dropoff = new google.maps.LatLng(trip.dropoffAddress?.lat, trip?.dropoffAddress?.lon);

            const eta = await this.mapService
                .get_eta({
                    origin: pickup,
                    destination: dropoff,
                    travelMode: google.maps.TravelMode.DRIVING,
                    transitOptions: {
                        departureTime: new Date(trip.pickup_datetime),
                    },
                })
                .pipe(first())
                .toPromise();
            trip.ride_estimate_time = eta?.value;
            const mas_queue = await this.masQueueService
                .queue_mas_trip(trip, trip?.rider_name !== trip?.callerName, go_live, prepaid_amount)
                .pipe(first())
                .toPromise();
            if (mas_queue?.flag) {
                this.utilityService.toast('success', 'Trip queued successfully.');
                this.all_mas_trips = this.all_mas_trips.filter((item) => item?.tripLegId != trip?.tripLegId);
                this.backup_all_mas_trips = this.all_mas_trips;
                this.getPage(1);

                // If a trip operation is prepaid_amount && tripLegSequence is 1 then mark the same for return trip as well.
                if (prepaid_amount && mas_active_leg_trip?.tripLegSequence == 1 && mas_both_leg_trip?.trips?.length >= 2) {
                    try {
                        await this.masQueueService.prepaid_second_leg_trip({
                            invoice_number: mas_active_leg_trip?.invoiceNumber,
                            initial_trip_leg_id: mas_active_leg_trip?.tripLegId,
                            mas_both_leg: mas_both_leg_trip,
                        });
                    } catch (err) {
                        console.log('Error handle_second_leg_trip', err);
                        this.utilityService.toast('error', 'Something went wrong while marking return trip as prepaid');
                    }
                }

                if (prepaid_amount) {
                    this.refetchPrepaidSubject.next();
                }
            } else {
                console.log(mas_queue);
            }
            this.loading_unassigned_trip_container = this.loading_unassigned_trip_container.filter((item) => item !== `queuing:${trip?.tripLegId}`);
        } catch (err) {
            console.log('error => ', err);
            this.utilityService.toast('error', err.message || 'Something went wrong');
            this.loading_unassigned_trip_container = this.loading_unassigned_trip_container.filter((item) => item !== `queuing:${trip?.tripLegId}`);
        }
    }

    queue_all_trip() {
        if (!this.mas_trips.length) return;
        this.utilityService
            .confirm({
                heading: `Queuing all MAS trips`,
                description: `You are marking all mas trips as queued`,
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe(async (event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                }
            });
    }

    live_trip_modal_ref: BsModalRef;
    async open_live_map_view(index: number) {
        const trip = this.mas_trips[index];
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                is_unassigned: true,
                left_action_text: 'Dismiss',
                right_action_text: 'Dispatch',
                trip_type: 'unassigned',
                trip_index: index,
                total_trips: this.mas_trips,
            },
            // ignoreBackdropClick: true,
            ...this.utilityService.mapViewModal,
        };
        // this.utilityService.loading = false;
        this.live_trip_modal_ref = this.modalService.show(LiveTripInfoComponent, initialState);
        this.live_trip_modal_ref.content.event.subscribe((data: { action: 'Dismiss' | 'Dispatch' | null; trip: any }) => {
            const action = data?.action;

            if (action === 'Dismiss') {
                this.live_trip_modal_ref.hide();
                this.live_trip_modal_ref = null;
                // this.cancelTrip(data?.trip);
            } else if (action === 'Dispatch') {
                this.book_mas_ride(data?.trip);
            }
        });
    }

    generateCSV() {
        if (!this.mas_trips.length) return;
        let csvContent = '';
        const csvKey = ['Invoice ID', 'Trip ID', 'Rider Name', 'Rider Mobile', 'Pickup Date & Time', 'Pickup Location', 'Dropoff Location'];
        csvContent += csvKey + '\r\n';
        this.mas_trips.forEach((trip) => {
            const row = [
                trip?.invoiceNumber || '-',
                trip?.tripLegId || '-',
                trip?.rider_name || '-',
                trip?.user_mobile || '-',
                moment(trip?.pickup_datetime).format('MMM DD YYYY hh:mm A') || '-',
                trip?.pickup_location_address?.replace(/[,#]/g, ' ') || '-',
                trip?.dropoff_location_address?.replace(/[,#]/g, ' ') || '-',
            ];
            csvContent += row + '\r\n';
        });

        this.utilityService.generate_csv(`Unassigned trips-${moment(this.active_trip_date_picker?.value).format('Do_MMM')}`, csvContent);
    }

    onDispatched() {
        this.router.navigate(['/', 'corporate', 'live-tracking']);
    }

    view_rider_detail(user_name: string, user_mobile: string) {
        this.userService.new_user_register(user_mobile, user_name, 1).subscribe((res) => {
            if (res?.user_id) {
                this.userService.navigateToUserDetail(res?.user_id);
            }
        });
    }

    @ViewChild('tableHeader') tableHeaderRef: ElementRef;
    showHeaderShadow: boolean = false;
    onScroll(event: any) {
        this.showHeaderShadow = event.srcElement.scrollTop !== 0;
        const rec = this.tableHeaderRef.nativeElement.getBoundingClientRect();
        if (rec?.bottom < 220) {
            this.showBottomSearchBox = true;
        } else {
            this.showBottomSearchBox = false;
        }
    }

    edit_passenger_info(trip: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode: 'edit',
                contact: { name: trip?.rider_name, mobile: trip?.user_mobile },
                title: 'Edit Passenger',
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((contact: { name: string; mobile: string }) => {
            this.backup_all_mas_trips = this.backup_all_mas_trips.map((item) => {
                if (item.tripLegId === trip?.tripLegId) {
                    item.rider_name = contact?.name;
                    item.firstName = contact?.name;
                    item.lastName = ''; // Keeping it empty to defuce complexity;
                    item.user_mobile = contact.mobile;
                    item.recipientPhone = contact?.mobile?.split('-')?.[1];
                }
                return item;
            });
            this.processFilters();
            modalref.hide();
        });
    }

    /** Filter Data By Broker Status */
    selectRideBroker(type: string) {
        this.masService.brokerStatus = type;
        this.masService.brokerStatusLabel = type;
        this.fetch_mas_trips(this.mas_session_identifier, this.active_trip_date_picker.value, this.active_trip_date_picker.value, false);
    }

    /** Ride Broker Drodpwon List */
    getRideBrokerDropdownList() {
        this.httpService
            .post(environment.urlWC + 'list_ride_brokers', { web_access_token: this.cookieService.get('web_access_token') })
            .subscribe((data) => {
                if (data.flag === 2101) {
                    this.listRideBrokers = data.data;
                    this.listRideBrokers = this.listRideBrokers.filter((broker) => broker.company_name !== 'SentryMS');
                    // Add SENTRY-TP and SENTRY-MP
                    this.listRideBrokers.push({
                        id: 14,
                        company_name: 'SMS-TPT',
                        office_number: 'YOUR_OFFICE_NUMBER',
                        contact_person: 'YOUR_CONTACT_PERSON',
                        contact_number: 'YOUR_CONTACT_NUMBER',
                        office_address: 'YOUR_OFFICE_ADDRESS',
                        is_enabled: 1,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                        is_deleted: 0,
                    });
                    this.listRideBrokers.push({
                        id: 15,
                        company_name: 'SMS-MP',
                        office_number: 'YOUR_OFFICE_NUMBER',
                        contact_person: 'YOUR_CONTACT_PERSON',
                        contact_number: 'YOUR_CONTACT_NUMBER',
                        office_address: 'YOUR_OFFICE_ADDRESS',
                        is_enabled: 1,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                        is_deleted: 0,
                    });
                }
            });
    }
}
