import { MoreSettingsComponent } from './pages/more-settings/more-settings.component';
import { CorporateComponent } from './corporate.component';
import { LiveTrackingComponent } from './pages/live-tracking/live-tracking.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TripsComponent } from './pages/trips/trips.component';
import { BankDetailsComponent } from './pages/bank-details/bank-details.component';
import { CardsComponent } from './pages/cards/cards.component';
import { DocumentsComponent } from './pages/documents/documents.component';
import { StaffComponent } from './pages/staff/staff.component';
import { RiderSignupComponent } from './pages/rider-signup/rider-signup.component';
import { TripsDetailComponent } from './pages/trips-detail/trips-detail.component';
import { AuthGuard } from '../core/guards/auth.guard';
import { TransactionHistoryComponent } from './pages/transaction-history/transaction-history.component';

const routes: Routes = [
    {
        path: '',
        component: CorporateComponent,
        canActivate: [AuthGuard],
        children: [
            { path: '', redirectTo: 'live-tracking', pathMatch: 'full' },
            { path: 'live-tracking', component: LiveTrackingComponent },
            { path: 'scheduled-trips', component: TripsComponent, data: { type: 'scheduled' } },
            { path: 'completed-trips', component: TripsComponent, data: { type: 'completed' } },
            { path: 'cancelled-trips', component: TripsComponent, data: { type: 'cancelled' } },

            { path: 'transaction-history-all', component: TransactionHistoryComponent, data: { type: 'all' } },
            { path: 'transaction-history-paid', component: TransactionHistoryComponent, data: { type: 'paid' } },
            { path: 'transaction-history-unpaid', component: TransactionHistoryComponent, data: { type: 'unpaid' } },

            { path: 'more-settings', component: MoreSettingsComponent },
            { path: 'cards', component: CardsComponent },
            { path: 'documents', component: DocumentsComponent },
            { path: 'staff', component: StaffComponent },
            { path: 'bank-details', component: BankDetailsComponent },
            { path: 'trip/:session_id', component: TripsDetailComponent },
            { path: 'riderSignup', component: RiderSignupComponent },

            // { path: 'notifications', component: NotificationsComponent },
            // { path: 'notification-settings', component: NotificationSettingsComponent },

            {
                path: 'earnings',
                loadChildren: () => import('./../corporate/pages/earning/earning.module').then((m) => m.EarningModule),
            },

            {
                path: 'business-center',
                loadChildren: () => import('./../corporate/pages/business-center/business-center.module').then((m) => m.BusinessCenterModule),
            },
            {
                path: 'notifications',
                loadChildren: () => import('./../corporate/pages/notifications/notification.module').then((m) => m.NotificationModule),
            },
            {
                path: 'active-trips',
                loadChildren: () => import('./../corporate/pages/active-trips/active-trip.module').then((m) => m.ActiveTripModule),
            },
            {
                path: 'drivers',
                loadChildren: () => import('./../corporate/pages/drivers/driver.module').then((m) => m.DriverModule),
            },
            {
                path: 'riders',
                loadChildren: () => import('./../corporate/pages/riders/rider.module').then((m) => m.RiderModule),
            },
            {
                path: 'attestation',
                loadChildren: () => import('./../corporate/pages/attestation/attestation.module').then((m) => m.AttestationModule),
            },
            {
                path: 'reports',
                loadChildren: () => import('./pages/reports/reports.module').then((m) => m.ReportsModule),
            },
            // { path: 'active-trips/dispatched', component: ActiveDispatchedTripsComponent },
        ],
    },
];

@NgModule({
    imports: [RouterModule.forChild(routes)],
    exports: [RouterModule],
})
export class CorporateRoutingModule {}
