import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { first, map } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { MasService } from '../mas/mas.service';
import { MasAssignService } from '../mas-assign/mas-assign.service';
import { TripBookingService } from '../trip-booking/trip-booking.service';
import { ISuggestedFare } from 'src/app/models/fare_estimate.model';
import { GoogleMapService } from '../google-map/google-map.service';
import { UserService } from '../user/user.service';
import { Observable, of } from 'rxjs';

enum QUEUED_APIS {
    paging_mas_trip = 'paging_mas_trip',
    get_paging_mas_roster_trip_info = 'get_paging_mas_roster_trip_info',
    get_queue_driver_info = 'get_queue_driver_info',
    edit_queue_mas_trip = 'edit_queue_mas_trip',
    send_acceptance_alert_to_driver = 'send_acceptance_alert_to_driver',
    get_queue_trip_info = 'get_queue_trip_info',
    SET_GO_LIVE = 'set_go_live',
    // get_queued_assigned_trip
    GET_QUEUED_ASSIGNED_TRIP = 'get_queued_assigned_trip',
}

@Injectable({
    providedIn: 'root',
})
export class MasQueueService {
    constructor(
        private cookieService: CookieService,
        private httpService: HttpService,
        private utilityService: UtilityService,
        private masService: MasService,
        private masAssignService: MasAssignService,
        private tripBookingService: TripBookingService,
        private mapService: GoogleMapService,
        private userService: UserService
    ) {}

    // Enable send_caller_info for mas type of trip
    queue_mas_trip(trip: any, send_caller_info: boolean = false, go_live: boolean = false, prepaid_amount?: number) {
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            first_name: trip?.firstName,
            last_name: trip?.lastName,
            user_mobile: trip?.user_mobile,
            pickup_date: trip?.pickupDate,
            pickup_latitude: trip?.pickupAddress?.lat,
            pickup_longitude: trip?.pickupAddress?.lon,
            pickup_location: trip?.pickup_location_address,
            dropoff_location: trip?.dropoff_location_address,
            dropoff_latitude: trip?.dropoffAddress?.lat,
            dropoff_longitude: trip?.dropoffAddress?.lon,
            avoid_tolls: trip?.avoid_tolls,
            avoidHighways: trip?.avoid_highways,
            invoice_number: trip?.invoiceNumber,
            trip_leg_id: trip?.tripLegId,
            pickup_time: trip?.pickup_time,
            offset: new Date().getTimezoneOffset() * -1,
            miles: trip?.legMileage,
            trip_leg_sequence: trip?.tripLegSequence || 0,
            leg_cost: this.masService.calculate_total_leg_cost(trip?.legMileage, trip?.legCost, trip?.secondary_services) || 0,
        };

        // for queue a trip modal
        if (trip?.car_type) {
            payload.car_type = trip?.car_type;
        }

        if (trip?.ride_estimate_time) {
            payload.ride_estimate_time = trip.ride_estimate_time;
        }

        const congestion_tax = this.utilityService.get_secondary_service({ trip, key: 'service_name', value: 'Congestion Fee Single' });
        if (congestion_tax) {
            payload.congestion_tax = congestion_tax?.service_rate;
        }

        if (trip?.pickupAddress?.zip) {
            payload.zip_code = trip?.pickupAddress?.zip;
        }

        if (go_live) {
            payload.go_live = 1;
        }

        if (trip?.estimated_fare) {
            payload.estimated_fare = trip?.estimated_fare;
        }

        if (trip?.corporate_addon) {
            payload.corporate_addon = trip?.corporate_addon;
        }

        if (trip?.ride_discount) {
            payload.ride_discount = trip?.ride_discount;
        }

        if (trip?.fare_factor) {
            payload.fare_factor = trip?.fare_factor;
        }

        if (prepaid_amount != null) {
            payload.is_prepaid = 1;
            payload.prepaid_amount = prepaid_amount;
        }

        if (trip?.ride_broker_id) {
            payload.ride_broker_id = trip?.ride_broker_id;
        }

        if (trip?.type) {
            payload.ride_broker_name = trip?.type;
        }

        if (trip?.type === 'sentryMS') {
            payload.sentryms_trip_type = trip?.trip_type;
            payload.broker_vehicle_id = trip.vehicleId ? trip.vehicleId : '';
            payload.broker_driver_id = trip.driverId ? trip.driverId : '';
        }

        if (prepaid_amount != null) {
            payload.is_prepaid = 1;
            payload.prepaid_amount = prepaid_amount;
        }

        if (trip?.user_id) {
            payload.callerUserId = trip?.user_id;

            if (send_caller_info && trip?.callerName && trip?.callerPhone) {
                payload.callerName = trip?.callerName;
                payload.callerPhone = this.utilityService.purify_mobile_no(trip?.callerPhone);
            }

            if (trip?.DOB) {
                payload.date_of_birth = trip?.DOB;
            }
            if (trip?.gender) {
                payload.gender = ['M', 'F']?.indexOf(trip?.gender);
            }
        }
        return this.httpService.post(environment.urlWC + QUEUED_APIS.paging_mas_trip, payload);
    }

    get_queued_trips(payload: Partial<{ limit: number; offset: number; sort_order?: any; searchFlag: number; searchString?: string }>) {
        return this.httpService.post(environment.urlWC + QUEUED_APIS.get_paging_mas_roster_trip_info, payload).pipe(
            map((res: { trips: any }) => {
                res.trips = res?.trips?.map((item) => {
                    item.pickup_datetime = new Date(
                        moment
                            .utc(item?.pickup_date + ' ' + item?.pickup_time)
                            .local()
                            .toString()
                    );
                    return item;
                });
                return res;
            })
        );
    }

    // Get queue drivers
    get_queue_driver_info(mas_trip_id: any) {
        return this.httpService.post(environment.urlWC + QUEUED_APIS.get_queue_driver_info, {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id,
        });
    }

    edit_queue_mas_trip(payload: {
        mas_trip_id: string | number;
        status?: number;
        ride_status?: number; // 4 -> Complete, 5 -> Cancel
        car_type?: number;
        pickup_time?: any;
        pickup_date?: any;
        third_party_amount?: any;
        gross_fare_amount?: number;
        fare_factor?: number;
        user_toll?: number;
        avoidHighways?: any;
        avoid_tolls?: any;
        will_call?: number;
        pickup_location?: string;
        pickup_latitude?: string;
        pickup_longitude?: string;
        dropoff_location?: string;
        dropoff_latitude?: string;
        dropoff_longitude?: string;
        user_name?: string; // rider name or user name
        user_mobile?: string; // rider mobile or user mobile
        ride_confirmed?: number;
        show_driver_payout?: number;
        is_prepaid?: number;
        prepaid_amount?: number;
    }) {
        const params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id: payload.mas_trip_id,
        };

        if (payload?.pickup_location) {
            params.pickup_location = payload.pickup_location;
            params.pickup_latitude = payload.pickup_latitude;
            params.pickup_longitude = payload.pickup_longitude;
            params.dropoff_location = payload.dropoff_location;
            params.dropoff_latitude = payload.dropoff_latitude;
            params.dropoff_longitude = payload.dropoff_longitude;
        }

        if (payload?.user_name) {
            params.name = payload?.user_name;
        }
        if (payload?.user_mobile) {
            params.mobile = payload?.user_mobile;
        }

        if (payload?.ride_status != null) {
            params.ride_status = payload?.ride_status;
        }

        if (payload?.is_prepaid != null) {
            params.is_prepaid = payload.is_prepaid;
        }

        if (payload?.prepaid_amount !== null) {
            params.prepaid_amount = payload?.prepaid_amount;
        }

        if (payload?.avoidHighways !== null) {
            params.avoidHighways = payload?.avoidHighways;
        }

        if (payload?.avoid_tolls !== null) {
            params.avoid_tolls = payload?.avoid_tolls;
        }

        if (payload?.status) {
            params.status = payload.status;
        } else {
            if (payload?.pickup_time && payload.pickup_date) {
                params.pickup_time = payload.pickup_time;
                params.pickup_date = payload.pickup_date;
            }
            if (payload?.car_type) {
                params.car_type = payload.car_type;
            }

            if (payload?.third_party_amount) {
                params.third_party_amount = payload.third_party_amount;
            }

            if (payload?.gross_fare_amount) {
                params.gross_fare_amount = payload.gross_fare_amount;
            }

            if (payload?.fare_factor) {
                params.fare_factor = payload.fare_factor;
            }

            if (payload?.user_toll) {
                params.user_toll = payload.user_toll;
            }

            if (payload?.will_call === 0 || payload?.will_call === 1) {
                params.will_call = payload?.will_call;
            }

            if (payload?.ride_confirmed !== null) {
                params.ride_confirmed = payload.ride_confirmed;
            }
            if (payload?.show_driver_payout === 0 || payload?.show_driver_payout === 1) {
                params.show_driver_payout = payload.show_driver_payout;
            }
        }
        return this.httpService.post(environment.urlWC + QUEUED_APIS.edit_queue_mas_trip, params);
    }

    send_driver_alert(params: { mas_trip_id: number | string; driver_id: number | string; web_access_token: string }) {
        return this.httpService.post(environment.urlWC + QUEUED_APIS.send_acceptance_alert_to_driver, params);
    }

    get_queue_trip_info(mas_trip_id: number | string) {
        return this.httpService.post(environment.urlWC + QUEUED_APIS.get_queue_trip_info, {
            mas_trip_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    update_live_status(mas_trip_id: number | string, go_live: number) {
        return this.httpService.post(environment.urlWC + QUEUED_APIS.SET_GO_LIVE, {
            mas_trip_id,
            go_live,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }

    // Auto Prepaid Flow
    // Update alternative leg of the trip for live and prepaid operation
    async prepaid_second_leg_trip(payload: {
        invoice_number: string;
        initial_trip_leg_id: string; // initial_leg_id
        // isUnassigned: boolean // If unassigned use the return trip as the alternative trip and avoid the refetch from mas.
        date?: string; // Must be passed if return_trip is not passed
        mas_both_leg?: any; // Unassigned trip only as per the current integration
    }) {
        console.log('Initiate prepaid second trip flow');
        let mas_both_leg: any;
        if (payload?.mas_both_leg) {
            mas_both_leg = payload?.mas_both_leg;
        } else {
            mas_both_leg = await this.masService.fetch_mas_details(payload?.date, payload.invoice_number);
        }

        let return_leg_trip = mas_both_leg?.trips?.find((item) => item.tripLegId != payload.initial_trip_leg_id);
        if (!return_leg_trip) return;

        // Check if it is assigned or queued
        const { trips: assign_queue_history } = await this.masAssignService
            .get_assign_driver_mas_trip(return_leg_trip?.tripLegId)
            .pipe(first())
            .toPromise();
        const history_match: { sent_to: any; mas_trip_id: number | string } = assign_queue_history?.find(
            (item) => item.trip_leg_id == return_leg_trip?.tripLegId
        );
        console.log('history match => ', history_match);

        // If true, either trip is assigned or queued so follow update trip process from here
        if (history_match) {
            if (history_match?.sent_to) {
                // If true, it mean the trip is assigned

                // Fetch the fresh trip data to get the latest location changes
                const { trip: assigned_trip } = await this.masAssignService
                    .get_assigned_trip_info(history_match?.mas_trip_id)
                    .pipe(first())
                    .toPromise();

                // check if it is already prepaid, if true then no changes required
                if (assigned_trip?.is_prepaid == 1) return;

                // Fetch suggsted fare for assigned trip
                const suggested_fare: ISuggestedFare = await this.get_assign_queue_unassigned_suggested_fare(assigned_trip, 'assigned')
                    .pipe(first())
                    .toPromise();

                // Update assigned trip;
                return this.masAssignService
                    .edit_assign_mas_trip({
                        mas_trip_id: assigned_trip?.mas_trip_id,
                        is_prepaid: 1,
                        prepaid_amount: suggested_fare?.total_amount,
                    })
                    .pipe(first())
                    .toPromise();
            } else {
                // Fetch the fresh trip data to get the latest location changes
                const { trip: queued_trip } = await this.get_queue_trip_info(history_match?.mas_trip_id).pipe(first()).toPromise();

                // check if it is already prepaid, if true then no changes required
                if (queued_trip?.is_prepaid == 1) return;

                // Fetch suggsted fare for assigned trip
                const suggested_fare: ISuggestedFare = await this.get_assign_queue_unassigned_suggested_fare(queued_trip, 'queued')
                    .pipe(first())
                    .toPromise();
                // Update assigned trip;
                return this.edit_queue_mas_trip({
                    mas_trip_id: queued_trip?.mas_trip_id,
                    is_prepaid: 1,
                    prepaid_amount: suggested_fare?.total_amount,
                    third_party_amount: suggested_fare?.total_amount,
                    gross_fare_amount: suggested_fare?.total_amount,
                })
                    .pipe(first())
                    .toPromise();
            }
        } else {
            // return_leg_trip = this.utilityService.pre_process_mas_trip(return_leg_trip);
            return_leg_trip.pickup_time = this.masService.get_mas_pickup_time(return_leg_trip);
            return_leg_trip.user_mobile = this.utilityService.purify_mobile_no(return_leg_trip?.recipientPhone);
            return_leg_trip.pickup_datetime = this.masService.get_mas_pickup_date_time(return_leg_trip);
            return_leg_trip.pickup_location_address = `${return_leg_trip?.pickupAddress?.address} ${return_leg_trip?.pickupAddress?.address2}, ${return_leg_trip?.pickupAddress?.city}, ${return_leg_trip?.pickupAddress?.state} ${return_leg_trip?.pickupAddress?.zip}`;
            return_leg_trip.dropoff_location_address = `${return_leg_trip?.dropoffAddress?.address} ${return_leg_trip?.dropoffAddress?.address2}, ${return_leg_trip?.dropoffAddress?.city}, ${return_leg_trip?.dropoffAddress?.state} ${return_leg_trip?.dropoffAddress?.zip}`;
            return_leg_trip.pickup_location_address =
                return_leg_trip?.pickup_location_address == ' , ,  ' ? null : return_leg_trip?.pickup_location_address;
            return_leg_trip.dropoff_location_address =
                return_leg_trip?.dropoff_location_address == ' , ,  ' ? null : return_leg_trip?.dropoff_location_address;

            const user = await this.userService
                .new_user_register(return_leg_trip?.user_mobile, `${return_leg_trip?.firstName} ${return_leg_trip?.lastName}`, 1)
                .pipe(first())
                .toPromise();
            return_leg_trip.user_id = user?.user_id;

            const pickup = new google.maps.LatLng(return_leg_trip.pickupAddress?.lat, return_leg_trip?.pickupAddress?.lon);
            const dropoff = new google.maps.LatLng(return_leg_trip.dropoffAddress?.lat, return_leg_trip?.dropoffAddress?.lon);

            const eta = await this.mapService
                .get_eta({
                    origin: pickup,
                    destination: dropoff,
                    travelMode: google.maps.TravelMode.DRIVING,
                    transitOptions: {
                        departureTime: new Date(return_leg_trip.pickup_datetime),
                    },
                })
                .pipe(first())
                .toPromise();
            return_leg_trip.ride_estimate_time = eta?.value;

            const suggested_fare: ISuggestedFare = await this.get_assign_queue_unassigned_suggested_fare(return_leg_trip, 'unassigned')
                .pipe(first())
                .toPromise();

            return this.queue_mas_trip(
                return_leg_trip,
                return_leg_trip?.rider_name !== return_leg_trip?.callerName,
                false,
                suggested_fare?.total_amount
            )
                .pipe(first())
                .toPromise();
        }
    }

    private get_assign_queue_unassigned_suggested_fare(trip: any, trip_type: 'assigned' | 'queued' | 'unassigned') {
        const payload: any = {
            car_type: trip?.car_type || 1,
            avoid_tolls: this.mapService.defaultAvoidTolls,
            avoid_highways: this.mapService.defaultAvoidHighways,
        };
        if (trip_type === 'assigned' || trip_type === 'queued') {
            payload.pickup_latitude = trip?.pickup_latitude;
            payload.pickup_longitude = trip?.pickup_longitude;
            payload.destination_latitude = trip?.dropoff_latitude;
            payload.destination_longitude = trip?.dropoff_longitude;
        } else {
            payload.pickup_latitude = trip.pickupAddress?.lat;
            payload.pickup_longitude = trip.pickupAddress?.lon;
            payload.destination_latitude = trip.dropoffAddress?.lat;
            payload.destination_longitude = trip.dropoffAddress?.lon;
        }

        return this.tripBookingService.getSuggestedFare(payload);
    }

    public get_queued_assigned_trip(payload: {
        offset: number;
        limit: number;
        date_filter: number;
        start_date?: string;
        end_date?: string;
        time_filter: number;
        start_time?: string;
        end_time?: string;
        searchFlag: number;
        searchString?: string;
        web_access_token?: string;
    }): Observable<any> {
        payload.web_access_token = this.cookieService.get('web_access_token');
        return this.httpService.post(`${environment.urlWC}/${QUEUED_APIS.GET_QUEUED_ASSIGNED_TRIP}`, payload);
    }
}
