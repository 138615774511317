import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { ICreatePayout } from 'src/app/models/business-center.model';
import { BankService } from '../../services/bank/bank.service';
import { BusinessCenterService } from '../../services/business-center/business-center.service';

@Component({
    selector: 'app-create-payout-confirm-modal',
    templateUrl: './create-payout-confirm-modal.component.html',
    styleUrls: ['./create-payout-confirm-modal.component.scss'],
})
export class CreatePayoutConfirmModalComponent implements OnInit, OnDestroy {
    constructor(
        public bsModalRef: BsModalRef,
        private businessService: BusinessCenterService,
        public utilityService: UtilityService,
        private bankService: BankService
    ) {}

    confirm_form: FormControl = new FormControl(false, [Validators.requiredTrue]);
    title: string = 'Create a payout';
    total_payout: number = 0;
    total_trips: number = 0;
    payout_payload: ICreatePayout;

    public event: EventEmitter<any> = new EventEmitter();
    private subscriptionList$: Subscription = new Subscription();

    balanceLoading: boolean = false;
    available_wallet_balance: number | string;
    ngOnInit(): void {
        this.get_wallet_balance();
    }

    get_wallet_balance() {
        this.balanceLoading = true;
        this.businessService
            .get_wallet_balance()
            .subscribe(
                (res: { availableBalance: number; onTheWay: number; willBeAvailableSoon: number; flag: number; log: string; error?: string }) => {
                    if (res?.flag === 20002) {
                        this.available_wallet_balance = res?.availableBalance;
                    } else if (res?.flag === 3200) {
                        this.utilityService.toast('info', res?.error);
                    } else if (res?.error) {
                        this.utilityService.toast('error', res?.error);
                    } else {
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.balanceLoading = false;
            });
    }

    create_payout() {
        this.utilityService.loading = true;
        this.bankService
            .create_payout(this.payout_payload)
            .subscribe((res: { flag: number; log: string; error?: string }) => {
                if (res?.flag === 20001) {
                    this.utilityService.toast('success', res?.log?.charAt(0).toUpperCase() + res?.log?.slice(1));
                    this.event.emit(true);
                    this.bsModalRef.hide();
                } else {
                    console.log(res);
                    this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                }
            })
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }
}
