export interface Card {
    id: number | string;
    last4_digits: number | string;
    default_card: 0 | 1;
    card_added_on: Date | string;
    offset: number | string;
    card_type: number | string;
}

export interface ListPaymentCards {
    corporateCards: { default_card_id: string | number; cards: Card[] };
    riderCards: { default_card_id: string | number; cards: Card[] };
}
export interface ListCardPaymentMethod {
    payment_type: Array<{
        card_used: number;
    }>;
}

export enum PaymentOptionType {
    corporate = 'corporate',
    rider = 'rider',
    both = 'both',
}

export const PaymentOptionMap = {
    1: PaymentOptionType.both,
    2: PaymentOptionType.corporate,
    3: PaymentOptionType.rider,
};
