import { Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { TripBookingService } from '../../services/trip-booking/trip-booking.service';
import { CookieService } from 'ngx-cookie-service';
import { UserService } from '../../services/user/user.service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { debounceTime, first } from 'rxjs/operators';
import { GoogleMapService } from '../../services/google-map/google-map.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as StaticDataConfig from './../../../core/services/utility/static-data.config';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { PromoCodeComponent } from '../promo-code/promo-code.component';
import { PremiumOptionsComponent } from '../premium-options/premium-options.component';
import { FareBreakdownComponent } from '../fare-breakdown/fare-breakdown.component';

@Component({
    selector: 'app-fare-estimate-recalculation',
    templateUrl: './fare-estimate-recalculation.component.html',
    styleUrls: ['./fare-estimate-recalculation.component.scss'],
})
export class FareEstimateRecalculationComponent implements OnInit {
    public event: EventEmitter<any> = new EventEmitter();
    menuActive: String = 'fare';
    formValue: any;
    from: any;
    booking: any = {};
    avoid_tolls: boolean = this.googleMapService.defaultAvoidTolls;
    avoid_highways: boolean = this.googleMapService.defaultAvoidHighways;
    fare_factor: number = 1; // value which will be shared for api parameters
    carDriverOptions: Array<any> = [];
    fare_breakdown: any = {};

    min_ride_request_distance: any;
    max_schedule_ride_days_count: any;
    max_schedule_ride_request_distance: number;
    max_ride_request_distance: number;
    ride_estimate_distance: any;

    premiumOption: FormGroup = new FormGroup({
        pet_friendly_car: new FormControl(false),
        plexiglass_partition: new FormControl(false),
        through_door_service: new FormControl(false),
    });
    premiumOption_Backup: any;
    activeOption: string;
    premium_option_confirmation = StaticDataConfig.PremiumOptionConfirmation;
    premiumOptionLabel: string;

    center: { lat: number; lng: number } = { lat: 40.73221, lng: -73.91902 };
    zoom = 11;
    directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
    directionsDisplay: google.maps.DirectionsRenderer;

    map: google.maps.Map;
    trip_distance: string | number;
    trip_duration: string | number;

    selected_driver: any;
    driverSearchControl: FormControl = new FormControl('');
    drivers: Array<any> = [];
    promo_applied: any;

    active_fare_breakdown_flag: 'now' | 'later' = 'now';
    ismultipliear: boolean = true;
    recalculate_options: any = [];

    fareEstimatePayload: any = {};
    custom_recalculate_multiplier: boolean = false;

    corporateAddOnValue: FormControl = new FormControl(null, [Validators.min(1), Validators.max(100)]);
    corporate_addon: any = 0;
    @ViewChild('modalInput') modalInput: any;
    @ViewChild('modalInputDiscountPin') modalInputDiscountPin: any;
    @ViewChild('modalInputDiscount') modalInputDiscount: any;

    // Ride Discount
    idPinValidate: boolean = false;
    discountPin: FormControl = new FormControl(null, [Validators.min(3), Validators.max(4)]);
    rideDiscountControl: FormControl = new FormControl(null, [Validators.min(1), Validators.max(100)]);

    ride_discount: number = 0;
    recalculate_price_loading: boolean = false;
    recalculate_state: any;
    recalculate_type: 'now' | 'later' = 'now';

    constructor(
        public bsModalRef: BsModalRef,
        private tripBookingService: TripBookingService,
        public cookieService: CookieService,
        private userService: UserService,
        public utilityService: UtilityService,
        private googleMapService: GoogleMapService,
        public httpService: HttpService,
        private modalService: BsModalService
    ) {}

    ngOnInit(): void {
        console.log(this.formValue);
        if (this.formValue) {
            let userMobile;
            if (this.formValue?.phone_number?.dialCode) {
                userMobile = `${this.formValue?.phone_number?.dialCode}-${this.formValue?.phone_number?.number}`;
            } else {
                userMobile = `${this.formValue?.countrycode}${this.formValue?.user_mobile}`;
            }
            this.booking.pickup_latitude = this.formValue.pickup_lat ? this.formValue.pickup_lat : this.formValue.current_latitude;
            this.booking.pickup_longitude = this.formValue.pickup_lang ? this.formValue.pickup_lang : this.formValue.current_longitude;
            this.booking.destination_latitude = this.formValue.dropoff_lat ? this.formValue.dropoff_lat : this.formValue.manual_destination_latitude;
            this.booking.destination_longitude = this.formValue.dropoff_lang
                ? this.formValue.dropoff_lang
                : this.formValue.manual_destination_longitude;
            this.booking.car_type = this.formValue.car_type;
            this.booking.user_mobile = this.formValue.userMobileListDetail ? this.formValue.userMobileListDetail : userMobile;
            this.booking.pickup_city = this.formValue.pickup_location ? this.formValue.pickup_location : this.formValue.pickup_location_address;
            this.booking.pickup_location_address = this.formValue.pickup_location
                ? this.formValue.pickup_location
                : this.formValue.pickup_location_address;
            this.booking.manual_destination_address = this.formValue.dropoff_location
                ? this.formValue.dropoff_location
                : this.formValue.manual_destination_address;
            this.booking.pickup_country = this.formValue?.phone_number?.dialCode
                ? this.formValue?.phone_number?.dialCode
                : this.formValue?.countrycode;
            this.booking.autoHitApi = 1;

            /** For Avoid Highway and Tolls As well Corporate Add On */
            if (this.from == 'list-detail') {
                this.avoid_tolls = this.formValue.avoid_tolls == 1 ? true : false;
                this.avoid_highways = this.formValue?.avoid_highways == 1 ? true : false;
                this.corporateAddOnValue.setValue(this.formValue?.corporate_addon);
                this.rideDiscountControl.setValue(this.formValue?.ride_discount);
                this.booking.mas_trip_id = this.formValue?.mas_trip_id;
            }

            this.fareEstimatePayload = {
                is_scheduled: 0,
                pickup_latitude: this.booking.pickup_latitude,
                pickup_longitude: this.booking.pickup_longitude,
                destination_latitude: this.booking.destination_latitude,
                destination_longitude: this.booking.destination_longitude,
                promo_code: this.booking.promo_code,
                web_access_token: this.cookieService.get('web_access_token'),
                car_type: this.booking.car_type,
                user_mobile: this.booking.user_mobile,
                avoid_tolls: this.avoid_tolls ? 1 : 0,
                avoid_highways: this.avoid_highways ? 1 : 0,
                corporate_addon: this.corporateAddOnValue.value,
                ride_discount: this.rideDiscountControl.value,
                fare_factor: this.fare_factor,
                pickup_city: this.booking.pickup_city,
                pickup_country: this.booking.pickup_country,
                mas_trip_id: this.booking?.mas_trip_id,
            };

            this.directionsDisplay = new google.maps.DirectionsRenderer();
            this.getFareEstimate();

            this.driverSearchControl.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                value = value.trim();
                this.getDriver(value);
            });
            this.getDriver();
        }
    }

    /** Menu Toggle */
    menuToggle(type: string) {
        this.menuActive = type;
        type == 'calculation' ? this.getMultiplier() : '';
        this.get_new_fare_estimateion(this.fare_factor);
    }

    /** Close Modal */
    closePopup() {
        this.bsModalRef.hide();
        this.rideDiscountControl.setValue(0);
        this.fareEstimatePayload.ride_discount = this.rideDiscountControl.value;
    }

    /** Avoid Tollas and hightways */
    public getDirectionsForBooking(proceed_for_fare_estimate: boolean = false) {
        if (this.fareEstimatePayload.pickup_latitude && this.fareEstimatePayload.destination_latitude) {
            this.directionsDisplay.setMap(null);
            this.directionsDisplay.setMap(this.map);
            this.directionsDisplay.setPanel(document.getElementById('panel'));
            const origin = this.fareEstimatePayload.pickup_latitude + ', ' + this.fareEstimatePayload.pickup_longitude;
            const destination = this.fareEstimatePayload.destination_latitude + ', ' + this.fareEstimatePayload.destination_longitude;
            const request: any = {
                origin: origin,
                destination: destination,
                travelMode: google.maps.TravelMode.DRIVING,
                avoidHighways: this.avoid_highways,
                avoidTolls: this.avoid_tolls,
            };
            this.booking.polylines = 'NOT FOUND YET';
            this.directionsService.route(request, (response, status) => {
                if (status == google.maps.DirectionsStatus.OK) {
                    this.directionsDisplay.setDirections(response);
                    this.booking.polylines = response.routes[0].overview_polyline;
                    if (proceed_for_fare_estimate) {
                        (this.fareEstimatePayload.avoid_highways = this.avoid_highways ? 1 : 0),
                            (this.fareEstimatePayload.avoid_tolls = this.avoid_tolls ? 1 : 0),
                            this.getFareEstimate();
                    }
                }
            });
        }
    }

    /** Change Car type  */
    changeCarType(carType: any) {
        this.booking.car_type = carType?.car_type;
        this.fareEstimatePayload.car_type = carType?.car_type;
        this.getFareEstimate();
    }

    /** Get Fare Estimate */
    public async getFareEstimate() {
        this.utilityService.loading = true;
        // check for mobile
        const check_mobile: any = await this.userService.check_mobile(this.booking.user_mobile, this.booking.user_name).pipe(first()).toPromise();
        if (check_mobile?.error && check_mobile?.flag === 1316) {
            await this.userService.new_user_register(this.booking.user_mobile, this.booking.user_name, 1).pipe(first()).toPromise();
        }
        const geocoder = new google.maps.Geocoder();
        if (this.booking.pickup_location_address || (this.booking.current_latitude && this.booking.current_longitude)) {
            const geoCoderPayload: { address?: string; location?: { lat: number; lng: number } } = {};
            if (this.booking.pickup_location_address) {
                geoCoderPayload.address = this.booking.pickup_location_address;
            }
            if (this.booking.current_latitude) {
                geoCoderPayload.location = {
                    lat: parseFloat(this.booking.current_latitude),
                    lng: parseFloat(this.booking.current_longitude),
                };
            }
            try {
                const geocoderResponse: any = await geocoder.geocode(geoCoderPayload);
                if (geocoderResponse.results.length) {
                    const place: any = geocoderResponse.results[0];
                    place.address_components.find((item) => {
                        if (item.types.includes('locality')) {
                            this.booking.pickup_city = item.long_name;
                        }
                        if (item.types.includes('country')) {
                            this.booking.pickup_country = item.long_name;
                        }
                    });
                }
            } catch (error) {
                this.booking.pickup_city = 'New York';
                this.booking.country = 'United States';
                console.log('Err', error);
            }
        }

        const premium_option = this.getPremiumOption();
        if (premium_option) {
            this.fareEstimatePayload['premium_options'] = premium_option;
        }
        this.tripBookingService.getFareEstimate(this.fareEstimatePayload).subscribe(
            (data) => {
                if (data.flag == 101) {
                } else if (data.flag == 1316) {
                    this.utilityService.loading = false;
                    this.utilityService.toast('warning', data.error, '');
                    this.booking.user_id = data.user_id;
                } else if (data.flag == 1317) {
                    this.utilityService.toast('warning', data.error, '');
                    this.booking.user_id = data.user_id;
                    this.utilityService.loading = false;
                } else if (data.error || data.flag == 0) {
                    this.utilityService.toast('error', data.error || data.message, '');
                    this.utilityService.loading = false;
                    return;
                } else {
                    this.min_ride_request_distance = data.min_ride_request_distance;
                    this.max_schedule_ride_days_count = data.max_schedule_ride_days_count;
                    this.max_schedule_ride_request_distance = data.max_schedule_ride_request_distance * 1609.344;
                    this.max_ride_request_distance = data.max_ride_request_distance * 1609.344;
                    this.ride_estimate_distance = data.ride_estimate_distance;

                    if (this.ride_estimate_distance <= this.min_ride_request_distance) {
                        this.utilityService.toast('error', 'Ride Distance too short, Please choose another Destination', '');
                        this.utilityService.loading = false;
                        return false;
                    } else if (this.ride_estimate_distance > this.max_ride_request_distance) {
                        this.utilityService.toast('error', 'Destination too far, Please choose another Destination', '');
                        this.utilityService.loading = false;
                        return false;
                    }
                    // this.cars_option = [];
                    const carsData = data.estimated_fare;
                    this.carDriverOptions = carsData;
                    this.booking.user_id = data.user_id;
                    this.booking.user_name = data.user_name;
                    this.booking.ride_estimate_time = data.ride_estimate_time;
                    this.booking.ride_estimate_distance = data.ride_estimate_distance;
                    this.booking.toll = data.toll;
                    this.booking.route = data.route;
                    this.tripBookingService
                        .getTripImage(
                            { lat: this.booking.pickup_latitude, long: this.booking.pickup_longitude },
                            { lat: this.booking.destination_latitude, long: this.booking.destination_longitude },
                            this.avoid_tolls,
                            this.avoid_highways
                        )
                        .then((data: { image: string; polylines: string }) => {
                            if (data.image) {
                                this.booking.route_image = data.image;
                                this.booking.polylines = data.polylines;
                                this.booking.route_directions =
                                    'https://maps.googleapis.com/maps/api/staticmap?size=350x600&path=color:0x00000cd0|weight:5|enc:' +
                                    this.booking.polylines +
                                    '&markers=shadow:false|scale:2|color:green|label:A|' +
                                    this.booking.pickup_latitude +
                                    ',' +
                                    this.booking.pickup_longitude +
                                    '&markers=color:red|label:B|shadow:false|scale:2|' +
                                    this.booking.destination_latitude +
                                    ',' +
                                    this.booking.destination_longitude +
                                    '&key=AIzaSyADXfBi40lkKpklejdGIWNxdkqQCKz8aKI';
                            }
                        });
                    const current_car_option: any = carsData.find((option) => option.car_type === this.booking.car_type);
                    this.fare_breakdown = current_car_option;
                    const fare = current_car_option?.value_regular;
                    const sche_fare = current_car_option?.value_scheduled;
                    this.booking.estimated_fare = fare;
                    this.booking.estimated_fare_later = sche_fare;
                    this.booking.estimated_fare_original = fare;
                    this.booking.estimated_fare_later_original = sche_fare;
                    const fare_old = current_car_option?.regular_ride_without_discount;
                    const sche_fare_old = current_car_option?.schedule_ride_without_discount;
                    this.booking.estimated_fare_old = fare_old;
                    this.booking.estimated_fare_later_old = sche_fare_old;
                    this.booking.sales_tax = current_car_option.sales_tax;
                    if (!this.booking.promo_code) {
                        this.booking.promo_code = '';
                    }
                    if (data.promo_data.code) {
                        this.booking.promo_code = data.promo_data.code;
                    }
                    this.booking.promo_value = data.promo_data.value;
                }
                this.utilityService.loading = false;
            },
            (error) => {
                this.utilityService.alert('error', 'Something went Wrong!');
                this.utilityService.loading = false;
            }
        );
    }

    public get_new_fare_estimateion(new_fare_factor: any) {
        if (new_fare_factor < 0) return;
        this.fareEstimatePayload.fare_factor = new_fare_factor;
        this.recalculate_price_loading = true;
        this.tripBookingService
            .getFareEstimate(this.fareEstimatePayload)
            .subscribe(
                (data) => {
                    if (data.flag != 101 && data.flag != 1316 && data.flag != 1317 && !(data.error || data.flag == 0)) {
                        const cars_option = data.estimated_fare;
                        const current_car_option: any = cars_option.find((option) => option.car_type === this.fareEstimatePayload.car_type);
                        this.recalculate_state = current_car_option;
                    } else {
                        this.utilityService.toast('warning', 'Something went wrong');
                    }
                },
                (error) => {
                    this.utilityService.alert('error', 'Something went Wrong!');
                    this.utilityService.loading = false;
                }
            )
            .add(() => {
                this.recalculate_price_loading = false;
            });
    }

    /** Get Premium Option */
    getPremiumOption() {
        let po = this.premiumOption.value;
        const keys = Object.keys(po);
        let premium_option = {};
        let flag: boolean = false;
        keys.forEach((k) => {
            if (po[k]) {
                flag = true;
                premium_option[k] = 1;
            } else {
                premium_option[k] = 0;
            }
        });
        if (flag) return premium_option;
        return false;
    }

    ngAfterViewInit() {
        this.initializeMap();
    }

    /** Map Initialise */
    @ViewChild('FareEstimateCelculateMap') FareEstimateCelculateMap: ElementRef;

    initializeMap() {
        // if (!this.map) {
        this.map = new google.maps.Map(this.FareEstimateCelculateMap?.nativeElement, {
            center: this.center, //{ lat: -34.397, lng: 150.644 },
            zoom: this.zoom,
            mapTypeId: google.maps.MapTypeId.TERRAIN,
            disableDefaultUI: true,
            mapTypeControl: false,
            fullscreenControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_BOTTOM,
            },
            streetViewControl: false,
        });
        // }
        this.directionsDisplay.setMap(this.map);
        const pickup = this.booking?.pickup_latitude + ',' + this.booking?.pickup_longitude;
        const dropoff = this.booking?.destination_latitude + ',' + this.booking?.destination_longitude;
        const request: google.maps.DirectionsRequest = {
            origin: pickup,
            destination: dropoff,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: this.avoid_tolls,
            avoidHighways: this.avoid_highways,
        };
        this.directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);
                this.trip_distance = response?.routes[0]?.legs[0]?.distance?.text;
                this.trip_duration = response?.routes[0]?.legs[0]?.duration?.text;
            }
        });
    }

    // for far_estimate
    getDriver(searchString: string = '') {
        this.httpService
            .post(environment.urlC + 'get_fav_driver', {
                web_access_token: this.cookieService.get('web_access_token'),
                limit: 20,
                offset: 0,
                searchFlag: searchString ? 1 : 0,
                searchString,
                tab_type: this.utilityService.MAS_Qudosfave_Map[this.booking?.is_mas_trip === 'mas' ? 'MAS Drivers' : 'All Drivers'],
            })
            .subscribe((data: any) => {
                if (data?.flag !== 101 && data?.flag !== 807) {
                    this.drivers = data?.drivers;
                }
            });
    }

    /** Open Promo Code */
    open_promo_code_popup() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                promo_code_applied: this.booking.promo_code ? true : false,
                promo_code: this.booking.promo_code,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(PromoCodeComponent, initialState);
        modalref.content.event.subscribe((code: string) => {
            this.promo_applied = code ? 1 : '';
            this.booking.promo_code = code || '';
        });
    }

    /** Open Premium Option popup */
    open_premium_option_popup() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                premiumOption: this.premiumOption,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(PremiumOptionsComponent, initialState);
        modalref.content.event.subscribe((value: any) => {
            console.log(value);
            this.premiumOption.patchValue(value);
        });
    }

    /** View Fare Breakdown */
    open_fare_breakdown_popup(type: 'now' | 'later') {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                fare_breakdown: this.fare_breakdown,
                active_fare_breakdown_flag: type,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(FareBreakdownComponent, initialState);
        modalref.content.event.subscribe((res: { type: 'cancel' | 'book-ride'; data: any }) => {
            console.log(res);
        });
    }

    /** Multiplier Get */
    getMultiplier() {
        this.httpService
            .postJSON(environment.urlC + 'mapped_fare_estimate', this.fareEstimatePayload)
            .subscribe((data) => {
                this.recalculate_options = data;
                this.ismultipliear = false;
            })
            .add(() => {
                this.ismultipliear = false;
            });
    }

    toggle_recalculate_type() {
        this.custom_recalculate_multiplier = !this.custom_recalculate_multiplier;
        if (this.fare_factor === 1) return;
        this.fare_factor = 1;
    }

    /** Manage Zero */
    handleInputClick() {
        const inputValue = this.corporateAddOnValue.value;
        if (inputValue == '0') {
            // If the value is "zeno," set it to an empty string or null
            this.corporateAddOnValue.setValue(null); // or this.corporateAddOnValue.setValue('');
        }
    }

    /** Open Modal */
    openModal() {
        setTimeout(() => {
            const inputValue = this.modalInput.nativeElement.value;
            if (inputValue === '0') {
                // Remove the 0
                this.modalInput.nativeElement.value = null;
            }
            // Focus the input
            this.modalInput.nativeElement.focus();
        }, 0);
    }

    /** Open Modal Ride */
    openModalRide() {
        setTimeout(() => {
            // Focus the input
            if (this.idPinValidate) {
                const inputValue = this.modalInputDiscount.nativeElement.value;
                if (inputValue == '0') {
                    // Remove the 0
                    this.modalInputDiscount.nativeElement.value = null;
                }
                this.modalInputDiscount.nativeElement.focus();
            } else {
                const inputValue = this.modalInputDiscountPin.nativeElement.value;
                if (inputValue == '0') {
                    // Remove the 0
                    this.modalInputDiscountPin.nativeElement.value = null;
                }
                this.modalInputDiscountPin.nativeElement.focus();
            }
        }, 0);
    }

    /** Apply PIN */
    applyDiscount(type: string) {
        if (type === 'pin') {
            if (this.discountPin.value == 1221) {
                this.idPinValidate = true;
                setTimeout(() => {
                    // Focus the input
                    const inputValue = this.modalInputDiscount.nativeElement.value;
                    if (inputValue == '0') {
                        // Remove the 0
                        this.modalInputDiscount.nativeElement.value = null;
                    }
                    this.modalInputDiscount.nativeElement.focus();
                }, 0);
            } else {
                this.utilityService.toast('error', 'Please enter a valid 4-digit PIN.');
                this.modalInputDiscountPin.nativeElement.focus();
            }
        } else {
            this.ride_discount = this.rideDiscountControl.value;
            this.fareEstimatePayload.ride_discount = this.ride_discount;
            this.get_new_fare_estimateion(this.fare_factor);
            if (this.from == 'list-detail') {
                const payload = {
                    mas_trip_id: this.booking.mas_trip_id,
                    web_access_token: this.cookieService.get('web_access_token'),
                    ride_discount: this.rideDiscountControl.value,
                    gross_fare_amount: this.recalculate_state?.value_regular,
                    submerchant_client_payout: this.recalculate_state.submerchant_client_payout,
                    other_fees: this.recalculate_state.other_fees,
                };
                this.httpService
                    .postJSON(environment.urlWC + 'edit_ride_discount', payload)
                    .subscribe((data) => {
                        if (data.flag === 1329) {
                            this.utilityService.toast('success', data?.log);
                        } else {
                            this.utilityService.toast('error', data?.message || 'Something went wrong');
                        }
                    })
                    .add(() => {
                        this.recalculate_price_loading = false;
                    });
            }
        }
    }

    /** DisMiss Discount */
    dismissDiscount() {
        this.discountPin.setValue(null);
        this.rideDiscountControl.setValue(this.ride_discount);
    }

    /** Dismiss Coraporate Add On */
    dismiss() {
        this.corporateAddOnValue.setValue(this.corporate_addon);
    }

    /** Apply Corporate Add On */
    applyCorporateAddOn() {
        if (this.corporateAddOnValue.value != 0) {
            this.corporate_addon = this.corporateAddOnValue.value;
            this.fareEstimatePayload.corporate_addon = this.corporate_addon;
            this.get_new_fare_estimateion(this.fare_factor);
            if (this.from == 'list-detail') {
                const params: any = {
                    web_access_token: this.cookieService.get('web_access_token'),
                    mas_trip_id: this.booking.mas_trip_id,
                    corporate_addon: this.corporateAddOnValue.value,
                    gross_fare_amount: this.recalculate_state.value_regular,
                };
                this.httpService
                    .postJSON(environment.urlWC + 'edit_corporate_addon', params)
                    .subscribe((data) => {
                        if (data.flag === 1329) {
                            this.utilityService.toast('success', data?.log);
                        } else {
                            this.utilityService.toast('error', data?.message || 'Something went wrong');
                        }
                    })
                    .add(() => {
                        this.recalculate_price_loading = false;
                    });
            }
        }
    }

    /** Apply Total Calculation  */
    applyCalculation() {
        this.menuActive = 'fare';
        this.getFareEstimate();
    }

    /** Reset Corporate Add On And Ride Request */
    resetCorporateAddOnAndRideDiscount() {
        this.rideDiscountControl.setValue(0);
        this.corporateAddOnValue.setValue(0);
        this.ride_discount = 0;
        this.corporate_addon = 0;
        this.discountPin.setValue(null);
    }

    /** Event Emit For Queued Trip Modal */
    emitQueued(from: string) {
        const params = {
            estimated_fare: this.booking.estimated_fare,
            corporate_addon: this.fareEstimatePayload.corporate_addon,
            ride_discount: this.fareEstimatePayload.ride_discount,
            fare_factor: this.fareEstimatePayload.fare_factor,
            avoid_tolls: this.avoid_tolls,
            avoid_highways: this.avoid_highways,
            user_mobile: this.booking.user_mobile,
            ride_estimate_distance: this.ride_estimate_distance,
            ride_estimate_time: this.booking.ride_estimate_time,
            car_type: this.booking.car_type,
            third_party_amount: this.fare_breakdown.submerchant_driver_payout,
            from: from,
        };
        this.event.emit(params);
        this.bsModalRef.hide();
    }
}
