import { Injectable } from '@angular/core';
import { from, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Injectable({
    providedIn: 'root',
})
export class GoogleMapService {
    constructor() {}
    directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();

    defaultAvoidHighways: boolean = false;
    defaultAvoidTolls: boolean = false;

    get_eta(request: google.maps.DirectionsRequest): Observable<google.maps.Duration> {
        return from(this.directionsService.route(request)).pipe(
            map((res) => {
                return res?.routes?.[0]?.legs?.[0]?.duration;
            })
        );
    }

    tigger_marker(marker: google.maps.Marker) {
        new google.maps.event.trigger(marker, 'click');
    }

    toggle_infowindow(infowindow: google.maps.InfoWindow, action: 'show' | 'hide', infowindowOption?: google.maps.InfoWindowOpenOptions) {
        if (action === 'show') {
            infowindow.open(infowindowOption);
        } else {
            infowindow.close();
        }
    }

    secondsToHms(seconds: number): string {
        seconds = Number(seconds);
        const h = Math.floor(seconds / 3600);
        const m = Math.floor((seconds % 3600) / 60);
        // var s = Math.floor(seconds % 3600 % 60);

        const hDisplay = h > 0 ? h + (h == 1 ? ' hr ' : ' hrs ') : '';
        const mDisplay = m > 0 ? m + (m == 1 ? ' min ' : ' mins ') : '';
        // var sDisplay = s > 0 ? s + (s == 1 ? " second" : " seconds") : "";
        return hDisplay + mDisplay;
    }
}
