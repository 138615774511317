<span
    class="cursor-pointer"
    [popover]="show_driver_popover"
    #driverPopover="bs-popover"
    containerClass="br-6 z-index-1000"
    placement="bottom"
    [outsideClick]="true"
    (click)="show_driver_popover_reference = driverPopover"
>
    <ng-content></ng-content>
</span>

<ng-template #show_driver_popover>
    <div class="touch-popover">
        <div class="touch-light-bg py-2 px-3 m-3 bordered">
            <div style="color: #707683" class="fw-600">SHOW DRIVER PAYOUT</div>
        </div>

        <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2">
            <div class="cursor-pointer" (click)="show_driver_popover_reference.hide()">Dismiss</div>
            <div class="cursor-pointer" (click)="show_driver_popover_reference.hide(); onToggle.emit()">
                <ng-container *ngIf="show_driver">Hide</ng-container>
                <ng-container *ngIf="!show_driver">Show</ng-container>
            </div>
        </div>
    </div>
</ng-template>
