import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'MaskText',
})
export class MaskTextPipe implements PipeTransform {
    transform(text: string, maskFrom: 'start' | 'end' = 'end', maskCount: number = 4): any {
        if (!text) return '';
        if (maskCount > text.length) return text.replace(/\d/g, '*');

        if (maskFrom == 'end') {
            text = text.replace('+', '1');
            const first_half = text.slice(0, text.length - maskCount);
            const second_half = text.slice(text.length - maskCount);
            return first_half.replace(/\d\D*/g, '*') + second_half;
        } else if (maskFrom == 'start') {
            const first_half = text.slice(0, maskCount);
            const second_half = text.slice(maskCount);
            return first_half + second_half.replace(/\d/g, '*');
        }
    }
}
