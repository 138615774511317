<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <app-trip-roster-header
            [hideTitleLayer]="minimal_setup"
            [preventRouteAction]="minimal_setup"
            (onTabChange)="onTabChange.emit($event)"
            headerTitle="Trip Roster"
            [tabList]="utilityService.settingHeaderConfig.active_trips.tabs"
            activeTab="Queued"
            [searchControl]="searchForm"
            buttonText="Export CSV"
            (buttonClick)="generateCSV()"
            [subTabList]="queuedSubTabList"
            [activeSubTab]="activeSubTab"
            (subTabClick)="onSubTabChanged($event)"
            [queuedTripTabConfigEnabled]="true"
            (onQueueTripItemSelected)="onQueueTripItemSelected($event)"
            [showStartSessionAction]="true"
            [filterOption]="true"
            [filters]="headerOptions"
            (onFilterEvent)="onFilterEvent($event)"
            [isFilterActive]="isFilterActive"
        >
            <!-- Render Rider Earning Overview and Prepaid rides -->
            <app-ride-earning-overview
                ride_earning_overview
                [hidePrepaidLabel]="true"
                [hideRiderAnalytics]="true"
                [prepaidDate]="active_trip_date_picker"
                [refetchTrigger]="refetchPrepaidSubject"
            ></app-ride-earning-overview>

            <!-- Subtab suffix -->
            <div queued_live_trip_suffix>
                <app-live-queue-trip-setting-popover>
                    <img class="ml-2" style="fill: #323f50; height: 20px" src="assets/images/setting_gear.svg" alt="" />
                </app-live-queue-trip-setting-popover>
            </div>
            <!-- <div class="d-flex fs-13" filter_options>
                <div class="cmodal align-self-center mr-1">
                    <div class="dropdown">
                        <span class="w-100" type="button" id="affiliation_menu" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                            <div class="placeholder small border-0 p-0 dropdown-toggle">
                                <span>Trip Leg</span>
                                <span class="text-blue-secondary fw-600">
                                    {{ leg_filter_type | titlecase }}
                                </span>
                            </div>
                        </span>
                        <div
                            class="overflow-auto dropdown-menu cd-menu first card-shadow py-3 px-0"
                            style="width: 200px !important; max-height: 300px"
                            id="trip_status_menu"
                            aria-labelledby="affiliation_menu"
                        >
                            <div class="dropdown-item cursor-pointer fs-13 pl-4" (click)="filter_leg_sequence('all')">All</div>
                            <div class="dropdown-item cursor-pointer fs-13 pl-4" (click)="filter_leg_sequence('initial')">Initial</div>
                        </div>
                    </div>
                </div>
                <div class="mx-1">
                    <app-time-range-picker
                        labelType="icon"
                        (onTimeChange)="onTimePickerChange($event)"
                        [default]="{ start: null, end: null }"
                        [reset]="resetSubject.asObservable()"
                    ></app-time-range-picker>
                </div>
            </div> -->
        </app-trip-roster-header>
    </div>
    <!-- Tables
      ================================================== -->
    <div class="table-container trip-roster touch-scrollbar" (scroll)="onScroll($event)">
        <div class="page-header"></div>
        <table class="table table-responsive trips dtrips">
            <thead #tableHeader>
                <tr class="frow">
                    <th scope="col">Action</th>
                    <!-- <th scope="col" style="min-width: 200px">Prepaid | Queued</th> -->
                    <th scope="col" style="min-width: 225px">Trip Status</th>

                    <th *ngIf="isActiveInput" scope="col" style="min-width: 180px">
                        <div class="w-100 px-2 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Search Brokers" />
                                    </div>
                                    <div>
                                        <mat-icon (click)="searchActive(); searchForm.reset()">close</mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" style="opacity: 1; min-width: 180px" *ngIf="!isActiveInput">
                        <div class="dropdown broker-status-search">
                            <a
                                data-target="brokerDropdown"
                                class="touch-border-frame tripsbtn"
                                data-toggle="dropdown"
                                aria-haspopup="true"
                                aria-expanded="false"
                            >
                                <span class="dropdown-toggle">
                                    <span class="light-weight">{{ masService.brokerStatusLabel }}</span>

                                    <span class="dropdown-search">
                                        <img class="dropdown-polygon mr-2" src="/assets/images/Polygon.svg" alt="" />
                                        |
                                        <i class="fa fa-search pr-0 ml-2" aria-hidden="true" (click)="searchActive()"></i>
                                    </span>
                                </span>
                            </a>
                            <div class="dropdown-menu cd-menu first card-shadow" aria-labelledby="brokerDropdown">
                                <div class="dropdown-item cd-item cursor-pointer" (click)="selectRideBroker('All Brokers')">All Brokers</div>
                                <div
                                    class="dropdown-item cd-item cursor-pointer"
                                    *ngFor="let broker of listRideBrokers"
                                    (click)="selectRideBroker(broker.company_name)"
                                >
                                    {{ broker.company_name }}
                                </div>
                            </div>
                        </div>
                    </th>

                    <th scope="col" class="large" style="min-width: 250px">Assign Driver</th>
                    <!-- <th scope="col">Rider Details</th> -->
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Search Riders" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="cursor-pointer" class="large" (click)="toggleAttestationSort()">
                        Pickup Date & Time
                        <i *ngIf="sort_order === 'desc'" class="fa fa-arrow-down" aria-hidden="true"></i>
                        <i *ngIf="sort_order === 'asc'" class="fa fa-arrow-up" aria-hidden="true"></i>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 px-2 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input class="m-0 border-0 w-100" type="text" placeholder="Search Pickup" [formControl]="pickup_search" />
                                    </div>
                                    <div>
                                        <i *ngIf="!pickup_search.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            *ngIf="pickup_search.value"
                                            (click)="pickup_search.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 px-2 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill text-left">
                                        <input
                                            class="m-0 border-0 mx-2 text-left"
                                            type="text"
                                            placeholder="Search Drop off"
                                            [formControl]="dropoff_search"
                                        />
                                    </div>
                                    <div>
                                        <i *ngIf="!dropoff_search.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            *ngIf="dropoff_search.value"
                                            (click)="dropoff_search.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <!-- <th scope="col">Pickup Location</th>
                        <th scope="col">Drop off Location</th> -->

                    <th scope="col">MAS Payout</th>
                    <th scope="col" style="opacity: 1">
                        <div class="d-flex">
                            <div style="opacity: 0.5">Driver Pay</div>
                            <div class="ml-1">
                                <!-- <img style="fill: #323F50;" src="assets/images/setting_gear.svg" alt=""> -->
                                <app-amount-popover
                                    [amount]="driver_payout_percentage"
                                    amount_type="percentage"
                                    label="% OF GROSS PAYOUT"
                                    [allowEdit]="true"
                                    (onSave)="updateDriverPercentage({ third_party_percentage: $event })"
                                >
                                    <div amount_popover_extra_body>
                                        <div class="my-2">
                                            <hr class="my-0 py-0" />
                                        </div>
                                        <div class="text-center mb-3">
                                            <!-- <app-loading *ngIf="suggested_fare_loading" type="placeholder" width="80px" height="18px"></app-loading> -->
                                            <div class="custom-control custom-switch ml-2">
                                                <input
                                                    type="checkbox"
                                                    class="custom-control-input stop-propagation"
                                                    [id]="'show_all_driver_payout_switch'"
                                                    [(ngModel)]="show_all_driver_payout"
                                                    (ngModelChange)="updateDriverPercentage({ show_driver_payout: show_all_driver_payout ? 1 : 0 })"
                                                />
                                                <label
                                                    class="custom-control-label fs-14 fw-600 dark-color stop-propagation"
                                                    [attr.for]="'show_all_driver_payout_switch'"
                                                ></label>
                                            </div>
                                            <div class="text-center fw-600 fs-12 mx-4 my-2 text-dark-blue">
                                                SHOW DRIVER PAYOUT
                                                <br />
                                                TO THE DRIVER APP
                                            </div>
                                        </div>
                                    </div>
                                </app-amount-popover>
                            </div>
                        </div>
                    </th>
                    <th scope="col">Toll</th>
                    <th scope="col">Mileage</th>
                    <th scope="col">Car Type</th>
                </tr>
            </thead>

            <tbody *ngIf="isLoading">
                <tr class="no-hover">
                    <td colspan="13" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="!isLoading && trips && trips.length > 0" class="break-word">
                <!-- | StringFilter: 'pickup_location':pickup_search
                    | StringFilter: 'dropoff_location':dropoff_search -->
                <tr
                    *ngFor="
                        let trip of trips
                            | paginate
                                : {
                                      id: 'activeTrips',
                                      itemsPerPage: itemsPerPage,
                                      currentPage: pageNo,
                                      totalItems: totalItems
                                  };
                        let i = index
                    "
                    class="bg-placeholder-trip"
                    (click)="onRowClick($event, trip?.mas_trip_id)"
                    [ngClass]="trip?.active_class + ' ' + trip?.inactive_class"
                >
                    <td class="book_btns align-left">
                        <div class="d-flex">
                            <div class="bd-highlight highlight align-self-center mr-1 tool stop-propagation" data-tip="More Options">
                                <img data-toggle="dropdown" class="hover-shadow rounded" id="dropdown" src="/assets/images/more_horiz_rounded.svg" />
                                <div class="dropdown-menu cd-menu grey-item left-0 card-shadow text-grey" aria-labelledby="download">
                                    <div
                                        *ngIf="!trip?.is_assigned"
                                        class="dropdown-item cd-item"
                                        stop-propagation
                                        [routerLink]="['/', 'corporate', 'active-trips', 'queued', trip?.mas_trip_id]"
                                    >
                                        Queue Trip Detail
                                    </div>
                                    <div
                                        *ngIf="!trip?.is_assigned && trip?.invoice_number"
                                        class="dropdown-item cd-item"
                                        stop-propagation
                                        (click)="
                                            update_queued_mas_trip({
                                                mas_trip_id: trip?.mas_trip_id,
                                                status: 1
                                            })
                                        "
                                    >
                                        Unqueue
                                    </div>
                                    <div class="dropdown-item cd-item" stop-propagation (click)="book_mas_ride(trip)">
                                        <ng-container *ngIf="trip?.ride_status === 3">Re-Dispatch this Trip</ng-container>
                                        <ng-container *ngIf="trip?.ride_status !== 3">Dispatch this Trip</ng-container>
                                    </div>
                                    <div
                                        class="dropdown-item cd-item"
                                        *ngIf="trip?.ride_status !== 3"
                                        stop-propagation
                                        (click)="
                                            update_queued_mas_trip({
                                                mas_trip_id: trip?.mas_trip_id,
                                                status: 1,
                                                ride_status: 5
                                            })
                                        "
                                    >
                                        Cancel this Trip
                                    </div>
                                    <div
                                        class="dropdown-item cd-item"
                                        *ngIf="trip?.ride_status !== 3"
                                        stop-propagation
                                        (click)="
                                            update_queued_mas_trip({
                                                mas_trip_id: trip?.mas_trip_id,
                                                status: 1,
                                                ride_status: 4
                                            })
                                        "
                                    >
                                        Complete this Trip
                                    </div>
                                    <div
                                        class="dropdown-item cd-item"
                                        stop-propagation
                                        (click)="
                                            update_queued_mas_trip({
                                                mas_trip_id: trip?.mas_trip_id,
                                                ride_confirmed: trip?.ride_confirmed ? 0 : 1
                                            })
                                        "
                                    >
                                        <ng-container *ngIf="trip?.ride_confirmed">Unconfirm Rider</ng-container>
                                        <ng-container *ngIf="!trip?.ride_confirmed">Confirm Rider</ng-container>
                                    </div>
                                    <div class="dropdown-item cd-item" stop-propagation (click)="open_live_map_view(i)">View map</div>

                                    <hr />
                                    <!-- Desktop View -->
                                    <div
                                        *ngIf="!isMobileScreen"
                                        class="dropdown-item cd-item"
                                        stop-propagation
                                        (click)="book_mas_ride(trip, null, 'suggested_fare')"
                                    >
                                        Check fare estimate
                                    </div>
                                    <!-- Mobile View -->
                                    <div *ngIf="isMobileScreen" class="dropdown-item cd-item" stop-propagation (click)="fareEstimateRecalc(trip)">
                                        Check fare estimate
                                    </div>
                                    <div class="dropdown-item cd-item" stop-propagation (click)="send_payment_link_to_rider(trip, 'base')">
                                        <ng-container *ngIf="!trip?.paid">Send payment link (Base)</ng-container>
                                        <ng-container *ngIf="trip?.paid">Send trip info link</ng-container>
                                    </div>
                                    <div
                                        *ngIf="!trip?.paid"
                                        class="dropdown-item cd-item"
                                        stop-propagation
                                        (click)="send_payment_link_to_rider(trip, 'agent')"
                                    >
                                        Send payment link (Agent)
                                    </div>
                                    <div class="dropdown-item cd-item" stop-propagation>
                                        <a target="_blank" class="cd-item" (click)="get_payment_link(trip?.mas_trip_id)">Open payment link</a>
                                    </div>
                                </div>
                            </div>
                            &nbsp;
                            <div class="tool align-self-center" data-tip="View Note" tabindex="1">
                                <app-note-popover
                                    stop-propagation
                                    noteListType="trip"
                                    [user_id]="trip?.user_id"
                                    [user_name]="trip?.user_name"
                                    [mas_trip_id]="trip?.mas_trip_id"
                                ></app-note-popover>
                            </div>
                            &nbsp;
                            <div class="mx-2 tool va-middle" stop-propagation (click)="onRefreshTrip(trip)" data-tip="Refresh Trip">
                                <img
                                    class="refresh"
                                    style="margin-top: 2px"
                                    [class.refresh-action]="refreshActionContainer?.includes(trip?.trip_leg_id)"
                                    src="assets/images/refresh.png"
                                />
                            </div>
                        </div>
                    </td>

                    <!-- Hide  Prepaid | Queued 3 Feb-->
                    <!-- <td>
                        <div class="d-flex align-items-center" *ngIf="!trip?.is_assigned">
                            <div stop-propagation>
                                <app-prepaid-trip-popover [state]="trip?.is_prepaid ? 'prepaid' : 'unpaid'"
                                    [trip_payload]="{
                                        pickup_latitude: trip.pickup_latitude,
                                        pickup_longitude: trip.pickup_longitude,
                                        destination_latitude: trip.dropoff_latitude,
                                        destination_longitude: trip.dropoff_longitude,
                                        car_type: trip?.car_type || 1,

                                        trip_leg_id: trip?.trip_leg_id,
                                        leg_cost: trip?.leg_cost,
                                        pickup_date: trip?.pickup_datetime,
                                        invoice_number: trip?.invoice_number,
                                        miles: trip?.miles,
                                        fare_factor: trip?.fare_factor || 1
                                    }" (onAddTrip)="
                                        update_queued_mas_trip(
                                            {
                                                mas_trip_id: trip?.mas_trip_id,
                                                is_prepaid: 1,
                                                prepaid_amount: $event?.total_amount,
                                                third_party_amount: $event?.driver_pay,
                                                gross_fare_amount: $event?.driver_pay
                                            },
                                            true,
                                            false,
                                            trip
                                        )
                                    "
                                    (onRemoveTrip)="update_queued_mas_trip({ mas_trip_id: trip?.mas_trip_id, is_prepaid: 0 })">
                                    <ng-container *ngIf="trip?.is_prepaid">
                                        {{ trip?.prepaid_amount | currency:
                                        'USD':'symbol':utilityService.show_currency_precision }}
                                    </ng-container>
                                    <ng-container *ngIf="!trip?.is_prepaid">GO PREPAID</ng-container>
                                </app-prepaid-trip-popover>
                            </div>
                            <div class="mx-2">|</div>
                            <ng-container>
                                <div class="mr-2 cmodal d-flex" align="center">
                                    <div class="dropdown fs-20">
                                        <span type="button" id="affiliation_menu" data-toggle="dropdown"
                                            aria-haspopup="true" aria-expanded="false"
                                            (click)="temp_driver = null; getQueuedDrivers(trip?.mas_trip_id)">
                                            <i *ngIf="trip?.confirmed_drivers_count === 0"
                                                class="text-dark-blue fa fa-exclamation-circle stop-propagation"></i>
                                            <i *ngIf="trip?.confirmed_drivers_count > 0"
                                                class="text-green fa fa-exclamation-circle stop-propagation"></i>
                                        </span>
                                        <div class="p-0 overflow-auto dropdown-menu auto cd-menu select-menu-item-style first card-shadow"
                                            style="width: 290px !important; max-height: 300px" id="trip_status_menu"
                                            aria-labelledby="affiliation_menu" stop-propagation>
                                            <div class="mx-4 mt-3">
                                                <div class="text-dark-blue fs-18">Confirmed Results</div>
                                                <div class="text-grey my-2 fs-12 fw-normal">Assigned Trip #{{
                                                    trip?.mas_trip_id }}</div>
                                            </div>
                                            <hr class="my-2" />

                                            <div *ngIf="!paged_driver_loading" style="height: 150px"
                                                class="overflow-auto">
                                                <div *ngFor="let driver of confirmed_driver_list"
                                                    class="dropdown-item cursor-pointer fs-13"
                                                    [class.active-item]="driver?.driver_id === temp_driver?.driver_id"
                                                    (click)="$event.stopPropagation(); temp_driver = driver">
                                                    <b>{{ driver.driver_name | ShortText: 15 }}</b>
                                                    <span class="fs-11 ml-1">{{ driver.driver_mobile }}</span>
                                                </div>
                                                <div *ngIf="!confirmed_driver_list?.length" class="text-center my-2">No
                                                    driver associated</div>
                                            </div>

                                            <div *ngIf="paged_driver_loading" class="mx-2" style="height: 150px">
                                                <app-loading *ngFor="let item of [1]" height="20px" borderRadius="2px"
                                                    type="placeholder"></app-loading>
                                            </div>
                                            <hr class="light" />
                                            <div
                                                class="d-flex justify-content-around text-blue-secondary fs-13 my-2 p-2">
                                                <div class="cursor-pointer"
                                                    (click)="$event.stopPropagation(); book_mas_ride(trip, temp_driver)">
                                                    Dispatch
                                                </div>
                                                <div class="cursor-pointer" [class.disabled-text]="!temp_driver"
                                                    (click)="assign_driver(temp_driver, trip, 'confirmed')">
                                                    Assign
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {{ trip?.confirmed_drivers_count }}
                                </div>
                            </ng-container> -->
                    <!-- Currently not in use -->
                    <!-- <ng-container>
                                <div class="align-self-center mx-2 cmodal d-flex" align="center">
                                    <div class="dropdown fs-20">
                                        <span
                                            type="button"
                                            id="affiliation_menu"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            (click)="temp_driver = null; getQueuedDrivers(trip?.mas_trip_id)"
                                        >
                                            <img
                                                *ngIf="trip?.interested_drivers_count"
                                                class="stop-propagation"
                                                src="assets/images/thumbs_up.svg"
                                                alt="Interested Drivers"
                                            />
                                            <img
                                                *ngIf="!trip?.interested_drivers_count"
                                                style="height: 20px"
                                                src="assets/images/thumbs_up_dark.png"
                                                alt="Interested Drivers"
                                                class="stop-propagation"
                                            />
                                        </span>
                                        <div
                                            class="p-0 overflow-auto dropdown-menu auto cd-menu select-menu-item-style first card-shadow"
                                            style="width: 290px !important; max-height: 300px"
                                            id="trip_status_menu"
                                            aria-labelledby="affiliation_menu"
                                            stop-propagation
                                        >
                                            <div class="mx-4 mt-3">
                                                <div class="text-dark-blue fs-18">Interested Results</div>
                                                <div class="text-grey my-2 fs-12 fw-normal">Assigned Trip #{{ trip?.mas_trip_id }}</div>
                                            </div>
                                            <hr class="my-2" />

                                            <div *ngIf="!paged_driver_loading" style="height: 150px" class="overflow-auto">
                                                <div
                                                    *ngFor="let driver of interested_driver_list"
                                                    class="dropdown-item cursor-pointer fs-13"
                                                    [class.active-item]="driver?.driver_id === temp_driver?.driver_id"
                                                    (click)="$event.stopPropagation(); temp_driver = driver"
                                                >
                                                    <b>{{ driver.driver_name | ShortText: 15 }}</b>
                                                    <span class="fs-11 ml-1">{{ driver.driver_mobile }}</span>
                                                </div>
                                                <div *ngIf="!interested_driver_list?.length" class="text-center my-2">No driver associated</div>
                                            </div>

                                            <div *ngIf="paged_driver_loading" class="mx-2" style="height: 150px">
                                                <app-loading
                                                    *ngFor="let item of [0, 1, 2]"
                                                    height="20px"
                                                    borderRadius="2px"
                                                    type="placeholder"
                                                ></app-loading>
                                            </div>
                                            <hr class="light" />
                                            <div class="d-flex justify-content-around text-blue-secondary fs-13 my-2 p-2">
                                                <div
                                                    class="cursor-pointer"
                                                    (click)="$event.stopPropagation(); queue_alert('dispatch', trip, temp_driver)"
                                                >
                                                    Dispatch
                                                </div>
                                                <div
                                                    class="cursor-pointer"
                                                    [class.disabled-text]="!temp_driver"
                                                    (click)="queue_alert('assign', trip, temp_driver)"
                                                >
                                                    Assign
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    {{ trip?.interested_drivers_count }}
                                </div>
                            </ng-container> -->
                    <!-- </div>
                        <div *ngIf="trip?.is_assigned">N/A</div>
                    </td> -->
                    <td>
                        <div class="d-flex">
                            <div stop-propagation>
                                <app-live-queue-trip-popover
                                    [mas_trip_id]="trip?.mas_trip_id"
                                    [is_live]="trip?.go_live || false"
                                    (onChange)="get_queued_trips(pageNo, itemsPerPage, true, trip?.mas_trip_id)"
                                >
                                    <div class="d-flex align-items-center">
                                        <div>|</div>
                                        <div style="height: 23px" class="ml-1" (click)="open_mas_trip_info_popup(trip)" *ngIf="trip?.invoice_number">
                                            <img style="height: 31px" src="/assets/images/white-bordered-exclamation.svg" alt="trip_info" />
                                        </div>

                                        <div style="height: 23px" class="ml-1" *ngIf="!trip?.invoice_number">
                                            <span
                                                class="cursor-pointer"
                                                [popover]="queue_trip_info_popover"
                                                #queue_trip_info="bs-popover"
                                                containerClass="br-6 z-index-1000"
                                                placement="bottom"
                                            >
                                                <img style="height: 31px" src="/assets/images/white-bordered-exclamation.svg" alt="trip_info" />
                                            </span>

                                            <ng-template #queue_trip_info_popover>
                                                <div class="touch-popover">
                                                    <div class="touch-light-bg py-2 px-3 m-3 bordered">
                                                        <div style="color: #707683" class="fw-600">Queue Trip ID: {{ trip?.mas_trip_id }}</div>
                                                    </div>

                                                    <div class="d-flex justify-content-around text-blue-secondary fs-13 fw-600 m-2">
                                                        <div class="cursor-pointer" (click)="queue_trip_info.hide()">Dismiss</div>
                                                    </div>
                                                </div>
                                            </ng-template>
                                        </div>
                                    </div>
                                </app-live-queue-trip-popover>
                            </div>
                            <div class="mx-2 d-flex align-items-center">|</div>
                            <ng-container>
                                <div class="mr-2 cmodal d-flex" align="center">
                                    <div class="dropdown fs-20 d-flex align-items-center">
                                        <span
                                            type="button"
                                            id="affiliation_menu"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                            (click)="temp_driver = null; getQueuedDrivers(trip?.mas_trip_id)"
                                        >
                                            <i
                                                *ngIf="trip?.confirmed_drivers_count === 0"
                                                class="text-dark-blue fa fa-exclamation-circle stop-propagation"
                                            ></i>
                                            <!-- <i class="text-red fa fa-exclamation-circle"></i> -->
                                            <i
                                                *ngIf="trip?.confirmed_drivers_count > 0"
                                                class="text-green fa fa-exclamation-circle stop-propagation"
                                            ></i>
                                        </span>
                                        <div
                                            class="p-0 overflow-auto dropdown-menu auto cd-menu select-menu-item-style first card-shadow"
                                            style="width: 290px !important; max-height: 300px"
                                            id="trip_status_menu"
                                            aria-labelledby="affiliation_menu"
                                            stop-propagation
                                        >
                                            <div class="mx-4 mt-3">
                                                <div class="text-dark-blue fs-18">Confirmed Results</div>
                                                <div class="text-grey my-2 fs-12 fw-normal">Assigned Trip #{{ trip?.mas_trip_id }}</div>
                                            </div>
                                            <hr class="my-2" />

                                            <!-- [class.active-item]="" -->
                                            <div *ngIf="!paged_driver_loading" style="height: 150px" class="overflow-auto">
                                                <div
                                                    *ngFor="let driver of confirmed_driver_list"
                                                    class="dropdown-item cursor-pointer fs-13"
                                                    [class.active-item]="driver?.driver_id === temp_driver?.driver_id"
                                                    (click)="$event.stopPropagation(); temp_driver = driver"
                                                >
                                                    <b>{{ driver.driver_name | ShortText: 15 }}</b>
                                                    <span class="fs-11 ml-1">{{ driver.driver_mobile }}</span>
                                                </div>
                                                <div *ngIf="!confirmed_driver_list?.length" class="text-center my-2">No driver associated</div>
                                            </div>

                                            <div *ngIf="paged_driver_loading" class="mx-2" style="height: 150px">
                                                <app-loading
                                                    *ngFor="let item of [1]"
                                                    height="20px"
                                                    borderRadius="2px"
                                                    type="placeholder"
                                                ></app-loading>
                                            </div>
                                            <hr class="light" />
                                            <div class="d-flex justify-content-around text-blue-secondary fs-13 my-2 p-2">
                                                <div class="cursor-pointer" (click)="$event.stopPropagation(); book_mas_ride(trip, temp_driver)">
                                                    Dispatch
                                                </div>
                                                <div
                                                    class="cursor-pointer"
                                                    [class.disabled-text]="!temp_driver"
                                                    (click)="assign_driver(temp_driver, trip, 'confirmed')"
                                                >
                                                    Assign
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="d-flex align-items-center">
                                    {{ trip?.confirmed_drivers_count }}
                                </div>
                            </ng-container>
                        </div>
                    </td>
                    <td class="ride-broker-value">
                        <div>{{ (trip?.ride_broker_name ? trip?.trip_leg_id : trip?.session_id) || 'N/A' }}</div>
                        <span class="rideBroker-label">
                            {{
                                trip?.ride_broker_name === 'sentryMS'
                                    ? trip?.sentryms_trip_type === 'assigned'
                                        ? 'SENTRY-TPT'
                                        : trip?.sentryms_trip_type === 'marketplace'
                                        ? 'SENTRY-MP'
                                        : trip?.ride_broker_name
                                    : trip?.ride_broker_name || 'No Broker'
                            }}
                        </span>
                    </td>
                    <td>
                        <div>
                            <div class="cmodal" *ngIf="!trip?.is_assigned">
                                <div class="dropdown" style="min-width: 150px; max-width: 160px">
                                    <ng-container
                                        *ngIf="
                                            loading_queued_trip_container?.includes(trip?.mas_trip_id) ||
                                            refreshActionContainer?.includes(trip?.trip_leg_id)
                                        "
                                    >
                                        <div class="px-3 py-1 br-6 fw-600 fs-11 sticky-loading-box">
                                            <ng-container *ngIf="loading_queued_trip_container?.includes(trip?.mas_trip_id)">
                                                Updating...
                                            </ng-container>
                                            <ng-container *ngIf="refreshActionContainer?.includes(trip?.trip_leg_id)">Refreshing...</ng-container>
                                        </div>
                                    </ng-container>
                                    <ng-container
                                        *ngIf="
                                            !loading_queued_trip_container?.includes(trip?.mas_trip_id) &&
                                            !refreshActionContainer?.includes(trip?.trip_leg_id)
                                        "
                                    >
                                        <span
                                            class="w-100"
                                            type="button"
                                            id="affiliation_menu"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <div
                                                class="placeholder small fw-600"
                                                (click)="
                                                    temp_driver = null;
                                                    reset_driver_results(
                                                        'select_driver_dropdown_input:' + trip?.mas_trip_id,
                                                        trip?.invoice_number === 0 ? 'all' : 'mas'
                                                    )
                                                "
                                            >
                                                <div class="d-flex stop-propagation justify-content-between">
                                                    <div clas="align-self-center">Select Driver</div>
                                                    <div class="align-self-center stop-propagation mx-1">
                                                        <i class="fa fa-sort-desc" aria-hidden="true"></i>
                                                    </div>
                                                    <div stop-propagation class="mx-1 op-7">|</div>
                                                    <div stop-propagation>
                                                        <!-- <img src="assets/images/preferred_driver_icon.svg" alt="preferred_driver"> -->
                                                        <app-preferred-driver-list
                                                            [user_info]="{ user_id: trip?.user_id }"
                                                            (onAssign)="assign_driver($event, trip)"
                                                            view_type="popover"
                                                        ></app-preferred-driver-list>
                                                    </div>
                                                </div>
                                            </div>
                                        </span>
                                        <div
                                            class="p-0 overflow-auto dropdown-menu auto cd-menu select-menu-item-style first card-shadow"
                                            style="width: 300px !important; max-height: 300px !important"
                                            id="trip_status_menu"
                                            aria-labelledby="affiliation_menu"
                                        >
                                            <div class="px-2 py-3 search-box">
                                                <span>
                                                    <input
                                                        id="select_driver_dropdown_from_queued"
                                                        [formControl]="driver_search_control"
                                                        stop-propagation
                                                        class="m-0 border-0"
                                                        style="width: 86%"
                                                        type="text"
                                                        placeholder=" Search Drivers"
                                                    />
                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                </span>
                                            </div>
                                            <div style="max-height: 150px" class="overflow-auto">
                                                <ng-container *ngIf="cached_drivers?.length">
                                                    <div
                                                        *ngFor="let driver of cached_drivers"
                                                        class="dropdown-item fs-13 d-flex cursor-pointer"
                                                        [class.active-item]="
                                                            driver?.driver_id === temp_driver?.driver_id && temp_driver?.type === 'cached'
                                                        "
                                                        (click)="$event.stopPropagation(); on_select_driver(driver, 'cached')"
                                                    >
                                                        <div>{{ driver?.driver_name }}</div>
                                                        <div class="ml-2 tlc-image">
                                                            <div
                                                                class="ml-2 tool tool-right"
                                                                data-tip="TLC Active"
                                                                *ngIf="driver?.is_tlc_active == '1'"
                                                            >
                                                                <img src="assets/images/circle-check.svg" alt="tlc_active" />
                                                            </div>
                                                            <div
                                                                class="ml-2 tool tool-right"
                                                                data-tip="TLC Inactive"
                                                                *ngIf="driver?.is_tlc_active == '0'"
                                                            >
                                                                <img src="assets/images/circle-x.svg" alt="tlc_active" />
                                                            </div>
                                                        </div>
                                                        <div
                                                            class="cursor-pointer alert-driver ml-auto"
                                                            [class.disable-icon]="
                                                                recent_driver_alert_logs?.includes('q:' + trip?.mas_trip_id + ':' + driver?.driver_id)
                                                            "
                                                            (click)="
                                                                $event.stopPropagation(); send_driver_alert(trip?.mas_trip_id, driver?.driver_id)
                                                            "
                                                        >
                                                            <img src="assets/images/send_arrow.svg" alt="Alert Driver" />
                                                        </div>
                                                    </div>
                                                    <div class="mb-1">
                                                        <hr />
                                                    </div>
                                                </ng-container>
                                                <ng-container *ngIf="!driverLoading">
                                                    <div
                                                        *ngFor="let driver of drivers"
                                                        class="dropdown-item fs-13 d-flex cursor-pointer"
                                                        [class.active-item]="
                                                            driver?.driver_id === temp_driver?.driver_id && temp_driver?.type === 'new'
                                                        "
                                                        (click)="$event.stopPropagation(); on_select_driver(driver, 'new')"
                                                    >
                                                        <div>{{ driver.driver_name }}</div>

                                                        <div class="tlc-image">
                                                            <div
                                                                class="ml-2 tool tool-right"
                                                                data-tip="TLC Active"
                                                                *ngIf="driver?.is_tlc_active == '1'"
                                                            >
                                                                <img src="assets/images/circle-check.svg" alt="tlc_active" />
                                                            </div>
                                                            <div
                                                                class="ml-2 tool tool-right"
                                                                data-tip="TLC Inactive"
                                                                *ngIf="driver?.is_tlc_active == '0'"
                                                            >
                                                                <img src="assets/images/circle-x.svg" alt="tlc_active" />
                                                            </div>
                                                        </div>

                                                        <div
                                                            class="cursor-pointer alert-driver ml-auto"
                                                            [class.disable-icon]="
                                                                recent_driver_alert_logs?.includes('q:' + trip?.mas_trip_id + ':' + driver?.driver_id)
                                                            "
                                                            (click)="
                                                                $event.stopPropagation(); send_driver_alert(trip?.mas_trip_id, driver?.driver_id)
                                                            "
                                                        >
                                                            <img src="assets/images/send_arrow.svg" alt="Alert Driver" />
                                                        </div>
                                                    </div>
                                                </ng-container>

                                                <div *ngIf="driverLoading" class="mx-2">
                                                    <app-loading
                                                        *ngFor="let item of [0, 1, 2, 3, 4, 5]"
                                                        height="20px"
                                                        borderRadius="2px"
                                                        type="placeholder"
                                                    ></app-loading>
                                                </div>
                                            </div>
                                            <hr class="light" />
                                            <div class="d-flex justify-content-around text-blue-secondary fs-13 my-2 p-2">
                                                <div class="cursor-pointer stop-propagation" (click)="temp_driver = null">Dismiss</div>
                                                <div
                                                    class="cursor-pointer stop-propagation"
                                                    [class.disabled-text]="!temp_driver"
                                                    (click)="assign_driver(temp_driver, trip)"
                                                >
                                                    Assign
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>
                                </div>
                            </div>

                            <ng-container *ngIf="trip?.is_assigned && (trip?.sent_to != null || trip?.sent_to != undefined)">
                                <div class="d-flex">
                                    <div class="align-self-center mr-2 fs-18">
                                        <div
                                            *ngIf="trip?.ride_status === 3"
                                            class="tool"
                                            data-tip="Dispatched: this trip has been dispatched to a driver"
                                        >
                                            <i class="fa fa-exclamation-circle text-blue"></i>
                                        </div>
                                        <div *ngIf="trip?.ride_status === 4" class="tool" data-tip="Completed: this trip is now completed">
                                            <i class="fa fa-exclamation-circle text-orange"></i>
                                        </div>
                                        <div *ngIf="trip?.ride_status === 5" class="tool" data-tip="Cancelled: this trip is cancelled">
                                            <i class="fa fa-times-circle text-red"></i>
                                        </div>
                                        <div *ngIf="trip?.ride_status === 6">
                                            <i class="fa fa-exclamation-circle text-blue"></i>
                                        </div>
                                        <ng-Container
                                            *ngIf="
                                                trip?.ride_status !== 4 &&
                                                trip?.ride_status !== 5 &&
                                                trip?.ride_status !== 6 &&
                                                trip?.ride_status !== 3
                                            "
                                        >
                                            <div
                                                *ngIf="!trip?.driver_id && !trip?.declined_driver"
                                                class="tool"
                                                data-tip="Pending: the driver still need to confirm this trip"
                                            >
                                                <i class="fa fa-exclamation-circle text-dark-blue"></i>
                                            </div>
                                            <div
                                                *ngIf="trip?.declined_driver"
                                                class="tool"
                                                data-tip="Declined: this trip has been declined by` a driver"
                                            >
                                                <i class="fa fa-exclamation-circle text-red"></i>
                                            </div>
                                            <div
                                                *ngIf="!trip?.declined_driver && trip?.driver_id"
                                                class="tool"
                                                data-tip="Confirmed: you can now dispatch this trip"
                                            >
                                                <i class="fa fa-exclamation-circle text-green"></i>
                                            </div>
                                        </ng-Container>
                                    </div>
                                    <ng-container *ngIf="!(trip?.ride_status === 5 && trip?.sent_to == 0)">
                                        <div>
                                            {{ trip?.requested_driver_names }}
                                            <br />
                                            {{ trip?.requested_driver_mobile | phoneFormat }}
                                        </div>

                                        <div class="tlc-image">
                                            <div class="ml-2 tool" data-tip="TLC Active" *ngIf="trip?.is_tlc_active == '1'">
                                                <img src="assets/images/circle-check.svg" alt="tlc_active" />
                                            </div>
                                            <div class="ml-2 tool" data-tip="TLC Inactive" *ngIf="trip?.is_tlc_active == '0'">
                                                <img src="assets/images/circle-x.svg" alt="tlc_active" />
                                            </div>
                                        </div>

                                        <div class="cmodal" align="right">
                                            <div class="dropdown">
                                                <span
                                                    class="w-100"
                                                    type="button"
                                                    id="affiliation_menu"
                                                    data-toggle="dropdown"
                                                    aria-haspopup="true"
                                                    aria-expanded="false"
                                                >
                                                    <div
                                                        class="d-flex justify-content-between placeholder small fw-600 border-0 p-0"
                                                        style="background: none"
                                                    >
                                                        <div>
                                                            <mat-icon
                                                                class="text-dark-blue fs-16 stop-propagation"
                                                                (click)="
                                                                    reset_driver_results(
                                                                        'edit_assigned_driver_dropdown_input:' + trip?.mas_trip_id,
                                                                        trip?.invoice_number === 0 ? 'all' : 'mas'
                                                                    )
                                                                "
                                                            >
                                                                edit
                                                            </mat-icon>
                                                        </div>
                                                    </div>
                                                </span>
                                                <!-- style="transform: translate3d(0px, 397px, 0px)!important;" -->
                                                <div
                                                    class="p-0 overflow-auto dropdown-menu auto cd-menu first card-shadow"
                                                    style="width: 250px !important; max-height: 300px"
                                                    id="trip_status_menu"
                                                    aria-labelledby="affiliation_menu"
                                                >
                                                    <div class="px-2 py-3 search-box">
                                                        <span class="input-group">
                                                            <div class="d-flex w-100">
                                                                <div class="flex-fill">
                                                                    <input
                                                                        [id]="'edit_assigned_driver_dropdown_input:' + trip?.mas_trip_id"
                                                                        [formControl]="driver_search_control"
                                                                        class="m-0 border-0 w-100"
                                                                        type="text"
                                                                        placeholder=" Search Other Drivers"
                                                                    />
                                                                </div>
                                                                <div>
                                                                    <i class="fa fa-search" aria-hidden="true"></i>
                                                                </div>
                                                            </div>
                                                        </span>
                                                    </div>
                                                    <div
                                                        style="max-height: 108px"
                                                        [ngClass]="{ 'overflow-auto': !driverLoading, 'overflow-hidden': driverLoading }"
                                                    >
                                                        <ng-container *ngIf="!driverLoading">
                                                            <ng-container *ngFor="let driver of drivers">
                                                                <div
                                                                    class="dropdown-item cursor-pointer fs-13 d-flex"
                                                                    stop-propagation
                                                                    (click)="update_assigned_trip(trip, driver, 'update')"
                                                                    *ngIf="driver?.driver_id.toString() !== trip?.sent_to.toString()"
                                                                >
                                                                    <div>
                                                                        {{ driver.driver_name }}
                                                                    </div>
                                                                    <div class="tlc-image">
                                                                        <div
                                                                            class="ml-2 tool tool-right"
                                                                            data-tip="TLC Active"
                                                                            *ngIf="driver?.is_tlc_active == '1'"
                                                                        >
                                                                            <img src="assets/images/circle-check.svg" alt="tlc_active" />
                                                                        </div>
                                                                        <div
                                                                            class="ml-2 tool tool-right"
                                                                            data-tip="TLC Inactive"
                                                                            *ngIf="driver?.is_tlc_active == '0'"
                                                                        >
                                                                            <img src="assets/images/circle-x.svg" alt="tlc_active" />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </ng-container>
                                                        </ng-container>
                                                        <div *ngIf="driverLoading" class="mx-2">
                                                            <app-loading
                                                                [placeholderLength]="5"
                                                                height="20px"
                                                                borderRadius="2px"
                                                                type="placeholder"
                                                            ></app-loading>
                                                        </div>
                                                    </div>
                                                    <hr class="light" />
                                                    <!-- <div class="d-flex justify-content-center text-blue-secondary fs-13 my-2">
                                                            <div class="cursor-pointer" (click)="confirm_assign_driver(trip, {}, 'unassign')">
                                                                Unassign
                                                            </div>
                                                        </div> -->
                                                </div>
                                            </div>
                                        </div>
                                    </ng-container>

                                    <ng-container *ngIf="trip?.ride_status === 5 && trip?.sent_to == 0">N/A</ng-container>
                                </div>
                            </ng-container>
                        </div>
                    </td>
                    <td>
                        <span *ngIf="showRiderFilterIcon === 'filter'" class="cursor-pointer">
                            <img
                                src="assets/images/entry-filter.svg"
                                alt="Rider Filter"
                                stop-propagation
                                (click)="updateRiderDetailFilter(trip, 'set')"
                            />
                        </span>
                        <span *ngIf="showRiderFilterIcon === 'reset'" class="cursor-pointer">
                            <img
                                src="assets/images/entry-filter-back.png"
                                alt="Rider Filter"
                                style="width: 28px"
                                stop-propagation
                                (click)="updateRiderDetailFilter(trip, 'reset')"
                            />
                        </span>
                        <span *ngIf="trip?.ride_confirmed">
                            <img src="assets/images/rider_confirm.svg" alt="Rider Filter" style="width: 20px" stop-propagation class="ml-1" />
                        </span>
                        <span class="cursor-pointer hover-text-highlight" stop-propagation (click)="userService.navigateToUserDetail(trip?.user_id)">
                            {{ trip?.user_name }}
                        </span>
                        <br />
                        <span
                            [popover]="secondary_contacts"
                            containerClass="w-350 br-6 secondary_contacts stop-propagation"
                            #popSecondaryContact="bs-popover"
                            stop-propagation
                            (click)="
                                secondary_contact_popover?.hide();
                                secondary_contact_popover = popSecondaryContact;
                                get_secondary_contacts(trip?.user_id)
                            "
                            placement="bottom"
                        >
                            <span class="hover-text-highlight mr-1">{{ trip?.user_mobile | phoneFormat }}</span>
                            <img src="assets/images/secondary_contact.svg" alt="secondary contact" />
                        </span>
                    </td>
                    <td>
                        <ng-container *ngIf="trip?.pickup_date === '0000-00-00'">-</ng-container>
                        <ng-container *ngIf="trip?.pickup_date !== '0000-00-00'">
                            {{ trip?.pickup_datetime | date: 'MMMM d, y' }}
                            <!-- <br /> -->
                            <div class="d-flex align-items-center">
                                <div>
                                    {{ trip?.pickup_datetime | date: 'h:mm a' }}
                                </div>
                                <div>
                                    <mat-icon
                                        class="text-dark-blue fs-16 ml-1"
                                        style="vertical-align: middle; padding-top: 2px"
                                        stop-propagation
                                        (click)="open_time_picker_modal(trip)"
                                    >
                                        edit
                                    </mat-icon>
                                </div>
                                <div
                                    class="fw-600 fs-13"
                                    [ngClass]="{ 'text-dark-blue': trip?.will_call, 'text-space-grey-light2': !trip?.will_call }"
                                >
                                    Call
                                </div>
                                <div class="custom-control custom-switch ml-2">
                                    <input
                                        type="checkbox"
                                        class="custom-control-input stop-propagation"
                                        [id]="'willCallSwitch:' + trip?.mas_trip_id"
                                        [(ngModel)]="trip.will_call"
                                        (ngModelChange)="
                                            update_queued_mas_trip(
                                                {
                                                    mas_trip_id: trip?.mas_trip_id,
                                                    car_type: active_car_type_holder?.car_type,
                                                    pickup_time: trip?.pickup_time,
                                                    third_party_amount: trip?.third_party_amount,
                                                    user_toll: trip?.user_toll,
                                                    will_call: trip?.will_call ? 1 : 0
                                                },
                                                true,
                                                true
                                            )
                                        "
                                    />
                                    <label
                                        class="custom-control-label fs-14 fw-600 dark-color stop-propagation"
                                        [attr.for]="'willCallSwitch:' + trip?.mas_trip_id"
                                    ></label>
                                </div>
                            </div>
                        </ng-container>
                    </td>
                    <td class="break-word-format">
                        {{ trip?.pickup_location || 'NA' }}
                        <span>
                            <mat-icon
                                class="text-dark-blue fs-16 ml-1"
                                style="vertical-align: middle; padding-top: 2px"
                                stop-propagation
                                (click)="open_location_picker(trip)"
                            >
                                edit
                            </mat-icon>
                        </span>
                        <span class="fw-600 cursor-pointer text-dark-grey view-in-live-map" stop-propagation (click)="open_live_map_view(i)">
                            View map
                        </span>
                    </td>
                    <td>
                        {{ trip?.dropoff_location || 'NA' }}
                        <span>
                            <mat-icon
                                class="text-dark-blue fs-16 ml-1"
                                style="vertical-align: middle; padding-top: 2px"
                                stop-propagation
                                (click)="open_location_picker(trip)"
                            >
                                edit
                            </mat-icon>
                        </span>
                    </td>
                    <td>
                        <span *ngIf="trip?.invoice_number" class="stop-propagation">
                            {{ trip?.leg_cost || '0' | currency: 'USD' }}
                            <span
                                class="cursor-pointer pl-1 fs-14"
                                style="margin-left: -3px"
                                stop-propagation
                                (click)="
                                    masService.open_leg_cost_breakdown({
                                        date: trip?.pickup_date,
                                        invoice_number: trip?.invoice_number,
                                        trip_leg_id: trip?.trip_leg_id
                                    })
                                "
                            >
                                <i style="color: #323f50" class="fa fa-exclamation-circle"></i>
                            </span>
                        </span>
                        <ng-container *ngIf="!trip?.invoice_number">N/A</ng-container>
                    </td>
                    <td>
                        <div class="d-flex">
                            <app-amount-popover
                                stop-propagation
                                [amount]="trip?.third_party_amount"
                                label="DRIVER PAY"
                                [allowEdit]="trip?.is_prepaid == 0"
                                [isLoading]="driver_pay_loading && suggested_driver_payout"
                                (popoverTriggered)="get_suggested_fare(trip); applied_suggested_payout = false"
                                (onSave)="
                                    applied_suggested_payout
                                        ? null
                                        : update_queued_mas_trip({
                                              mas_trip_id: trip?.mas_trip_id,
                                              third_party_amount: $event,
                                              gross_fare_amount: $event,
                                              pickup_time: trip?.pickup_time,
                                              car_type: trip?.car_type || 1,
                                              will_call: trip?.will_call || 0,
                                              user_toll: trip?.user_toll
                                          })
                                "
                                [enable_secondary_amount]="true"
                                [reverse_secondary_position]="true"
                                [secondary_amount]="trip?.gross_fare_amount"
                                secondary_label="GROSS FARE"
                                [readonly_secondary_amount]="true"
                            >
                                <div amount_popover_extra_body>
                                    <div class="mx-4 my-2">
                                        <hr class="my-0 py-0" />
                                    </div>
                                    <div class="text-center mb-3">
                                        <app-loading *ngIf="suggested_fare_loading" type="placeholder" width="80px" height="18px"></app-loading>
                                        <div *ngIf="!suggested_fare_loading" style="color: #878a8d" class="fs-16 fw-600">
                                            {{ suggested_fare || '0' | currency: 'USD':'symbol':utilityService.show_currency_precision }}
                                        </div>
                                        <div class="text-dark-blue">
                                            Suggested Fare
                                            <span
                                                class="cursor-pointer pl-1 fs-14"
                                                style="margin-left: -3px"
                                                [class.disabled]="suggested_fare_loading"
                                                stop-propagation
                                            >
                                                <i
                                                    style="color: #323f50"
                                                    class="fa fa-exclamation-circle"
                                                    (click)="book_mas_ride(trip, null, 'suggested_fare')"
                                                ></i>
                                            </span>
                                        </div>
                                        <div
                                            class="text-blue-secondary cursor-pointer"
                                            [class.disabled]="suggested_fare_loading"
                                            (click)="update_driverpay_amount(trip)"
                                        >
                                            Apply this fare
                                        </div>
                                    </div>
                                    <div class="mx-4 my-2">
                                        <hr class="my-0 py-0" />
                                    </div>
                                    <div class="text-center mb-3">
                                        <!-- <app-loading *ngIf="suggested_fare_loading" type="placeholder" width="80px" height="18px"></app-loading> -->
                                        <div class="custom-control custom-switch ml-2">
                                            <input
                                                type="checkbox"
                                                class="custom-control-input stop-propagation"
                                                [id]="'show_driver_payout_switch:' + trip?.mas_trip_id"
                                                [(ngModel)]="trip.show_driver_payout"
                                                (ngModelChange)="
                                                    update_queued_mas_trip(
                                                        {
                                                            mas_trip_id: trip?.mas_trip_id,
                                                            show_driver_payout: trip?.show_driver_payout ? 1 : 0
                                                        },
                                                        true
                                                    )
                                                "
                                            />
                                            <label
                                                class="custom-control-label fs-14 fw-600 dark-color stop-propagation"
                                                [attr.for]="'show_driver_payout_switch:' + trip?.mas_trip_id"
                                            ></label>
                                        </div>
                                        <div class="text-center fw-600 fs-12 mx-4 my-2 text-dark-blue">
                                            SHOW DRIVER PAYOUT
                                            <br />
                                            TO THE DRIVER APP
                                        </div>
                                    </div>
                                </div>
                            </app-amount-popover>
                            <div *ngIf="trip?.show_driver_payout" [class.ml-2]="trip?.is_prepaid">
                                <app-show-driver-popover
                                    stop-propagation
                                    [show_driver]="trip?.show_driver_payout"
                                    (onToggle)="
                                        update_queued_mas_trip(
                                            {
                                                mas_trip_id: trip?.mas_trip_id,
                                                show_driver_payout: trip?.show_driver_payout ? 0 : 1
                                            },
                                            true
                                        )
                                    "
                                >
                                    <img src="assets/images/show.svg" alt="Eye Svg" style="height: 17px; margin-left: -5px; margin-top: -6px" />
                                </app-show-driver-popover>
                            </div>
                        </div>
                    </td>
                    <td>
                        <div>
                            <app-amount-popover
                                stop-propagation
                                [amount]="trip?.user_toll || 0"
                                [allowEdit]="true"
                                (onSave)="
                                    update_queued_mas_trip({
                                        mas_trip_id: trip?.mas_trip_id,
                                        third_party_amount: trip?.third_party_amount,
                                        pickup_time: trip?.pickup_time,
                                        car_type: trip?.car_type || 1,
                                        will_call: trip?.will_call || 0,
                                        user_toll: $event
                                    })
                                "
                            ></app-amount-popover>
                        </div>
                    </td>
                    <td style="text-align: left !important">{{ (trip?.miles | number: '1.2') || 'NA' }}</td>
                    <td>
                        <div class="cmodal" align="right">
                            <div class="dropdown">
                                <span
                                    class="w-100"
                                    type="button"
                                    id="affiliation_menu"
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                >
                                    <div class="d-flex placeholder small fw-600 border-0 p-0 stop-propagation" style="background: none">
                                        <div class="stop-propagation">{{ car_type_map[trip?.car_type] || '-' }}</div>
                                        <div>
                                            <mat-icon class="text-dark-blue fs-14 stop-propagation" (click)="active_car_type_holder = null">
                                                edit
                                            </mat-icon>
                                        </div>
                                    </div>
                                </span>
                                <div
                                    class="p-0 overflow-auto dropdown-menu cd-menu auto select-menu-item-style first card-shadow"
                                    style="width: 250px !important; max-height: 300px"
                                    id="trip_status_menu"
                                    aria-labelledby="affiliation_menu"
                                >
                                    <div class="m-3">
                                        <div class="text-dark-blue fs-16">Edit Car Type</div>
                                    </div>
                                    <hr />
                                    <div style="max-height: 200px" [ngClass]="{ 'overflow-auto': !driverLoading, 'overflow-hidden': driverLoading }">
                                        <ng-container *ngIf="!driverLoading">
                                            <ng-container *ngFor="let car of carTypes">
                                                <div
                                                    (click)="$event.stopPropagation(); active_car_type_holder = car"
                                                    class="dropdown-item cursor-pointer fs-13"
                                                    [class.active-item]="
                                                        active_car_type_holder?.car_type === car?.car_type ||
                                                        (!active_car_type_holder?.car_type && trip?.car_type === car?.car_type)
                                                    "
                                                >
                                                    {{ car.car_name }}
                                                </div>
                                            </ng-container>
                                        </ng-container>
                                    </div>
                                    <hr class="light" />
                                    <div class="d-flex justify-content-around text-blue-secondary fs-13 my-2">
                                        <div class="cursor-pointer p-1 stop-propagation">Cancel</div>
                                        <div
                                            class="cursor-pointer p-1"
                                            [class.disabled-text]="!active_car_type_holder"
                                            stop-propagation
                                            (click)="update_car_type(trip, active_car_type_holder?.car_type || 1)"
                                        >
                                            Save
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="trips && trips.length == 0 && !isLoading">
                <tr class="no-hover">
                    <td colspan="13" class="text-center">No Data Found!</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="custom_pagination_v2">
    <div class="d-flex flex-row-reverse align-self-center">
        <ng-container *ngIf="!isLoading">
            <div class="align-self-center" *ngIf="showPagination">
                <pagination-controls id="activeTrips" responsive="true" (pageChange)="get_queued_trips($event)"></pagination-controls>
            </div>

            <div class="p-2 bd-highlight highlight align-self-center ml-3 dropdown cursor-pointer">
                <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                    <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                    <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
                </div>
                <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                    <div
                        *ngFor="let item of utilityService.display_filter_list"
                        class="dropdown-item text-center cd-item text-spacegrey"
                        (click)="get_queued_trips(1, item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="text-spacegrey fs-13 align-self-center fw-600">
                <!-- {{ pageNo * itemsPerPage >= totalItems ? totalItems : pageNo * itemsPerPage }} of {{ totalItems }} -->
                Results:
                <span class="text-blue-secondary">{{ totalItems }}</span>
            </div>
        </ng-container>
        <ng-container *ngIf="isLoading">
            <app-loading type="placeholder" count="1" width="430px" height="24px"></app-loading>
        </ng-container>

        <div class="flex-fill"></div>
        <div class="search-box light bottom-search d-none d-md-block" *ngIf="showBottomSearchBox">
            <span class="input-group">
                <div class="d-flex w-100">
                    <div class="flex-fill text-left">
                        <input
                            class="m-0 border-0 w-100 text-left grey-placeholder"
                            type="text"
                            placeholder="Search Drop off"
                            [formControl]="dropoff_search"
                        />
                    </div>
                    <div>
                        <i *ngIf="!dropoff_search.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                        <mat-icon *ngIf="dropoff_search.value" (click)="dropoff_search.reset()" class="cursor-pointer" style="vertical-align: middle">
                            close
                        </mat-icon>
                    </div>
                </div>
            </span>
        </div>

        <div class="mr-3 search-box light bottom-search d-none d-md-block" *ngIf="showBottomSearchBox">
            <span class="input-group">
                <div class="d-flex w-100">
                    <div class="flex-fill">
                        <input class="m-0 border-0 w-100 grey-placeholder" type="text" placeholder="Search Pickup" [formControl]="pickup_search" />
                    </div>
                    <div>
                        <i *ngIf="!pickup_search.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                        <mat-icon *ngIf="pickup_search.value" (click)="pickup_search.reset()" class="cursor-pointer" style="vertical-align: middle">
                            close
                        </mat-icon>
                    </div>
                </div>
            </span>
        </div>

        <div class="ml-4 mr-3 search-box light bottom-search d-none d-md-block" *ngIf="showBottomSearchBox">
            <span class="input-group">
                <div class="d-flex w-100">
                    <div class="flex-fill">
                        <input [formControl]="searchForm" class="m-0 border-0 w-100 grey-placeholder" type="text" placeholder="Search Riders" />
                    </div>
                    <div>
                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                        <mat-icon
                            [class.disabled-text]="searchForm?.disabled"
                            *ngIf="searchForm.value"
                            (click)="searchForm.reset()"
                            class="cursor-pointer"
                            style="vertical-align: middle"
                        >
                            close
                        </mat-icon>
                    </div>
                </div>
            </span>
        </div>
    </div>
</div>

<app-fare-estimate
    [booking]="trip_to_book"
    (onBooking)="onDispatched()"
    (onSaveFare)="
        update_queued_mas_trip({
            mas_trip_id: suggested_fare_trip_refrence?.mas_trip_id,
            third_party_amount: $event?.driver_payout,
            gross_fare_amount: $event?.value,
            user_toll: suggested_fare_trip_refrence?.user_toll,
            car_type: suggested_fare_trip_refrence?.car_type || 1,
            pickup_time: suggested_fare_trip_refrence?.pickup_time,
            will_call: suggested_fare_trip_refrence?.will_call || 0,
            fare_factor: $event?.fare_factor,
            avoidHighways: $event?.avoid_highways ? 1 : 0,
            avoid_tolls: $event?.avoid_tolls ? 1 : 0
        })
    "
></app-fare-estimate>

<ng-template #secondary_contacts>
    <div class="touch-popover" stop-propagation>
        <div class="py-3 px-4 fw-600 text-dark-blue fs-16">Secondary Contacts</div>
        <hr class="light light-color" />
        <div>
            <div class="contacts touch-scrollbar">
                <div class="px-4" *ngIf="secondary_contact_loading">
                    <app-loading *ngFor="let item of [0, 1, 2]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                </div>
                <ng-container *ngIf="!secondary_contact_loading">
                    <div *ngFor="let contact of secondary_contact_list" class="d-flex align-items-center py-2 px-4 touch-list-item cursor-pointer">
                        <div class="text-grey flex-fill">{{ contact?.name }} {{ contact?.user_secondary_mobile | phoneFormat }}</div>
                        <div>
                            <mat-icon
                                stop-propagation
                                (click)="add_or_update_secondary_contact('edit', contact)"
                                class="text-dark-blue va-middle fs-16 text-right"
                            >
                                edit
                            </mat-icon>
                        </div>
                        <div class="ml-2">
                            <img stop-propagation (click)="remove_secondary_contact(contact)" style="height: 13px" src="assets/images/delete.png" />
                        </div>
                    </div>
                    <div *ngIf="!secondary_contact_list?.length" class="d-flex justify-content-center py-2">
                        <div class="text-grey">No contacts found</div>
                    </div>
                </ng-container>
            </div>

            <hr class="light light-color" />
            <!-- Add -->
            <div
                class="d-flex justify-content-between align-items-center py-2 px-4 touch-list-item cursor-pointer"
                stop-propagation
                (click)="add_or_update_secondary_contact('add')"
            >
                <div class="text-grey">Add</div>
                <div><mat-icon class="text-dark-blue fs-20 text-right">add</mat-icon></div>
            </div>
        </div>
        <hr class="light light-color" />
        <div class="d-flex justify-content-end text-blue2 fs-13 fw-600 py-3 px-4">
            <div class="cursor-pointer mx-4" stop-propagation (click)="secondary_contact_popover.hide()">Dismiss</div>
            <!-- <div (click)="secondary_contact_popover.hide()" class="cursor-pointer">Save</div> -->
        </div>
    </div>
</ng-template>
