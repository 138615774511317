import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { TripsService } from '../../services/trips/trips.service';
import { GoogleMapService } from '../../services/google-map/google-map.service';
import { CookieService } from 'ngx-cookie-service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { Subscription } from 'rxjs';
import { IMapConfig, I_ETA_VALUE } from '../../components/google-map/google-map.component';
import { DriverService } from '../../services/driver/driver.service';
declare const google: any;

@Component({
    selector: 'app-trips-detail',
    templateUrl: './trips-detail.component.html',
    styleUrls: ['./trips-detail.component.scss'],
})
export class TripsDetailComponent implements OnInit {
    private subscriptionList$: Subscription = new Subscription();

    tripDetail: any;
    ipadSize: boolean = false;
    MapShow: any = true;
    imgLoading: boolean = false;
    imgNotfound: boolean = false;
    tab_type: 'trip_info' | 'milestone_detail' | 'fare_breakdown' | 'trip_activity' | 'view_map' = 'trip_info';
    //Trip Activity
    trip_activity: any[] = []; //
    driver_information_show: boolean = true;
    trip_information_show: boolean = true;
    TimezoneLabel: string;
    trip: any;
    map: google.maps.Map;
    session_id: string;
    isMobileScreen = false;

    /** Google Map */
    driver_pickup_ETA: I_ETA_VALUE;
    pickup_dropoff_ETA: I_ETA_VALUE;
    encodedPolyline: any; // to create map image for print
    mapConfig: IMapConfig;
    driver_coordinates: Array<{ lat: number; lng: number }>;

    constructor(
        public utilityService: UtilityService,
        private tripService: TripsService,
        private activatedRoute: ActivatedRoute,
        private googleMapService: GoogleMapService,
        private cookieService: CookieService,
        private httpService: HttpService,
        public driverService: DriverService
    ) {}
    ngOnInit(): void {
        // this.tripDetail = JSON.parse(localStorage.getItem('userTripDetails'));
        // if (this.tripDetail && !this.tripDetail.imgSourcePath) {
        //     this.initializeMap();
        // }

        this.checkScreenWidth();
        // Subscribe to window resize event to dynamically update the screen width
        window.addEventListener('resize', () => this.checkScreenWidth());

        this.session_id = this.activatedRoute.snapshot.paramMap.get('session_id');
        if (this.session_id) {
            this.get_ride_by_session();
        } else {
            this.utilityService.toast('error', 'Invalid trip reference, please try again');
        }
    }

    private checkScreenWidth(): void {
        this.isMobileScreen = window.innerWidth <= 767;
    }

    center: { lat: number; lng: number } = { lat: 40.73221, lng: -73.91902 };
    zoom = 11;
    directionsService: google.maps.DirectionsService = new google.maps.DirectionsService();
    directionsDisplay: google.maps.DirectionsRenderer = new google.maps.DirectionsRenderer();

    initializeMap() {
        console.log(this.map);
        if (!this.map) {
            this.map = new google.maps.Map(document.getElementById('map'), {
                center: this.center, //{ lat: -34.397, lng: 150.644 },
                zoom: this.zoom,
                mapTypeId: google.maps.MapTypeId.TERRAIN,
                // disableDefaultUI: true,
                mapTypeControl: false,
                fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                zoomControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                },
                streetViewControl: false,
            });

            // const bannerControlDiv = document.createElement('div');
            // bannerControlDiv.appendChild(this.bannerViewControl(this.map));
            // this.map.controls[google.maps.ControlPosition.TOP_RIGHT].push(bannerControlDiv);
        }
        this.directionsDisplay.setMap(this.map);
        // this.directionsDisplay.setPanel(document.getElementById('panel'));

        const pickup = this.tripDetail?.pickup_latitude + ',' + this.tripDetail?.pickup_longitude;
        const dropoff = this.tripDetail?.manual_destination_latitude + ',' + this.tripDetail?.manual_destination_longitude;
        console.log();
        // console.log(this.trip?.pickup);
        const request: google.maps.DirectionsRequest = {
            origin: pickup,
            destination: dropoff,
            travelMode: google.maps.TravelMode.DRIVING,
            avoidTolls: this.googleMapService.defaultAvoidTolls,
            avoidHighways: this.googleMapService.defaultAvoidHighways,
        };

        this.directionsService.route(request, (response, status) => {
            if (status == google.maps.DirectionsStatus.OK) {
                this.directionsDisplay.setDirections(response);

                // console.log("response", response)
                // this.trip_distance = response?.routes[0]?.legs[0]?.distance?.text;
                // this.trip_duration = response?.routes[0]?.legs[0]?.duration?.text;
            } else {
                console.log('map status => ', status);
                console.log('map response => ', response);
            }
        });
    }

    sendInvoice(send_to: 0 | 1): void {
        if (send_to === 0 && !this.tripDetail?.driver_email) {
            this.utilityService.toast('error', 'Please update corporate email!');
        } else if (send_to === 1 && !this.tripDetail?.user_email) {
            this.utilityService.toast('error', 'Please update rider email!');
        } else {
            this.utilityService.sendInvoice(this.tripDetail?.session_id, send_to);
        }
    }

    sendInvoiceToBoth() {
        this.sendInvoice(0);
        setTimeout(() => {
            this.utilityService.clearToast();
            this.sendInvoice(1);
        }, 2000);
    }

    get_ride_by_session(session_id: string = this.session_id) {
        this.utilityService.loading = true;
        this.tripService
            .get_ride_by_session(session_id)
            .subscribe(
                (res) => {
                    if (res?.length) {
                        this.tripDetail = res?.[0];
                        this.getTripActivity(this.tripDetail.mas_trip_id);
                        // this.getAssignedTripInfo(this.tripDetail.mas_trip_id);
                        this.initializeMap();
                    } else {
                        this.utilityService.toast('error', 'Trip not found');
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    // Get Trip Activity Data
    getTripActivity(mas_trip_id: number | string) {
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id: mas_trip_id,
        };
        this.subscriptionList$.add(
            this.httpService.postJSON(environment.urlWC + 'get_trip_activity', params).subscribe((data) => {
                if (data) {
                    this.trip_activity = data.trip_activity;
                }
            })
        );
    }

    /** Get Assigned Trip Info */
    getAssignedTripInfo(mas_trip_id: number | string) {
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            mas_trip_id: mas_trip_id,
        };
        this.subscriptionList$.add(
            this.httpService.postJSON(environment.urlWC + 'get_assgined_trip_info', params).subscribe((data) => {
                if (data) {
                    this.trip = data.trip;
                    // if (this.trip?.ride_milestone) {
                    //     this.get_driver_coordinates();
                    // } else {
                    //     this.togglLoading(false);
                    //     this.setupMapConfig();
                    // }
                }
            })
        );
    }

    get_driver_coordinates(mas_trip_id = this.trip?.mas_trip_id) {
        this.subscriptionList$.add(
            this.driverService
                .get_dirver_cordinates_history({
                    mas_trip_id,
                })
                .subscribe(
                    (res: { coordinates: Array<{ latitude: number; longitude: number }> }) => {
                        // console.log("get_driver_cordinates => ", res);
                        if (res?.coordinates?.length) {
                            this.driver_coordinates = res?.coordinates?.map((item) => {
                                return {
                                    lat: item.latitude,
                                    lng: item.longitude,
                                };
                            });
                        } else {
                            console.log('No Driver Route Found', res);
                            this.utilityService.toast('info', 'Trip does not contain driver route history');
                        }
                        console.log('Set Map Config');
                        this.setupMapConfig();
                    },
                    (err) => {
                        console.log('ERROR => ', err);
                    }
                )
                .add(() => {
                    this.togglLoading(false);
                })
        );
    }

    setupMapConfig() {
        // If Mobile view is being rendered on the trip info tab, skip the setup of map.
        if (this.tab_type === 'trip_info' && window.innerWidth <= 768) {
            console.log('return');
            return;
        }

        // Show milestone and polyline for completed assigned trip or milstone detail tab.
        if (this.tab_type === 'milestone_detail' || this.trip?.ride_status_text === 'completed') {
            console.log('Milestone based map rendering');
            const showDriver = this.trip?.ride_status_text !== 'completed';

            const request_pickup_dropoff = [
                // REQUEST PICKUP AND DROPOFF
                {
                    lat: Number(this.trip?.pickup_latitude),
                    lng: Number(this.trip?.pickup_longitude),
                    title: 'RP',
                    icon: 'assets/images/milestones_svgs/request_pickup.svg',
                    location: this.trip?.pickup_location,
                },
                {
                    lat: Number(this.trip?.dropoff_latitude),
                    lng: Number(this.trip?.dropoff_longitude),
                    title: 'RD',
                    icon: 'assets/images/milestones_svgs/request_dropoff.svg',
                    location: this.trip?.dropoff_location,
                },
            ];

            const milestones = [
                // MILESTONES
                {
                    lat: Number(this.trip?.dispatch_latitude),
                    lng: Number(this.trip?.dispatch_longitude),
                    title: 'S',
                    icon: 'assets/images/milestones_svgs/start_pickup.svg',
                },
                {
                    lat: Number(this.trip?.arrived_latitude),
                    lng: Number(this.trip?.arrived_longitude),
                    title: 'A',
                    icon: 'assets/images/milestones_svgs/arrived.svg',
                },
                {
                    lat: Number(this.trip?.starttrip_latitude),
                    lng: Number(this.trip?.starttrip_longitude),
                    title: 'P',
                    icon: 'assets/images/milestones_svgs/start_trip.svg',
                    anchorPoint:
                        Number(this.trip?.arrived_latitude) === Number(this.trip?.starttrip_latitude) &&
                        Number(this.trip?.arrived_longitude) === Number(this.trip?.starttrip_longitude)
                            ? new google.maps.Point(30, 20)
                            : null,
                },
                {
                    lat: Number(this.trip?.arrived_dropoff_latitude),
                    lng: Number(this.trip?.arrived_dropoff_longitude),
                    title: 'D',
                    icon: 'assets/images/milestones_svgs/arrived_dropoff.svg',
                },
                {
                    lat: Number(this.trip?.endtrip_latitude),
                    lng: Number(this.trip?.endtrip_longitude),
                    title: 'E',
                    icon: 'assets/images/milestones_svgs/end_trip.svg',
                    anchorPoint:
                        Number(this.trip?.arrived_dropoff_latitude) === Number(this.trip?.endtrip_latitude) &&
                        Number(this.trip?.arrived_dropoff_longitude) === Number(this.trip?.endtrip_longitude)
                            ? new google.maps.Point(30, 20)
                            : null,
                },
            ];

            // && this.trip?.ride_status_text === 'completed'
            const polylines = !this.driver_coordinates?.length
                ? milestones?.map((item) => ({ lat: item.lat, lng: item.lng }))
                : this.driver_coordinates;

            this.mapConfig = {
                id: 'assign-queue-milestone-detail',
                enable_driver_view: showDriver,
                show_online_driver_only: showDriver,
                show_custom_banner: true,
                show_driver_infowindow: showDriver,
                driver: {
                    id: showDriver ? this.trip?.sent_to : '',
                    driver_name: showDriver ? this.trip?.requested_driver_names : '',
                    driver_mobile: showDriver ? this.trip?.requested_driver_mobile : '',
                },
                milestones: [...request_pickup_dropoff, ...milestones],
                polylines: polylines,
            };
        } else {
            this.mapConfig = {
                id: 'assign-queue-detail',

                pickup_location: this.trip?.pickup_location,
                pickup_lat: this.trip?.pickup_latitude,
                pickup_lng: this.trip?.pickup_longitude,

                dropoff_location: this.trip?.dropoff_location,
                dropoff_lat: this.trip?.dropoff_latitude,
                dropoff_lng: this.trip?.dropoff_longitude,
                enable_driver_view: true,
                show_online_driver_only: true,
                driver: {
                    id: this.trip?.sent_to,
                    driver_name: this.trip?.requested_driver_names,
                    driver_mobile: this.trip?.requested_driver_mobile,
                },
                show_custom_banner: true,
                show_driver_infowindow: true,
                avoidHighways: this.googleMapService.defaultAvoidHighways,
                avoidTolls: this.googleMapService.defaultAvoidTolls,
            };
        }
    }

    togglLoading(flag: boolean) {
        this.utilityService.loading = flag;
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }
}
