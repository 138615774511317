import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'confirm',
    templateUrl: './confirm.component.html',
    styleUrls: ['./confirm.component.scss'],
})
export class ConfirmComponent implements OnInit {
    @Input() heading: string = 'Modal Heading';
    @Input() description: string = 'Modal description';
    @Input() leftButtonText: string;
    @Input() rightButtonText: string;
    @Input() styleType: string = 'default';
    @Input() headingType: string;

    constructor(public bsModalRef: BsModalRef) {}

    active_fare_breakdown_flag: 'now' | 'later';
    fare_breakdown: any;

    public event: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {}

    on_button_action(type: 'left' | 'right') {
        this.bsModalRef.hide();
        this.event.emit({ action: type });
    }
}
