<div
    [popover]="note_popover"
    containerClass="br-6 notes_popover"
    #notePopover="bs-popover"
    (click)="note_popover_reference = notePopover; get_mas_notes()"
    placement="bottom left"
    [outsideClick]="true"
    [adaptivePosition]="false"
    class="cursor-pointer"
>
    <ng-container *ngIf="labelType === 'icon'">
        <img src="/assets/images/view-note.svg" />
    </ng-container>
    <ng-container *ngIf="labelType === 'note'">
        <ng-container *ngIf="notes[0] && notes[0]?.notes">
            {{ notes[0]?.notes }}
        </ng-container>
        <ng-container *ngIf="!notes[0] || !notes[0]?.notes">N/A</ng-container>
        <mat-icon class="fs-16 ml-2">edit</mat-icon>
    </ng-container>
</div>

<ng-template #note_popover>
    <div class="touch-popover">
        <div class="py-3 px-4 fw-600 text-dark-blue fs-16">{{ title[noteListType] }}</div>
        <hr class="light light-color" />
        <div>
            <!-- Common Loading for Rider and Trip Note -->
            <div class="px-4" *ngIf="isLoading">
                <app-loading placeholderLength="3" height="20px" borderRadius="2px" type="placeholder"></app-loading>
            </div>

            <!-- Trip Notes -->
            <div *ngIf="noteListType === 'trip'" class="note-list touch-scrollbar">
                <div *ngIf="!isLoading && new_note_mode" class="px-4 py-2">
                    <div class="d-flex justify-content-between">
                        <div class="w-100">
                            <input
                                focused
                                [formControl]="newNoteForm"
                                type="text"
                                class="note-input w-100"
                                placeholder="Write a note here"
                                [placeholder]="new_note_placeholder"
                            />
                        </div>
                        <div class="align-self-center">
                            <div [class.disabled]="newNoteForm.invalid" (click)="$event.stopPropagation(); add_note()">
                                <ng-container [ngTemplateOutlet]="save_icon"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!isLoading && notes?.length">
                    <div class="d-flex justify-content-between px-4 py-2" *ngFor="let item of notes">
                        <ng-container *ngIf="edit_note?.notes_id != item.notes_id">
                            <div class="text-grey">{{ item.notes }}</div>
                            <div class="align-self-center">
                                <mat-icon
                                    class="text-dark-blue fs-16 cursor-pointer w-auto"
                                    (click)="$event.stopPropagation(); edit_note = item; editNoteForm.patchValue(item.notes); new_note_mode = false"
                                >
                                    edit
                                </mat-icon>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="edit_note?.notes_id === item.notes_id">
                            <div class="w-100 mr-auto">
                                <input
                                    focused
                                    [formControl]="editNoteForm"
                                    type="text"
                                    class="note-input w-100"
                                    [placeholder]="new_note_placeholder"
                                />
                            </div>
                            <div class="align-self-center mr-1">
                                <div
                                    [class.disabled]="editNoteForm.invalid || item.notes === editNoteForm.value"
                                    (click)="$event.stopPropagation(); update_note(item)"
                                >
                                    <ng-container [ngTemplateOutlet]="save_icon"></ng-container>
                                </div>
                            </div>
                            <div class="align-self-center">
                                <mat-icon
                                    class="text-dark-blue fs-18 w-auto cursor-pointer"
                                    (click)="$event.stopPropagation(); edit_note = null; editNoteForm.reset()"
                                >
                                    close
                                </mat-icon>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>

            <!-- Rider Notes -->
            <div *ngIf="noteListType === 'rider'" class="note-list touch-scrollbar">
                <div *ngIf="!isLoading" class="px-4 py-2">
                    <div class="d-flex justify-content-between">
                        <div class="w-100">
                            <input
                                focused
                                [formControl]="newNoteForm"
                                type="text"
                                class="note-input w-100"
                                placeholder="Write a note here"
                                [placeholder]="new_note_placeholder"
                            />
                        </div>
                        <div class="align-self-center">
                            <div [class.disabled]="newNoteForm.invalid" (click)="add_rider_note()">
                                <ng-container [ngTemplateOutlet]="save_icon"></ng-container>
                            </div>
                        </div>
                    </div>
                </div>

                <div *ngIf="!isLoading && rider_notes?.length">
                    <div class="d-flex justify-content-between px-4 py-2" *ngFor="let item of rider_notes">
                        <ng-container *ngIf="edit_note?.notes_id != item.notes_id">
                            <div class="text-grey">{{ item.description }}</div>
                            <div class="align-self-center">
                                <mat-icon
                                    class="text-dark-blue fs-16 w-auto"
                                    (click)="
                                        $event.stopPropagation(); edit_note = item; editNoteForm.patchValue(item.description); new_note_mode = false
                                    "
                                >
                                    edit
                                </mat-icon>
                            </div>
                        </ng-container>

                        <ng-container *ngIf="edit_note?.notes_id === item.notes_id">
                            <div class="w-100 mr-auto">
                                <input
                                    focused
                                    [formControl]="editNoteForm"
                                    type="text"
                                    class="note-input w-100"
                                    [placeholder]="new_note_placeholder"
                                />
                            </div>
                            <div class="align-self-center mr-1">
                                <div
                                    [class.disabled]="editNoteForm.invalid || item.description === editNoteForm.value"
                                    (click)="$event.stopPropagation(); update_rider_note(item)"
                                >
                                    <ng-container [ngTemplateOutlet]="save_icon"></ng-container>
                                </div>
                            </div>
                            <div class="align-self-center">
                                <mat-icon
                                    class="text-dark-blue fs-18 w-auto"
                                    (click)="$event.stopPropagation(); edit_note = null; editNoteForm.reset(); new_note_mode = true"
                                >
                                    close
                                </mat-icon>
                            </div>
                        </ng-container>
                    </div>
                </div>
            </div>
        </div>
        <hr class="light light-color" />

        <!-- Common Action Center for Rider And Trip Notes -->
        <div class="d-flex align-items-center text-blue2 fs-13 fw-600 py-3 px-4">
            <!-- Only used for Rider -->
            <div
                *ngIf="noteListType === 'rider'"
                [disabled]="!rider_notes?.length || !user_name"
                class="cursor-pointer mx-4"
                (click)="$event.stopPropagation(); navigateToRiderDetailNote()"
            >
                View More
            </div>
            <div class="flex-fill"></div>

            <!-- Can be used for Rider and Trip Notes -->
            <div class="cursor-pointer mx-3">
                <div
                    *ngIf="noteListType === 'trip'"
                    [class.disabled]="!user_id"
                    (click)="$event.stopPropagation(); this.noteListType = 'rider'; get_rider_notes(); new_note_mode = true"
                >
                    View Riders Notes
                </div>
                <div *ngIf="noteListType === 'rider'" (click)="$event.stopPropagation(); this.noteListType = 'trip'; get_mas_notes()">
                    View Trip Notes
                </div>
            </div>
            <div class="cursor-pointer mr-4" (click)="note_popover_reference.hide()">Dismiss</div>
        </div>
    </div>
</ng-template>

<ng-template #save_icon>
    <img style="height: 18px" class="cursor-pointer" src="assets/images/save_note.svg" alt="save" />
</ng-template>
