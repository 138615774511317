import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';
import { FormControl } from '@angular/forms';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { CarsOptions } from 'src/app/core/services/utility/static-data.config';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IOptionItem, ISettingHeaderConfig, OptionType } from 'src/app/models/setting-header.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

type subTabType = 'All Trips' | 'Scheduled Trips' | 'On-Demand Trips';

@Component({
    selector: 'app-completed-trips',
    templateUrl: './completed-trips.component.html',
    styleUrls: ['./completed-trips.component.scss'],
})
export class CompletedTripsComponent implements OnInit, OnDestroy {
    subscriptionList: Subscription = new Subscription();
    activeSubTab: subTabType = 'All Trips';
    showPagination: boolean = false;
    searchForm: FormControl = new FormControl('');
    driver_search: FormControl = new FormControl('');
    isLoading: boolean = false;
    Trips: Array<any> = [];
    pageNo: number = 0;
    itemsPerPage: number = this.utilityService.default_display_filter;
    totalItems: number = 0;
    showHeaderShadow: boolean = false;

    constructor(
        public utilityService: UtilityService,
        private cookieService: CookieService,
        private httpService: HttpService,
        private router: Router
    ) {}

    // As we need same clonned logic under rider detail page, so these parameters are for Rider Detail Page usage only, as per now
    @Input() minimal_setup: boolean = false;
    @Input() rider_name_pre_filter: string;
    @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

    pageHeaderConfig: ISettingHeaderConfig = {
        headerTitle: 'Trip Roster',
        tabs: {
            items: this.utilityService.settingHeaderConfig.active_trips.tabs,
            activeTab: 'Completed',
        },
        subTabs: {
            items: this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Completed'],
            activeSubTab: this.activeSubTab,
            preventRouteAction: true,
        },
        search: {
            form: this.searchForm,
            placeholder: 'Search',
            type: OptionType.SEARCH,
        },
        moreOptionList: [],
    };

    ngOnInit(): void {
        this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.getTrips(1);
        });

        this.driver_search.valueChanges.pipe(debounceTime(500)).subscribe(() => {
            this.getTrips(1);
        });

        if (this.rider_name_pre_filter) {
            this.searchForm.patchValue(this.rider_name_pre_filter);
            this.searchForm.disable();
        } else {
            this.getTrips(1);
        }
    }

    onPageHeaderEvent(event: IOptionItem) {
        if (event.id == 'tab') {
            this.onTabChange.next(event.value);
        }
        if (event.id === 'subtab') {
            this.changeActiveSubTab(event.value);
        }
    }

    ngOnDestroy(): void {
        this.subscriptionList.unsubscribe();
    }

    changeActiveSubTab(tab: subTabType) {
        this.activeSubTab = tab;
        this.getTrips(1);
    }

    public async getTrips(pageNo: number, itemsPerPage: number = this.itemsPerPage) {
        this.itemsPerPage = itemsPerPage;
        this.pageNo = pageNo;
        const skip = (pageNo - 1) * this.itemsPerPage;
        this.utilityService.updateQueryParams({ page: pageNo });

        let payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: `${this.itemsPerPage}`,
            offset: skip,
            requestType: 1,
            all: this.activeSubTab === 'All Trips' ? 1 : 0,
        };

        if (this.activeSubTab !== 'All Trips') {
            payload.is_schedule = this.activeSubTab === 'Scheduled Trips' ? 1 : 0;
        }

        if (this.searchForm.value) {
            payload.searchFlag = 1;
            payload.searchString = this.searchForm.value;
        }

        if (this.driver_search.value) {
            payload.driver_filter = 1;
            payload.driver_searchString = this.driver_search.value;
        }

        this.isLoading = true;
        this.Trips = [];

        this.httpService.post(environment.urlC + 'ride_history', payload).subscribe((data) => {
            if (typeof data == 'string') data = JSON.parse(data);
            if (data.flag == 101) {
                this.router.navigate(['/', 'corporate_login']);
            }
            if (data.flag == 502) {
                this.processTrips(data);
            } else this.isLoading = false;
        });
    }

    public processTrips(data: any): void {
        this.Trips = [];
        this.totalItems = data.total_length;
        const Bookings = data.my_trips;

        Bookings.forEach((bookingData: any) => {
            const d = bookingData;
            d.payment_method = bookingData.payment_method || 'Card';

            d.ride_status = this.utilityService.getRideStatus(bookingData.ride_status, bookingData.request_status);
            // d.ride_status_text_class = this.getButtonClass(d.ride_status);

            // d.total_with_tax = bookingData?.total_with_tax?.toFixed(2) || '';
            //d.total_with_tax = parseFloat(d.total_with_tax);

            if (!d.car_name) {
                d.car_name = CarsOptions.find((x) => x.car_type == d.car_type)['car_name'];
            }

            if (bookingData?.pickup_time) {
                const dt = new Date(d.pickup_time);
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                const raw = dt.toISOString();
                d.pickup_time = raw;
            }
            if (bookingData?.start_time) {
                d.start_time = bookingData.start_time;
                const dt = new Date(d.start_time);
                dt.setMinutes(dt.getMinutes() + dt.getTimezoneOffset());
                d.start_time = dt.toISOString();
            }
            this.Trips.push(d);
            // if (
            //   d.ride_status === 'Completed'
            // ) {
            //   this.Trips.push(d);
            // }
        });
        this.isLoading = false;
        this._setShowPagination();
    }

    private _setShowPagination() {
        // (this.Trips?.length >= this.itemsPerPage || this.pageNo != 1) &&
        this.showPagination = this.Trips?.length > 0 && !this.isLoading;
        // && this.totalItems > this.itemsPerPage;
    }
}
