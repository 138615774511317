import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-loading',
    templateUrl: './loading.component.html',
    styleUrls: ['./loading.component.scss'],
})
export class LoadingComponent implements OnInit {
    @Input() type: 'spinner' | 'placeholder' = 'spinner';
    @Input() placeholderLength: any = 1;
    @Input() show: 'grid' | 'list' = 'list';
    listLength: Array<number>;
    @Input() height: string = '70px';
    @Input() width: string = '100%';
    @Input() borderRadius: string = '8px';
    @Input() margin: string = '4px 0px';
    @Input() space = true;

    constructor() {}
    ngOnInit(): void {
        if (this.type === 'placeholder') {
            this.listLength = Array(this.placeholderLength);
        }
    }
}
