import { Component, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IOptionItem, ISettingHeaderConfig, OptionType } from 'src/app/models/setting-header.model';

@Component({
    selector: 'app-transaction-history',
    templateUrl: './transaction-history.component.html',
    styleUrls: ['./transaction-history.component.scss'],
})
export class TransactionHistoryComponent implements OnInit {
    searchForm: FormControl = new FormControl();
    filterDateForm: FormControl = new FormControl();
    activeTransaction: string = this.route.snapshot.data?.type; // All trips, paid, unpaid

    pageHeaderConfig: ISettingHeaderConfig = {
        headerTitle: 'Transaction History',
        tabs: {
            items: this.utilityService.settingHeaderConfig.transaction_history.transaction_type,
            activeTab: 'All Trips',
        },
        search: {
            form: this.searchForm,
            placeholder: 'Search',
            type: OptionType.SEARCH,
        },
        moreOptionList: [
            { type: OptionType.NORMAL_DATE, id: 'filter-date', form: this.filterDateForm },
            { type: OptionType.BUTTON, id: 'export-csv', text: 'Export CSV', emitEvent: true },
        ],
    };
    constructor(public utilityService: UtilityService, private route: ActivatedRoute) {}

    ngOnInit(): void {
        this.setActiveTab();
    }

    /** On page change event */
    onPageHeaderEvent(event: IOptionItem) {}

    /** Set active tab */
    setActiveTab() {
        if (this.activeTransaction === 'all') {
            this.pageHeaderConfig.tabs.activeTab = 'All Trips';
        } else if (this.activeTransaction === 'paid') {
            this.pageHeaderConfig.tabs.activeTab = 'Paid';
        } else if (this.activeTransaction === 'unpaid') {
            this.pageHeaderConfig.tabs.activeTab = 'Unpaid';
        }
    }
}
