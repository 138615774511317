import { CoreModule } from './core/core.module';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ToastContainerModule, ToastrModule } from 'ngx-toastr';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input-r';
import { ComboDatepickerModule } from 'ngx-combo-datepicker';
import { CalendarModule } from 'primeng/calendar';
import { DropdownModule } from 'primeng/dropdown';
import { LoginComponent } from './pages/login/login.component';
// import { NgxPaginationModule } from 'ngx-pagination';

import { CorporateSignupComponent } from './pages/corporate-signup/corporate-signup.component';
import * as $ from 'jquery';
import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';
import { BsModalService, ModalModule } from 'ngx-bootstrap/modal';
import { NotificationToastComponent } from './corporate/components/notification-toast/notification-toast.component';
import { NgxStripeModule, StripeService } from 'ngx-stripe';
const thirdParties = [
    NgxIntlTelInputModule,
    // NgxPaginationModule,
    ComboDatepickerModule,
    CalendarModule,
    DropdownModule,
];

@NgModule({
    declarations: [AppComponent, LoginComponent, CorporateSignupComponent],
    imports: [
        BrowserModule,
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        HttpClientModule,
        BrowserAnimationsModule,
        ModalModule,
        NgxStripeModule.forRoot(environment.stripeKey),
        ToastrModule.forRoot({
            // maxOpened: 1,
        }), // ToastrModule added
        ToastContainerModule,
        CoreModule,
        ...thirdParties,
        ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    ],
    providers: [BsModalService],
    bootstrap: [AppComponent],
    entryComponents: [NotificationToastComponent],
})
export class AppModule {}
