import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { Card, ListCardPaymentMethod, ListPaymentCards, PaymentOptionMap } from 'src/app/models/card.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root',
})
export class CardsService {
    userCardPaymentOption: 'corporate' | 'rider' | 'both';
    corporateCards: { default_card_id: string | number; cards: Array<Card> } = { default_card_id: '', cards: [] };
    riderCards: { default_card_id: string | number; cards: Array<Card> } = { default_card_id: '', cards: [] };

    constructor(private httpService: HttpService, private utilityService: UtilityService, private cookieService: CookieService) {
        this.listCardPaymentOption().subscribe();
    }

    listCardPaymentOption() {
        return this.httpService
            .post(environment.urlC + 'list_card_payment_option', {
                web_access_token: this.cookieService.get('web_access_token'),
            })
            .pipe(
                map((payment_option: ListCardPaymentMethod) => {
                    this.userCardPaymentOption = PaymentOptionMap[payment_option.payment_type[0]?.card_used];
                    return payment_option;
                })
            );
    }

    getPaymentCards(user_id: string): Observable<ListPaymentCards> {
        return this.httpService
            .post(environment.urlC + 'list_payment_cards', {
                web_access_token: this.cookieService.get('web_access_token'),
                user_id,
            })
            .pipe(
                map((cards: { corporateCards: Array<Card>; userCards: Array<Card> }) => {
                    if (cards.corporateCards) {
                        this.corporateCards.cards = cards.corporateCards.map((card: Card) => {
                            if (card.default_card) {
                                this.corporateCards.default_card_id = card.id;
                            }
                            const dt = new Date(card.card_added_on);
                            dt.setMinutes(dt.getMinutes() - Number(card.offset));
                            card.card_added_on = dt.toISOString();
                            return card;
                        });
                    }
                    if (cards.userCards) {
                        this.riderCards.cards = cards.userCards.map((card: Card) => {
                            if (card.default_card) {
                                this.riderCards.default_card_id = card.id;
                            }
                            const dt = new Date(card.card_added_on);
                            dt.setMinutes(dt.getMinutes() - Number(card.offset));
                            card.card_added_on = dt.toISOString();
                            return card;
                        });
                    }
                    return { corporateCards: this.corporateCards, riderCards: this.riderCards };
                })
            );
    }

    /**
     *
     * @param params @type {web_access_token: string, nounce: any, card_type: number | string, offset: any}
     * @param add_type : corporate or rider
     * @returns Observable
     */
    add_card(
        params: { web_access_token: string; nounce: any; card_type: number | string; offset: any; user_id?: number | string },
        add_type: 'corporate' | 'rider' = 'corporate'
    ): Observable<any> {
        const url_suffix = add_type == 'corporate' ? 'add_credit_card' : 'add_user_credit_card';
        return this.httpService.post(environment.urlC + url_suffix, {
            web_access_token: this.cookieService.get('web_access_token'),
            ...params,
        });
    }

    // set default card
    set_default_card(card_id: string | number): Observable<any> {
        return this.httpService.post(environment.urlC + 'change_default_card', {
            web_access_token: this.cookieService.get('web_access_token'),
            card_id,
        });
    }
}
