import { Component, EventEmitter, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { IBalance } from 'src/app/models/business-center.model';
import { Card } from 'src/app/models/card.model';
import { BusinessCenterService } from '../../services/business-center/business-center.service';
import { CardsService } from '../../services/cards/cards.service';
import { AddCardComponent } from '../add-card/add-card.component';

@Component({
    selector: 'app-select-cards',
    templateUrl: './select-cards.component.html',
    styleUrls: ['./select-cards.component.scss'],
})
export class SelectCardsComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        public cardsService: CardsService,
        private utilityService: UtilityService,
        private modalService: BsModalService,
        private businessService: BusinessCenterService
    ) {}

    corporateCards: { default_card_id: string | number; cards: Array<Card> };
    riderCards: { default_card_id: string | number; cards: Array<Card> };
    cardPassed: boolean = false;
    request_type: 'now' | 'later';
    user_id: string;
    user_email: string;
    searchCard: string;
    showCardsType: 'corporate' | 'rider' | 'corporate_wallet' = 'corporate';
    selected_card_id: string | number;
    amount: number | string = 0;

    cardLoading: boolean = false; // cardLoading state
    balanceLoading: boolean = false;
    available_wallet_balance: number | string;

    public event: EventEmitter<any> = new EventEmitter();
    confirm_wallet_transaction: boolean = false;

    ngOnInit(): void {
        if (!this.cardPassed) {
            this._getPaymentCards();
        } else {
            this.corporateCards = this.cardsService.corporateCards;
            this.riderCards = this.cardsService.riderCards;
            this.setActiveTab(this.showCardsType);
        }

        this.get_wallet_balance();
    }

    setActiveTab(type: 'corporate' | 'rider' | 'corporate_wallet') {
        this.showCardsType = type;
        if (type === 'corporate') {
            this.selected_card_id = this.corporateCards.default_card_id || this.corporateCards.cards[0]?.id || '';
        } else {
            this.selected_card_id = this.riderCards.default_card_id || this.riderCards.cards[0]?.id || '';
        }
    }

    private _getPaymentCards(user_id: string = this.user_id) {
        this.cardLoading = true;
        return this.cardsService
            .getPaymentCards(user_id)
            .subscribe((cards) => {
                if (cards.corporateCards) {
                    this.corporateCards = cards.corporateCards;
                }
                if (cards.riderCards) {
                    this.riderCards = cards.riderCards;
                }
                this.setActiveTab(this.showCardsType);
            })
            .add(() => {
                this.cardLoading = false;
            });
    }

    onSelect() {
        this.bsModalRef.hide();
        this.event.emit({ card_id: this.selected_card_id, payment_from: this.showCardsType });
    }

    // Set Default Card
    public setDefaultCard(card_id: string | number, silent: boolean = false) {
        if (card_id) {
            this.cardsService.set_default_card(card_id).subscribe((data) => {
                if (data.error) {
                    this.utilityService.toast('error', data.error, '');
                    return;
                } else {
                    this._getPaymentCards();
                    if (!silent) this.utilityService.toast('success', 'Default card updated', '');
                }
            });
        }
    }

    addCard() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                user_id: this.user_id,
                user_email: this.user_email,
                hard_check_for_email: this.showCardsType === 'rider',
                cardType: this.showCardsType,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(AddCardComponent, initialState);
        modalref.content.event.subscribe((res: { status: 'success' | 'fail'; data: any }) => {
            if (res.status === 'success') {
                this._getPaymentCards();
            }
        });
    }

    public selectThisCard(id: any) {
        $('#' + id).click();
    }

    get_wallet_balance() {
        this.balanceLoading = true;
        this.businessService
            .get_wallet_balance()
            .subscribe(
                (res: { availableBalance: number; onTheWay: number; willBeAvailableSoon: number; flag: number; log: string; error?: string }) => {
                    if (res?.flag === 20002) {
                        this.available_wallet_balance = res?.availableBalance;
                    } else if (res?.flag === 3200) {
                        this.utilityService.toast('info', res?.error);
                    } else if (res?.error) {
                        this.utilityService.toast('error', res?.error);
                    } else {
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                }
            )
            .add(() => {
                this.balanceLoading = false;
            });
    }
}
