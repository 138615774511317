import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as _ from 'lodash';
import { BsModalRef } from 'ngx-bootstrap/modal';
declare const google: any;
declare const jQuery: any;

interface ILocation {
    location: string;
    lat?: string | number;
    lng?: string | number;
}

@Component({
    selector: 'app-location-picker-modal',
    templateUrl: './location-picker-modal.component.html',
    styleUrls: ['./location-picker-modal.component.scss'],
})
export class LocationPickerModalComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}
    title: string = 'Edit Pickup & Drop Off Location';
    leftButtonText: string = 'Cancel';
    rightButtonText: string = 'Save';
    viewMode: 'both' | 'pickup' | 'dropoff' = 'both';

    location: {
        pickup_location?: ILocation;
        dropoff_location?: ILocation;
    } = {
        pickup_location: { location: '' },
        dropoff_location: { location: '' },
    };

    locationFormGroup: FormGroup = new FormGroup({
        pickup_location: new FormControl(null, [Validators.required]),
        pickup_lat: new FormControl(null),
        pickup_lng: new FormControl(null),

        dropoff_location: new FormControl(null, [Validators.required]),
        dropoff_lat: new FormControl(null),
        dropoff_lng: new FormControl(null),
    });
    public event: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {
        this.syncInputWithForm();
        this.locationPicker();
    }
    syncInputWithForm() {
        const patchPayload: any = {};
        if (this.location.pickup_location?.location) {
            (patchPayload.pickup_location = this.location.pickup_location?.location),
                (patchPayload.pickup_lat = this.location.pickup_location?.lat),
                (patchPayload.pickup_lng = this.location.pickup_location?.lng);
        }
        if (this.location.dropoff_location?.location) {
            (patchPayload.dropoff_location = this.location.dropoff_location?.location),
                (patchPayload.dropoff_lat = this.location.dropoff_location?.lat),
                (patchPayload.dropoff_lng = this.location.dropoff_location?.lng);
        }
        this.locationFormGroup.patchValue(patchPayload);
    }

    onConfirm() {
        this.locationFormGroup.markAllAsTouched();
        if (this.locationFormGroup.invalid) {
            return;
        }
        this.event.emit(this.locationFormGroup.value);
        this.bsModalRef.hide();
    }

    public locationPicker() {
        let autocomplete = {};
        let autocompletesWraps = ['pickup_location', 'dropoff_location'];
        autocompletesWraps.forEach((name: string, index: number) => {
            if ($('#' + name).length == 0) {
                return;
            }
            autocomplete[name] = new google.maps.places.Autocomplete($('#' + name + '.autocomplete')[0]);
            google.maps.event.addListener(autocomplete[name], 'place_changed', () => {
                const place = autocomplete[name].getPlace();
                if (!place.geometry) {
                    alert('Something Went Wrong!!');
                    return;
                }
                console.log((<HTMLInputElement>document.getElementById(name)).value);
                console.log(place.geometry.location.lat(), place.geometry.location.lng());
                console.log(place);

                switch (name) {
                    case 'pickup_location':
                        this.locationFormGroup.patchValue({
                            pickup_location: (<HTMLInputElement>document.getElementById(name)).value,
                            pickup_lat: place.geometry.location.lat(),
                            pickup_lng: place.geometry.location.lng(),
                        });
                        break;

                    case 'dropoff_location':
                        this.locationFormGroup.patchValue({
                            dropoff_location: (<HTMLInputElement>document.getElementById(name)).value,
                            dropoff_lat: place.geometry.location.lat(),
                            dropoff_lng: place.geometry.location.lng(),
                        });
                        break;
                }
            });
        });
    }
}
