import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpService } from '../../../services/http/http.service';
import { environment } from 'src/environments/environment';
import { UtilityService } from './../../../core/services/utility/utility.service';
import * as moment from 'moment';
import { CookieService } from 'ngx-cookie-service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input-r';
import { country_code } from '../live-tracking/country-list';
import { IOptionItem, ISettingHeaderConfig, OptionType } from 'src/app/models/setting-header.model';
declare const jQuery: any;
const $: any = jQuery;

interface StaffResponse {
    total_staff: number;
    staff: Array<Staff>;
    flag?: number;
    error?: string;
}
interface Staff {
    corporate_id: number | string;
    corporate_type: number | string;
    staff_name: string;
    staff_mobile: string;
    added_on: Date | any;
}

@Component({
    selector: 'app-staff',
    templateUrl: './staff.component.html',
    styleUrls: ['./staff.component.scss'],
})
export class StaffComponent implements OnInit {
    itemsPerPage: number = this.utilityService.default_display_filter;
    Staffs: Array<any> = [];
    isLoading: boolean = false;
    page: number = 1;
    totalItems: number;
    editStaff: boolean = false;

    searchForm: FormControl = new FormControl('');
    userRole: 'admin' | 'staff' = 'admin';

    // ngx-intl-tel-input
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    SearchCountryField = SearchCountryField;
    separateDialCode = false;
    selectedCountryISO: any = CountryISO.UnitedStates;
    TooltipLabel = TooltipLabel;

    staffForm: FormGroup = new FormGroup({
        staff_id: new FormControl(''),
        staff_name: new FormControl('', [Validators.required]),
        staff_mobile: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    showPagination: boolean = false;
    showHeaderShadow: boolean = false;

    pageHeaderConfig: ISettingHeaderConfig = {
        headerTitle: 'My Staff',
        tabs: {
            items: this.utilityService.settingHeaderConfig.settings,
            activeTab: 'My Staff',
        },
        search: {
            form: this.searchForm,
            placeholder: 'Search',
            type: OptionType.SEARCH,
        },
        moreOptionList: [],
    };

    constructor(
        private router: Router,
        private httpService: HttpService,
        public utilityService: UtilityService,
        private cookieService: CookieService
    ) {}

    ngOnInit(): void {
        this.get_staffs();

        if (this.userRole === 'admin') {
            this.pageHeaderConfig.moreOptionList.push({ type: OptionType.BUTTON, id: 'action-btn', text: 'Add Staff', emitEvent: true });
        }

        const loggedInDriverData: any = JSON.parse(localStorage.getItem('corporateModel'));
        if (loggedInDriverData && loggedInDriverData?.corporate_type === 1) this.userRole = 'staff';
    }

    onPageHeaderEvent(event: IOptionItem) {
        if (event.id === 'action-btn') {
            this.openAddStaffModal();
        }
    }

    deleteStaff(data) {}
    completeUserAdd() {}

    public get_staffs(page: number = 1, itemsPerPage = this.itemsPerPage) {
        this.page = page;
        this.itemsPerPage = itemsPerPage;
        this.isLoading = true;
        // no data required
        const params = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: this.itemsPerPage,
            offset: (page - 1) * itemsPerPage,
            // searchFlag: 0,
            // searchString: '',
        };
        this.httpService.post(environment.urlC + 'get_staff', params).subscribe(
            (data: StaffResponse) => {
                if (data.flag === 100) {
                    this.utilityService.toast('error', data?.error);
                    return;
                }

                this.totalItems = data.total_staff;
                this.Staffs = data.staff;
                this.isLoading = false;
                this._setShowPagination();
            },
            (error) => {
                this.isLoading = false;
                this.utilityService.toast('error', error.message || 'Something went wrong');
                console.error(error);
            }
        );
    }

    private _setShowPagination() {
        this.showPagination = this.Staffs?.length > 0;
    }

    public onStaffSubmit() {
        if (this.staffForm.invalid) return;

        const staff: {
            staff_name: string;
            password: string;
            staff_mobile: {
                dialCode: string;
                number: string;
            };
            staff_id: number | string;
        } = this.staffForm.value;

        const params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            staff_mobile: `${staff.staff_mobile.dialCode}-${staff.staff_mobile.number}`,
            staff_name: staff.staff_name,
            password: staff.password,
        };
        let staff_url = environment.urlC + 'add_staff';

        if (this.editStaff) {
            params.staff_id = staff.staff_id;
            staff_url = environment.urlC + 'update_staff';
            if (!params.password) delete params.password;
        }

        this.httpService.post(staff_url, params).subscribe(
            (data: { log: string; flag: number; error?: string }) => {
                if (data.flag == 141 || data.flag == 143) {
                    this.utilityService.toast('success', data.log);
                    this.page = 1;
                    this.get_staffs();
                    this.utilityService.hideModal('add_staff');
                    setTimeout(() => {
                        this.staffForm.reset();
                    }, 0);
                } else {
                    this.utilityService.toast('warning', data.error || 'Something went wrong');
                }
            },
            (error) => {
                this.utilityService.toast('warning', error.message || 'Something went wrong');
            }
        );
    }

    openEditStaffMemberModal(staff_member: { staff_name: string; staff_mobile: string; corporate_id: any }) {
        // this.staffForm.get('password').clearValidators();
        setTimeout(() => {
            this.staffForm.controls['password'].clearValidators();
        }, 0);
        this.editStaff = true;
        // console.log(staff_member);
        console.log(staff_member);
        this.staffForm.patchValue({
            staff_id: staff_member?.corporate_id,
            staff_name: staff_member.staff_name,
            staff_mobile: staff_member.staff_mobile.split('-')[1],
            password: '',
        });
        this.selectedCountryISO = country_code[staff_member.staff_mobile.split('-')[0]]?.toLowerCase();
        this.utilityService.showModal('add_staff');
    }

    openAddStaffModal() {
        this.selectedCountryISO = CountryISO.UnitedStates;
        this.staffForm.reset();
        this.editStaff = false;
        this.utilityService.showModal('add_staff');
        // this.staffForm.get('password').setValidators([Validators.required]);
        this.staffForm.controls['password'].setValidators(Validators.required);
    }

    confirmRemoveStaff(staff_member: any) {
        this.utilityService.showModal('confirm_remove_staff');

        this.utilityService
            .confirm({
                heading: 'Remove Staff?',
                description: `Are you sure want to remove ${staff_member?.staff_name} from staff list?`,
                leftButtonText: 'Cancel',
                rightButtonText: 'Confirm',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.removeStaff(staff_member);
                }
            });
    }

    // remove staff
    removeStaff(staff_member: any) {
        if (!staff_member) this.utilityService.toast('warning', 'Something went wrong please try again!');
        this.httpService
            .post(environment.urlC + 'delete_staff', {
                web_access_token: this.cookieService.get('web_access_token'),
                staff_id: staff_member.corporate_id,
            })
            .subscribe(
                (data: { log: string; flag: number; error?: string }) => {
                    if (data.flag === 142) {
                        this.utilityService.toast('success', data.log);
                        this.page = 1;
                        this.get_staffs();
                    } else {
                        this.utilityService.toast('warning', data.error || 'Something went wrong');
                    }
                },
                (error) => {
                    this.utilityService.toast('warning', error.message || 'Something went wrong');
                }
            );
    }
}
