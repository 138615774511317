import { Injectable } from '@angular/core';
import * as moment from 'moment';
import { BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { LegCostBreakdownComponent } from '../../components/leg-cost-breakdown/leg-cost-breakdown.component';
import { ILiveTripInfo } from '../../components/live-trip-info/live-trip-info.component';
import { MasTripInfoComponent } from 'src/app/core/components/mas-trip-info/mas-trip-info.component';

@Injectable({
    providedIn: 'root',
})
export class MasService {
    third_party_enabled: boolean = false;
    brokerStatus: string = 'All Brokers';
    brokerStatusLabel: string = 'All Brokers';

    constructor(
        private cookieService: CookieService,
        private httpService: HttpService,
        private utilityService: UtilityService,
        private modalService: BsModalService
    ) {
        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));
        this.third_party_enabled = driverModel.is_mas_dispatcher == 1 ? true : false;
    }

    /**
     * @param tripLegStatus  - please use the following key in the api : /mas_trip_roster_request to check if the trip is deleted ,cancelled or active from mas. tripLegStatus [0 -- cancelled, 1-- active, 2 -- deleted]
     */
    public fetch_mas_details(
        trip_date: string,
        invoice_number: string | any = '',
        tripLegStatus: number = -1,
        filter_standing_order: boolean = false,
        start_new_session: boolean = true
    ): Promise<any> {
        if (!this.third_party_enabled) return;
        const mas_session_identifier = localStorage.getItem('session_identifier');
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            session_identifier: mas_session_identifier,
            invoice_number,
            trip_date,
        };
        if (tripLegStatus != -1) {
            payload.tripLegStatus = tripLegStatus;
        }
        if (filter_standing_order) {
            payload.filter_standing_order = true;
        }
        console.log(this.brokerStatus);
        payload.ride_broker =
            this.brokerStatus == 'All Brokers'
                ? ''
                : this.brokerStatus == 'SMS-TPT'
                ? 'SentryMS'
                : this.brokerStatus == 'SMS-MP'
                ? 'SentryMS'
                : this.brokerStatus;
        payload.trip_type = this.brokerStatus == 'SMS-TPT' ? 'assigned' : this.brokerStatus == 'SMS-MP' ? 'marketplace' : '';
        return new Promise(async (resolve, reject) => {
            if (start_new_session) {
                payload.session_identifier = await this.utilityService.start_mas_session({ silent: true });
            }
            this.httpService.post(environment.urlWC + 'mas_trip_roster_request', payload).subscribe(
                (res) => {
                    if (res?.message === 'Success.' || res?.message === 'No Results.') {
                        // if (res?.message === 'No Results.') this.utilityService.toast('warning', res?.message);
                        resolve(res);
                    } else if (res?.error === 'Authentication Failed. There is no account linked to the key provided.') {
                        reject(res);
                    } else {
                        reject(res);
                    }
                },
                (err) => {
                    reject(err);
                }
            );
        });
    }

    public check_trip_is_deleted(trip_date: string, invoice_number: string = '') {
        return this.fetch_mas_details(trip_date, invoice_number);
    }

    get_mas_pickup_time(trip: any) {
        if (!trip?.pickupTime) return '';
        return moment(`${trip?.pickupTime.slice(0, 2)}:${trip?.pickupTime.slice(2, 4)}`, 'hh:mm a').format('hh:mm a');
    }

    get_mas_pickup_date_time(trip: any): Date {
        return new Date(moment(trip?.pickupDate + ' ' + this.get_mas_pickup_time(trip)).toString());
    }

    parse_mas_trip_to_live_trip_info(mas_trip: any): ILiveTripInfo {
        return {
            driver_id: mas_trip?.driverId,
            driver_name: null,
            driver_mobile: null,
            rider_name: mas_trip?.rider_name,
            rider_mobile: mas_trip?.user_mobile,
            pickup_datetime: mas_trip?.pickup_datetime,
            pickup_location: mas_trip?.pickup_location_address,
            dropoff_location: mas_trip?.dropoff_location_address,
            pickup_latitude: mas_trip?.pickupAddress?.lat,
            pickup_longitude: mas_trip?.pickupAddress?.lon,
            dropoff_latitude: mas_trip?.dropoffAddress?.lat,
            dropoff_longitude: mas_trip?.dropoffAddress?.lon,
        };
    }

    parse_mas_assigned_or_queued_to_live_trip_info(mas_trip: any): ILiveTripInfo {
        return {
            driver_id: mas_trip?.sent_to,
            driver_name: mas_trip?.requested_driver_names,
            driver_mobile: mas_trip?.requested_driver_mobile,
            rider_name: mas_trip?.user_name,
            rider_mobile: mas_trip?.user_mobile,
            pickup_datetime: mas_trip?.pickup_datetime,
            pickup_location: mas_trip?.pickup_location,
            dropoff_location: mas_trip?.dropoff_location,
            pickup_latitude: mas_trip?.pickup_latitude,
            pickup_longitude: mas_trip?.pickup_longitude,
            dropoff_latitude: mas_trip?.dropoff_latitude,
            dropoff_longitude: mas_trip?.dropoff_longitude,
            mas_trip_id: mas_trip?.mas_trip_id,
        };
    }

    filter_standing_order(order: boolean, trips: Array<any>): Array<any> {
        return trips?.filter((trip) => {
            if (order) {
                return !(trip?.isStandingOrderTrip === true && moment(trip?.standingOrderEndDate).isBefore(moment()));
            } else {
                return (
                    // trip?.isStandingOrderTrip === false ||
                    trip?.isStandingOrderTrip === true && moment(trip?.standingOrderEndDate).isBefore(moment())
                );
            }
        });
    }

    filter_cancel_order(order: boolean, trips: Array<any>): Array<any> {
        return trips.filter((trip) => {
            return order ? trip?.status?.includes('Cancel') : !trip?.status?.includes('Cancel');
        });
    }

    // Calculate leg cost

    calculate_total_leg_cost(mileage: number, mas_leg_cost: number, secondary_services: any[]) {
        let charges: number = Number(mas_leg_cost);
        let charge_S0215 = 0;
        let is_TN_exist: boolean = false;
        let leg_milege: number = Number(mileage) || 0;
        if (secondary_services) {
            secondary_services.forEach((service: any) => {
                if (service.service_procedure_code === 'A0100' || service.service_procedure_code === 'A0170')
                    charges += Number(service?.service_rate || '0');
                if (service.service_procedure_code === 'S0215') {
                    if (service?.service_procedure_code_modifier === 'TN') {
                        is_TN_exist = true;
                    }
                    charge_S0215 = Number(service?.service_rate);
                }
            });
        }

        if (!is_TN_exist) {
            if (leg_milege > 8) leg_milege -= 8;
            // if leg_milege is more than 8 then subtract 8 fron the number
            else charge_S0215 = 0; // else remove the multiplier
        }
        return charges + leg_milege * charge_S0215;
    }

    async open_leg_cost_breakdown(payload: {
        trips?: any; // If passed then trip_leg_id, date and invoice_number will be ignored
        date?: string | Date | any; // considered if mas_trip is not passed
        invoice_number?: string | number; // considered if mas_trip is not passed
        trip_leg_id?: string | number; // considered if mas_trip is not passed
        toll?: number;
    }) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: payload,
            ...this.utilityService.ngxModalOption,
        };
        this.modalService.show(LegCostBreakdownComponent, initialState);
    }

    async open_mas_trip_info_popup(payload: {
        trips?: any; // If trip is not passed, date and invoice_number must be passed in the parameters
        date?: string | Date | any;
        invoice_number?: string | number;
    }) {
        this.utilityService.loading = true;
        let mas_both_leg_trip = payload;
        if (payload?.trips) {
            mas_both_leg_trip = payload?.trips;
        } else {
            mas_both_leg_trip = await this.fetch_mas_details(moment(payload?.date).format('MM/DD/yyyy'), payload?.invoice_number);
        }

        this.utilityService.loading = false;
        const modalOption: any = {
            id: Date.now(),
            class: 'bg-none modal-dialog-centered modal-dialog-large',
            initialState: {
                trip: mas_both_leg_trip,
            },
        };
        this.modalService.show(MasTripInfoComponent, modalOption);
        this.utilityService.loading = false;
    }

    delete_mas_trip(trip_leg_id: string) {
        return this.httpService.post(environment?.urlWC + 'delete_mas_trip', {
            trip_leg_id,
            web_access_token: this.cookieService.get('web_access_token'),
        });
    }
}
