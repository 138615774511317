import { Component, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';

@Component({
    selector: 'app-fare-breakdown',
    templateUrl: './fare-breakdown.component.html',
    styleUrls: ['./fare-breakdown.component.scss'],
})
export class FareBreakdownComponent implements OnInit {
    constructor(public bsModalRef: BsModalRef) {}

    active_fare_breakdown_flag: 'now' | 'later';
    fare_breakdown: any;

    public event: EventEmitter<any> = new EventEmitter();

    ngOnInit(): void {}

    triggerEvent(type: 'book-ride' | 'cancel') {
        this.bsModalRef.hide();
        this.event.emit({ type: type });
    }
}
