<div class="bg-wrap">
    <footer class="footer">
        <div class="container">
            <div class="row">
                <div class="col-12">
                    <div class="footer__line"></div>
                </div>

                <div class="col-6 col-md-4 col-lg-3">
                    <a href="index.html" class="footer__logo">
                        <object type="image/svg+xml" data="assets/img/logo--white.svg"></object>
                    </a>

                    <span class="footer__title">Address</span>

                    <p class="footer__text">
                        1969 Steinway Street,
                        <br />
                        Long Island City, NY 11105
                        <br />
                        <a href="tel:7183605010">(718)-360-5010</a>
                        <br />
                        <a href="mailto:support@drivequdos.com">support@driveQudos.com</a>
                    </p>
                </div>

                <div class="col-6 col-md-4 col-lg-2">
                    <span class="footer__title">Company</span>

                    <ul class="footer__list">
                        <li><a href="#">About Us</a></li>
                        <!--<li><a href="#">Press</a></li>
                                <li><a href="#">Career</a></li>-->
                    </ul>
                </div>

                <div class="col-6 col-md-4 col-lg-2">
                    <span class="footer__title">Support</span>

                    <ul class="footer__list">
                        <li><a href="mailto:support@drivequdos.com">Help</a></li>
                        <li><a href="privacy/terms.html">Terms & Conditions</a></li>
                        <li><a href="privacy/privacy.html">Private Policy</a></li>
                        <li><a href="mailto:support@drivequdos.com">Contact Us</a></li>
                    </ul>
                </div>

                <div class="col-6 col-md-4 col-lg-2">
                    <span class="footer__title">Ride</span>

                    <ul class="footer__list">
                        <li><a href="http://qudos.tech/driverlogin.html">Drive</a></li>
                        <li><a href="http://qudos.tech/riderlogin.html">Ride</a></li>
                        <!--<li><a href="#">Cities</a></li>-->
                    </ul>
                </div>

                <div class="col-12 col-md-6 col-lg-3">
                    <span class="footer__title footer__title--connect">Connect with Us</span>

                    <ul class="footer__social clearfix">
                        <li>
                            <a href="https://www.facebook.com/QudosRide/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="14px"
                                    height="29px"
                                >
                                    <image
                                        x="0px"
                                        y="0px"
                                        width="14px"
                                        height="29px"
                                        xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA4AAAAdCAQAAAAwA5VvAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiAhgAJRsaoyUyAAAAx0lEQVQoz+WQPQ/BQByHn7teKwQrkRhMJoPBYrXYGH1Pm9UoMVl8BgnxEqW0NejVH60v4Jl+d0/u/i8qRlClRZEIAM3GCNVjQEWc1y/ZZcw7V50EjxGfKCvbuF/S2G8b4vLIjC0eeyvluzlLkoafREKebbDSFzJIi1KiTkBNyCZ7DA4nFXeYkM1KE5PHTr+18iVNrjwZfA7cKFMQo/g4wFbFGpc7Q/qpnLLAAQJDxFXOBlwICeUSZOV0lZof/Jt0sh7YoIRM8wNvICsbyCIf6AAAAABJRU5ErkJggg=="
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://twitter.com/ridequdos">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="32px"
                                    height="26px"
                                >
                                    <image
                                        x="0px"
                                        y="0px"
                                        width="32px"
                                        height="26px"
                                        xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAACAAAAAaCAQAAAB8kpa0AAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiAhgAJTJYEb1eAAAB1ElEQVQ4y5XUzUtUURzG8c8droqVZm+GyUCUokFEVGi4iKBdtqhVi7Yt2/h/tG1TiyAQCiJq0aJaZRi4isCRLIIJGqFkfJnBYkbTFjN3xnm5w/jb3HOee74P9zz3/E6wo60aNuKwbWkLVipqj3ywA52KLeDApHGJ8izrjRROuGrGz5LBPZ/MxuJ3nazT5vQ6I+0RIfoNGLBqoanB9QaccXzx1LDlBPrBHRNN8N6mKkUbppxVTGC7LE66ra9u4WjMxjpctOKlPyF+VeRzRn0wZ6OiHIlN5r23lDLIyhgsv+h0zYSUBUvyyMUY/CvhlP7CUVMNSwoy1vQ5FZPB/dJ3hjgu74UbOmuWdMWgkX355IRIuumzQp1B68rZrBosCpzfAwzL0SCBvPk94vzYbcDrlr3QrL7VGuQ8lN0DnrYaDYNKOx9wyWU9bRlMV/smLD+73LIlaAuvabvIoCBnrM0NvNo9SeySF9vC56sB1hrwxGylM+Nq3fNaIai7E/c77YqBGHzTg+oRqs0gqg0d9sXgfz2uxwmFtsrjQaMuNFwpUWU8a3ZWQt3GJHXrcqzF3me8a55PKC/loKHYM1CU8tFSnHMUYsKIIUmHKgkUrPntu6/WW3yZ/4F0dWRyQis2AAAAAElFTkSuQmCC"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.linkedin.com/company/ridequdos/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="31px"
                                    height="31px"
                                >
                                    <image
                                        x="0px"
                                        y="0px"
                                        width="31px"
                                        height="31px"
                                        xlink:href="assets/img/linkedin.png"
                                    />
                                </svg>
                            </a>
                        </li>
                        <li>
                            <a href="https://www.instagram.com/qudosride/">
                                <svg
                                    xmlns="http://www.w3.org/2000/svg"
                                    xmlns:xlink="http://www.w3.org/1999/xlink"
                                    width="31px"
                                    height="31px"
                                >
                                    <image
                                        x="0px"
                                        y="0px"
                                        width="31px"
                                        height="31px"
                                        xlink:href="assets/img/instagram.png"
                                    />
                                </svg>
                            </a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>

        <div class="footer__copyright">
            <div class="container">
                <div class="row">
                    <div class="col-12">
                        <small class="footer__small">
                            <svg
                                xmlns="http://www.w3.org/2000/svg"
                                xmlns:xlink="http://www.w3.org/1999/xlink"
                                width="18px"
                                height="18px"
                            >
                                <image
                                    x="0px"
                                    y="0px"
                                    width="18px"
                                    height="18px"
                                    xlink:href="data:img/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAQAAAD8x0bcAAAABGdBTUEAALGPC/xhBQAAACBjSFJNAAB6JgAAgIQAAPoAAACA6AAAdTAAAOpgAAA6mAAAF3CculE8AAAAAmJLR0QA/4ePzL8AAAAHdElNRQfiAhgBASUr6LMIAAABYUlEQVQoz43SsWsTARQG8F/uLkOV5iQqxSVNMhRcaq1REIcOYl0UXBw6KEVcnPxnBAcRBQehCILgkm4dXISYuAQL5hp1EGpLL6CW2hCH3kVBCn7T433few/e9xW6xqiZMy1Gqq8tyYlCJjrphqqferZwXN2Evpc2/4hqbtm3qmWYDYfmXRF5JjkQnXBP6qkBplSMfPEVk24re+hboctdUx5IHXHTTLap64VdR9236VGgpqYpVXTHjA9WrFh32rLQd6uqapEzdrVwySlvvAbvXReKDLVcNRep+miIc35oZsdGXmXVUM90ILaNorINv/yLbXGQ/wsjhyCQKmPPjopg3C+6qATK0kBfXYiOSQtj0YJrLiBU14+0Ncx7a82sy47p4LxZW9Zw1oR2JJFYtC712JKGBvjsuT2xRRuS3JaBJwYK6ir4pGekZFmc23Jg8FDTO/uHGfyfUfk7dCUM9HX0cuI3sK1zjecnoy0AAAAASUVORK5CYII="
                                />
                            </svg>
                            Copyrighted 2020
                        </small>
                        <span class="footer__rights">All Rights Reserved</span>
                        <a href="index.html" class="footer__link">www.rideQudos.com</a>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</div>
