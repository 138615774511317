import { Component, EventEmitter, OnInit, ViewChild } from '@angular/core';
import { StripeCardElementOptions, StripeElementsOptions } from '@stripe/stripe-js';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { StripeCardComponent, StripeService } from 'ngx-stripe';
import { StripeCardOption, StripeElementOption } from 'src/app/core/services/utility/stripe-element.config';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { ValidationService } from 'src/app/core/services/validation/validation.service';
import { CardsService } from '../../services/cards/cards.service';
import { UserService } from '../../services/user/user.service';

@Component({
    selector: 'app-add-card',
    templateUrl: './add-card.component.html',
    styleUrls: ['./add-card.component.scss'],
})
export class AddCardComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private validation: ValidationService,
        private utilityService: UtilityService,
        private stripeService: StripeService,
        private cardsService: CardsService,
        private userService: UserService
    ) {}

    cardType: 'corporate' | 'rider' = 'corporate';
    user_id: string;
    user_email: string;
    show_email_input: boolean = false;

    hard_check_for_email: boolean = false;

    @ViewChild(StripeCardComponent) stripeCard: StripeCardComponent;
    cardError: string = '';
    show_email_error: boolean = false;

    public event: EventEmitter<any> = new EventEmitter();

    cardOptions: StripeCardElementOptions = StripeCardOption;
    elementsOptions: StripeElementsOptions = StripeElementOption;

    ngOnInit(): void {
        if (!this.user_email && this.hard_check_for_email) {
            this.show_email_input = true;
        }
    }

    onSaveCard() {
        if (this.hard_check_for_email && this.show_email_input) {
            if (!this.validation.email.test(this.user_email)) {
                this.show_email_error = true;
                return;
            } else {
                this.updateUser();
            }
        } else {
            this.createStripeToken();
        }
    }

    createStripeToken(): void {
        this.utilityService.loading = true;
        this.stripeService.createToken(this.stripeCard.element).subscribe((result) => {
            this.utilityService.loading = false;
            if (result.token) {
                // Use the token
                // console.log(result.token.id);
                // console.log(result.token);
                this.stripeTokenHandler(result.token);
            } else if (result.error) {
                // Error creating the token
                console.log(result.error.message);
                this.cardError = result.error.message;
            }
        });
    }

    onStripeCardChange(event: any) {
        if (event.error) {
            this.cardError = event.error.message;
        } else {
            this.cardError = '';
        }
    }

    clearStripeCard(stripeElement: any) {
        if (stripeElement) stripeElement.element.clear();
    }

    // update user before adding card
    public updateUser() {
        if (this.validation.isEmailValid(this.user_email)) {
            this.utilityService.toast('error', 'Please fill valid email id');
            return;
        }
        this.userService
            .update_registered_user_info({
                user_id: this.user_id,
                user_email: this.user_email,
            })
            .subscribe((data) => {
                if (typeof data == 'string') data = JSON.parse(data);
                else data = data;
                if (data.flag == 101) {
                    this.utilityService.toast('error', data.error);
                    // this.router.navigate(['/', 'corporate_login']);
                } else if (data.error) {
                    if (data.error == 'Please fill all the required fields.') {
                        this.utilityService.toast('error', 'Please fill valid email id');
                    } else {
                        this.utilityService.toast('error', data.error);
                    }
                    return;
                } else {
                    this.utilityService.toast('success', 'User info updated Successfully', '');
                    this.createStripeToken();
                }
            });
    }

    buttonClicked: boolean = false;
    public stripeTokenHandler(token) {
        this.utilityService.loading = true;
        if (this.buttonClicked) {
            this.utilityService.loading = false;
            return false;
        } else {
            this.buttonClicked = true;

            const params: any = {
                nounce: token.id,
                card_type: 52,
                offset: new Date().getTimezoneOffset() * -1,
            };

            if (this.user_id) {
                params.user_id = this.user_id;
            }

            this.cardsService.add_card(params, this.cardType || 'corporate').subscribe(
                (data) => {
                    this.buttonClicked = false;
                    if (data.error) {
                        this.utilityService.loading = false;
                        if (data.flag == 301) {
                            this.cardError = 'Duplicate Card, Please try another';
                        } else {
                            this.utilityService.alert('error', data.error);
                        }
                    } else {
                        this.utilityService.toast('success', data.log, '');
                        this.triggerEvent('success');
                        setTimeout(() => {
                            this.utilityService.loading = false;
                        }, 1100);
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err.message || 'Something went wrong');
                    console.log('err => ', err);
                }
            );
        }
    }

    triggerEvent(status: 'success' | 'fail') {
        this.bsModalRef.hide();
        this.event.emit({ status });
    }
}
