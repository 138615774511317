<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <!-- <SettingHeader
            headerTitle="My Cards"
            [tabList]="utilityService.settingHeaderConfig.settings"
            activeTab="My Cards"
            buttonText="Add Card"
            (buttonClick)="addCard()"
            [searchControl]="searchForm"
        ></SettingHeader> -->
        <SettingHeader [config]="pageHeaderConfig" (onEvent)="onPageHeaderEvent($event)"></SettingHeader>
    </div>

    <!-- Tables
================================================== -->
    <div class="table-container short touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0">
        <div class="page-header"></div>
        <table class="table table-responsive trips">
            <thead>
                <tr class="frow">
                    <th scope="col">ID</th>
                    <th scope="col">Card Type</th>
                    <th scope="col">Card Number</th>
                    <th scope="col">Date Added</th>
                    <th scope="col" class="tcenter">Default</th>
                    <th scope="col">Action</th>
                </tr>
            </thead>
            <tbody *ngIf="isLoading">
                <tr class="no-hover">
                    <td colspan="6" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="Cards.length || 10"></app-loading>
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="Cards?.length > 0 && !isLoading">
                <!-- ng-repeat="doc in Cards | orderBy : '-id' track by $index" -->
                <tr
                    *ngFor="
                        let doc of Cards
                            | paginate
                                : {
                                      itemsPerPage: searchString ? this.AllCards?.length : itemsPerPage,
                                      currentPage: pageNo,
                                      totalItems: this.AllCards?.length
                                  };
                        let i = index
                    "
                >
                    <td>{{ doc.id }}</td>
                    <td style="padding-left: 36px">{{ doc.card_type }}</td>
                    <td style="padding-left: 34px">
                        <ng-container *ngIf="doc.last4_digits">**{{ doc.last4_digits }}</ng-container>
                    </td>
                    <td>{{ doc.card_added_on | date: 'mediumDate' }}</td>
                    <td class="tcenter">
                        <div *ngIf="doc.default_card == 1" class="badge badge-secondary text-white py-1 px-2 default_card">Default</div>
                        <button
                            *ngIf="doc.default_card != 1"
                            [disabled]="onLoad"
                            (click)="setDefaultCard(doc.id)"
                            class="callToAction buttonlink cardDefaultLabel cursor-pointer"
                        >
                            Make Default
                        </button>
                    </td>

                    <td class="control_icons">
                        <!-- <a class="callToAction"> <span class="icon icon-Edit"></span> </a> -->
                        <button
                            class="callToAction"
                            (click)="confirmRemoveCard(doc)"
                            [disabled]="doc.default_card"
                            [class.disable-icon]="doc.default_card"
                        >
                            <span class="icon icon-Delete" [class.highlight]="!doc.default_card"></span>
                        </button>
                        <!-- <a class="callToAction"> <span class="icon icon-more_vert"></span></a> -->
                    </td>
                </tr>
            </tbody>
            <tbody *ngIf="Cards.length === 0 && !isLoading">
                <tr class="no-hover">
                    <td colspan="6" class="text-center">No Card Found</td>
                </tr>
            </tbody>
        </table>
    </div>
</div>
<div class="custom_pagination_v2">
    <div class="d-flex flex-row-reverse align-items-center">
        <ng-container *ngIf="!isLoading">
            <div *ngIf="showPagination">
                <pagination-controls responsive="true" (pageChange)="getPage($event)"></pagination-controls>
            </div>

            <div class="p-2 bd-highlight highlight ml-3 dropdown cursor-pointer">
                <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                    <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                    <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
                </div>
                <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                    <div
                        *ngFor="let item of utilityService.display_filter_list"
                        class="dropdown-item text-center cd-item text-spacegrey"
                        (click)="getPage(1, item)"
                    >
                        {{ item }}
                    </div>
                </div>
            </div>

            <div class="text-spacegrey fs-13 fw-600">
                <!-- {{ pageNo * itemsPerPage >= totalItems ? totalItems : pageNo * itemsPerPage }} of {{ totalItems }} -->
                Results:
                <span class="text-blue-secondary">{{ this.AllCards?.length }}</span>
            </div>
        </ng-container>

        <ng-container *ngIf="isLoading">
            <app-loading type="placeholder" count="1" width="430px" height="24px"></app-loading>
        </ng-container>
    </div>
</div>
