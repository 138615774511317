<div class="touch-popup" role="document">
    <div class="modal-content booking-model-component card-shadow">
        <div class="touch-header pt-2">
            <div class="mx-5 my-4">
                <div class="medium-text dark-color">{{ title }}</div>
            </div>
        </div>

        <div class="modal-body p-0">
            <hr class="light" />
            <div class="message p-5">
                <div class="panel-body">
                    <div class="d-flex flex-column flex-md-row m-auto touch-input-group justify-content-center">
                        <div class="flex-fill px-2 mb-0 date-picker" *ngIf="viewMode === 'full' || viewMode === 'date'">
                            <div class="d-flex justify-content-between schedule-date" (click)="scheduleDate.open()">
                                <div class="text text-space-grey-light date-picker">{{ date | date: 'y-MM-dd (EEEE)' }}</div>
                                <div class="cal-icon no-select">
                                    <img src="assets/images/calender_icon.svg" alt="" />
                                </div>
                            </div>
                            <input
                                [(ngModel)]="date"
                                readonly
                                [matDatepicker]="scheduleDate"
                                class="invisible mat-date-input text-space-grey-light"
                                [min]="minDate"
                                [max]="maxDate"
                            />
                            <mat-datepicker #scheduleDate></mat-datepicker>
                        </div>

                        <div class="px-2 mb-0 align-self-center mb-3 mb-md-0" style="min-width: 110px" *ngIf="viewMode === 'willcall+time'">
                            <div class="custom-control custom-switch">
                                <input type="checkbox" class="custom-control-input" id="willCallSwitch" [(ngModel)]="willCall" />
                                <label class="custom-control-label fs-14 fw-600 dark-color" for="willCallSwitch">Will Call</label>
                            </div>
                        </div>

                        <div class="px-2 input-group mb-3 mb-md-0">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="hour_selection" style="padding-top: 6px">
                                    <mat-icon class="fs-20 text-dark-blue">schedule</mat-icon>
                                </label>
                            </div>
                            <select class="input-append text-space-grey-light" id="hour_selection" [(ngModel)]="hour">
                                <option *ngFor="let option of hoursList" [value]="option.value">
                                    {{ option.title }}
                                </option>
                            </select>
                        </div>

                        <div class="px-2 input-group">
                            <div class="input-group-prepend">
                                <label class="input-group-text" for="minutes_selection" style="padding-top: 6px">
                                    <mat-icon class="fs-20 text-dark-blue">schedule</mat-icon>
                                </label>
                            </div>
                            <select class="input-append text-space-grey-light" id="minutes_selection" [(ngModel)]="minute">
                                <option [value]="m" *ngFor="let m of minutesList">
                                    <span *ngIf="m < 10">0{{ m }} mins</span>
                                    <span *ngIf="m >= 10">{{ m }} mins</span>
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
            </div>
            <hr class="light" />

            <div class="touch-bottom my-3 mx-3 mx-md-0" align="center">
                <button class="btn btn-secondary btn-light text-white p-2 mr-2 br-6" (click)="bsModalRef.hide()">{{ leftButtonText }}</button>
                <button class="btn btn-primary btn-right-secondary p-2 ml-2 br-6" (click)="onConfirm()">{{ rightButtonText }}</button>
            </div>
        </div>
    </div>
</div>
