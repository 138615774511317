export interface MasNote {
    notes: string;
    notes_id?: number;
}

export interface IRiderNote {
    notes_id?: number;
    title: string;
    priority: number | string;
    description?: string;
    qudos_user_id?: number;
}

// Priority Maps
export const NotePriority = {
    Normal: 1,
    Medium: 2,
    High: 3,
};

export const PriorityMap = {
    1: 'Normal',
    2: 'Medium',
    3: 'High',
};

export const PriorityList = ['Normal', 'Medium', 'High'];

export interface IAdvanceNote {
    qudos_user_id: number;
    qudos_user_type: number;
    other_notes: number;
    other_notes_description: string;
    visually_impaired: number;
    hearing_impaired: number;
    frequent_flyer: number;
}
