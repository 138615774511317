import { environment } from 'src/environments/environment';
import { UtilityService } from './../../core/services/utility/utility.service';
import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { SearchCountryField, TooltipLabel, CountryISO } from 'ngx-intl-tel-input-r';
import { HttpService } from '../../services/http/http.service';
import { Router } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { LocationStrategy } from '@angular/common';
import { country_code } from '../../corporate/pages/live-tracking/country-list';

declare const jQuery: any;
const $: any = jQuery;

@Component({
    selector: 'app-login',
    templateUrl: './login.component.html',
    styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
    error: string = '';

    forgot = { mobile: '' };
    otp: string = '';

    activeMode: 'login' | 'forgot' | 'verify-otp' | 'reset-password' | 'reset-success-message' = 'login';

    promoCountryCode: string = '';
    hitInProgress: number = 0;
    loginDriver: any = {
        mobile: '',
        password: '',
    };

    success: boolean = false;
    // onloadDisble: boolean = false;

    isDisable: boolean = false;
    loadings: boolean = false;
    DisableOtp: boolean = false;
    DisableRegenrate: boolean = false;
    DisableVerifyOtp: boolean = false;

    resetPass: any = {};

    separateDialCode = false;
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];

    phoneNumber: FormControl = new FormControl(undefined, [Validators.required]);
    forgotMobileNumber: FormControl = new FormControl(undefined, [Validators.required]);

    staffLoginForm: FormGroup = new FormGroup({
        staff_mobile: new FormControl('', [Validators.required]),
        mobile: new FormControl('', [Validators.required]),
        password: new FormControl('', [Validators.required]),
    });

    is_approved: any;
    errorMsg = '';

    // reference of the last toast for [ confirm password error ]
    lastToastRef: any;

    selectedCountryISO: any = CountryISO.UnitedStates;
    staffSelectedCountryISO: any = CountryISO.UnitedStates;

    constructor(
        private router: Router,
        public utilityService: UtilityService,
        private httpService: HttpService,
        private cookieService: CookieService,
        private location: LocationStrategy
    ) {
        localStorage.removeItem('book-ride-for');
        history.pushState(null, null, window.location.href);
        this.location.onPopState(() => {
            // history.pushState(null, null, window.location.href);
            if (this.activeMode != 'login') {
                this.switchToLogin();
            }
        });
    }

    ngOnInit(): void {
        // this.utilityService.showModal('staff_login');
        // this.activeMode = 'forgot';
        window.onbeforeunload = this.WindowCloseHanlder;

        // guard emit authorized false, then close loading
        this.utilityService.authorized.subscribe((flag) => {
            if (!flag) {
                console.log('Received false flag from auth guard');
                this.loadings = false;
                if (this.cookieService.get('loginType') !== 'staff') this.loginDriverFn(false);
            }
        });
        localStorage.removeItem('selectedCorp');
        this.fetchSessionCredentials();
    }

    private fetchSessionCredentials() {
        const login_session = JSON.parse(sessionStorage.getItem('login_credentials') || '{}');
        if (!login_session) return;
        const mobile = login_session?.mobile;
        const password = login_session?.password;
        const staff_mobile = login_session?.staff_mobile;
        if (mobile && password) {
            this.selectedCountryISO = country_code[mobile.split('-')[0]]?.toLowerCase();

            if (staff_mobile) {
                this.staffLoginForm.patchValue({
                    staff_mobile: staff_mobile.split('-')[1],
                    mobile: mobile.split('-')[1],
                    password,
                });
                this.staffSelectedCountryISO = country_code[staff_mobile.split('-')[0]?.toLowerCase()];
            } else {
                this.phoneNumber.patchValue(mobile.split('-')[1]);
                this.loginDriver.password = password;
            }
        }
    }

    public WindowCloseHanlder() {
        if (!localStorage.getItem('web_access_token')) {
            window.location.href = 'http://qudos.tech/affiliatelogin.html';
        }
    }

    public isNumberKeys(evt): boolean {
        const charCode = evt.which ? evt.which : evt.keyCode;
        if (charCode < 48 || charCode > 57) return false;
        return true;
    }

    public switchToLogin() {
        this.activeMode = 'login';
        this.DisableVerifyOtp = false;
        this.otp = '';
        this.forgotMobileNumber.reset();
    }

    public startAgain() {
        location.reload();
    }

    public forgotStart() {
        this.error = '';
        this.activeMode = 'forgot';

        const phoneNumber = this.phoneNumber.value;
        if (phoneNumber) {
            this.selectedCountryISO = country_code[phoneNumber.dialCode]?.toLowerCase();
            this.forgotMobileNumber.patchValue(phoneNumber.number);
        }

        $('.grid_before').removeClass('flipInY');
        $('.gridBlock').addClass('flipInY');
    }

    public changeNum() {
        this.activeMode = 'forgot';
        this.otp = '';
    }

    public showVerify() {
        this.success = true;
        this.activeMode = 'verify-otp';
    }

    public loginDriverFn(shouldDisable: boolean = true) {
        this.error = '';

        if (this.phoneNumber.invalid) {
            // return this.utilityService.toast('error', 'Please enter valid number.');
            return this.utilityService.alert('error', 'Please enter Mobile number.');
        } else {
            this.loginDriver.mobile = this.phoneNumber.value.number;
            this.promoCountryCode = this.phoneNumber.value.dialCode;
        }

        if (!this.loginDriver.mobile) {
            return this.utilityService.alert('error', 'Please enter Mobile number.');
        } else if (!this.loginDriver.password) {
            return this.utilityService.alert('error', 'Please enter Password.');
        } else if (this.loginDriver.password.length < 6) {
            return this.utilityService.alert('info', 'Password should be of at least 6 letters. Please enter the valid password');
        } else if (this.loginDriver.mobile && this.loginDriver.password) {
            this.loadings = true;
            this.isDisable = shouldDisable;
            const data = {
                mobile: this.promoCountryCode + '-' + this.loginDriver.mobile,
                password: this.loginDriver.password,
                web_access_token: 0,
                access_token_login_flag: 0,
            };
            this.perform_login(data);
        } else {
            this.utilityService.alert('error', 'Invalid Mobile number or Password.');
        }
    }

    perform_login(parameter: any) {
        const url_login = environment.authURL + 'login';
        this.httpService.post(url_login, parameter).subscribe(
            (data) => {
                if (typeof data == 'string') data = JSON.parse(data);

                if (data.error) {
                    this.error = data.error;

                    this.utilityService.toast('error', data.error, '');
                    this.loadings = false;

                    this.isDisable = false;
                } else if (data.login.web_access_token) {
                    this.isDisable = false;
                    const web_access_token = data.login.web_access_token;

                    const params = {
                        web_access_token: web_access_token,
                    };

                    const url_login_token = environment.urlC + 'get_info';
                    this.httpService.post(url_login_token, params).subscribe(
                        (resp) => {
                            if (typeof resp == 'string') resp = JSON.parse(resp);

                            if (resp.error) {
                                this.error = resp.error;
                                this.utilityService.toast('error', resp.error, '');
                                this.loadings = false;

                                setTimeout(() => {
                                    this.isDisable = false;
                                }, 1500);

                                // this.$digest();
                            } else {
                                const corpInfo = resp.corpInfo;
                                const clientInfo = resp?.clientInfo;
                                // this.loadings = false;
                                this.isDisable = false;
                                const driverModel = {
                                    first_name: corpInfo.first_name,
                                    driver_name: corpInfo.first_name + ' ' + corpInfo.last_name,
                                    driver_image: corpInfo.image,
                                    driver_mobile: corpInfo.mobile,
                                    driver_email: corpInfo.email,
                                    referral_code: corpInfo.referral_code,
                                    zipcode: corpInfo.zipcode,
                                    state_id: corpInfo.state_id,
                                    corporate_id: corpInfo.corporate_id,
                                    subtype_id: corpInfo.subtype_id,
                                    location: corpInfo.city,
                                    state: corpInfo.state,
                                    city: corpInfo.city,
                                    address: corpInfo.address,
                                    latitude: corpInfo.latitude,
                                    longitude: corpInfo.longitude,
                                    is_mas_dispatcher: corpInfo.is_mas_dispatcher || 0,
                                    corp_type: corpInfo.corp_type,
                                };

                                if (!driverModel.driver_image) {
                                    driverModel['driver_image_approved'] = 'images/default.png';
                                    driverModel.driver_image = 'images/default.png';
                                }

                                if (corpInfo.corp_type == 1) {
                                    this.cookieService.set('super_corp_web_access_token', web_access_token);
                                }

                                this.cookieService.delete('cached_filter_driver');
                                this.cookieService.set('web_access_token', web_access_token);
                                this.cookieService.set('access_token', web_access_token);

                                sessionStorage.setItem('login_credentials', JSON.stringify(parameter));

                                localStorage.setItem('corporateModel', JSON.stringify(driverModel));
                                this.cookieService.set('driverdata', resp);

                                localStorage.setItem('driverdata', JSON.stringify(resp));
                                localStorage.setItem('justLogin', '1');

                                localStorage.setItem('clientInfo', JSON.stringify(clientInfo));

                                this.router.navigate(['/', 'corporate', 'live-tracking']);
                                this.loadings = false;
                            }
                        },
                        (err) => {
                            this.loadings = false;
                        }
                    );
                } else {
                    this.loadings = false;
                    this.utilityService.alert('error', 'No Token Received');
                }
            },
            (err) => {
                this.utilityService.alert('error', err.message);
                console.log('ERROR ==> ', err);
                this.loadings = false;
            }
        );
    }

    public verifyOTP() {
        this.DisableVerifyOtp = true;
        this.errorMsg = '';

        this.loadings = true;

        if (!this.otp) {
            this.utilityService.alert('info', 'Please enter OTP');
            setTimeout(() => {
                this.DisableVerifyOtp = false;
                this.loadings = false;
            }, 500);
        } else {
            this.httpService
                .post(environment.urlC + 'verify_otp', {
                    mobile: this.promoCountryCode + this.forgot.mobile,
                    otp: this.otp,
                })
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    if (data.error) {
                        this.utilityService.toast('error', data.error, '');

                        setTimeout(() => {
                            this.DisableVerifyOtp = false;
                            this.loadings = false;
                        }, 400);
                    } else {
                        this.utilityService.toast('success', 'OTP verified successfully.', '');
                        this.activeMode = 'reset-password';
                        this.loadings = false;
                        this.resetPass = {};
                    }
                });
        }
    }

    public requestOTP() {
        if (!this.forgotMobileNumber.value || !this.forgotMobileNumber.value.number) {
            this.utilityService.toast('error', 'Please Enter Mobile Number');
            return;
        }

        const mobile = this.forgotMobileNumber.value.number;

        if (mobile.trim() == '') {
            this.utilityService.toast('error', 'Please Enter Mobile Number');
            return;
        }
        this.DisableOtp = true;
        this.loadings = true;
        this.promoCountryCode = this.forgotMobileNumber.value.dialCode + '-';

        this.forgot.mobile = mobile;
        if (this.forgot.mobile.indexOf('+') >= 0) {
            //$scope.promoCountryCode = '';
            this.forgot.mobile = this.forgot.mobile.replace(this.forgotMobileNumber.value.dialCode + '-', '');
            this.forgot.mobile = this.forgot.mobile.replace(this.forgotMobileNumber.value.dialCode, '');
        }

        const data = {
            mobile: this.promoCountryCode + this.forgot.mobile,
        };

        this.error = '';
        let stop: any;
        this.httpService.post(environment.urlC + 'mobile_forgot_password', data).subscribe(
            (data) => {
                if (typeof data == 'string') data = JSON.parse(data);

                if (data.error) {
                    if (data.error == 'This email or phone number is not registered with us.') {
                        this.utilityService.toast('error', 'This phone number is not registered with us.', '');
                    } else {
                        this.utilityService.toast('error', data.error, '');
                    }

                    this.DisableOtp = false;

                    this.loadings = false;
                } else {
                    this.DisableOtp = false;
                    this.loadings = false;

                    this.error = '';

                    this.success = true;
                    this.activeMode = 'verify-otp';

                    this.utilityService.toast('success', data.log, '');
                }
            },
            (error) => {
                this.DisableOtp = false;
                this.loadings = false;
            }
        );
    }

    public regenerateOTP() {
        this.errorMsg = '';
        // this.DisableRegenrate = true;
        this.httpService
            .post(environment.urlC + 'resend_otp', {
                mobile: this.promoCountryCode + this.forgot.mobile,
                is_approved: this.is_approved,
            })
            .subscribe(
                (data) => {
                    if (data.flag == 0) {
                        this.errorMsg = data.message;

                        setTimeout(function () {
                            this.errorMsg = '';
                        }, 2500);

                        setTimeout(function () {
                            this.DisableRegenrate = false;
                        }, 2500);

                        this.utilityService.toast('error', this.errorMsg);
                    } else {
                        this.utilityService.toast('success', 'An OTP has been sent on your mobile number', '');

                        setTimeout(function () {
                            this.DisableRegenrate = false;
                        }, 2500);
                    }
                },
                (error) => {}
            );
    }

    public setPassword() {
        this.errorMsg = '';
        this.utilityService.clearToast();

        if (!this.resetPass.password) {
            this.utilityService.alert('info', 'Please enter New password');
            return;
        } else if (this.resetPass.password.length < 6) {
            this.utilityService.alert('info', 'Password should be of atleast 6 characters');
            return;
        } else if (!this.resetPass.confirmpassword) {
            this.utilityService.alert('info', 'please enter Confirm password');
            return;
        } else if (this.resetPass.password != this.resetPass.confirmpassword) {
            if (this.lastToastRef?.toastId) {
                this.utilityService.removeToast(this.lastToastRef?.toastId);
            }
            this.lastToastRef = this.utilityService.toast('error', "New and Confirm password doesn't match.", '');
        } else {
            // this.onloadDisble = true;
            this.httpService
                .post(environment.urlC + 'reset_password_mobile', {
                    mobile: this.promoCountryCode + this.forgot.mobile,
                    password: this.resetPass.password,
                    otp: this.otp,
                    is_approved: 0,
                })
                .subscribe((data) => {
                    // data = JSON.parse(data);
                    const takeInside = {
                        mobile: this.forgot.mobile,
                        password: this.resetPass.password,
                        is_approved: parseInt(this.is_approved),
                    };
                    localStorage.setItem('takeInside', JSON.stringify(takeInside));

                    if (data.error && data.flag == 130) {
                        this.utilityService.toast('error', 'old and new password must be different', '');
                    } else if (data.error) {
                        this.utilityService.toast('error', data.error, '');
                    } else if (data.flag != 117) {
                        this.utilityService.toast('error', 'Something went wrong, Please try again later.', '');
                        this.resetPass = {};
                        setTimeout(() => {
                            this.errorMsg = '';
                        }, 3000);
                    } else if (data.flag == 117) {
                        this.activeMode = 'reset-success-message';
                    } else {
                        this.errorMsg = data.message.toString();
                        setTimeout(() => {
                            this.errorMsg = '';
                        }, 3000);
                    }
                });
        }
    }

    // Staff Login

    staffLogin() {
        if (this.staffLoginForm.invalid) return;

        this.loadings = true;
        const staff: {
            staff_mobile: {
                dialCode: string;
                number: string;
            };
            mobile: {
                dialCode: string;
                number: string;
            };
            password: string;
        } = this.staffLoginForm.value;

        const params = {
            staff_mobile: `${staff.staff_mobile.dialCode}-${staff.staff_mobile.number}`,
            mobile: `${staff.mobile.dialCode}-${staff.mobile.number}`,
            password: staff.password,
        };
        this.perform_login(params);
    }
}
