import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { NotesService } from 'src/app/corporate/services/notes/notes.service';
import { IRiderNote, NotePriority, PriorityMap } from 'src/app/models/notes.model';
import { AddNoteModalComponent } from '../../add-note-modal/add-note-modal.component';

/**
 * NOTE
 * currently this component is being used at driver section as well in order reuse the same codebase.
 * TODO: refactor it once we refactor the folder structure of components section alogn with ShareModule integration.
 */

@Component({
    selector: 'app-rider-details-notes-list',
    templateUrl: './rider-details-notes-list.component.html',
    styleUrls: ['./rider-details-notes-list.component.scss'],
})
export class RiderDetailsNotesListComponent implements OnInit, OnDestroy {
    @Input() user_id: number | string;
    @Input() type: 'rider' | 'driver' = 'rider';
    rider_notes: IRiderNote[] = [];
    isLoading: boolean = false;
    priorityMap = PriorityMap;
    private subscriptionList$: Subscription = new Subscription();

    constructor(private utilityService: UtilityService, private modalService: BsModalService, private notesService: NotesService) {}

    ngOnInit(): void {
        this.get_rider_notes();
        this.subscriptionList$.add(
            this.notesService.triggerCreateRiderDriverNote.subscribe(() => {
                this.add_or_update_note();
            })
        );
    }

    // Rider Notes

    add_or_update_note(mode: 'add' | 'edit' = 'add', current_note?: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode,
                current_note,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalRef: BsModalRef = this.modalService.show(AddNoteModalComponent, initialState);
        this.subscriptionList$.add(
            modalRef.content.event.subscribe((note: { title: string; description: string; priority: string }) => {
                this.utilityService.loading = true;
                if (mode === 'add') {
                    this.subscriptionList$.add(
                        this.notesService
                            .add_user_notes({
                                user_id: this.user_id,
                                title: note?.title,
                                priority: NotePriority[note?.priority],
                                desc: note?.description,
                                qudos_user_type: this.type === 'driver' ? 3 : 1,
                            })
                            .subscribe(
                                (res: { flag: number; log: string; error?: string }) => {
                                    if (res?.flag === 2203) {
                                        this.utilityService.toast('success', 'Note added successfully');
                                        this.get_rider_notes();
                                        modalRef.hide();
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                } else if (mode === 'edit') {
                    this.subscriptionList$.add(
                        this.notesService
                            .update_user_notes({
                                ...note,
                                qudos_user_id: current_note.qudos_user_id,
                                notes_id: current_note.notes_id,
                                desc: note?.description,
                                priority: NotePriority[note?.priority],
                            })
                            .subscribe((res: { flag: number; log: string; error?: string }) => {
                                // console.log("res => ", res);
                                if (res?.flag === 2203) {
                                    this.utilityService.toast('success', res?.log);
                                    this.get_rider_notes(true);
                                    modalRef.hide();
                                } else {
                                    this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                }
                            })
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                }
            })
        );
    }

    remove_rider_note(note: IRiderNote) {
        this.subscriptionList$.add(
            this.utilityService
                .confirm({
                    heading: 'Remove note',
                    description: `Are you sure you want to remove a note?`,
                    leftButtonText: 'Cancel',
                    rightButtonText: 'Yes',
                })
                .subscribe((event: { action: 'left' | 'right' }) => {
                    if (event?.action === 'right') {
                        this.subscriptionList$.add(
                            this.notesService.remove_user_note(note.notes_id).subscribe((res: { flag: number; log: string; error?: string }) => {
                                if (res?.flag === 2203) {
                                    this.utilityService.toast('success', res?.log);
                                    this.get_rider_notes(true);
                                } else {
                                    this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                }
                            })
                        );
                    }
                })
        );
    }

    get_rider_notes(silent: boolean = false) {
        if (!silent) this.isLoading = true;
        this.subscriptionList$.add(
            this.notesService
                .get_user_notes(this.user_id, this.type === 'driver' ? 3 : 1)
                .subscribe(
                    (res: any | { flag: number; notes: IRiderNote[]; log: string; error?: string }) => {
                        if (res?.flag === 2203) {
                            this.rider_notes = res?.notes;
                        } else {
                            this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                    }
                )
                .add(() => {
                    this.isLoading = false;
                })
        );
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }
}
