import { FormControl, FormGroup } from '@angular/forms';
import { Subject } from 'rxjs';

interface ITabs {
    title: string;
    url?: string;
}

export interface ISettingHeaderConfig {
    headerTitle?: string;
    headerSubTitle?: string;
    showStartSession?: boolean;

    tabs?: {
        activeTab: string;
        items: ITabs[];
        preventRouteAction?: boolean;
    };

    subTabs?: {
        activeSubTab: string;
        items: ITabs[];
        preventRouteAction?: boolean;
    };

    search?: {
        cssClass?: string;
        placeholder: string;
        form?: FormControl;
        type: OptionType.SEARCH;
    };

    moreOptionList?: IOptionItem[];
    bottomOptionList?: IOptionItem[];
}

export interface IOptionItem {
    id: string;
    type?: OptionType; // 'date' | 'dropdown' | 'toggle' | 'button' | 'link' | 'pipe' | 'time';
    form?: FormGroup | FormControl;
    emitEvent?: boolean;
    cssClass?: string;
    text?: string; // for button or dropdown

    value?: any;
    options?: any[]; // for dropdown
    optionType?: 'string' | 'object';
    optionKey?: string; // If options is object
    disabled?: boolean;
}

export enum OptionType {
    LINK_BUTTON = 'LINK_BUTTON',
    BUTTON = 'BUTTON',
    NORMAL_DATE = 'NORMAL_DATE',
    RANGE_DATE = 'RANGE_DATE',
    DROPDOWN = 'DROPDOWN',
    SEARCH = 'SEARCH',
    TOGGLE = 'TOGGLE',
    TIME = 'TIME',
    DIVIDER = 'DIVIDER', // for mobile only
}
