<ng-container *ngIf="view_type === 'list'">
    <div class="m-5 pl-5" *ngIf="isLoading">
        <app-loading></app-loading>
    </div>
    <div class="detail_outer collapse show" id="secondary_contacts" *ngIf="!isLoading">
        <ul>
            <li *ngFor="let driver of preferred_driver_list; let index = index">
                <span>Driver {{ index + 1 }}</span>
                {{ driver?.driver_name }} {{ driver?.driver_mobile | phoneFormat }}
                <span class="text-blue cursor-pointer hover-bold ml-1" style="min-width: 0" (click)="add_or_update_preferred_driver('edit', driver)">
                    Edit
                </span>
                <span class="text-blue cursor-pointer hover-bold ml-2" (click)="delete_preferred_driver(driver)">Delete</span>
            </li>
            <li>
                <span>New Driver</span>
                <span class="text-blue cursor-pointer hover-bold" (click)="add_or_update_preferred_driver('add')">Add</span>
            </li>
        </ul>
    </div>
</ng-container>

<ng-container *ngIf="view_type === 'popover'">
    <img
        *ngIf="isDesktop"
        src="assets/images/preferred_driver_icon.svg"
        alt="preferred_driver"
        [popover]="preferred_driver_popover"
        containerClass="w-350 br-6 preferred_driver"
        #popPreferedDriver="bs-popover"
        (click)="preferred_driver_reference = popPreferedDriver; fetch_popover_info()"
        placement="bottom"
        container="body"
    />

    <img
        *ngIf="!isDesktop"
        src="assets/images/preferred_driver_icon.svg"
        alt="preferred_driver"
        containerClass="w-350 br-6 preferred_driver"
        (click)="openModal(template)"
    />

    <ng-template #preferred_driver_popover>
        <div class="touch-popover">
            <div class="py-3 px-4 fw-600 text-dark-blue fs-16">Preferred Driver</div>
            <hr class="light light-color" />
            <div>
                <div class="drivers touch-scrollbar">
                    <div class="px-4" *ngIf="isLoading">
                        <app-loading *ngFor="let item of [0, 1, 2]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                    </div>
                    <div *ngIf="!isLoading" class="select-menu-item-style">
                        <div
                            *ngFor="let driver of preferred_driver_list"
                            class="d-flex align-items-center py-2 px-4 touch-list-item cursor-pointer dropdown-item"
                            [class.active-item]="selected_driver?.driver_id === driver?.driver_id"
                        >
                            <div class="text-grey flex-fill" (click)="selected_driver = driver">
                                {{ driver?.driver_name }} {{ driver?.driver_mobile | phoneFormat }}
                            </div>
                            <div>
                                <mat-icon (click)="add_or_update_preferred_driver('edit', driver)" class="text-dark-blue va-middle fs-16 text-right">
                                    edit
                                </mat-icon>
                            </div>
                            <div class="ml-2">
                                <img (click)="delete_preferred_driver(driver)" style="height: 13px" src="assets/images/delete.png" />
                            </div>
                        </div>
                        <div *ngIf="!preferred_driver_list?.length" class="d-flex justify-content-center py-2">
                            <div class="text-grey">No driver found</div>
                        </div>
                    </div>
                </div>

                <hr class="light light-color" />
                <!-- Add -->
                <div
                    class="d-flex justify-content-between align-items-center py-2 px-4 touch-list-item cursor-pointer"
                    (click)="add_or_update_preferred_driver('add')"
                >
                    <div class="text-grey">Add</div>
                    <div><mat-icon class="text-dark-blue fs-20 text-right">add</mat-icon></div>
                </div>
            </div>
            <hr class="light light-color" />
            <div class="d-flex justify-content-end text-blue2 fs-13 fw-600 py-3 px-4">
                <div class="cursor-pointer mx-4" (click)="preferred_driver_reference.hide()">Dismiss</div>
                <div
                    [class.disabled]="!selected_driver"
                    class="cursor-pointer mx-4"
                    (click)="onAssign.emit(selected_driver); preferred_driver_reference.hide()"
                >
                    Assign
                </div>
            </div>
        </div>
    </ng-template>

    <ng-template #template>
        <div class="modal-header">
            <div class="py-3 px-4 text-dark-blue fs-16">Preferred Driver</div>
        </div>
        <div class="modal-body py-3 px-4">
            <div class="drivers touch-scrollbar">
                <div class="px-4" *ngIf="isLoading">
                    <app-loading *ngFor="let item of [0, 1, 2]" height="20px" borderRadius="2px" type="placeholder"></app-loading>
                </div>
                <div *ngIf="!isLoading" class="select-menu-item-style">
                    <div
                        *ngFor="let driver of preferred_driver_list"
                        class="d-flex align-items-center py-2 px-4 touch-list-item cursor-pointer dropdown-item"
                        [class.active-item]="selected_driver?.driver_id === driver?.driver_id"
                    >
                        <div class="text-grey flex-fill" (click)="selected_driver = driver">
                            {{ driver?.driver_name }} {{ driver?.driver_mobile | phoneFormat }}
                        </div>
                        <div>
                            <mat-icon (click)="add_or_update_preferred_driver('edit', driver)" class="text-dark-blue va-middle fs-16 text-right">
                                edit
                            </mat-icon>
                        </div>
                        <div class="ml-2">
                            <img (click)="delete_preferred_driver(driver)" style="height: 13px" src="assets/images/delete.png" />
                        </div>
                    </div>
                    <div *ngIf="!preferred_driver_list?.length" class="d-flex justify-content-center py-2">
                        <div class="text-grey">No driver found</div>
                    </div>
                </div>
            </div>
            <hr class="light light-color" />
            <!-- Add -->
            <div
                class="d-flex justify-content-between align-items-center py-2 px-4 touch-list-item cursor-pointer"
                (click)="add_or_update_preferred_driver('add')"
            >
                <div class="text-grey">Add</div>
                <div><mat-icon class="text-dark-blue fs-20 text-right">add</mat-icon></div>
            </div>
        </div>
        <div class="modal-footer d-flex justify-content-between text-blue2 fs-13 fw-600 py-3 px-4">
            <div class="cursor-pointer py-3 px-4 fw-600" (click)="modalRef.hide()">Dismiss</div>
            <div
                class="cursor-pointer py-3 px-4 fw-600"
                [class.disabled]="!selected_driver"
                (click)="onAssign.emit(selected_driver); modalRef.hide()"
            >
                Assign
            </div>
        </div>
    </ng-template>
</ng-container>
