import { Component, EventEmitter, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CountryISO, SearchCountryField, TooltipLabel } from 'ngx-intl-tel-input-r';
import { combineLatest, Observable } from 'rxjs';
import { debounceTime, filter, first } from 'rxjs/operators';
import { Primary_CarsDriverOptions } from 'src/app/core/services/utility/static-data.config';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { country_code } from '../../pages/live-tracking/country-list';
import { GoogleMapService } from '../../services/google-map/google-map.service';
import { MasAssignService } from '../../services/mas-assign/mas-assign.service';
import { NotesService } from '../../services/notes/notes.service';
import { MasQueueService } from '../../services/mas-queue/mas-queue.service';
import { UserService } from '../../services/user/user.service';
import { DateTimePickerComponent } from '../date-time-picker/date-time-picker.component';
import { TripBookingService } from '../../services/trip-booking/trip-booking.service';
import { ISuggestedFare } from 'src/app/models/fare_estimate.model';
import { TripsService } from '../../services/trips/trips.service';
import { FareEstimateRecalculationComponent } from '../fare-estimate-recalculation/fare-estimate-recalculation.component';

declare const google: any;

@Component({
    selector: 'app-queue-trip-modal',
    templateUrl: './queue-trip-modal.component.html',
    styleUrls: ['./queue-trip-modal.component.scss'],
})
export class QueueTripModalComponent implements OnInit {
    constructor(
        public bsModalRef: BsModalRef,
        private utilityService: UtilityService,
        private modalService: BsModalService,
        private masQueueService: MasQueueService,
        private userService: UserService,
        private mapService: GoogleMapService,
        private masNotesService: NotesService,
        private masAssignService: MasAssignService,
        private tripBookingService: TripBookingService,
        private tripsService: TripsService
    ) {}

    public event: EventEmitter<any> = new EventEmitter();

    title: string = 'Queue a trip (New rider)';
    primaryActionText: string = 'Queue The Ride';
    viewType: 'add' | 'edit' = 'add';
    trip_type: 'queued' | 'assigned' = 'queued'; // assigned is only for assigned trip detail page edit operations
    trip?: any;
    isMobilePopup: boolean = false;
    preferredCountries: CountryISO[] = [CountryISO.UnitedStates, CountryISO.UnitedKingdom];
    SearchCountryField = SearchCountryField;
    TooltipLabel = TooltipLabel;
    CountryISO = CountryISO;
    countryToSet: string = 'US';
    separateDialCode = false;
    countries = country_code;
    notes_id: number | string;

    queueRideFormGroup = new FormGroup({
        rider_name: new FormControl(null, [Validators.required]),
        phone_number: new FormControl(null, [Validators.required]),
        car_type: new FormControl(null, [Validators.required]),

        pickup_date_time: new FormControl(null, [Validators.required]),
        pickup_location: new FormControl(null, [Validators.required]),
        pickup_lat: new FormControl(),
        pickup_lang: new FormControl(),

        dropoff_location: new FormControl(null, [Validators.required]),
        dropoff_lat: new FormControl(),
        dropoff_lang: new FormControl(),

        amount: new FormControl(null),
        user_toll: new FormControl(null),
        notes: new FormControl(''),
    });

    pickup_date_time: Date;
    dropoff_location: { location: string; lat: any; lang: any } = { location: '', lat: '', lang: '' };
    pickup_location: { location: string; lat: any; lang: any } = { location: '', lat: '', lang: '' };

    carType: Array<any> = Primary_CarsDriverOptions;
    selected_car_type: any; // = this.carType[0];
    riders_list: Array<any> = [];
    is_rider_popover_open: boolean = false;

    selectedCountryISO: any = CountryISO.UnitedStates;
    paramFromFareEstimate: any;
    promoCountryCode: any = '+1-';

    ngOnInit(): void {
        this._getCarDriverOptions();

        if (this.trip && this.viewType === 'edit') {
            this.queueRideFormGroup.patchValue({
                rider_name: this.trip?.user_name,
                phone_number: this.trip?.user_mobile?.split('-')?.[1],
                car_type: this.trip?.car_type,
                pickup_date_time: this.trip?.pickup_datetime,

                pickup_location: this.trip?.pickup_location,
                pickup_lat: this.trip?.pickup_latitude,
                pickup_lang: this.trip?.pickup_longitude,

                dropoff_location: this.trip?.dropoff_location,
                dropoff_lat: this.trip?.dropoff_latitude,
                dropoff_lang: this.trip?.dropoff_longitude,

                amount: this.trip?.third_party_amount,
                user_toll: this.trip?.user_toll,
                notes: this.trip?.notes,
            });
            this.selectedCountryISO = country_code[this.trip?.user_mobile?.split('-')?.[0]]?.toLowerCase();

            if (this.trip?.notes_id) {
                this.notes_id = this.trip?.notes_id;
            }

            this.select_this_car_type(this.carType.find((car) => car?.car_type === this.trip?.car_type));
        }

        this.queueRideFormGroup
            .get('rider_name')
            .valueChanges.pipe(debounceTime(500))
            .subscribe((value) => {
                this.get_rider_data(value?.trim() || '');
            });

        this.queueRideFormGroup
            .get('phone_number')
            .valueChanges.pipe(
                debounceTime(500),
                filter((value) => value?.number?.length > 9)
            )
            .subscribe((value) => {
                this.get_rider_data(`${value?.dialCode}-${value?.number}`, true);
            });

        this.get_rider_data();
    }

    ngAfterViewInit() {
        this.locationPicker();
    }

    private _getCarDriverOptions() {
        this.tripsService.get_car_type().subscribe((res) => {
            if (res?.data) {
                this.carType = res?.data;
            }
        });
    }

    closePopup() {
        this.bsModalRef.hide();
    }

    apply() {
        this.closePopup();
    }

    open_time_selection_popup() {
        console.log('if');
        const bookingDate = new Date();
        bookingDate.setHours(bookingDate.getHours() + 1);
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                minDate: new Date(),
                maxDate: moment(new Date()).add(6, 'days')['_d'],
                bookingDate: this.queueRideFormGroup.get('pickup_date_time')?.value || new Date(),
                rightButtonText: 'Select',
                viewMode: this.viewType === 'edit' ? 'time' : 'full',
                date: this.trip?.pickup_datetime ? this.trip?.pickup_datetime : new Date(),
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(DateTimePickerComponent, initialState);
        modalref.content.event.subscribe((res: { date: Date }) => {
            if (res?.date) {
                this.queueRideFormGroup.get('pickup_date_time').patchValue(new Date(res.date));
            }
        });
    }

    public locationPicker() {
        let autocomplete = {};
        let autocompletesWraps = ['pickup_location', 'dropoff_location'];
        autocompletesWraps.forEach((name: string, index: number) => {
            if ($('#' + name).length == 0) {
                return;
            }
            autocomplete[name] = new google.maps.places.Autocomplete($('#' + name + '.autocomplete')[0]);
            google.maps.event.addListener(autocomplete[name], 'place_changed', () => {
                const place = autocomplete[name].getPlace();
                if (!place.geometry) {
                    alert('Something Went Wrong!!');
                    return;
                }

                switch (name) {
                    case 'pickup_location':
                        this.queueRideFormGroup.patchValue({
                            pickup_lat: place.geometry.location.lat(),
                            pickup_lang: place.geometry.location.lng(),
                        });
                        break;

                    case 'dropoff_location':
                        this.queueRideFormGroup.patchValue({
                            dropoff_lat: place.geometry.location.lat(),
                            dropoff_lang: place.geometry.location.lng(),
                        });
                        break;
                }
                const patch_value = {};
                patch_value[name] = (<HTMLInputElement>document.getElementById(name)).value;
                this.queueRideFormGroup.patchValue(patch_value);
            });
        });
    }

    select_this_car_type(selected_item: any) {
        this.selected_car_type = selected_item;
        this.queueRideFormGroup?.get('car_type').patchValue(selected_item?.car_type);
    }

    onPrimaryAction() {
        this.queueRideFormGroup.markAllAsTouched();
        if (this.queueRideFormGroup.invalid) return;

        const queue_trip = this.queueRideFormGroup?.value;
        this.utilityService.loading = true;
        if (this.viewType === 'add') {
            this.queue_a_new_rider(queue_trip);
        } else if (this.viewType === 'edit') {
            this.update_trip(queue_trip);
        }
    }

    async queue_a_new_rider(queue_trip: any) {
        const pickup = new google.maps.LatLng(queue_trip?.pickup_lat, queue_trip?.pickup_lang);
        const dropoff = new google.maps.LatLng(queue_trip?.dropoff_lat, queue_trip?.dropoff_lang);
        const eta = await this.mapService
            .get_eta({
                origin: pickup,
                destination: dropoff,
                travelMode: google.maps.TravelMode.DRIVING,
                transitOptions: {
                    departureTime: new Date(queue_trip?.pickup_date_time),
                },
            })
            .pipe(first())
            .toPromise();
        let mas_queue_payload: any;
        mas_queue_payload = {
            firstName: queue_trip?.rider_name,
            lastName: '',
            user_mobile: `${queue_trip?.phone_number?.dialCode}-${queue_trip?.phone_number?.number}`,
            pickupDate: moment.utc(queue_trip?.pickup_date_time).format('MM/DD/YYYY'),
            pickup_time: moment.utc(queue_trip?.pickup_date_time).format('hh:mm a'),
            pickupAddress: {
                lat: queue_trip?.pickup_lat,
                lon: queue_trip?.pickup_lang,
            },
            dropoffAddress: {
                lat: queue_trip?.dropoff_lat,
                lon: queue_trip?.dropoff_lang,
            },
            pickup_location_address: queue_trip?.pickup_location,
            dropoff_location_address: queue_trip?.dropoff_location,
            tripLegId: 0,
            invoiceNumber: 0,
            legMileage: null,
            car_type: queue_trip?.car_type,
            ride_estimate_time: eta?.value,
            trip_leg_sequence: 0,
        };

        if (this.paramFromFareEstimate) {
            mas_queue_payload.estimated_fare = this.paramFromFareEstimate.estimated_fare;
            mas_queue_payload.corporate_addon = this.paramFromFareEstimate.corporate_addon;
            mas_queue_payload.fare_factor = this.paramFromFareEstimate.fare_factor;
            mas_queue_payload.ride_discount = this.paramFromFareEstimate.ride_discount;
            mas_queue_payload.avoid_highways = this.paramFromFareEstimate.avoid_highways ? 1 : 0;
            mas_queue_payload.avoid_tolls = this.paramFromFareEstimate.avoid_tolls ? 1 : 0;
            mas_queue_payload.car_type = this.paramFromFareEstimate.car_type;
            mas_queue_payload.select_car_type = this.paramFromFareEstimate.car_type;
        }

        const check_mobile: any = await this.userService
            .check_mobile(`${queue_trip?.phone_number?.dialCode}-${queue_trip?.phone_number?.number}`, queue_trip?.rider_name)
            .pipe(first())
            .toPromise();

        if (check_mobile?.error && check_mobile?.flag === 1316) {
            await this.userService
                .new_user_register(`${queue_trip?.phone_number?.dialCode}-${queue_trip?.phone_number?.number}`, queue_trip?.rider_name, 1)
                .pipe(first())
                .toPromise();
        }

        this.masQueueService
            .queue_mas_trip(mas_queue_payload)
            .subscribe(
                (res: { flag: number; error: string; log: string; mas_trip_id: number }) => {
                    if (!res?.error) {
                        this.utilityService.toast('success', 'Trip queued successfully.');
                        this.event.emit({ queued: true, mas_trip_id: res?.mas_trip_id });
                    } else {
                        this.utilityService.toast('error', res.error);
                    }
                },
                (err) => {
                    this.utilityService.toast('error', err.message);
                }
            )
            .add(() => {
                this.utilityService.loading = false;
                this.bsModalRef.hide();
            });
    }

    async update_trip(trip: any) {
        let note_observable$: Observable<any>;
        if (this.notes_id) {
            // update note
            note_observable$ = this.masNotesService.update_mas_note(this.notes_id, trip?.notes);
        } else {
            note_observable$ = this.masNotesService.add_mas_notes(this.trip?.mas_trip_id, trip?.notes);
        }

        const suggested_fare: ISuggestedFare | any = await this.tripBookingService
            .getSuggestedFare({
                pickup_latitude: trip?.pickup_lat,
                pickup_longitude: trip?.pickup_lang,
                destination_latitude: trip?.dropoff_lat,
                destination_longitude: trip?.dropoff_lang,
                car_type: trip?.car_type,
                avoid_highways: trip?.avoid_highways,
                avoid_tolls: trip?.avoid_tolls,
                fare_factor: trip?.fare_factor || 1,
                mas_trip_id: trip?.mas_trip_id,
            })
            .pipe(first())
            .toPromise();

        if (this.trip_type === 'queued') {
            const new_mobile_no: string = `${trip?.phone_number?.dialCode}-${trip?.phone_number?.number}`;
            if (new_mobile_no !== this.trip?.user_mobile) {
                const check_mobile: any = await this.userService.check_mobile(new_mobile_no, trip?.rider_name).pipe(first()).toPromise();

                if (!check_mobile?.error || check_mobile?.flag !== 1316) {
                    this.utilityService.toast('warning', 'Mobile number already exist');
                    this.queueRideFormGroup.controls?.phone_number.setErrors({ duplicate: true });
                    this.utilityService.loading = false;
                    return;
                }
            }

            const payload: any = {
                mas_trip_id: this.trip?.mas_trip_id,

                user_name: trip?.rider_name,
                user_mobile: `${trip?.phone_number?.dialCode}-${trip?.phone_number?.number}`,
                car_type: trip?.car_type,

                pickup_location: trip?.pickup_location,
                pickup_latitude: trip?.pickup_lat,
                pickup_longitude: trip?.pickup_lang,

                dropoff_location: trip?.dropoff_location,
                dropoff_latitude: trip?.dropoff_lat,
                dropoff_longitude: trip?.dropoff_lang,

                pickup_time: moment.utc(trip?.pickup_date_time).format('hh:mm a'),
                pickup_date: moment.utc(trip?.pickup_date_time).format('MM/DD/yyyy'),
                third_party_amount: trip?.amount,
                user_toll: trip?.user_toll,
                will_call: trip?.will_call || 0,
            };

            if (this.trip?.is_prepaid) {
                payload.prepaid_amount = suggested_fare?.total_amount;
                payload.third_party_amount = suggested_fare?.submerchant_driver_payout;
                payload.gross_fare_amount = suggested_fare?.submerchant_driver_payout;
            }

            const edit_queue_trip$ = this.masQueueService.edit_queue_mas_trip(payload);

            combineLatest([edit_queue_trip$, note_observable$])
                .subscribe(
                    ([queue_trip_response, note_response]) => {
                        if (queue_trip_response?.flag === 1329 && note_response?.flag === 2203) {
                            this.utilityService.toast('success', queue_trip_response.log);
                            this.event.emit({ trip_updated: true });
                        } else {
                            if (queue_trip_response?.error) {
                                this.utilityService.toast('error', queue_trip_response?.error);
                            }
                            if (note_response?.error) {
                                this.utilityService.toast('error', note_response?.error);
                            }
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message);
                    }
                )
                .add(() => {
                    this.utilityService.loading = false;
                    this.bsModalRef.hide();
                });
        } else if (this.trip_type === 'assigned') {
            const payload: any = {
                mas_trip_id: this.trip?.mas_trip_id,
                car_type: trip?.car_type,

                pickup_location: trip?.pickup_location,
                pickup_latitude: trip?.pickup_lat,
                pickup_longitude: trip?.pickup_lang,

                dropoff_location: trip?.dropoff_location,
                dropoff_latitude: trip?.dropoff_lat,
                dropoff_longitude: trip?.dropoff_lang,

                pickup_time: moment.utc(trip?.pickup_date_time).format('hh:mm a'),
                pickup_date: moment.utc(trip?.pickup_date_time).format('MM/DD/yyyy'),
                third_party_amount: trip?.amount,
                user_toll: trip?.user_toll,
                will_call: trip?.will_call || 0,
            };

            if (this.trip?.is_prepaid) {
                payload.prepaid_amount = suggested_fare?.total_amount;
                payload.third_party_amount = suggested_fare?.submerchant_driver_payout;
                payload.gross_fare_amount = suggested_fare?.submerchant_driver_payout;
            }

            const edit_assign_trip$ = this.masAssignService.edit_assign_mas_trip(payload);

            combineLatest([edit_assign_trip$, note_observable$])
                .subscribe(
                    ([queue_trip_response, note_response]) => {
                        if (queue_trip_response?.flag === 2204 && note_response?.flag === 2203) {
                            this.utilityService.toast('success', queue_trip_response.log);
                            this.event.emit({ trip_updated: true });
                        } else {
                            if (queue_trip_response?.error) {
                                this.utilityService.toast('error', queue_trip_response?.error);
                            }
                            if (note_response?.error) {
                                this.utilityService.toast('error', note_response?.error);
                            }
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message);
                    }
                )
                .add(() => {
                    this.utilityService.loading = false;
                    this.bsModalRef.hide();
                });
        }
    }

    get_rider_data(searchString: string = '', search_by_phone: boolean = false) {
        this.userService.get_associated_rider_list({ searchString: searchString }).subscribe((users) => {
            if (users.error) {
                this.utilityService.toast('error', users.error);
            } else {
                this.riders_list = users?.users;
                if (search_by_phone) {
                    this.check_name(searchString);
                } else if (searchString) {
                    this.is_rider_popover_open = true;
                }
            }
        });
    }

    check_name(mobile_no: string | any) {
        const user_match = this.riders_list.find((user) => user.user_mobile == mobile_no);
        if (user_match) {
            this.queueRideFormGroup.patchValue({ rider_name: user_match?.user_name }, { emitEvent: false });
        }
    }

    select_this_rider(rider: { user_name: string; user_mobile: string | any }) {
        this.queueRideFormGroup.patchValue(
            {
                rider_name: rider?.user_name,
                phone_number: rider?.user_mobile?.split('-')?.[1],
            },
            { emitEvent: false }
        );

        this.selectedCountryISO = country_code[rider?.user_mobile?.split('-')?.[0]]?.toLowerCase();
        this.is_rider_popover_open = false;
    }

    /** Open Fare Modal */
    updateQueueTripModal: any;
    openFareModal() {
        this.queueRideFormGroup.markAllAsTouched();
        if (this.queueRideFormGroup.invalid) return;
        // Update the static variable
        this.updateQueueTripModal = { ...this.queueRideFormGroup?.value };
    }

    /** Leastion event */
    onBookingQueued(event: any) {
        this.paramFromFareEstimate = event;
        this.onPrimaryAction();
        this.updateQueueTripModal = null;
    }

    /** Swap Location Pickup */
    public swapPickupLocation() {
        const currentPickupLocation = this.queueRideFormGroup.get('pickup_location').value;
        const currentPickupLat = this.queueRideFormGroup.get('pickup_lat').value;
        const currentPickupLang = this.queueRideFormGroup.get('pickup_lang').value;

        this.queueRideFormGroup.patchValue({
            pickup_location: this.queueRideFormGroup.get('dropoff_location').value,
            pickup_lat: this.queueRideFormGroup.get('dropoff_lat').value,
            pickup_lang: this.queueRideFormGroup.get('dropoff_lang').value,
            dropoff_location: currentPickupLocation,
            dropoff_lat: currentPickupLat,
            dropoff_lang: currentPickupLang,
        });
    }

    /** Swap Location DropOff Pickup */
    public swapDropoffLocation() {
        const currentDropoffLocation = this.queueRideFormGroup.get('dropoff_location').value;
        const currentDropoffLat = this.queueRideFormGroup.get('dropoff_lat').value;
        const currentDropoffLang = this.queueRideFormGroup.get('dropoff_lang').value;

        this.queueRideFormGroup.patchValue({
            dropoff_location: this.queueRideFormGroup.get('pickup_location').value,
            dropoff_lat: this.queueRideFormGroup.get('pickup_lat').value,
            dropoff_lang: this.queueRideFormGroup.get('pickup_lang').value,
            pickup_location: currentDropoffLocation,
            pickup_lat: currentDropoffLat,
            pickup_lang: currentDropoffLang,
        });
    }

    /** Current Location  */
    isPickupLocation: boolean = false;
    isDropOffLocation: boolean = false;
    getCurrentLocation(type: string) {
        type === 'pickup_location' ? (this.isPickupLocation = true) : (this.isDropOffLocation = true);
        if (navigator.geolocation) {
            navigator.geolocation.getCurrentPosition(
                (position) => {
                    // Success callback
                    const lat = position.coords.latitude;
                    const lng = position.coords.longitude;
                    const latlng = new google.maps.LatLng(lat, lng);
                    // Use Geocoding service to get the address
                    this.getAddressFromLatLng(latlng).then(
                        (address: string) => {
                            // Patch the values to the form group
                            if (type === 'pickup_location') {
                                this.queueRideFormGroup.patchValue({
                                    pickup_location: address,
                                    pickup_lat: lat,
                                    pickup_lang: lng,
                                });
                            } else {
                                this.queueRideFormGroup.patchValue({
                                    dropoff_location: address,
                                    dropoff_lat: lat,
                                    dropoff_lang: lng,
                                });
                            }
                            this.isPickupLocation = false;
                            this.isDropOffLocation = false;
                        },
                        (error) => {
                            this.utilityService.toast(
                                'error',
                                `Oops! We couldn't fetch your address. Please allow location access to enhance your experience.`
                            );
                            this.isPickupLocation = false;
                            this.isDropOffLocation = false;
                        }
                    );
                },
                (error) => {
                    // Error callback
                    this.utilityService.toast(
                        'error',
                        `Oops! We couldn't fetch your address. Please allow location access to enhance your experience.`
                    );
                    this.isPickupLocation = false;
                    this.isDropOffLocation = false;
                },
                { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
            );
        } else {
            this.utilityService.toast('error', 'Geolocation is not supported by your browser');
            this.isPickupLocation = false;
            this.isDropOffLocation = false;
        }
    }

    /** Get Address From Lat Lng */
    getAddressFromLatLng(latlng: google.maps.LatLng): Promise<string> {
        return new Promise((resolve, reject) => {
            const geocoder = new google.maps.Geocoder();
            geocoder.geocode({ location: latlng }, (results, status) => {
                if (status === 'OK') {
                    if (results[0]) {
                        resolve(results[0].formatted_address);
                    } else {
                        reject('No address found');
                    }
                } else {
                    reject(`Geocoder failed due to: ${status}`);
                }
            });
        });
    }

    /** Open Mobile Design Fare Estimate modal */
    fareEstimateRecalc() {
        this.queueRideFormGroup.markAllAsTouched();
        if (this.queueRideFormGroup.invalid) return;
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                formValue: this.queueRideFormGroup.value,
                from: 'modal',
            },
            class: 'queud-sortcut modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(FareEstimateRecalculationComponent, initialState);
        modalref.content.event.subscribe((res: any) => {
            if (res) {
                this.paramFromFareEstimate = res;
                this.onPrimaryAction();
                this.updateQueueTripModal = null;
            }
        });
    }

    onCountryChange($event) {
        this.promoCountryCode = `+${$event.dialCode}-`;
    }
}
