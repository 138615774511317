<div class="row">
    <div class="col-12">
        <div class="inner_box">
            <h3>
                <ng-container *ngIf="type === 'driver'">Driver and Car information</ng-container>
                <ng-container *ngIf="type == 'rider'">Rider Information</ng-container>

                <span
                    data-toggle="collapse"
                    data-target="#account"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    (click)="rider_driver_information_show = !rider_driver_information_show"
                >
                    <ng-container *ngIf="rider_driver_information_show">Show Less</ng-container>
                    <ng-container *ngIf="!rider_driver_information_show">Show More</ng-container>
                </span>
            </h3>
            <div class="detail_outer collapse show" id="account">
                <ul>
                    <li>
                        <span>Full Name</span>
                        {{ info_detail?.full_name | ShortText | titlecase }}
                    </li>
                    <li>
                        <span>Sex</span>
                        <span class="ml-1">
                            <span style="min-width: 0" class="text-dark-blue">
                                <ng-container *ngIf="this.info_detail.gender != null">
                                    {{ gender_list[info_detail?.gender] | titlecase }}
                                </ng-container>
                                <ng-container *ngIf="!this.info_detail.gender == null">NA</ng-container>
                            </span>
                            <span
                                class="text-blue fs-10 ml-2 cursor-pointer"
                                [popover]="gender_popover"
                                #genderPopover="bs-popover"
                                containerClass="br-6 w-260"
                                placement="bottom"
                                [outsideClick]="true"
                                (click)="gender_popover_reference = genderPopover; gender_form.patchValue(info_detail?.gender)"
                            >
                                Edit
                            </span>
                        </span>
                    </li>
                    <li>
                        <span>Date Of Birth</span>
                        <span class="text-dark-blue fs-13 ml-1">
                            <ng-container *ngIf="info_detail?.dob">
                                {{ info_detail?.dob | date: 'MMMM d, y' }} | {{ info_detail?.age }} years old
                            </ng-container>
                            <ng-container *ngIf="!info_detail.dob">NA</ng-container>
                        </span>
                    </li>
                </ul>
                <ul>
                    <li>
                        <span>Country</span>
                        {{ info_detail.country || 'NA' }}
                    </li>
                    <li>
                        <span>State</span>
                        {{ info_detail.state || 'NA' }}
                    </li>
                    <li>
                        <span>Zipcode</span>
                        {{ info_detail.zipcode || 'NA' }}
                    </li>
                </ul>
            </div>
        </div>
        <div class="inner_box">
            <h3>
                Rides Information
                <span
                    data-toggle="collapse"
                    data-target="#rides"
                    role="button"
                    aria-expanded="false"
                    aria-controls="collapseExample"
                    (click)="ride_information_show = !ride_information_show"
                >
                    <ng-container *ngIf="ride_information_show">Show Less</ng-container>
                    <ng-container *ngIf="!ride_information_show">Show More</ng-container>
                </span>
            </h3>
            <div class="detail_outer collapse show" id="rides">
                <ul>
                    <li>
                        <span>Total Lapsed</span>
                        {{ info_detail?.total_lapsed || 0 }}
                    </li>
                    <li>
                        <span>Total Request</span>
                        {{ info_detail?.total_request || 0 }}
                    </li>
                    <li>
                        <span>Rides Cancelled</span>
                        {{ info_detail?.total_cancelled || 0 }}
                    </li>
                </ul>
                <ul>
                    <li>
                        <span>Total Completed</span>
                        {{ info_detail?.total_completed || 0 }}
                    </li>
                    <li>
                        <span>Total Regular</span>
                        {{ info_detail?.total_regular || 0 }}
                    </li>
                    <li>
                        <span>Total Scheduled</span>
                        {{ info_detail?.total_scheduled || 0 }}
                    </li>
                </ul>
            </div>
        </div>
        <ng-container>
            <ng-content select="[other_contact]"></ng-content>
        </ng-container>
    </div>
</div>

<ng-template #gender_popover>
    <div class="touch-popover">
        <div class="py-3 px-4 fw-600 text-dark-blue fs-16">Sex Orientation</div>
        <hr class="light light-color" />
        <div>
            <ng-container *ngFor="let item of gender_list; let index = index">
                <div class="d-flex py-2 px-4 justify-content-between touch-list-item cursor-pointer" (click)="gender_id.click()">
                    <div class="text-grey">{{ item | titlecase }}</div>
                    <div>
                        <input
                            class="dark-blue"
                            type="radio"
                            name="gender"
                            #gender_id
                            stop-propagation
                            [value]="index"
                            [checked]="info_detail?.gender === index"
                            (change)="gender_form?.patchValue($event?.target?.value)"
                        />
                    </div>
                </div>
                <hr class="light light-color" />
            </ng-container>
            <!-- Add -->
        </div>
        <hr class="light light-color" />
        <div class="d-flex justify-content-end text-blue2 fs-13 fw-600 py-3 px-4">
            <div class="cursor-pointer mx-4" stop-propagation (click)="gender_popover_reference.hide(); update_gender()">Save</div>
        </div>
    </div>
</ng-template>
