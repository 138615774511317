<ng-container *ngIf="type === 'spinner'">
    <div class="spinner-border text-secondary" role="status">
        <span class="sr-only">Loading...</span>
    </div>
</ng-container>

<ng-container *ngIf="type === 'placeholder'">
    <div [ngClass]="{ 'col m-0 p-0': space }" *ngIf="show === 'list'">
        <ngx-skeleton-loader
            [count]="placeholderLength"
            appearance="line"
            [theme]="{
                width: width,
                'border-radius': borderRadius,
                height: height,
                margin: margin
            }"
        ></ngx-skeleton-loader>
    </div>

    <div *ngIf="show === 'grid'">
        <ngx-skeleton-loader
            [count]="placeholderLength"
            appearance="line"
            [theme]="{
                width: '18rem',
                'border-radius': borderRadius,
                height: '120px',
                margin: '6px'
            }"
        ></ngx-skeleton-loader>
    </div>
    <!-- </div> -->
</ng-container>
