import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';
import { map } from 'rxjs/operators';
import { Notification, NotificationType } from 'src/app/models/notification.model';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import { NotificationToastComponent } from '../../components/notification-toast/notification-toast.component';
import { UtilityService } from 'src/app/core/services/utility/utility.service';

enum NOTIFICATION_APIS {
    corporate_notification = 'corporate_notification',
    read_corporate_notification = 'read_corporate_notification',
}

@Injectable({
    providedIn: 'root',
})
export class NotificationService {
    syncNewNotification = new Subject<any>();
    notificationCircle = new Subject<{ position?: string; active: boolean }>();
    notificationRefsList: Array<{ type: 'top' | 'bottom' | any; id: any }> = [];
    notification_refresh_interval: number = 1 * 1000; // 1 seconds
    is_notification_active: boolean = false;
    silent_notification: boolean = false;
    driverModel: any;

    constructor(private httpService: HttpService, private toastr: ToastrService, private router: Router, private utilityService: UtilityService) {
        this.driverModel = JSON.parse(localStorage.getItem('corporateModel'));
    }

    // Notification APIs
    getNotification(payload: { web_access_token: string; limit: number; offset: number; searchFlag?: number; searchString?: string }) {
        // this.Notifications = this.Notifications.map((notification: Notification) => {
        //     notification.message = this.notificationService.parseNotification(notification.message);
        //     return notification;
        // });
        return this.httpService.post(environment.urlC + NOTIFICATION_APIS.corporate_notification, payload).pipe(
            map((res) => {
                if (res?.flag !== 807 || res?.flag !== 101) {
                    res.corporate_notificatons = res.corporate_notificatons?.map((item: Notification) => this.parseNotification(item));
                }
                return res;
            })
        );
    }

    readNotification(payload: { web_access_token: string; alert_id: number | string; is_all?: number }) {
        return this.httpService.post(environment.urlC + NOTIFICATION_APIS.read_corporate_notification, payload);
    }

    // Notification Triggers

    private notificationSound() {
        const audio: HTMLAudioElement = new Audio();
        audio.src = 'assets/images/notification.mp3';
        audio.load();
        audio.play();
    }

    public triggerNotification(data: { notification: Partial<Notification>; timeOut?: number; silent?: boolean }) {
        if (data?.notification?.metadata) {
            data.notification.metadata = JSON.parse(data?.notification?.metadata);
        }
        if (!document.hasFocus()) {
            this.deskTopNotification(data);
        }

        if (!this.silent_notification && !this.notificationRefsList?.length) this.notificationSound();

        const timeOut = data?.timeOut || 45 * 1000;

        let position = 'bottom';
        let positionClass = 'toast-bottom-right';
        let title = 'New Notification';

        if (data?.notification?.type === NotificationType.MAS_QUEUE_LIVE_ACTION) {
            position = 'top';
            positionClass = 'toast-top-right';
            title = 'Live Trip Notifications';
        }

        this.notificationCircle.next({ position: position, active: true });
        this.is_notification_active = true;

        const notification_payload: any = {
            leftActionText: 'Dismiss',
            type: data?.notification?.type === NotificationType.MAS_QUEUE_LIVE_ACTION ? 'detailed' : 'normal',
            position: position,
            notification_payload: data?.notification,
        };

        if (data.notification?.type) {
            if (data.notification?.type === NotificationType.DISPATCHED_NOW) {
                notification_payload.rightActionText = 'View Map';
            } else if (
                data.notification?.type?.includes(NotificationType.MAS_ASSIGN) ||
                data.notification?.type?.includes(NotificationType.MAS_QUEUE) ||
                data.notification?.type?.includes(NotificationType.MAS_QUEUE_LIVE_ACTION)
            ) {
                notification_payload.rightActionText = 'View Details';
            }
        }
        const notificationRef = this.toastr.show(data.notification?.message, title, {
            positionClass,
            toastComponent: NotificationToastComponent,
            tapToDismiss: false,
            toastClass: `notification-toast ${position}`,
            timeOut,
            newestOnTop: false,

            // @ts-ignore
            payload: notification_payload,
        });

        this.notificationRefsList.push({ type: position, id: notificationRef.toastId });

        notificationRef.onAction.subscribe((res) => {
            console.log('ACTION', res);
            // if (res !== rightActionText) return;

            if (res === 'View Map') {
                if (
                    (data.notification.type === NotificationType.MAS_QUEUE_LIVE_ACTION ||
                        data.notification.type === NotificationType.DISPATCHED_NOW) &&
                    data.notification?.session_id
                ) {
                    // localStorage.setItem(
                    //     'assigned-trip-id',
                    //     JSON.stringify({
                    //         id: data?.notification?.identifier,
                    //         date: data?.notification?.pickup_date,
                    //     })
                    // );

                    localStorage.setItem('routeOff', `${data.notification?.session_id}`);
                    localStorage.setItem('defaultTab', 'reg');

                    if (this.router?.url?.includes('live-tracking')) {
                        this.utilityService.highlightActiveTrip.next();
                    } else {
                        this.router.navigate(['/', 'corporate', 'live-tracking']);
                    }
                    return;
                }
            }

            if (data.notification?.type === NotificationType.MAS_QUEUE) {
                this.router.navigate(['/', 'corporate', 'active-trips', 'queued', data.notification.identifier]);
                return;
            }

            if (
                data.notification?.type?.includes(NotificationType.MAS_ASSIGN) ||
                data.notification?.type === NotificationType.MAS_QUEUE_LIVE_ACTION
            ) {
                this.router.navigate(['/', 'corporate', 'active-trips', 'assigned', data.notification.identifier]);
                return;
            }
        });

        notificationRef.onHidden.subscribe((action) => {
            this.notificationRefsList = this.notificationRefsList.filter((notification) => notification?.id !== notificationRef.toastId);
            if (this._notification_by_type(position)?.length === 0) {
                this.notificationCircle.next({ active: false, position });
                this.is_notification_active = false;
            }
        });
    }

    _notification_by_type(position): Array<any> {
        return this.notificationRefsList.filter((item) => item.type === position);
    }

    private deskTopNotification(data: { notification: Partial<Notification>; timeOut?: number; silent?: boolean }) {
        Notification.requestPermission((permission) => {
            const notification = new Notification(data.notification.subject, {
                body: data.notification.message,
                icon: 'assets/img/favIcon.png',
                dir: 'auto',
                data: data.notification,
                silent: data.silent,
            });
            notification.onclick = (event) => {
                if (
                    data.notification.type?.includes(NotificationType.MAS_ASSIGN) ||
                    data.notification.type?.includes(NotificationType.MAS_QUEUE_LIVE_ACTION)
                ) {
                    window.open(`${window.location.origin}/corporate/active-trips/assigned/${data.notification.identifier}`).focus();
                }
                if (data.notification.type === NotificationType.MAS_QUEUE) {
                    window.open(`${window.location.origin}/corporate/active-trips/queued/${data.notification.identifier}`).focus();
                }
            };
        });
    }

    // Notification Settings

    update_notification_settings(silent_notification: boolean = this.silent_notification) {
        this.silent_notification = silent_notification;
        let notification_preference: any = JSON.parse(localStorage.getItem('notification_preference')) || [];
        if (notification_preference && notification_preference?.find((item) => item.corporate_id === this.driverModel?.corporate_id)) {
            notification_preference = notification_preference.map((item) => {
                if (item.corporate_id === this.driverModel?.corporate_id) {
                    return {
                        corporate_id: this.driverModel?.corporate_id,
                        silent_notification: this.silent_notification,
                    };
                }
                return item;
            });
        } else {
            notification_preference.push({
                corporate_id: this.driverModel?.corporate_id,
                silent_notification: this.silent_notification,
            });
        }
        localStorage.setItem('notification_preference', JSON.stringify(notification_preference));
        this.triggerNotification({
            notification: {
                message: `${this.silent_notification ? 'Silent' : 'Loud'} Notification`,
            },
            timeOut: 3 * 1000,
        });
    }

    get_notification_preference() {
        const notification_preference: any = JSON.parse(localStorage.getItem('notification_preference')) || [];
        this.silent_notification = notification_preference?.find((item) => item.corporate_id === this.driverModel?.corporate_id)?.silent_notification;
    }
    // Henry Cavil has confirmed an assigned trip for Laurie Graham with pickup on { pickup_date:March 24, 2023 11:30 AM }.
    parseNotification(notification: Notification) {
        const pattern = /\{ pickup_date:(.*?) \}/;
        const dateStr = this.get_pickup_date_from_regex(notification.message);
        if (dateStr) {
            notification.pickup_date = new Date(dateStr);
            notification.message = notification.message.replace(
                pattern,
                `${moment.utc(dateStr).local().format('MMMM DD, YYYY')} at ${moment.utc(dateStr).local().format('hh:mm A')}`
            );
        }
        return notification;
    }

    get_pickup_date_from_regex(str: string, pattern = /\{ pickup_date:(.*?) \}/) {
        const match = str.match(pattern);
        return match?.[1];
    }
}
