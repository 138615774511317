import { Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { BsModalRef, BsModalService, ModalOptions } from 'ngx-bootstrap/modal';
import { CookieService } from 'ngx-cookie-service';
import { Subject, Subscription, from } from 'rxjs';
import { debounceTime, first, skip, take } from 'rxjs/operators';
import { Time_Picker } from 'src/app/core/components/time-range-picker/time-range-picker.component';
import { UtilityService } from 'src/app/core/services/utility/utility.service';
import { DateTimePickerComponent } from 'src/app/corporate/components/date-time-picker/date-time-picker.component';
import { QueueTripModalComponent } from 'src/app/corporate/components/queue-trip-modal/queue-trip-modal.component';
import { MasAssignService } from 'src/app/corporate/services/mas-assign/mas-assign.service';
import { MasQueueService } from 'src/app/corporate/services/mas-queue/mas-queue.service';
import { UserService } from 'src/app/corporate/services/user/user.service';
import { HttpService } from 'src/app/services/http/http.service';
import { environment } from 'src/environments/environment';
import * as _ from 'lodash';
import { PopoverDirective } from 'ngx-bootstrap/popover';
import { ActivatedRoute, Router } from '@angular/router';
import { LiveTripInfoComponent } from 'src/app/corporate/components/live-trip-info/live-trip-info.component';
import { MasService } from 'src/app/corporate/services/mas/mas.service';
import { GoogleMapService } from 'src/app/corporate/services/google-map/google-map.service';
import { LocationPickerModalComponent } from 'src/app/corporate/components/location-picker-modal/location-picker-modal.component';
import { ContactModalComponent, IContact } from 'src/app/corporate/components/contact-modal/contact-modal.component';
import { ISecondaryContact } from 'src/app/models/user.model';
import { DriverService } from 'src/app/corporate/services/driver/driver.service';
import { DefaultMatCalendarRangeStrategy, MAT_DATE_RANGE_SELECTION_STRATEGY } from '@angular/material/datepicker';
import { TripBookingService } from 'src/app/corporate/services/trip-booking/trip-booking.service';
import { ISuggestedFare } from 'src/app/models/fare_estimate.model';
import { IOptionItem, OptionType } from 'src/app/models/setting-header.model';
import { TripsService } from 'src/app/corporate/services/trips/trips.service';
import { FareEstimateRecalculationComponent } from 'src/app/corporate/components/fare-estimate-recalculation/fare-estimate-recalculation.component';

// TODO: Replace it with ngx dropdown for long run
declare const jQuery: any;
const $: any = jQuery;

@Component({
    selector: 'app-queued-trips',
    templateUrl: './queued-trips.component.html',
    styleUrls: ['./queued-trips.component.scss'],
    providers: [{ provide: MAT_DATE_RANGE_SELECTION_STRATEGY, useClass: DefaultMatCalendarRangeStrategy }],
})
export class QueuedTripsComponent implements OnInit {
    trip_to_book: {
        is_scheduled: number;
        pickup_latitude: any;
        pickup_longitude: any;
        destination_longitude: any;
        destination_latitude: any;
        promo_code: string;
        car_type: number;
        fare_factor: number;
        user_mobile: any;
        date: any;
        pickup_location_address: any;
        manual_destination_address: any;
        is_mas_trip: boolean;
        selected_driver: { driver_id: string | number; driver_name: string };
        mas_both_leg_trip?: any;
        mas_active_leg_trip?: any;
        assigned_trip_status?: 'pending' | 'dispatched' | 'confirmed' | 'declined' | 'completed' | 'cancelled' | 'dispatched' | 'no show' | '';
        mas_trip_id: number | string;
        type: 'normal' | 'suggested_fare';
        showCreatePayout?: boolean;
        corporate_addon: any;
        ride_discount: any;
        avoid_highways: any;
        avoid_tolls: any;
    };
    constructor(
        public utilityService: UtilityService,
        private masAssignService: MasAssignService,
        private cookieService: CookieService,
        private httpService: HttpService,
        private modalService: BsModalService,
        private masQueueService: MasQueueService,
        public userService: UserService,
        private route: ActivatedRoute,
        private router: Router,
        public masService: MasService,
        private mapService: GoogleMapService,
        private driverService: DriverService,
        private tripBookingService: TripBookingService,
        private tripsService: TripsService
    ) {}

    // As we need same clonned logic under rider detail page, so these parameters are for Rider Detail Page usage only, as per now
    @Input() minimal_setup: boolean = false;
    @Input() rider_name_pre_filter: string;
    use_date_range: boolean = false;
    @Output() onTabChange: EventEmitter<string> = new EventEmitter<string>();

    showPagination: boolean = false;
    showBottomSearchBox: boolean = false;
    searchForm: FormControl = new FormControl('');

    driver_payout_percentage: number;
    show_all_driver_payout: number;
    leg_filter_type: 'all' | 'initial' = 'all';
    pickup_search: FormControl = new FormControl('');
    dropoff_search: FormControl = new FormControl('');

    private subscriptionList$: Subscription = new Subscription();

    isLoading: boolean = false;
    // mas_session_identifier: string;
    third_party_enabled: boolean;
    temp_driver: any; // for making a assign driver call from queue status;
    confirmed_driver_list: Array<any>;
    interested_driver_list: Array<any>;
    paged_driver_loading: boolean = false;

    pageNo: number = 1;
    itemsPerPage: number = this.utilityService.default_display_filter;
    totalItems: number = 0;

    selected_driver: any;
    driver_search_control: FormControl = new FormControl('');
    get_driver_mode: 'all' | 'mas';
    driverLoading: boolean = false;
    drivers: Array<any> = [];
    cached_drivers: Array<any> = [];

    trips: Array<any> = [];
    riderFilterApplied: boolean = false;
    showRiderFilterIcon: 'filter' | 'reset' = 'filter';

    secondary_contact_popover: PopoverDirective;
    secondary_contact_loading: boolean = false;
    active_sc_user_id: number | string;
    secondary_contact_list: Array<ISecondaryContact> = [];

    activeSubTab: 'Active' | 'All Day' | 'Confirmed' | 'Interested' | 'Pending' | 'Dispatched' | 'Manual' | 'Live' | 'Prepaid' = 'All Day';

    loading_queued_trip_container: any[] = [];

    active_trip_date_picker: FormControl = new FormControl(this.utilityService.default_date_filter);
    active_trip_date_range_picker: FormGroup = new FormGroup({
        start: new FormControl(this.utilityService.default_date_filter),
        end: new FormControl(this.utilityService.default_date_filter),
    });

    sort_order: 'desc' | 'asc' = 'asc';

    time_picker: { start: any; end: any } = {
        start: '',
        end: '',
    };

    refetchPrepaidSubject: Subject<void> = new Subject<void>();
    // Holds current active driver state for respective action

    car_type_map: any = {};
    carTypes: any;
    active_car_type_holder: any;
    queuedSubTabList = this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Queued'];
    refreshActionContainer: Array<any> = [];

    headerOptions: IOptionItem[] = [
        { id: 'trip-leg-menu', type: OptionType.DROPDOWN, options: ['all', 'initial'], emitEvent: true, value: 'all', text: 'Trip Leg' },
        { id: 'time-picker', type: OptionType.TIME, emitEvent: true, value: { start: null, end: null } },
        {
            id: 'date-picker',
            type: this.use_date_range ? OptionType.RANGE_DATE : OptionType.NORMAL_DATE,
            form: this.use_date_range ? this.active_trip_date_range_picker : this.active_trip_date_picker,
        },
    ];

    ngOnInit(): void {
        this.checkScreenWidth();
        // Subscribe to window resize event to dynamically update the screen width
        window.addEventListener('resize', () => this.checkScreenWidth());

        this.fetchDriverPercentage();

        this.cached_drivers = JSON.parse(this.cookieService.get('cached_filter_driver') || '[]');
        const driverModel = JSON.parse(localStorage.getItem('corporateModel'));
        this.third_party_enabled = driverModel.is_mas_dispatcher == 1 ? true : false;

        this._getCarType();
        this.getDrivers();
        this.getRideBrokerDropdownList();
        if (this.rider_name_pre_filter) {
            this.use_date_range = true;
        }

        if (this.use_date_range) {
            this.subscriptionList$.add(
                this.active_trip_date_range_picker.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                    const { start, end } = value;
                    if (start && end) {
                        const queryParams = this.route?.snapshot?.queryParams;
                        this.router.navigate([this.router.url.split('?')[0]], {
                            queryParams: { ...queryParams, date_filter: `${moment(start).format('MM/DD/yyyy')}-${moment(end).format('MM/DD/yyyy')}` },
                        });
                        this.resetTimeFilter();
                        this.get_queued_trips();
                    }
                })
            );
        } else {
            this.active_trip_date_picker.valueChanges.subscribe((value) => {
                if (!this.rider_name_pre_filter) this.searchForm.patchValue('', { emitEvent: false });
                this.riderFilterApplied = false;
                this.showRiderFilterIcon = 'filter';

                const queryParams = this.route?.snapshot?.queryParams;
                this.router.navigate([this.router.url.split('?')[0]], {
                    queryParams: { ...queryParams, date_filter: moment(value).format('MM/DD/yyyy') },
                });
                this.resetTimeFilter();
                this.updateSubTabView(value);
                this.get_queued_trips();
            });
        }

        setTimeout(() => {
            const date_filter = this.route.snapshot.queryParams?.date_filter;
            if (this.rider_name_pre_filter) {
                this.searchForm.patchValue(this.rider_name_pre_filter);
                this.searchForm.disable();
            }
            if (date_filter) {
                if (this.use_date_range && date_filter?.includes('-')) {
                    const start = new Date(date_filter?.split('-')?.[0] || new Date());
                    const end = new Date(date_filter?.split('-')?.[1] || new Date());
                    this.active_trip_date_range_picker.patchValue({ start, end });
                } else {
                    this.active_trip_date_picker.patchValue(new Date(date_filter));
                }
            } else {
                this.get_queued_trips();
            }
        }, 0);

        this.subscriptionList$.add(
            this.searchForm.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                if (!value) this.riderFilterApplied = false;
                this.get_queued_trips();
            })
        );

        this.subscriptionList$.add(
            this.driver_search_control.valueChanges.pipe(debounceTime(500)).subscribe((value) => {
                if (value) {
                    value = value.trim();
                }
                this.getDrivers(value);
            })
        );

        this.subscriptionList$.add(
            this.pickup_search.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
                this.dropoff_search.reset('', { emitEvent: false });
                this.get_queued_trips();
            })
        );

        this.subscriptionList$.add(
            this.dropoff_search.valueChanges.pipe(debounceTime(500)).subscribe((value: string) => {
                this.pickup_search.reset('', { emitEvent: false });
                this.get_queued_trips();
            })
        );

        // this.get_queued_trips();
        // this.get_assign_mas_roster_trip_info();

        this.subscriptionList$.add(
            this.utilityService.applyButtonClick$.subscribe(() => {
                // Handle the apply button click event here
                this.get_queued_trips(this.pageNo, this.itemsPerPage, true);
            })
        );

        this.subscriptionList$.add(
            this.utilityService.queuedTripAdding$.subscribe(() => {
                console.log('Event');
                // Handle the apply button click event here
                this.get_queued_trips();
            })
        );
    }

    resetTimeFilter(value: { start: any; end: any } = { start: '', end: '' }) {
        this.time_picker = value;
        this.updateHeaderOption('time-picker', { value: this.time_picker });
    }

    get isFilterActive(): boolean {
        if (this.time_picker.start && this.time_picker.end) return true;
        if (
            this.use_date_range &&
            (!moment().isSame(moment(this.active_trip_date_range_picker?.value?.start), 'day') ||
                !moment().isSame(moment(this.active_trip_date_range_picker?.value?.end), 'day'))
        ) {
            return true;
        } else if (!moment().isSame(moment(this.active_trip_date_picker?.value), 'day')) {
            return true;
        }
        if (this.leg_filter_type != 'all') {
            return true;
        }
        return false;
    }

    onFilterEvent(event: IOptionItem) {
        switch (event.id) {
            case 'trip-leg-menu':
                this.filter_leg_sequence(event.value);
                break;
            case 'time-picker':
                this.onTimePickerChange(event.value);
                break;

            case 'RESET':
                this.leg_filter_type = 'all';
                this.time_picker = { start: '', end: '' };
                this.updateHeaderOption('trip-leg-menu', { value: 'all' });
                this.updateHeaderOption('time-picker', { value: this.time_picker });
                if (this.use_date_range) this.active_trip_date_range_picker.patchValue({ start: new Date(), end: new Date() });
                else this.active_trip_date_picker.patchValue(new Date());
                break;
        }
    }

    updateHeaderOption(id: string, change: any) {
        this.headerOptions = this.headerOptions.map((item) => {
            if (item.id === id) {
                item = { ...item, ...change };
            }
            return item;
        });
    }

    private _getCarType() {
        this.subscriptionList$.add(
            this.tripsService.get_car_type().subscribe((res) => {
                if (res?.data) {
                    this.carTypes = res?.data || [];
                    this.carTypes.forEach((item) => {
                        this.car_type_map[item.car_type] = item?.car_name;
                    });
                }
            })
        );
    }

    private updateSubTabView(date: Date | any) {
        const is_future_date = moment(date).isAfter(moment().endOf('day'));
        const is_todays_date = moment(date).get('date') === moment(date).get('date') && moment().isSame(moment(date), 'day');
        this.queuedSubTabList = this.utilityService.settingHeaderConfig.active_trips.sub_tabs['Queued'].filter((tab: any) => {
            if (is_future_date) {
                return tab?.title !== 'Active';
            } else if (!is_todays_date) {
                return tab?.title !== 'Active';
            } else {
                return true;
            }
        });

        if (!is_todays_date && this.activeSubTab === 'Active') {
            this.activeSubTab = 'All Day';
        }

        if (is_todays_date) {
            // this.activeSubTab = 'Active';
            this.activeSubTab = 'All Day';

            // this.resetSubject.next({ start: `${moment().format('HH')}`, end: '23' });
        }
    }

    onSubTabChanged($event: any) {
        this.activeSubTab = $event;

        if (this.activeSubTab === 'Active') {
            this.resetTimeFilter({ start: `${moment().format('HH')}`, end: '23' });
        } else {
            this.resetTimeFilter();
        }
        this.get_queued_trips();
    }

    public apply_driver_filter(driver: any) {
        this.selected_driver = driver;
        this.get_queued_trips();
    }

    public get_queued_trips(pageNo: number = 1, itemsPerPage: number = this.itemsPerPage, silent: boolean = false, mas_trip_id?: string | number) {
        this.pageNo = pageNo;
        this.itemsPerPage = itemsPerPage;
        if (!silent) {
            this.isLoading = true;
            this.trips = [];
        }
        const payload: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            offset: (pageNo - 1) * this.itemsPerPage,
            limit: this.itemsPerPage,
            sort_order: this.sort_order,
            location_filter: 0,
            date_filter: 0,
            status_filter: 0,
            live_trip: this.activeSubTab === 'Live' ? 1 : 0,
            prepaid_trip: this.activeSubTab === 'Prepaid' ? 1 : 0,
        };

        if (this.activeSubTab === 'Manual') {
            payload.manual_trip = 1;
        }

        if (this.searchForm.value) {
            payload.searchFlag = 1;
            payload.searchString = this.searchForm.value.trim();
        }

        if (this.leg_filter_type === 'initial') {
            payload.trip_leg_sequence = 1;
        }

        if (this.selected_driver) {
            payload.driver_filter = 1;
            payload.driver_searchString = this.selected_driver?.driver_name;
        }

        if (['Confirmed', 'Interested', 'Dispatched']?.includes(this.activeSubTab)) {
            const trip_map = { Confirmed: 1, Interested: 2, Dispatched: 3 };
            payload.status_filter = trip_map[this.activeSubTab];
        }

        if (this.pickup_search.value.trim()) {
            payload.location_filter = 1;
            payload.location_searchString = this.pickup_search.value.trim();
        }

        if (this.dropoff_search.value.trim()) {
            payload.location_filter = 2;
            payload.location_searchString = this.dropoff_search.value.trim();
        }

        payload.ride_broker =
            this.masService.brokerStatus == 'All Brokers'
                ? ''
                : this.masService.brokerStatus == 'SENTRY-TPT'
                ? 'SentryMS'
                : this.masService.brokerStatus == 'SENTRY-MP'
                ? 'SentryMS'
                : this.masService.brokerStatus;
        payload.trip_type =
            this.masService.brokerStatus == 'SENTRY-TPT' ? 'assigned' : this.masService.brokerStatus == 'SENTRY-MP' ? 'marketplace' : '';

        if (this.use_date_range) {
            if (this.active_trip_date_range_picker.value?.start && this.active_trip_date_range_picker.value?.end) {
                const { start, end } = this.active_trip_date_range_picker.value;
                let start_date = moment(start).startOf('day');
                let end_date = moment(end).endOf('day');

                if (this.time_picker?.start && this.time_picker?.end && start_date.isSame(end_date)) {
                    start_date = start_date.set({ hour: this.time_picker?.start, minute: 0, second: 0 });
                    end_date = end_date.set({ hour: this.time_picker?.end, minute: 59, second: 59 });
                }

                payload.date_filter = 1;
                payload.start_date = moment.utc(start_date).format('YYYY-MM-DD');
                payload.end_date = moment.utc(end_date).format('YYYY-MM-DD');

                payload.time_filter = 1;
                payload.start_time = moment.utc(start_date).format('HH:mm:ss');
                payload.end_time = moment.utc(end_date).format('HH:mm:ss');
            }
        } else {
            if (this.active_trip_date_picker.value) {
                const date = this.active_trip_date_picker.value;
                let start_date = moment(date).startOf('day');
                let end_date = moment(date).endOf('day');

                if (this.time_picker?.start && this.time_picker?.end) {
                    start_date = start_date.set({ hour: this.time_picker?.start, minute: 0, second: 0 });
                    end_date = end_date.set({ hour: this.time_picker?.end, minute: 59, second: 59 });
                }

                payload.date_filter = 1;
                payload.start_date = moment.utc(start_date).format('YYYY-MM-DD');
                payload.end_date = moment.utc(end_date).format('YYYY-MM-DD');

                payload.time_filter = 1;
                payload.start_time = moment.utc(start_date).format('HH:mm:ss');
                payload.end_time = moment.utc(end_date).format('HH:mm:ss');
            }
        }

        this.subscriptionList$.add(
            this.masQueueService
                .get_queued_trips(payload)
                .subscribe((res: { count: number; trips: Array<any>; error: string }) => {
                    if (res?.error) {
                        this.utilityService.toast('error', res.error);
                    } else {
                        this.totalItems = res.count || 0;
                        this.trips = res.trips || [];
                        if (silent && mas_trip_id) {
                            this.remove_from_loading_container(mas_trip_id);
                        } else {
                            this.totalItems = res.count || 0;
                            this.trips = res.trips || [];
                        }
                        this.trips = this.trips.map((trip: any) => {
                            // trip.pickup_datetime = new Date(moment.utc(trip?.pickup_date + ' ' + trip?.pickup_time).local().toString());
                            trip.active_class = moment(trip?.pickup_datetime).isBetween(moment(), moment().add(1, 'hour')) ? 'bg-active-trip' : '';
                            trip.inactive_class = moment(trip?.pickup_datetime).isBefore(moment()) ? 'bg-inactive-trip' : '';
                            trip.is_queued = true;
                            return trip;
                        });
                    }

                    if (this.riderFilterApplied) {
                        this.showRiderFilterIcon = 'reset';

                        if (['Completed', 'Dispatched']?.includes(this.activeSubTab)) {
                            const trip_map = { Confirmed: 1, Dispatched: 6 };
                            payload.status_filter = trip_map[this.activeSubTab];
                        }
                        this.masAssignService.get_assign_mas_roster_trip_info(payload).subscribe((res) => {
                            let assigned_trips = res?.trips || [];
                            assigned_trips = assigned_trips.map((trip: any) => {
                                // trip.pickup_datetime = new Date(moment.utc(trip?.pickup_date + ' ' + trip?.pickup_time).local().toString());
                                trip.active_class = moment(trip?.pickup_datetime).isBetween(moment(), moment().add(1, 'hour'))
                                    ? 'bg-active-trip'
                                    : '';
                                trip.inactive_class = moment(trip?.pickup_datetime).isBefore(moment()) ? 'bg-inactive-trip' : '';
                                trip.is_assigned = true;
                                return trip;
                            });
                            this.trips.push(...assigned_trips);
                            console.log('this.trips => ', this.trips);
                            console.log('assigned_trips => ', assigned_trips);

                            if (this.sort_order === 'asc') {
                                this.trips.sort((a, b) => Date.parse(a?.pickup_datetime) - Date.parse(b?.pickup_datetime));
                            } else if (this.sort_order === 'desc') {
                                this.trips.sort((a, b) => Date.parse(b?.pickup_datetime) - Date.parse(a?.pickup_datetime));
                            }

                            this.isLoading = false;
                        });
                    } else {
                        this.isLoading = false;
                        this.showRiderFilterIcon = 'filter';
                    }
                })
                .add(() => {
                    this._setShowPagination();
                    if (mas_trip_id) {
                        this.remove_from_loading_container(mas_trip_id);
                    }
                })
        );
    }

    async assign_driver(driver: any, trip: any, type: 'confirmed' | 'interested' | 'none' = 'none') {
        if (!trip) {
            return;
        }
        try {
            const is_mas_trip: any = trip?.invoice_number ? true : false;
            // this.utilityService.loading = true;
            const mas_trip_id = trip?.mas_trip_id;
            this.loading_queued_trip_container.push(mas_trip_id);

            if (type === 'confirmed') {
                driver.type = type;
            }

            const pickup = new google.maps.LatLng(trip?.pickup_latitude, trip?.pickup_longitude);
            const dropoff = new google.maps.LatLng(trip?.dropoff_latitude, trip?.dropoff_longitude);

            const eta = await this.mapService
                .get_eta({
                    origin: pickup,
                    destination: dropoff,
                    travelMode: google.maps.TravelMode.DRIVING,
                    transitOptions: {
                        departureTime: new Date(trip.pickup_datetime),
                    },
                })
                .pipe(first())
                .toPromise();

            if (is_mas_trip) {
                const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number.toString());
                let mas_active_leg_trip: any = mas_both_leg_trip?.trips.find((x: any) => x.tripLegId.toString() === trip?.trip_leg_id.toString());

                if (!mas_active_leg_trip) {
                    this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                    this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                    return;
                }
                mas_active_leg_trip.car_type = trip?.car_type;
                mas_active_leg_trip.ride_status = trip?.ride_status;

                trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip);
                trip.ride_estimate_time = eta?.value;
            }

            const check_mobile = await this.userService.check_mobile(trip?.user_mobile, trip?.rider_name).pipe(first()).toPromise();
            if (check_mobile?.error && check_mobile?.flag === 1316) {
                console.log(trip?.rider_name, trip);
                await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            }

            let mas_assign: any;
            if (is_mas_trip) {
                // cause the trip object is updated if it is coming from mas
                console.log(trip);
                mas_assign = await this.masAssignService
                    .assign_driver(this.masAssignService.mas_trip_assign_driver_params(trip, driver, 'queued', trip?.ref_trip))
                    .pipe(first())
                    .toPromise();
            } else {
                mas_assign = await this.masAssignService
                    .assign_driver({
                        first_name: trip?.user_name,
                        last_name: '',
                        user_mobile: trip?.user_mobile,
                        pickup_date: trip?.pickup_date,
                        pickup_latitude: trip?.pickup_latitude,
                        pickup_longitude: trip?.pickup_longitude,
                        pickup_location: trip?.pickup_location,
                        dropoff_location: trip?.dropoff_location,
                        dropoff_latitude: trip?.dropoff_latitude,
                        dropoff_longitude: trip?.dropoff_longitude,
                        invoice_number: 0,
                        // Before here added trip_leg_id = 0 but now it's requred to send the trip_leg_id
                        trip_leg_id: trip?.trip_leg_id,
                        pickup_time: trip?.pickup_time,
                        driver_id: driver?.driver_id,
                        offset: new Date().getTimezoneOffset() * -1,
                        confirmed_driver_id: driver?.type === 'confirmed' ? driver?.driver_id : 0,
                        ride_estimate_time: eta?.value,
                        miles: 0,
                        trip_leg_sequence: 0,
                        mas_trip_id,
                        ride_confirmed: trip?.ride_confirmed ? 1 : 0,
                        isUTC: 1,
                        car_type: trip?.car_type,
                        broker_vehicle_id: trip.vehicleId ? trip.vehicleId : '',
                        broker_driver_id: trip.driverId ? trip.driverId : '',
                    })
                    .pipe(first())
                    .toPromise();
            }

            if (mas_assign?.error && mas_assign?.flag === 1316) {
                await this.userService.new_user_register(trip?.user_mobile, trip?.rider_name, 1).pipe(first()).toPromise();
            }

            if (mas_assign.flag === 1322) {
                this.utilityService.toast('success', 'Driver assigned successfully');
                // this.get_queued_trips();
                this.get_queued_trips(this.pageNo, this.itemsPerPage, true, mas_trip_id);
            } else if (mas_assign?.error) {
                this.utilityService.toast('warning', mas_assign?.error);
            }
        } catch (err) {
            console.log('error => ', err);
            this.utilityService.toast('error', err.message || 'Something went wrong');
        } finally {
            this.utilityService.loading = false;
            if (this.live_trip_modal_ref) {
                this.live_trip_modal_ref.hide();
                this.live_trip_modal_ref = null;
            }
        }

        if (driver) {
            this.cached_drivers.unshift(driver);
            this.cached_drivers = _.uniqBy(this.cached_drivers, 'driver_id');
            this.cookieService.set('cached_filter_driver', JSON.stringify(this.cached_drivers));
        }
    }

    onRowClick(event, mas_trip_id: number | string) {
        const classnames: string = event?.target?.className;
        const parentNodeClass: string = event?.target?.parentNode?.className;
        console.log(event);
        console.log(classnames);
        if (classnames?.includes('stop-propagation') || parentNodeClass?.includes('stop-propagation')) return;
        this.router.navigate(['/', 'corporate', 'active-trips', 'queued', mas_trip_id]);
    }

    reset_driver_results(id?: string, driver_mode: 'all' | 'mas' = this.get_driver_mode) {
        const searchString = this.driver_search_control?.value;
        if (searchString || driver_mode != this.get_driver_mode) {
            this.get_driver_mode = driver_mode;
            this.driverLoading = true;
            this.driver_search_control.reset('');
        }

        const element: HTMLElement = document.getElementById(`${id}`) as HTMLElement;
        if (element) {
            setTimeout(() => {
                element.focus();
            }, 0);
        }
    }

    getDrivers(searchString: string = '') {
        this.driverLoading = true;
        let params: any = {
            web_access_token: this.cookieService.get('web_access_token'),
            limit: 20,
            offset: 0,
            tab_type: this.utilityService.MAS_Qudosfave_Map[this.get_driver_mode === 'mas' ? 'MAS Drivers' : 'All Drivers'],
        };

        if (searchString) {
            params.searchFlag = 1;
            params.searchString = searchString;
        }

        this.subscriptionList$.add(
            this.httpService
                .post(environment.urlC + 'get_fav_driver', params)
                .subscribe((data) => {
                    if (typeof data == 'string') data = JSON.parse(data);
                    else data = data;

                    if (data.flag !== 101 && data.flag !== 807) {
                        this.drivers = data?.drivers?.map((driver: any) => {
                            driver.is_mas_driver = this.get_driver_mode === 'mas';
                            return driver;
                        });
                    }
                })
                .add(() => {
                    this.driverLoading = false;
                })
        );
    }

    updateRiderDetailFilter(trip: any, mode: 'set' | 'reset' = 'set') {
        if (mode === 'set') {
            this.searchForm.patchValue(trip?.user_name);
            this.riderFilterApplied = true;
            this.showPagination = false;
        } else {
            this.searchForm.reset();
            this.riderFilterApplied = false;
            this.showPagination = true;
        }
    }

    getQueuedDrivers(mas_trip_id: any) {
        this.paged_driver_loading = true;
        this.subscriptionList$.add(
            this.masQueueService
                .get_queue_driver_info(mas_trip_id)
                .subscribe((data) => {
                    this.confirmed_driver_list = data?.confirmed_drivers || [];
                    this.interested_driver_list = data?.interested_drivers || [];
                })
                .add(() => {
                    this.paged_driver_loading = false;
                })
        );
    }

    update_assigned_driver(trip: any, driver: any, type: string) {
        this.subscriptionList$.add(
            this.masAssignService
                .update_assigned_driver({
                    web_access_token: this.cookieService.get('web_access_token'),
                    driver_id: driver?.driver_id || '',
                    mas_trip_id: trip?.mas_trip_id,
                    request_type: type === 'update' ? 0 : 1,
                })
                .subscribe((res) => {
                    this.get_queued_trips(this.pageNo);
                })
        );
    }

    update_assigned_trip(
        trip: any,
        driver: any,
        type: 'update' | 'unassign',
        operation: 'uncompleting' | 'uncancelling' | 'unassigning' = 'unassigning'
    ) {
        const config = {
            heading: `${_.startCase(operation)} a Trip`,
            description: `You are ${operation} this trip, please confirm`,
            leftButtonText: 'Cancel',
            rightButtonText: 'Yes',
        };
        if (type === 'update') {
            config.heading = 'Assigning a New Driver';
            config.description = 'You are assigning a new driver for this trip, please confirm.';
        }

        this.subscriptionList$.add(
            this.utilityService.confirm(config).subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.update_assigned_driver(trip, driver, type);
                    if (this.live_trip_modal_ref) {
                        this.live_trip_modal_ref.hide();
                        this.live_trip_modal_ref = null;
                    }
                }
            })
        );
    }

    filter_leg_sequence(status: 'all' | 'initial') {
        if (status === this.leg_filter_type) return;
        this.leg_filter_type = status;

        this.get_queued_trips(1);
    }

    async book_mas_ride(trip: any, driver?: any, type: 'normal' | 'suggested_fare' = 'normal') {
        this.suggested_fare_trip_refrence = trip;
        this.utilityService.loading = true;
        if (this.live_trip_modal_ref) {
            this.live_trip_modal_ref.hide();
            this.live_trip_modal_ref = null;
        }
        if (!trip?.invoice_number) {
            this.trip_to_book = {
                is_scheduled: 0,
                pickup_latitude: trip?.pickup_latitude,
                pickup_longitude: trip?.pickup_longitude,
                destination_latitude: trip?.dropoff_latitude,
                destination_longitude: trip?.dropoff_longitude,
                promo_code: '',
                car_type: trip?.car_type,
                fare_factor: 1,
                user_mobile: trip?.user_mobile,
                date: trip?.pickup_datetime,
                pickup_location_address: trip?.pickup_location,
                manual_destination_address: trip?.dropoff_location,
                is_mas_trip: false,
                selected_driver: {
                    driver_id: driver?.driver_id,
                    driver_name: driver?.driver_name,
                },
                assigned_trip_status: this.masAssignService.get_assigned_status(trip) || '',
                mas_trip_id: trip?.mas_trip_id,
                corporate_addon: trip?.corporate_addon,
                ride_discount: trip?.ride_discount,
                avoid_highways: trip?.avoid_highways,
                avoid_tolls: trip?.avoid_tolls,
                type,
                showCreatePayout: false,
            };
        } else {
            const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number);
            const mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId.toString() === trip?.trip_leg_id.toString());
            if (!mas_active_leg_trip) {
                this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                return;
            }
            mas_active_leg_trip.pickupTime = moment(trip?.pickup_datetime).format('HHmm');
            mas_active_leg_trip.pickup_time = moment(trip?.pickup_datetime).format('hh:mm a');
            mas_active_leg_trip.pickup_datetime = new Date(
                moment(mas_active_leg_trip?.pickupDate).format('YYYY-MM-DD') + ', ' + mas_active_leg_trip?.pickup_time
            );
            this.trip_to_book = {
                is_scheduled: 0,
                pickup_latitude: trip?.pickup_latitude,
                pickup_longitude: trip?.pickup_longitude,
                destination_latitude: trip?.dropoff_latitude,
                destination_longitude: trip?.dropoff_longitude,
                pickup_location_address: trip?.pickup_location,
                manual_destination_address: trip?.dropoff_location,
                promo_code: '',
                car_type: trip?.car_type,
                fare_factor: 1,
                user_mobile: trip?.user_mobile ? trip?.user_mobile : this.utilityService.purify_mobile_no(mas_active_leg_trip?.recipientPhone),
                date: trip?.pickup_datetime,
                is_mas_trip: true,
                mas_both_leg_trip,
                mas_active_leg_trip,
                selected_driver: {
                    driver_id: driver?.driver_id,
                    driver_name: driver?.driver_name,
                },
                assigned_trip_status: this.masAssignService.get_assigned_status(trip) || '',
                mas_trip_id: trip?.mas_trip_id,
                corporate_addon: trip?.corporate_addon,
                ride_discount: trip?.ride_discount,
                avoid_highways: trip?.avoid_highways,
                avoid_tolls: trip?.avoid_tolls,
                type,
                showCreatePayout: false,
            };
        }
    }

    private _setShowPagination() {
        this.showPagination =
            this.trips?.length > 0 &&
            // (this.trips?.length >= this.itemsPerPage || this.pageNo != 1) &&
            !this.isLoading;
        // && this.totalItems > this.itemsPerPage;

        // alert(this.showPagination);
    }

    onTimePickerChange(time_picker: Time_Picker) {
        this.time_picker = time_picker;
        // this.process_time_filter();
        this.get_queued_trips();
    }

    confirm_assign_driver(trip: any, driver: any, type: 'update' | 'unassign') {
        const config = {
            heading: 'Unassigning a Driver',
            description: 'You are unassigning this trip, please confirm',
            leftButtonText: 'Cancel',
            rightButtonText: 'Yes',
        };
        if (type === 'update') {
            config.heading = 'Assigning a New Driver';
            config.description = 'You are assigning a new driver for this trip, please confirm.';
        }

        this.subscriptionList$.add(
            this.utilityService.confirm(config).subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    this.update_assigned_driver(trip, driver, type);
                }
            })
        );
    }

    generateCSV() {
        if (!this.trips.length) return;
        let csvContent = '';
        const csvKey = [
            'Trip ID',
            'Assign ID',
            'Assigned Driver',
            'Driver Mobile',
            'Rider Name',
            'Rider Mobile',
            'Pickup Date & Time',
            'Pickup Location',
            'Dropoff Location',
            'MAS Payout',
            'Driver Pay',
            'Toll',
            'Mileage',
            'Car Type',
        ];
        csvContent += csvKey + '\r\n';

        this.trips.forEach((trip) => {
            const row = [
                trip?.trip_leg_id || '-',
                trip?.mas_trip_id || '-',
                trip?.requested_driver_names || '-',
                trip?.requested_driver_mobile || '-',
                trip?.user_name || '-',
                trip?.user_mobile || '-',
                moment(trip?.pickup_datetime).format('MMM DD YYYY hh:mm A') || '-',
                trip?.pickup_location?.replace(/[,#]/g, ' ') || '-',
                trip?.dropoff_location?.replace(/[,#]/g, ' ') || '-',
                `$${trip?.leg_cost}`,
                `$${trip?.third_party_amount}`,
                `$${trip?.user_toll}`,
                `${trip?.miles} Miles`,
                this.car_type_map[trip?.car_type],
            ];

            csvContent += row + '\r\n';
        });

        this.utilityService.generate_csv(`Queued-trips-${moment(this.active_trip_date_picker?.value).format('Do_MMM')}`, csvContent);
    }

    toggleAttestationSort() {
        this.sort_order = this.sort_order === 'asc' ? 'desc' : 'asc';
        this.get_queued_trips();
    }

    open_time_picker_modal(trip: any) {
        console.log(trip);
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                viewMode: 'willcall+time',
                bookingDate: trip?.pickup_datetime ? new Date(trip?.pickup_datetime) : new Date(),
                rightButtonText: 'Save',
                title: 'Edit Trip Time',
                willCall: trip?.will_call === 1 ? true : false,
                date: trip?.pickup_datetime,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(DateTimePickerComponent, initialState);
        modalref.content.event.subscribe((res: { date: Date; willCall?: boolean }) => {
            if (res) {
                const payload: any = {
                    mas_trip_id: trip?.mas_trip_id,
                    pickup_time: moment.utc(res.date).format('hh:mm a'),
                    pickup_date: moment.utc(res.date).format('MM/DD/yyyy'),
                    will_call: trip?.will_call || 0,
                };
                if (res.willCall) {
                    payload.will_call = 1;
                } else if (res.willCall === false && trip?.will_call == 1) {
                    payload.will_call = 0;
                }
                this.update_queued_mas_trip(payload);
            }
        });
    }

    // trip: any
    open_location_picker(trip: any) {
        const location = {
            pickup_location: {
                location: trip?.pickup_location,
                lat: trip?.pickup_latitude,
                lng: trip?.pickup_longitude,
            },
            dropoff_location: {
                location: trip?.dropoff_location,
                lat: trip?.dropoff_latitude,
                lng: trip?.dropoff_longitude,
            },
        };
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                location,
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(LocationPickerModalComponent, initialState);
        modalref.content.event.subscribe((res: any) => {
            if (res) {
                const payload: any = {
                    mas_trip_id: trip?.mas_trip_id,

                    car_type: trip?.car_type,
                    // pickup_time: trip?.pickup_time,
                    third_party_amount: trip?.third_party_amount,
                    user_toll: trip?.user_toll,
                    will_call: trip?.will_call ? 1 : 0,

                    pickup_location: res?.pickup_location,
                    pickup_latitude: res?.pickup_lat,
                    pickup_longitude: res?.pickup_lng,
                    dropoff_location: res?.dropoff_location,
                    dropoff_latitude: res?.dropoff_lat,
                    dropoff_longitude: res?.dropoff_lng,
                };

                if (trip?.is_prepaid) {
                    // If trip is prepaid, then refetch the suggested fare and update it from here.
                    this.tripBookingService
                        .getSuggestedFare({
                            pickup_latitude: res?.pickup_lat,
                            pickup_longitude: res?.pickup_lng,
                            destination_latitude: res?.dropoff_lat,
                            destination_longitude: res?.dropoff_lng,
                            car_type: trip?.car_type,
                            avoid_highways: trip?.avoid_highways,
                            avoid_tolls: trip?.avoid_tolls,
                            fare_factor: trip?.fare_factor || 1,
                            mas_trip_id: trip?.mas_trip_id,
                        })
                        .subscribe((suggested_fare: ISuggestedFare) => {
                            payload.prepaid_amount = suggested_fare?.total_amount;
                            payload.third_party_amount = suggested_fare?.submerchant_driver_payout;
                            payload.gross_fare_amount = suggested_fare?.submerchant_driver_payout;
                            this.update_queued_mas_trip(payload);
                        });
                } else {
                    this.update_queued_mas_trip(payload);
                }
            }
        });
    }

    update_car_type(trip: any, car_type: any) {
        const payload: any = {
            mas_trip_id: trip?.mas_trip_id,
            car_type,
            pickup_time: trip?.pickup_time,
            third_party_amount: trip?.third_party_amount,
            user_toll: trip?.user_toll,
            will_call: trip?.will_call || 0,
        };
        if (trip?.is_prepaid) {
            // If trip is prepaid, then refetch the suggested fare and update it from here.
            this.tripBookingService
                .getSuggestedFare({
                    pickup_latitude: trip?.pickup_latitude,
                    pickup_longitude: trip?.pickup_longitude,
                    destination_latitude: trip?.dropoff_latitude,
                    destination_longitude: trip?.dropoff_longitude,
                    car_type,
                    avoid_highways: trip?.avoid_highways,
                    avoid_tolls: trip?.avoid_tolls,
                    fare_factor: trip?.fare_factor || 1,
                    mas_trip_id: trip?.mas_trip_id,
                })
                .subscribe((suggested_fare: ISuggestedFare) => {
                    payload.prepaid_amount = suggested_fare?.total_amount;
                    payload.third_party_amount = suggested_fare?.submerchant_driver_payout;
                    payload.gross_fare_amount = suggested_fare?.submerchant_driver_payout;
                    this.update_queued_mas_trip(payload);
                });
        } else {
            this.update_queued_mas_trip(payload);
        }
    }

    async cancelTrip(trip: any) {
        try {
            // this.utilityService.loading = true;
            this.loading_queued_trip_container.push(trip?.mas_trip_id);
            // this.utilityService
            //     .confirm({
            //         heading: 'Cancel this Trip',
            //         description: 'You are CANCELLING this trip, please confirm.',
            //         leftButtonText: 'Dismiss',
            //         rightButtonText: 'Yes',
            //     })
            //     .subscribe(async (event: { action: 'left' | 'right' }) => {
            //         if (event.action === 'right') {

            // forkJoin(this.masQueueService.edit_queue_mas_trip({ mas_trip_id: trip?.mas_trip_id, status: 1 }).pipe(catchError((err) => of(err))))

            await this.masQueueService.edit_queue_mas_trip({ mas_trip_id: trip?.mas_trip_id, status: 1 }).pipe(first()).toPromise();

            // get_mas_trip
            const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number);
            let mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId.toString() === trip?.trip_leg_id.toString());

            mas_active_leg_trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip);
            mas_active_leg_trip.car_type = trip?.car_type || 1;

            const cancel_trip = await this.masAssignService.cancel_trip(mas_active_leg_trip).pipe(first()).toPromise();

            if (cancel_trip.flag === 1326) {
                this.utilityService.toast('success', 'Mas trip ride cancelled successfully');
                this.get_queued_trips(this.pageNo, this.itemsPerPage, true);
            } else if (cancel_trip?.error) {
                console.log('res => ', cancel_trip);
                this.utilityService.toast('error', cancel_trip?.error || 'Something went wrong');
            }
            //     }
            // });
        } catch (err) {
            console.log('error => ', err);
            this.utilityService.toast('error', err.message || 'Something went wrong');
        } finally {
            this.utilityService.loading = false;
        }
    }

    async completeTrip(trip: any) {
        // this.utilityService
        //     .confirm({
        //         heading: 'Complete this Trip',
        //         description: 'You are COMPLETING this trip, please confirm.',
        //         leftButtonText: 'Dismiss',
        //         rightButtonText: 'Yes',
        //     })
        //     .subscribe(async (event: { action: 'left' | 'right' }) => {
        //         if (event.action === 'right') {
        this.loading_queued_trip_container.push(trip?.mas_trip_id);
        await this.masQueueService.edit_queue_mas_trip({ mas_trip_id: trip?.mas_trip_id, status: 1 }).pipe(first()).toPromise();

        // get_mas_trip
        const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number);
        let mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId.toString() === trip?.trip_leg_id.toString());

        mas_active_leg_trip = this.utilityService.pre_process_mas_trip(mas_active_leg_trip, trip);
        mas_active_leg_trip.car_type = trip?.car_type || 1;

        const cancel_trip = await this.masAssignService.cancel_trip(mas_active_leg_trip).pipe(first()).toPromise();

        if (cancel_trip.flag === 1326) {
            const complete_trip = await this.masAssignService
                .edit_assign_mas_trip({
                    mas_trip_id: cancel_trip?.mas_trip_id,
                    ride_status: 4,
                })
                .pipe(first())
                .toPromise();

            if (complete_trip?.flag === 2204) {
                this.utilityService.toast('success', 'Mas trip ride completed successfully');
                this.get_queued_trips(this.pageNo, this.itemsPerPage, true);
            } else {
                this.utilityService.toast('success', 'Something went wrong');
                console.log('complete_trip res ===> ', complete_trip);
            }
        } else {
            this.utilityService.toast('success', 'Something went wrong');
            console.log('complete_trip res ===> ', cancel_trip);
        }
        //     }
        // });
    }

    queue_alert(nextAction: 'dispatch' | 'assign', trip: any, driver: any) {
        const config = {
            heading: 'Queue Alert',
            description: 'This INTERESTED Driver is not approved by your car base, please reach out and advice them to submit their application.',
            leftButtonText: 'Cancel',
            rightButtonText: 'Ok',
        };

        this.subscriptionList$.add(
            this.utilityService.confirm(config).subscribe((event: { action: 'left' | 'right' }) => {
                if (event.action === 'right') {
                    if (nextAction === 'dispatch') {
                        this.book_mas_ride(trip, driver);
                    } else {
                        this.assign_driver(driver, trip);
                    }
                }
            })
        );
    }

    async open_mas_trip_info_popup(trip: any) {
        if (!trip?.invoice_number) return;
        this.masService.open_mas_trip_info_popup({
            date: trip?.pickup_datetime,
            invoice_number: trip?.invoice_number,
        });
    }

    /**
     * @param {number} item - 1 -> Queue a trip (New rider), 2 -> Queue a trip (Existing rider), 3 -> Queue multi-trips
     */
    onQueueTripItemSelected(item: number) {
        console.log(item);
        if (item === 1) {
            this.open_queue_a_trip_modal();
        }
        // else if(item === 2) {
        // } else if(item === 3) {
        // }
    }

    open_queue_a_trip_modal() {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                isMobilePopup: false,
            },
            class: 'bg-none modal-dialog-centered modal-dialog-large w-auto modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(QueueTripModalComponent, initialState);
        modalref.content.event.subscribe((res: { queued: boolean }) => {
            console.log(res);
            if (res?.queued) {
                this.get_queued_trips();
            }
            // this.mas_active_leg_trip.recipientPhone = phone_number.number.toString();
            // this.open_mas_trip_details(this.mas_active_leg_trip, false, true);
        });
    }

    update_queued_mas_trip(
        payload: {
            mas_trip_id: string | number;
            status?: number;
            ride_status?: number;
            car_type?: number;
            pickup_time?: any;
            pickup_date?: any;
            third_party_amount?: any;
            gross_fare_amount?: number;
            fare_factor?: number;
            user_toll?: any;
            avoidHighways?: any;
            avoid_tolls?: any;
            will_call?: number;
            pickup_location?: string;
            pickup_latitude?: string;
            pickup_longitude?: string;
            dropoff_location?: string;
            dropoff_latitude?: string;
            dropoff_longitude?: string;
            ride_confirmed?: number;
            show_driver_payout?: number;
            is_prepaid?: number;
            prepaid_amount?: number;
        },
        silent_reload: boolean = true,
        skip_reload: boolean = false,
        tripRef?: any // for prepaid change
    ) {
        this.loading_queued_trip_container.push(payload.mas_trip_id);
        this.subscriptionList$.add(
            this.masQueueService.edit_queue_mas_trip(payload).subscribe(
                (res: { flag: number; log: string; error: any }) => {
                    if (res?.flag === 1328 || res?.flag === 1329) {
                        this.utilityService.toast('success', res.log);

                        // Handle prepaid second leg trip, for prepaid operation on initial leg trip, and must be checked that it is a mas trip or not.
                        if (payload.is_prepaid == 1 && tripRef && tripRef?.trip_leg_sequence == 1 && tripRef?.invoice_number) {
                            from(
                                this.masQueueService.prepaid_second_leg_trip({
                                    initial_trip_leg_id: tripRef?.trip_leg_id,
                                    invoice_number: tripRef?.invoice_number,
                                    date: moment(tripRef?.pickup_datetime).tz('America/New_York').format('MM/DD/YYYY'),
                                })
                            ).subscribe(
                                (res) => {
                                    this.get_queued_trips(this.pageNo, this.itemsPerPage, silent_reload);
                                },
                                (err) => {
                                    console.log('Error handle_second_leg_trip', err);
                                    this.utilityService.toast('error', 'Something went wrong while marking return trip as prepaid');
                                },
                                () => {
                                    this.remove_from_loading_container(payload?.mas_trip_id);
                                    this.refetchPrepaidSubject.next();
                                }
                            );
                        } else {
                            if (payload?.is_prepaid != null || payload?.prepaid_amount != null) {
                                this.refetchPrepaidSubject.next();
                            }

                            if (!skip_reload) {
                                this.get_queued_trips(this.pageNo, this.itemsPerPage, silent_reload, payload?.mas_trip_id);
                            } else {
                                this.remove_from_loading_container(payload?.mas_trip_id);
                            }
                        }
                        this.driver_pay_loading = false;
                        this.applied_suggested_payout = true;
                    } else {
                        this.utilityService.toast('error', res?.error || res?.log || 'Something went wrong!');
                        this.get_queued_trips(this.pageNo, this.itemsPerPage, silent_reload, payload?.mas_trip_id);
                    }
                },
                (err) => {
                    this.remove_from_loading_container(payload?.mas_trip_id);
                    this.utilityService.toast('error', err?.message || 'Something went wrong!');
                }
            )
        );
    }

    driver_pay_loading: boolean = false;
    applied_suggested_payout: boolean = false;
    update_driverpay_amount(trip: any) {
        this.driver_pay_loading = true;
        trip.third_party_amount = this.suggested_driver_payout;
        trip.gross_fare_amount = this.suggested_fare;
        this.update_queued_mas_trip(
            {
                mas_trip_id: trip?.mas_trip_id,
                third_party_amount: this.suggested_driver_payout,
                gross_fare_amount: this.suggested_fare,
                user_toll: trip?.user_toll,
                car_type: trip?.car_type || 1,
                pickup_time: trip?.pickup_time,
                will_call: trip?.will_call || 0,
            },
            true,
            true
        );
    }

    live_trip_modal_ref: BsModalRef;
    async open_live_map_view(index: number) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                left_action_text: 'Unqueue',
                right_action_text: 'Dispatch',
                trip_type: 'queued',
                total_trips: this.trips,
                trip_index: index,
            },
            // ignoreBackdropClick: true,
            ...this.utilityService.mapViewModal,
        };
        this.live_trip_modal_ref = this.modalService.show(LiveTripInfoComponent, initialState);
        this.live_trip_modal_ref.content.event.subscribe(
            (data: { action: 'Unqueue' | 'Dispatch' | 'Re-Dispatch' | 'assign'; driver?: any; trip: any }) => {
                const action = data?.action;
                if (action === 'Unqueue') {
                    this.update_queued_mas_trip({ mas_trip_id: data?.trip?.mas_trip_id, status: 1 });
                } else if (action === 'Dispatch' || action === 'Re-Dispatch') {
                    this.book_mas_ride(data?.trip);
                } else if (action === 'assign' && data?.driver) {
                    this.assign_driver(data?.driver, data?.trip);
                }
            }
        );
    }

    add_or_update_secondary_contact(mode: 'add' | 'edit', contact?: ISecondaryContact) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode,
                contact: { name: contact?.name, mobile: contact?.user_secondary_mobile },
                title: mode === 'edit' ? 'Edit Secondary Contact' : 'Add Secondary Contact',
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((action: { name: string; mobile: string }) => {
            if (action) {
                this.utilityService.loading = true;

                // Add contact
                if (mode === 'add') {
                    this.subscriptionList$.add(
                        this.userService
                            .add_secondary_contacts({ user_id: this.active_sc_user_id, mobile: action?.mobile, name: action?.name })
                            .subscribe(
                                (res) => {
                                    console.log(res);
                                    if (res?.flag === 1500) {
                                        this.utilityService.toast('success', `Added ${action?.name} as secondary contact`);
                                        modalref.hide();
                                        this.get_secondary_contacts(this.active_sc_user_id, true);
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                } else {
                    this.subscriptionList$.add(
                        this.userService
                            .update_seconday_contacts({ id: contact.id, mobile: action?.mobile, name: action?.name })
                            .subscribe(
                                (res) => {
                                    console.log(res);
                                    if (res?.flag === 1500) {
                                        this.utilityService.toast('success', `Updated secondary contact ${action?.name}`);
                                        modalref.hide();
                                        this.get_secondary_contacts(this.active_sc_user_id, true);
                                    } else {
                                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                                    }
                                },
                                (err) => {
                                    this.utilityService.toast('error', err?.message || 'Something went wrong');
                                }
                            )
                            .add(() => {
                                this.utilityService.loading = false;
                            })
                    );
                }

                // Edit Contact
            }
        });
    }

    get_secondary_contacts(user_id: number | string, silent: boolean = false) {
        this.active_sc_user_id = user_id;
        if (!user_id) {
            this.utilityService.toast('info', 'Trip is not associated with any rider');
            console.log('User id not found in the trip');
            return;
        }
        if (!silent) this.secondary_contact_loading = true;
        this.subscriptionList$.add(
            this.userService
                .get_secondary_contacts(user_id)
                .subscribe(
                    (res) => {
                        console.log('res => ', res);
                        if (res?.contacts) {
                            this.secondary_contact_list = res?.contacts;
                        } else {
                            this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                    }
                )
                .add(() => {
                    this.secondary_contact_loading = false;
                })
        );
    }

    remove_secondary_contact(contact: ISecondaryContact) {
        this.utilityService
            .confirm({
                heading: 'Remove secondary contact',
                description: `Are you sure you want to remove <u>${contact?.name}</u> as Secondary Contact?`,
                leftButtonText: 'Cancel',
                rightButtonText: 'Yes',
            })
            .subscribe((event: { action: 'left' | 'right' }) => {
                if (event?.action === 'right') {
                    this.utilityService.loading = true;
                    this.userService
                        .remove_secondary_contact(contact.id)
                        .subscribe((res) => {
                            if (res?.flag === 1500) {
                                this.utilityService.toast('success', `${contact?.name} has been removed successfully from secondary contact`);
                                this.get_secondary_contacts(contact.user_id, true);
                            } else {
                                this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                            }
                        })
                        .add(() => {
                            this.utilityService.loading = false;
                        });
                }
            });
    }

    async onRefreshTrip(trip: any) {
        this.refreshActionContainer.push(trip?.trip_leg_id);
        if (trip?.invoice_number) {
            try {
                const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number, 2);
                if (mas_both_leg_trip?.trips?.length === 0 && mas_both_leg_trip?.message === 'No Results.') {
                    this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                    this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                } else {
                    this.refreshActionContainer = this.refreshActionContainer.filter((leg_id) => leg_id != trip?.trip_leg_id);
                }
            } catch (errr) {
                console.log('No result found');
                this.utilityService.toast('success', 'Trip not found on MAS, removing from queued');
            }
        }
        // stop loading
    }

    delete_mas_trip(leg_id: string, mas_trip_id?: string) {
        this.masService.delete_mas_trip(leg_id).subscribe((res) => {
            this.get_queued_trips(this.pageNo, this.itemsPerPage, true, mas_trip_id);
        });

        this.refreshActionContainer = this.refreshActionContainer.filter((item) => item != leg_id);
    }

    @ViewChild('tableHeader') tableHeaderRef: ElementRef;
    showHeaderShadow: boolean = false;
    onScroll(event: any) {
        this.showHeaderShadow = event.srcElement.scrollTop !== 0;
        const rec = this.tableHeaderRef.nativeElement.getBoundingClientRect();
        if (rec?.bottom < 220) {
            this.showBottomSearchBox = true;
        } else {
            this.showBottomSearchBox = false;
        }
    }

    on_select_driver(driver: any, type: 'cached' | 'new' = 'new') {
        this.temp_driver = {
            ...driver,
            type,
        };
    }

    recent_driver_alert_logs: Array<string | number> = [];
    send_driver_alert(mas_trip_id: number | string, driver_id: number | string) {
        this.recent_driver_alert_logs.push(`q:${mas_trip_id}:${driver_id}`);
        this.utilityService.loading = true;
        this.subscriptionList$.add(
            this.masQueueService
                .send_driver_alert({ mas_trip_id, driver_id, web_access_token: this.cookieService.get('web_access_token') })
                .subscribe(
                    (res: { flag: number; log: string; error: string }) => {
                        if (res?.flag === 1333) {
                            this.utilityService.toast('success', 'Request sent successfully');
                        } else if (res?.error) {
                            this.utilityService.toast('error', res?.error || 'Something went wrong');
                        } else {
                            this.utilityService.toast('warning', res?.log);
                        }

                        setTimeout(() => {
                            this.release_driver_log(mas_trip_id, driver_id);
                        }, 30 * 1000);
                    },
                    (err) => {
                        console.log(err);
                        this.utilityService.toast('error', err?.messsage || 'Something went wrong');
                        this.release_driver_log(mas_trip_id, driver_id);
                    }
                )
                .add(() => {
                    this.utilityService.loading = false;
                })
        );
    }

    send_payment_link_to_rider(trip: any, type: any) {
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                mode: 'edit',
                actionButton: 'Send',
                title: trip?.paid ? 'Send Trip Info Link' : 'Send Payment Link to Rider ',
                showHeaderSuffixLabel: 'Check fare estimate?',
                contact: {
                    name: trip?.user_name,
                    mobile: trip?.user_mobile,
                },
            },
            ...this.utilityService.ngxModalOption,
        };
        const modalref: BsModalRef = this.modalService.show(ContactModalComponent, initialState);
        modalref.content.event.subscribe((action: { mobile: string; onSuffixHeaderClicked?: boolean }) => {
            if (action?.onSuffixHeaderClicked) {
                this.book_mas_ride(trip, 'suggested_fare');
            } else {
                if (action.mobile?.includes('-')) action.mobile = action.mobile.replace('-', '');
                this.utilityService.loading = true;
                this.tripsService
                    .send_payment_link(action.mobile, trip?.mas_trip_id, type)
                    .subscribe((res) => {
                        if (res?.error) {
                            this.utilityService.toast('error', res?.error || res?.message);
                        } else {
                            this.utilityService.toast('success', res?.message);
                        }
                    })
                    .add(() => {
                        this.utilityService.loading = false;
                    });
                modalref.hide();
            }
        });
    }

    get_payment_link(mas_trip_id: string) {
        this.utilityService.loading = true;
        this.tripsService
            .get_payment_link(mas_trip_id)
            .subscribe((res) => {
                if (res?.error || !res?.referral_link) {
                    this.utilityService.toast('error', res?.error || res?.message || 'Something went wrong');
                } else {
                    window.open(res?.referral_link, '_blank');
                }
            })
            .add(() => {
                this.utilityService.loading = false;
            });
    }

    release_driver_log(mas_trip_id: number | string, driver_id: number | string) {
        this.recent_driver_alert_logs = this.recent_driver_alert_logs.filter((log) => log !== `q:${mas_trip_id}:${driver_id}`);
    }

    updateDriverPercentage(payload: { third_party_percentage?: number; show_driver_payout?: number }) {
        this.subscriptionList$.add(
            this.driverService.set_driver_percentage(payload).subscribe(
                (res: { flag: number; error?: string; log?: string }) => {
                    if (res?.flag === 1329) {
                        this.utilityService.toast('success', res?.log);
                        if (payload?.show_driver_payout === 0 || payload?.show_driver_payout === 1) {
                            this.get_queued_trips(this.pageNo, this.itemsPerPage, true);
                        }
                        this.fetchDriverPercentage();
                    } else {
                        this.utilityService.toast('error', res?.log || res?.error || 'Something went wrong');
                        console.log('driver percentage error => ', res);
                    }
                },
                (err) => {
                    console.log('driver percentage error => ', err);
                }
            )
        );
    }

    fetchDriverPercentage() {
        this.driverService.featch_driver_percentage().subscribe(
            (res: { third_party_percentage: number; show_driver_payout: number; go_live_minutes?: number; go_live_miles?: number }) => {
                this.driver_payout_percentage = res?.third_party_percentage;
                this.show_all_driver_payout = res?.show_driver_payout;
            },
            (err) => {
                console.log('driver percentage error => ', err);
            }
        );
    }

    suggested_fare_loading: boolean = false;
    suggested_driver_payout: number;
    suggested_fare: number;
    suggested_fare_trip_refrence: any;
    get_suggested_fare(trip: any) {
        this.suggested_fare_trip_refrence = trip;
        this.suggested_fare_loading = true;
        this.subscriptionList$.add(
            this.tripBookingService
                .getSuggestedFare({
                    pickup_latitude: trip?.pickup_latitude,
                    pickup_longitude: trip?.pickup_longitude,
                    destination_latitude: trip?.dropoff_latitude,
                    destination_longitude: trip?.dropoff_longitude,
                    car_type: trip?.car_type,
                    avoid_highways: trip?.avoid_highways,
                    avoid_tolls: trip?.avoid_tolls,
                    fare_factor: trip?.fare_factor || 1,
                    mas_trip_id: trip?.mas_trip_id,
                })
                .subscribe(
                    (res: { submerchant_driver_payout: number; value_regular: number }) => {
                        if (res?.submerchant_driver_payout == null && res?.value_regular == null) {
                            console.log(res);
                            this.utilityService.toast('error', 'Something went wrong');
                        } else {
                            this.suggested_driver_payout = res?.submerchant_driver_payout;
                            this.suggested_fare = res?.value_regular;
                        }
                    },
                    (err) => {
                        this.utilityService.toast('error', err?.message || 'Something went wrong');
                    }
                )
                .add(() => {
                    this.suggested_fare_loading = false;
                })
        );
    }

    private remove_from_loading_container(mas_trip_id: number | string) {
        this.loading_queued_trip_container = this.loading_queued_trip_container.filter((item) => item !== mas_trip_id);
    }

    onDispatched() {
        this.router.navigate(['/', 'corporate', 'live-tracking']);
    }

    ngOnDestroy(): void {
        this.subscriptionList$.unsubscribe();
    }

    /** Open Mobile Design Fare Estimate modal */
    async fareEstimateRecalc(trip) {
        this.utilityService.loading = true;
        if (this.live_trip_modal_ref) {
            this.live_trip_modal_ref.hide();
            this.live_trip_modal_ref = null;
        }
        if (!trip?.invoice_number) {
            this.suggested_fare_trip_refrence = trip;
        } else {
            const mas_both_leg_trip = await this.masService.fetch_mas_details(trip?.pickup_date, trip?.invoice_number);
            const mas_active_leg_trip = mas_both_leg_trip?.trips.find((x) => x.tripLegId.toString() === trip?.trip_leg_id.toString());
            if (!mas_active_leg_trip) {
                this.utilityService.toast('error', 'Mas trip does not exist, removing the trip');
                this.delete_mas_trip(trip?.trip_leg_id, trip?.mas_trip_id);
                return;
            }
            mas_active_leg_trip.pickupTime = moment(trip?.pickup_datetime).format('HHmm');
            mas_active_leg_trip.pickup_time = moment(trip?.pickup_datetime).format('hh:mm a');
            mas_active_leg_trip.pickup_datetime = new Date(
                moment(mas_active_leg_trip?.pickupDate).format('YYYY-MM-DD') + ', ' + mas_active_leg_trip?.pickup_time
            );
            this.suggested_fare_trip_refrence = trip;
        }
        this.suggested_fare_trip_refrence.pickup_lat = this.suggested_fare_trip_refrence.pickup_latitude;
        this.suggested_fare_trip_refrence.pickup_lang = this.suggested_fare_trip_refrence.pickup_longitude;
        this.suggested_fare_trip_refrence.dropoff_lat = this.suggested_fare_trip_refrence.dropoff_latitude;
        this.suggested_fare_trip_refrence.dropoff_lang = this.suggested_fare_trip_refrence.dropoff_longitude;
        this.suggested_fare_trip_refrence.userMobileListDetail = this.suggested_fare_trip_refrence.user_mobile;
        const initialState: ModalOptions = {
            id: Date.now(),
            initialState: {
                formValue: this.suggested_fare_trip_refrence,
                from: 'list-detail',
            },
            class: 'queud-sortcut modal-lg',
        };
        const modalref: BsModalRef = this.modalService.show(FareEstimateRecalculationComponent, initialState);
        modalref.content.event.subscribe((res: any) => {
            if (res) {
                const payload = {
                    mas_trip_id: this.suggested_fare_trip_refrence.mas_trip_id,
                    avoidHighways: res.avoid_highways ? 1 : 0,
                    avoid_tolls: res.avoid_tolls ? 1 : 0,
                    car_type: res.car_type,
                    third_party_amount: res.third_party_amount,
                    gross_fare_amount: res.estimated_fare,
                    fare_factor: res.fare_factor,
                };
                this.update_queued_mas_trip(payload);
            }
        });
    }

    /** Window Resize */
    isMobileScreen = false;
    isActiveInput: boolean = false;
    from: string;
    listRideBrokers: any[] = [];
    private checkScreenWidth(): void {
        this.isMobileScreen = window.innerWidth <= 600;
    }

    /** Active Input For Ride Brokers*/
    searchActive() {
        this.isActiveInput = !this.isActiveInput;
    }

    /** Filter Data By Broker Status */
    selectRideBroker(type: string) {
        this.masService.brokerStatus = type;
        this.masService.brokerStatusLabel = type;
        this.get_queued_trips();
    }

    /** Ride Broker Drodpwon List */
    getRideBrokerDropdownList() {
        this.httpService
            .post(environment.urlWC + 'list_ride_brokers', { web_access_token: this.cookieService.get('web_access_token') })
            .subscribe((data) => {
                if (data.flag === 2101) {
                    this.listRideBrokers = data.data;
                    this.listRideBrokers = this.listRideBrokers.filter((broker) => broker.company_name !== 'SentryMS');
                    // Add SENTRY-TP and SENTRY-MP
                    this.listRideBrokers.push({
                        id: 14,
                        company_name: 'SENTRY-TPT',
                        office_number: 'YOUR_OFFICE_NUMBER',
                        contact_person: 'YOUR_CONTACT_PERSON',
                        contact_number: 'YOUR_CONTACT_NUMBER',
                        office_address: 'YOUR_OFFICE_ADDRESS',
                        is_enabled: 1,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                        is_deleted: 0,
                    });
                    this.listRideBrokers.push({
                        id: 15,
                        company_name: 'SENTRY-MP',
                        office_number: 'YOUR_OFFICE_NUMBER',
                        contact_person: 'YOUR_CONTACT_PERSON',
                        contact_number: 'YOUR_CONTACT_NUMBER',
                        office_address: 'YOUR_OFFICE_ADDRESS',
                        is_enabled: 1,
                        created_at: new Date().toISOString(),
                        updated_at: new Date().toISOString(),
                        is_deleted: 0,
                    });
                }
            });
    }
}
