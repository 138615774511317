<div
    class="modal-dialog modal-dialog-auto modal-dialog-centered modal-lg touch-popup"
    role="document"
    style="min-width: 580px !important; max-width: fit-content !important"
>
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-5 my-4">
                <div class="medium-text dark-color">{{ title }}</div>
            </div>
        </div>

        <hr class="light" />
        <div class="bg-light-blue pr-3 pr-md-5">
            <div class="touch-content mx-5 my-4 py-2">
                <form [formGroup]="noteForm">
                    <div class="d-flex my-2 justify-content-end">
                        <div class="mx-2 align-self-center fs-13 dark-color fw-600">Title</div>
                        <div class="ml-2">
                            <input
                                [class.error]="noteForm.get('title')?.touched && noteForm.get('title')?.invalid"
                                type="text"
                                class="touch-input fs-13 w-100 p-1 px-2"
                                formControlName="title"
                                placeholder="Add title here"
                            />
                        </div>
                    </div>
                    <div class="d-flex my-2 justify-content-end">
                        <div class="mx-2 align-self-center fs-13 dark-color fw-600">Priority</div>
                        <div class="ml-2 btn-group" dropdown>
                            <div dropdownToggle class="touch-input fs-13 w-100 p-1 px-2 d-flex justify-content-between cursor-pointer">
                                <div>
                                    <ng-container *ngIf="noteForm.get('priority')?.value">
                                        {{ noteForm.get('priority')?.value | titlecase }}
                                    </ng-container>
                                    <ng-container *ngIf="!noteForm.get('priority')?.value">Select Priority</ng-container>
                                </div>
                                <div><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                            </div>
                            <ul
                                id="dropdown-basic"
                                *dropdownMenu
                                class="dropdown-menu cd-menu first card-shadow w-100 left-0"
                                role="menu"
                                aria-labelledby="button-basic"
                            >
                                <li *ngFor="let item of priorityList" role="menuitem" (click)="noteForm.get('priority')?.patchValue(item)">
                                    <div class="dropdown-item blue cd-item cursor-pointer">
                                        {{ item | titlecase }}
                                    </div>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div class="d-flex my-2 justify-content-end">
                        <div class="mx-2 align-self-start fs-13 dark-color fw-600">Description</div>
                        <div class="ml-2">
                            <textarea
                                style="height: 105px !important"
                                [class.error]="noteForm.get('description')?.touched && noteForm.get('description')?.invalid"
                                class="touch-input fs-13 w-100 p-1 px-2"
                                formControlName="description"
                                placeholder="Add description here"
                            ></textarea>
                        </div>
                    </div>
                </form>
                <!-- <div class="text-secondary fs-11 mt-4">Note: You can always update your API key in your settings whenever needed.</div> -->
            </div>
        </div>

        <hr class="light" />
        <div class="touch-bottom mx-5 my-3 pr-3 pr-md-5" align="right">
            <button class="btn btn-secondary btn-light text-white p-2 mx-2 br-6" (click)="bsModalRef.hide()">Dismiss</button>
            <button [disabled]="noteForm.invalid" class="btn btn-primary text-white btn-right-secondary p-2 ml-2 br-6" (click)="onAction()">
                <ng-container *ngIf="mode === 'add'">Save</ng-container>
                <ng-container *ngIf="mode === 'edit'">Update</ng-container>
            </button>
        </div>
    </div>
</div>
