<div *ngIf="!isMobilePopup" class="touch-popup" role="document">
    <div class="modal-content">
        <div class="touch-header pt-2">
            <div class="mx-4 mx-md-5 my-4 d-flex justify-content-between">
                <div class="medium-text dark-color">
                    {{ title }}
                </div>
                <div class="check-fare" (click)="openFareModal()">Check Fare Estimate</div>
            </div>
        </div>
        <hr class="light" />
        <div class="touch-content mx-4 mx-md-5 my-4 py-2">
            <form [formGroup]="queueRideFormGroup">
                <div class="d-flex flex-column flex-md-row">
                    <div>
                        <div class="dark-color fw-600 mb-2">Rider Information</div>
                        <table>
                            <tr>
                                <td>
                                    <div class="align-self-center fs-13 fw-600">
                                        <span *ngIf="viewType === 'add'">New</span>
                                        Rider
                                    </div>
                                </td>
                                <td>
                                    <div class="ml-2 touch-tel-input">
                                        <ng-container *ngIf="trip_type === 'assigned'">
                                            {{ trip?.user_name }}
                                        </ng-container>
                                        <ng-container *ngIf="trip_type === 'queued'">
                                            <input
                                                triggers="input:active"
                                                [popover]="rider_name_search"
                                                [isOpen]="is_rider_popover_open"
                                                [outsideClick]="true"
                                                placement="bottom"
                                                formControlName="rider_name"
                                                type="text"
                                                class="touch-input py-1 px-2 w-100"
                                                placeholder="Enter Rider Name"
                                                [class.error]="
                                                    queueRideFormGroup?.get('rider_name')?.touched &&
                                                    (!queueRideFormGroup?.get('rider_name')?.value || queueRideFormGroup?.get('rider_name').invalid)
                                                "
                                            />
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Mobile Number</div>
                                </td>
                                <td>
                                    <div
                                        class="ml-2 touch-tel-input"
                                        [class.error]="
                                            queueRideFormGroup?.get('phone_number')?.touched &&
                                            (!queueRideFormGroup?.get('phone_number')?.value || queueRideFormGroup?.get('phone_number').invalid)
                                        "
                                    >
                                        <ng-container *ngIf="trip_type === 'assigned'">
                                            {{ trip?.user_mobile }}
                                        </ng-container>
                                        <ng-container *ngIf="trip_type === 'queued'">
                                            <ngx-intl-tel-input
                                                [cssClass]="'custom'"
                                                [attr.autocomplete]="false"
                                                [resetSearchContainer]="true"
                                                [enableAutoCountrySelect]="true"
                                                [enablePlaceholder]="true"
                                                [searchCountryFlag]="true"
                                                [selectFirstCountry]="false"
                                                [selectedCountryISO]="selectedCountryISO"
                                                [maxLength]="promoCountryCode == '+1-' ? 10 : 12"
                                                [tooltipField]="TooltipLabel.Name"
                                                [phoneValidation]="true"
                                                [separateDialCode]="separateDialCode"
                                                name="phone_no"
                                                [inputId]="'phone_no'"
                                                formControlName="phone_number"
                                                customPlaceholder="Enter Rider Mobile Number"
                                                [disabled]="true"
                                                (countryChange)="onCountryChange($event)"
                                            ></ngx-intl-tel-input>
                                        </ng-container>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td colspan="2">
                                    <div class="dark-color fw-600 mt-3 mb-2">Vehicle Information</div>
                                </td>
                            </tr>
                            <tr>
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Car Type</div>
                                </td>
                                <td class="cmodal">
                                    <div class="dropdown ml-2">
                                        <span
                                            style="width: 260px"
                                            type="button"
                                            id="affiliation_menu"
                                            data-toggle="dropdown"
                                            aria-haspopup="true"
                                            aria-expanded="false"
                                        >
                                            <div
                                                class="d-flex justify-content-between placeholder small fw-600"
                                                [class.error]="
                                                    queueRideFormGroup?.get('car_type')?.touched &&
                                                    (!queueRideFormGroup?.get('car_type')?.value || queueRideFormGroup?.get('car_type').invalid)
                                                "
                                            >
                                                <div>
                                                    <ng-container *ngIf="!selected_car_type">Select Car Type</ng-container>
                                                    <ng-container *ngIf="selected_car_type">{{ selected_car_type?.car_name }}</ng-container>
                                                </div>
                                                <div><i class="fa fa-sort-desc" aria-hidden="true"></i></div>
                                            </div>
                                        </span>
                                        <div
                                            class="dropdown-menu p-0 card-shadow border-0 overflow-auto fs-13 w-100 h-auto"
                                            id="trip_status_menu"
                                            aria-labelledby="affiliation_menu"
                                        >
                                            <div
                                                *ngFor="let item of carType"
                                                class="dropdown-item cd-item cursor-pointer"
                                                (click)="select_this_car_type(item)"
                                            >
                                                {{ item.car_name }}
                                            </div>
                                        </div>
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="viewType === 'edit'">
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Driver Pay</div>
                                </td>
                                <td>
                                    <div class="ml-2">
                                        <input
                                            formControlName="amount"
                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                            type="text"
                                            class="touch-input p-1 px-2 autocomplete w-75"
                                            placeholder="0.00"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="viewType === 'edit'">
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Toll</div>
                                </td>
                                <td>
                                    <div class="ml-2">
                                        <input
                                            formControlName="user_toll"
                                            onkeypress="return /[0-9]|\./i.test(event.key)"
                                            type="text"
                                            class="touch-input p-1 px-2 autocomplete w-75"
                                            placeholder="0.00"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                    <div class="vertical-line mx-4 d-none d-md-block"></div>
                    <div class="mt-3 mt-md-0">
                        <div class="dark-color fw-600 mb-2">Trip Information</div>
                        <table>
                            <tr>
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Pickup Date & Time</div>
                                </td>
                                <td>
                                    <div class="ml-2 fw-600">
                                        {{
                                            queueRideFormGroup?.get('pickup_date_time')?.value
                                                | date: 'MMMM dd, y
                                        EEEE hh:mm a'
                                        }}
                                        <mat-icon
                                            class="cursor-pointer align-middle dark-color"
                                            style="font-size: 22px"
                                            [class.error-text]="
                                                queueRideFormGroup?.get('pickup_date_time')?.touched &&
                                                (!queueRideFormGroup?.get('pickup_date_time')?.value ||
                                                    queueRideFormGroup?.get('pickup_date_time').invalid)
                                            "
                                            (click)="open_time_selection_popup()"
                                        >
                                            date_range
                                        </mat-icon>
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Pickup Location</div>
                                </td>
                                <td>
                                    <div class="ml-2 pickupAndDropOff">
                                        <span *ngIf="isPickupLocation">Loading...</span>
                                        <input
                                            id="pickup_location"
                                            type="search"
                                            formControlName="pickup_location"
                                            class="touch-input p-1 px-2 autocomplete w-100"
                                            placeholder="Enter Pickup Location"
                                            [class.error]="
                                                queueRideFormGroup?.get('pickup_location')?.touched &&
                                                (!queueRideFormGroup?.get('pickup_location')?.value ||
                                                    queueRideFormGroup?.get('pickup_location').invalid)
                                            "
                                        />
                                        <img
                                            *ngIf="!queueRideFormGroup?.get('pickup_location')?.value"
                                            (click)="getCurrentLocation('pickup_location')"
                                            src="assets/images/add-queue/location.svg"
                                            alt="divider_blue"
                                        />
                                    </div>
                                </td>
                            </tr>

                            <tr>
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Dropoff Location</div>
                                </td>
                                <td>
                                    <div class="ml-2 pickupAndDropOff">
                                        <span *ngIf="isDropOffLocation">Loading...</span>
                                        <input
                                            id="dropoff_location"
                                            type="search"
                                            formControlName="dropoff_location"
                                            class="touch-input p-1 px-2 autocomplete w-100"
                                            placeholder="Enter Dropoff Location"
                                            [class.error]="
                                                queueRideFormGroup?.get('dropoff_location')?.touched &&
                                                (!queueRideFormGroup?.get('dropoff_location')?.value ||
                                                    queueRideFormGroup?.get('dropoff_location').invalid)
                                            "
                                        />
                                        <img
                                            *ngIf="!queueRideFormGroup?.get('dropoff_location')?.value"
                                            (click)="getCurrentLocation('dropoff_location')"
                                            src="assets/images/add-queue/location.svg"
                                            alt="divider_blue"
                                        />
                                    </div>
                                </td>
                            </tr>
                            <tr *ngIf="viewType === 'edit'">
                                <td>
                                    <div class="align-self-center fs-13 fw-600">Notes</div>
                                </td>
                                <td>
                                    <div class="ml-2">
                                        <input
                                            formControlName="notes"
                                            class="touch-input p-1 px-2 autocomplete w-75"
                                            placeholder="Sample notes here"
                                        />
                                    </div>
                                </td>
                            </tr>
                        </table>
                    </div>
                </div>
            </form>
        </div>
        <hr class="light" />
        <div class="touch-bottom my-3" align="center">
            <button class="btn btn-secondary btn-light text-white p-2 mx-2 br-6" (click)="closePopup()">Cancel</button>
            <button class="btn btn-primary btn-right-secondary p-2 ml-2 br-6" (click)="onPrimaryAction()">{{ primaryActionText }}</button>
        </div>
    </div>
</div>

<!-- Mobile Screen Only Desgin  -->
<div class="mobile-screen-queued modal-fullscreen" *ngIf="isMobilePopup">
    <div class="modal-content">
        <div class="touch-header sticky-top-header">
            <div class="d-flex justify-content-between">
                <div class="ml-3 mt-4">
                    <div class="touch-tabs d-flex justify-content-between">
                        <div class="d-flex">
                            <div class="align-self-end tab active">Queue a trip</div>
                            <div class="ml-3 tab">|</div>
                            <div class="align-self-end tab ml-4">Enter trip details</div>
                        </div>
                    </div>
                </div>
                <div class="mr-3 mt-4">
                    <button type="button" aria-label="Close" (click)="closePopup()">
                        <img src="/assets/images/modalClose.svg" alt="closeIcon" />
                    </button>
                </div>
            </div>
            <hr class="m-0" />
        </div>
        <div class="touch-content">
            <div class="custom-height">
                <form [formGroup]="queueRideFormGroup" class="form">
                    <div class="row left-icon-div mt-2">
                        <div class="col-auto left-icon">
                            <!-- <i class="fa fa-user" style="color: grey"></i> -->
                            <img src="assets/images/add-queue/person.svg" alt="divider_blue" />
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box">
                            <input
                                triggers="input:active"
                                [popover]="rider_name_search"
                                [isOpen]="is_rider_popover_open"
                                [outsideClick]="true"
                                formControlName="rider_name"
                                placement="bottom"
                                type="text"
                                class="touch-input py-1 px-2"
                            />
                            <label
                                for="userName"
                                [class.active-input-state]="queueRideFormGroup?.get('rider_name')?.value"
                                [class.error-label]="
                                    queueRideFormGroup?.get('rider_name')?.touched &&
                                    (!queueRideFormGroup?.get('rider_name')?.value || queueRideFormGroup?.get('rider_name').invalid)
                                "
                            >
                                Passenger Name
                            </label>
                        </div>
                    </div>
                    <hr />
                    <div class="row left-icon-div">
                        <div class="col-auto left-icon">
                            <img src="assets/images/add-queue/phone.svg" alt="divider_blue" />
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div
                            class="col input-box mt-1"
                            [class.error-label]="
                                queueRideFormGroup?.get('phone_number')?.touched &&
                                (!queueRideFormGroup?.get('phone_number')?.value || queueRideFormGroup?.get('phone_number').invalid)
                            "
                        >
                            <ngx-intl-tel-input
                                [ngClass]="'borderless-input'"
                                type="search"
                                [resetSearchContainer]="true"
                                [preferredCountries]="preferredCountries"
                                [enableAutoCountrySelect]="true"
                                [enablePlaceholder]="true"
                                [searchCountryFlag]="true"
                                [searchCountryField]="[SearchCountryField.Iso2, SearchCountryField.Name]"
                                [selectFirstCountry]="false"
                                [selectedCountryISO]="countryToSet"
                                [maxLength]="promoCountryCode == '+1-' ? 10 : 12"
                                [tooltipField]="TooltipLabel.Name"
                                [phoneValidation]="true"
                                [separateDialCode]="separateDialCode"
                                name="phone"
                                [inputId]="'phone'"
                                formControlName="phone_number"
                                [customPlaceholder]="' '"
                                [label]="'Phone Number'"
                                (countryChange)="onCountryChange($event)"
                            ></ngx-intl-tel-input>
                        </div>
                    </div>
                    <hr />
                    <div class="row left-icon-div">
                        <div class="col-auto left-icon">
                            <img src="assets/images/add-queue/cartype.svg" alt="divider_blue" />
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box mt-1 mb-2" style="position: static">
                            <div class="dropdown">
                                <span
                                    class="dropdown-toggle"
                                    type="button"
                                    id="ride_type_dropdown_return"
                                    [class.error-label]="
                                        queueRideFormGroup?.get('car_type')?.touched &&
                                        (!queueRideFormGroup?.get('car_type')?.value || queueRideFormGroup?.get('car_type').invalid)
                                    "
                                    data-toggle="dropdown"
                                    aria-haspopup="true"
                                    aria-expanded="false"
                                    #currentRideType
                                >
                                    {{ selected_car_type?.car_name ? selected_car_type?.car_name : 'Car Type' }}
                                </span>
                                <div
                                    class="dropdown-menu card-shadow pt-2 pb-3"
                                    id="ride_type_menu_return"
                                    aria-labelledby="ride_type_dropdown_return"
                                >
                                    <ng-container *ngFor="let obj of carType; let i = index">
                                        <div class="dropdown-item p-0">
                                            <div class="row my-1 cursor-pointer p-0" (click)="select_this_car_type(obj)">
                                                <div class="col-auto ml-4">
                                                    <img src="assets/images/ride_type.svg" alt="ride_type_icon" />
                                                    &nbsp;&nbsp;
                                                    <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                                                </div>
                                                <div class="col mt-1 item-label p-0">
                                                    {{ obj.car_name }}
                                                </div>
                                            </div>
                                        </div>
                                        <hr />
                                    </ng-container>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr />

                    <div class="row left-icon-div" (click)="open_time_selection_popup()">
                        <div class="col-auto left-icon">
                            <img src="assets/images/add-queue/calendar.svg" alt="divider_blue" />
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box mt-1 mb-2">
                            <input
                                *ngIf="!queueRideFormGroup?.get('pickup_date_time')?.value"
                                id="dateTime"
                                type="search"
                                class="autocomplete"
                                formControlName="pickup_date_time"
                                placeholder=""
                            />
                            <label *ngIf="queueRideFormGroup?.get('pickup_date_time')?.value">
                                {{ queueRideFormGroup?.get('pickup_date_time')?.value | date: 'MMMM dd, y EEEE hh:mm a' }}
                            </label>
                            <label
                                for="dateTime"
                                [class.error-label]="
                                    queueRideFormGroup?.get('pickup_date_time')?.touched &&
                                    (!queueRideFormGroup?.get('pickup_date_time')?.value || queueRideFormGroup?.get('pickup_date_time').invalid)
                                "
                                [class.active-input-state]="queueRideFormGroup?.get('pickup_date_time')?.value"
                            >
                                Pickup Date & Time
                            </label>
                        </div>
                    </div>
                    <hr />
                    <div class="row left-icon-div">
                        <div class="col-auto left-icon">
                            <img (click)="getCurrentLocation('pickup_location')" src="assets/images/add-queue/location.svg" alt="divider_blue" />
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box mt-1 mb-2">
                            <span class="locationLoading" *ngIf="isPickupLocation">Loading...</span>
                            <input id="pickup_location" type="search" class="autocomplete" formControlName="pickup_location" placeholder="" />
                            <label
                                for="pickup"
                                [class.error-label]="
                                    queueRideFormGroup?.get('pickup_location')?.touched &&
                                    (!queueRideFormGroup?.get('pickup_location')?.value || queueRideFormGroup?.get('pickup_location').invalid)
                                "
                                [class.active-input-state]="queueRideFormGroup?.get('pickup_location')?.value"
                            >
                                Pickup Location
                            </label>
                        </div>
                        <div class="col-auto mr-2" class="swap-arrow cursor-pointer" (click)="swapPickupLocation()">
                            <img src="assets/images/swap_arrow.svg" alt="SWAP" />
                        </div>
                    </div>
                    <hr />
                    <div class="row left-icon-div">
                        <div class="col-auto left-icon">
                            <img (click)="getCurrentLocation('dropoff_location')" src="assets/images/add-queue/location.svg" alt="divider_blue" />
                            &nbsp;&nbsp;&nbsp;
                            <img src="assets/images/divider_blue.svg" alt="divider_blue" />
                        </div>
                        <div class="col input-box mt-1 mb-2">
                            <span class="locationLoading" *ngIf="isDropOffLocation">Loading...</span>
                            <input id="dropoff_location" type="search" class="autocomplete" formControlName="dropoff_location" placeholder="" />
                            <label
                                for="dropoff_location"
                                [class.error-label]="
                                    queueRideFormGroup?.get('dropoff_location')?.touched &&
                                    (!queueRideFormGroup?.get('dropoff_location')?.value || queueRideFormGroup?.get('dropoff_location').invalid)
                                "
                                [class.active-input-state]="queueRideFormGroup?.get('dropoff_location')?.value"
                            >
                                Drop off Location
                            </label>
                        </div>
                        <div class="col-auto mr-2" class="swap-arrow cursor-pointer" (click)="swapDropoffLocation()">
                            <img src="assets/images/swap_arrow.svg" alt="SWAP" />
                        </div>
                    </div>
                    <hr />
                    <!-- <div class="row mt-4 justify-content-center" (click)="openFareModal()">
                        <h5 class="check-fare">Check fare estimate</h5>
                    </div> -->
                    <div class="row mt-4 justify-content-center" (click)="fareEstimateRecalc()">
                        <h5 class="check-fare">Check fare estimate</h5>
                    </div>
                </form>
            </div>
        </div>
        <hr class="light" />
        <div class="mobileFooter sticky-bottom" (click)="onPrimaryAction()">Queue the trip</div>
    </div>
</div>

<ng-template #rider_name_search>
    <div class="touch-popover w-260">
        <div class="rider-search-max-height">
            <div class="touch-popover-menu-item cursor-pointer" *ngFor="let rider of riders_list" (click)="select_this_rider(rider)">
                <div class="fw-600">
                    <span class="title">{{ rider?.user_name }}</span>
                    <span class="text-spacegrey mx-1">|</span>
                    <span class="subtitle text-sapcegrey">{{ rider?.user_mobile }}</span>
                </div>
            </div>
            <div *ngIf="!riders_list?.length" class="fs-13 text-spacegrey fw-600 text-center my-2">No result found!</div>
        </div>
        <hr class="light" />
        <div class="d-flex justify-content-center text-blue-secondary fs-13 fw-600 my-2">
            <div class="cursor-pointer" (click)="is_rider_popover_open = false">Queue w/out Signup</div>
        </div>
    </div>
</ng-template>

<app-fare-estimate [updateQueueTripModal]="updateQueueTripModal" (onBookingQueued)="onBookingQueued($event)"></app-fare-estimate>

<!-- New Mobile UI -->
<!-- <app-fare-estimate-recalculation (onBookingQueued)="onBookingQueued($event)"></app-fare-estimate-recalculation> -->
