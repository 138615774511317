<div class="main_outer pagination pt-0">
    <div [class.sticky-shadow]="showHeaderShadow">
        <app-trip-roster-header
            [hideTitleLayer]="minimal_setup"
            [preventRouteAction]="minimal_setup"
            (onTabChange)="onTabChange.emit($event)"
            headerTitle="Trip Roster"
            [tabList]="utilityService.settingHeaderConfig.active_trips.tabs"
            activeTab="Completed"
            [searchControl]="searchForm"
            [subTabList]="utilityService.settingHeaderConfig.active_trips.sub_tabs['Completed']"
            [activeSubTab]="activeSubTab"
            (subTabClick)="changeActiveSubTab($event)"
            [showStartSessionAction]="true"
        ></app-trip-roster-header>
    </div>
    <div class="table-container touch-scrollbar" (scroll)="showHeaderShadow = $event.srcElement.scrollTop !== 0" filter_options>
        <table class="table table-responsive-md trips_detail dtrips trips">
            <thead>
                <tr class="frow">
                    <th scope="col">Session ID</th>
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="searchForm" class="m-0 border-0 w-100" type="text" placeholder="Search Riders" />
                                    </div>
                                    <div>
                                        <i *ngIf="!searchForm.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            [class.disabled-text]="searchForm?.disabled"
                                            *ngIf="searchForm.value"
                                            (click)="searchForm.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col" class="large">
                        <div class="w-100 search-box light">
                            <span class="input-group">
                                <div class="d-flex w-100">
                                    <div class="flex-fill">
                                        <input [formControl]="driver_search" class="m-0 border-0 w-100" type="text" placeholder=" Search Drivers" />
                                    </div>
                                    <div>
                                        <i *ngIf="!driver_search.value" class="fa fa-search pr-0" aria-hidden="true"></i>
                                        <mat-icon
                                            *ngIf="driver_search.value"
                                            (click)="driver_search.reset()"
                                            class="cursor-pointer"
                                            style="vertical-align: middle"
                                        >
                                            close
                                        </mat-icon>
                                    </div>
                                </div>
                            </span>
                        </div>
                    </th>
                    <th scope="col">Pickup Date & time</th>
                    <th scope="col" class="mobilecar large">Pickup Location</th>
                    <th scope="col" class="tcenter">Car Type</th>
                    <th scope="col" class="tcenter">Status</th>
                    <th scope="col">Payment Method</th>
                </tr>
            </thead>
            <tbody *ngIf="Trips?.length > 0">
                <tr
                    *ngFor="
                        let trip of Trips
                            | paginate
                                : {
                                      id: 'completed_trips',
                                      itemsPerPage: itemsPerPage,
                                      currentPage: pageNo,
                                      totalItems: totalItems
                                  };
                        let index = index
                    "
                    class="rideBody Disb cursor-pointer"
                >
                    <td>
                        {{ trip.session_id }}
                    </td>
                    <td>
                        {{ trip.user_name | ShortText | titlecase }}
                    </td>
                    <td>
                        {{ trip.driver_name | ShortText | titlecase }}
                        <br />
                        {{ trip.driver_mobile }}
                    </td>
                    <td [class.tcenter]="!trip?.arrival_time">
                        <ng-container *ngIf="trip.arrival_time">
                            {{ trip?.arrival_time | date: 'MMMM d, y h:mm a' }} - ({{ utilityService.getTimezone(trip?.arrival_time) }})
                        </ng-container>
                        <ng-container *ngIf="!trip?.arrival_time">-</ng-container>
                    </td>
                    <td [class.tcenter]="!trip.pickup_location_address">
                        {{ trip.pickup_location_address || '-' }}
                    </td>
                    <td class="tcenter">
                        {{ trip.car_name || '-' }}
                    </td>
                    <td class="tcenter status-color blue">
                        {{ trip.ride_status || '-' }}
                    </td>
                    <!-- <td class="tcenter">
                        {{ trip.qudos_corporate_share || '-' }}
                    </td> -->
                    <td>
                        <ng-container *ngIf="trip.last4_card_digit">**{{ trip.last4_card_digit }}</ng-container>
                        <ng-container *ngIf="!trip.last4_card_digit">-</ng-container>
                    </td>
                </tr>
            </tbody>

            <tbody *ngIf="Trips.length == 0 && !isLoading">
                <tr class="noride no-hover">
                    <td colspan="8" class="text-center" style="padding: 20px 87px 20px 87px !important">No Rides Yet</td>
                </tr>
            </tbody>
            <tbody *ngIf="Trips.length === 0 && isLoading">
                <tr class="noride no-hover">
                    <td colspan="8" class="text-center px-3">
                        <app-loading type="placeholder" [placeholderLength]="10"></app-loading>
                    </td>
                </tr>
            </tbody>
        </table>
    </div>
</div>

<div class="custom_pagination_v2" *ngIf="showPagination">
    <div class="d-flex flex-row-reverse">
        <div class="align-self-center">
            <pagination-controls id="completed_trips" responsive="true" (pageChange)="getTrips($event)"></pagination-controls>
        </div>

        <div class="p-2 bd-highlight highlight align-self-center ml-3 dropdown cursor-pointer">
            <div class="fw-600 fs-13 dropdown-toggle" data-toggle="dropdown" id="dropdown">
                <span class="text-spacegrey">{{ utilityService.display_filter_label }}:</span>
                <span class="text-blue-secondary ml-1">{{ itemsPerPage }}</span>
            </div>
            <div class="dropdown-menu cd-menu left-0 card-shadow" aria-labelledby="download" style="right: 0; left: -8px">
                <div
                    *ngFor="let item of utilityService.display_filter_list"
                    class="dropdown-item text-center cd-item text-spacegrey"
                    (click)="getTrips(1, item)"
                >
                    {{ item }}
                </div>
            </div>
        </div>

        <div class="text-spacegrey fs-13 align-self-center fw-600">
            <!-- {{ pageNo * itemsPerPage >= totalItems ? totalItems : pageNo * itemsPerPage }} of {{ totalItems }} -->
            Results:
            <span class="text-blue-secondary">{{ totalItems }}</span>
        </div>
    </div>
</div>

<!-- TODO: uncomment -->

<!-- <div
    class="modal fade"
    id="reschedule_time_picker_modal"
    tabindex="-1"
    role="dialog"
    aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true"
    style="z-index: 1230 !important"
    data-backdrop="static"
>
    <div class="modal-dialog modal-dialog-auto modal-dialog-centered" role="document" style="width: 700px !important">
        <div class="modal-content booking-model-component card-shadow">
            <div class="mx-5 mt-5 mb-2 title">Reschedule the ride</div>

            <div class="modal-body p-0">
                <hr class="confirm mb-0" />
                <div class="message-bg">
                    <div class="message p-5">
                        <div class="panel-body">
                            <div class="row m-auto">
                                <div class="col-md-6 px-2 mb-0">
                                    <div class="d-flex justify-content-between schedule-date" (click)="reschedule_dispatched.open()">
                                        <div class="text">{{ selected_date?.date | date: 'y-MM-dd (EEEE)' }}</div>
                                        <div class="cal-icon no-select">
                                            <img src="assets/images/calender_icon.svg" alt="" />
                                        </div>
                                    </div>
                                    <input
                                        [(ngModel)]="selected_date.date"
                                        readonly
                                        [matDatepicker]="reschedule_dispatched"
                                        class="invisible mat-date-input"
                                    />
                                    <mat-datepicker #reschedule_dispatched></mat-datepicker>
                                </div>
                                <div class="col-md-3 px-2 form-group">
                                    <select
                                        name="time"
                                        [(ngModel)]="selected_date.time_hour"
                                        class="form-control select-field-spacing select-highlight text-dark-blue"
                                        id="time"
                                        style="height: 50px"
                                    >
                                        <option *ngFor="let option of hoursList" [value]="option.value">{{ option.title }}</option>
                                    </select>
                                </div>
                                <div class="col-md-3 px-2 form-group">
                                    <select
                                        name="time"
                                        [(ngModel)]="selected_date.time_minute"
                                        class="form-control select-field-spacing select-highlight text-dark-blue"
                                        id="time-min"
                                        style="height: 50px"
                                    >
                                        <option [value]="m" *ngFor="let m of minutesList">
                                            <span>{{ m }} mins</span>
                                        </option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <hr class="confirm mt-0" />

                <div align="center" class="m-3">
                    <button
                        class="btn btn-secondary border-0 btn-left m-2 px-3 py-1"
                        style="background-color: #707683 !important"
                        data-dismiss="modal"
                    >
                        Cancel
                    </button>

                    <button class="btn btn-secondary border-0 btn-right m-2 px-3 py-1">Reschedule</button>
                </div>
            </div>
        </div>
    </div>
</div> -->
